import React from "react";
import Label, { LabelProps } from "./Label";
import CheckBox, { CheckBoxProps } from "./CheckBox";
import classNames from "classnames";

export interface FieldProps extends CheckBoxProps {
  label: string;
  labelProps?: LabelProps;
  Component?: React.ForwardRefExoticComponent<
    React.InputHTMLAttributes<HTMLInputElement> &
      React.RefAttributes<HTMLInputElement> & {
        small?: boolean;
        extraSmall?: boolean;
      }
  >;
  extraText?: React.ReactElement;
}
function OptionWithoutRef(
  {
    className,
    id,
    label,
    labelProps,
    small,
    extraSmall,
    Component = React.forwardRef(CheckBox),
    extraText,
    ...inputProps
  }: FieldProps,
  ref?: React.Ref<HTMLInputElement>
) {
  return (
    <div>
      <div className={classNames("relative flex py-1", className)}>
        <div className="flex h-6 items-center">
          <Component
            id={id}
            ref={ref}
            {...inputProps}
            small={small}
            extraSmall={extraSmall}
          />
        </div>
        <div>
          <Label
            className="ml-3"
            htmlFor={id}
            {...labelProps}
            small={small}
            extraSmall={extraSmall}
          >
            {label}
          </Label>
        </div>
      </div>
      {extraText && <div className="mt-1">{extraText}</div>}
    </div>
  );
}

const Option = React.forwardRef(OptionWithoutRef);
export default Option;
