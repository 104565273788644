import { useContext, useEffect, useReducer } from "react";
import { ValidationScoreBoardContext } from "./context";

export function useValidationScoreBoardTotal() {
  // read out the ValidationScoreBoard instance from current context
  const vsb = useContext(ValidationScoreBoardContext);
  if (!vsb) {
    throw Error(
      "useValidationScoreBoard can only be used inside a <ValidationScoreBoardContext.Provider/>."
    );
  }
  // in this hook trigger rerenders
  const [, tick] = useReducer((i) => i + 1, 0);

  // register our state as listener to get notified when the ValidationScoreBoard updates
  useEffect(() => {
    vsb.register(tick);
    // this is cleans up listener
    return () => vsb.unregister(tick);
  }, [vsb]);
  return [vsb.totalScore, vsb] as const;
}

export default useValidationScoreBoardTotal;
