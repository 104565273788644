import React from "react";
import { is } from "superstruct";
import { AnyRenderType } from "./types";
import {
  RenderRichTextTemplateBlockContent,
  RenderTypeModel as RichTextRenderTypeModel,
} from "./RichTextBlock";
import {
  RenderTableTemplateBlockContent,
  RenderTypeModel as TableRenderTypeModel,
} from "./Table";
import {
  RenderQuestionnaireTemplateBlockContent,
  RenderTypeModel as QuestionnaireRenderTypeModel,
} from "./Questionnaire";
import { FocusableBlockContent } from "./useFocusableBlockContent";

export interface RenderTemplateBlockContentProps<
  R extends AnyRenderType = AnyRenderType,
> {
  templateBlockId: string;
  renderType: R;
  readOnly?: boolean;
}

function RenderTemplateBlockContentWithoutRef(
  props: RenderTemplateBlockContentProps,
  ref: React.Ref<FocusableBlockContent>,
): JSX.Element {
  const { renderType } = props;
  try {
    if (is(renderType, RichTextRenderTypeModel)) {
      return (
        <RenderRichTextTemplateBlockContent
          ref={ref}
          {...props}
          renderType={renderType}
        />
      );
    }
    if (is(renderType, TableRenderTypeModel)) {
      return (
        <RenderTableTemplateBlockContent
          ref={ref}
          {...props}
          renderType={renderType}
        />
      );
    }

    if (is(renderType, QuestionnaireRenderTypeModel)) {
      return (
        <RenderQuestionnaireTemplateBlockContent
          ref={ref}
          {...props}
          renderType={renderType}
        />
      );
    }
    throw Error(
      `No render functions found for block with type: ${props.renderType}`,
    );
  } catch (error) {
    console.warn("Couldn't render building block with props:", props);
    throw error;
  }
}

const RenderTemplateBlockContent = React.memo(
  React.forwardRef(RenderTemplateBlockContentWithoutRef),
);

export default RenderTemplateBlockContent;
