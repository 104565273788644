import { SuggestionPosition } from "@/components/legacy/suggestions/types";
import { BaseRange, Node, NodeEntry, Range } from "slate";
import { Query } from "./detectQuery";

type DecoratorFn = (entry: NodeEntry<Node>) => BaseRange[];
const useHighlightTargetDecorator = (
  query: Query | null,
  position?: SuggestionPosition,
  color?: string,
): DecoratorFn => {
  if (!query || !position) {
    return () => [];
  }
  const replacementRange = {
    ...(query.targetRange ?? query.queryRange),
    highlight: true,
    color: color,
  };
  if (position) {
    const { anchor } = replacementRange;
    replacementRange.anchor = {
      ...anchor,
      offset: anchor.offset + position.offset,
    };
  }
  return ([, path]: NodeEntry<Node>) =>
    Range.includes(replacementRange, path) ? [replacementRange] : [];
};

export default useHighlightTargetDecorator;
