/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { PatientLinkReference_Input } from './PatientLinkReference_Input';

export type PatientLink_Input = {
    id?: (string | null);
    type: PatientLink_Input.type;
    other: PatientLinkReference_Input;
};

export namespace PatientLink_Input {

    export enum type {
        REFER = 'refer',
    }


}

