import CodingTooltip from "@/components/base/CodingCard/CodingTooltip";
import Tooltip from "@/components/info-cards/Tooltip";
import { IConcept } from "@/data-models/concept";
import { conceptAsCoding } from "@/data-models/value-models/types";
import { useFeatureFlagEnabled } from "posthog-js/react";
import { Controller } from "react-hook-form";

function ObservationCode({
  name,
  code,
  hidden,
  disabled,
}: {
  name: string;
  code: IConcept;
  hidden?: boolean;
  disabled?: boolean;
}) {
  const showCodingCardTooltip = useFeatureFlagEnabled(
    "show-coding-card-tooltip",
  );
  return (
    <Tooltip enabled={showCodingCardTooltip && !hidden && !disabled}>
      <Tooltip.Content className="fill-gray-lighter">
        <CodingTooltip coding={conceptAsCoding(code)} />
      </Tooltip.Content>
      <Tooltip.Trigger>
        <span>
          <Controller
            name={name}
            defaultValue={code}
            render={() =>
              hidden ? <span /> : <span className="pr-1">{code?.text}</span>
            }
          />
        </span>
      </Tooltip.Trigger>
    </Tooltip>
  );
}
export default ObservationCode;
