import { assert, pattern, string, type, union } from "superstruct";
import { IReference, IIdentifier } from "@/data-models/value-models/types";
import Identifier from "./identifier";

type ReferenceArg<
  TRefType extends string = string,
  TRef extends string = `${TRefType}/${string}`,
> =
  | {
      reference: TRef;
      identifier: IIdentifier;
      display?: string;
      type: TRefType;
    }
  | {
      reference?: undefined;
      identifier: IIdentifier;
      display?: string;
      type: TRefType;
    }
  | {
      reference: TRef;
      identifier?: undefined;
      display?: string;
      type: TRefType;
    };

class Reference<
  TRefType extends string = string,
  TRef extends string = `${TRefType}/${string}`,
> implements IReference
{
  display?: string;
  reference?: TRef;
  identifier?: Identifier;
  type: TRefType;
  constructor({
    identifier,
    reference,
    type,
    display,
  }: ReferenceArg<TRefType, TRef>) {
    if (!identifier && !reference)
      throw new Error(
        "Reference must have either an identifier or a reference",
      );
    this.display = display;
    this.reference = reference;
    this.identifier = identifier && new Identifier(identifier);
    this.type = type;
  }

  get onlyDisplay() {
    return !!this.display && !this.reference && !this.identifier;
  }

  toURLTokens<TName extends string = string>(
    name: TName,
  ): Record<TName | `${TName}Identifier`, string> {
    let tokenEntries = [];
    if (this.reference) tokenEntries.push([name, this.reference]);
    if (this.identifier)
      tokenEntries.push([
        `${name}Identifier`,
        `${this.identifier.system}|${this.identifier.value}`,
      ]);
    return Object.fromEntries(tokenEntries);
  }

  toIdentifierToken() {
    return this.identifier && this.identifier.toToken();
  }

  toUniqueToken() {
    return this.reference || this.toIdentifierToken();
  }

  toString() {
    if (this.onlyDisplay) return this.display!;
    if (this.reference) return this.reference;
    if (this.identifier) return this.identifier.toToken();

    throw new Error(
      "Reference must have at least an identifier, a reference or a display",
    );
  }

  static fromObj(obj: IReference): Reference {
    assert(
      obj,
      union([
        type({
          reference: pattern(string(), /^([A-Za-z]+)\/([A-Za-z0-9\-.]+)$/),
        }),
        type({ identifier: type({ system: string(), value: string() }) }),
        type({ display: string() }),
      ]),
    );

    return new Reference(obj as ReferenceArg);
  }
}
interface TiroPatientReferenceArg {
  reference: `Patient/${number}`;
  identifier?: IIdentifier;
  type: "Patient";
  display?: string;
}

export class TiroPatientReference extends Reference<
  "Patient",
  `Patient/${number}`
> {
  reference: `Patient/${number}`;
  constructor(arg: TiroPatientReferenceArg) {
    super(arg);
    this.reference = arg.reference;
  }

  static fromPatientId(patientId: number) {
    return new TiroPatientReference({
      reference: `Patient/${patientId}`,
      type: "Patient",
    });
  }
}

export default Reference;
