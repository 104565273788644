import { useSemaphore } from "@/components/questionnaire-v2/Semaphore";
import { ITypedElement } from "@/editor/TypedElement";
import { IEnabledWhen } from "@/hooks/useConditionallyEnabled/types";
import useConditionallyEnabled from "@/hooks/useConditionallyEnabled";
import useFHIRPath from "@/hooks/useFHIRPath";
import { useCallback, useContext, useEffect } from "react";
import { Transforms } from "slate";
import {
  ReactEditor,
  useFocused,
  useReadOnly,
  useSelected,
  useSlateStatic,
} from "slate-react";
import PrefixNameContext from "./PrefixName";

export type IBaseInputElement = {
  name: string;
  initialExpression?: string;
  enabledWhen?: IEnabledWhen;
} & ITypedElement;

function useSlateInputElement<
  TElement extends IBaseInputElement,
  TUpdateElement extends IBaseInputElement = TElement,
>(element: TElement) {
  const readOnly = useReadOnly();
  const prefix = useContext(PrefixNameContext);
  const name = prefix ? `${prefix}.${element.name}` : element.name;
  const { enabledWhen, name: linkId } = element;

  const enabled = useConditionallyEnabled({ enabledWhen, linkId });

  const { hasSemaphore, request, release } = useSemaphore(name, {
    failIfContextNotFound: false,
  });

  const result = useFHIRPath(element.initialExpression ?? "", {});
  const editor = useSlateStatic();
  const selected = useSelected();
  const focused = useFocused();
  const active = selected && focused;

  useEffect(() => {
    if (active) request();
  }, [request, release, active, selected]);

  const update = useCallback(
    (update: Partial<TUpdateElement>) => {
      Transforms.setNodes(editor, update, {
        at: ReactEditor.findPath(editor, element),
      });
    },
    [editor, element],
  );

  return {
    name,
    update,
    showProperties: hasSemaphore,
    disabled: !readOnly || !enabled,
    active,
    initialValue: result?.[0],
  };
}

export default useSlateInputElement;
