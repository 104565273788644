import { DateEntity } from "@/data-models/date-entity";
import React from "react";
import { EntityEditorProps } from ".";
import FormContainer, { FormContainerProps } from "./FormContainer";
export interface DateEntityEditorProps extends EntityEditorProps<DateEntity> {}
const DateFormContainer = React.forwardRef<
  HTMLFormElement,
  FormContainerProps<DateEntity>
>(FormContainer);
const DateEntityEditorWithoutRef = (
  props: DateEntityEditorProps,
  ref?: React.Ref<HTMLFormElement>
) => {
  return (
    <DateFormContainer ref={ref} {...props}>
      {({ register }) => (
        <div>
          <div className="mx-3 text-xs uppercase tracking-wide text-gray-primary">
            <span>Datum</span>
          </div>
          <div className="mt-1 grid grid-cols-3 space-x-2 px-3">
            <input
              {...register("entity.date")}
              placeholder="datum"
              type="date"
              className="col-span-2 mt-1 block rounded-sm border border-gray-200 px-1.5 py-1 focus:border-blue-200 focus:ring-1 focus:ring-blue-200 sm:text-sm"
              autoFocus
            />
          </div>
        </div>
      )}
    </DateFormContainer>
  );
};
const DateEntityEditor = React.forwardRef(DateEntityEditorWithoutRef);
export default DateEntityEditor;
