import * as React from "react";
import {
  useFloating,
  autoUpdate,
  offset,
  flip,
  shift,
  useHover,
  useFocus,
  useDismiss,
  useRole,
  useInteractions,
  useMergeRefs,
  FloatingPortal,
  useTransitionStyles,
  safePolygon,
  FloatingArrow,
  arrow,
  autoPlacement,
  useDelayGroup,
  FloatingArrowProps,
} from "@floating-ui/react";
import type { Placement } from "@floating-ui/react";
import { func, is } from "superstruct";

type Delay = Partial<{ open: number; close: number }> | number;

interface TooltipOptions {
  initialOpen?: boolean;
  allowedPlacements?: Placement[];
  arrowRef?: React.RefObject<SVGSVGElement> | null;
  open?: boolean;
  onOpenChange?: (open: boolean) => void;
  enabled?: boolean;
  delay?: Delay;
  id?: string;
}

export function useTooltip({
  initialOpen = false,
  allowedPlacements = ["top-start", "top-end", "bottom-start", "bottom-end"],
  arrowRef = null,
  open: controlledOpen,
  onOpenChange: setControlledOpen,
  delay: providedDelay = { open: 300, close: 300 },
  enabled,
  id,
}: TooltipOptions = {}) {
  const [uncontrolledOpen, setUncontrolledOpen] = React.useState(initialOpen);

  const open = controlledOpen ?? uncontrolledOpen;
  const setOpen = setControlledOpen ?? setUncontrolledOpen;

  const data = useFloating({
    open,
    onOpenChange: setOpen,
    whileElementsMounted: autoUpdate,
    middleware: [
      offset(5),
      flip({
        fallbackAxisSideDirection: "start",
        padding: 5,
      }),
      shift({ padding: 5 }),
      arrow({ element: arrowRef, padding: 5 }),
      autoPlacement({ allowedPlacements }),
    ],
  });

  const context = data.context;
  const groupContext = useDelayGroup(context, { id });
  const hover = useHover(context, {
    move: false,
    enabled: enabled && controlledOpen == null,
    delay: groupContext?.delay ?? providedDelay,
    handleClose: safePolygon(),
  });
  const { styles } = useTransitionStyles(context);

  const focus = useFocus(context, {
    enabled: controlledOpen == null,
  });
  const dismiss = useDismiss(context);
  const role = useRole(context, { role: "tooltip" });

  const interactions = useInteractions([hover, focus, dismiss, role]);

  return React.useMemo(
    () => ({
      open,
      setOpen,
      ...interactions,
      ...data,
      styles,
    }),
    [open, setOpen, interactions, data, styles],
  );
}

type ContextType = (ReturnType<typeof useTooltip> & { arrow?: boolean }) | null;

const TooltipContext = React.createContext<ContextType>(null);
const ArrowContext = React.createContext<React.RefObject<SVGSVGElement>>({
  current: null,
});

export const useTooltipContext = () => {
  const context = React.useContext(TooltipContext);

  if (context == null) {
    throw new Error("Tooltip components must be wrapped in <Tooltip />");
  }

  return context;
};

function Tooltip({
  children,
  id,
  ...options
}: { children: React.ReactNode } & TooltipOptions & { id?: string }) {
  // This can accept any props as options, e.g. `placement`,
  // or other positioning options.

  const arrowRef = React.useRef<SVGSVGElement>(null);
  const tooltip = useTooltip({ ...options, arrowRef, id });

  return (
    <ArrowContext.Provider value={arrowRef}>
      <TooltipContext.Provider value={{ ...tooltip }}>
        {children}
      </TooltipContext.Provider>
    </ArrowContext.Provider>
  );
}

const TooltipTrigger = React.forwardRef<
  HTMLElement,
  React.HTMLProps<HTMLElement> & { asChild?: boolean }
>(function TooltipTrigger({ children, asChild = true, ...props }, propRef) {
  const context = useTooltipContext();
  const childrenRef = (children as any).ref;
  const ref = useMergeRefs([context.refs.setReference, propRef, childrenRef]);

  // `asChild` allows the user to pass any element as the anchor
  if (asChild && React.isValidElement(children)) {
    return React.cloneElement(
      children,
      context.getReferenceProps({
        ref,
        ...props,
        ...children.props,
        "data-state": context.open ? "open" : "closed",
      }),
    );
  }

  return (
    <button
      ref={ref}
      // The user can style the trigger based on the state
      data-state={context.open ? "open" : "closed"}
      {...context.getReferenceProps(props)}
    >
      {children}
    </button>
  );
});

const TooltipContent = React.forwardRef<
  HTMLDivElement,
  React.HTMLProps<HTMLDivElement> & {
    children?: React.ReactNode | (() => React.ReactNode);
  } & Pick<FloatingArrowProps, "fill" | "stroke" | "className">
>(function TooltipContent({ stroke, fill, className, ...props }, propRef) {
  const arrowRef = React.useContext(ArrowContext);
  const { context, getFloatingProps } = useTooltipContext();
  const { isInstantPhase, currentId } = useDelayGroup(context);
  const instantDuration = 0;
  const duration = 250;
  const { isMounted, styles } = useTransitionStyles(context, {
    duration: isInstantPhase
      ? {
          open: instantDuration,
          // `id` is this component's `id`
          // `currentId` is the current group's `id`
          close: currentId === context.floatingId ? duration : instantDuration,
        }
      : duration,
    initial: {
      opacity: 0,
    },
  });
  const ref = useMergeRefs([context.refs.setFloating, propRef]);

  if (!isMounted) return null;

  const children = is(props.children, func())
    ? props.children()
    : props.children;
  return (
    <FloatingPortal>
      <div
        ref={ref}
        style={{ ...context.floatingStyles, ...styles }}
        {...getFloatingProps(props)}
      >
        <FloatingArrow
          ref={arrowRef}
          context={context}
          tipRadius={2}
          stroke={stroke}
          fill={fill}
          className={className}
        />
        {children}
      </div>
    </FloatingPortal>
  );
});

Tooltip.Trigger = TooltipTrigger;
Tooltip.Content = TooltipContent;
export default Tooltip;
