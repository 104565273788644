import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";
import Tippy from "@tippyjs/react";
import classNames from "classnames";
import { Spinner } from "@/Icons";
import { useQuery } from "react-query";
import { createCodeSystemByURLQuery } from "@/services/content/useCodeSystem";

const URI_TO_CS = {
  "http://snomed.info/sct": "SNOMED CT",
  "http://hl7.org/fhir/sid/icd-10": "ICD-10",
  "http://unitsofmeasure.org": "UCUM",
};

function getCodeSystemDisplay(system: string) {
  return Object.keys(URI_TO_CS).includes(system)
    ? URI_TO_CS[system as unknown as keyof typeof URI_TO_CS]
    : undefined;
}

function compactURI(uri: string) {
  return uri.replace(/(https?:\/\/)|(\/$)/g, "");
}

function useCodeSystemTitle(url: string) {
  return useQuery({
    ...createCodeSystemByURLQuery(url),
    select: (data) => data.entry?.[0].resource.title,
  });
}
function RenderSystem({ url, className }: { url: string; className?: string }) {
  const {
    data: title,
    isError,
    isLoading,
    isSuccess,
  } = useCodeSystemTitle(url);
  return (
    <Tippy
      disabled={isSuccess}
      content={
        isLoading
          ? "Loading CodeSystem..."
          : isError
            ? "Error - CodeSystem not found"
            : undefined
      }
    >
      <span className={classNames(className, "inline-flex items-center")}>
        {title ?? getCodeSystemDisplay(url) ?? compactURI(url)}
        {isLoading && (
          <Spinner className="ml-0.5 inline h-4 w-4 animate-spin text-gray-400" />
        )}
        {isError && (
          <ExclamationTriangleIcon
            className="ml-0.5 inline h-3 w-3 text-red-400"
            aria-hidden="true"
          />
        )}
      </span>
    </Tippy>
  );
}

export default RenderSystem;
