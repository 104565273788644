import format from "date-fns/format";
import isValid from "date-fns/isValid";
import parse from "date-fns/parse";
import { nlBE as locale } from "date-fns/locale";
import {
  Describe,
  Infer,
  literal,
  pattern,
  string,
  type,
  union,
} from "superstruct";
import { IAbstractEntity } from "./entity";

export interface IDateTimeEntity {
  type: "datetime";
  datetime: string;
}
export const DateTimeEntityModel: Describe<IDateTimeEntity> = type({
  type: literal("datetime"),
  datetime: union([
    pattern(
      string(),
      /([0-9]([0-9]([0-9][1-9]|[1-9]0)|[1-9]00)|[1-9]000)(-(0[1-9]|1[0-2])(-(0[1-9]|[1-2][0-9]|3[0-1])(T([01][0-9]|2[0-3]):[0-5][0-9]:([0-5][0-9]|60)(\.[0-9]+)?(Z|(\+|-)((0[0-9]|1[0-3]):[0-5][0-9]|14:00)))?)?)?/,
    ),
    literal(""),
  ]),
});

export class DateTimeEntity implements IAbstractEntity {
  readonly type: "datetime" = "datetime";
  readonly datetime: string;
  readonly parsedDateTime: Date | null;
  constructor(entity: Infer<typeof DateTimeEntityModel>) {
    this.datetime = entity.datetime;
    this.parsedDateTime =
      this.datetime.length > 0
        ? parse(this.datetime, "yyyy-MM-dd'T'HH:mm", new Date())
        : null;
  }
  get text() {
    return this.toString();
  }
  get isValid() {
    return !isValid(this.parsedDateTime) || !this.parsedDateTime;
  }
  get value() {
    return this.datetime;
  }
  toString() {
    return this.toShortString();
  }
  toShortString() {
    if (!isValid(this.parsedDateTime) || !this.parsedDateTime) return "";
    try {
      return format(this.parsedDateTime, "Pp", { locale });
    } catch (e) {
      console.warn(e, this);
      return "";
    }
  }
  toLongString() {
    if (!isValid(this.parsedDateTime) || !this.parsedDateTime) return "";
    try {
      return format(this.parsedDateTime, "PPPppp", { locale });
    } catch (e) {
      console.warn(e, this);
      return "";
    }
  }
}
