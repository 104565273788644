/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateUserPickCode } from "../models/CreateUserPickCode";
import type { CreateUserPickValueSet } from "../models/CreateUserPickValueSet";

import type { CancelablePromise } from "../core/CancelablePromise";
import type { BaseHttpRequest } from "../core/BaseHttpRequest";

export class V2Service {
  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Create Event
   * @returns any Successful Response
   * @throws ApiError
   */
  public createEventV2({
    requestBody,
    session,
  }: {
    requestBody: CreateUserPickCode | CreateUserPickValueSet;
    session?: string;
  }): CancelablePromise<any> {
    return this.httpRequest.request({
      method: "POST",
      url: "/v2/events/",
      cookies: {
        session: session,
      },
      body: requestBody,
      mediaType: "application/json",
      errors: {
        422: `Validation Error`,
      },
    });
  }
}
