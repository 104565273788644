import classNames from "classnames";
import SlateSuggestEditor from "@/components/controlled/SlateSuggestEditor";

import { useMemo } from "react";
import AddCheckElement from "@/editor/legacy/CheckPlugin/AddCheckElement";
import constructAnswerEditor from "./createAnswerEditor";
import { ITiroElement } from "@/data-models/nodes";

export interface RenderAnswerProps<A extends ITiroElement[] = ITiroElement[]> {
  className?: string;
  answer: A;
  onAnswerChange: (v: A) => void;
  mute?: boolean;
}

function RenderAnswer({
  answer,
  className,
  mute,
  onAnswerChange: setAnswer,
}: RenderAnswerProps) {
  const editor = useMemo(() => {
    return constructAnswerEditor({
      mute,
    });
  }, [mute]);

  const Footer = useMemo(() => <AddCheckElement />, []);
  return (
    <dd className={classNames("text-sm text-gray-900", className)}>
      <SlateSuggestEditor
        className="relative rounded-md border-gray-200 bg-white px-3 py-2 focus-within:outline-blue-100 focus-within:drop-shadow-sm"
        editableFooter={Footer}
        editor={editor}
        value={answer}
        setValue={setAnswer}
        scrollSelectionIntoView={console.log}
      />
    </dd>
  );
}
export default RenderAnswer;
