import classNames from "classnames";
import { Text } from "slate";
import { RenderLeafProps } from "slate-react";

export interface RenderSuggestLeafProps extends RenderLeafProps {
  leaf: Text & { highlight?: boolean; color?: string };
}

function RenderSuggestLeaf({
  attributes,
  children,
  leaf,
}: RenderSuggestLeafProps) {
  return (
    <span
      {...attributes}
      className={classNames({
        "underline decoration-2 underline-offset-1": leaf.highlight,
        "decoration-red-300": leaf.color === "red",
        "decoration-blue-light": leaf.color !== "red",
      })}
    >
      {children}
    </span>
  );
}

export default RenderSuggestLeaf;
