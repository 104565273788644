import TDForm from "@/components/forms/TopDownForm";
import {
  getIdTokenResult,
  IdTokenResult,
  signInWithCustomToken,
} from "firebase/auth";
import { useURLParams } from "@/hooks/useURLParams";
import { useState } from "react";
import { useMutation } from "react-query";
import { getTenantAuth } from "@/services/tenant";
import PageContainer from "../PageContainer";
import Panel from "../Panel";

export interface TestCustomTokenProps {}
async function validateToken(token: string) {
  const auth = getTenantAuth();
  const cred = await signInWithCustomToken(auth, token);
  return await getIdTokenResult(cred.user);
}
function TestCustomToken(props: TestCustomTokenProps) {
  const params = useURLParams();
  const [payload, setPayload] = useState<IdTokenResult | null>(null);
  const { mutateAsync } = useMutation(validateToken, {
    onSuccess: (payload) => setPayload(payload),
  });
  return (
    <PageContainer logo>
      <Panel>
        <TDForm
          className="space-y-6"
          defaultValues={{ token: params.get("token") || "" }}
          onSubmit={({ token }) => mutateAsync(token)}
        >
          {({ register }) => (
            <>
              <div>
                <h3 className="text-lg font-medium leading-6 text-gray-900">
                  Test hier je JWT-token
                </h3>
                <p className="text-sm text-gray-600">
                  Plak hieronder je eigengemaakte JWT en wij laten je zien hoe
                  we die interpreteren.
                </p>
              </div>
              <TDForm.Field
                label="Token"
                placeholder="JWT-token"
                type="text"
                {...register("token")}
              />
              <TDForm.Submit>Valideer</TDForm.Submit>
            </>
          )}
        </TDForm>
        <div className="prose mt-10">
          <div className="not-prose">
            <TDForm.Label>Payload:</TDForm.Label>
          </div>
          <TDForm.Field
            label="encounter"
            type="text"
            value={(payload?.claims["encounter"] as string) ?? ""}
          />
          <TDForm.Field
            label="patient"
            type="text"
            value={(payload?.claims["patient"] as string) ?? ""}
          />
          <pre>{payload && JSON.stringify(payload, undefined, 4)}</pre>
        </div>
      </Panel>
    </PageContainer>
  );
}

export default TestCustomToken;
