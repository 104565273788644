import CodingTooltip from "@/components/base/CodingCard/CodingTooltip";
import Tooltip from "@/components/info-cards/Tooltip";
import { IConcept } from "@/data-models/concept";
import { ICoding, IQuantity } from "@/data-models/value-models";
import { IRange } from "@/data-models/value-models/types";
import { useFeatureFlagEnabled } from "posthog-js/react";
import { Fragment } from "react";
import {
  Controller,
  FieldPath,
  FieldPathByValue,
  FieldValues,
  RegisterOptions,
  useFormContext,
  UseFormRegister,
} from "react-hook-form";
import { useTranslation } from "react-i18next";
import { is, literal, nullable, optional } from "superstruct";
import Badge from "../../Badge/Badge";
import StyledInput from "../../Badge/StyledInput";
import ObservationCode from "../ObservationCode";

const DECIMAL_TRANSFORMER = <TFieldValues extends FieldValues>(
  register: UseFormRegister<TFieldValues>,
) => {
  return <TFieldName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>>(
    name: TFieldName,
    options?: Omit<
      RegisterOptions<TFieldValues, TFieldName>,
      "valueAsDate" | "valueAsNumber" | "valueAsBoolean"
    >,
  ) => {
    return register(name, {
      ...options,
      onChange: (e) => {
        e.target.value = e.target.value.replace(/,/g, ".");
      },
    });
  };
};

const IS_EMPTY = ({ value }: { value: IQuantityComponentObservationValue }) =>
  is(value?.value?.value, nullable(optional(literal(""))));

export interface IQuantityComponentObservationValue {
  code?: IConcept;
  value: IQuantity<null>;
}

export interface IQuantityObservationValue {
  code?: IConcept;
  value: string;
  component?: IQuantityComponentObservationValue[];
}

interface QuantityObservationComponentProps {
  /** The measured quantity accompanied with codes. */
  code?: IConcept;
  /** The range of values that are allowed for the value of the observation. */
  absoluteRange?: IRange;
  /** The unit */
  unit?: ICoding;
}

export interface QuantityObservationInputGroupProps<
  TFieldValues extends FieldValues,
  TPath extends FieldPathByValue<
    TFieldValues,
    IQuantityObservationValue
  > = FieldPathByValue<TFieldValues, IQuantityObservationValue>,
> {
  name: TPath;
  /** The measured quantity accompanied with codes. */
  code?: IConcept;
  /** The components of this quantity */
  components: QuantityObservationComponentProps[];
  /** The unit of the value of the observation. */
  unit?: ICoding;
  /** The separator that visually seperate the quantity values of the components in the input */
  separator?: string;
  style?: "table" | "tile" | "default";
  active?: boolean;
  disabled?: boolean;
  hideLabel?: boolean;
}

function QuantityComponentCode({
  name,
  code,
}: {
  name: string;
  code?: IConcept;
}) {
  return <Controller name={name} defaultValue={code} render={() => <></>} />;
}

function QuantityObservationInputGroup<
  TFieldValues extends FieldValues = FieldValues,
  TPath extends FieldPathByValue<
    TFieldValues,
    IQuantityObservationValue
  > = FieldPathByValue<TFieldValues, IQuantityObservationValue>,
>({
  name,
  code,
  components,
  separator = "/",
  hideLabel,
  active,
  disabled,
  unit,
  style = "default",
}: QuantityObservationInputGroupProps<TFieldValues, TPath>) {
  const { t } = useTranslation();
  const { register, getFieldState, formState, getValues } =
    useFormContext<TFieldValues>();
  const obsValueFieldPath = `${name}.value` as FieldPathByValue<
    TFieldValues,
    IQuantityObservationValue["value"]
  >;

  const showCodingCardTooltip = useFeatureFlagEnabled(
    "show-coding-card-tooltip",
  );

  const isInvalid = !!getFieldState(obsValueFieldPath, formState).error;
  const getComponentQuantityPath = (i: number) =>
    `${name}.component.${i}.value` as FieldPathByValue<
      TFieldValues,
      IQuantityComponentObservationValue["value"]["value"]
    >;
  const getComponentQuantityValuePath = (i: number) =>
    `${getComponentQuantityPath(i)}.value` as FieldPathByValue<
      TFieldValues,
      IQuantityComponentObservationValue["value"]["value"]
    >;
  const getComponentQuantityUnitPath = (i: number) =>
    `${getComponentQuantityPath(i)}.unit` as FieldPathByValue<
      TFieldValues,
      IQuantityComponentObservationValue["value"]["unit"]
    >;
  const getComponentQuantityCodePath = (i: number) =>
    `${getComponentQuantityPath(i)}.code` as FieldPathByValue<
      TFieldValues,
      IQuantityComponentObservationValue["value"]["code"]
    >;
  const getComponentQuantitySystemPath = (i: number) =>
    `${getComponentQuantityPath(i)}.system` as FieldPathByValue<
      TFieldValues,
      IQuantityComponentObservationValue["value"]["system"]
    >;
  const getComponentCodePath = (i: number) =>
    `${name}.component.${i}.code` as FieldPathByValue<
      TFieldValues,
      IQuantityComponentObservationValue["code"]
    >;
  const lastComponentIndex = components.length - 1;
  return (
    <Badge
      name={name}
      style={style}
      isEmptyFn={({ value }: { value: IQuantityObservationValue }) =>
        !value?.component ||
        !!value.component?.some((value) => IS_EMPTY({ value }))
      }
    >
      <Badge.Editor
        active={active}
        prefix={
          code && (
            <ObservationCode
              name={`${name}.code`}
              code={code}
              hidden={hideLabel}
            />
          )
        }
        suffix={unit?.display}
      >
        {({ autoFocus }) => (
          <>
            {components.map((c, index) => (
              <Fragment key={index}>
                <QuantityComponentCode
                  name={getComponentCodePath(index)}
                  code={c.code}
                />
                <StyledInput
                  type="text"
                  inputMode="decimal"
                  disabled={disabled}
                  autoFocus={autoFocus && index == 0}
                  invalid={isInvalid}
                  {...DECIMAL_TRANSFORMER(register)<any>(
                    getComponentQuantityValuePath(index),
                    {
                      pattern: {
                        value: /^(-?\d+\.?\d*)?$/g,
                        message: t("invalidNumber"),
                      },
                      max: c.absoluteRange?.high?.value && {
                        value: c.absoluteRange?.high?.value,
                        message: `Waarde moet kleiner zijn dan ${c.absoluteRange?.high?.value} ${c.absoluteRange?.high?.unit}`,
                      },
                      min: c.absoluteRange?.low?.value && {
                        value: c.absoluteRange?.low?.value,
                        message: `Waarde moet groter zijn dan ${c.absoluteRange?.low?.value} ${c.absoluteRange?.low?.unit}`,
                      },
                      disabled,
                    },
                  )}
                  size={10}
                />
                <input
                  type="hidden"
                  {...register(getComponentQuantityUnitPath(index))}
                  defaultValue={c.unit?.display}
                />
                <input
                  type="hidden"
                  {...register(getComponentQuantityCodePath(index))}
                  defaultValue={c.unit?.code}
                />
                <input
                  type="hidden"
                  {...register(getComponentQuantitySystemPath(index))}
                  defaultValue={c.unit?.system}
                />
                {components.length != index + 1 && (
                  <Badge.Editor.Fix>{separator}</Badge.Editor.Fix>
                )}
                {!unit && c.unit?.display && (
                  <Badge.Viewer.Fix>{c.unit.display}</Badge.Viewer.Fix>
                )}
              </Fragment>
            ))}
          </>
        )}
      </Badge.Editor>
      <Badge.Viewer
        prefix={
          code && (
            <ObservationCode
              name={`${name}.code`}
              code={code}
              hidden={hideLabel}
            />
          )
        }
        suffix={unit?.display}
      >
        {() =>
          components.map((c, index) => (
            <span key={index}>
              {getValues(getComponentQuantityValuePath(index))}
              {components.length != index + 1 && (
                <Badge.Viewer.Fix>{separator}</Badge.Viewer.Fix>
              )}
              {!unit && c.unit?.display && (
                <Badge.Viewer.Fix className="mx-1">
                  <Tooltip enabled={showCodingCardTooltip}>
                    <Tooltip.Trigger>
                      <span>{c.unit.display}</span>
                    </Tooltip.Trigger>
                    <Tooltip.Content className="fill-gray-lighter">
                      <CodingTooltip coding={c.unit} />
                    </Tooltip.Content>
                  </Tooltip>
                </Badge.Viewer.Fix>
              )}
            </span>
          ))
        }
      </Badge.Viewer>
    </Badge>
  );
}

export default QuantityObservationInputGroup;
