import { BaseElement, Element } from "slate";
import { RenderElementProps } from "slate-react";

export interface ITypedElement extends BaseElement {
  readonly type: string;
}

export const TypedElement = {
  ...Element,
  isTypedElement: (o: any): o is ITypedElement =>
    TypedElement.isElement(o) && "type" in o,
};

export interface RenderTypedElementProps extends RenderElementProps {
  element: ITypedElement;
}
