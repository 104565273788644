import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { useEffect } from "react";

export function ReadOnlyPlugin({ readOnly }: { readOnly?: boolean }) {
  const [editor] = useLexicalComposerContext();

  useEffect(() => {
    if (readOnly) {
      editor.setEditable(false);
    } else {
      editor.setEditable(true);
    }
  }, [readOnly, editor]);
  return null;
}
