import classNames from "classnames";
import React, { HTMLAttributes } from "react";

interface PageContainerProps
  extends React.PropsWithChildren<HTMLAttributes<HTMLDivElement>> {
  wide?: boolean;
}

export const PageContainer = ({
  children,
  className,
  wide = false,
  ...attrs
}: PageContainerProps) => (
  <div className={classNames(className)} {...attrs}>
    <div
      className={classNames("mx-auto h-full w-full", {
        "max-w-7xl px-4 pt-8 sm:px-6 lg:px-8": !wide,
      })}
    >
      <div
        className={classNames("relative mx-auto h-full", {
          "lg:max-w-4xl xl:max-w-7xl": !wide,
        })}
      >
        {children}
      </div>
    </div>
  </div>
);
