import { TiroPatientReference } from "@/data-models/value-models/reference";
import { QueryClient } from "react-query";
import reportClient, { BlockIn, Encounter } from "./reports/client";
import { createTemplateReportQuery } from "./useCreateReportFromTemplate";

type CreateReportFromTemplateOptions = {
  queryClient: QueryClient;
  createReportFn: typeof reportClient.v1.createReportV1;
  createTemplateReportQuery: typeof createTemplateReportQuery;
  encounter?: Encounter;
  subject: TiroPatientReference;
};

export default async function createReportFromTemplate(
  templateId: string,
  options: CreateReportFromTemplateOptions,
) {
  console.debug("Fetching template and creating report");
  const {
    queryClient,
    createReportFn,
    createTemplateReportQuery,
    subject,
    encounter,
  } = options;
  const template = await queryClient.fetchQuery(
    createTemplateReportQuery(templateId),
  );
  const report = await createReportFn({
    requestBody: {
      encounter,
      subject,
      template: templateId,
      title: template.title,
      // TODO filter 'richt-text-with-questionnaire' server side
      // @ts-expect-error
      blocks: template.blocks.map(
        (block) => ({
          ...block,
          title: block.title || block.blockType,
          renderType: block.renderType,
          autoPopulateWithPreviousResponse:
            block.autoPopulateWithPreviousResponse,
        }), // FIXME: make sure title is defined in content engine
      ),
    },
  });
  return report;
}
