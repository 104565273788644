import {
  CheckCircleIcon,
  QuestionMarkCircleIcon,
  XCircleIcon,
} from "@heroicons/react/20/solid";
import classNames from "classnames";
import React, { HTMLAttributes, useCallback } from "react";
import { Editor, Element, Location, Node, Path, Transforms } from "slate";
import { ReactEditor, useSlateStatic } from "slate-react";
import { IStatefullElement, State, STATES } from "../Entities/types";
import { EntryElement } from "./EntryElement";

function isDescendantNodeOfCollectionRootPath(
  editor: ReactEditor,
  descendant: Node,
  path: Path
) {
  /**
   * Checks if given path is the root of the collection
   * Checks if given node is a descendant of that collection
   */

  const collectionPath = path.length > 1 && Path.parent(path);
  const isCollection =
    collectionPath &&
    EntryElement.isCollection(Editor.node(editor, collectionPath)[0]);
  if (!isCollection) return false;
  const isRoot = isCollection && path[path.length - 1] === 0;
  const nodePath = ReactEditor.findPath(editor, descendant);
  return (
    isRoot &&
    Path.isPath(collectionPath) &&
    Path.isDescendant(nodePath, collectionPath)
  );
}

function isRootNodeOfAncestorCollection(
  editor: ReactEditor,
  root: Node,
  path: Path
) {
  /**
   * Check if given node is the root of the collection
   * Checks if given path is a descendant path of that collection
   */
  const rootPath = ReactEditor.findPath(editor, root);
  const collectionPath = rootPath.length > 1 && Path.parent(rootPath);
  const isCollection =
    collectionPath &&
    EntryElement.isCollection(Editor.node(editor, collectionPath)[0]);
  if (!isCollection) return false;
  const isRoot = rootPath[rootPath.length - 1] === 0 && isCollection;
  return (
    isRoot &&
    Path.isPath(collectionPath) &&
    Path.isDescendant(path, collectionPath)
  );
}

export const TripleStateButton = <T extends IStatefullElement>({
  element,
  propagate,
  className,
  children,
  ...attributes
}: {
  element: T;
  propagate?: Location;
} & HTMLAttributes<HTMLButtonElement>) => {
  const { state } = element;
  const editor = useSlateStatic() as ReactEditor;
  const path = ReactEditor.findPath(editor, element);
  const onChange = useCallback(() => {
    const newState =
      STATES[(STATES.findIndex((k) => k === state) + 1) % STATES.length];
    const newProperties: Partial<{ state: State } & Element> = {
      state: newState,
    };
    let match = (n: Node) => "state" in n;
    if (newState == "present")
      match = (n: Node) =>
        (n === element || isRootNodeOfAncestorCollection(editor, n, path)) &&
        "state" in n;
    if (newState === "absent")
      match = (n: Node) =>
        (n === element ||
          isDescendantNodeOfCollectionRootPath(editor, n, path)) &&
        "state" in n;
    if (newState === "unknown")
      match = (n: Node) =>
        (n === element ||
          isDescendantNodeOfCollectionRootPath(editor, n, path)) &&
        "state" in n;

    Transforms.setNodes(editor, newProperties, {
      at: propagate ?? path,
      match,
      mode: "all",
    });
  }, [path, editor, element, propagate, state]);
  return (
    <button
      type="button"
      disabled={!state}
      tabIndex={-1}
      className={classNames(
        "flex items-baseline focus:outline-none",
        className,
        {
          invisible: !state,
          "invisible current-block-focus-within:visible current-block-hover:visible":
            state === "unknown",
        }
      )}
      onClick={onChange}
      {...attributes}
    >
      {state === "present" && (
        <CheckCircleIcon className="relative h-5 w-5 fill-current p-0 text-green-600" />
      )}
      {state === "absent" && (
        <XCircleIcon className="relative h-5 w-5 fill-current p-0 text-gray-400" />
      )}
      {state === "unknown" && (
        <QuestionMarkCircleIcon className="relative h-5 w-5 fill-current p-0 text-gray-light" />
      )}
      {children}
    </button>
  );
};
