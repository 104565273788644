import { CONFIG } from "@/config";
import { getAuth, OAuthProvider } from "firebase/auth";
import { app } from "./firebase";

export function getTenantAuth() {
  const auth = getAuth(app);
  if (CONFIG.isDemo) {
    auth.tenantId = "demo-e39ly";
  } else {
    auth.tenantId = null;
  }
  return auth;
}

export const ISS_TO_PROVIDER = {
  "https://launch.smarthealthit.org/v/r4/fhir": () =>
    new OAuthProvider("oidc.smart-launcher"),
  "https://fhir-ehr-code.cerner.com/r4/ec2458f2-1e24-41c8-b71b-0e701af7583d":
    () => new OAuthProvider("oidc.oidc.cerner-sandbox"),
  "https://8a15-46-18-32-241.ngrok-free.app/oauth2": () =>
    new OAuthProvider("oidc.intersystems-demo"),
} as const;

export type ISS = keyof typeof ISS_TO_PROVIDER;
