/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { models__api__identifier_token__Identifier_Input } from './models__api__identifier_token__Identifier_Input';

export type EncounterIdentifier = {
    reference?: (string | null);
    type?: EncounterIdentifier.type;
    identifier: models__api__identifier_token__Identifier_Input;
    display?: (string | null);
};

export namespace EncounterIdentifier {

    export enum type {
        ENCOUNTER = 'Encounter',
    }


}

