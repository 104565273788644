import classNames from "classnames";
import { RenderElementProps, useFocused, useSelected } from "slate-react";
import { QAEntryAnswer } from "./types";

export interface RenderAnswerElementProps extends RenderElementProps {
  element: QAEntryAnswer;
}
export const RenderAnswerElement = ({
  attributes,
  children,
}: RenderAnswerElementProps) => {
  const selected = useSelected();
  const focused = useFocused();
  return (
    <div
      className={classNames(
        "mr-8  rounded-sm px-2 current-entry-focus-within:focus-within:bg-gray-800/5 current-entry-hover:bg-gray-400/5 current-entry-hover:hover:bg-gray-800/5",
        { "bg-gray-800/5": selected && focused }
      )}
      {...attributes}
    >
      {children}
    </div>
  );
};
