import { RouteObject } from "react-router";
import { SMART_PATH } from "./consts";
import createRedirectRoute from "./redirect/createRoute";
import createLaunchRoute from "./launch/createRoute";
import createHandlerRoute from "./handler/createRoute";

export default (): RouteObject => ({
  path: SMART_PATH,
  children: [createLaunchRoute(), createRedirectRoute(), createHandlerRoute()],
});
