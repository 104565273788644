import { useLoaderData } from "react-router";
import { LoaderData } from "./loader";

function SMARTLaunchData() {
  const { action, defaultValues, urlParams } = useLoaderData() as LoaderData;
  return (
    <main className="w-full overflow-y-scroll p-10">
      <h2 className="text-2xl font-medium text-gray-800">
        SMART on FHIR Launch
      </h2>
      <section className="flex gap-4">
        <div className="prose">
          <h3 className="text-xl font-medium text-gray-800">
            SMART Server configuration
          </h3>
          <pre>{JSON.stringify(urlParams, null, 2)}</pre>
        </div>
        <form
          className="relative grow border-l-2 border-gray-400 px-2"
          method="GET"
          action={action}
        >
          <h3 className="text-xl font-medium text-gray-800">
            Launch SMART App
          </h3>
          <label className="block w-full">
            <code>response_type</code>
            <input
              type="text"
              name="response_type"
              className="block w-full"
              defaultValue={defaultValues.response_type}
            />
          </label>
          <label className="block w-full">
            <code>client_id</code>
            <input
              type="text"
              className="block w-full"
              name="client_id"
              defaultValue={defaultValues.client_id}
            />
          </label>
          <label className="block w-full">
            <code>client_secret</code>
            <input
              type="text"
              name="client_secret"
              className="block w-full disabled:bg-gray-200 disabled:opacity-50"
              disabled={!defaultValues.client_secret}
              defaultValue={defaultValues.client_secret}
            />
          </label>
          <label className="block w-full">
            <code>redirect_uri</code>
            <input
              type="text"
              name="redirect_uri"
              className="block w-full"
              defaultValue={defaultValues.redirect_uri}
            />
          </label>
          <label className="block w-full">
            <code>launch</code>
            <input
              type="text"
              name="launch"
              className="block w-full"
              defaultValue={defaultValues.launch}
            />
          </label>
          <label className="block w-full">
            <code>scope</code>
            <input
              type="text"
              name="scope"
              className="block w-full"
              defaultValue={defaultValues.scope}
            />
          </label>
          <label className="block w-full">
            <code>state</code>
            <input
              type="text"
              name="state"
              className="block w-full"
              defaultValue={defaultValues.state}
            />
          </label>
          <label className="block w-full">
            <code>aud</code>
            <input
              type="text"
              name="aud"
              className="block w-full"
              defaultValue={defaultValues.aud}
            />
          </label>
          <label className="block w-full">
            <code>code_challenge</code>
            <input
              type="text"
              name="code_challenge"
              className="block w-full"
              defaultValue={defaultValues.code_challenge}
            />
          </label>
          <label className="block w-full">
            <code>code_challenge_method</code>
            <input
              type="text"
              name="code_challenge_method"
              className="block w-full"
              defaultValue={defaultValues.code_challenge_method}
            />
          </label>
          <button
            type="submit"
            className="mt-2 rounded-md border border-gray-200 p-2 "
          >
            Launch SMART App
          </button>
        </form>
      </section>
    </main>
  );
}

export default SMARTLaunchData;
