import React from "react";
import { useFormContext } from "react-hook-form";
import useValueSets from "@/services/content/useValueSets";

function VersionSelect({
  name,
  url,
  className = "mt-2",
}: {
  url?: string;
  name: string;
  className?: string;
}) {
  const { register } = useFormContext();
  const { data } = useValueSets({ url }, { enabled: !!url });
  return (
    <div className={className}>
      <label className="block text-sm font-medium leading-6 text-gray-700">
        Version
      </label>
      <div className="mt-1">
        <select
          {...register(name, { disabled: !url })}
          className="block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-blue-600 focus:outline-none focus:ring-blue-600 disabled:bg-gray-50 sm:text-sm"
        >
          <option value="latest">Latest</option>
          {data?.map((vs) => (
            <option key={vs.id} value={vs.version}>
              {vs.version}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
}

export default VersionSelect;
