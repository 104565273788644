import React, { useMemo } from "react";
import { IQuestionItem } from "..";
import { IQuestionGroup } from "../models";
import createUniqueQuestionKey from "./createUniqueQuestionKey";

type WrapperProps<TQuestion extends IQuestionItem | IQuestionGroup> = {
  parentPath: string[];
  parentIndexPath: number[];
  pathKey: string;
  index: number;
  question: TQuestion;
  children: (props: {
    key: string;
    path: string[];
    indexPath: number[];
    question: TQuestion;
    index: number;
  }) => JSX.Element;
};

function Wrapper<TQuestion extends IQuestionItem | IQuestionGroup>({
  parentPath,
  parentIndexPath,
  pathKey: key,
  children,
  question,
  index,
}: WrapperProps<TQuestion>) {
  const path = useMemo(() => [...parentPath, key], [parentPath, key]);
  const indexPath = useMemo(
    () => [...parentIndexPath, index],
    [parentIndexPath, index],
  );
  return children({ key, path, indexPath, question, index });
}
export interface IterateQuestionsProps<
  TQuestion extends IQuestionItem | IQuestionGroup,
> {
  questions: TQuestion[];
  path: string[];
  indexPath: number[];
  children: (props: {
    key: string;
    path: string[];
    indexPath: number[];
    question: TQuestion;
    index: number;
  }) => JSX.Element;
}

export function IterateQuestions<
  TQuestion extends IQuestionItem | IQuestionGroup,
>({ questions, path, indexPath, children }: IterateQuestionsProps<TQuestion>) {
  return (
    <>
      {questions.map((question, index) => {
        const key = createUniqueQuestionKey(question);
        return (
          <Wrapper
            key={key}
            pathKey={key}
            parentPath={path}
            parentIndexPath={indexPath}
            question={question}
            index={index}
          >
            {children}
          </Wrapper>
        );
      })}
    </>
  );
}
