import classNames from "classnames";
import React, { useContext } from "react";
import { FieldError, useFormContext } from "react-hook-form";
import { Describe, enums, func, is, string, type } from "superstruct";
import handleBlur from "@/util/handleBlur";
import {
  BadgeStyleContext,
  BadgeModeContext,
  BadgeSwitchModeContext,
  BadgeAutoFocusContext,
  BadgeNameContext,
  BadgeColorContext,
} from "./context";

type PickAttrs = "prefix" | "suffix";
interface BadgeAttrs {
  disabled?: boolean;
  active?: boolean;
  prefix?: React.ReactChild | null; // string, int, or component
  suffix?: React.ReactChild | null;
}
type EditBadgeChildren =
  | React.ReactNode
  | ((props: {
      mode: "edit" | "view";
      style: "tile" | "table" | "default";
      autoFocus: boolean;
    }) => React.ReactNode);
export type EditBadgeProps = BadgeAttrs &
  Omit<React.HTMLAttributes<HTMLSpanElement>, PickAttrs> & {
    children?: EditBadgeChildren;
  };

const hasMessage: () => Describe<{ message: string }> = () =>
  type({ message: string() });
const FIELD_ERROR_KEYWORDS: (keyof FieldError)[] = [
  "message",
  "ref",
  "root",
  "type",
  "types",
];
const isKeyword = (key: string): key is keyof FieldError =>
  is(key, enums(FIELD_ERROR_KEYWORDS));
function getErrorMessages(error: FieldError | Record<string, any>): string[] {
  const initialValue = is(error, hasMessage()) ? [error.message] : [];
  return Object.entries(error).reduce((prev, [key, value]) => {
    return !isKeyword(key) && is(value, hasMessage())
      ? [...prev, value.message]
      : !isKeyword(key)
      ? getErrorMessages(value)
      : prev;
  }, initialValue);
}

export const EditBadgeFix = ({
  className,
  ...props
}: React.HTMLProps<HTMLSpanElement>) => (
  <span className={classNames("mx-1 inline-flex", className)} {...props} />
);

function EditBadge({
  prefix,
  suffix,
  active,
  children,
  disabled,
  className,
  ...attributes
}: EditBadgeProps) {
  const name = useContext(BadgeNameContext);
  const style = useContext(BadgeStyleContext);
  const color = useContext(BadgeColorContext);
  const mode = useContext(BadgeModeContext);
  const autoFocus = useContext(BadgeAutoFocusContext);
  const switchMode = useContext(BadgeSwitchModeContext);
  const { getFieldState, formState } = useFormContext();
  if (!name)
    throw new Error("Badge must be used inside a BadgeNameContext.Provider");
  if (!switchMode)
    throw new Error(
      "Badge must be used inside a BadgeSwitchModeContext.Provider"
    );
  if (mode == "view") return null;
  const error = getFieldState(name, formState)?.error;
  return (
    <div className="inline-block">
      <div
        onBlur={handleBlur({
          onFocusOutside: () => switchMode("view"),
          onFocusNull: () => switchMode("view"),
        })}
        data-testid="edit-badge"
        className={classNames(
          className,
          "mr-1 flex items-baseline rounded-[0.25rem] px-1 py-0.5 text-sm",
          {
            "bg-slate-200  text-gray-dark": !disabled,
            "bg-slate-100 text-gray-light": disabled,
            "h-full w-full": style === "table",
            "ring-2 ring-blue-light": !!active && color == "blue",
            "ring-2 ring-emerald-200": !!active && color == "green",
          }
        )}
        {...attributes}
      >
        {prefix}
        {is(children, func()) ? children({ mode, style, autoFocus }) : children}
        {suffix}
      </div>
      {error &&
        getErrorMessages(error).map((message, i) => (
          <p
            key={`${message}-${i}`}
            className="w-min min-w-full text-xs text-red-600"
          >
            {message}
          </p>
        ))}
    </div>
  );
}

EditBadge.Fix = EditBadgeFix;

export default EditBadge;
