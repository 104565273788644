import { PlusIcon } from "@heroicons/react/24/outline";
import React from "react";
import Cells from "./cells/Cells";
import { Column, TableInstance } from "./types";

import Rows from "./Rows";
import useTable from "./useTable";
import Form from "./Form";
import useFocusableBlockContent, {
  FocusableBlockContent,
} from "@/components/blocks/content/useFocusableBlockContent";
import ReportButton from "@/components/base/ReportButton";
import classNames from "classnames";
import PrefixNameContext from "@/editor/utils/PrefixName";

export interface TableProps {
  table?: TableInstance;
  onTableChange?: (values: TableInstance) => void;
}

const TableWithoutRef = (
  { table: defaultTable, onTableChange }: TableProps,
  ref?: React.Ref<FocusableBlockContent>,
) => {
  const { table, gridTemplate, rowTemplates } = useTable({
    defaultTable,
  });

  // if this appears to be slow, put in a useMemo
  const gridTemplateColumns = "50px " + gridTemplate;
  const gridStyle = { gridTemplateColumns };
  const containerRef = useFocusableBlockContent(ref);
  return (
    <div
      ref={containerRef}
      data-title="container"
      className="overflow-x-scroll"
    >
      <Form defaultValues={table} onChange={onTableChange}>
        {({ fields, move, remove, append }) => (
          <>
            <div
              className={`grid min-w-max auto-rows-[minmax(40px,_auto)] items-stretch overflow-x-auto rounded-md border border-gray-light`}
              style={gridStyle}
            >
              <div className="h-full border-b border-r border-gray-light"></div>
              {table.columns.map((column: Column, index) => (
                <div
                  key={column.id}
                  className={classNames(
                    "flex w-full items-center border-b border-gray-light px-2",
                    {
                      "border-r": table.columns.length !== index + 1,
                      "border-r-0": table.columns.length === index + 1,
                    },
                  )}
                >
                  <h3 className="text-sm font-medium">{column.code.text}</h3>
                </div>
              ))}
              {fields.map((row, rowIndex) => (
                <PrefixNameContext.Provider
                  key={row.id}
                  value={`data.${rowIndex}`}
                >
                  <Rows rowIndex={rowIndex} moveRow={move} removeRow={remove}>
                    {table.columns.map((column: Column) => {
                      return (
                        <div
                          key={column.id}
                          className="group-last:border-b-5 flex items-center border-b border-r border-gray-light text-sm last:border-r-0"
                        >
                          <Cells
                            name={`data.${rowIndex}.${column.id}`}
                            type={column.type}
                            column={column}
                          />
                        </div>
                      );
                    })}
                  </Rows>
                </PrefixNameContext.Provider>
              ))}
            </div>
            <div className="mt-2 flex w-full">
              {rowTemplates.map(({ name, row }, index) => (
                <ReportButton
                  key={index}
                  onClick={() => append(row)}
                  positionIcon="left"
                  Icon={
                    <PlusIcon className="inline h-4 w-4 text-gray-primary" />
                  }
                >
                  {name ? `Voeg '${name}' toe` : "Voeg rij toe"}
                </ReportButton>
              ))}
            </div>
          </>
        )}
      </Form>
    </div>
  );
};
const Table = React.forwardRef(TableWithoutRef);
export default Table;
