import { useField, useFormikContext } from "formik";
import debounce from "lodash/debounce";
import React, { useContext, useEffect, useState } from "react";

type Size = [number, number];
const ColumnSplitSizes = React.createContext<
  [Size, (value: Size) => void] | null
>(null);

export const ColumnSplitSizesManager = ({
  children,
}: React.PropsWithChildren<{}>) => {
  const { submitForm } = useFormikContext();
  const [{ value }, , { setValue }] = useField<[number, number]>("columnWidth");
  useEffect(() => {
    debounce(submitForm, 400);
  }, [value, submitForm]);
  return (
    <ColumnSplitSizes.Provider value={[value ?? [20, 80], setValue]}>
      {children}
    </ColumnSplitSizes.Provider>
  );
};

export const useColumnSplitSizes = () => {
  let context = useContext(ColumnSplitSizes);
  const localState = useState<[number, number]>([20, 80]);
  if (!context) {
    context = localState;
  }
  return context;
};
