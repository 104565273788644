import React, {
  forwardRef,
  HTMLAttributes,
  SetStateAction,
  useCallback,
} from "react";
import QuestionAnswerComposer from "./QuestionAnswer/QuestionAnswerComposer";
import useQuestionnaireController, {
  UseQuestionnaireControllerProps,
} from "./useQuestionnaireController";
import QuestionnaireLayout from "./Layout";
import QALayout, { QuestionAnswerStyleProvider } from "./QuestionAnswer/Layout";
import SortableTree from "./SortableTree/SortableTree";
import { ActionTypes, RecursiveQuestionNode } from "./models";
import { IterateQuestions } from "./QuestionAnswer/IterateQuestions";
import AddQuestionButton from "./add-question/AddQuestionButton";
import createUniqueQuestionKey from "./QuestionAnswer/createUniqueQuestionKey";
export interface QuestionnaireComposerProps
  extends HTMLAttributes<HTMLDivElement>,
    UseQuestionnaireControllerProps {
  orientation?: "vertical" | "horizontal";
}

const ROOT_PATH: string[] = [];
const ROOT_INDEX_PATH: number[] = [];
function QuestionnaireComposerWithoutRef(
  {
    questions: providedQuestions,
    defaultQuestions,
    dispatch: providedDispatch,
    onQuestionsChange,
    orientation = "vertical",
    className,
    ...attributes
  }: QuestionnaireComposerProps,
  ref?: React.Ref<HTMLDivElement>
) {
  const [questions, dispatch] = useQuestionnaireController({
    questions: providedQuestions,
    dispatch: providedDispatch,
    defaultQuestions,
    onQuestionsChange,
  });
  console.log("Number of provided questions:", providedQuestions?.length);
  console.log("Number of questions: ", questions.length);

  const handleItemsChange = useCallback(
    (
      updater: SetStateAction<RecursiveQuestionNode<typeof questions[number]>[]>
    ) => dispatch({ type: ActionTypes.UPDATE_QUESTIONS, updater }),
    [dispatch]
  );

  return (
    <QuestionnaireLayout.Container
      ref={ref}
      className={className}
      {...attributes}
    >
      <QuestionAnswerStyleProvider value={{ orientation }}>
        <QuestionnaireLayout.Body>
          <SortableTree.Manager
            indentationWidth={1}
            items={questions}
            getItemId={createUniqueQuestionKey}
            onItemsChange={handleItemsChange}
          >
            <IterateQuestions
              questions={questions}
              path={ROOT_PATH}
              indexPath={ROOT_INDEX_PATH}
            >
              {({ question, ...props }) =>
                question.repeating ? (
                  <QuestionAnswerComposer
                    dispatch={dispatch}
                    question={question.template}
                    {...props}
                  />
                ) : (
                  <QuestionAnswerComposer
                    dispatch={dispatch}
                    question={question}
                    {...props}
                  />
                )
              }
            </IterateQuestions>
            <SortableTree.DragOverlay>
              {({ activeQuestion }) => (
                <QALayout.Container>
                  <QALayout.QuestionContainer>
                    <QALayout.Toolbar className="bg-white/80">
                      <QALayout.DragHandle visible isDragging />
                    </QALayout.Toolbar>
                    <QALayout.Question
                      code={activeQuestion.code}
                      className="bg-white/80 opacity-50"
                    />
                  </QALayout.QuestionContainer>
                </QALayout.Container>
              )}
            </SortableTree.DragOverlay>
          </SortableTree.Manager>
          <div>
            <AddQuestionButton
              onAddQuestion={(question) => {
                dispatch({
                  type: ActionTypes.ADD_QUESTION,
                  question,
                  path: [questions.length],
                });
              }}
            />
          </div>
        </QuestionnaireLayout.Body>
      </QuestionAnswerStyleProvider>
    </QuestionnaireLayout.Container>
  );
}
const QuestionnaireComposer = forwardRef(QuestionnaireComposerWithoutRef);
export default QuestionnaireComposer;
