import { createSuggestionEditor } from "@/editor/legacy/Suggestions/SuggestEditor";
import { IAnswerEditor } from "./types";
import AttachmentPlugin from "@/editor/AttachmentPlugin";
import { IEditorPlugin } from "@/editor/Base/types";
import CalculatorPlugin from "@/editor/CalculatorPlugin";
import CheckPlugin, { withCheck } from "@/editor/legacy/CheckPlugin";
import BadgePlugin from "@/editor/legacy/BadgePlugin";
import DropdownPlugin from "@/editor/legacy/DropdownPlugin";
import withEntities from "@/editor/legacy/Entities/WithEntities";
import InlineEntityPlugin from "@/editor/legacy/Entities/InlineEntityPlugin";
import ToggleButtonPlugin from "@/editor/legacy/ToggleButtonPlugin";
import withToggles from "@/editor/legacy/ToggleButtonPlugin/withToggles";
import withInlineVoid from "@/editor/withInlineVoid";
import { SuggestPlugin } from "@/editor/legacy/Suggestions";
import { withHistory } from "slate-history";
import { pipe } from "@/util/pipe";
import MergeTagPlugin from "@/editor/legacy/MergeTagPlugin";
const PLUGINS: IEditorPlugin[] = [
  CheckPlugin,
  MergeTagPlugin,
  InlineEntityPlugin,
  CalculatorPlugin,
  BadgePlugin,
  ToggleButtonPlugin,
  DropdownPlugin,
  AttachmentPlugin,
  SuggestPlugin,
];

const constructAnswerEditor = (
  options: {
    mute?: boolean;
  } = {},
): IAnswerEditor => {
  const baseEditor = createSuggestionEditor(PLUGINS, options);
  const mixins = [
    withHistory,
    withInlineVoid(),
    withCheck,
    withEntities,
    withToggles,
  ];
  const fullEditor = pipe(...mixins)(baseEditor);
  return fullEditor;
};

export default constructAnswerEditor;
