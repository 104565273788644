import { User } from "firebase/auth";
import { useQuery } from "react-query";
import { currentUserQuery } from "./useAuthUser";

export const isAuthenticatedQuery = {
  ...currentUserQuery,
  initialData: () => currentUserQuery.initialData?.(),
  select: (user: User | null) => !!user,
};
export type IsAuthenticatedQuery = Pick<
  typeof isAuthenticatedQuery,
  "queryFn" | "queryKey" | "select"
>;

function useIsAuthenticated() {
  return useQuery(isAuthenticatedQuery);
}
export default useIsAuthenticated;
