import { useEffect, useState } from "react";
import { useMutation, UseMutationOptions } from "react-query";
const sleep = (ms: number) => new Promise((r) => setTimeout(r, ms));

const SAVE_FUNCS = new Set<() => void>();
const unsubscribe = (saveFn: () => void) => {
  SAVE_FUNCS.delete(saveFn);
};
export const subscribeSaveFn = (saveFn: () => void) => {
  SAVE_FUNCS.add(saveFn);
  return () => unsubscribe(saveFn);
};
export const saveAll = () => {
  SAVE_FUNCS.forEach((saveFn) => saveFn());
};

export const saveAllAsync = async () => {
  return new Promise<void>((resolve, reject) => {
    try {
      SAVE_FUNCS.forEach((saveFn) => saveFn());
      sleep(500).then(() => resolve());
    } catch (e) {
      reject(e);
    }
  });
};

export const useSaveAll = (options: UseMutationOptions = {}) => {
  const [isSuccess, setIsSuccess] = useState(false);
  return {
    ...useMutation(saveAllAsync, {
      ...options,
      onSuccess: () => {
        setIsSuccess(true);
        setTimeout(() => setIsSuccess(false), 2000);
      },
    }),
    isSuccess,
  };
};

/**
 * Register a save function to be called when the user presses ctrl+s or clicks on the save button
 * @param save
 */
export function useRegisterSaveAll(save: () => void) {
  useEffect(() => {
    const unsubscribe = subscribeSaveFn(save);
    return unsubscribe;
  }, [save]);
}
