/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BaseHttpRequest } from "./core/BaseHttpRequest";
import type { OpenAPIConfig } from "./core/OpenAPI";
import { FetchHttpRequest } from "./core/FetchHttpRequest";

import { BlocksService } from "./services/BlocksService";
import { DefaultService } from "./services/DefaultService";
import { EventsService } from "./services/EventsService";
import { R5Service } from "./services/R5Service";
import { TemplatesService } from "./services/TemplatesService";
import { V1Service } from "./services/V1Service";
import { V2Service } from "./services/V2Service";

type HttpRequestConstructor = new (config: OpenAPIConfig) => BaseHttpRequest;

export class ContentClient {
  public readonly blocks: BlocksService;
  public readonly default: DefaultService;
  public readonly events: EventsService;
  public readonly r5: R5Service;
  public readonly templates: TemplatesService;
  public readonly v1: V1Service;
  public readonly v2: V2Service;

  public readonly request: BaseHttpRequest;

  constructor(
    config?: Partial<OpenAPIConfig>,
    HttpRequest: HttpRequestConstructor = FetchHttpRequest,
  ) {
    this.request = new HttpRequest({
      BASE: config?.BASE ?? "",
      VERSION: config?.VERSION ?? "0.1.0",
      WITH_CREDENTIALS: config?.WITH_CREDENTIALS ?? false,
      CREDENTIALS: config?.CREDENTIALS ?? "include",
      TOKEN: config?.TOKEN,
      USERNAME: config?.USERNAME,
      PASSWORD: config?.PASSWORD,
      HEADERS: config?.HEADERS,
      ENCODE_PATH: config?.ENCODE_PATH,
    });

    this.blocks = new BlocksService(this.request);
    this.default = new DefaultService(this.request);
    this.events = new EventsService(this.request);
    this.r5 = new R5Service(this.request);
    this.templates = new TemplatesService(this.request);
    this.v1 = new V1Service(this.request);
    this.v2 = new V2Service(this.request);
  }
}
