import { useController } from "react-hook-form";
import CommentsEditor from "./CommentsEditor";
type CommentsInputProps = {
  name: string;
  disabled?: boolean;
  active?: boolean;
};
export default function CommentsInput({
  name,
  disabled,
  active,
}: CommentsInputProps) {
  const { field } = useController({ name });
  return (
    <CommentsEditor
      ref={field.ref}
      value={field.value}
      onChange={field.onChange}
      onBlur={field.onBlur}
      disabled={disabled}
      active={active}
    />
  );
}
