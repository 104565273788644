import { ArrowTopRightOnSquareIcon } from "@heroicons/react/24/outline";
import classNames from "classnames";
import { NotificationsProvider } from "@/components/controlled/Notifications";
import { useForm } from "react-hook-form";
import { useMutation } from "react-query";
import PageContainer from "../PageContainer";
import Panel from "../Panel";

const CLIENT_ID = "c0cc5ff3-b558-45f6-a289-4d253588f37b";
function ApproveAzureAD() {
  const { mutateAsync } = useMutation(
    async ({ tenantId }: { tenantId: string }) => {
      window.open(
        `https://login.microsoftonline.com/${tenantId}/adminconsent?client_id=${CLIENT_ID}`
      );
    }
  );

  const {
    handleSubmit,
    register,
    formState: { errors, isValid, isDirty },
  } = useForm({ defaultValues: { tenantId: "" }, mode: "onChange" });
  return (
    <PageContainer logo title="Connecteer met Azure AD">
      <Panel>
        <form
          className="space-y-6"
          onSubmit={handleSubmit(({ tenantId }) => mutateAsync({ tenantId }))}
        >
          <p className="text-sm text-gray-700">
            Vul hier je tenant-id in. Dit formulier maakt een link waarmee je
            Azure AD en Atticus connecteert.
          </p>
          <div className="mt-2">
            <label
              htmlFor="tenant-id"
              className="block text-sm font-medium text-gray-dark"
            >
              Tenant-id
            </label>
            <div>
              <input
                id="tenant-id"
                type="text"
                className={classNames(
                  "block w-full appearance-none rounded-md border px-3 py-2 placeholder-gray-400 shadow-sm focus:outline-none sm:text-sm",
                  {
                    "border-gray-light focus:border-blue-500 focus:ring-blue-500":
                      !errors.tenantId,
                    "border-red-500 ring-1 ring-red-500":
                      errors.tenantId && errors.tenantId.type == "required",
                  }
                )}
                placeholder="tenant-id"
                {...register("tenantId", { required: true })}
              />
              <p className="pl-1">
                <a
                  target="_blank"
                  className="text-sm text-blue-600 underline hover:text-blue-500"
                  href="https://learn.microsoft.com/en-us/partner-center/find-ids-and-domain-names#find-the-microsoft-azure-ad-tenant-id-and-primary-domain-name"
                  rel="noreferrer"
                >
                  Waar vind ik mijn <span className="italic">tenant-id</span>{" "}
                  terug?
                </a>
              </p>
            </div>
          </div>
          <div>
            <button
              disabled={!isValid || !isDirty}
              type="submit"
              className="flex w-full items-center justify-center rounded-md border border-transparent bg-blue-primary px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 disabled:bg-gray-400 hover:disabled:bg-gray-400 focus:disabled:ring-gray-primary"
            >
              <span>Open Azure Portal</span>
              <ArrowTopRightOnSquareIcon className="mx-1 inline h-4 w-4 text-center" />
            </button>
          </div>
        </form>
      </Panel>
      <NotificationsProvider />
    </PageContainer>
  );
}

export default ApproveAzureAD;
