/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { models__api__identifier_token__Identifier_Output } from './models__api__identifier_token__Identifier_Output';

export type Supervisor_Output = {
    reference?: (string | null);
    type?: Supervisor_Output.type;
    identifier?: (models__api__identifier_token__Identifier_Output | null);
    display?: (string | null);
};

export namespace Supervisor_Output {

    export enum type {
        PRACTITIONER = 'Practitioner',
    }


}

