import { HotKeys } from "@/editor/hotkeys";
import { Field, FieldProps, Form, useFormikContext } from "formik";
import isFunction from "lodash/isFunction";
import React, { SetStateAction, useRef, useState } from "react";

const ValueWrapper = <
  Value,
  Values extends Record<string, any> = Record<string, any>,
>({
  name,
  children,
}: {
  name?: string;
  children: (props: {
    value?: FieldProps<Value, Values>["field"]["value"];
  }) => JSX.Element;
}) => {
  if (name)
    return (
      <Field name={name}>
        {({ field }: FieldProps<Value, Values>) =>
          children({ value: field.value })
        }
      </Field>
    );
  return children({});
};

export const InlineEditForm = <
  Value,
  Values extends Record<string, any> = Record<string, any>,
>({
  name,
  children,
  defaultIsEditing,
  isEditing: providedIsEditing,
  setIsEditing: providedSetEditing,
  readOnly,
  ...attributes
}: {
  defaultIsEditing?: boolean;
  isEditing?: boolean;
  setIsEditing?: React.Dispatch<SetStateAction<boolean>>;
  readOnly?: boolean;
  name?: string;
  children:
    | JSX.Element
    | ((props: {
        isEditing: boolean;
        setIsEditing: React.Dispatch<React.SetStateAction<boolean>>;
        submit: () => void;
        reset: () => void;
        value?: Value;
      }) => JSX.Element);
} & React.HTMLAttributes<HTMLFormElement>) => {
  const [isEditing, setEditing] = useState(!!defaultIsEditing);
  const formRef = useRef<HTMLFormElement>(null);
  const { submitForm, resetForm } = useFormikContext<Values>();
  const handleFocus = () => {
    providedSetEditing === undefined
      ? setEditing(true)
      : providedSetEditing(true);
  };
  const handelBlur = async (e: React.FocusEvent) => {
    if (!formRef.current?.contains(e.relatedTarget as HTMLElement)) {
      // Not triggered when swapping focus between children
      formRef.current?.requestSubmit();
    }
  };
  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (HotKeys.isEscape(e)) {
      formRef.current?.reset();
    }
  };

  const handleReset = (e: React.FormEvent<HTMLFormElement>) => {
    if (e && e.preventDefault && isFunction(e.preventDefault)) {
      e.preventDefault();
    }

    if (e && e.stopPropagation && isFunction(e.stopPropagation)) {
      e.stopPropagation();
    }
    resetForm();
    providedSetEditing === undefined
      ? setEditing(false)
      : providedSetEditing(false);
  };
  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    if (e && e.preventDefault && isFunction(e.preventDefault)) {
      e.preventDefault();
    }

    if (e && e.stopPropagation && isFunction(e.stopPropagation)) {
      e.stopPropagation();
    }

    providedSetEditing === undefined
      ? setEditing(false)
      : providedSetEditing(false);
    submitForm().catch((reason) => {
      console.warn(
        `Warning: An unhandled error was caught from submitForm()`,
        reason,
      );
    });
  };
  return (
    <Form
      ref={formRef}
      onFocus={handleFocus}
      onBlur={handelBlur}
      onKeyDown={handleKeyDown}
      onSubmit={handleSubmit}
      onReset={handleReset}
      {...attributes}
    >
      <ValueWrapper<Value, Values> name={name}>
        {({ value }) => {
          if (typeof children !== "function") {
            return children as JSX.Element;
          } else {
            return children({
              isEditing:
                providedIsEditing === undefined
                  ? isEditing && !readOnly
                  : providedIsEditing,
              setIsEditing:
                providedSetEditing === undefined
                  ? setEditing
                  : providedSetEditing,
              submit: () => formRef.current?.requestSubmit(),
              reset: () => formRef.current?.reset(),
              value,
            });
          }
        }}
      </ValueWrapper>
    </Form>
  );
};
