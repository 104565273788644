import DebugRoute from "@/routing/DebugRoute";
import { QueryClient } from "react-query";
import { RouteObject } from "react-router";
import { LOGOUT_PATH } from "./const";
import createLoader from "./createLoader";

export default function createRoute(queryClient: QueryClient): RouteObject {
  return {
    path: LOGOUT_PATH,
    loader: createLoader(queryClient),
    element: <DebugRoute />,
  };
}
