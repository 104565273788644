import { TFunction } from "i18next";
import { ValidationValueMessage } from "react-hook-form";

type TranslatedValidatorMap = Record<
  "date" | "time" | "month" | "year" | "datetime-local",
  (t: TFunction) => ValidationValueMessage<RegExp>
>;
export const DATE_VALIDATOR_MAP: TranslatedValidatorMap = {
  date: (t: TFunction) => ({
    value: /^(\d{4}-\d{2}-\d{2})?$/,
    message: t("invalid-date-format"),
  }),
  time: (t: TFunction) => ({
    value: /^(\d{2}:\d{2})?$/,
    message: t("invalid-time-format"),
  }),
  month: (t: TFunction) => ({
    value: /^(\d{4}-\d{2})?$/,
    message: t("invalid-month-format"),
  }),
  year: (t: TFunction) => ({
    value: /^(\d{4})?$/,
    message: t("invalid-year-format"),
  }),
  "datetime-local": (t: TFunction) => ({
    value: /^(\d{4}-\d{2}-\d{2}T\d{2}:\d{2})?$/,
    message: t("invalid-datetime-local-format"),
  }),
};
