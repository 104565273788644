import { IfhirR4 } from "@smile-cdr/fhirts";
import { useQuery, UseQueryOptions } from "react-query";

export interface FHIRSearchTarget {
  baseURL: string;
  resourceType: string;
  query: Record<string, string>;
}

function useFHIRSearch<TData = IfhirR4.IBundle>(
  { baseURL, resourceType, query }: FHIRSearchTarget,
  options?: UseQueryOptions<IfhirR4.IBundle, Error, TData>
) {
  const url = new URL(`${baseURL}/${resourceType}`);
  url.search = new URLSearchParams(query).toString();
  return useQuery<IfhirR4.IBundle, Error, TData>(
    [baseURL, resourceType, query],
    () =>
      fetch(url.toString(), {
        headers: { "Content-Type": "application/json" },
      }).then((response) => response.json() as Promise<IfhirR4.IBundle>),
    options
  );
}

export default useFHIRSearch;
