import { Column, ColumnType, Row, TableInstance, TemplateRow } from "./types";
import React, {
  SetStateAction,
  useCallback,
  useMemo,
  useReducer,
  useRef,
} from "react";
import tableReducer, { TableAction } from "./reducer";
import useStableCallback from "@/hooks/useStableCallback";
import { defaultCellValue, generateUniqueId } from "./utils";

const DEFAULT_TABLE: TableInstance = { columns: [], data: [] };

export interface UseTableProps<TColumns extends Column[] = Column[]> {
  table?: TableInstance<TColumns>;
  defaultTable?: TableInstance<TColumns>;
  onTableChange?: React.Dispatch<SetStateAction<TableInstance<TColumns>>>;
}
function useTable<TColumns extends Column[] = Column[]>({
  defaultTable,
  table: providedTable,
  onTableChange,
}: UseTableProps<TColumns> = {}) {
  // stabelize potentially unstable callback
  const onTableChangeStable = useStableCallback(onTableChange);
  // this state is used when onTableChange is
  const [localTable, localDispatch] = useReducer(
    tableReducer,
    defaultTable || DEFAULT_TABLE
  );

  const dispatch = useCallback(
    (action: TableAction) => {
      if (onTableChangeStable) {
        onTableChangeStable((table) => tableReducer<TColumns>(table, action));
      } else {
        localDispatch(action);
      }
    },
    [localDispatch, onTableChangeStable]
  );

  const table = providedTable || localTable;

  const tableRef = useRef<HTMLDivElement>(null);

  const createRow = useCallback(() => {
    const newRow: Partial<Row<typeof table["columns"]>> = {
      id: generateUniqueId(),
    };
    table.columns.forEach((col: Column) => {
      newRow[col.id] = defaultCellValue(col);
    });
    return newRow as Row<typeof table["columns"]>;
  }, [table]);

  const addRow = useCallback(() => dispatch({ type: "ADD_ROW" }), [dispatch]);
  const removeRow = useCallback(
    (index: number) => dispatch({ type: "REMOVE_ROW", index }),
    [dispatch]
  );
  const moveRow = useCallback(
    (dragIndex: number, hoverIndex: number) =>
      dispatch({ type: "MOVE_ROW", dragIndex, hoverIndex }),
    [dispatch]
  );
  const addColumn = useCallback(
    <TColumnType extends ColumnType = ColumnType>(
      column: Omit<Column<TColumnType>, "id">
    ) => dispatch({ type: "ADD_COLUMN", column }),
    [dispatch]
  );
  const gridTemplate = table.columns.map((c) => c.width ?? "auto").join(" ");
  const defaultRow = useMemo(
    () =>
      Object.fromEntries(
        table.columns.map((col: Column) => [col.id, defaultCellValue(col)])
      ) as Row<TColumns>,
    [table.columns]
  );
  const rowTemplates = (table.template ?? [
    { row: defaultRow },
  ]) as TemplateRow<TColumns>[];

  return {
    table,
    tableRef,
    gridTemplate,
    rowTemplates,
    addRow,
    moveRow,
    removeRow,
    addColumn,
    createRow,
  };
}
export default useTable;
