import { HTMLAttributes } from "react";
import DesignationDisplay from "./DesignationDisplay";
import DesignationFieldset from "./DesignationFieldset";

const Designation = (props: HTMLAttributes<HTMLDivElement>) => (
  <div {...props} />
);

Designation.Display = DesignationDisplay;
Designation.Fieldset = DesignationFieldset;

export default Designation;
