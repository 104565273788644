import React, {
  Fragment,
  HTMLAttributes,
  Ref,
  useCallback,
  useState,
} from "react";
import QuestionAnswer from "./QuestionAnswer/QuestionAnswer";
import QuestionnaireLayout from "./Layout";
import useQuestionnaireController, {
  UseQuestionnaireControllerProps,
} from "./useQuestionnaireController";
import { IterateQuestions } from "./QuestionAnswer/IterateQuestions";
import QuestionGroup from "./QuestionAnswer/QuestionGroup";
import {
  QuestionAnswerStyle,
  QuestionAnswerStyleProvider,
} from "./QuestionAnswer/Layout";
import ReportButton from "@/components/base/ReportButton";
import { PlusIcon } from "@heroicons/react/24/outline";
import { ActionTypes, IQuestionItem } from "./models";
import { MARKDOWN_INPUT_ELEMENT } from "@/editor/MarkdownInputPlugin/consts";
import AddQuestionPanel from "./add-question/AddQuestionPanel";
import { Dialog, Transition } from "@headlessui/react";
import { FloatingDelayGroup } from "@floating-ui/react";

export interface QuestionnaireProps
  extends Omit<HTMLAttributes<HTMLDivElement>, "style">,
    UseQuestionnaireControllerProps {
  orientation?: QuestionAnswerStyle["orientation"];
  allowComments?: boolean;
  allowAddQuestion?: boolean;
}

const ROOT_PATH: string[] = [];
const ROOT_INDEX_PATH: number[] = [];

const COMMENT_QUESTION: IQuestionItem = {
  mode: "data-entry",
  orientation: "vertical",
  code: {
    text: "Opmerkingen",
    coding: [
      {
        system: "http://loinc.org",
        code: "comments",
        display: "Comments",
      },
    ],
  },
  answer: [
    {
      type: MARKDOWN_INPUT_ELEMENT,
      name: "comments",
      placeholder: "Opmerkingen",
      children: [{ text: "" }],
    },
  ],
};

function QuestionnaireWithoutRef(
  {
    questions: providedQuestions,
    defaultQuestions: initialQuestions,
    dispatch: providedDispatch,
    onQuestionsChange,
    orientation = "vertical",
    allowComments = false,
    allowAddQuestion = true,
    className,
    ...attributes
  }: QuestionnaireProps,
  ref: Ref<HTMLDivElement>,
) {
  const autoFocusRef = React.useRef<HTMLInputElement>(null);
  const [isAddQuestionDialogOpen, setIsAddQustionDialogOpen] = useState(false);

  const [questions, dispatch] = useQuestionnaireController({
    questions: providedQuestions,
    dispatch: providedDispatch,
    defaultQuestions: initialQuestions,
    onQuestionsChange,
  });

  const handleAddComments = useCallback(() => {
    dispatch({
      type: ActionTypes.ADD_QUESTION,
      path: [questions.length],
      question: COMMENT_QUESTION,
    });
  }, [dispatch, questions.length]);

  const handleAddQuestion = useCallback(
    (question: IQuestionItem) => {
      dispatch({
        type: ActionTypes.ADD_QUESTION,
        path: [questions.length],
        question,
      });
      setIsAddQustionDialogOpen(false);
    },
    [dispatch, questions.length],
  );
  const showComments = !questions.some(
    (q) => !q.repeating && q.code.text === COMMENT_QUESTION.code.text,
  );

  return (
    <FloatingDelayGroup delay={{ open: 1000, close: 100 }} timeoutMs={500}>
      <QuestionnaireLayout.Container
        ref={ref}
        className={className}
        {...attributes}
      >
        <QuestionAnswerStyleProvider value={{ orientation }}>
          <QuestionnaireLayout.Body>
            <IterateQuestions
              questions={questions}
              indexPath={ROOT_INDEX_PATH}
              path={ROOT_PATH}
            >
              {({ question, ...props }) =>
                question.repeating ? (
                  <QuestionGroup
                    {...props}
                    question={question}
                    dispatch={dispatch}
                  />
                ) : (
                  <QuestionAnswer
                    {...props}
                    question={question}
                    dispatch={dispatch}
                  />
                )
              }
            </IterateQuestions>
            <div>
              {allowComments && showComments && (
                <ReportButton
                  muted
                  positionIcon="left"
                  onClick={handleAddComments}
                  className="invisible flex items-center current-block-hover:visible"
                  Icon={<PlusIcon className="inline h-4 w-4" />}
                >
                  Voeg extra opmerkingen toe
                </ReportButton>
              )}
              {allowAddQuestion && (
                <ReportButton
                  muted
                  positionIcon="left"
                  onClick={() => setIsAddQustionDialogOpen(true)}
                  className="invisible flex items-center current-block-hover:visible"
                  Icon={<PlusIcon className="inline h-4 w-4" />}
                >
                  Voeg vraag toe
                </ReportButton>
              )}
            </div>
          </QuestionnaireLayout.Body>
        </QuestionAnswerStyleProvider>

        <Transition.Root show={isAddQuestionDialogOpen} as={Fragment}>
          <Dialog
            open={isAddQuestionDialogOpen}
            onClose={() => setIsAddQustionDialogOpen(false)}
          >
            {/* The backdrop, rendered as a fixed sibling to the panel container */}
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>
            {/* Full-screen scrollable container */}
            <div className="fixed inset-0 w-screen overflow-y-auto">
              {/* Container to center the panel */}
              <div className="flex min-h-full items-center justify-center p-4">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  enterTo="opacity-100 translate-y-0 sm:scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                  leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                  <Dialog.Panel className="mx-auto max-w-5xl rounded bg-white p-4">
                    <Dialog.Title className="text-xl font-medium text-gray-darker">
                      Voeg vraag toe
                    </Dialog.Title>
                    <AddQuestionPanel
                      autoFocusRef={autoFocusRef}
                      className="mt-4"
                      onSubmit={handleAddQuestion}
                    />
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition.Root>
      </QuestionnaireLayout.Container>
    </FloatingDelayGroup>
  );
}

const Questionnaire = React.forwardRef(QuestionnaireWithoutRef);

export default Questionnaire;
