import {
  autoPlacement,
  FloatingPortal,
  offset,
  useFloating,
} from "@floating-ui/react";
import { Listbox, Transition } from "@headlessui/react";
import { ArrowDownTrayIcon, LinkIcon } from "@heroicons/react/24/outline";
import classNames from "classnames";
import { UserTimestamp } from "@/data-models/block";
import { Fragment } from "react";
import { useQuery } from "react-query";
import client from "@/services/reports/client";
import keyFactory from "@/services/reports/keyFactory";
import { printDate } from "@/text-printers/print-date";

type ImportableBlockOption = {
  id: number;
  title: string;
  created: UserTimestamp;
};

export interface BlockImportButtonGroupProps {
  className?: string;
  currentBlockId: number | null;
  linkedBlock: null | ImportableBlockOption;
  onLinkedBlockChange?: (block: null | ImportableBlockOption) => void;
  subjectId: number | null;
  blockType: string | null;
}
export default function BlockImportButtonGroup({
  className,
  blockType,
  currentBlockId,
  linkedBlock,
  subjectId,
  onLinkedBlockChange,
}: BlockImportButtonGroupProps) {
  const { data: options = [] } = useQuery({
    queryKey: keyFactory.allBlocks({ blockType, subjectId }),
    queryFn: () =>
      client.v1.getBlocksV1({
        type: blockType!,
        subject: `Patient/${subjectId!}`,
      }),
    select: (blocks) => blocks.filter((block) => block.id !== currentBlockId),
    enabled: !!blockType && !!subjectId,
  });
  const { floatingStyles, refs } = useFloating({
    middleware: [offset(10), autoPlacement({ alignment: "end" })],
  });
  return (
    <Listbox
      as="div"
      ref={refs.setReference}
      value={linkedBlock}
      onChange={onLinkedBlockChange}
      by={(a, b) => a?.id == b?.id}
      className={classNames(className, "relative flex")}
    >
      {({ value }) => (
        <>
          <Listbox.Button className="relative mx-1 inline-flex items-center justify-center text-gray-light hover:text-gray-primary">
            {value ? (
              <span>
                <LinkIcon
                  className="mr-1 inline h-4 w-4 text-blue-600"
                  aria-hidden="true"
                />
                <RenderBlockOption
                  block={value}
                  className="mr-2 text-sm text-gray-primary"
                />
              </span>
            ) : (
              <span>
                <ArrowDownTrayIcon
                  className="inline-block h-5 w-5"
                  aria-hidden="true"
                />
                <span className="sr-only text-xs">
                  Importeer uit vorig verslag
                </span>
              </span>
            )}
          </Listbox.Button>
          <FloatingPortal>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Listbox.Options
                style={floatingStyles}
                ref={refs.setFloating}
                className="absolute right-0 z-10 mt-1 max-h-60 w-min min-w-fit overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
              >
                {/*!!value && <Listbox.Option
                className={({ active }) =>
                  classNames(
                    active ? "bg-blue-600 text-white" : "text-gray-primary",
                    "relative cursor-default select-none py-2 pl-10 pr-4 border-b border-gray-ultralight"
                  )
                }
                value={null}
              >
                {({ selected }) => (
                  <>
                    <span
                      className={classNames(
                        selected ? "font-semibold" : "font-normal",
                        "block truncate"
                      )}
                    >
                      Ontkoppel bouwblok
                    </span>
                  </>
                )}
                      </Listbox.Option>*/}
                {options.map((block) => (
                  <Listbox.Option
                    key={block.id}
                    className={({ active }) =>
                      classNames(
                        active ? "bg-blue-600 text-white" : "text-gray-darker",
                        "relative cursor-default select-none py-2 pl-10 pr-4",
                      )
                    }
                    value={block}
                  >
                    {({ selected, active }) => (
                      <>
                        <span
                          className={classNames(
                            selected ? "font-semibold" : "font-normal",
                            "block truncate",
                          )}
                        >
                          <RenderBlockOption
                            block={block as ImportableBlockOption}
                            selected={selected}
                          />
                        </span>
                        {selected ? (
                          <span
                            className={classNames(
                              active ? "text-white" : "text-blue-600",
                              "absolute inset-y-0 left-0 flex items-center pl-3",
                            )}
                          >
                            <LinkIcon className="h-5 w-5" aria-hidden="true" />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </FloatingPortal>
        </>
      )}
    </Listbox>
  );
}

function RenderBlockOption({
  block,
  selected,
  className,
}: {
  block: ImportableBlockOption;
  selected?: boolean;
  className?: string;
}) {
  return (
    <span
      className={classNames(
        className,
        selected ? "font-semibold" : "font-normal",
        "truncate",
      )}
    >
      {`${printDate(block.created.timestamp)}, door ${
        block.created.user.display
      }`}
    </span>
  );
}
