import { ProviderId, User } from "firebase/auth";
import { useQuery } from "react-query";
import { currentUserQuery } from "./useAuthUser";

function hasUserVerifiedEmail(user: User) {
  if (!user) return false;
  const hasProviderId = user.providerData.length > 0;
  const isEmailPasswordProvider =
    user.providerData?.[0]?.providerId == ProviderId.PASSWORD;
  const hasVerifiedEmail = user.emailVerified;
  return !hasProviderId || !isEmailPasswordProvider || hasVerifiedEmail;
}

export const hasVerifiedEmailQuery = {
  ...currentUserQuery,
  select: (user: User | null) => (user ? hasUserVerifiedEmail(user) : false),
};
export type HasVerifiedEmailQuery = Pick<
  typeof hasVerifiedEmailQuery,
  "queryFn" | "queryKey" | "select"
>;

function useHasVerifiedEmail() {
  return useQuery(hasVerifiedEmailQuery);
}
export default useHasVerifiedEmail;
