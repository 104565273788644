import {
  ExpansionContains,
  SemanticAxis,
} from "@/services/content/content-client";
import { assert, string } from "superstruct";

export interface IQuantity<T = undefined> {
  value: number | T;
  unit?: string;
  code: string;
  system: string;
}

export interface ITiroQuantity extends IQuantity<""> {
  unit: string;
  minValue?: number | null;
  maxValue?: number | null;
}

export interface IAbstractRatio<T> {
  numerator: T;
  denominator: T;
}
export type IRatio = IAbstractRatio<IQuantity>;

export interface ICSConcept {
  system: string;
  code?: string;
  display?: string;
  designation?: {
    use?: {
      system: string;
      code: string;
      display: string;
    };
    value: string;
    language?: string;
  };
}

export interface ICoding {
  display?: string;
  code: string;
  system: string;
  version?: string;
  userSelected?: boolean;
}
export interface IHumanReadableCoding {
  display: string;
  code: string;
  system: string;
  version?: string;
  userSelected?: boolean;
}

export interface ICodeableConcept {
  text: string;
  coding: [IHumanReadableCoding] | IHumanReadableCoding[];
}

export interface IIdentifier {
  system: string;
  value: string;
  use?: string;
}

export interface IReference {
  reference?: string;
  type?: string;
  identifier?: IIdentifier;
  display?: string;
}

export interface ISimpleQuantity {
  value: number;
  unit: string;
  system?: string;
  code?: string;
}
export interface IRange {
  low?: ISimpleQuantity;
  high?: ISimpleQuantity;
}

export interface INarrative {
  status: "generated" | "extensions" | "additional" | "empty";
  div: string;
}

export function codingToConcept(option: ExpansionContains): ICodeableConcept {
  assert(option.display, string());
  assert(option.system, string());
  const concept: ICodeableConcept = {
    text: option.display,
    coding: [
      {
        system: option.system,
        code: option.code,
        version: option.version,
        display: option.display,
        userSelected: true,
      },
    ],
  };
  if (option.property && option.property.length > 0) {
    const semanticCoding = option.property.find(
      (p): p is SemanticAxis => p.code === "semantic_axis",
    );
    if (semanticCoding) {
      const { code, display, system } = semanticCoding.valueCoding;
      assert(system, string());
      assert(display, string());
      concept.coding.push({ code, display, system });
    }
  }
  return concept;
}
export function conceptAsCoding(
  concept: ICodeableConcept,
): IHumanReadableCoding {
  const coding =
    concept.coding.find((c) => c.userSelected) || concept.coding[0];
  return {
    display: concept.text,
    code: coding.code,
    system: coding.system,
    version: coding.version,
  };
}
