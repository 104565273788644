import classNames from "classnames";
import React from "react";

export interface SplitButtonProps {
  align?: "left" | "right";
  onClick?: React.MouseEventHandler;
}
const SplitButton = ({
  onClick: handleClick,
  align = "right",
}: SplitButtonProps) => {
  return (
    <div className="relative h-0 w-full">
      <div className="group absolute inset-x-0 border border-transparent duration-300 ease-in-out hover:border-blue-primary hover:transition-all">
        <div
          className={classNames("absolute -top-2.5 h-5 w-16", {
            "-left-1 ": align == "left",
            "right-1": align == "right",
          })}
        >
          <button
            onClick={handleClick}
            tabIndex={-1}
            type="button"
            //onClick={(e) => handleAdd(e, index)}
            className={classNames(
              "bg-blue-transparent absolute -top-1 z-10 flex items-center space-x-1 rounded-md px-1 text-transparent duration-300 ease-in-out hover:transition-all group-hover:bg-blue-500 group-hover:text-blue-50",
              { " -left-2 ": align == "left", "-right-2": align == "right" }
            )}
          >
            <span className="material-icons text-lg font-bold">add</span>
            <span className="text-xs font-normal">tekst</span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default SplitButton;
