import { useQuery, UseQueryOptions } from "react-query";
import { assert, integer } from "superstruct";
import client from "./client";
import keyFactory from "./keyFactory";
type TQueryKey = ReturnType<typeof keyFactory.plainTextView>;
export type UsePlainTextViewOptions<TData> = UseQueryOptions<
  string,
  Error,
  TData,
  TQueryKey
>;
function usePlainTextView<TData = string>(
  reportId?: number,
  options?: UsePlainTextViewOptions<TData>
) {
  const { enabled, ...otherOptions } = options ?? {};
  return useQuery(
    keyFactory.plainTextView(reportId!),
    async () => {
      assert(reportId, integer());
      return (await client.v1.getPlainTextV1({
        reportId,
      })) as string;
    },
    {
      enabled: enabled && !!reportId,
      ...otherOptions,
    }
  );
}
export default usePlainTextView;
