import { FloatingPortal, Placement, useFloating } from "@floating-ui/react";
import { Combobox } from "@headlessui/react";
import {
  CheckIcon,
  ChevronUpDownIcon,
  ExclamationTriangleIcon,
} from "@heroicons/react/24/outline";
import classNames from "classnames";
import { useMeasure } from "react-use";
import { Spinner } from "@/Icons";
import React, { useCallback, useEffect, useState } from "react";
import useValueSets from "@/services/content/useValueSets";
import { create, func, is, optional, string, type } from "superstruct";
import StatusTag, { StatusStruct } from "@/components/base/StatusTag";
import { FieldValues, RegisterOptions, useController } from "react-hook-form";
import VersionSelect from "./VersionSelect";
import { ReadValueSetDoc } from "@/services/content/content-client";

export type ValueSetMeta = {
  url: string;
  version?: string;
  id: string;
  title: string;
};
export const ValueSetMetaModel = type({
  url: string(),
  version: optional(string()),
  id: string(),
  title: string(),
});

function Button_(
  {
    loading,
    success,
    error,
    children,
    ...attributes
  }: React.ButtonHTMLAttributes<HTMLButtonElement> & {
    loading?: boolean;
    error?: boolean;
    success?: boolean;
  },
  ref: React.Ref<HTMLButtonElement>
) {
  return (
    <button
      ref={ref}
      className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none"
      {...attributes}
    >
      {loading && <Spinner className="h-5 w-5 animate-spin text-gray-400" />}
      {success && (
        <ChevronUpDownIcon
          className="h-5 w-5 text-gray-400"
          aria-hidden="true"
        />
      )}
      {error && (
        <ExclamationTriangleIcon
          className="h-5 w-5 text-red-400"
          aria-hidden="true"
        />
      )}
      {children}
    </button>
  );
}
const Button = React.forwardRef(Button_);

interface ComboboxWrapperChildrenProps {
  query: string;
  onQueryChange: (query: string) => void;
}
export type ComboboxWrapperProps = Omit<
  React.HTMLProps<HTMLDivElement>,
  "children"
> & {
  placement?: Placement;
  query?: string;
  onQueryChange?: (query: string) => void;
  children:
    | ((childrenProps: ComboboxWrapperChildrenProps) => React.ReactNode)
    | React.ReactNode;
};
const ComboboxWrapper = ({
  children,
  placement = "bottom-end",
  query: providedQuery,
  onQueryChange: providedOnQueryChange,
  className,
  ...props
}: ComboboxWrapperProps) => {
  const [localQuery, setLocalQuery] = useState("");
  const query = providedQuery ?? localQuery;
  const onQueryChange = useCallback(
    (query: string) => {
      setLocalQuery(query);
      providedOnQueryChange && providedOnQueryChange(query);
    },
    [providedOnQueryChange]
  );
  const {
    data: filteredValuesets = [],
    isFetching,
    isSuccess,
    isError,
  } = useValueSets({ title: query });
  const { floatingStyles, refs, update, elements } = useFloating({ placement });

  const [measureRef, { width }] = useMeasure();
  useEffect(() => {
    // update floating portal position when valuesets change
    update();
  }, [filteredValuesets, update]);
  useEffect(() => {
    // sync floating portal width with input width
    elements.domReference && measureRef(elements.domReference);
  }, [elements.domReference, measureRef]);
  return (
    <div
      ref={refs.setReference}
      className={classNames("relative", className)}
      {...props}
    >
      {is(children, func()) ? children({ query, onQueryChange }) : children}
      <Combobox.Button
        type="button"
        as={Button}
        loading={isFetching}
        error={isError}
        success={isSuccess}
      />
      <FloatingPortal>
        {filteredValuesets.length > 0 && (
          <Combobox.Options
            ref={refs.setFloating}
            style={{ ...floatingStyles, minWidth: width }}
            className="absolute z-10 max-h-60 overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
          >
            {filteredValuesets.map((vs) => (
              <Combobox.Option
                key={vs.id}
                value={vs}
                className={({ active }) =>
                  classNames(
                    "relative cursor-default select-none py-2 pl-3 pr-9",
                    active ? "bg-blue-600 text-white" : "text-gray-900"
                  )
                }
              >
                {({ active, selected }) => (
                  <>
                    <div className="flex">
                      <div
                        className={classNames(
                          "truncate tabular-nums",
                          selected && "font-semibold",
                          active ? "text-blue-100" : "text-gray-700"
                        )}
                      >
                        {vs.title}
                      </div>
                      <div
                        className={classNames(
                          "mx-2",
                          active ? "text-blue-100" : "text-gray-500"
                        )}
                      >
                        {vs.version}
                      </div>
                      <div>
                        <StatusTag status={create(vs.status, StatusStruct)} />
                      </div>
                    </div>

                    {selected && (
                      <span
                        className={classNames(
                          "absolute inset-y-0 right-0 flex items-center pr-4",
                          active ? "text-white" : "text-blue-600"
                        )}
                      >
                        <CheckIcon className="h-5 w-5" aria-hidden="true" />
                      </span>
                    )}
                  </>
                )}
              </Combobox.Option>
            ))}
          </Combobox.Options>
        )}
      </FloatingPortal>
    </div>
  );
};

export interface ValueSetInputV2Props {
  name: string;
  defaultValueSet?: string | null;
  version?: string;
  onValueSetChange?: (code: string | null) => void;
  rules?:
    | Omit<
        RegisterOptions<FieldValues, string>,
        "disabled" | "valueAsNumber" | "valueAsDate" | "setValueAs"
      >
    | undefined;
}
function by(a: ReadValueSetDoc | null, b: ReadValueSetDoc | null) {
  return a?.url == b?.url && a?.version == b?.version;
}
interface ValueSetInputChildrenProps<TValueSet> {
  query: string;
  onQueryChange: (query: string) => void;
  valueSet: TValueSet | null;
}

function ValueSetInputv2({
  name,
  defaultValueSet = null,
  version,
  onValueSetChange,
  children,
  rules,
}: ValueSetInputV2Props & {
  children:
    | React.ReactNode
    | ((props: ValueSetInputChildrenProps<ReadValueSetDoc>) => React.ReactNode);
}) {
  const { field } = useController({
    name,
    defaultValue: defaultValueSet,
    rules,
  });

  const { data: valueSetDocs } = useValueSets({
    url: field.value,
    version,
  });
  const currentValueSet = valueSetDocs?.[0] ?? null;

  const [query, setQuery] = useState("");
  const handleCodeChange = async (vs: ReadValueSetDoc | null) => {
    const canonical = vs?.url ?? null;
    field.onChange(canonical);
    onValueSetChange && onValueSetChange(canonical);
    if (!vs) return;
    setQuery("");
  };
  return (
    <Combobox
      as="div"
      value={currentValueSet}
      onChange={handleCodeChange}
      by={by}
      onBlur={field.onBlur}
    >
      {({ value }) =>
        children ? (
          is(children, func()) ? (
            children({ query, onQueryChange: setQuery, valueSet: value })
          ) : (
            children
          )
        ) : (
          <>
            <Combobox.Label className="block text-sm font-medium leading-6 text-gray-900">
              Search for a ValueSet
            </Combobox.Label>
            <ComboboxWrapper className="mt-2" query={query}>
              <Combobox.Input
                autoComplete="off"
                className="w-full rounded-md border-0 bg-white py-1.5 pl-3 pr-12 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                onChange={(event) => setQuery(event.target.value)}
                displayValue={(vs: ValueSetMeta | null) => vs?.title ?? ""}
              />
            </ComboboxWrapper>
          </>
        )
      }
    </Combobox>
  );
}

ValueSetInputv2.Label = Combobox.Label;
ValueSetInputv2.Input = Combobox.Input;
ValueSetInputv2.Wrapper = ComboboxWrapper;
ValueSetInputv2.VersionSelect = VersionSelect;

export default ValueSetInputv2;
