import classNames from "classnames";
import React, { HTMLProps, Ref } from "react";

export interface QuestionButtonProps extends HTMLProps<HTMLButtonElement> {
  type: "button" | "submit" | "reset" | undefined;
  highlight?: boolean;
}
const QuestionButtonWithoutRef = (
  { className, highlight, ...props }: QuestionButtonProps,
  ref?: Ref<HTMLButtonElement>
) => (
  <button
    ref={ref}
    className={classNames(
      "m-0 rounded border border-transparent bg-transparent px-px text-left text-sm font-medium text-primary hover:bg-gray-ultralight focus:outline-blue-200  group-hover:bg-gray-ultralight",
      {
        "border-blue-light ring-blue-light": highlight,
        "border-transparent ring-transparent": !highlight,
      },
      className
    )}
    {...props}
  />
);

const QuestionButton = React.forwardRef(QuestionButtonWithoutRef);
export default QuestionButton;
