import { FirebaseError } from "firebase/app";
import {
  browserLocalPersistence,
  browserSessionPersistence,
  OAuthProvider,
  setPersistence,
  signInWithEmailAndPassword,
  signInWithPopup,
} from "firebase/auth";
import { posthog } from "posthog-js";
import { ActionFunction } from "react-router";
import { getTenantAuth } from "@/services/tenant";
import { z } from "zod";
import { jumpBack } from "../auth-flow";
import { PostHogEvents } from "@/postHogEvents";
import { QueryClient } from "react-query";
import { currentUserDataOrFalseQuery } from "@/services/reports/useUserData";

const schema = z.union([
  z.object({
    type: z.literal("email/pass"),
    email: z.string().email(),
    password: z.string(),
    "stay-logged-in": z
      .string()
      .optional()
      .transform((value) => (value == "true" ? true : false)),
  }),
  z.object({
    type: z.literal("microsoft"),
    tenant: z.string(),
    "stay-logged-in": z
      .string()
      .optional()
      .transform((value) => (value == "true" ? true : false)),
  }),
  z.object({
    type: z.literal("google"),
    domain: z.string(),
    "stay-logged-in": z
      .string()
      .optional()
      .transform((value) => (value == "true" ? true : false)),
  }),
]);

export type ActionData = {
  error: FirebaseError;
  type: "email/pass" | "microsoft" | "google";
};

export type ActionOptions = {
  queryClient: QueryClient;
};
export default ({ queryClient }: ActionOptions): ActionFunction =>
  async ({ request }): Promise<ActionData | Response> => {
    const url = new URL(request.url);
    const payload = await request.formData();
    const result = schema.safeParse(Object.fromEntries(payload.entries()));
    if (!result.success) {
      console.error(result.error);
      throw Error("Invalid payload");
    }

    setStayLoggedIn(result.data["stay-logged-in"]);
    const { data } = result;
    switch (data.type) {
      case "email/pass":
        {
          try {
            console.debug("Logging in with email/pass");
            await signInWithEmailAndPassword(
              getTenantAuth(),
              data.email,
              data.password,
            );
          } catch (error) {
            if (error instanceof FirebaseError) {
              posthog.capture(...PostHogEvents.failedToLogin(data.type));
              return { error, type: "email/pass" };
            }
          }
        }
        break;
      case "microsoft":
        {
          const provider = new OAuthProvider("microsoft.com");
          provider.setCustomParameters({ tenant: data.tenant });
          try {
            console.log("Logging in with microsoft");
            await signInWithPopup(getTenantAuth(), provider);
          } catch (error) {
            console.warn(error);
            if (error instanceof FirebaseError) {
              posthog.capture(...PostHogEvents.failedToLogin(data.type));
              return { error, type: "microsoft" };
            }
          }
        }
        break;
      case "google":
        {
          const provider = new OAuthProvider("google.com");
          provider.setCustomParameters({ hd: data.domain });
          try {
            console.log("Logging in with google");
            await signInWithPopup(getTenantAuth(), provider);
          } catch (error) {
            console.warn(error);
            if (error instanceof FirebaseError) {
              posthog.capture(...PostHogEvents.failedToLogin(data.type));
              return { error, type: "google" };
            }
          }
        }
        break;
    }
    console.debug("Logged in successfully");
    console.debug("Prefetching user data");
    // Make sure this is query doesn't fail on a 404 because this may be the first time the user logs in
    queryClient.prefetchQuery(currentUserDataOrFalseQuery);
    return jumpBack(url);
  };

const getPersistence = (value: boolean) =>
  value ? browserLocalPersistence : browserSessionPersistence;

async function setStayLoggedIn(value: boolean) {
  await setPersistence(getTenantAuth(), getPersistence(value));
}
