import Tippy from "@tippyjs/react";
import classNames from "classnames";
import React, { Ref } from "react";
import { useTranslation } from "react-i18next";
import RemoveButton from "@/components/base/RemoveWithWarning/RemoveButton";

interface RemoveReportButtonProps
  extends Omit<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    "type" | "children" | "onClick"
  > {
  onClick?: () => void;
}
function RemoveReportButton_(
  { className, onClick, tabIndex = -1, ...props }: RemoveReportButtonProps,
  ref: Ref<HTMLButtonElement>,
) {
  const { t } = useTranslation();
  return (
    <Tippy content={t("verwijder-deze-vraag")} delay={[300, 50]}>
      <RemoveButton
        ref={ref}
        type="button"
        className={classNames(
          "absolute right-0 top-1 text-transparent group-hover:text-slate-400 group-hover:hover:text-gray-primary",
          className,
        )}
        tabIndex={tabIndex}
        onRemove={() => onClick?.()}
        warningTitle={t("verwijder-deze-vraag")}
        warningMessage={t("verwijder-deze-vraag-message")}
        {...props}
      />
    </Tippy>
  );
}
const RemoveReportButton = React.forwardRef<
  HTMLButtonElement,
  RemoveReportButtonProps
>(RemoveReportButton_);
export default RemoveReportButton;
