import { Descendant, Editor, Path, Point, Range, Transforms } from "slate";
import { ReactEditor } from "slate-react";
import { TableElement } from ".";
import TiroEditor, { ITiroEditor } from "../../Base/editor";
import { ITableElement } from "./types";

function* serializeTableToPlainText(
  editor: ITiroEditor,
  node: ITableElement,
  inlineSCT: boolean
) {
  const cellWiseText = node.children.map((n) =>
    n.children.map((n) =>
      Array.from(TiroEditor.serializePlainText(editor, n, inlineSCT)).join(" ")
    )
  );
  const columnWidth = cellWiseText.reduce(
    (prev, curr) => prev.map((p, i) => Math.max(prev[i], curr[i].length)),
    node.columns.map(() => 0)
  );
  const formattedCellWiseText = cellWiseText.map((r) =>
    r.map((c, ci) => c.padEnd(columnWidth[ci]))
  );
  const formattedRowWiseText = formattedCellWiseText.map((r) => r.join("  "));
  const formattedText = formattedRowWiseText.join("\n");
  yield formattedText;
}
const isInsideTable = (
  editor: ReactEditor,
  tablePath: Path,
  node: Descendant
) => {
  const path = ReactEditor.findPath(editor, node);
  if (!Path.isAncestor(tablePath, path)) return false;
  return Path.relative(path, tablePath).length > 2;
};
const withTable = <T extends ITiroEditor>(editor: T): T => {
  const {
    deleteBackward,
    deleteForward,
    deleteFragment,
    insertBreak,
    tabFocus,
    serializePlainText,
  } = editor;

  editor.deleteBackward = (unit) => {
    const { selection } = editor;
    if (selection && Range.isCollapsed(selection)) {
      const [cell] = Editor.nodes(editor, {
        match: (n) => !Editor.isEditor(n) && TableElement.isTableCellElement(n),
      });

      if (cell) {
        const [, cellPath] = cell;
        const start = Editor.start(editor, cellPath);

        if (Point.equals(selection.anchor, start)) {
          return;
        }
      }
    }
    deleteBackward(unit);
  };
  editor.deleteForward = (unit) => {
    const { selection } = editor;

    if (selection && Range.isCollapsed(selection)) {
      const [cell] = Editor.nodes(editor, {
        match: (n) => !Editor.isEditor(n) && TableElement.isTableCellElement(n),
      });

      if (cell) {
        const [, cellPath] = cell;
        const end = Editor.end(editor, cellPath);

        if (Point.equals(selection.anchor, end)) {
          return;
        }
      }
    }
    deleteForward(unit);
  };

  editor.deleteFragment = (direction) => {
    const [tableEntry] = Editor.levels(editor, {
      match: (n) => TableElement.isTableElement(n),
      reverse: true,
    });
    const insideTable = !!tableEntry;
    if (insideTable) {
      const [, tablePath] = tableEntry;
      const { selection } = editor;
      if (!selection) return;

      const match = (n: Descendant) => isInsideTable(editor, tablePath, n);
      Transforms.removeNodes(editor, { at: selection, match, mode: "highest" });
      Transforms.select(
        editor,
        Editor.range(
          editor,
          selection.anchor.path.slice(0, tablePath.length + 2),
          selection.focus.path.slice(0, tablePath.length + 2)
        )
      );
      return;
    }
    return deleteFragment(direction);
  };

  editor.insertBreak = () => {
    const blockAbove = Editor.above(editor, {
      match: (n) => Editor.isBlock(editor, n),
    });
    if (blockAbove && TableElement.isTableCellElement(blockAbove[0])) return;
    insertBreak();
  };
  editor.tabFocus = (options = {}) => {
    const { reverse } = options;
    const blockAbove = Editor.above(editor, {
      match: (n) => Editor.isBlock(editor, n),
    });
    if (blockAbove && TableElement.isTableCellElement(blockAbove[0])) {
      const nextFocus = reverse
        ? Editor.previous(editor, {
            match: (n) => TableElement.isTableCellElement(n),
          })
        : Editor.next(editor, {
            match: (n) => TableElement.isTableCellElement(n),
          });
      if (nextFocus) Transforms.select(editor, nextFocus[1]);
      return true;
    }
    return tabFocus(options);
  };

  editor.serializePlainText = (node, inlineSCT) => {
    if (TableElement.isTableElement(node)) {
      return serializeTableToPlainText(editor, node, inlineSCT);
    }
    return serializePlainText(node, inlineSCT);
  };
  return editor;
};
export default withTable;
