import { EntityModel } from "@/data-models/entity";
import { ReferenceModel } from "@/data-models/reference";
import { Infer } from "superstruct";
import { ITiroElement } from "../../Base/types";
import { INLINE_ENTITY_ELEMENT } from "./const";

export const STATES = ["present", "absent", "unknown"] as const;
export type State = typeof STATES[number];
export interface IStatefullElement extends ITiroElement {
  state?: State;
  active?: boolean;
  checked?: boolean;
}

export interface IAbstractEntityElement
  extends ITiroElement,
    IStatefullElement {
  placeholder?: string;
  entity?: Infer<typeof EntityModel>;
  reference?: Infer<typeof ReferenceModel>;
}
export interface IEntityElement<
  TEntity extends Infer<typeof EntityModel> = Infer<typeof EntityModel>
> extends IAbstractEntityElement {
  entity: TEntity;
}

export interface IInlineEntityElement extends IAbstractEntityElement {
  readonly type: typeof INLINE_ENTITY_ELEMENT;
  placeholder?: string;
}

export const SupportedEntityTypes = [INLINE_ENTITY_ELEMENT] as const;
export type AllEntityTypes = typeof SupportedEntityTypes[number];
