import classNames from "classnames";
import React, { useContext } from "react";
import { BadgeStyleContext } from "./context";
import { ViewBadgeFix } from "./ViewBadge";

export type LoadingBadgeProps =
  React.ButtonHTMLAttributes<HTMLButtonElement> & {
    prefix?: React.ReactChild | null;
    suffix?: React.ReactChild | null;
  };

function LoadingBadge({
  prefix,
  suffix,
  className,
  children,
  ...attributes
}: LoadingBadgeProps) {
  const style = useContext(BadgeStyleContext);
  return (
    <button
      type="button"
      data-testid="loading-badge"
      className={classNames(
        "mr-px inline-flex min-w-max items-baseline rounded-[0.25rem] border border-transparent px-3 py-1.5 font-medium leading-3 ",
        "text-slate-700 hover:border-slate-500",
        {
          "text-sm": style !== "tile",
          "text-xl": style === "tile",
          "bg-slate-100/90": style !== "table",
          "h-full w-full border-white bg-white": style === "table",
        },
        className
      )}
      {...attributes}
    >
      {prefix && <ViewBadgeFix>{prefix}</ViewBadgeFix>}
      {children}
      {suffix && <ViewBadgeFix>{suffix}</ViewBadgeFix>}
    </button>
  );
}

export default LoadingBadge;
