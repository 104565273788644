import classNames from "classnames";
import React from "react";

export interface StyledInputProps extends React.HTMLProps<HTMLInputElement> {
  invalid?: boolean;
}

function StyledInput_(
  { className, invalid, ...props }: StyledInputProps,
  ref: React.Ref<HTMLInputElement>
) {
  return (
    <input
      ref={ref}
      className={classNames(
        className,
        "outline-none focus:outline-none focus:ring-0", // reset default outline
        "inline-flex rounded border placeholder-gray-light focus:border-blue-400",
        "px-2 py-px text-sm leading-3",
        "disabled:cursor-not-allowed disabled:bg-slate-50 disabled:text-gray-500",
        { "border-gray-light": !invalid, "border-red-400": invalid }
      )}
      {...props}
    />
  );
}
const StyledInput = React.forwardRef(StyledInput_);
export default StyledInput;
