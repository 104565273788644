import { CSSProperties, Dispatch } from "react";
import { CSS } from "@dnd-kit/utilities";
import { QAAction, IQuestionItem } from "../models";
import QALayout from "./Layout";
import useQuestion from "./useQuestion";
import SortableTreeItem from "../SortableTree/SortableItem";
import { serializePath } from "../SortableTree/utilities";
import classNames from "classnames";
import { SettingsPanelPortal } from "@/components/layout/SettingsPanel";
import QuestionPropertiesForm from "./QuestionSettings/PropertiesForm";
import { useSemaphore } from "../Semaphore";
import { Transition } from "@headlessui/react";
import { IterateQuestions } from "./IterateQuestions";
import Answer from "../Answer/Answer";
import { useTranslation } from "react-i18next";

export interface QuestionAnswerComposerProps {
  question: IQuestionItem;
  dispatch?: Dispatch<QAAction>;
  path: string[];
  indexPath: number[];
  clone?: boolean;
}

function QuestionAnswerComposer({
  question,
  indexPath,
  path,
  dispatch,
  clone,
}: QuestionAnswerComposerProps) {
  const [
    questionState,
    { remove, updateContent, updateQuestion, updateValues },
  ] = useQuestion({
    question,
    indexPath,
    path,
    dispatch,
  });
  const pathString = serializePath(path);
  const { t } = useTranslation();
  const { hasSemaphore, request: requestSemaphore } = useSemaphore(
    path.join("-")
  );
  return (
    <QALayout.Container>
      <SortableTreeItem id={pathString}>
        {({
          attributes,
          listeners,
          setNodeRef,
          transform,
          transition,
          isDragging,
          projected,
        }) =>
          isDragging ? (
            <div
              className={classNames(
                "z-10 -mb-px",
                "relative box-border flex items-center border border-gray-300 bg-white p-2.5 text-gray-700",
                "!h-1.5 border-blue-500 bg-blue-500 !p-0",
                "before:absolute before:-left-2 before:-top-1 before:block before:h-3 before:w-3 before:rounded-full before:border before:border-blue-500 before:bg-white before:content-['']",
                {
                  "border-blue-500 bg-blue-500 before:border-blue-500":
                    projected?.allowDrop != false,
                  "border-red-300 bg-red-500 before:border-red-500":
                    projected?.allowDrop == false,
                }
              )}
              ref={setNodeRef}
              style={
                {
                  transform: CSS.Translate.toString(transform),
                  transition,
                  left: projected && `${projected.depth}rem`,
                } as CSSProperties
              }
            />
          ) : (
            <QALayout.QuestionContainer
              ref={setNodeRef}
              indent={questionState.indent}
              className={classNames("border-collapse rounded-md", {
                "border-l-2 !border-blue-primary": hasSemaphore,
                "border-gray-200": !hasSemaphore,
              })}
            >
              <QALayout.RemoveButton onClick={remove} />
              <QALayout.Toolbar>
                <QALayout.DragHandle
                  {...attributes}
                  {...listeners}
                  style={
                    {
                      transform: CSS.Translate.toString(transform),
                      transition,
                    } as CSSProperties
                  }
                  visible
                  isDragging={isDragging}
                />
              </QALayout.Toolbar>
              <QALayout.Question
                code={question.code}
                onClick={requestSemaphore}
                extraction={question.extraction}
              />
              <QALayout.Answer show>
                <Answer
                  linkId={path.join(".")}
                  content={questionState.answer}
                  onContentChange={updateContent}
                  values={questionState.values}
                  onValuesChange={updateValues}
                  mode="content-editable"
                />
              </QALayout.Answer>
            </QALayout.QuestionContainer>
          )
        }
      </SortableTreeItem>
      <QALayout.SubQuestionContainer show={!clone}>
        <IterateQuestions
          questions={questionState.subQuestions}
          path={path}
          indexPath={indexPath}
        >
          {({ question: childQuestion, indexPath, path }) => (
            <QuestionAnswerComposer
              question={childQuestion}
              dispatch={dispatch}
              indexPath={indexPath}
              path={path}
            />
          )}
        </IterateQuestions>
      </QALayout.SubQuestionContainer>
      <SettingsPanelPortal>
        <Transition show={hasSemaphore} enter="ease-in-out duration-75">
          <QuestionPropertiesForm
            actionLabel={t("update-question")}
            defaultValues={question}
            onSubmit={updateQuestion}
          />
        </Transition>
      </SettingsPanelPortal>
    </QALayout.Container>
  );
}
export default QuestionAnswerComposer;
