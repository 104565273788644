/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Composition } from '../models/Composition';
import type { FHIRPatientIn } from '../models/FHIRPatientIn';
import type { FHIRPatientOut } from '../models/FHIRPatientOut';
import type { FHIRPractitionerIn } from '../models/FHIRPractitionerIn';
import type { FHIRPractitionerOut } from '../models/FHIRPractitionerOut';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class FhirR5Service {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Read All Practitioners
     * Get all practitioners from the same group as the user
     * @returns any Successful Response
     * @throws ApiError
     */
    public readAllPractitionersFhirR5(): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/fhir/r5/Practitioner/',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Create Practitioner
     * Create a practitioner
     * @returns FHIRPractitionerOut Successful Response
     * @throws ApiError
     */
    public createPractitionerFhirR5({
        requestBody,
    }: {
        requestBody: FHIRPractitionerIn,
    }): CancelablePromise<FHIRPractitionerOut> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/fhir/r5/Practitioner/',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Read All Practitioner Roles
     * Get all practitioners from the same group as the user
     * @returns any Successful Response
     * @throws ApiError
     */
    public readAllPractitionerRolesFhirR5({
        role,
    }: {
        role?: (string | null),
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/fhir/r5/PractitionerRole/',
            query: {
                'role': role,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Create Patient
     * Create a patient
     * @returns any Successful Response
     * @throws ApiError
     */
    public createPatientFhirR5({
        requestBody,
    }: {
        requestBody: FHIRPatientIn,
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/fhir/r5/Patient/',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Read Patient
     * Read a patient
     * @returns FHIRPatientOut Successful Response
     * @throws ApiError
     */
    public readPatientFhirR5({
        patientId,
    }: {
        patientId: number,
    }): CancelablePromise<FHIRPatientOut> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/fhir/r5/Patient/{patient_id}',
            path: {
                'patient_id': patientId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Read Composition
     * Read a patient
     * @returns Composition Successful Response
     * @throws ApiError
     */
    public readCompositionFhirR5({
        reportId,
    }: {
        reportId: number,
    }): CancelablePromise<Composition> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/fhir/r5/Composition/{report_id}',
            path: {
                'report_id': reportId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
