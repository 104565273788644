import { ArrowLeftIcon } from "@heroicons/react/24/outline";
import MailToButton, {
  MailToButtonProps,
} from "@/components/base/MailToButton/MailToButton";
import React from "react";
import { useNavigate } from "react-router-dom";
import OopsImage from "./oops.svg?react";

export interface UnkownErrorProps {
  title?: string;
  description?: string | JSX.Element;
  mailTo?: MailToButtonProps;
}

export default function UnknownError({
  title,
  description,
  mailTo,
  children,
}: React.PropsWithChildren<UnkownErrorProps>) {
  const navigate = useNavigate();
  return (
    <div className="h-screen w-full bg-gray-ultralight px-20 pt-[20vh]">
      <div className="flex w-full items-center justify-center gap-20">
        <OopsImage />
        <div className="max-w-2xl">
          <h1 className="pb-5 text-5xl font-bold text-gray-dark">{title}</h1>
          <main className="pb-5 text-primary">{description}</main>
          {mailTo && (
            <>
              <MailToButton {...mailTo} className="my-px" />
              <span className="mx-5">of</span>
            </>
          )}
          <button
            type="button"
            onClick={() => navigate(-1)}
            className="my-px inline-flex items-center rounded bg-gray-dark px-2 py-1 text-white hover:bg-gray-primary"
          >
            <ArrowLeftIcon className="mr-2 h-4 w-4 stroke-current" /> ga terug
            naar de vorige pagina
          </button>
          {children}
        </div>
      </div>
    </div>
  );
}
