import schema from "@/pages/auth/ask-user-data/schema";
import { QueryClient } from "react-query";
import { ActionFunction } from "react-router";
import client from "@/services/reports/client";
import keyFactory from "@/services/reports/keyFactory";
import { UserIn, UserOut } from "@/services/reports/report-client";
type CreateActionConfig = {
  queryClient?: QueryClient;
  updateUserDataFn: (data: { requestBody: UserIn }) => Promise<UserOut>;
};
export const ACTION_CONFIG_DEFAULTS = {
  updateUserDataFn: (data: { requestBody: UserIn }) =>
    client.v1.updateCurrentUserDataV1(data),
};
export default (config: CreateActionConfig): ActionFunction =>
  async ({ request }): Promise<ActionData> => {
    const { updateUserDataFn, queryClient } = config;
    const payload = await request.formData();
    const formData = Object.fromEntries(payload.entries());
    const parseResult = schema.safeParse(formData);
    if (parseResult.success) {
      const userData = await updateUserDataFn({
        requestBody: parseResult.data,
      });
      queryClient?.setQueryData(keyFactory.currentUserData(), userData);
      return { userData, isSuccessful: true };
    }
    throw new Error("Invalid form data");
  };

export type ActionData = { userData: UserOut; isSuccessful: true };
