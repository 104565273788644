import {
  rand,
  randBetweenDate,
  randFirstName,
  randLastName,
  randNumber,
} from "@ngneat/falso";

import { FormValues } from "./NewPatientForm";

export function generateTestPatient(): FormValues {
  const gender: "male" | "female" = rand(["male", "female"]);
  return {
    identifier:
      "TEST-" +
      randNumber({ min: 0, max: 9999, fraction: 0 })
        .toString()
        .padStart(4, "0"),
    firstName: randFirstName({ gender }),
    lastName: randLastName({ gender }),
    gender,
    dob: randBetweenDate({
      from: new Date("01/01/1940"),
      to: new Date("01/01/2000"),
    })
      .toISOString()
      .slice(0, 10),
  };
}
