import {
  getRedirectResult,
  OAuthProvider,
  signInWithRedirect,
} from "firebase/auth";
import { LoaderFunction } from "react-router";
import { redirect } from "react-router-dom";
import { getTenantAuth, ISS_TO_PROVIDER } from "@/services/tenant";

const loader: LoaderFunction = async ({ request }) => {
  const url = new URL(request.url);

  const params = new URLSearchParams(url.search);
  const iss = params.get("iss");
  const launch = params.get("launch");
  if (!iss || !launch) {
    throw new Response("Invalid SMART on FHIR launch request", {
      status: 400,
    });
  }

  const provider =
    ISS_TO_PROVIDER["https://launch.smarthealthit.org/v/r4/fhir"]();
  provider.setCustomParameters({ launch });
  const auth = getTenantAuth();
  console.log("Reading redirect result");
  const result = await getRedirectResult(auth);
  // User is signed in.
  // IdP data available in result.additionalUserInfo.profile.

  // Get the OAuth access token and ID Token
  //console.log("Got redirect result", result)
  if (!result) {
    await signInWithRedirect(auth, provider);
    return;
  }
  const credential = OAuthProvider.credentialFromResult(result);

  if (!credential) return;
  const accessToken = credential.accessToken;
  const idToken = credential.idToken;
  // @ts-expect-error TODO: Types for this are wrong
  const patient = result.additionalUserInfo?.profile?.patient;
  // @ts-expect-error TODO: Types for this are wrong
  const encounter = result.additionalUserInfo?.profile?.encounter;
  const redirectUrl = new URL("/patients/dispatch");
  redirectUrl.searchParams.set("patient", patient);
  redirectUrl.searchParams.set("encounter", encounter);
  return redirect(redirectUrl.href);
};

export default loader;
