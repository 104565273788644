import { RouteObject } from "react-router";
import { SMART_LAUNCH_PATH } from "./consts";
import SMARTLaunchData from "./LaunchData";
import loader from "./loader";

export default function createRoute(): RouteObject {
  return {
    path: SMART_LAUNCH_PATH,
    element: <SMARTLaunchData />,
    loader,
  }
}
