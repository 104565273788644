import classNames from "classnames";
import { useCallback, useMemo } from "react";
import { Transforms } from "slate";
import {
  ReactEditor,
  useFocused,
  useSelected,
  useSlateStatic,
} from "slate-react";
import { RenderDropdownEntityElementProps } from ".";
import { Concept, IConcept } from "@/data-models/concept";
import DropdownEntityTooltip from "@/components/base/EntityTooltip/DropdownEntityTooltip";
import ValueSetDropdown from "@/components/inputs/Dropdown/legacy/ValueSetDropdown";
function RenderDropdownEntityElement({
  element,
  attributes,
  children,
}: RenderDropdownEntityElementProps) {
  const { entity, placeholder, valueSet, inputType } = element;
  const selectedConcept = useMemo(
    () => entity && new Concept(entity),
    [entity]
  );
  const editor = useSlateStatic() as ReactEditor;
  const selected = useSelected();
  const focused = useFocused();
  const active = selected && focused;

  const handleSelectedChange = useCallback(
    (newConcept: IConcept | null) => {
      const at = ReactEditor.findPath(editor, element);
      Transforms.setNodes(editor, { entity: newConcept }, { at });
    },
    [editor, element]
  );

  return (
    <span className="not-prose inline-block select-none" {...attributes}>
      <DropdownEntityTooltip entity={selectedConcept} valueset={valueSet}>
        <ValueSetDropdown
          valueset={valueSet}
          selected={selectedConcept}
          placeholder={placeholder}
          className={classNames({
            "rounded outline-none ring-2 ring-blue-400": active,
          })}
          onSelectedChange={handleSelectedChange}
        />
      </DropdownEntityTooltip>
      {children}
    </span>
  );
}
export default RenderDropdownEntityElement;
