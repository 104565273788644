import { RouteObject } from "react-router";
import BlockEditPage from "./BlockEditPage";
import { BLOCK_EDIT_PAGE } from "./const";

export default function createRoute(): RouteObject {
  return {
    path: BLOCK_EDIT_PAGE,
    element: <BlockEditPage />,
  };
}
