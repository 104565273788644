import { Panel, PanelGroup, PanelResizeHandle } from "react-resizable-panels";
import { Form, useSearchParams } from "react-router-dom";
import TableSelectionFieldset, {
  TableGroupOption,
} from "./TableSelectionFieldset";
import { useQuery } from "react-query";
import keyFactory from "@/services/reports/keyFactory";
import client from "@/services/reports/client";
import { ArrowDownTrayIcon } from "@heroicons/react/24/outline";
import { CONFIG } from "@/config";
import { getTenantAuth } from "@/services/tenant";
import React, { Suspense, useState } from "react";
import { Spinner } from "@/Icons";
import { TableLoader } from "./Tables";

const Table = React.lazy(() => import("./Tables"));

function ExportPanels() {
  const { data: tableGroups } = useQuery({
    queryKey: keyFactory.groupedExportTablesv2(),
    queryFn: () => client.v2.getAllGroupedTablesV2({ by: "report" }),
  });
  const [searchParams] = useSearchParams();
  const tableIds = searchParams.getAll("table");
  // hack to give the page a name, should come from the backend
  const groupName = searchParams.get("group");
  const groupId = searchParams.get("group-id");
  return (
    <PanelGroup direction="horizontal">
      <Panel
        defaultSize={20}
        className="relative border-r border-blue-ultralight bg-white px-2 py-4"
      >
        <div className="flex h-full flex-col overflow-scroll">
          <h3 className="font-primary text-base font-semibold text-content-primary">
            Beschikbare verslagen
          </h3>
          <Form className="mt-1" method="GET" action="">
            {tableGroups ? (
              <TableSelectionFieldset
                groups={tableGroups as TableGroupOption[]}
              />
            ) : (
              <p>loading...</p>
            )}
          </Form>
        </div>
      </Panel>
      <PanelResizeHandle className="relative -left-0.5 z-10 w-1 cursor-col-resize hover:bg-gray-300" />{" "}
      <Panel
        defaultSize={80}
        className="mx-auto flex flex-col px-4 pt-5 lg:max-w-4xl xl:max-w-7xl"
      >
        {groupName ? (
          <div className="mt-2 flex grow flex-col overflow-hidden">
            <div className="flex justify-between">
              <h3 className="font-primary text-lg font-semibold text-content-primary">
                Exporteer alle data uit {groupName}
              </h3>
            </div>
            <Suspense fallback={<TableLoader />}>
              <Table
                tableIds={tableIds}
                groupId={groupId}
                className="mt-2 flex-grow self-stretch overflow-hidden rounded-md border border-blue-ultralight bg-white shadow-sm"
              />
            </Suspense>
            <div className="py-2">
              <DownloadButton tableIds={tableIds} groupId={groupId} />
            </div>
          </div>
        ) : (
          <div>
            <h2 className="font-mediumstext-content-primary font-primary text-sm">
              Exporteer je data
            </h2>
            <p className="mt-2 text-content-primary">
              Selecteer een verslag om te exporteren
            </p>
          </div>
        )}
      </Panel>
    </PanelGroup>
  );
}

export default ExportPanels;

function DownloadButton({
  tableIds,
  groupId,
}: {
  tableIds: string[];
  groupId: string | null;
}) {
  const [loading, setLoading] = useState(false);
  const handleClick = async () => {
    setLoading(true);
    await downloadTable(tableIds, groupId);
    setLoading(false);
  };
  return (
    <button
      type="button"
      className="inline-flex items-baseline rounded bg-blue-primary px-2 py-1 font-primary text-sm font-medium text-white hover:bg-blue-700"
      onClick={handleClick}
    >
      {loading ? (
        <Spinner className="relative top-0.5 mr-1 inline h-3 w-3 animate-spin" />
      ) : (
        <ArrowDownTrayIcon className="relative top-0.5 mr-1 inline h-3 w-3" />
      )}
      Download
    </button>
  );
}

async function downloadTable(tableIds: string[], groupId: string) {
  const auth = getTenantAuth();
  const params = new URLSearchParams();
  tableIds.forEach((tableId) => params.append("table", tableId));
  let filename: string = `export-${groupId.toLowerCase().replace(/ /g, "-")}-${
    new Date().toISOString().split("T")[0]
  }.xlsx`;
  if (groupId) params.append("report", groupId);
  params.append("filename", filename);
  const url = `${
    CONFIG.REPORT_SERVER
  }/api/v2/export/tables?${params.toString()}`;
  const headers = new Headers({
    "Content-Type":
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  });
  if (auth.currentUser) {
    console.debug("Adding auth header to download request.");
    await auth.currentUser.getIdToken().then((token) => {
      headers.set("Authorization", `Bearer ${token}`);
    });
  } else {
    console.warn("No user logged in.");
  }

  await fetch(url, { headers })
    .then((res) => {
      try {
        const disposition = res.headers.get("Content-Disposition");
        if (!disposition) {
          console.warn("No filename found in response headers.");
          return res.blob();
        }
        filename = disposition.split(/;(.+)/)[1].split(/=(.+)/)[1];
        if (filename.toLowerCase().startsWith("utf-8''"))
          filename = decodeURIComponent(filename.replace(/utf-8''/i, ""));
        else filename = filename.replace(/['"]/g, "");
      } catch (e) {
        console.error("Error parsing filename from response headers: ", e);
      }
      return res.blob();
    })
    .then((blob) => {
      var url = window.URL.createObjectURL(blob);
      var a = document.createElement("a");
      a.href = url;
      a.download = filename;
      document.body.appendChild(a).click(); // append the element to the dom and click it
      a.remove(); // afterwards, remove the element
    });
}
