import createProtector, {
  PROTECTOR_CONFIG_DEFAULTS,
} from "@/pages/auth/createProtector";
import { QueryClient } from "react-query";
import { redirect, RouteObject } from "react-router";
import { SETTINGS_PATH } from "./const";
import Layout from "./Layout";
import { PROFILE_SUBPATH } from "./Profile/consts";
import createProfileRoute from "./Profile/createRoute";
import createServerSettingsRoute from "./ServerSettings/createRoute";

export default (queryClient: QueryClient): RouteObject => ({
  path: SETTINGS_PATH,
  loader: createProtector({ queryClient, ...PROTECTOR_CONFIG_DEFAULTS }),
  element: <Layout />,
  children: [
    { index: true, loader: () => redirect(PROFILE_SUBPATH) },
    createProfileRoute(queryClient),
    createServerSettingsRoute(queryClient),
  ],
});
