/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type SimpleViewDefinition = {
    resourceType?: SimpleViewDefinition.resourceType;
    name: string;
    version?: (string | null);
    select?: any[];
};

export namespace SimpleViewDefinition {

    export enum resourceType {
        VIEW_DEFINITION = 'ViewDefinition',
    }


}

