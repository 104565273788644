import { TypedElement } from "@/editor/TypedElement";
import { Node } from "slate";

export function removeTitleNodeIfExits<N extends Node>(nodes: N[]) {
  const [firstNode, ...otherNodes] = nodes;
  if (TypedElement.isTypedElement(firstNode) && firstNode.type === "title") {
    return otherNodes;
  }
  return nodes;
}
