import ValidationPanel from "@/components/controlled/ValidationPanel";
import { useLaagVariabeleZorg } from "@/demo-lvz/laagvariabelezorg";
import { QualityPanel as QualityPanelLVZ } from "@/demo-lvz/QualityPanel";
import { ReportOut } from "@/services/reports/report-client";

export interface DemoFeaturesProps {
  report: ReportOut;
}
/**
 *  Some demo features that are only relevant in specific demo scenario.
 * @param param0
 * @returns
 */
function DemoFeatures({ report }: DemoFeaturesProps) {
  const isLVZ = useLaagVariabeleZorg(report);

  return (
    <>
      {report.template === "radicale-prostatectomie-hospitalisatie-verslag" && (
        <div className="overflow-y-auto">
          <QualityPanelLVZ lvz={isLVZ} />
        </div>
      )}
      {report.template !== "radicale-prostatectomie-hospitalisatie-verslag" && (
        <div className="overflow-y-auto">
          <ValidationPanel />
        </div>
      )}
    </>
  );
}

export default DemoFeatures;
