import { Column } from "./types";
import uuidjs from "uuidjs";

export const defaultCellValue = (column: Column) => {
  switch (column.type) {
    case "DATE":
      return "";
    case "PERIOD":
      return { start: "", end: "" };
    case "DATE_OR_PERIOD":
      return "";
    case "TEXT":
      return [{ children: [{ text: "" }] }];
    case "QUANTITY": {
      return null;
    }
    case "DROPDOWN": {
      return null;
    }
    default:
      return null;
  }
};

export const generateUniqueId = () => uuidjs.genV4().hexString;
