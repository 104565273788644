import { ICoding } from "@/data-models/value-models";
import { Describe, optional, string, type } from "superstruct";

export interface IDesignation {
  value: string;
  language?: string;
  use?: Partial<ICoding> & { display: string };
}

export const UseCodingModel: Describe<Partial<ICoding> & { display: string }> =
  type({
    display: string(),
    system: optional(string()),
    code: optional(string()),
  });

export const DesignationModel: Describe<IDesignation> = type({
  value: string(),
  language: optional(string()),
  use: optional(UseCodingModel),
});
