import React, { useContext } from "react";
import ReactDOM from "react-dom";
export const Portal = ({
  children,
  refElement,
}: {
  children?: React.ReactNode;
  refElement?: HTMLElement | null;
}) => {
  const contextRefElement = useContext(PortalReferenceContext)?.current;
  return typeof document === "object"
    ? ReactDOM.createPortal(
        children,
        refElement ?? contextRefElement ?? document.body
      )
    : null;
};

export const PortalReferenceContext =
  React.createContext<React.MutableRefObject<HTMLElement | null> | null>(null);
