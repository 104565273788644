import client from "@/services/reports/client";
import keyFactory from "@/services/reports/keyFactory";
import { useQuery } from "react-query";
import { useParams } from "react-router";
import { useSearchParams } from "react-router-dom";

function HTMLPrintPreview() {
  const { reportId: reportIdString } = useParams<"reportId">();
  const [searchParams] = useSearchParams();
  const refetchInterval = searchParams.get("refetchInterval")
    ? parseInt(searchParams.get("refetchInterval")!) * 1000
    : undefined;
  const reportId = reportIdString ? parseInt(reportIdString) : undefined;
  const { data } = useQuery({
    queryKey: keyFactory.htmlView(reportId),
    queryFn: () => client.v1.getHtmlV1({ reportId: reportId! }),
    enabled: reportId !== undefined,
    refetchInterval,
  });

  return (
    <div
      className="prose-sm m-5 mx-auto max-w-[800px] rounded-md border p-12"
      dangerouslySetInnerHTML={{
        __html: data || "",
      }}
    />
  );
}
export default HTMLPrintPreview;
