import { Describe, literal, never, optional, string, type } from "superstruct";
import { RENDER_TYPE } from "./consts";
export interface IFreeTextContent {
  data?: { text?: string };
  columns?: never;
}
export const FreeTextContentModel: Describe<IFreeTextContent> = type({
  data: optional(type({ text: optional(string()) })),
  columns: optional(never()),
});

export const RenderTypeModel = literal(RENDER_TYPE);

export default FreeTextContentModel;
