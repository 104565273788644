import { TemplateTypeModel } from "@/data-models/template";
import { useQuery } from "react-query";
import { Infer } from "superstruct";

import { validateTemplates } from "./templates";
import { apm } from "@elastic/apm-rum";
import client, { ContentClient } from "./client";
import keyFactory from "./keyFactory";

export interface ITemplate extends Infer<typeof TemplateTypeModel> {}

export const createTemplateQuery = (client: ContentClient) => {
  return {
    queryKey: keyFactory.allTemplateReports(),
    queryFn: async () => client.templates.getAllTemplatesV1({}),
  };
};

export const useTemplates = (
  pathways: string[] = [],
  options: { enabled?: boolean } = {},
) => {
  const { enabled = true } = options;
  return useQuery(
    ["templates", { pathways }],
    async () => {
      const result = await client.templates.getAllTemplatesV1({});
      return validateTemplates(result);
    },
    {
      enabled,
      select: (templates) =>
        templates.filter((t) => {
          if (pathways.length == 0) {
            return false;
          }
          if (pathways.length == 1 && pathways[0] == "all") {
            return true;
          }
          return t.pathway && t.pathway.some((p) => pathways.includes(p));
        }),
    },
  );
};

export const useTemplate = (
  id: string,
  options: { enabled?: boolean } = {},
) => {
  const { enabled = true } = options;

  return useQuery(
    keyFactory.templateReport(id),
    async () => {
      const tx = apm.getCurrentTransaction();
      const span = tx?.startSpan("getTemplate");
      span?.addLabels({ template: id });
      const response = await client.templates.getTemplateByIdV1({
        templateId: id,
      });
      span?.end();
      return response;
    },
    { enabled: enabled },
  );
};
