import useStableCallback from "@/hooks/useStableCallback";
import { useEffect, useRef, useState } from "react";
import {
  FieldValues,
  Path,
  useFormContext,
  UseFormReturn,
} from "react-hook-form";
import isEnabled from "./isEnabled";
import { IEnabledWhen } from "./types";

function hasMatchingLinkdId(name: string, { conditions }: IEnabledWhen) {
  return conditions.some(
    (c) => c.linkId == name || name.startsWith(c.linkId + ".")
  );
}

type UseConditionallyEnabledArgs<
  TFieldValues extends FieldValues,
  TName extends Path<TFieldValues>
> = {
  /**
   * The enabledWhen object from the form definition
   * Make sure has a stable reference to avoid unnecessary rerenders and infinite loops
   * This is loosely based on the FHIR Questionnaire definition
   * @see https://www.hl7.org/fhir/questionnaire-definitions.html#Questionnaire.item.enableWhen
   */
  enabledWhen: IEnabledWhen | undefined;
  /**
   * The linkId of the field to enable/disable
   */
  linkId: TName;
  /**
   * Optional callback to be called when the enabled state changes
   */
  onChange?: (enabled: boolean) => void;
};

export default function useConditionallyEnabled<
  TFieldValues extends FieldValues = FieldValues,
  TName extends Path<TFieldValues> = Path<TFieldValues>
>(
  {
    enabledWhen,
    linkId,
    onChange,
  }: UseConditionallyEnabledArgs<TFieldValues, TName>,
  formContext?: UseFormReturn<TFieldValues>
) {
  const [enabled, setEnabled] = useState(true);
  const onChangeStable = useStableCallback(onChange);
  const enabledRef = useRef(true); // Keep track of enabled state while avoiding unnecessary rerenders
  enabledRef.current = enabled;

  const context = useFormContext<TFieldValues>() || formContext;
  if (!context)
    throw new Error(
      "useConditionallyEnabled must be used within a <FormProvider /"
    );
  const { watch, resetField, getValues } = context;

  useEffect(() => {
    if (!enabledWhen) return;

    // Update enabled state on mount
    const isEnabledResult = isEnabled(enabledWhen, getValues());
    onChangeStable?.(isEnabledResult);
    setEnabled(isEnabledResult);
    // Expect that field value to be the defaultValue so no need to reset it

    const subscription = watch((data, { name }) => {
      if (!name || hasMatchingLinkdId(name, enabledWhen)) {
        // Update enabled state when watched fields change
        const isEnabledResult = isEnabled(enabledWhen, data);
        console.log("isEnabledResult", isEnabledResult, {
          enabledWhen,
          data,
          name,
        });
        isEnabledResult !== enabledRef.current &&
          onChangeStable?.(isEnabledResult);
        setEnabled(isEnabledResult);
        if (!isEnabledResult) resetField(linkId);
      }
    });
    return () => subscription.unsubscribe();
  }, [enabledWhen, watch, getValues, linkId, resetField, onChangeStable]);
  return enabled;
}
