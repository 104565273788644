import { ConceptEntityModel } from "@/data-models/concept-entity";
import { QuantityModel } from "@/data-models/quantity-entity";
import { DateEntityModel } from "@/data-models/date-entity";
import { is } from "superstruct";
import ConceptEntityTooltip, {
  IConceptEntityTooltipProps,
} from "./ConceptEntityTooltip";
import QuantityEntityTooltip, {
  IQuantityEntityTooltipProps,
} from "./QuantityEntityTooltip";
import DateEntityTooltip, {
  IDateEntityTooltipProps,
} from "./DateEntityTooltip";
import { TimeEntityModel } from "@/data-models/time-entity";
import TimeEntityTooltip, {
  ITimeEntityTooltipProps,
} from "./TimeEntityTooltip";
import { DateTimeEntityModel } from "@/data-models/datetime-entity";
import DateTimeEntityTooltip from "./DateTimeEntityTooltip";

type TooltipProps =
  | IConceptEntityTooltipProps
  | IQuantityEntityTooltipProps
  | ITimeEntityTooltipProps
  | IDateEntityTooltipProps
  | any;
const EntityTooltip = (props: TooltipProps) => {
  const { entity } = props;
  if (is(entity, ConceptEntityModel))
    return <ConceptEntityTooltip {...props} />;
  if (is(entity, QuantityModel)) return <QuantityEntityTooltip {...props} />;
  if (is(entity, DateEntityModel)) return <DateEntityTooltip {...props} />;
  if (is(entity, DateTimeEntityModel))
    return <DateTimeEntityTooltip {...props} />;
  if (is(entity, TimeEntityModel)) return <TimeEntityTooltip {...props} />;
  return props.children;
};

export default EntityTooltip;
