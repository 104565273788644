import { RouteObject } from "react-router";
import { RESET_PASSWORD_PAGE } from "./const";
import PageContainer from "../PageContainer";
import ResetPasswordPanel from "./ResetPasswordPanel";
import createAction from "./createAction";

export default function createRoute(): RouteObject {
  return {
    path: RESET_PASSWORD_PAGE,
    element: <ResetPassword />,
    action: createAction(),
  };
}

function ResetPassword() {
  return (
    <PageContainer logo className="h-full">
      <ResetPasswordPanel />
    </PageContainer>
  );
}
