function RenderCode({ code }: { code: string }) {
  return (
    <data
      value={code}
      className="inline-block max-w-[120px] truncate align-bottom"
    >
      {code}
    </data>
  );
}

export default RenderCode;
