import { useSlateSelector } from "slate-react";
import {
  AddEntryButtonContainer,
  AddTextEntryButton,
  AddTimestampedEntryButton,
} from "@/editor/legacy/EntryLayoutPlugin/AddEntryButton";
import { IBlockEditor } from "@/editor/legacy/BlockLayoutPlugin/types";

export const Footer = () => {
  const renderType = useSlateSelector(
    (editor) => (editor as IBlockEditor).renderType,
  );
  return (
    <AddEntryButtonContainer>
      {renderType === "timestamped-rich-text" && <AddTimestampedEntryButton />}
      {renderType !== "table-rich-text" && <AddTextEntryButton />}
    </AddEntryButtonContainer>
  );
};
