import { Portal } from "@/components/base/Portal";
import React, { useContext, useState } from "react";

const SettingsPanelCallbackContext = React.createContext<
  React.RefCallback<HTMLDivElement>
>(() => {});
const SettingsPanelNodeContext = React.createContext<HTMLDivElement | null>(
  null
);

export const useSettingsPanelCallbackRef = () => {
  const portalNode = React.useContext(SettingsPanelNodeContext);
  if (!portalNode) {
    throw new Error("No portal node found");
  }
  return portalNode;
};

export const SettingsPanelPortal = ({
  children,
}: React.PropsWithChildren<{}>) => {
  const portalRef = useContext(SettingsPanelNodeContext);
  return <Portal refElement={portalRef}>{children}</Portal>;
};

export const SettingsPanelProvider = ({
  children,
}: React.PropsWithChildren<{}>) => {
  const [portalRef, setPortalRef] = useState<HTMLDivElement | null>(null);

  return (
    <SettingsPanelNodeContext.Provider value={portalRef}>
      <SettingsPanelCallbackContext.Provider value={setPortalRef}>
        {children}
      </SettingsPanelCallbackContext.Provider>
    </SettingsPanelNodeContext.Provider>
  );
};

export const RenderSettingsPanel = ({
  children,
}: React.PropsWithChildren<{}>) => {
  const callbackRef = useContext(SettingsPanelCallbackContext);
  return <div ref={callbackRef}>{children}</div>;
};
