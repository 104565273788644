import produce, { setAutoFreeze } from "immer";
import { WritableDraft } from "immer/dist/internal";
import setWith from "lodash/setWith";
import clone from "lodash/clone";
import { useMemo, useReducer } from "react";
import {
  QAAction,
  ActionTypes,
  IQuestionItem,
  QAState,
  RecursiveQuestionNode,
  IQuestionNode,
} from "./models";
import { func, is } from "superstruct";

setAutoFreeze(false);
const PATH_SEP: `.${keyof IQuestionItem}.` = ".questions." as const; // safety check to make sure this equals a field of a question group

function getCommonPath(path1: number[], path2: number[]) {
  let i;
  for (i = 0; i < Math.min(path1.length, path2.length); i++) {
    if (path1[i] != path2[i]) return path1.slice(0, i);
  }
  return path1.slice(0, i);
}

function getQuestionByPath<T>(
  questions: WritableDraft<RecursiveQuestionNode<T>[]>,
  path: number[],
) {
  const parentPath = [...path];
  const index = parentPath.pop() as number;
  return parentPath.reduce((childQuestions, index) => {
    const rootQuestion = childQuestions[index];
    if (rootQuestion.questions) return rootQuestion.questions;
    rootQuestion.questions = [];
    return rootQuestion.questions;
  }, questions)[index];
}

function getChildQuestionsByPath<T>(
  questions: WritableDraft<RecursiveQuestionNode<T>[]>,
  path: number[],
) {
  return path.reduce((childQuestions, index) => {
    const rootQuestion = childQuestions[index];
    if (rootQuestion.questions) return rootQuestion.questions;
    rootQuestion.questions = [];
    return rootQuestion.questions ?? [];
  }, questions);
}

function* iterDescendants<T>(
  questions: RecursiveQuestionNode<T>[],
): Generator<RecursiveQuestionNode<T>> {
  for (const question of questions) {
    yield question;
    if (question.questions) {
      yield* iterDescendants(question.questions);
    }
  }
}

interface CreateReducerOptions {
  onStateChange?: (state: QAState, newState: QAState) => void;
}
function createReducer(options: CreateReducerOptions = {}) {
  function reducer(state: QAState, action: QAAction) {
    let newState = state;
    switch (action.type) {
      case ActionTypes.ADD_QUESTION:
        {
          const path = action.path.join(PATH_SEP);
          newState = setWith(clone(state), path, action.question, clone);
        }
        break;
      case ActionTypes.EDIT_QUESTION: {
        newState = produce(newState, (questions) => {
          const targetQuestion = getQuestionByPath(questions, action.path);
          Object.assign(targetQuestion, action.question);
        });
        break;
      }
      case ActionTypes.TOGGLE_DISCLOSURE: {
        newState = produce(newState, (questions) => {
          const targetQuestion = getQuestionByPath(questions, action.path);
          targetQuestion.collapsed = !targetQuestion.collapsed;
        });
        break;
      }
      case ActionTypes.MOVE_QUESTION: {
        newState = produce(state, (questions) => {
          const commonPath = getCommonPath(action.src, action.dest);
          if (commonPath.length == action.src.length) return;
          /* Recalculate destination path taking into account that
           * the source question is already removed and can affect the positioning of the destination
           */
          if (
            action.src.length <= action.dest.length && // removing the source element can only affect dest element if
            action.src.length == commonPath.length + 1 &&
            action.src[commonPath.length] < action.dest[commonPath.length]
          )
            action.dest[commonPath.length] -= 1;
          // split path and leaf index for source
          const srcParentPath = [...action.src];
          const srcIndex = srcParentPath.pop() as number;
          const srcQuestions = getChildQuestionsByPath(
            questions,
            srcParentPath,
          );
          // remove the source question
          const srcQuestion = srcQuestions.splice(srcIndex, 1)[0];
          // split path and leaf index for dest
          const destParentPath = [...action.dest];
          const destIndex = destParentPath.pop() as number;
          const destQuestions = getChildQuestionsByPath(
            questions,
            destParentPath,
          );
          // insert it into the destination path
          destQuestions.splice(destIndex, 0, srcQuestion);
        });
        break;
      }
      case ActionTypes.REMOVE_QUESTION: {
        const leafIndex = action.path.pop();
        if (leafIndex === undefined)
          throw Error("Invalid path passed to reducer.");
        newState = produce(newState, (questions) => {
          let leafQuestions = getChildQuestionsByPath(questions, action.path);
          leafQuestions.splice(leafIndex, 1);
        });
        break;
      }
      case ActionTypes.UPDATE_ANSWER: {
        newState = produce(newState, (questions) => {
          // TODO: how to check that the path matches a question item?
          const targetQuestion = getQuestionByPath(
            questions,
            action.path,
          ) as IQuestionItem;
          targetQuestion.answer = action.answer;
        });
        break;
      }
      case ActionTypes.UPDATE_VALUES: {
        newState = produce(newState, (questions) => {
          // TODO: how to check that the path matches a question item?
          const targetQuestion = getQuestionByPath(
            questions,
            action.path,
          ) as IQuestionItem;
          targetQuestion.values = action.values;
        });
        break;
      }
      case ActionTypes.UPDATE_QUESTIONS:
        {
          newState = is(action.updater, func())
            ? action.updater(state)
            : action.updater;
        }
        break;
      case ActionTypes.CLEAR_VALUES:
        {
          newState = produce(newState, (questions) => {
            // TODO: how to check that the path matches a question item?
            const targetQuestion = getQuestionByPath(
              questions,
              action.path,
            ) as IQuestionItem;
            targetQuestion.values = {};
            for (const nestedQuestion of iterDescendants(
              targetQuestion.questions ?? [],
            )) {
              nestedQuestion.values = {};
            }
          });
        }
        break;
    }
    const { onStateChange } = options;
    onStateChange && onStateChange(state, newState);
    return newState;
  }
  return reducer;
}
export default createReducer;

interface UseQuestionnaireReducerOptions {
  key?: string;
  enableScoring?: boolean;
  /** Callback function that is called when the questions change. The callback should be memoized to prevent unnecessary rerenders. */
  onQuestionsChange?: (question: IQuestionNode[]) => void;
}

export function useCreateQuestionnaireReducer({
  onQuestionsChange,
}: UseQuestionnaireReducerOptions = {}) {
  return useMemo(() => {
    const options: CreateReducerOptions = {
      onStateChange: (state, newState) =>
        onQuestionsChange && onQuestionsChange(newState),
    };
    return createReducer(options);
  }, [onQuestionsChange]);
}

export function useQuestionnaireReducer(
  initialState: QAState,
  onQuestionsChange?: (question: IQuestionNode[]) => void,
) {
  const reducer = useCreateQuestionnaireReducer({ onQuestionsChange });
  return useReducer(reducer, initialState);
}
