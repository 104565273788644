import classNames from "classnames";
import SlateSuggestEditor from "@/components/controlled/SlateSuggestEditor";

import { useEffect, useMemo } from "react";
import AddCheckElement from "@/editor/legacy/CheckPlugin/AddCheckElement";
import constructAnswerEditor from "./createAnswerEditor";
import { ITiroElement } from "@/data-models/nodes";
import AnswerForm from "./AnswerForm";
import { DefaultValues, FieldValues } from "react-hook-form";
import { Editor } from "slate";

export interface AnswerProps<
  C extends ITiroElement[] = ITiroElement[],
  V extends FieldValues = FieldValues,
> {
  linkId: string;
  className?: string;
  content: C | undefined;
  onContentChange: (v: C) => void;
  values: V | undefined;
  onValuesChange: (v: V) => void;
  initialExpressions?: Record<string, string>;
  mute?: boolean;
  mode?: "content-editable" | "data-entry";
}

const Styles = {
  CONTENT_EDITABLE:
    "border border-gray-200 focus-within:border-blue-primary focus-within:outline-1 focus-within:outline-blue-primary focus-within:drop-shadow-sm px-1 py-0.5",
  DATA_INPUT: "",
};
function Answer<
  C extends ITiroElement[] = ITiroElement[],
  V extends FieldValues = FieldValues,
>({
  linkId,
  content,
  values,
  initialExpressions,
  className,
  mute,
  onContentChange,
  onValuesChange,
  mode = "content-editable",
}: AnswerProps<C, V>) {
  const editor = useMemo(() => constructAnswerEditor({ mute }), [mute, mode]);
  const Footer = useMemo(() => <AddCheckElement />, []);
  if (!content) return null;

  return (
    <AnswerForm
      linkId={linkId}
      values={values as DefaultValues<V> | undefined}
      initialExpressions={initialExpressions}
      onValuesChange={onValuesChange}
    >
      <SlateSuggestEditor
        className={classNames(
          "relative rounded  bg-white",
          {
            [Styles.CONTENT_EDITABLE]: mode === "content-editable",
            [Styles.DATA_INPUT]: mode === "data-entry",
          },
          className,
        )}
        editableFooter={Footer}
        onDrop={console.log}
        editor={editor}
        readOnly={mode === "data-entry"}
        contentEditable={mode === "content-editable"}
        value={content}
        setValue={onContentChange}
      />
    </AnswerForm>
  );
}
export default Answer;
