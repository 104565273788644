import useStableCallback from "@/hooks/useStableCallback";
import React, { useCallback } from "react";
import { FieldPathByValue, FieldValues, useFormContext } from "react-hook-form";
import {
  empty,
  is,
  number,
  string,
  date,
  boolean,
  nonempty,
  array,
  object,
} from "superstruct";
import {
  BadgeAutoFocusContext,
  BadgeColorContext,
  BadgeModeContext,
  BadgeNameContext,
  BadgeStyleContext,
  BadgeSwitchModeContext,
} from "./context";
import EditBadge from "./EditBadge";
import LoadingBadge from "./LoadingBadge";
import { IsEmptyFunc } from "./types";
import useEditMode from "./useEditMode";
import ViewBadge from "./ViewBadge";

export interface BadgeProps<
  TFieldValues extends FieldValues,
  TValue = any,
  TPath extends FieldPathByValue<TFieldValues, TValue> = FieldPathByValue<
    TFieldValues,
    TValue
  >,
> {
  style?: "default" | "table" | "tile";
  color?: "blue" | "green";
  name: TPath;
  defaultMode?: "edit" | "view";
  defaultAutoFocus?: boolean;
  onInvalid?: (value: unknown) => void;
  isEmptyFn?: IsEmptyFunc<TFieldValues>;
  onChangeMode?: (mode: "edit" | "view") => void;
}

const DEFAULT_IS_EMPTY: IsEmptyFunc<any> = ({ value }: { value: any }) => {
  if (value == null) return true; // null is empty
  if (value === undefined) return true; // undefined is empty
  if (is(value, string())) return is(value.trim(), empty(string())); // empty string is empty
  if (is(value, number())) return false; // numbers are not empty
  if (is(value, date())) return false; // dates are not empty
  if (is(value, boolean())) return false; // booleans are not empty
  if (is(value, object())) return false; // objects are not empty
  if (is(value, nonempty(array()))) return false; // nonempty arrays are not empty
  return true; // everything else is empty
};

function Badge<
  TFieldValues extends FieldValues = FieldValues,
  TValue extends any = any,
  TName extends FieldPathByValue<TFieldValues, TValue> = FieldPathByValue<
    TFieldValues,
    TValue
  >,
>({
  name,
  children,
  defaultMode,
  defaultAutoFocus = false,
  onInvalid,
  isEmptyFn = DEFAULT_IS_EMPTY,
  onChangeMode,
  style = "default",
  color = "blue",
  ...props
}: React.PropsWithChildren<BadgeProps<TFieldValues, TValue, TName>>) {
  const { trigger, getValues } = useFormContext<TFieldValues>();
  const [editMode, setEditMode] = useEditMode<TFieldValues>(
    name,
    defaultMode,
    isEmptyFn,
  );
  const [autoFocus, setAutoFocus] = React.useState(defaultAutoFocus);
  const onInvalidStable = useStableCallback(onInvalid);
  const switchToMode = useCallback(
    async (mode: "edit" | "view") => {
      if (mode === "edit") {
        setEditMode(true);
        setAutoFocus(true);
        onChangeMode && onChangeMode(mode);
      } else {
        const isEmpty = isEmptyFn({ value: getValues(name), getValues });
        const isValid = await trigger(name);
        if (isEmpty) return;
        if (!isValid) {
          onInvalidStable?.(getValues(name));
          return;
        }
        setEditMode(false);
        setAutoFocus(false);
        onChangeMode && onChangeMode(mode);
      }
    },
    [
      setEditMode,
      setAutoFocus,
      trigger,
      onInvalidStable,
      getValues,
      isEmptyFn,
      name,
      onChangeMode,
    ],
  );
  return (
    <BadgeNameContext.Provider value={name}>
      <BadgeSwitchModeContext.Provider value={switchToMode}>
        <BadgeAutoFocusContext.Provider value={autoFocus}>
          <BadgeModeContext.Provider value={editMode ? "edit" : "view"}>
            <BadgeStyleContext.Provider value={style}>
              <BadgeColorContext.Provider value={color}>
                {children}
              </BadgeColorContext.Provider>
            </BadgeStyleContext.Provider>
          </BadgeModeContext.Provider>
        </BadgeAutoFocusContext.Provider>
      </BadgeSwitchModeContext.Provider>
    </BadgeNameContext.Provider>
  );
}

Badge.Editor = EditBadge;
Badge.Viewer = ViewBadge;
Badge.Loader = LoadingBadge;

export default Badge;
