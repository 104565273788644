import { CheckCircleIcon } from "@heroicons/react/20/solid";
import classNames from "classnames";
import ValidationCard from "@/components/InfoSideBar/ValidationCard/ValidationCard";
import React from "react";
import { useTranslation } from "react-i18next";
import { useValidationScoreBoardTotal } from "@/services/validation";

interface ValidationPanelProps extends React.HTMLAttributes<HTMLDivElement> {}
export default function ValidationPanel({
  className,
  ...props
}: ValidationPanelProps) {
  const [score, vsb] = useValidationScoreBoardTotal();
  const { t } = useTranslation();
  return (
    <div className={classNames("w-full", className)} {...props}>
      <ValidationCard title={t("volledigheid-rapport")} className="">
        <ValidationCard.Content className="space-y-2">
          {vsb.total == 0 && (
            <h4 className="text-sm text-gray-dark">
              {t("geen-verplichte-vragen")}
            </h4>
          )}
          {vsb.totalInvalid > 0 && (
            <h4 className="text-sm text-gray-dark">
              {t("nog")} {vsb.totalInvalid} {t("verplichte")}{" "}
              {vsb.totalInvalid > 1 ? t("vragen") : t("vraag-0")}{" "}
              {t("in-te-vullen")}.
            </h4>
          )}
          {vsb.totalScore > 0 && (
            <h4 className=" text-sm text-gray-dark">
              {t("dit-verslag-ziet-er-volledig-uit")}{" "}
              <CheckCircleIcon className="mx-1 inline h-5 w-5 text-green-600" />
            </h4>
          )}
          <ul>
            {vsb.missing.map((v) => (
              <li key={v}>
                <a
                  href="#"
                  className="px-2 text-sm text-gray-600 hover:text-blue-500"
                >
                  {v}
                </a>
              </li>
            ))}
          </ul>
          <ValidationCard.Progress value={score} max={1} />
        </ValidationCard.Content>
      </ValidationCard>
    </div>
  );
}
