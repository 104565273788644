import { Editor, Node, NodeEntry, Range, Transforms } from "slate";
import { TypedElement } from "../../TypedElement";
import TitleElement from "../BlockLayoutPlugin/TitleElement";
import { TIME_TIMESTAMP } from "../EntryLayoutPlugin/consts";
import { IEntryEditor } from "../EntryLayoutPlugin/withEntryLayout";
import { EntryElement } from "../EntryLayoutPlugin/EntryElement";
import { TimeEntryTimestamp } from "../EntryLayoutPlugin/types";
const NOP = () => undefined;

export const withMedicalHistory = <T extends IEntryEditor>(editor: T): T => {
  const { normalizeNode, insertBreak } = editor;

  editor.insertIndent = NOP;
  editor.insertBreak = () => {
    const { selection } = editor;
    const isSelectionCollapsed = selection && Range.isCollapsed(selection);
    const isNotInTitle = !Editor.above(editor, {
      match: TitleElement.isTitleElement,
    });
    if (isSelectionCollapsed && isNotInTitle) {
      Transforms.splitNodes(editor, {
        always: true,
        mode: "highest",
        match: (n) => !Editor.isEditor(n),
      });
      return;
    }
    insertBreak();
  };

  editor.normalizeNode = (entry: NodeEntry<Node>) => {
    const [node, path] = entry;
    if (
      EntryElement.isCollection(node) &&
      node.children.length === 1 &&
      EntryElement.isTimestampedEntryTimestamp(node.children[0])
    ) {
      Transforms.unwrapNodes(editor, { at: path });
      return;
    }
    if (
      EntryElement.isCollection(node) &&
      node.children.length === 1 &&
      !EntryElement.isTimestampedEntryTimestamp(node.children[0])
    ) {
      Transforms.insertNodes<TimeEntryTimestamp>(
        editor,
        { type: TIME_TIMESTAMP, children: [{ text: "" }] },
        { at: path.concat(0), select: true }
      );
      return;
    }
    if (TypedElement.isTypedElement(node) && node.type === TIME_TIMESTAMP) {
      const [parentNode] = Editor.parent(editor, path);
      if (!EntryElement.isCollection(parentNode)) {
        Transforms.removeNodes(editor, { at: path });
        return;
      }
      if (path[path.length - 1] !== 0) {
        Transforms.removeNodes(editor, { at: path });
        return;
      }
    }
    return normalizeNode(entry);
  };

  return editor;
};
