import React from "react";
import { RecursiveQuestionNode, WithKey } from "../models";
import { Flattened } from "./types";
import { getProjection } from "./utilities";
export type SortableTreeContextValue =
  | {
      activeItem: Flattened<RecursiveQuestionNode<WithKey>>;
      activePathString: string;
      projected: ReturnType<typeof getProjection>;
    }
  | { activeItem: null; activePathString: null; projected: null };
const INIT_CONTEXT: SortableTreeContextValue = {
  activeItem: null,
  activePathString: null,
  projected: null,
};
export const SortableTreeContext =
  React.createContext<SortableTreeContextValue>(INIT_CONTEXT);

export function useSortableTreeContext<T extends Record<string, any>>() {
  const context = React.useContext(SortableTreeContext);
  // typecast because context can't b generic
  const activeItem = context.activeItem as Flattened<
    RecursiveQuestionNode<WithKey<T>>
  > | null;

  return { ...context, activeItem };
}
