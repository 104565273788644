import Tippy from "@tippyjs/react";
import classNames from "classnames";
import React, { useRef, useState } from "react";

export interface BlockTitleInputProps
  extends Omit<
    React.HTMLAttributes<HTMLInputElement>,
    "value" | "onChange" | "defaultValue"
  > {
  blockType?: string;
  title: string;
  onTitleChange?: (title: string) => any;
}

const Wrapper = (props: React.PropsWithChildren<{ blockType?: string }>) => {
  return (
    <div className="group relative grow">
      {props.blockType && (
        <div className="invisible absolute -top-[25px] left-0 z-10 group-focus-within:visible">
          <Tippy content="Type bouwblok" delay={[50, 500]} placement="bottom">
            <button className="inline-flex items-center rounded bg-blue-primary px-2 py-0.5 text-xs text-white">
              {props.blockType}
            </button>
          </Tippy>
        </div>
      )}
      {props.children}
    </div>
  );
};

function BlockTitleInput({
  className,
  title: defaultTitle,
  onTitleChange,
  blockType,
  ...otherProps
}: BlockTitleInputProps) {
  const formRef = useRef<HTMLFormElement>(null);
  const handleSubmit: React.FormEventHandler = (event) => {
    event.preventDefault();
    onTitleChange?.(title);
  };
  const handleBlur: React.FocusEventHandler = () => {
    formRef.current?.requestSubmit();
  };
  const [title, setTitle] = useState(defaultTitle);
  return (
    <Wrapper blockType={blockType}>
      <form ref={formRef} onSubmit={handleSubmit} className="w-full pr-1">
        <input
          type="text"
          className={classNames(
            "mb-1 px-1.5 py-0.5",
            "rounded-md border border-transparent hover:bg-gray-ultralight focus:border-blue-primary focus:bg-white focus:outline-none focus:ring-0",
            "text-xl text-primary",
            "w-full",
            className,
          )}
          {...otherProps}
          value={title}
          onChange={(e) => setTitle(e.currentTarget.value)}
          onBlur={handleBlur}
        />
        <button type="submit" className="sr-only">
          Wijzig titel
        </button>
      </form>
    </Wrapper>
  );
}

export default BlockTitleInput;
