import {
  QuantityWithComponentsModel,
  SimpleQuantityModel,
} from "@/data-models/quantity-entity";
import { TimeEntityModel } from "@/data-models/time-entity";
import React, { HTMLAttributes, Ref } from "react";
import { is } from "superstruct";
import TimeEntityEditor, { TimeEntityEditorProps } from "./TimeEntityEditor";
import DateEntityEditor, { DateEntityEditorProps } from "./DateEntityEditor";
import DateTimeEntityEditor, {
  DateTimeEntityEditorProps,
} from "./DateTimeEntityEditor";
import { DateEntityModel } from "@/data-models/date-entity";
import { DateTimeEntityModel } from "@/data-models/datetime-entity";
import { IEditableEntity } from "./types";
import SimpleQuantityEditor, {
  SimpleQuantityEditorProps,
} from "./QuantityEditor/SimpleQuantityEditor";
import QuantityWithComponentsEditor, {
  QuantityWithComponentsEditorProps,
} from "./QuantityEditor/QuantityWithComponentsEditor";

export interface EntityEditorProps<TEntity extends IEditableEntity>
  extends HTMLAttributes<HTMLFormElement> {
  open?: boolean;
  close?: () => void;
  entity: TEntity;
  onEntityChange: (entity: TEntity) => void;
}

function EntityEditor<TEntity extends IEditableEntity>(
  props: EntityEditorProps<TEntity>,
  ref?: Ref<HTMLFormElement>
) {
  const { entity } = props;
  if (is(entity, SimpleQuantityModel))
    return (
      <SimpleQuantityEditor
        ref={ref}
        {...(props as unknown as SimpleQuantityEditorProps)}
      />
    );
  if (is(entity, QuantityWithComponentsModel))
    return (
      <QuantityWithComponentsEditor
        ref={ref}
        {...(props as unknown as QuantityWithComponentsEditorProps)}
      />
    );
  if (is(entity, TimeEntityModel))
    return (
      <TimeEntityEditor
        ref={ref}
        {...(props as unknown as TimeEntityEditorProps)}
      />
    );
  if (is(entity, DateEntityModel))
    return (
      <DateEntityEditor
        ref={ref}
        {...(props as unknown as DateEntityEditorProps)}
      />
    );
  if (is(entity, DateTimeEntityModel))
    return (
      <DateTimeEntityEditor
        ref={ref}
        {...(props as unknown as DateTimeEntityEditorProps)}
      />
    );
  return (
    <div className="p-2 text-sm">
      Couldn&apos;t render the editor because the received entity is invalid.
    </div>
  );
}

export default EntityEditor;
