import classNames from "classnames";
import RenderEntityTooltip, {
  UseFormGetValuesContext,
} from "@/components/inputs/MarkdownInput/RenderEntityTooltip";
import {
  UseFormRegisterContext,
  useEntityHandlers,
} from "@/components/inputs/MarkdownInput/useEntityHandlers";
import MarkdownTextEditor from "@/components/MarkdownTextEditor";
import React from "react";
import {
  Control,
  Controller,
  FieldPath,
  FieldValues,
  PathValue,
} from "react-hook-form";

export type TextFieldProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> = {
  name: TName;
  className?: string;
  control?: Control<TFieldValues>;
  formContext?: UseFormRegisterContext<TFieldValues> &
    UseFormGetValuesContext<TFieldValues>;
  defaultValue?: PathValue<TFieldValues, TName>;
  placeholder?: string;
  disabled?: boolean;
  readOnly?: boolean;
};
function TextField<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>(
  {
    name,
    className,
    placeholder,
    disabled,
    defaultValue,
    readOnly,
    control,
    formContext,
  }: TextFieldProps<TFieldValues, TName>,
  ref: React.Ref<HTMLDivElement>,
) {
  const { handleAddEntity, handleRemoveEntity } =
    useEntityHandlers(formContext);
  return (
    <Controller
      name={name}
      defaultValue={defaultValue}
      // FIXME: Upgrading to react-hook-forms 7.46 introduces a disabled property
      // but introduces a bug in the Badge component
      control={control}
      render={({ field }) => (
        <MarkdownTextEditor
          value={field.value}
          placeholder={placeholder}
          onChange={field.onChange}
          onBlur={field.onBlur}
          onAddEntity={handleAddEntity}
          onRemoveEntity={handleRemoveEntity}
          disabled={disabled || readOnly}
          className={classNames(
            "relative flex items-stretch rounded border px-2 py-1 text-gray-dark outline-1 sm:text-sm",
            "focus-within:border-blue-600 focus-within:outline focus-within:outline-blue-600",
            {
              "cursor-text  border-gray-light": !disabled && !readOnly,
              "cursor-not-allowed border-gray-lighter bg-gray-ultralight text-gray-light":
                disabled,
              "cursor-not-allowed border-gray-light": readOnly,
            },
            className,
          )}
          renderEntity={(id) => (
            <RenderEntityTooltip
              id={id as FieldPath<TFieldValues>}
              context={formContext}
            />
          )}
        />
      )}
    />
  );
}
export default TextField;
