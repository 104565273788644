export type State<B> = B[];

export type ActionType = "APPEND" | "REMOVE" | "INSERT" | "REORDER";
export type Action<
  TBlockIn,
  TAction extends ActionType = ActionType,
> = TAction extends "APPEND"
  ? { type: "APPEND"; block: TBlockIn }
  : TAction extends "REMOVE"
    ? { type: "REMOVE"; blockId: number }
    : TAction extends "INSERT"
      ? { type: "INSERT"; index: number; block: TBlockIn }
      : TAction extends "REORDER"
        ? { type: "REORDER"; srcBlockId: number; dstIndex: number }
        : never;

function blockListReducer<
  TBlockIn,
  TBlockOut extends TBlockIn & { id: string | number },
>(state: State<TBlockOut>, action: Action<TBlockIn>): State<TBlockOut> | null {
  switch (action.type) {
    case "APPEND":
      // No optimistic update for now because it's not clear how to handle
      // The id of the block is not known until the server responds
      return null;
    case "REMOVE": {
      // optimistic update: remove the block from the list
      const index = state.findIndex((b) => b.id === action.blockId);
      if (index < 0 || index >= state.length) {
        return state;
      }
      return [...state.slice(0, index), ...state.slice(index + 1)];
    }
    case "INSERT":
      // No optimistic update for now because it's not clear how to handle
      // The id of the block is not known until the server responds
      return null;
    case "REORDER": {
      // optimistic update: reorder the block in the list
      const newState = [...state];
      const removeIndex = newState.findIndex((b) => b.id === action.srcBlockId);
      const [removed] = removeIndex >= 0 ? newState.splice(removeIndex, 1) : [];
      if (!removed) {
        throw Error(
          `Can't reorder block because source block with id ${action.srcBlockId} doesn't exist.`,
        );
      }
      newState.splice(action.dstIndex, 0, removed);
      return newState;
    }
    default:
      throw Error("Invalid action type for BlockListReducer");
  }
}
export default blockListReducer;
