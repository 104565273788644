import { useFormValuesChange } from "@/components/forms/OnChangePlugin";
import React from "react";
import {
  FieldValues,
  FormProvider,
  SubmitErrorHandler,
  SubmitHandler,
  useFieldArray,
  UseFieldArrayReturn,
  useForm,
  UseFormProps,
} from "react-hook-form";
import { Column, TableInstance } from "./types";

interface FormProps<TFieldValues extends FieldValues>
  extends UseFormProps<TFieldValues> {
  children: (props: UseFieldArrayReturn<TFieldValues>) => React.ReactNode;
  onSubmit?: SubmitHandler<TFieldValues>;
  onError?: SubmitErrorHandler<TFieldValues>;
  onChange?: (data: TFieldValues) => any;
}
const NOOP = () => undefined;
function Form<
  TColumns extends Column[] = Column[],
  TInstance extends TableInstance<TColumns> = TableInstance<TColumns>
>({
  children,
  onSubmit = NOOP,
  onError = NOOP,
  onChange = NOOP,
  ...props
}: FormProps<TInstance>) {
  const ref = React.useRef<HTMLFormElement>(null);
  const methods = useForm<TInstance>({ ...props, shouldFocusError: false });
  const { control, handleSubmit, watch } = methods;

  useFormValuesChange(onChange, { watch });

  const arrayMethods = useFieldArray<TInstance, any, "id">({
    control,
    name: "data",
  });

  return (
    <form
      ref={ref}
      onSubmit={handleSubmit(onSubmit, onError)}
      className="contents"
    >
      <FormProvider {...methods}>{children(arrayMethods)}</FormProvider>
    </form>
  );
}

export default Form;
