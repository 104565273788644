import { EyeIcon } from "@heroicons/react/20/solid";
import classNames from "classnames";
import React, { useState } from "react";

export interface PasswordInputProps
  extends Omit<React.InputHTMLAttributes<HTMLInputElement>, "ref"> {
  small?: boolean;
  extraSmall?: boolean;
  error?: boolean;
}

function PasswordInputWithoutRef(
  { className, error, small, extraSmall, ...otherProps }: PasswordInputProps,
  ref: React.Ref<HTMLInputElement>
) {
  const [showPassword, setShowPassword] = useState(false);
  return (
    <div className="group relative rounded-md shadow-sm">
      <input
        ref={ref}
        type={showPassword ? "text" : "password"}
        className={classNames(
          "block w-full rounded-md border-gray-light pr-10 placeholder-gray-400 group-focus-within:border-blue-700 group-focus-within:ring-blue-primary sm:text-sm",
          className
        )}
        {...otherProps}
      />
      <div className="absolute inset-y-0 right-0 flex items-center pr-3">
        <button
          type="button"
          tabIndex={-1}
          onClick={(e) => {
            setShowPassword((t) => !t);
            e.preventDefault();
          }}
          className="inline-flex items-center rounded-r-md "
        >
          <EyeIcon className="block h-4 w-4 text-gray-400" />
        </button>
      </div>
    </div>
  );
}

const PasswordInput = React.forwardRef(PasswordInputWithoutRef);
export default PasswordInput;
