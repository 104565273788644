import withCheck from "@/editor/legacy/CheckPlugin/withCheck";
import { withDataFields } from "@/editor/legacy/DataFields/withDataFields";
import withEntities from "@/editor/legacy/Entities/WithEntities";
import withToggles from "@/editor/legacy/ToggleButtonPlugin/withToggles";
import { withEntryLayout } from "@/editor/legacy/EntryLayoutPlugin/withEntryLayout";
import { withMedicalHistory } from "@/editor/legacy/MedicalHistory/Editor";
import { withTable } from "@/editor/legacy/TablePlugin";
import { RenderType } from "../types";

export const TIMESTAMPED_RICH_TEXT_MIXINS = [
  withEntryLayout,
  withEntities,
  withDataFields,
  withMedicalHistory,
  withToggles,
  withCheck,
];

export const ENTRY_LAYOUT_RICH_TEXT_MIXINS = [
  withEntryLayout,
  withTable,
  withEntities,
  withDataFields,
  withToggles,
  withCheck,
];

export const QUESTIONNAIRE_MIXINS = [
  withEntities,
  withDataFields,
  withToggles,
  withCheck,
];

export const TABLE_MIXINS = [
  withTable,
  withEntities,
  withDataFields,
  withToggles,
  withCheck,
];

function getMixins(renderType: RenderType) {
  switch (renderType) {
    case "qa-rich-text":
      return ENTRY_LAYOUT_RICH_TEXT_MIXINS;
    case "rich-text-with-questionnaire":
      return QUESTIONNAIRE_MIXINS;
    case "table-rich-text":
      return TABLE_MIXINS;
    case "timestamped-rich-text":
      return TIMESTAMPED_RICH_TEXT_MIXINS;
  }
}
export default getMixins;
