import { useMutation, UseMutationOptions, useQueryClient } from "react-query";
import client from "./client";
import keyFactory from "./keyFactory";
import { ApiError, PatientOut } from "./report-client";

export default function useRemovePatient(
  options: UseMutationOptions<PatientOut, ApiError, number> = {}
) {
  const queryClient = useQueryClient();
  return useMutation<PatientOut, ApiError, number>(
    (patientId: number) => client.v1.deletePatientV1({ patientId }),
    {
      ...options,
      onSuccess: (data, variables, context) => {
        queryClient.invalidateQueries(keyFactory.allPatients());
        options.onSuccess?.(data, variables, context);
      },
    }
  );
}
