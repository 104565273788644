import { RenderPlaceholderProps } from "slate-react";

export const RenderPlacholder = ({
  attributes,
  children,
}: RenderPlaceholderProps) => (
  <span
    {...attributes}
    className="text-transparent group-hover:text-gray-400 group-focus:text-gray-400 sm:text-sm"
  >
    {children}
  </span>
);
