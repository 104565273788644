import React, {
  HTMLAttributes,
  RefCallback,
  useEffect,
  useLayoutEffect,
} from "react";
import { EditableProps as SlateEditableProps } from "slate-react/dist/components/editable";
import TiroEditable from "../../Base/TiroEditable";
import { ReactEditor, useSlateStatic } from "slate-react";
import { ISuggestEditor, QueryChangeEventHandler } from "./SuggestEditor";

export interface EditableProps extends SlateEditableProps {
  onQueryChange?: QueryChangeEventHandler;
  editableRef?: RefCallback<HTMLDivElement>;
}

function Editable({
  editableRef,
  onQueryChange: handleQueryChange,
  ...props
}: HTMLAttributes<HTMLDivElement> & EditableProps) {
  const editor = useSlateStatic() as ISuggestEditor;

  useEffect(() => {
    /**
     * Subscribe query change handlers to editor
     */
    if (!handleQueryChange) return;
    // returns a cleanup function
    return editor.subscribeQuery(handleQueryChange);
  }, [editor, handleQueryChange]);

  useLayoutEffect(() => {
    /**
     * Set the Div element to ref handler
     */
    const domNode = ReactEditor.toDOMNode(editor, editor) as HTMLDivElement;

    if (editableRef && domNode) {
      editableRef(domNode);
    }
  }, [editableRef, editor]);

  return <TiroEditable {...props} />;
}
export default Editable;
