import { Combobox, Transition } from "@headlessui/react";
import classNames from "classnames";
import { ICoding } from "@/data-models/value-models";
import React, { MutableRefObject } from "react";
import { ExpansionContains } from "@/services/content/content-client";
import { IDesignation } from "../CodingCard/models";
import RenderCodingCard from "../CodingCard/RenderCodingCard";
type CodeComboboxResultsProps = {
  className?: string;
  open?: boolean;
  minimal?: boolean;
  codes: ExpansionContains[];
  helpers: {
    getSemantic: (
      property: ExpansionContains["property"],
    ) => ICoding | undefined;
    getCoding: (
      coding: ICoding,
      designation: ExpansionContains["designation"],
    ) => ICoding;
    getDesignation: (
      text: string,
      designation: ExpansionContains["designation"],
    ) => IDesignation | undefined;
  };
  style?: React.CSSProperties;
};

function CodeComboboxResults_(
  {
    open = true,
    minimal,
    codes,
    style,
    className,
    helpers,
  }: CodeComboboxResultsProps,
  ref?: React.Ref<HTMLOListElement>,
) {
  return (
    <Transition show={open} className={classNames(className, "relative")}>
      <Combobox.Options
        static
        ref={ref}
        style={style}
        className={classNames("bg-white py-1 text-base sm:text-sm", {
          "rounded-md shadow-lg ring-1 ring-black ring-opacity-5": minimal,
          "space-y-1": !minimal,
        })}
      >
        <RenderCodingOptions
          codes={codes}
          helpers={helpers}
          minimal={minimal}
        />
        {codes.length == 0 && (
          <Combobox.Option
            className="px-3 py-1 text-gray-700"
            value={null}
            disabled
          >
            Geen resultaten
          </Combobox.Option>
        )}
      </Combobox.Options>
    </Transition>
  );
}

const CodeComboboxResults = React.forwardRef(CodeComboboxResults_);

export default CodeComboboxResults;

function RenderCodingOptions({
  codes,
  helpers,
  minimal,
}: {
  codes: ExpansionContains[];
  helpers: CodeComboboxResultsProps["helpers"];
  minimal?: boolean;
}) {
  return (
    <>
      {codes.map(
        ({
          system,
          code,
          display,
          designation = [],
          property = [],
          abstract,
          contains,
        }) =>
          abstract ? (
            <div key={`${system}-${code}`}>
              <Combobox.Option
                value={display}
                disabled
                className="border-y border-gray-200/70 bg-gray-100 px-2 py-0.5 text-xs font-medium text-gray-500"
              >
                {display}
              </Combobox.Option>
              {contains && contains.length > 0 ? (
                <RenderCodingOptions
                  key={`${system}-${code}`}
                  codes={contains}
                  helpers={helpers}
                  minimal={minimal}
                />
              ) : (
                <Combobox.Option
                  className="px-3 py-1 text-sm text-gray-400"
                  value={null}
                  disabled
                >
                  Geen resultaten
                </Combobox.Option>
              )}
            </div>
          ) : (
            <Combobox.Option
              key={`${system}-${code}`}
              value={{ system, code, display }}
            >
              {({ active }) => (
                <RenderCodingCard
                  {...helpers.getCoding(
                    { system: system!, code: code!, display: display! },
                    designation,
                  )}
                  designation={helpers.getDesignation(display!, designation)}
                  minimal={minimal}
                  small={minimal}
                  active={active}
                  generated={
                    !!property.find(
                      (p) => p.code == "generated" && p.valueBoolean,
                    )
                  }
                  semantic={helpers.getSemantic(property)}
                />
              )}
            </Combobox.Option>
          ),
      )}
    </>
  );
}

export const CodingResultsContainerRefContext = React.createContext<
  MutableRefObject<HTMLElement | null>
>({ current: null });
