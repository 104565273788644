import { IConcept } from "@/data-models/concept";
import { ChipInput } from "./ChipInput";
import Chip from "@/components/base/Chip/Chip";
import getPosition from "./getPosition";
import { createValueSetExpansionQuery } from "@/services/content/useValueSetExpansion";
import {
  conceptAsCoding,
  ICodeableConcept,
  IHumanReadableCoding,
  codingToConcept,
} from "@/data-models/value-models/types";
import { ExpansionContains } from "@/services/content/content-client";
import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";
import classNames from "classnames";
import Tippy from "@tippyjs/react";
import { Spinner } from "@/Icons";
import { useQuery } from "react-query";
import { useLanguage } from "@/i18n";
import Tooltip from "@/components/info-cards/Tooltip";
import CodingTooltip from "@/components/base/CodingCard/CodingTooltip";
import React from "react";
import { useFeatureFlagEnabled } from "posthog-js/react";

export interface ValueSetChipInputProps {
  name: string;
  code?: IConcept;
  className?: string;
  valueSet?: string;
  defaultValue?: IHumanReadableCoding;
  options?: IHumanReadableCoding[];
  active?: boolean;
  disabled?: boolean;
}
const CodingChipInput = React.forwardRef(
  ChipInput<ExpansionContains, ICodeableConcept>,
);
function ValueSetChipInput({
  name,
  code,
  className,
  valueSet,
  options: providedOptions,
  defaultValue,
  disabled,
  active,
}: ValueSetChipInputProps) {
  if (!valueSet && providedOptions == undefined) {
    throw new Error(
      "ValueSetChipInput: Either valueSet or options must be provided",
    );
  }
  const showCodingCardTooltip = useFeatureFlagEnabled(
    "show-coding-card-tooltip",
  );
  const hasDefinedOptions = providedOptions && providedOptions.length > 0;
  const enabled = !hasDefinedOptions && disabled != true;
  const lang = useLanguage();
  const {
    data: valueSetOptions = [],
    isError,
    isLoading,
  } = useQuery({
    ...createValueSetExpansionQuery(valueSet!, {
      displayLanguage: lang,
      count: 30,
    }),
    enabled,
    select(data) {
      return data.expansion.contains ?? [];
    },
  });
  const options =
    providedOptions == undefined ? valueSetOptions : providedOptions;
  return (
    <div className="inline-flex flex-wrap items-baseline gap-0.5">
      {code && (
        <Chip position="left" disabled className="!pr-1" active={active}>
          {`${code?.text}:`}
        </Chip>
      )}
      {isError && (
        <Tippy content="Couldn't load options to populate the chip buttons.">
          <span>
            <Chip
              position={code ? "right" : "independent"}
              disabled
              className={classNames(
                className,
                "border-orange-500 text-orange-600",
              )}
              active={active}
              Icon={
                <ExclamationTriangleIcon
                  className="inline h-5 w-5 text-orange-400"
                  aria-hidden="true"
                />
              }
            >
              No options
            </Chip>
          </span>
        </Tippy>
      )}
      {isLoading && (
        <Tippy content="Loading options to populate the chip buttons.">
          <span>
            <Chip
              position={code ? "right" : "independent"}
              disabled
              className={classNames(className, "border-gray-500 text-gray-600")}
              active={active}
              Icon={
                <Spinner
                  className="inline h-5 w-5 animate-spin text-gray-400"
                  aria-hidden="true"
                />
              }
            >
              Loading
            </Chip>
          </span>
        </Tippy>
      )}
      {options.map((option, index) => (
        <Tooltip
          key={`${option.system}|${option.code}`}
          enabled={showCodingCardTooltip}
        >
          <Tooltip.Trigger asChild>
            <CodingChipInput
              name={name}
              disabled={disabled}
              active={active}
              equals={(a, b) => a.code === b.code && a.system === b.system}
              getDisplay={(value) => value?.display ?? "Unkown"}
              value={option}
              defaultValue={defaultValue}
              transform={{
                input: codingToConcept,
                output: conceptAsCoding,
              }}
              position={getPosition(
                index,
                options.length,
                code ? "center" : "left",
              )}
            />
          </Tooltip.Trigger>
          <Tooltip.Content className="fill-gray-lighter">
            <CodingTooltip coding={option as IHumanReadableCoding} />
          </Tooltip.Content>
        </Tooltip>
      ))}
    </div>
  );
}

export default ValueSetChipInput;
