import classNames from "classnames";
import { HTMLAttributes } from "react";
type CardProps = HTMLAttributes<HTMLDivElement> & {
  small?: boolean;
  minimal?: boolean;
  active?: boolean;
};

function Header({
  children,
  className,
  ...props
}: HTMLAttributes<HTMLDivElement>) {
  return (
    <div
      className={classNames(
        "items-bottom flex justify-between gap-1",
        className
      )}
      {...props}
    >
      {children}
    </div>
  );
}

function BodyRow({
  children,
  className,
  ...props
}: HTMLAttributes<HTMLDivElement>) {
  return (
    <div
      className={classNames(
        "mt-2 flex flex-wrap gap-1 text-xs text-gray-500 dark:text-gray-400 lg:max-w-sm lg:flex-nowrap",
        className
      )}
      {...props}
    >
      {children}
    </div>
  );
}
function Card({
  children,
  className,
  small,
  active,
  minimal,
  ...props
}: CardProps) {
  return (
    <section
      className={classNames(
        "relative",
        { "px-3 py-2": !small, "px-2 py-1": small },
        {
          "rounded-md border shadow-sm dark:border-gray-700": !minimal,
          "border-none": minimal,
        },
        "box-border",
        {
          "border-gray-200 bg-white ": !active,
          "border-gray-200 bg-blue-500 ": active && minimal,
          "border-blue-500 ring-1 ring-inset ring-blue-500": active && !minimal,
        },
        className
      )}
      {...props}
    >
      {children}
    </section>
  );
}

/**
 * Header for the card with `.flex.items-bottom.justify-between.gap-1`
 */
Card.Header = Header;
/**
 * Body row for the card with `.flex.flex-wrap.gap-1.max-w-sm.lg:flex-nowrap`
 */
Card.BodyRow = BodyRow;

export default Card;
