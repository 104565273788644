import { PlusIcon } from "@heroicons/react/24/outline";
import classNames from "classnames";
import useCurrentReportId from "@/hooks/useCurrentReportId";
import { useFeatureFlagEnabled } from "posthog-js/react";
import * as Scroll from "react-scroll";
import { ReportBlockOut } from "@/services/reports/report-client";
import { useReportBlockList } from "@/services/reports/useReportBlockList";
import React from "react";
import useLaunchedFrom from "@/hooks/useLaunchedFrom";
import { useBlockNavigationSync } from "@/hooks/smart-web-messaging/useBlockScrollNavigationSync";
import useCurrentSubjectId from "@/hooks/useCurrentSubjectId";
export type NavigationSideBarProps = {
  blocks?: ReportBlockOut[];
};

function NavigationSideBar(props: NavigationSideBarProps) {
  const reportId = useCurrentReportId();
  const patientId = useCurrentSubjectId();
  const { blocks = [] } = useReportBlockList(reportId);
  const showAddBlock = useFeatureFlagEnabled("add-block");
  const isSynops = useLaunchedFrom("Synops");
  useBlockNavigationSync({ reportId, patientId }, { enabled: isSynops });

  const links = blocks.map((block) => (
    <div className="my-4 mr-4 block border-b border-gray-light" key={block.id}>
      <Link blockId={block.id}>{block.title}</Link>
    </div>
  ));
  return (
    <div className="h-full w-full">
      <nav>{links}</nav>
      <div className="mt-10 flex items-center px-4">
        {showAddBlock && <AddBlock className="grow" />}
      </div>
    </div>
  );
}

function AddBlock({ className }: { className?: string }) {
  return (
    <button
      type="button"
      className={classNames(
        "mx-auto inline-flex items-center justify-center gap-1 justify-self-start rounded-md border border-gray-light bg-white px-4 py-2 font-primary text-base font-medium text-content-primary hover:bg-fill-secondary focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 sm:w-auto",
        className,
      )}
    >
      open Navify
      <PlusIcon className="inline h-5 w-5 opacity-80" />
    </button>
  );
}

export default NavigationSideBar;

function Link({
  blockId,
  children,
}: {
  blockId: number;
  children: React.ReactNode;
}) {
  return (
    <Scroll.Link
      activeClass={`bg-slate-200 rounded transition duration-500`}
      to={`block-container-${blockId}`}
      spy={true}
      smooth={true}
      offset={-20}
      duration={500}
      containerId="center_column"
      className="relative top-[-1] my-1 block cursor-pointer rounded py-1 pl-6 text-sm font-medium text-primary hover:bg-blue-ultralight hover:text-blue-dark"
    >
      {children}
    </Scroll.Link>
  );
}
