import { IEditorPlugin, RenderTiroElementProps } from "../../Base/types";
import { QUESTIONNAIRE_ELEMENT } from "./consts";
import { QuestionnaireElement } from "./QuestionnaireElement";
import RenderQuestionnaireElement, {
  RenderQuestionnaireElementProps,
} from "./RenderQuestionnaireElement";

const QuestionnairePlugin: IEditorPlugin = {
  renderElement: (props: RenderTiroElementProps) => {
    const { element } = props;
    if (QuestionnaireElement.isQuestionnaireElement(element)) {
      return (
        <RenderQuestionnaireElement
          {...(props as RenderQuestionnaireElementProps)}
        />
      );
    }
  },
  voidTypes: [QUESTIONNAIRE_ELEMENT],
};

export default QuestionnairePlugin;
