import { TypedElement } from "../../TypedElement";
import { ITiroQuantity } from "@/data-models/value-models/types";
import { ReactEditor, RenderElementProps } from "slate-react";
import { IEditorPlugin } from "../../Base/types";
import { TABLE_CELL_TYPE, TABLE_ROW_TYPE, TABLE_TYPE } from "./consts";
import { RenderTableElement, RenderTableElementProps } from "./Table";
import {
  RenderTableCellElement,
  RenderTableCellElementProps,
} from "./TableCell";
import { RenderTableRowElement, RenderTableRowElementProps } from "./TableRow";
import { ITableCellElement, ITableElement, ITableRowElement } from "./types";
import TiroEditor, { ITiroEditor } from "../../Base/editor";
import { TiroElement } from "../../Base/TiroElement";
import {
  IAbstractDataField,
  IDateField,
  IFreeTextField,
  IQuantityField,
} from "../DataFields/types";
import { KeyboardEvent } from "react";
import { isEventHandled } from "../../util";
import { HotKeys } from "../../hotkeys";
import { Editor } from "slate";

interface IAbstractTableColumn extends IAbstractDataField {
  header: string;
}
interface IQuantityColumn
  extends IAbstractTableColumn,
    Omit<IQuantityField, "children"> {
  dataType: "quantity";
  quantity: Omit<ITiroQuantity, "value">;
}
interface IFreeTextColumn
  extends IAbstractTableColumn,
    Omit<IFreeTextField, "children"> {
  dataType: "free-text";
}
interface IDateColumn
  extends IAbstractTableColumn,
    Omit<IDateField, "children"> {
  dataType: "date";
}

interface ICodeColumn extends IAbstractTableColumn {
  dataType: "concept";
}

export type AllColumnTypes =
  | IQuantityColumn
  | IFreeTextColumn
  | IDateColumn
  | ICodeColumn;

export const TableElement = {
  ...TiroElement,
  isTableElement: (o: any): o is ITableElement => {
    return TableElement.isTypedElement(o) && o.type === TABLE_TYPE;
  },
  isTableRowElement: (o: any): o is ITableRowElement => {
    return TableElement.isTypedElement(o) && o.type === TABLE_ROW_TYPE;
  },
  isTableCellElement: (o: any): o is ITableCellElement => {
    return TableElement.isTypedElement(o) && o.type === TABLE_CELL_TYPE;
  },
};
const handleKeyDown = (e: KeyboardEvent, editor: ITiroEditor) => {
  if (
    !isEventHandled(e) &&
    HotKeys.isTab(e) &&
    Editor.above(editor, { match: (n) => TableElement.isTableElement(n) })
  ) {
    const { selection } = editor;
    if (ReactEditor.isFocused(editor) && !!selection) {
      TiroEditor.tabFocus(editor, { reverse: e.shiftKey });
      e.preventDefault();
    }
  }
};

const TablePlugin: IEditorPlugin = {
  handleKeyDown,
  renderElement: (props: RenderElementProps) => {
    const { element } = props;
    if (!TypedElement.isTypedElement(element)) {
      return;
    }
    switch (element.type) {
      case TABLE_CELL_TYPE:
        return (
          <RenderTableCellElement {...(props as RenderTableCellElementProps)} />
        );
      case TABLE_ROW_TYPE:
        return (
          <RenderTableRowElement {...(props as RenderTableRowElementProps)} />
        );
      case TABLE_TYPE:
        return <RenderTableElement {...(props as RenderTableElementProps)} />;
    }
  },
};
export default TablePlugin;
