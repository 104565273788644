import { Menu, Transition } from "@headlessui/react";
import classNames from "classnames";
import Avatar from "@/components/base/Avatar";
import useUserData from "@/services/reports/useUserData";
import { Fragment } from "react";
import { Link, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { SETTINGS_PROFILE_PATH } from "@/pages/settings/Profile/consts";
import { LOGOUT_PATH } from "@/pages/auth/logout/const";
import Tooltip from "@/components/info-cards/Tooltip";
//TODO: make story
export const ProfileMenu = () => {
  const { data: user, isLoading, isError } = useUserData();
  const { t } = useTranslation();

  const { pathname } = useLocation();
  if (isLoading || isError) {
    return (
      <Tooltip>
        <Tooltip.Trigger>
          <Avatar initials="" />
        </Tooltip.Trigger>
        <Tooltip.Content>
          {isLoading ? "Loading user data..." : "Error loading user data"}
        </Tooltip.Content>
      </Tooltip>
    );
  }

  const { firstName, lastName, email } = user!;
  const initials = getInitials(firstName, lastName) || "??";
  return (
    <Menu as="div" className="relative ml-3 w-min">
      {({ open }) => (
        <>
          <div>
            <Menu.Button className="flex rounded-full bg-white text-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2">
              <span className="sr-only">Open user menu</span>
              <Avatar initials={initials} />
            </Menu.Button>
          </div>
          <Transition
            show={open}
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <Menu.Items
              static
              className="absolute right-0 z-30 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
            >
              <Menu.Item>
                {({ active }) => (
                  <Link
                    to={{ pathname: `/${SETTINGS_PROFILE_PATH}` }}
                    state={{ from: pathname }}
                    className={classNames(
                      { "bg-gray-ultralight": active },
                      "block w-full px-4 py-2 text-sm text-gray-dark",
                    )}
                  >
                    {t("je-profiel")}
                  </Link>
                )}
              </Menu.Item>
              <Menu.Item>
                {({ active }) => (
                  <Link
                    to={`/reset-password?email=${email}`}
                    className={classNames(
                      { "bg-gray-ultralight": active },
                      "block w-full px-4 py-2 text-sm text-gray-dark",
                    )}
                  >
                    {t("wijzig-wachtwoord")}
                  </Link>
                )}
              </Menu.Item>
              <Menu.Item>
                {({ active }) => (
                  <Link
                    to={`/${LOGOUT_PATH}`}
                    className={classNames(
                      { "bg-gray-ultralight": active },
                      "block w-full px-4 py-2 text-left text-sm text-gray-dark",
                    )}
                  >
                    {t("log-uit")}
                  </Link>
                )}
              </Menu.Item>
              <Menu.Item>
                {({ active }) => (
                  <Link
                    to="/table-export"
                    className={classNames(
                      { "bg-gray-ultralight": active },
                      "block w-full px-4 py-2 text-sm text-gray-dark",
                    )}
                  >
                    {t("exporteer-database")}
                  </Link>
                )}
              </Menu.Item>
            </Menu.Items>
          </Transition>
        </>
      )}
    </Menu>
  );
};

function getInitials(firstName: string, lastName: string) {
  return (firstName ? firstName[0] : "") + (lastName ? lastName[0] : "");
}
