import { ICoding } from "@/data-models/value-models";
import { CodingModel } from "@/data-models/value-models/structs";
import { useCallback } from "react";
import { useMutation, useQuery } from "react-query";
import client from "@/services/content/client";
import { Designation } from "@/services/content/content-client";
import {
  createCodeLookupQuery,
  CodeLookup,
} from "@/services/content/useCodeLookup";
import { any, array, create, is, type } from "superstruct";
import { DesignationModel, IDesignation, UseCodingModel } from "./models";
import RenderCodingCard from "./RenderCodingCard";

type CodingCardProps = {
  system: string;
  code: string;
  display: string;
  minimal?: boolean;
  className?: string;
  validateCoding?: (coding: ICoding) => Promise<boolean>;
  onDisplayChange?: (display: string) => void;
};

function CodingCard({
  code,
  system,
  display,
  onDisplayChange,
  className,
  minimal,
}: CodingCardProps) {
  const { data } = useQuery({
    ...createCodeLookupQuery({ system, code }),
    staleTime: 3600 * 1000,
  });
  const { mutateAsync: validateCode } = useMutation(
    ({ system, code, display }: ICoding) =>
      client.r5.validateCodesystemCodeR5({ system, code, display }),
  );

  const handleDesignationChange = useCallback(
    async (designation: IDesignation) => {
      const { result } = await validateCode({
        system,
        code,
        display: designation.value,
      });
      if (!result) {
        throw new Error("Invalid code");
      }
      onDisplayChange?.(designation.value);
      return true;
    },
    [onDisplayChange, validateCode, system, code],
  );
  return (
    <RenderCodingCard
      minimal={minimal}
      small={minimal}
      code={code}
      className={className}
      system={system}
      display={data?.display ?? display}
      designation={getDesignation(display, data)}
      semantic={getSemantic(data)}
      onDesignationChange={onDisplayChange && handleDesignationChange}
    />
  );
}

export default CodingCard;

function castDesignation(designation: Designation): IDesignation {
  return is(designation.use, UseCodingModel)
    ? create(designation, DesignationModel)
    : { ...designation, use: undefined };
}

function getDesignation(display: string, data: CodeLookup | undefined) {
  if (!data) return undefined;
  const matchingDesignation = data?.designation?.find(
    (d: any) => d.value === display,
  );
  const designation = matchingDesignation
    ? castDesignation(matchingDesignation)
    : undefined;
  return designation;
}

function getSemantic(data: CodeLookup | undefined) {
  if (!data || !is(data, type({ property: array(any()) }))) return undefined;
  const { property } = data;
  const prop = property.find(
    (p: { code?: string }) => p?.code === "semantic_axis",
  );
  if (is(prop, type({ valueCoding: CodingModel }))) {
    return prop.valueCoding;
  }
  return undefined;
}

export const CODING_HELPERS = {
  getDesignation,
  getSemantic,
};
