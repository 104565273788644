import { Spinner } from "@/Icons";

export default function Loader() {
  return (
    <div className="flex h-screen items-center justify-center">
      <h2 className="text-2xl font-bold">Loading matching report...</h2>
      <Spinner className="mx-1 h-6 w-6 animate-spin" aria-hidden="true" />
    </div>
  );
}
