import Identifier from "@/data-models/value-models/identifier";
import { useQuery, UseQueryOptions } from "react-query";
import client, { PatientOut, ApiError } from "./client";
import keyFactory from "./keyFactory";
interface UsePatientsOptions<TData>
  extends UseQueryOptions<PatientOut[], ApiError, TData> {
  identifier?: string;
  limit?: number;
  offset?: number;
}
type QueryOptions = {
  identifier?: string;
  limit?: number;
  offset?: number;
};

export const createPatientsQuery = ({
  identifier,
  limit,
  offset,
}: QueryOptions) => {
  return {
    queryKey: keyFactory.allPatients({ offset, limit, identifier }),
    queryFn: () =>
      client.v1.getPatientsV1({
        offset,
        limit,
        identifier,
      }),
  };
};
export type CreatePatientsQuery = typeof createPatientsQuery;
export type PatientsQuery = ReturnType<typeof createPatientsQuery>;

function usePatients<TData = PatientOut[]>(
  options: UsePatientsOptions<TData> = {},
) {
  const { offset, limit, identifier, ...otherOptions } = options;
  return useQuery<PatientOut[], ApiError, TData>(
    keyFactory.allPatients({ offset, limit, identifier }),
    () =>
      client.v1.getPatientsV1({
        offset,
        limit,
        identifier,
      }),
    { staleTime: Infinity, ...otherOptions },
  );
}

export default usePatients;
