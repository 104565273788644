import { RenderElementProps, useFocused, useSelected } from "slate-react";
import { FreeTextEntry } from "./types";
import { TripleStateButton } from "./TripleState";
import { EntityContextWrapper } from "../Entities/ContextWrapper";
import classNames from "classnames";
import { useFormattingClassnames } from "../../BasicFormatting/useFormattingClassnames";
import { useLevel } from "../../Base/useLevel";
import { EntrySettings } from "./EntrySettings";
import SelectHandle from "./SelectHandle";

export interface RenderFreeTextEntryProps extends RenderElementProps {
  element: FreeTextEntry;
}

export const RenderFreeTextEntry = ({
  element,
  attributes,
  children,
}: RenderFreeTextEntryProps) => {
  const classes = useFormattingClassnames(element);
  const selected = useSelected();
  const focused = useFocused();
  const level = useLevel(element);
  return (
    <div
      className={classNames("current-entry relative flex ", {
        "-ml-6 -mr-8": level === 1,
      })}
      {...attributes}
    >
      {level === 1 && (
        <div className="relative -left-2 flex">
          {level === 1 && <SelectHandle element={element} />}
        </div>
      )}
      <div
        className={classNames(
          "relative flex grow items-center rounded-sm ",
          "hover:bg-gray-dark/5 current-entry-hover:bg-gray-400/5 current-entry-hover:hover:bg-gray-dark/5",
          { "bg-gray-600/5": selected && focused },
          {}
        )}
      >
        {/*<DragHandle className="select-none h-full invisible current-entry-hover:visible" contentEditable={false} />*/}
        <TripleStateButton
          className="select-none"
          element={element}
          contentEditable={false}
        />
        <EntityContextWrapper.Provider value={element.state ?? "present"}>
          <div
            className={classNames("grow pr-2", classes, {
              "ml-8": level == 1,
              "pl-6": level > 1,
            })}
          >
            {children}
          </div>
        </EntityContextWrapper.Provider>
      </div>
      <EntrySettings className="absolute inset-y-0 right-8" element={element} />
    </div>
  );
};
