import React from "react";
import { IContent } from "./Answer/models";
import { FieldValues } from "react-hook-form";
import { ICodeableConcept, ICoding } from "@/data-models/value-models/types";

export type IEnabledWhenCondition =
  | {
      linkId?: string;
      operator: "=" | "!=";
      operand: ICoding;
    }
  | {
      linkId?: string;
      operator: "exists";
      operand: boolean;
    };

export type IEnabledWhen = {
  conditions: IEnabledWhenCondition[];
  mode: "all" | "any";
};

type ExtractionConfig = {
  path: string;
  context: string;
  reverse?: boolean;
};

export interface IDraftQuestion {
  code: null | ICodeableConcept;
  enabledWhen?: IEnabledWhen;
  extraction?: Record<"fhir" | "omop", ExtractionConfig>;
  hidden?: boolean;
  values?: Record<string, any>;
  answer?: IContent;
  required?: boolean;
}

export type RecursiveQuestionNode<T = Record<string, any>> = T & {
  questions?: RecursiveQuestionNode<T>[];
  collapsed?: boolean;
};
export type WithKey<T = Record<string, any>> = T & { id: string };

export interface IQuestionGroup {
  repeating: true;
  /** The template question that is instantiated when adding a new instance to a set of repeatable questions */
  template: IQuestionItem;
  /** The set of instantiated repeatable questions. */
  questions: IQuestionItem[];
  /** Wether or not the subquestions and the answer are hidden with a disclosure. */
  collapsed?: boolean;
}
export interface IQuestionItem extends IDraftQuestion {
  /** The unique identifier of the question */
  id?: string;
  repeating?: false;
  /** The question text */
  code: ICodeableConcept;
  /** Hide question text in printed text generation */
  hideInPrint?: boolean;
  /** Subquestions of the current questions */
  questions?: IQuestionItem[];
  /** Wether or not the subquestions and the answer are hidden with a disclosure. */
  collapsed?: boolean;
  /** The structured answer to the question. */
  values?: FieldValues;
  /** Mode of the answer container. Either: "data-entry" or "content-editable" */
  mode?: "data-entry" | "content-editable";
  /**
   * The orientation of the answer container. Either: "horizontal" or "vertical"
   * Overrides the default orientation of the entire questionnaire
   * */
  orientation?: "horizontal" | "vertical";
}

export type DragItem = IQuestionItem & { path: number[] };

export enum ActionTypes {
  ADD_COMMENTS = "ADD_COMMENTS",
  ADD_QUESTION = "ADD_QUESTION",
  EDIT_QUESTION = "EDIT_QUESTION",
  UPDATE_ANSWER = "UPDATE_ANSWER",
  UPDATE_VALUES = "UPDATE_VALUES",
  MOVE_QUESTION = "MOVE_QUESTION",
  REMOVE_QUESTION = "REMOVE_QUESTION",
  TOGGLE_STATE = "TOGGLE_STATE",
  UPDATE_QUESTIONS = "UPDATE_QUESTIONS",
  TOGGLE_DISCLOSURE = "TOGGLE_DISCLOSURE",
  CLEAR_VALUES = "CLEAR_VALUES",
}

type AppendQuestion = {
  type: ActionTypes.ADD_QUESTION;
  question: IQuestionItem;
  path: number[];
};

type EditQuestion = {
  type: ActionTypes.EDIT_QUESTION;
  question: Partial<IQuestionItem>;
  path: number[];
};

type MoveQuestion = {
  type: ActionTypes.MOVE_QUESTION;
  src: number[];
  dest: number[];
};

type RemoveQuestion = {
  type: ActionTypes.REMOVE_QUESTION;
  path: number[];
};

type ToggleDisclosure = {
  type: ActionTypes.TOGGLE_DISCLOSURE;
  path: number[];
};

type UpdateAnswer = {
  type: ActionTypes.UPDATE_ANSWER;
  path: number[];
  answer: IContent;
};
type UpdateValues = {
  type: ActionTypes.UPDATE_VALUES;
  path: number[];
  values: FieldValues;
};

type UpdateQuestions = {
  type: ActionTypes.UPDATE_QUESTIONS;
  updater: React.SetStateAction<QAState>;
};

type ClearValues = {
  type: ActionTypes.CLEAR_VALUES;
  path: number[];
};

type AddComments = {
  type: ActionTypes.ADD_COMMENTS;
};

export type QAAction =
  | AppendQuestion
  | AddComments
  | EditQuestion
  | MoveQuestion
  | RemoveQuestion
  | UpdateAnswer
  | UpdateQuestions
  | UpdateValues
  | ToggleDisclosure
  | ClearValues;

export type IQuestionNode = IQuestionItem | IQuestionGroup;
export type QAState = IQuestionNode[];
