import classNames from "classnames";
import { IBlockMeta, UserTimestamp } from "@/data-models/block";

import React from "react";
import { DraggableProvidedDragHandleProps } from "react-beautiful-dnd";
import BlockCard from "./BlockCard";

export interface BlockContainerProps<
  TBlockIn extends IBlockMeta = IBlockMeta,
  TBlockUpdate extends IBlockMeta = IBlockMeta,
> {
  id: string | number;
  title: string;
  lastModified?: UserTimestamp;
  blockType: string;
  readOnly?: boolean;
  dragHandleProps?: DraggableProvidedDragHandleProps;
  update: (updates: Partial<TBlockUpdate>) => void;
  remove?: () => void;
  insert?: (block: TBlockIn) => void;
  focusContent: () => void;
}

const BlockContainer = {
  Card: BlockCard,
  Toolbar: (props: React.HTMLProps<HTMLDivElement>) => (
    <div
      {...props}
      className={classNames("absolute left-2 top-6", props.className)}
    />
  ),
  Header: (props: React.HTMLProps<HTMLDivElement>) => (
    <div
      {...props}
      className={classNames(
        "inline-flex w-full grow items-center border-b border-gray-light",
        props.className,
      )}
    />
  ),
  Body: (props: React.HTMLProps<HTMLDivElement>) => (
    <div {...props} className={classNames("mt-4", props.className)} />
  ),
};

export default BlockContainer;
