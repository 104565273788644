/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { models__api__identifier_token__Identifier_Input } from './models__api__identifier_token__Identifier_Input';

export type Supervisor_Input = {
    reference?: (string | null);
    type?: Supervisor_Input.type;
    identifier?: (models__api__identifier_token__Identifier_Input | null);
    display?: (string | null);
};

export namespace Supervisor_Input {

    export enum type {
        PRACTITIONER = 'Practitioner',
    }


}

