/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { Annotation } from './Annotation';
import type { CodeableConcept } from './CodeableConcept';
import type { CompositionAttester } from './CompositionAttester';
import type { CompositionEvent } from './CompositionEvent';
import type { data_contracts__common__fhir__r5__datatypes__complex__Identifier_Output } from './data_contracts__common__fhir__r5__datatypes__complex__Identifier_Output';
import type { Meta } from './Meta';
import type { Narrative } from './Narrative';
import type { Reference_Output } from './Reference_Output';
import type { RelatedArtifact } from './RelatedArtifact';
import type { Section } from './Section';
import type { UsageContext } from './UsageContext';

export type Composition = {
    resourceType?: Composition.resourceType;
    id?: (string | null);
    url?: (string | null);
    meta?: (Meta | null);
    text?: (Narrative | null);
    type?: CodeableConcept;
    date?: string;
    name?: (string | null);
    note?: (Array<Annotation> | null);
    title: string;
    event?: (Array<CompositionEvent> | null);
    status?: string;
    author: Array<Reference_Output>;
    version?: (string | null);
    subject?: (Array<Reference_Output> | null);
    section?: Array<Section>;
    language?: (string | null);
    category?: (Array<CodeableConcept> | null);
    attester?: (Array<CompositionAttester> | null);
    encounter?: (Reference_Output | null);
    custodian?: (Reference_Output | null);
    relatesTo?: (Array<RelatedArtifact> | null);
    identifier?: (Array<data_contracts__common__fhir__r5__datatypes__complex__Identifier_Output> | null);
    useContext?: (Array<UsageContext> | null);
    implicitRules?: (string | null);
};

export namespace Composition {

    export enum resourceType {
        COMPOSITION = 'Composition',
    }


}

