import React from "react";
import { Infer } from "superstruct";
import { CalendarIcon } from "@heroicons/react/24/outline";
import Tippy from "@tippyjs/react";
import { DateEntityModel } from "@/data-models/date-entity";
import format from "date-fns/format";
import { nlBE } from "date-fns/locale";

export interface IDateEntityTooltipProps {
  entity: Infer<typeof DateEntityModel>;
  children: React.ReactElement;
}

const DateEntityTooltip = ({ entity, children }: IDateEntityTooltipProps) => {
  const { date } = entity;
  if (!date) return children;
  const dateStr = format(new Date(date), "PPPP", { locale: nlBE });
  return (
    <Tippy
      delay={[500, 50]}
      interactive
      content={
        <div>
          <div className="items-bottom my-1 flex w-full justify-center font-light">
            <CalendarIcon className="-mb-1 mr-1 inline h-4 w-4 stroke-current" />
            <span className="whitespace-nowrap font-normal sm:text-sm">
              {dateStr}
            </span>
          </div>
        </div>
      }
    >
      {children}
    </Tippy>
  );
};
export default DateEntityTooltip;
