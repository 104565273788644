import debounce from "lodash.debounce";
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
  useQuery,
  useQueryClient,
  UseQueryResult,
} from "react-query";
import { func, is } from "superstruct";

import {
  CancelablePromise,
  getBlockByIdV1BlocksBlockIdGet,
  TemplateBlockOut,
  TemplateBlockUpdate,
  updateBlockByIdV1BlocksBlockIdPut,
} from "@/services/templates";

function createKey(blockId: number) {
  return ["template-service", "block", blockId] as const;
}
function createBlockQueryOptions(blockId: number) {
  return {
    queryKey: createKey(blockId),
    queryFn: () => getBlockByIdV1BlocksBlockIdGet({ blockId }),
  };
}

function createBlockMutationOptions<
  T extends TemplateBlockUpdate = TemplateBlockUpdate,
>(blockId: number) {
  return {
    mutationKey: createKey(blockId),
    mutationFn: (block: T): CancelablePromise<TemplateBlockOut> =>
      updateBlockByIdV1BlocksBlockIdPut({ blockId, requestBody: block }),
  } as const;
}

export type MergeFunc<C> = (remote: C, local: C) => C;

export interface AutoSaveOptions {
  wait: number;
  maxWait?: number;
}

export interface UseAutoSyncOptions<TContent, TError = unknown> {
  autoSaveOptions?: AutoSaveOptions;
  alertIfUnsavedChanges?: boolean;
  merge?: MergeFunc<TContent>;
  enabled?: boolean;
  autoSaveDraft?: boolean;
  defaultDraft?: TContent;
  onMutationSucces?: UseMutationOptions<
    TemplateBlockOut,
    TError,
    TContent,
    { prevBlock: TemplateBlockOut }
  >["onSuccess"];
}

export interface UseAutoSyncReturnType<TemplateBlockOut, TError = unknown> {
  draft?: TemplateBlockOut;
  setDraft: Dispatch<SetStateAction<TemplateBlockOut | undefined>>;
  save: () => void;
  saveDebounced: () => void;
  queryResult: UseQueryResult<TemplateBlockOut, unknown>;
  mutationResult: UseMutationResult<
    TemplateBlockOut,
    TError,
    TemplateBlockOut,
    { prevBlock: TemplateBlockOut }
  >;
}

/**
 * Empty function used to avoid the overhead of `lodash.debounce` if autoSaveOptions are not used.
 */
const EmptyDebounceFunc = Object.assign(() => {}, {
  flush: () => {},
  cancel: () => {},
});

/**
 * useAutoSync handles automatic updates for you.
 * It uses optmistic updates as described here: https://react-query-v3.tanstack.com/guides/optimistic-updates
 * @param options
 * @returns
 */
function useAutoSyncTemplateBlock(
  blockId: number,
  options?: UseAutoSyncOptions<TemplateBlockOut>,
): UseAutoSyncReturnType<TemplateBlockOut> {
  // determine the block id
  const {
    autoSaveOptions = { wait: 1000, maxWait: 5000 },
    alertIfUnsavedChanges = true,
    merge,
    enabled = true,
    onMutationSucces,
    defaultDraft,
    autoSaveDraft = true,
  } = options ?? {};

  const queryClient = useQueryClient();

  const [draft, setDraft] = useState(defaultDraft);
  // create a stable ref to the draft so we can memoize the save function
  const draftRef = useRef<TemplateBlockOut | undefined>(undefined);
  draftRef.current = draft;

  // Retrieve the block
  const queryResult = useQuery({
    ...createBlockQueryOptions(blockId),
    enabled,
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60, // 1 minute
  });

  // Create a mutator for the block
  const mutationResult = useMutation({
    ...createBlockMutationOptions<TemplateBlockOut>(blockId),
    onMutate(draft) {
      // optimistically update the cache
      const queryKey = createKey(blockId);
      queryClient.cancelQueries(queryKey);
      const prevBlock = queryClient.getQueryData<TemplateBlockOut>(queryKey);
      if (!prevBlock) return; // no optmistic update possible
      queryClient.setQueryData(queryKey, {
        ...prevBlock,
        content: draft,
      });
      // optimistically clear our draft state because cache is now up to date
      setDraft(undefined);
      return { prevBlock };
    },
    onError(error, draft, context) {
      // revert the optimistic update if the mutation fails
      const { prevBlock } = context ?? {};
      const queryKey = createKey(blockId);
      if (prevBlock) queryClient.setQueryData(queryKey, prevBlock);
      // reset the draft to the last known draft unless the user made more changes
      if (draft !== undefined) {
        setDraft(draft);
      }
    },
    onSuccess(data, variables, context) {
      /**
       *  ⚠️ Don't set the QueryCache of the block because it is possible we have a newer version of the block
       *  for which a mutation is already in flight and an optimistic update is already applied.
       */
      onMutationSucces && onMutationSucces(data, variables, context);
    },
    onSettled() {
      queryClient.invalidateQueries(createKey(blockId));
    },
  });
  const { mutate } = mutationResult;

  // return a stable save function
  const save = useCallback(() => {
    if (draftRef.current !== undefined) {
      mutate(draftRef.current);
    }
  }, [mutate]);

  // memoize a debounced save function
  const saveDebounced = useMemo(
    () =>
      autoSaveOptions?.wait === undefined
        ? EmptyDebounceFunc
        : debounce(save, autoSaveOptions?.wait, {
            // only pass maxWait to the options if maxWait is defined
            // if maxWait is undefined it is set to 0
            ...(autoSaveOptions?.maxWait !== undefined
              ? { maxWait: autoSaveOptions?.maxWait }
              : {}),
          }),
    [autoSaveOptions?.maxWait, autoSaveOptions?.wait, save],
  );

  // create a function which saves and cancels the debounced save
  const saveAndCancelDebounced = useMemo(
    () => () => {
      saveDebounced.cancel();
      save();
    },
    [save, saveDebounced],
  );

  // clean up saveDebounced on unmount to avoid leaks
  useEffect(() => {
    const prevSaveDebounced = saveDebounced;
    return () => {
      prevSaveDebounced.cancel();
    };
  }, [saveDebounced]);

  // call saveDebounced when the draft changes
  useEffect(() => {
    // check that autoSave is enabled and there are local changes to save
    if (
      autoSaveOptions?.wait !== undefined &&
      draft !== undefined &&
      autoSaveDraft
    ) {
      saveDebounced();
    }
  }, [saveDebounced, draft, autoSaveOptions?.wait, autoSaveDraft]);

  // confirm before the user leaves if the draft value isn't saved
  useEffect(() => {
    const shouldPreventUserFromLeaving =
      draft !== undefined && alertIfUnsavedChanges;

    const alertUserIfDraftIsUnsaved = (e: BeforeUnloadEvent) => {
      console.log({ shouldPreventUserFromLeaving });
      if (shouldPreventUserFromLeaving) {
        // Cancel the event
        e.preventDefault(); // If you prevent default behavior in Mozilla Firefox prompt will always be shown
        // Chrome requires returnValue to be set
        e.returnValue = "";
      } else {
        // the absence of a returnValue property on the event will guarantee the browser unload happens
        delete e["returnValue"];
      }
    };

    // only add beforeUnload if there is unsaved work to avoid performance penalty
    if (shouldPreventUserFromLeaving) {
      window.addEventListener("beforeunload", alertUserIfDraftIsUnsaved);
    }
    // document.addEventListener("visibilitychange", saveDraftOnVisibilityChange);
    return () => {
      if (shouldPreventUserFromLeaving) {
        window.removeEventListener("beforeunload", alertUserIfDraftIsUnsaved);
      }
      // document.removeEventListener("visibilitychange", saveDraftOnVisibilityChange);
    };
  }, [alertIfUnsavedChanges, draft, saveAndCancelDebounced]);

  // merge the server data with the local data when the server data changes
  useEffect(() => {
    const serverData = queryResult.data;
    if (serverData !== undefined && merge !== undefined) {
      console.log(
        "merge the server data with the local data when the server data changes",
      );
      setDraft((localData) => {
        if (localData !== undefined) {
          return merge(serverData, localData);
        }
      });
    }
  }, [merge, queryResult.data]);

  const setDraftWrapper = useCallback<
    Dispatch<SetStateAction<TemplateBlockOut | undefined>>
  >(
    (updater) => {
      if (is(updater, func())) {
        setDraft((draft) => {
          const cachedBlock = queryClient.getQueryData<TemplateBlockOut>(
            createKey(blockId),
          );
          const prevValue: TemplateBlockOut | undefined = draft ?? cachedBlock;
          if (prevValue === undefined) return draft;
          return updater(prevValue);
        });
      } else {
        setDraft(updater);
      }
    },
    [queryClient, blockId],
  );

  const localDraft = draft ?? queryResult.data;

  return {
    draft: localDraft,
    setDraft: setDraftWrapper,
    save: saveAndCancelDebounced,
    saveDebounced,
    mutationResult,
    queryResult,
  } as const;
}

export default useAutoSyncTemplateBlock;
