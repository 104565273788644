import type { UniqueIdentifier } from "@dnd-kit/core";
import { arrayMove } from "@dnd-kit/sortable";
import { RecursiveQuestionNode, IQuestionItem, WithKey } from "../models";

import type { Flattened } from "./types";

export const iOS = /iPad|iPhone|iPod/.test(navigator.platform);

export function serializePath(path: string[]) {
  return path.join(".");
}

function getDragDepth(offset: number, indentationWidth: number) {
  return Math.round(offset / indentationWidth);
}

export function getProjection<T>(
  items: Flattened<WithKey<T>>[],
  activePathString: string,
  overPathString: string,
  dragOffset: number,
  indentationWidth: number
) {
  const overItemIndex = items.findIndex(
    ({ pathString }) => pathString === overPathString
  );
  const activeItemIndex = items.findIndex(
    ({ pathString }) => pathString === activePathString
  );
  if (overItemIndex === -1) throw Error("overItemIndex is -1");
  if (activeItemIndex === -1) throw Error("activeItemIndex is -1");
  const activeItem = items[activeItemIndex];
  const newItems = arrayMove(items, activeItemIndex, overItemIndex);
  const previousItem = newItems[overItemIndex - 1];
  const nextItem = newItems[overItemIndex + 1];
  const dragDepth = getDragDepth(dragOffset, indentationWidth);
  const projectedDepth = activeItem.depth + dragDepth;
  const allowDrop =
    items.filter(
      (item) =>
        item.parentPathString === overPathString && item.id == activeItem.id
    ).length == 0;
  const maxDepth = getMaxDepth({
    previousItem,
  });
  const minDepth = getMinDepth({ nextItem });
  let depth = projectedDepth;

  if (projectedDepth >= maxDepth) {
    depth = maxDepth;
  } else if (projectedDepth < minDepth) {
    depth = minDepth;
  }
  return {
    depth,
    maxDepth,
    minDepth,
    allowDrop,
    parentPathString: getParentPathString(),
  };

  function getParentPathString() {
    if (depth === 0 || !previousItem) {
      return null;
    }

    if (depth === previousItem.depth) {
      return previousItem.parentPathString;
    }

    if (depth > previousItem.depth) {
      return previousItem.pathString;
    }

    const newParent = newItems
      .slice(0, overItemIndex)
      .reverse()
      .find((item) => item.depth === depth)?.parentPathString;

    return newParent ?? null;
  }
}

function getMaxDepth<T>({ previousItem }: { previousItem: Flattened<T> }) {
  if (previousItem) {
    return previousItem.depth + 1;
  }

  return 0;
}

function getMinDepth<T>({ nextItem }: { nextItem: Flattened<T> }) {
  if (nextItem) {
    return nextItem.depth;
  }

  return 0;
}

function flatten<T>(
  items: RecursiveQuestionNode<T>[],
  {
    getItemId,
    ...options
  }: {
    getItemId: (item: RecursiveQuestionNode<T>) => string;
    parentPathString?: string | null;
    path?: string[];
    depth?: number;
  }
): Flattened<RecursiveQuestionNode<WithKey<T>>>[] {
  const { parentPathString = null, depth = 0, path = [] } = options;
  return items.reduce<Flattened<RecursiveQuestionNode<WithKey<T>>>[]>(
    (acc, item, index) => {
      const id = getItemId(item);
      const newPath = [...path, id];
      const pathString = serializePath(newPath);
      const nested = flatten(item.questions ?? [], {
        parentPathString: pathString,
        depth: depth + 1,
        path: newPath,
        getItemId,
      });
      const current: Flattened<RecursiveQuestionNode<WithKey<T>>> = {
        ...item,
        questions: nested,
        id,
        parentPathString,
        depth,
        index,
        path: newPath,
        pathString,
      };
      const temp = [...acc, current, ...nested];
      return temp;
    },
    []
  );
}

export function flattenTree<T>(
  items: RecursiveQuestionNode<T>[],
  { getItemId }: { getItemId: (item: RecursiveQuestionNode<T>) => string }
): Flattened<RecursiveQuestionNode<WithKey<T>>>[] {
  return flatten(items, { getItemId });
}

type Nodes<T> = Record<string, RecursiveQuestionNode<T>>;

export function buildTree<T>(
  flattenedItems: Flattened<RecursiveQuestionNode<WithKey<T>>>[]
): RecursiveQuestionNode<WithKey<T>>[] {
  const root = { id: "root", questions: [] };
  const nodes: Nodes<T> = {};

  // @ts-ignore
  nodes["root"] = root;
  const items: Flattened<RecursiveQuestionNode<WithKey<T>>>[] =
    flattenedItems.map((item) => ({ ...item, questions: [] }));

  for (const item of items) {
    const { id, path, depth, index, parentPathString, pathString, ...other } =
      item;
    const nodeKey = parentPathString ?? "root";
    const parent = nodes[nodeKey] ?? findItem(items, nodeKey);
    const node = other as RecursiveQuestionNode<T>;
    nodes[pathString] = node;
    parent.questions = parent.questions ?? [];
    parent.questions.push(node);
  }
  if (!root.questions) throw Error("root.questions is undefined");
  return root.questions;
}

export function findItem<T>(items: WithKey<T>[], itemId: UniqueIdentifier) {
  return items.find(({ id }) => id === itemId);
}

export function findItemDeep<T>(
  items: RecursiveQuestionNode<WithKey<T>>[],
  path: string[]
): RecursiveQuestionNode<WithKey<T>> | undefined {
  for (const item of items) {
    const { id, questions = [] } = item;
    if (id === path[0]) {
      if (path.length > 1) {
        return findItemDeep(questions, path.slice(1));
      }
      return item;
    }
  }

  return undefined;
}

export function removeItem<T extends RecursiveQuestionNode = IQuestionItem>(
  items: WithKey<T>[],
  id: string
) {
  const newItems = [];

  for (const item of items) {
    if (item.id === id) {
      continue;
    }

    if (item.questions?.length) {
      // @ts-ignore
      item.questions = removeItem(item.questions, id);
    }

    newItems.push(item);
  }

  return newItems;
}

function countChildren<T>(
  items: RecursiveQuestionNode<T>[],
  count = 0
): number {
  return items.reduce((acc, { questions }) => {
    if (questions?.length) {
      return countChildren(questions, acc + 1);
    }

    return acc + 1;
  }, count);
}

export function getChildCount<T>(
  items: RecursiveQuestionNode<WithKey<T>>[],
  path: string[]
) {
  const item = findItemDeep(items, path);

  return item ? countChildren(item?.questions ?? []) : 0;
}

export function removeChildrenOf<T>(
  items: Flattened<RecursiveQuestionNode<WithKey<T>>>[],
  pathStrings: string[]
) {
  const excludeParentPathStrings = [...pathStrings];

  return items.filter((item) => {
    if (
      item.parentPathString &&
      excludeParentPathStrings.includes(item.parentPathString)
    ) {
      if (item.questions?.length) {
        excludeParentPathStrings.push(item.pathString);
      }
      return false;
    }

    return true;
  });
}
