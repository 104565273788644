import { BaseElement, BaseText, Descendant } from "slate";
import {
  array,
  Describe,
  lazy,
  string,
  type,
  union,
  Struct,
  Infer,
  literal,
  tuple,
  optional,
} from "superstruct";

export interface ITiroElement extends BaseElement {
  type?: string;
  format?: string;
  inline?: boolean;
  void?: boolean;
  children: TiroDescendant[];
}

export interface ITiroLeaf extends BaseText {
  text: string;
  format?: string;
}

export type TiroDescendant = Descendant;
interface TElement<T extends any> {
  children: (TElement<T> | T)[];
}

export const TextModel: Describe<ITiroLeaf> = type({
  text: string(),
  format: optional(string()),
});

function element<T extends Struct<any, unknown>>(
  leaf: T
): Describe<TElement<Infer<T>>> {
  return type({ children: array(union([lazy(() => element(leaf)), leaf])) });
}

export const ElementModel: Describe<ITiroElement> = element(TextModel);

export interface ITitleElement extends TElement<{ text: string }> {
  type: "title";
  children: [{ text: string }];
}

export const TitleElementModel: Describe<ITitleElement> = type({
  children: tuple([type({ text: string() })]),
  type: literal("title"),
});

export type AnyTiroElement =
  | (ITiroElement & Record<string, any>)
  | ITitleElement;
