import { Concept } from "@/data-models/concept";
import ConceptDropdown, { DropdownProps } from "../Dropdown";
import React from "react";
import { createValueSetExpansionQuery } from "@/services/content/useValueSetExpansion";
import { codingToConcept } from "@/data-models/value-models/types";
import { useQuery } from "react-query";

export interface ValueSetDropdownProps
  extends Omit<DropdownProps<Concept>, "options"> {
  valueset: string;
}
function optionToKey(option: Concept) {
  return option.key;
}
function optionToDisplay(option: Concept) {
  return option.text;
}
function optionToDescription(option: Concept | null) {
  return option?.definition ?? undefined;
}

function ValueSetDropdown_(
  { valueset, disabled = false, ...props }: ValueSetDropdownProps,
  ref?: React.Ref<HTMLButtonElement>
) {
  const csUrl = `http://tiro.health/fhir/CodeSystem/${valueset}/vs`;
  const vsUrl = `http://tiro.health/fhir/ValueSet/${valueset}`;
  const { data: csData } = useQuery({
    ...createValueSetExpansionQuery(csUrl),
    enabled: !disabled,
    select: (valueSet) =>
      valueSet.expansion.contains?.map((c) => new Concept(codingToConcept(c))),
  });
  const { data: vsData } = useQuery({
    ...createValueSetExpansionQuery(csUrl),
    enabled: !disabled,
    select: (valueSet) =>
      valueSet.expansion.contains?.map((c) => new Concept(codingToConcept(c))),
  });
  const concepts = vsData ?? csData ?? [];
  return (
    <ConceptDropdown
      ref={ref}
      options={concepts}
      optionToKey={optionToKey}
      optionToDisplay={optionToDisplay}
      optionToDescription={optionToDescription}
      {...props}
    />
  );
}
const ValueSetDropdown = React.forwardRef(ValueSetDropdown_);
export default ValueSetDropdown;
