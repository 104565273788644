import { IEditorPlugin } from "@/editor/Base/types";
import { TypedElement } from "@/editor/TypedElement";
import { ADD_CHIP_ELEMENT, CHIP_INPUT_ELEMENT } from "./consts";
import RenderAddChipButtonElement from "./RenderAddChipButton";
import RenderChipInputElement from "./RenderChipInputElement";
import { IAddChipButtonElement, IChipInputElement } from "./types";

export const ChipInputElement = {
  ...TypedElement,
  isChipInputElement: (o: any): o is IChipInputElement => {
    return TypedElement.isTypedElement(o) && o.type === CHIP_INPUT_ELEMENT;
  },
  isAddChipButtonElement: (o: any): o is IAddChipButtonElement => {
    return TypedElement.isTypedElement(o) && o.type === ADD_CHIP_ELEMENT;
  },
};

const ChipInputPlugin: IEditorPlugin = {
  renderElement: (props) => {
    const { element } = props;
    if (ChipInputElement.isChipInputElement(element)) {
      return <RenderChipInputElement {...props} element={element} />;
    }
    if (ChipInputElement.isAddChipButtonElement(element)) {
      return <RenderAddChipButtonElement {...props} element={element} />;
    }
  },
  inlineTypes: [CHIP_INPUT_ELEMENT, ADD_CHIP_ELEMENT],
  voidTypes: [CHIP_INPUT_ELEMENT, ADD_CHIP_ELEMENT],
};

export default ChipInputPlugin;
