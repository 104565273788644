import { DefinedValueSet } from "./content-client";

const keyFactory = {
  allTemplateBlocks: () => ["template-blocks"] as const,
  templateBlock: (blockId: string | undefined) =>
    [...keyFactory.allTemplateBlocks(), blockId] as const,
  templateBlockContent: (blockId: string | undefined) =>
    [...keyFactory.templateBlock(blockId), "content"] as const,
  allTemplateReports: (options?: any) => ["template-reports", options] as const,
  templateReport: (templateId: string | undefined) =>
    [...keyFactory.allTemplateReports(), templateId] as const,
  templateReportBlocks: (templateId: string | undefined) =>
    [...keyFactory.templateReport(templateId), "blocks"] as const,
  expandValueSet: (urlOrValueSet: string | DefinedValueSet, options = {}) =>
    ["ValueSet", "$expand", { ...options, urlOrValueSet }] as const,
  expandValueSetById: (id: string, options = {}) =>
    ["ValueSet", id, "$expand", options] as const,
  allValueSets: (options = {}) => ["ValueSet", options],
  allCodeSystems: (options = {}) => ["CodeSystem", options],
  codeLookup: (code: string, system: string, options = {}) => [
    "CodeSystem",
    "$lookup",
    { ...options, code, system },
  ],
};

export default keyFactory;
