import { flip, useFloating } from "@floating-ui/react";
import { useLayoutEffect } from "react";
import { ReactEditor, useSlateStatic } from "slate-react";
import { ISuggestEditor } from "./SuggestEditor";

function useFloatingSuggestionList({ open }: { open?: boolean } = {}) {
  const editor = useSlateStatic() as ISuggestEditor;

  const {
    refs: { floating, setReference },
    x,
    y,
    strategy,
    update,
  } = useFloating({
    open,
    placement: "bottom-start",
    middleware: [flip()],
  });

  /**
   * Sync the editor with the positioning of the SuggestionMenu
   */

  // this piece of code should run strictly after the dom is rendered
  useLayoutEffect(() => {
    // subscribe the handler when the query changes and retrieve the unsubscriber
    const unsubscribeFn = editor.subscribeQuery((query) => {
      // the reference function expects a node, we create a virtual node that simulates the
      // structure of a real element with a getBoundingClientRect function
      const domRange = query && ReactEditor.toDOMRange(editor, query.range);
      const boundingClientRect = domRange && domRange.getBoundingClientRect();
      const virtualNode = boundingClientRect && {
        getBoundingClientRect() {
          return boundingClientRect;
        },
      };
      setReference(virtualNode);
    });
    return unsubscribeFn;
  }, [setReference, editor]);
  return { floating, x, y, strategy, update };
}

export default useFloatingSuggestionList;
