/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { models__api__identifier_token__Identifier_Input } from './models__api__identifier_token__Identifier_Input';

export type Encounter_Input = {
    reference?: (string | null);
    type?: Encounter_Input.type;
    identifier?: (models__api__identifier_token__Identifier_Input | null);
    display?: (string | null);
};

export namespace Encounter_Input {

    export enum type {
        ENCOUNTER = 'Encounter',
    }


}

