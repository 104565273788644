/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { models__api__identifier_token__Identifier_Input } from './models__api__identifier_token__Identifier_Input';

export type SupervisorIdentifier = {
    reference?: (string | null);
    type?: SupervisorIdentifier.type;
    identifier: models__api__identifier_token__Identifier_Input;
    display?: (string | null);
};

export namespace SupervisorIdentifier {

    export enum type {
        PRACTITIONER = 'Practitioner',
    }


}

