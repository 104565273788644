import { useMutation, useQuery, useQueryClient } from "react-query";
import keyFactory from "./keyFactory";
import client, { ApiError, BlockOut } from "./client";
import { BlockUpdate, ReportOut } from "./report-client";
import { assert, integer } from "superstruct";

export function createBlockQuery(blockId: number) {
  return {
    queryKey: keyFactory.block(blockId),
    queryFn: () => client.v1.getBlockV1({ blockId }),
  };
}

export function useBlock(blockId: number | undefined) {
  return useQuery<BlockOut, ApiError>(
    keyFactory.block(blockId),
    () => {
      assert(blockId, integer());
      return client.v1.getBlockV1({
        blockId,
      });
    },
    {
      enabled: !!blockId,
      staleTime: 1000 * 60, // 1 minute
    }
  );
}
export function useBlockMutator(blockId: number) {
  const queryClient = useQueryClient();
  // Create a mutator for the block with react-query
  return useMutation<BlockOut, Error, BlockUpdate, { prevBlock: BlockOut }>(
    async (requestBody) =>
      client.v1.updateBlockV1({
        blockId,
        requestBody,
      }),
    {
      onMutate(updates) {
        // optimistically update the cache
        const queryKey = keyFactory.block(blockId!);
        queryClient.cancelQueries(queryKey);
        const prevBlock = queryClient.getQueryData<BlockOut>(queryKey);
        if (!prevBlock) return; // no optmistic update possible
        const nextBlock = { ...prevBlock, ...updates };
        queryClient.setQueryData(queryKey, nextBlock);
        queryClient.invalidateQueries(keyFactory.report(prevBlock.reportId));
        return { prevBlock };
      },
      onError(error, updates, context) {
        const { prevBlock } = context ?? {};
        if (!prevBlock) return;
        queryClient.setQueryData(keyFactory.block(blockId!), prevBlock);
      },
      onSuccess(data) {
        const prevReportData = queryClient.getQueryData<ReportOut>(
          keyFactory.report(data?.reportId)
        );
        if (!prevReportData) return;
        queryClient.setQueryData<ReportOut>(keyFactory.report(data.reportId), {
          ...prevReportData,
          lastModified: data.lastModified,
        });
      },
    }
  );
}

export function useBlockLinkCreator(blockId: number) {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (target: number) =>
      client.v1.linkBlockToOtherV1({
        blockId,
        target,
      }),
    onSettled() {
      queryClient.invalidateQueries(keyFactory.block(blockId));
    },
  });
}

export function useBlockContent(blockId: number) {
  return useQuery<BlockOut, ApiError, unknown>(
    keyFactory.block(blockId),
    () => client.v1.getBlockV1({ blockId }),
    { select: (b) => b.content }
  );
}
export default useBlock;
