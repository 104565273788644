import classNames from "classnames";
import { AuthError, AuthErrorCodes } from "firebase/auth";
import PasswordInput from "@/components/PasswordInput";
import { useTranslation } from "react-i18next";
import { Form, useActionData } from "react-router-dom";

const EMAIL_ERROR_MAP: Map<
  typeof AuthErrorCodes[keyof typeof AuthErrorCodes],
  string
> = new Map([
  [AuthErrorCodes.EMAIL_EXISTS, "Dit email adres is al in gebruik."],
  [AuthErrorCodes.INVALID_EMAIL, "Dit email adres is ongeldig."],
]);

const PASSWORD_ERROR_MAP: Map<
  typeof AuthErrorCodes[keyof typeof AuthErrorCodes],
  string
> = new Map([
  [AuthErrorCodes.WEAK_PASSWORD, "Dit wachtwoord is te zwak."],
  [AuthErrorCodes.INVALID_PASSWORD, "Dit wachtwoord is ongeldig."],
]);

type ErrorCode = typeof AuthErrorCodes[keyof typeof AuthErrorCodes];

function EmailPassSignupPanel() {
  const { t } = useTranslation();
  const data = useActionData();
  const { error } = (data ?? {}) as { error?: AuthError };
  const { code: errorCode, message: errorMessage } = error ?? {};
  const emailError = errorCode && EMAIL_ERROR_MAP.get(errorCode as ErrorCode);
  const passwordError =
    errorCode && PASSWORD_ERROR_MAP.get(errorCode as ErrorCode);

  return (
    <div className="space-y-6 bg-white px-4 py-8 shadow sm:space-y-5 sm:rounded-lg sm:px-10">
      <Form method="post" className="space-y-6">
        {errorCode != AuthErrorCodes.WEAK_PASSWORD &&
          errorCode != AuthErrorCodes.EMAIL_EXISTS &&
          errorMessage && (
            <div className="mt-1">
              <p className="text-sm text-red-600">{errorMessage}</p>
            </div>
          )}
        <div>
          <label
            htmlFor="email"
            className="block text-sm font-medium text-gray-dark"
          >
            Email
          </label>

          <div className="mt-1">
            <input
              id="email"
              name="email"
              type="email"
              autoComplete="email"
              className={classNames(
                "block w-full appearance-none rounded-md border px-3 py-2 placeholder-gray-400 shadow-sm focus:outline-none sm:text-sm",
                {
                  "border-gray-light focus:border-blue-500 focus:ring-blue-500":
                    !emailError,
                  "border-red-500 ring-1 ring-red-500": emailError,
                }
              )}
              placeholder="email"
              required
            />
            {emailError && errorCode == AuthErrorCodes.EMAIL_EXISTS && (
              <div className="mt-1">
                <p className="text-sm text-red-600">
                  {t("email-exists-message")}
                  <a
                    className="mx-1 text-blue-primary underline"
                    href="./login"
                  >
                    {t("log-in-instruction")}
                  </a>
                </p>
              </div>
            )}
          </div>
        </div>
        <div className="group">
          <label
            htmlFor="password"
            className="block text-sm font-medium text-gray-dark"
          >
            Wachtwoord
          </label>
          <div>
            <PasswordInput
              id="password"
              name="password"
              autoComplete="current-password"
              className={classNames(
                "block w-full appearance-none rounded-md border px-3 py-2 placeholder-gray-400 shadow-sm focus:outline-none sm:text-sm",
                {
                  "border-gray-light focus:border-blue-500 focus:ring-blue-500":
                    !passwordError,
                  "border-red-500 ring-1 ring-red-500": passwordError,
                }
              )}
              placeholder="wachtwoord"
              minLength={6}
            />
          </div>
          <div className="mt-1">
            <span
              className={classNames("text-gray-primary sm:text-sm", {
                "text-red-500 group-focus-within:text-gray-primary":
                  errorCode == AuthErrorCodes.WEAK_PASSWORD,
              })}
            >
              {t("weak-password-instruction")}
            </span>
          </div>
        </div>
        <div>
          <button
            type="submit"
            className="flex w-full justify-center rounded-md border border-transparent bg-blue-primary px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 disabled:bg-gray-400 hover:disabled:bg-gray-400 focus:disabled:ring-gray-primary"
          >
            Registreer
          </button>
        </div>
      </Form>
    </div>
  );
}

export default EmailPassSignupPanel;
