import { RenderType } from "./types";
import { Spinner } from "@/Icons";
import React, { useCallback, useImperativeHandle, useRef } from "react";
import { validate } from "superstruct";
import { FocusableBlockContent } from "../useFocusableBlockContent";
import { QAAction } from "@/components/questionnaire-v2/models";
import { RenderTemplateBlockContentProps } from "../RenderTemplateBlockContent";
import useAutoSyncTemplateBlockContent from "@/services/content/useAutoSyncTemplateBlockContent";
import FreeTextContentModel, { IFreeTextContent } from "./models";
import FreeText from "@/components/free-text-v1/FreeText";

interface RenderFreeTextTemplateBlockProps
  extends RenderTemplateBlockContentProps {
  renderType: RenderType;
}

function RenderFreeTextTemplateBlockContentWithoutRef(
  props: RenderFreeTextTemplateBlockProps,
  ref: React.Ref<FocusableBlockContent>,
) {
  const { draft, queryResult, setDraft } =
    useAutoSyncTemplateBlockContent<IFreeTextContent>(FreeTextContentModel);

  const questionnaireRef = useRef<HTMLDivElement>(null);

  useImperativeHandle(
    ref,
    () => ({
      focus: () => questionnaireRef.current?.focus(),
    }),
    [],
  );

  if (queryResult.isLoading) {
    return (
      <div className="flex items-center justify-center">
        <Spinner className="mx-2 inline h-6 w-6 animate-spin text-gray-primary" />
      </div>
    );
  }
  const result = validate(draft, FreeTextContentModel, {
    mask: true,
    coerce: true,
  });
  if (result[0]) throw result[0];

  const validDraft = result[1];

  return (
    <FreeText
      values={validDraft.data ?? {}}
      onChange={(values) => setDraft({ data: values })}
      onSubmit={(values) => setDraft({ data: values })}
    />
  );
}

const RenderQuestionnaireBlock = React.forwardRef(
  RenderFreeTextTemplateBlockContentWithoutRef,
);
export default RenderQuestionnaireBlock;
