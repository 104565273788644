import {
  Announcements,
  closestCenter,
  DndContext,
  DragEndEvent,
  DragMoveEvent,
  DragOverEvent,
  DragStartEvent,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { SensorContext } from "./types";
import { sortableTreeKeyboardCoordinates } from "./keyboardCoordinates";
import {
  buildTree,
  flattenTree,
  getProjection,
  removeChildrenOf,
} from "./utilities";
import { create, optional, string } from "superstruct";
import {
  arrayMove,
  SortableContext,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import cloneDeep from "lodash/cloneDeep";
import { measuring } from "./config";
import {
  SortableTreeContext,
  SortableTreeContextValue,
} from "./SortableTreeContext";
import { RecursiveQuestionNode } from "../models";

interface SortableTreeProps<T> {
  items?: RecursiveQuestionNode<T>[];
  onItemsChange?: Dispatch<SetStateAction<RecursiveQuestionNode<T>[]>>;
  getItemId: (item: RecursiveQuestionNode<T>) => string;
  collapsible?: boolean;
  indentationWidth: number;
}

function SortableTreeManager<T>({
  children,
  items = [],
  onItemsChange,
  getItemId,
  indentationWidth,
}: React.PropsWithChildren<SortableTreeProps<T>>) {
  const [activePathString, setActivePathString] = useState<string | null>(null);
  const [overPathString, setOverPathString] = useState<string | null>(null);
  const [offsetLeft, setOffsetLeft] = useState(0);
  const [currentPosition, setCurrentPosition] = useState<{
    parentPathString: string | null;
    overPathString: string;
  } | null>(null);

  console.log({ currentPosition, overPathString, activePathString });
  const flattenedQuestions = useMemo(() => {
    const flattenedTree = flattenTree(items, { getItemId });

    const collapsedItemPathStrings = flattenedTree.reduce<string[]>(
      (acc, { questions, collapsed, pathString }) =>
        collapsed && questions?.length ? [...acc, pathString] : acc,
      [],
    );

    return removeChildrenOf(
      flattenedTree,
      activePathString
        ? [activePathString, ...collapsedItemPathStrings]
        : collapsedItemPathStrings,
    );
  }, [activePathString, items, getItemId]);

  const projected =
    activePathString && overPathString
      ? getProjection(
          flattenedQuestions,
          activePathString,
          overPathString,
          offsetLeft,
          indentationWidth,
        )
      : null;
  const sensorContext: SensorContext = useRef({
    items: flattenedQuestions,
    offset: offsetLeft,
  });
  const [coordinateGetter] = useState(() =>
    sortableTreeKeyboardCoordinates(sensorContext, true, indentationWidth),
  );
  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: { delay: 100, tolerance: 5 },
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter,
    }),
  );

  const sortedIds = useMemo(
    () => flattenedQuestions.map(({ pathString }) => pathString),
    [flattenedQuestions],
  );
  const activeItem = activePathString
    ? flattenedQuestions.find(
        ({ pathString }) => pathString === activePathString,
      )
    : null;

  useEffect(() => {
    sensorContext.current = {
      items: flattenedQuestions,
      offset: offsetLeft,
    };
  }, [flattenedQuestions, offsetLeft]);

  const announcements: Announcements = {
    onDragStart({ active }) {
      return `Picked up ${active.id}.`;
    },
    onDragMove({ active, over }) {
      return getMovementAnnouncement(
        "onDragMove",
        create(active.id, string()),
        create(over?.id, optional(string())),
      );
    },
    onDragOver({ active, over }) {
      return getMovementAnnouncement(
        "onDragOver",
        create(active.id, string()),
        create(over?.id, optional(string())),
      );
    },
    onDragEnd({ active, over }) {
      return getMovementAnnouncement(
        "onDragEnd",
        create(active.id, string()),
        create(over?.id, optional(string())),
      );
    },
    onDragCancel({ active }) {
      return `Moving was cancelled. ${active.id} was dropped in its original position.`;
    },
  };

  const sortableTreeContext: SortableTreeContextValue = useMemo(
    () =>
      activeItem && activePathString && projected
        ? { activeItem, activePathString, projected }
        : { activeItem: null, activePathString: null, projected: null },
    // activeItem is not a dependency because it is derived from activePathString
    [activePathString, overPathString, offsetLeft], // eslint-disable-line react-hooks/exhaustive-deps
  );

  return (
    <DndContext
      accessibility={{ announcements }}
      sensors={sensors}
      collisionDetection={closestCenter}
      measuring={measuring}
      onDragStart={handleDragStart}
      onDragMove={handleDragMove}
      onDragOver={handleDragOver}
      onDragEnd={handleDragEnd}
      onDragCancel={handleDragCancel}
    >
      <SortableTreeContext.Provider value={sortableTreeContext}>
        <SortableContext
          items={sortedIds}
          strategy={verticalListSortingStrategy}
        >
          {children}
        </SortableContext>
      </SortableTreeContext.Provider>
    </DndContext>
  );

  function handleDragStart({ active: { id } }: DragStartEvent) {
    const activePathString = create(id, string());
    console.debug("Drag start: " + activePathString);
    setActivePathString(create(activePathString, string()));
    setOverPathString(create(activePathString, string()));

    const activeItem = flattenedQuestions.find(
      ({ pathString }) => pathString === activePathString,
    );

    if (activePathString && activeItem) {
      setCurrentPosition({
        parentPathString: activeItem.parentPathString,
        overPathString: activePathString,
      });
      document.body.style.setProperty("cursor", "grabbing");
    }
  }

  function handleDragMove({ delta }: DragMoveEvent) {
    setOffsetLeft(delta.x);
  }

  function handleDragOver({ over }: DragOverEvent) {
    setOverPathString((over?.id as string) ?? null);
  }

  function handleDragEnd({ active, over }: DragEndEvent) {
    resetState();

    if (projected && over) {
      const { depth, parentPathString } = projected;
      const clonedItems = cloneDeep(flattenTree(items, { getItemId }));

      const overPathString = create(over.id, string());
      const activePathString = create(active.id, string());
      const overIndex = clonedItems.findIndex(
        ({ pathString }) => pathString === overPathString,
      );
      const activeIndex = clonedItems.findIndex(
        ({ pathString }) => pathString === activePathString,
      );
      const activeTreeItem = clonedItems[activeIndex];
      /**
       * Check for path collisions
       */

      const collisions = clonedItems.filter(
        (item) =>
          item.id === activeTreeItem.id &&
          item.parentPathString === parentPathString,
      );
      if (collisions.length > 0) {
        console.warn(
          "Can't move item to level " +
            depth +
            " because it already exists there.",
        );
        return;
      }
      clonedItems[activeIndex] = { ...activeTreeItem, depth, parentPathString };

      const sortedItems = arrayMove(clonedItems, activeIndex, overIndex);
      const newItems = buildTree(sortedItems);

      onItemsChange && onItemsChange(newItems);
    }
  }

  function handleDragCancel() {
    resetState();
  }

  function resetState() {
    setOverPathString(null);
    setActivePathString(null);
    setOffsetLeft(0);
    setCurrentPosition(null);

    document.body.style.setProperty("cursor", "");
  }

  function getMovementAnnouncement(
    eventName: string,
    activePathString: string,
    overPathString?: string,
  ) {
    if (overPathString && projected) {
      if (eventName !== "onDragEnd") {
        if (
          currentPosition &&
          projected.parentPathString === currentPosition.parentPathString &&
          overPathString === currentPosition.overPathString
        ) {
          return;
        } else {
          setCurrentPosition({
            parentPathString: projected.parentPathString,
            overPathString,
          });
        }
      }

      const clonedItems = cloneDeep(flattenTree(items, { getItemId }));
      const overIndex = clonedItems.findIndex(
        ({ pathString }) => pathString === overPathString,
      );
      const activeIndex = clonedItems.findIndex(
        ({ pathString }) => pathString === activePathString,
      );
      const sortedItems = arrayMove(clonedItems, activeIndex, overIndex);

      const previousItem = sortedItems[overIndex - 1];

      let announcement;
      const movedVerb = eventName === "onDragEnd" ? "dropped" : "moved";
      const nestedVerb = eventName === "onDragEnd" ? "dropped" : "nested";

      if (!previousItem) {
        const nextItem = sortedItems[overIndex + 1];
        announcement = `${activePathString} was ${movedVerb} before ${nextItem.id}.`;
      } else {
        if (projected.depth > previousItem.depth) {
          announcement = `${activePathString} was ${nestedVerb} under ${previousItem.id}.`;
        } else {
          let previousSibling: typeof previousItem | undefined = previousItem;
          while (previousSibling && projected.depth < previousSibling.depth) {
            const parentId: string | null = previousSibling.parentPathString;
            previousSibling = parentId
              ? sortedItems.find(({ id }) => id === parentId)
              : undefined;
          }

          if (previousSibling) {
            announcement = `${activePathString} was ${movedVerb} after ${previousSibling.id}.`;
          }
        }
      }

      return announcement;
    }

    return;
  }
}

export default SortableTreeManager;
