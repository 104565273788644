import { Node } from "slate";
import { RenderTypedElementProps } from "../../TypedElement";
import { ITitleElement } from "./TitleElement";

export interface RenderTitleElementProps extends RenderTypedElementProps {
  element: ITitleElement;
}

function RenderTitleElement({
  attributes,
  element,
  children,
}: RenderTitleElementProps) {
  const text = Node.string(element);
  const isEmpty = text.length === 0;
  return (
    <h2
      {...attributes}
      className="relative !mb-1 !mt-1 pb-2 text-2xl font-semibold tracking-wide"
    >
      {isEmpty && (
        <span
          contentEditable={false}
          className="pointer-events-none absolute w-full select-none text-gray-light"
        >
          Typ een titel of filter bouwblokken
        </span>
      )}
      {children}
    </h2>
  );
}

export default RenderTitleElement;
