import { LoaderFunction, redirect } from "react-router";
import { getTenantAuth } from "@/services/tenant";
import { LOGIN_PATH } from "../login/const";

export default (): LoaderFunction => async () => {
  const { currentUser } = getTenantAuth() ?? {};
  // this page is only for authenticated users
  if (!currentUser) return redirect(LOGIN_PATH);
  return null;
};
