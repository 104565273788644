import { BlockOut } from "@/services/reports/report-client";
import useBlock from "@/services/reports/useBlock";
import useCurrentReportBlockId from "./useCurrentReportBlockId";

function useCurrentBlock() {
  const blockId = useCurrentReportBlockId();
  return useBlock(blockId);
}

export default useCurrentBlock;

interface CurrentBlockProviderProps {
  children: (block: BlockOut) => JSX.Element;
}

export const ProvideCurrentBlock = ({
  children,
}: CurrentBlockProviderProps) => {
  const { data: block } = useCurrentBlock();
  return block ? children(block) : null;
};
