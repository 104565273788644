import { Coding } from "@/data-models/value-models";
import {
  CodeableConceptModel,
  CodingModel,
  QuantityModel,
} from "@/data-models/value-models/structs";
import get from "lodash/get";
import { is, type } from "superstruct";
import { IEnabledWhen, IEnabledWhenCondition } from "./types";

function evaluateCondition(
  condition: IEnabledWhenCondition,
  data: Record<string, any>,
) {
  const { operator, operand, linkId } = condition;
  const value = get(data, linkId);
  switch (operator) {
    case "=": {
      if (is(operand, CodingModel)) {
        return Coding.fromObj(operand).equals(value);
      }
      break;
    }
    case "!=": {
      if (is(operand, CodingModel)) {
        return !Coding.fromObj(operand).equals(value);
      }
      break;
    }
    case "exists": {
      if (value === undefined) return !operand;
      if (is(value, CodingModel)) return operand;
      if (is(value, CodeableConceptModel))
        return operand == (!!value.text || !!value.coding.length);
      if (is(value, QuantityModel)) return !!value.value == operand;
      if (is(value, type({ code: CodeableConceptModel, value: QuantityModel })))
        return operand == !!value.value.value;
      if (is(value, type({ code: CodingModel, value: CodeableConceptModel })))
        return operand == (!!value.value.text || !!value.value.coding.length);
      break;
    }
  }
  return false;
}

export default function isEnabled(
  isEnabledWhen: IEnabledWhen,
  data: Record<string, any>,
): boolean {
  const conditionResults = isEnabledWhen.conditions.map((condition) =>
    evaluateCondition(condition, data),
  );

  if (isEnabledWhen.mode === "all") {
    return conditionResults.every((result) => result);
  } else {
    return conditionResults.some((result) => result);
  }
}
