function getPosition(
  index: number,
  length: number,
  first: "left" | "right" | "center" = "left"
): "left" | "right" | "center" {
  if (index == 0) return first;
  if (index == length - 1) return "right";
  return "center";
}
export default getPosition;
