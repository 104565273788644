import { RenderType, IRichTextContent } from "./types";
import useRichTextBlockEditor from "./useRichTextBlockEditor";
import toast from "react-hot-toast";
import { ReactEditor, Slate } from "slate-react";
import { Spinner } from "@/Icons";
import EditorErrorBoundary from "@/components/EditorErrorBoundary";
import SuggestEditable from "@/editor/legacy/Suggestions/SuggestEditable";
import { Footer } from "./Footer";
import { RenderPlacholder } from "./RenderPlacholder";
import { removeTitleNodeIfExits } from "./backwards-compatibility";
import React, { useImperativeHandle, useMemo } from "react";
import RichTextContentModel from "./models";
import { assert } from "superstruct";
import cloneDeep from "lodash/cloneDeep";
import { FocusableBlockContent } from "../useFocusableBlockContent";
import { RenderTemplateBlockContentProps } from "../RenderTemplateBlockContent";
import useAutoSyncTemplateBlockContent from "@/services/content/useAutoSyncTemplateBlockContent";

interface RenderRichTextTemplateBlockContentProps
  extends RenderTemplateBlockContentProps {
  renderType: RenderType;
}

function RenderRichTextBlockContentWithoutRef(
  props: RenderRichTextTemplateBlockContentProps,
  ref: React.Ref<FocusableBlockContent>,
) {
  const { renderType, readOnly, templateBlockId } = props;

  const [editor, plugins] = useRichTextBlockEditor({ renderType });

  useImperativeHandle(
    ref,
    () => ({
      focus: () => ReactEditor.focus(editor),
    }),
    [editor],
  );

  const { draft, setDraft, queryResult } =
    useAutoSyncTemplateBlockContent<IRichTextContent>(RichTextContentModel, {
      autoSaveDraft: false,
    });

  /**
   * Title used to be part of the Slate Document.
   * As of 12/09/2022 we removed the title into a dedicated field outside the block content.
   * This conversion removes the title node if it exits inside the document.
   * This script should stay in plays until a database migration is completed.
   */
  const compatibleDraft = useMemo(
    () => draft && removeTitleNodeIfExits(cloneDeep(draft)),
    [draft],
  );
  const addEntryFooter = props.renderType !== "table-rich-text";
  const editableFooter = useMemo(
    () => (addEntryFooter ? <Footer /> : undefined),
    [addEntryFooter],
  );
  if (queryResult.isLoading) {
    return (
      <div className="flex items-center justify-center">
        <Spinner className="mx-2 inline h-6 w-6 animate-spin text-gray-primary" />
      </div>
    );
  }
  assert(compatibleDraft, RichTextContentModel);

  return (
    <Slate
      key={templateBlockId} // make sure a rerender is triggered when the block changes
      editor={editor}
      value={compatibleDraft}
      onChange={(value) =>
        readOnly
          ? toast.error(
              "Deze bouwblok kan niet bewerkt worden omdat je niet de oorspronkelijke auteur bent.",
              { id: "unauthorized-block-update" },
            )
          : setDraft(value as IRichTextContent)
      }
    >
      <div className="relative w-full">
        <EditorErrorBoundary editor={editor}>
          <SuggestEditable
            className="block w-full border-0 focus:bg-white"
            editableClassname="text-primary p-0 space-y-1"
            editableFooter={editableFooter}
            placeholder=""
            renderPlaceholder={RenderPlacholder}
            spellCheck={false}
            readOnly={readOnly}
            plugins={plugins}
          />
        </EditorErrorBoundary>
      </div>
    </Slate>
  );
}

const RenderRichTextBlockContent = React.forwardRef(
  RenderRichTextBlockContentWithoutRef,
);
export default RenderRichTextBlockContent;
