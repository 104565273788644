import { ArrowTopRightOnSquareIcon } from "@heroicons/react/24/outline";
import { IHumanReadableCoding } from "@/data-models/value-models/types";
import { MouseEventHandler, useState } from "react";
import CodingCard from "./CodingCard";
import { useFeatureFlagEnabled } from "posthog-js/react";

export const CODING_TOOLTIP_DELAY_GROUP_ID = "coding-tooltip-delay";

type ConceptTooltipProps = {
  coding: IHumanReadableCoding;
  showCard?: boolean;
};
function CodingTooltip({
  coding,
  showCard: showCardForce,
}: ConceptTooltipProps) {
  const showCardFF = useFeatureFlagEnabled("show-coding-card-tooltip");
  const [showCard, setShowCard] = useState(showCardForce || showCardFF);
  const handleClick: MouseEventHandler = (event) => {
    setShowCard(true);
    event.preventDefault();
    event.stopPropagation();
  };
  return showCard || showCardForce || showCardFF ? (
    <CodingCard {...coding} className="drop-shadow-lg" />
  ) : (
    <p className="rounded-md bg-gray-darker px-2 py-1 text-sm text-gray-ultralight ">
      <button
        type="button"
        className="inline-flex items-center text-xs"
        onClick={handleClick}
      >
        More info
        <ArrowTopRightOnSquareIcon className="inline h-3 w-3" />
      </button>
    </p>
  );
}
export default CodingTooltip;
