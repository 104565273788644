import classNames from "classnames";
import React from "react";
import { IDesignation } from "../models";

type DesignationDisplayProps = {
  designation: IDesignation;
  className?: string;
  isEditable?: boolean;
  onRequestEdit?: () => void;
  small?: boolean;
  minimal?: boolean;
  active?: boolean;
  showDesignationMeta?: boolean;
  children?: React.ReactNode;
};
function DesignationDisplay({
  designation,
  className,
  isEditable,
  onRequestEdit,
  small,
  active,
  minimal,
  children,
  showDesignationMeta,
  ...props
}: DesignationDisplayProps) {
  return (
    <div className={className} {...props}>
      <button
        type="button"
        onClick={() => onRequestEdit?.()}
        disabled={!isEditable}
        className="flex items-baseline rounded border border-transparent hover:bg-gray-50 hover:disabled:bg-transparent"
      >
        <h6
          title={designation.value}
          className={classNames(
            "shrink truncate whitespace-nowrap align-baseline",
            { "text-sm": small, "text-lg": !small },
            {
              " text-gray-700 dark:text-gray-400": !active,
              "text-blue-50": active && minimal,
              "text-blue-800": active && !minimal,
            }
          )}
        >
          {designation.value}
        </h6>
        <div
          className={classNames(
            "material-symbols-outlined ml-1 translate-y-0.5 align-text-bottom text-sm text-gray-400",
            { hidden: !isEditable, block: isEditable }
          )}
        >
          edit
        </div>
      </button>
      {designation && !minimal && showDesignationMeta && (
        <div className="leading-none">
          <span className="text-xs font-medium uppercase text-gray-400">
            {designation.language}
          </span>
          <span className="mx-1 text-xs text-gray-400">&middot;</span>
          <span className="text-xs text-gray-400">
            {designation.use?.display}
          </span>
        </div>
      )}
      {children}
    </div>
  );
}

export default DesignationDisplay;
