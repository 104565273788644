import CodingCard from "@/components/base/CodingCard/CodingCard";
import { IHumanReadableCoding } from "@/data-models/value-models/types";
import {
  FieldPath,
  FieldValues,
  PathValue,
  useFormContext,
} from "react-hook-form";
import { z } from "zod";

export type UseFormGetValuesContext<TFieldValues extends FieldValues> = {
  getValues: <TName extends FieldPath<TFieldValues>>(
    name: TName
  ) => PathValue<TFieldValues, TName>;
};

function RenderEntityTooltip<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>
>({
  id,
  context,
}: {
  id: TName;
  context?: UseFormGetValuesContext<TFieldValues>;
}) {
  const formContext = useFormContext<TFieldValues>();
  const { getValues } = context || formContext || DUMMY_CTX;
  const entity = getValues(id);
  if (isValidCoding(entity)) {
    return <CodingCard {...entity} />;
  }
  return null;
}

export default RenderEntityTooltip;

const DUMMY_CTX = {
  getValues: () => {
    console.warn("No form context passed to RenderEntityTooltip.");
  },
} as const;

function isValidCoding(value: unknown): value is IHumanReadableCoding {
  return z
    .object({
      display: z.string(),
      code: z.string(),
      system: z.string(),
    })
    .safeParse(value).success;
}
