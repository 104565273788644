import classNames from "classnames";
import { NotificationsProvider } from "@/components/controlled/Notifications";
import DemoBanner from "@/components/DemoBanner";
import { TCEConnectionCheck } from "@/components/TCEConnectionHealth";
import { CONFIG } from "@/config";
import i18next from "@/i18n";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { I18nextProvider } from "react-i18next";
import { Outlet } from "react-router";

export default function MainLayout() {
  return (
    <div id="container" className="flex h-screen flex-col overflow-y-hidden">
      {CONFIG.isDemo && <DemoBanner className="h-12" />}
      <div
        className={classNames("absolute inset-x-0 bottom-0", {
          "top-12": CONFIG.isDemo,
          "top-0": !CONFIG.isDemo,
        })}
      >
        <TCEConnectionCheck hidden />
        <DndProvider backend={HTML5Backend}>
          <NotificationsProvider />
          <I18nextProvider i18n={i18next}>
            <Outlet />
          </I18nextProvider>
        </DndProvider>
      </div>
    </div>
  );
}
