import { useQuery, UseQueryOptions } from "react-query";
import keyFactory from "./keyFactory";
import client, { ApiError, ReportOut } from "./client";

type SortField = "created" | "modified";
type SortDirection = "asc" | "desc";

interface UseReportParams {
  subject?: string;
  encounterIdentifier?: string;
  sort?: `${SortField}:${SortDirection}`;
  limit?: number;
  offset?: number;
}

export const createReportsQuery = ({
  offset = 0,
  limit = 100,
  sort = "modified:desc",
  subject,
  encounterIdentifier,
}: UseReportParams) => {
  return {
    queryKey: keyFactory.allReports({
      offset,
      limit,
      sort,
      subject,
      encounterIdentifier,
    }),
    queryFn: () => {
      return client.v1.getReportsV1({
        offset,
        limit,
        sort,
        subject,
        encounterIdentifier,
      });
    },
  };
};

export type CreateReportsQuery = typeof createReportsQuery;

function useReports<TData = ReportOut[]>(
  {
    subject,
    encounterIdentifier,
    limit = 100,
    offset = 0,
    sort = "modified:desc",
  }: UseReportParams,
  options: UseQueryOptions<ReportOut[], ApiError, TData> = {},
) {
  return useQuery<ReportOut[], ApiError, TData>({
    ...createReportsQuery({
      offset,
      limit,
      sort,
      subject,
      encounterIdentifier,
    }),
    ...options,
  });
}

export default useReports;
