import { IBundle } from "@smile-cdr/fhirts/dist/FHIR-R4/interfaces/IBundle";
import { IObservation } from "@smile-cdr/fhirts/dist/FHIR-R4/interfaces/IObservation";
import { ICoding } from "@/data-models/value-models";
import { IQuantity, IReference } from "@/data-models/value-models/types";
import {
  createFHIRQuery,
  FHIRQueryOptions,
} from "@/expressions/fhir-query/query";
import { z } from "zod";

type CreateObservationBasedPrepopulationQueryOptions = {
  codes: ICoding[];
  subject: IReference;
  afterDate: Date;
  type: "quantity";
} & FHIRQueryOptions;

const ResponseSchema = z
  .object({
    resourceType: z.literal("Bundle"),
    entry: z
      .array(
        z.object({
          resource: z.any(),
        })
      )
      .optional(),
  })
  .passthrough();

export function createObservationBasedPrepopulationQuery({
  codes,
  subject,
  afterDate,
  type = "quantity",
  ...options
}: CreateObservationBasedPrepopulationQueryOptions) {
  const query = new URLSearchParams({
    subject: `${subject.reference}`,
    code: codes.map((code) => `${code.system}|${code.code}`).join(","),
    status: "final,corrected",
    date: `ge${afterDate.toISOString()}`,
    _sort: "-date",
    _count: "1",
  });
  const target = `Observation?${query.toString()}`;
  return {
    ...createFHIRQuery(target, { ...options, schema: ResponseSchema }),
    select: (bundle: IBundle) => valueSelector(bundle, { url: target, type }),
  };
}

function valueSelector(
  bundle: IBundle,
  options: { url: string; type: "quantity" }
): IQuantity<undefined | number> | undefined {
  const { url, type } = options;
  const observation = bundle.entry?.[0]?.resource as IObservation;
  if (!observation) {
    console.debug("No observation found for query: ", url);
    return undefined;
  }
  if (observation.resourceType !== "Observation") {
    console.warn(
      "Expected Observation resource type, but got: ",
      observation.resourceType
    );
    return undefined;
  }
  switch (type) {
    case "quantity": {
      const valueQuantity = observation.valueQuantity;
      if (!valueQuantity) {
        console.warn(
          "Expected Observation.valueQuantity, but got: ",
          observation
        );
        return undefined;
      }
      return valueQuantity as IQuantity<undefined | number>; // FIXME: fix typing
    }
  }
}
