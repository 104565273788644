import InlineChromiumBugfix from "@/components/InlineChromiumBugFix";
import ChipInput from "@/components/inputs/ChipInput/ChipInput";
import { RenderTypedElementProps } from "@/editor/TypedElement";
import {
  conceptAsCoding,
  ICodeableConcept,
} from "@/data-models/value-models/types";
import PropertiesForm from "./PropertiesForm";
import { SettingsPanelPortal } from "@/components/layout/SettingsPanel";
import { Transition } from "@headlessui/react";
import useSlateInputElement from "@/editor/utils/useSlateInputElement";
import { CodeableConcept } from "@/data-models/value-models/code";
import { IChipInputElement } from "./types";
import Tooltip from "@/components/info-cards/Tooltip";
import ConceptTooltip from "@/components/base/CodingCard/ConceptTooltip";
import React from "react";
import CodingTooltip from "@/components/base/CodingCard/CodingTooltip";
import { useFeatureFlagEnabled } from "posthog-js/react";

export interface RenderChipInputElementProps extends RenderTypedElementProps {
  element: IChipInputElement;
}

const ConceptChipInput = React.forwardRef(
  ChipInput<ICodeableConcept, ICodeableConcept>,
);
function RenderChipInputElement({
  element,
  attributes,
  children,
}: RenderChipInputElementProps) {
  const { name, children: _, type, code, ...other } = element;
  const showCodingCardTooltip = useFeatureFlagEnabled(
    "show-coding-card-tooltip",
  );
  const { update, showProperties, disabled, active, initialValue } =
    useSlateInputElement(element);
  return (
    <span {...attributes} tabIndex={-1} className="mx-0.5">
      <InlineChromiumBugfix />
      <Tooltip enabled={showCodingCardTooltip}>
        <Tooltip.Trigger>
          <span>
            <ConceptChipInput
              name={name}
              active={active}
              disabled={disabled}
              equals={(a: ICodeableConcept, b: ICodeableConcept) =>
                !!CodeableConcept.fromObj(a).equivalent(b)
              }
              value={code}
              defaultValue={initialValue}
              getDisplay={(value: ICodeableConcept) => value.text}
              {...other}
            />
          </span>
        </Tooltip.Trigger>
        <Tooltip.Content className="fill-gray-lighter">
          <CodingTooltip coding={conceptAsCoding(code)} />
        </Tooltip.Content>
      </Tooltip>
      <InlineChromiumBugfix />
      {children}
      <SettingsPanelPortal>
        <Transition show={showProperties} enter="ease-in-out duration-75">
          <PropertiesForm
            onSubmit={update}
            defaultValues={{ coding: conceptAsCoding(element.code) }}
          />
        </Transition>
      </SettingsPanelPortal>
    </span>
  );
}
export default RenderChipInputElement;
