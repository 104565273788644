import { ICoding } from "@/data-models/value-models/types";
import client from "@/services/reports/client";
import keyFactory from "@/services/reports/keyFactory";
import { BlockOut, ReportBlockOut } from "@/services/reports/report-client";
import { useCallback, useEffect } from "react";
import { QueryClient, useQuery, useQueryClient } from "react-query";
import { useNavigate } from "react-router";
import { z } from "zod";
import useSMARTWebMessageHandler from "./useSMARTWebMessageHandler";

function areCodingEqual(coding1: ICoding, coding2: ICoding) {
  return coding1.system === coding2.system && coding1.code === coding2.code;
}

const BLOCK_ELEM_ID_PREFIX = "block-container-";

const Payload = z.object({
  code: z.object({
    coding: z.array(
      z.object({
        system: z.string(),
        code: z.string(),
        display: z.string().optional(),
      }),
    ),
  }),
});

type HasCoding = { coding?: ICoding[] };

/**
 * Find a block that matches the given code.
 * @param blocks The blocks to search.
 * @param code The code to match.
 */
function isMatch<TBlock extends HasCoding>(
  blocks: TBlock[],
  payload: z.infer<typeof Payload>,
): TBlock | undefined {
  return blocks.find((block) =>
    block.coding?.some((c1) =>
      payload.code.coding.some((c2) => areCodingEqual(c2, c1)),
    ),
  );
}

/**
 * Get the block container DOM element.
 */
function getBlockContainerDOMElem(blockId: number): HTMLElement {
  return document.getElementById(BLOCK_ELEM_ID_PREFIX + blockId) as HTMLElement;
}

/**
 * Get the coding for a block based on latest data in the query cache.
 */
function getBlockCoding(queryClient: QueryClient, blockId: number): ICoding[] {
  const block = queryClient.getQueryData<BlockOut>(keyFactory.block(blockId));
  const { coding } = block ?? {};
  return coding ?? [];
}
type UseBlockFocusSyncArg = { reportId: number; patientId: number };
type UseBlockFocusSyncOptions = { enabled?: boolean };

/**
 * Sync the scroll navigation of the block with the UI of the webview host.
 * This hooks uses SMART Web Messaging to communicate with the host.
 */
export function useBlockNavigationSync(
  { reportId, patientId }: UseBlockFocusSyncArg,
  options: UseBlockFocusSyncOptions = {},
) {
  const queryClient = useQueryClient();
  useQuery({
    queryKey: keyFactory.reportBlocks(reportId),
    queryFn: () => client.v1.getBlocksFromReportV1({ reportId }),
    staleTime: Infinity,
  });
  const navigate = useNavigate();
  const { enabled } = options;

  /**
   * Handle a focus request from the host.
   */
  const handleFocusMessage = useCallback(
    (raw: unknown): boolean => {
      const parsed = Payload.safeParse(raw);
      // payload doesn't match the expected format
      if (!parsed.success) {
        console.debug("Invalid focus message payload", raw);
        return false;
      }
      // payload is valid
      const queryKey = keyFactory.reportBlocks(reportId);
      const blocks = queryClient.getQueryData<ReportBlockOut[]>(queryKey) ?? [];
      const match = isMatch(blocks, parsed.data);
      if (!match) {
        console.debug("No matching block found", parsed.data, blocks);
        return false;
      }
      navigate(
        {
          pathname: `/patients/${patientId}/reports/${reportId}/blocks/${match.id}/edit`,
        },
        { replace: true },
      );

      return true;
    },
    [reportId, patientId, queryClient, navigate],
  );

  useSMARTWebMessageHandler("ui.focus", handleFocusMessage, { enabled });
}
