import { v4 } from "uuid";
import { z } from "zod";
import { isWebView2 } from "./webview2-bridge";

const TEST_MESSAGE_HANDLE = "AAAA-AAAA-AAAA-AAAA";

const MessageTypes = {
  UIFocus: "ui.focus",
  UIDone: "ui.done",
  ScratchPadUpdate: "scratchpad.update",
  FHIRHTTP: "fhir.http",
} as const;

export type MessageType = (typeof MessageTypes)[keyof typeof MessageTypes];
export const RequestMessage = z.object({
  messageType: z.nativeEnum(MessageTypes),
  messageId: z.string(),
  messagingHandle: z.string(),
  payload: z.unknown(),
});

export const ResponseMessage = z.object({
  messageId: z.string(),
  responseToMessageId: z.string(),
  additionalResponsesExpected: z.boolean().optional(),
  payload: z.unknown(),
});

export async function sendSMARTWebMessage(
  messageType: (typeof MessageTypes)[keyof typeof MessageTypes],
  payload: unknown,
) {
  if (!isWebView2()) {
    console.debug("No WebView2 host, dropping message ", payload);
    return;
  }
  const messageId = v4();
  const messagingHandle = TEST_MESSAGE_HANDLE;
  await window.chrome?.webview.postMessage({
    messageType,
    payload,
    messageId,
    messagingHandle,
  });
}
