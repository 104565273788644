import classNames from "classnames";
import Card, { CardProps } from "@/components/base/Card";

export default function BlockCard(props: CardProps) {
  return (
    <Card
      {...props}
      className={classNames(
        "current-block",
        "py-4", // without drag handle p-4
        "relative px-8", // for drag handle
        props.className,
      )}
    />
  );
}
