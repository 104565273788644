import { PlusIcon } from "@heroicons/react/24/outline";
import classNames from "classnames";
import ReportButton from "@/components/base/ReportButton";
import React, { useCallback, useMemo } from "react";
import { Descendant, Node, Transforms } from "slate";
import {
  Editable,
  ReactEditor,
  RenderElementProps,
  RenderLeafProps,
  Slate,
  useReadOnly,
  useSlateStatic,
} from "slate-react";
import { IEditorPlugin } from "../Base/types";
import createBaseEditor from "./createEditor";

function renderElement({ element, attributes, children }: RenderElementProps) {
  return (
    <p {...attributes} className="relative my-0 mb-1 w-full">
      {children}
    </p>
  );
}

const CommentEditor = ({
  value: initialValue,
  autoFocus,
  className,
  onBlur,
  onChange,
}: {
  value?: string;
  autoFocus?: boolean;
  className?: string;
  onBlur?: () => void;
  onChange?: (content: string) => void;
}) => {
  const editor = useMemo(() => createBaseEditor(), []);
  const [value, setValue] = React.useState<Descendant[]>(() => [
    { children: [{ text: initialValue ?? " " }] },
  ]);
  const handleChange = useCallback(
    (value: Descendant[]) => {
      setValue(value);
      onChange && onChange(value.map(Node.string).join("\n"));
    },
    [onChange],
  );
  return (
    <Slate editor={editor} value={value} onChange={handleChange}>
      <Editable
        autoFocus={autoFocus}
        className={className}
        renderElement={renderElement}
        onBlur={onBlur}
      />
    </Slate>
  );
};

const RenderCommentLeaf = ({
  attributes,
  leaf,
  text,
  children,
}: RenderLeafProps) => {
  const [isEditing, setIsEditing] = React.useState(false);
  const isReadOnly = useReadOnly();
  const isEmpty = text.text.trim() === "";
  const isEnabled = isReadOnly && isEmpty && !isEditing;
  const editor = useSlateStatic();
  const handleCommentChange = useCallback(
    (content: string) => {
      const path = ReactEditor.findPath(editor, text);
      Transforms.insertText(editor, content, { at: path });
    },
    [editor, text],
  );
  return (
    <span {...attributes} className="relative inline-flex items-baseline">
      <span className={isReadOnly ? "hidden" : ""}>{children}</span>
      {(isEditing || !isEmpty) && (
        <CommentEditor
          value={text.text}
          className="ml-1 !inline-flex flex-col rounded-none border-l border-gray-lighter px-1 pb-1 text-sm focus-within:border-l-2 focus:border-gray-dark"
          onBlur={() => setIsEditing(false)}
          onChange={handleCommentChange}
          autoFocus={isEditing}
        />
      )}
      <ReportButton
        onClick={() => setIsEditing(true)}
        disabled={!isEnabled}
        className={classNames("ml-2 inline-flex items-baseline", {
          hidden: !isEnabled,
          "border-transparent text-xs text-transparent hover:border-gray-light hover:text-gray-primary focus:border-gray-lighter focus:text-gray-primary":
            isEnabled,
        })}
        outline
        positionIcon="left"
        Icon={<PlusIcon className="inline h-4 w-4" />}
      >
        <span>Voeg opmerking toe</span>
      </ReportButton>
    </span>
  );
};

const CommentsPlugin: IEditorPlugin = {
  renderLeaf: (props) => {
    const { leaf } = props;
    if (leaf.comment) {
      return <RenderCommentLeaf {...props} />;
    }
  },
};

export default CommentsPlugin;
