/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type UserReference = {
    reference: string;
    type?: UserReference.type;
    identifier?: null;
    display?: (string | null);
    id: number;
};

export namespace UserReference {

    export enum type {
        PRACTITIONER = 'Practitioner',
    }


}

