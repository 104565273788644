import { RouteObject } from "react-router";
import { REPORT_EDIT_PAGE } from "./const";
import ReportEditPage from "./ReportEditPage";

export default function createRoute(): RouteObject {
  return {
    path: REPORT_EDIT_PAGE,
    element: <ReportEditPage />,
  };
}
