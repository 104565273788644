import { PauseIcon, PlayIcon } from "@heroicons/react/20/solid";
import Tippy from "@tippyjs/react";
import classNames from "classnames";
import { HTMLAttributes, useCallback } from "react";
import { Transforms } from "slate";
import { ReactEditor, useSlateStatic } from "slate-react";
import { BaseEntry } from "./types";

export const EntrySettings = ({
  element,
  className,
  ...attributes
}: { element: BaseEntry } & HTMLAttributes<HTMLDivElement>) => {
  const { mute } = element;
  const editor = useSlateStatic() as ReactEditor;
  const path = ReactEditor.findPath(editor, element);
  const handleMuteChange = useCallback(
    (mute: boolean) => {
      Transforms.setNodes(editor, { mute } as Partial<BaseEntry>, { at: path });
    },
    [path, editor]
  );
  return (
    <div
      className={classNames(
        "flex items-center",
        className,
        "invisible current-block-focus-within:current-entry-focus-within:visible current-block-focus-within:current-entry-hover:visible"
      )}
      {...attributes}
      contentEditable={false}
    >
      {!mute ? (
        <Tippy content="Zet de suggesties af." delay={[500, 50]}>
          <button
            onClick={(e) => {
              handleMuteChange(true);
              e.preventDefault();
            }}
            type="button"
            tabIndex={-1}
            className="invisible block current-entry-focus-within:visible current-entry-hover:visible"
          >
            <PauseIcon className="h-4 w-4 text-gray-light " />
          </button>
        </Tippy>
      ) : (
        <Tippy content="Zet de suggesties aan." delay={[500, 50]}>
          <button
            onClick={(e) => {
              handleMuteChange(false);
              e.preventDefault();
            }}
            type="button"
            tabIndex={-1}
            className="invisible block current-entry-focus-within:visible current-entry-hover:visible"
          >
            <PlayIcon className="h-4 w-4 text-gray-light " />
          </button>
        </Tippy>
      )}
    </div>
  );
};
