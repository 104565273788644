import { Disclosure } from "@headlessui/react";
import { ProfileMenu } from "./ProfileMenu";
import classNames from "classnames";
import React, { HTMLAttributes } from "react";
import LogoLink from "./LogoLink";
import useLaunchedFrom from "@/hooks/useLaunchedFrom";
import { useTranslation } from "react-i18next";
import StyledBackLink from "./StyledBackLink";
import { ALL_PATIENTS_PATH } from "@/pages/patients/const";
import { LinkProps } from "react-router-dom";
interface NavBarProps extends HTMLAttributes<HTMLDivElement> {
  showNav?: boolean;
  backLink?: React.ReactNode | false;
}

const NavBar = ({ className, ...props }: NavBarProps) => {
  return (
    <Nav className={className} {...props}>
      <Nav.Left>
        <Nav.Logo>
          <LogoLink color="white" />
        </Nav.Logo>

        <div className="mr-auto" />
      </Nav.Left>
      <Nav.ProfileSlot>
        <ProfileMenu />
      </Nav.ProfileSlot>
    </Nav>
  );
};

export default NavBar;

export function PatientsLink() {
  const { t } = useTranslation();
  const isKWS = useLaunchedFrom("KWS");
  if (isKWS) return null;
  return (
    <StyledBackLink
      to={{ pathname: ALL_PATIENTS_PATH }}
      className=" inline-flex h-9 items-center rounded border border-white bg-opacity-70 pl-3 pr-5 text-white hover:bg-gray-primary sm:text-sm"
    >
      <span>{t("patienten")}</span>
    </StyledBackLink>
  );
}
const ProfileSlot = (props: React.HTMLAttributes<HTMLDivElement>) => (
  <div
    className="relative z-10 flex items-center justify-self-end pr-2 "
    {...props}
  />
);

const Right = (props: React.HTMLAttributes<HTMLDivElement>) => (
  <div
    className="flex flex-1 items-center justify-end justify-self-end"
    {...props}
  />
);

const Left = (props: React.HTMLAttributes<HTMLDivElement>) => (
  <div
    className="flex flex-1 items-center justify-start justify-self-start"
    {...props}
  />
);

const Center = (props: React.HTMLAttributes<HTMLDivElement>) => (
  <div
    className="relative flex flex-shrink-0 items-center justify-center justify-self-center"
    {...props}
  />
);

const Logo = ({ ...props }: React.HTMLAttributes<HTMLDivElement>) => (
  <div className="flex flex-shrink-0 items-center" {...props}>
    <LogoLink color="white" />
  </div>
);

const BackLink = ({
  ...props
}: React.HTMLAttributes<HTMLAnchorElement> & LinkProps) => (
  <StyledBackLink
    className={classNames(
      "mx-4 flex h-9 items-center rounded border border-white bg-opacity-70 pl-3 pr-5 text-white hover:bg-gray-primary sm:text-sm",
    )}
    {...props}
  />
);

export function Nav({
  children,
  className,
  ...props
}: React.HTMLAttributes<HTMLDivElement>) {
  return (
    <nav
      className={classNames(
        "relative z-10 border-b border-gray-light bg-gray-dark shadow-sm",
        className,
      )}
      {...props}
    >
      <div className="mx-auto px-2 sm:px-6 lg:px-8">
        {/** !Fixed height is set here! */}
        <div className="relative flex h-14 justify-between">{children}</div>
      </div>
    </nav>
  );
}

Nav.ProfileSlot = ProfileSlot;
Nav.Logo = Logo;
Nav.BackLink = BackLink;
Nav.Left = Left;
Nav.Center = Center;
Nav.Right = Right;
