import { ISuggestionContext } from "@/components/legacy/suggestions/types";
import { BaseRange, Editor, Element, Node, Range, Text } from "slate";
import { ISuggestEditor } from "./SuggestEditor";
import CheckElement from "../CheckPlugin/CheckElement";

export interface IDetectedQuery {
  range: Range;
  text: string;
}
export type SuggestionQueryDetector = (editor: ISuggestEditor) => Query | null;

// the order is super important!
export const DEFAULT_MODES = [
  "empty-check",
  "forward-r-slash",
  "forward-slash",
  "text-selection",
] as const;

export type SuggestionDetectMode =
  | (typeof DEFAULT_MODES)[number]
  | "empty-block"
  | "begin-of-block";

export class Query implements IDetectedQuery {
  text: string;
  range: Range;
  constructor(text: string, range: Range) {
    this.text = text;
    this.range = range;
  }

  get queryRange(): Range {
    return this.range;
  }
  get targetRange(): Range | null {
    return null;
  }

  equals(other: Query) {
    return this.text === other.text && Range.equals(this.range, other.range);
  }
}

export class AutoCompleteQuery extends Query {
  autoCompleted = true;
}

export class EmptyBlockQuery extends Query {
  empty = true;
}

export class EmptyCheckQuery extends Query {
  get targetRange(): BaseRange {
    return this.range;
  }
}

export class SlashQuery extends Query {
  constructor(text: string, range: Range) {
    if (range.anchor.offset < 1) {
      throw Error(
        "A slash query can't have a range starting at the beginning of a line because of the '/' symbol.",
      );
    }
    super(text, range);
  }
  get context(): ISuggestionContext {
    return { searchType: "search" };
  }
  get targetRange(): Range {
    return {
      ...this.range,
      anchor: {
        ...this.range.anchor,
        offset: this.range.anchor.offset - 1,
      },
    };
  }
  get queryRange(): Range {
    return this.range;
  }
}

export class RSlashQuery extends SlashQuery {
  constructor(text: string, range: Range) {
    if (range.anchor.offset < 2) {
      throw Error(
        "A r-slash query can't have a range starting at the beginning of a line because of the '/' symbol.",
      );
    }
    super(text, range);
  }
  get context(): ISuggestionContext {
    return {
      ...super.context,
      must: [
        "regimes-therapies",
        "drug-therapy",
        "therapeutic-procedure",
        "procedure",
      ],
    };
  }
  get targetRange(): Range {
    return {
      ...this.range,
      anchor: { ...this.range.anchor, offset: this.range.anchor.offset - 2 },
    };
  }
}

export class SelectionQuery extends Query {
  get context(): ISuggestionContext {
    return {
      searchType: "search",
    };
  }
  get targetRange(): Range {
    return this.range;
  }
}

export const detectForwardSlashQueryInEditor: SuggestionQueryDetector = (
  editor: ISuggestEditor,
) => {
  const { selection, mute } = editor;
  if (mute) return null;

  if (!selection) {
    return null;
  }
  if (!selection || Range.isExpanded(selection)) return null;
  // only give suggestions when the selection is collapsed
  const [start] = Range.edges(selection);
  // get the beginning of current line
  const beginLinePoint = Editor.before(editor, start, { unit: "line" });
  // get the range from the beginning of current line to the start of the selection
  const beforeRange =
    beginLinePoint && Editor.range(editor, beginLinePoint, start);
  // extract the corresponding text
  const beforeText = beforeRange && Editor.string(editor, beforeRange);
  // pattern match that text
  const beforeMatch =
    beforeText &&
    beforeText.match(
      /(^|\s)\/\s?(?<query>([a-zA-Z0-9- ]([a-zA-Z0-9--]\/)?)*)$/,
    );
  const query =
    !!beforeMatch && !!beforeMatch.groups ? beforeMatch.groups.query : null;
  const queryStartPoint =
    beginLinePoint &&
    query !== null &&
    query !== undefined &&
    (Editor.before(editor, start, {
      unit: "offset",
      distance: query.length,
    }) ||
      start);
  const queryRange =
    queryStartPoint && Editor.range(editor, queryStartPoint, start);
  const after = Editor.after(editor, start);
  const afterRange = Editor.range(editor, start, after);
  const afterText = Editor.string(editor, afterRange);
  const afterMatch = afterText.match(/^(\s|$)/);
  if ((!!query || query === "") && afterMatch && queryRange) {
    return new SlashQuery(query, queryRange);
  }
  return null;
};

export const detectForwardTherapySlashQueryInEditor: SuggestionQueryDetector = (
  editor: ISuggestEditor,
) => {
  const { selection, mute } = editor;
  if (mute) return null;

  if (!selection) return null;
  if (!selection || Range.isExpanded(selection)) return null;
  // only give suggestions when the selection is collapsed
  const [start] = Range.edges(selection);
  // get the beginning of current line
  const beginLinePoint = Editor.before(editor, start, { unit: "line" });
  // get the range from the beginning of current line to the start of the selection
  const beforeRange =
    beginLinePoint && Editor.range(editor, beginLinePoint, start);
  // extract the corresponding text
  const beforeText = beforeRange && Editor.string(editor, beforeRange);
  // pattern match that text
  const beforeMatch =
    beforeText &&
    beforeText.match(
      /(^|\s)(R|r)\/(?<query>([a-zA-Z0-9 ]([a-zA-Z0-9--]\/)?)*)$/,
    );
  const query =
    !!beforeMatch && !!beforeMatch.groups ? beforeMatch.groups.query : null;
  const queryStartPoint =
    beginLinePoint &&
    query !== null &&
    query !== undefined &&
    Editor.before(editor, start, {
      unit: "offset",
      distance: query.length,
    });
  const queryRange =
    queryStartPoint && Editor.range(editor, queryStartPoint, start);
  const after = Editor.after(editor, start);
  const afterRange = Editor.range(editor, start, after);
  const afterText = Editor.string(editor, afterRange);
  const afterMatch = afterText.match(/^(\s|$)/);
  if ((!!query || query === "") && afterMatch && queryRange) {
    return new RSlashQuery(query, queryRange);
  }
  return null;
};

export const detectLineWithoutBreakCharacters =
  (
    breakCharacters: string[] = ["(", ")", ";", ". ", ", "],
  ): SuggestionQueryDetector =>
  (editor: ISuggestEditor) => {
    const { selection, mute } = editor;
    if (mute) return null;

    if (!selection || !Range.isCollapsed(selection)) {
      return null;
    }
    // search for start point of current leaf node (what if we apply that breaks a leaf formatting??)
    const [start] = Editor.edges(editor, selection);
    const startCurrentLine = Editor.start(editor, start.path);
    const rangeCurrentLine = Editor.range(editor, startCurrentLine, start);
    const textCurrentLine = Editor.string(editor, rangeCurrentLine);

    // check if current text leaf contains a breaking char
    // if so, start the queryText after last breacking char found
    let lastBreakChar = -1;
    let lastBreakCharLen = 0;
    for (const breakChar of breakCharacters) {
      const hit = textCurrentLine.lastIndexOf(breakChar);
      if (hit > -1) {
        lastBreakChar = hit;
        lastBreakCharLen = breakChar.length;
      }
    }
    let distance = lastBreakChar + lastBreakCharLen;
    const textAfterBreakChar = textCurrentLine.slice(distance);

    // if the residual text starts with whitespace characters, strip them
    const numberOfFrontalWhitespaces =
      textAfterBreakChar.length - textAfterBreakChar.trimLeft().length;
    distance += numberOfFrontalWhitespaces;

    const queryStart =
      distance > 0
        ? Editor.after(editor, startCurrentLine, {
            unit: "offset",
            distance,
          })
        : startCurrentLine;

    // calculate query
    let queryRange = queryStart && Editor.range(editor, queryStart, start);
    let queryText = queryRange && Editor.string(editor, queryRange);
    if (!queryText || queryText.length === 0 || !queryRange) return null;

    return new AutoCompleteQuery(queryText, queryRange);
  };

export const detectEmptyBlockQuery: SuggestionQueryDetector = (
  editor: ISuggestEditor,
) => {
  // selection should exist and be collapsed
  const { selection, mute } = editor;
  if (mute) return null;
  if (!selection || !Range.isCollapsed(selection)) {
    return null;
  }

  //if containing block is not empty, short-circuit
  const blockAboveEntry = Editor.above(editor, {
    match: (n) => !Editor.isInline(editor, n) && Element.isElement(n),
  });
  if (!blockAboveEntry || Node.string(blockAboveEntry[0]).length > 0)
    return null;
  return new EmptyBlockQuery("", selection);
};

export const detectEmptyCheckQuery: SuggestionQueryDetector = (
  editor: ISuggestEditor,
) => {
  // selection should exist and be collapsed
  const { selection } = editor;
  if (!selection || !Range.isCollapsed(selection)) {
    return null;
  }

  //if containing block is not empty, short-circuit
  const parentEntry = Editor.above(editor);
  if (!parentEntry || !CheckElement.isCheckElement(parentEntry[0])) return null;

  // if check has entity already, short-circuit
  if (parentEntry[0].entity) return null;

  const query = Node.string(parentEntry[0]);
  const range = Editor.range(editor, parentEntry[1]);
  return new EmptyCheckQuery(query, range);
};

export const detectTextSelectionQuery: SuggestionQueryDetector = (
  editor: ISuggestEditor,
) => {
  const { selection, mute } = editor;
  if (mute) return null;

  // only proceed when selection is expanded
  if (!selection || !Range.isExpanded(selection)) {
    return null;
  }

  // selection should be hidden when it spans multiple blocks
  const [blockMatch1, blockMatch2] = Editor.nodes(editor, {
    match: (n) => Editor.isBlock(editor, n),
    mode: "lowest",
  });
  if (blockMatch1 && blockMatch2) return null;

  // only text nodes in selection
  const matches = Array.from(Editor.nodes(editor, { mode: "lowest" })).map(
    (entry) => entry[0],
  );
  if (!Text.isTextList(matches)) return null;

  const text = Editor.string(editor, selection);
  if (text.length == 0) return null;
  return new SelectionQuery(text, selection);
};

const MODE_DETECTOR_MAP: Record<SuggestionDetectMode, SuggestionQueryDetector> =
  {
    "forward-r-slash": detectForwardTherapySlashQueryInEditor,
    "forward-slash": detectForwardSlashQueryInEditor,
    "empty-block": detectEmptyBlockQuery,
    "empty-check": detectEmptyCheckQuery,
    "begin-of-block": detectLineWithoutBreakCharacters([
      ")",
      "(",
      ";",
      "\n",
      "|",
      ":",
      ", ",
      ". ",
    ]),
    "text-selection": detectTextSelectionQuery,
  };

const detectQuery = (editor: ISuggestEditor) => {
  const { detectModes } = editor;
  if (!detectModes) {
    return null;
  }
  for (const mode of editor.detectModes) {
    const maybeHit = MODE_DETECTOR_MAP[mode](editor);
    if (maybeHit) {
      return maybeHit;
    }
  }
  return null;
};

export default detectQuery;
