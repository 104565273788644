import React, { useCallback, useMemo } from "react";
import {
  ReactEditor,
  useFocused,
  useSelected,
  useSlateStatic,
} from "slate-react";
import { ColumnLayoutEntry } from "./types";
import Split from "react-split";
import { RenderTypedElementProps } from "../../TypedElement";
import { useLevel } from "../../Base/useLevel";
import classNames from "classnames";
import { EntityContextWrapper } from "../Entities/ContextWrapper";
import { Editor, Range } from "slate";
import SelectHandle from "./SelectHandle";
import { useColumnSplitSizes } from "@/components/legacy/ColumnSplitSize";

export interface RenderColumnEntryProps extends RenderTypedElementProps {
  element: ColumnLayoutEntry;
}

export const RenderColumnEntry = ({
  attributes,
  element,
  children,
}: RenderColumnEntryProps) => {
  const editor = useSlateStatic() as ReactEditor;
  const { selection } = editor;
  const selected = useSelected();
  const focused = useFocused();
  const shouldHighlight = useMemo(() => {
    const path = ReactEditor.findPath(editor, element);
    const range = Editor.range(editor, path);
    const intersection = selection && Range.intersection(range, selection);
    return (
      selected && focused && intersection && Range.equals(intersection, range)
    );
  }, [element, editor, selected, focused, selection]);
  const childrenArray = React.Children.toArray(children);
  const [sizes, setSizes] = useColumnSplitSizes();
  const level = useLevel(element);
  const state = element.children[0].state ?? "present";
  const leftColumn = childrenArray[0];
  const rightColumns = childrenArray.slice(1);
  const gutter = useCallback((index, direction) => {
    const gutter = document.createElement("div");
    gutter.className = `gutter gutter-${direction}`;
    gutter.contentEditable = "false";
    return gutter;
  }, []);
  const handleDrag = useCallback(
    (sizes: [number, number]) => setSizes(sizes),
    [setSizes]
  );

  return (
    <EntityContextWrapper.Provider value={state}>
      <div
        className={classNames(
          "current-entry flex",
          { "-ml-6 -mr-8": level === 1 },
          { "rounded-sm ring ring-blue-200": shouldHighlight }
        )}
        {...attributes}
      >
        <div className="relative -left-2 flex items-start">
          {level === 1 && <SelectHandle element={element} />}
        </div>
        <Split
          className="contents space-x-px"
          sizes={sizes}
          minSize={100}
          expandToMin
          cursor="col-resize"
          gutterSize={4}
          gutter={gutter}
          // make sure slate doesn't start searching for the cursor position when clicking/focusing the gutter
          onDrag={handleDrag}
        >
          <div className="flex items-start">{leftColumn}</div>
          <div className="grow">
            <div
              className={classNames(
                { hidden: state === "absent" },
                "space-y-1"
              )}
            >
              {rightColumns}
            </div>
            {state == "absent" && (
              <div
                className={classNames(
                  "invisible relative flex select-none px-px current-block-focus-within:visible current-block-hover:visible"
                )}
                contentEditable={false}
              >
                <span className="px-2 text-xs font-normal italic">
                  {childrenArray.length - 1} rijen ingeklapt
                </span>
              </div>
            )}
          </div>
        </Split>
      </div>
    </EntityContextWrapper.Provider>
  );
};
