import { CSS } from "@dnd-kit/utilities";
import {
  SortableContext,
  sortableKeyboardCoordinates,
  useSortable,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { XCircleIcon, PlusIcon } from "@heroicons/react/24/solid";
import Tippy from "@tippyjs/react";
import classNames from "classnames";
import { ICoding } from "@/data-models/value-models";
import React, { HTMLAttributes } from "react";
import CodingCard from "../CodingCard";
import {
  closestCenter,
  DndContext,
  DragEndEvent,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";

type CodingCardListProps = {
  codes: ICoding[];
  minimal?: boolean;
  onCodeRemove?: (index: number) => void;
  onCodesChange?: React.Dispatch<React.SetStateAction<ICoding[]>>;
  children?: (code: ICoding) => React.ReactNode;
};
function CodingCardList({
  codes,
  onCodesChange,
  onCodeRemove,
  minimal,
  children,
}: CodingCardListProps) {
  return (
    <ul>
      {codes.map((code, index) =>
        children ? (
          children(code)
        ) : (
          <CodingCardLI
            minimal={minimal}
            key={`${code.system}-${code.code}`}
            onRemove={() => onCodeRemove?.(index)}
          >
            <CodingCard {...code} minimal={minimal} />
          </CodingCardLI>
        ),
      )}
    </ul>
  );
}

export default CodingCardList;

export function SortableCodingCardList({
  codes,
  onCodesChange,
  minimal,
  children,
}: CodingCardListProps) {
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );
  const codeIds = codes.map((code) => `${code.system}-${code.code}`);
  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;
    if (over && active.id !== over?.id) {
      onCodesChange?.((codes) => {
        const oldIndex = codeIds.indexOf(active.id as string);
        const newIndex = codeIds.indexOf(over.id as string);
        const reorderedCodes = [...codes];
        reorderedCodes.splice(
          newIndex,
          0,
          reorderedCodes.splice(oldIndex, 1)[0],
        );
        return reorderedCodes;
      });
    }
  };
  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragEnd={handleDragEnd}
    >
      <SortableContext items={codeIds} strategy={verticalListSortingStrategy}>
        <ul className="relative">
          {codes.map((code) =>
            children ? (
              children(code)
            ) : (
              <SortableCodingCardLI
                minimal={minimal}
                key={`${code.system}-${code.code}`}
                id={`${code.system}-${code.code}`}
              >
                <CodingCard {...code} minimal={minimal} />
              </SortableCodingCardLI>
            ),
          )}
        </ul>
      </SortableContext>
    </DndContext>
  );
}

type CodingCardLIProps = {
  className?: string;
  style?: React.CSSProperties;
  onRemove?: () => void;
  minimal?: boolean;
};
function CodingCardLI_(
  {
    minimal,
    onRemove,
    className,
    style,
    children,
  }: React.PropsWithChildren<CodingCardLIProps>,
  ref?: React.Ref<HTMLLIElement>,
) {
  return (
    <li
      ref={ref}
      style={style}
      className={classNames(
        "group relative list-none",
        {
          "px-1.5 py-0.5 first:pt-1.5": !minimal,
          "p-0": minimal,
        },
        className,
      )}
    >
      {children}
      <Tippy content="Verwijder code">
        <button
          type="button"
          onClick={onRemove}
          aria-details="Remove code"
          className="absolute right-0 top-0 flex cursor-pointer items-center justify-center"
        >
          <XCircleIcon className="h-5 w-5 rounded-full border border-white bg-white text-gray-300 group-hover:text-gray-500" />
        </button>
      </Tippy>
    </li>
  );
}
export const CodingCardLI = React.forwardRef<
  HTMLLIElement,
  React.PropsWithChildren<CodingCardLIProps>
>(CodingCardLI_);

export function SortableCodingCardLI({
  id,
  minimal,
  onRemove,
  children,
}: React.PropsWithChildren<CodingCardLIProps & { id: string }>) {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id });
  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };
  return (
    <CodingCardLI
      ref={setNodeRef}
      minimal={minimal}
      onRemove={onRemove}
      className="pl-5"
      style={style}
    >
      <Tippy content="Drag to reorder">
        <button
          {...attributes}
          {...listeners}
          type="button"
          aria-details="Drag to reorder"
          className={classNames("absolute left-0 cursor-grab", {
            "top-3.5": !minimal,
            "top-1": minimal,
          })}
        >
          <span className="sr-only">Drag to reorder</span>
          <span className="material-symbols-outlined text-xl text-transparent group-hover:text-gray-400">
            drag_indicator
          </span>
        </button>
      </Tippy>
      {children}
    </CodingCardLI>
  );
}
