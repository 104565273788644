import InlineChromiumBugfix from "@/components/InlineChromiumBugFix";
import ReferenceDropdownInput from "@/components/inputs/Dropdown/ReferenceDropdown";
import useSlateInputElement from "@/editor/utils/useSlateInputElement";
import { RenderReferenceInputElementProps } from "./ReferenceInputPlugin";

function RenderDropdownInputElement({
  element,
  attributes,
  children,
}: RenderReferenceInputElementProps) {
  const { options, expression, placeholder } = element;
  const { name, update, initialValue, showProperties, ...otherProps } =
    useSlateInputElement(element);
  return (
    <span {...attributes} tabIndex={-1}>
      <InlineChromiumBugfix />
      <ReferenceDropdownInput
        name={name}
        options={options}
        expression={expression}
        placeholder={placeholder}
        {...otherProps}
      />
      {children}
      <InlineChromiumBugfix />
    </span>
  );
}

export default RenderDropdownInputElement;
