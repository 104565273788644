import { nullable, optional, pattern, string, type } from "superstruct";

export type ValueSetURL = string;

export interface IValueSet {
  title: string;
  description?: string | null;
  id: string;
  name?: string;
  url: ValueSetURL;
}

export const ValueSetURL = pattern(string(), /\S*/);

export const ValueSetModel = type({
  title: string(),
  description: optional(nullable(string())),
  id: string(),
  name: optional(string()),
  url: ValueSetURL,
});

export class TiroValueSet implements IValueSet {
  BASE_URL = "http://tiro.health/fhir/ValueSet";
  title: string;
  description: string;
  constructor(title: string, description?: string) {
    this.title = title;
    this.description = description ?? "";
  }
  get name() {
    return this.title
      .split(" ")
      .map((w, i) => (i > 0 ? w.toLocaleUpperCase() : w.toLowerCase()))
      .join("");
  }
  get id() {
    return this.title.replace(" ", "-").toLowerCase();
  }
  get url() {
    return this.BASE_URL + "/" + this.id;
  }
}
