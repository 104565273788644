import { BeakerIcon, UserIcon } from "@heroicons/react/24/outline";
import { Resource } from "@smile-cdr/fhirts/dist/FHIR-R4/classes/resource";
import { IBundle } from "@smile-cdr/fhirts/dist/FHIR-R4/interfaces/IBundle";
import Reference from "@/data-models/value-models/reference";
import { IReference } from "@/data-models/value-models/types";
import { IFHIRQueryExpression } from "@/expressions/fhir-query/compile";
import { createFHIRExpressionQuery } from "@/expressions/fhir-query/query";
import React from "react";
import {
  Control,
  Controller,
  ControllerProps,
  FieldPathByValue,
  FieldValues,
  PathValue,
  useFormContext,
} from "react-hook-form";
import { useQuery } from "react-query";
import { resourceToDisplay } from "@/util/resourceTodisplay";
import { z } from "zod";
import { createdTypedDropdown, DropdownProps } from "./Dropdown";

const ReferenceDropdown = createdTypedDropdown<Reference>();

export type ReferencePath<TFieldValues extends FieldValues> = FieldPathByValue<
  TFieldValues,
  IReference | null
>;

export interface ReferenceDropdownInputProps<
  TFieldValues extends FieldValues,
  TName extends ReferencePath<TFieldValues>
> extends Omit<ControllerProps<TFieldValues, TName>, "render" | "defaultValue">,
    Omit<
      DropdownProps<Reference>,
      | "selected"
      | "onSelectedChange"
      | "innerRef"
      | "options"
      | "defaultValue"
      | "type"
    > {
  name: TName;
  control?: Control<TFieldValues>;
  expression?: IFHIRQueryExpression;
  defaultValue?: IReference | null;
  options?: IReference[];
}

function RenderIcon({
  type,
  ...otherProps
}: { type: string } & React.SVGAttributes<SVGSVGElement>) {
  switch (type) {
    case "PractitionerRole":
    case "Practitioner":
      return <UserIcon {...otherProps} />;
    case "Observation":
      return <BeakerIcon {...otherProps} />;
    default:
      return null;
  }
}

const ResponseSchema = z.object({
  resourceType: z.literal("Bundle"),
  entry: z.array(
    z.object({
      resource: z.discriminatedUnion("resourceType", [
        z.object({
          resourceType: z.literal("PractitionerRole"),
          id: z.string(),
          practitioner: z.object({
            reference: z.string(),
            display: z.string(),
          }),
          organization: z.optional(
            z.object({
              reference: z.optional(z.string()),
              display: z.string(),
            })
          ),
        }),
        z.object({
          resourceType: z.literal("Practitioner"),
          id: z.string(),
          identifier: z.array(
            z.object({
              system: z.string(),
              value: z.string(),
            })
          ),
          name: z.array(
            z.object({
              family: z.string(),
              given: z.array(z.string()),
            })
          ),
        }),
        z.object({
          resourceType: z.literal("Observation"),
          id: z.string(),
          identifier: z.array(
            z.object({
              system: z.string(),
              value: z.string(),
            })
          ),
          code: z.object({
            coding: z.array(
              z.object({
                system: z.string(),
                code: z.string(),
                display: z.string(),
              })
            ),
          }),
        }),
      ]),
    })
  ),
});

function ReferenceDropdownInput<
  TRefType extends string,
  TFieldValues extends FieldValues = FieldValues,
  TName extends ReferencePath<TFieldValues> = ReferencePath<TFieldValues>
>({
  name,
  disabled,
  defaultValue = null,
  options: providedOptions = [],
  expression,
  ...props
}: ReferenceDropdownInputProps<TFieldValues, TName>) {
  const { trigger } = useFormContext();
  const { data: options = providedOptions.map(Reference.fromObj) } = useQuery({
    ...createFHIRExpressionQuery(expression, { schema: ResponseSchema }),
    select(data: IBundle) {
      return data.entry
        ?.map((e) => e.resource)
        .filter((r): r is Resource => !!r)
        .map(
          (r) =>
            new Reference({
              display: resourceToDisplay(r),
              reference: `${r.resourceType!}/${r.id!}`,
              identifier: (r as any).identifier?.[0],
              type: r.resourceType!,
            })
        );
    },
  });
  const isError = false;
  return (
    <Controller<TFieldValues, TName>
      name={name}
      defaultValue={defaultValue as PathValue<TFieldValues, TName>}
      render={({ field: { ref, value, onChange, onBlur } }) => (
        <ReferenceDropdown
          ref={ref}
          disabled={disabled}
          selected={value && Reference.fromObj(value)}
          onSelectedChange={(option) => {
            onChange(option);
            trigger(name);
          }}
          onBlur={onBlur}
          OptionToIcon={({ value: r }) => (
            <RenderIcon
              type={r?.type ?? ""}
              className="mr-2 h-4 w-4 text-opacity-50"
            />
          )}
          options={options}
          optionToKey={(ref) => ref.toUniqueToken()!}
          optionToDisplay={(r) => r?.display ?? "Unknown"}
          error={isError}
          {...props}
        />
      )}
    />
  );
}

export default ReferenceDropdownInput;
