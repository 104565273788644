import {
  ChevronUpDownIcon,
  ExclamationTriangleIcon,
} from "@heroicons/react/24/outline";
import { Spinner } from "@/Icons";
import React from "react";

function Button_(
  {
    loading,
    success,
    error,
    children,
    ...props
  }: React.ButtonHTMLAttributes<HTMLButtonElement> & {
    loading?: boolean;
    error?: boolean;
    success?: boolean;
  },
  ref: React.Ref<HTMLButtonElement>
) {
  return (
    <button
      ref={ref}
      {...props}
      className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none"
    >
      {loading && <Spinner className="h-5 w-5 animate-spin text-gray-400" />}
      {success && (
        <ChevronUpDownIcon
          className="h-5 w-5 text-gray-400"
          aria-hidden="true"
        />
      )}
      {error && (
        <ExclamationTriangleIcon
          className="h-5 w-5 text-red-400"
          aria-hidden="true"
        />
      )}
      {children}
    </button>
  );
}
const Button = React.forwardRef(Button_);
export default Button;
