import { Popover, Transition } from "@headlessui/react";
import classNames from "classnames";
import React, { Fragment, MouseEventHandler, Ref, useCallback } from "react";
import { Editor, Element, Node, Transforms } from "slate";
import { ReactEditor, useSlateStatic } from "slate-react";
import cloneDeep from "lodash/cloneDeep";

const MenuButton = ({
  children,
  onClick,
}: Pick<React.HTMLAttributes<HTMLButtonElement>, "onClick" | "children">) => (
  <li className="relative w-full">
    <button
      onClick={onClick}
      className="flex w-full items-center rounded-sm px-1.5 py-1 text-sm text-gray-900 hover:bg-gray-ultralight"
    >
      {children}
    </button>
  </li>
);

const RemoveMenuItem = ({
  element,
}: {
  element: Element;
  close: () => any;
}) => {
  const editor = useSlateStatic() as ReactEditor;
  const path = ReactEditor.findPath(editor, element);
  const handleRemove: MouseEventHandler = useCallback(
    (e) => {
      Transforms.removeNodes(editor, { at: path, voids: true, hanging: true });
      close();
      e.preventDefault();
    },
    [editor, path],
  );
  return (
    <MenuButton onClick={handleRemove}>
      <span
        className="material-icons mr-2 text-base font-semibold text-gray-dark"
        aria-hidden="true"
      >
        delete
      </span>
      Verwijder
    </MenuButton>
  );
};
const DuplicateMenuItem = ({
  element,
  close,
}: {
  element: Element;
  close: () => any;
}) => {
  const editor = useSlateStatic();
  const path = ReactEditor.findPath(editor, element);
  const handleDuplicate: MouseEventHandler = useCallback(
    (e) => {
      const insertPath = [...path.slice(0, 1)];
      insertPath[0] += 1;
      const rangeToBeDuplicated = Editor.range(editor, path);
      const fragmentToBeDuplicated = cloneDeep(
        Node.fragment(editor, rangeToBeDuplicated),
      );
      Transforms.insertNodes(editor, fragmentToBeDuplicated, {
        at: insertPath,
      });
      Transforms.select(editor, insertPath);
      e.preventDefault();
      close();
    },
    [editor, path, close],
  );
  return (
    <MenuButton onClick={handleDuplicate}>
      <span
        className="material-icons mr-2 text-base font-semibold text-gray-dark"
        aria-hidden="true"
      >
        content_copy
      </span>
      Dupliceer
    </MenuButton>
  );
};

interface SelectHandleProps {
  element: Element;
}
const SelectHandleWithoutRef = (
  { element }: SelectHandleProps,
  ref: Ref<HTMLButtonElement>,
) => {
  return (
    <Popover as={Fragment}>
      {({ open, close }) => (
        <div
          contentEditable={false}
          className={classNames(
            "not-prose relative flex h-full items-center text-gray-dark",
            { "!visible": open },
          )}
        >
          <Popover.Button
            ref={ref}
            tabIndex={-1}
            className="material-icons invisible cursor-pointer self-start text-lg text-gray-primary current-entry-hover:visible"
          >
            drag_indicator
          </Popover.Button>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Popover.Panel
              contentEditable={false}
              className="absolute left-4 top-6 z-30 mt-2 w-56 origin-top-right select-none divide-y divide-gray-ultralight rounded-sm bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
            >
              <ul className="my-1">
                {/*<StateSwitchMenuItem element={element} close={close} />

                <li className="relative w-full">
                  <HeadlessTippy
                    placement="top-end"
                    interactive
                    render={(args) => (
                      <div
                        {...args}
                        className="absolute left-0 z-30 mt-2 w-56 origin-top-right divide-y divide-gray-ultralight rounded-sm bg-white shadow-2xl ring-1 ring-black ring-opacity-5 focus:outline-none"
                      >
                        <ul className="my-1 px-2">
                          <MenuButton>
                            <span
                              className="material-icons mr-2 text-base font-semibold text-gray-dark"
                              aria-hidden="true"
                            >
                              text_increase
                            </span>
                            Kop 1
                          </MenuButton>
                          <MenuButton>
                            <span
                              className="material-icons mr-2 text-base font-semibold text-gray-dark"
                              aria-hidden="true"
                            >
                              text_decrease
                            </span>
                            Kop 2
                          </MenuButton>
                          <MenuButton>
                            <span
                              className="material-icons mr-2 text-base font-semibold text-gray-dark"
                              aria-hidden="true"
                            >
                              title
                            </span>
                            Normale tekst
                          </MenuButton>
                        </ul>
                      </div>
                    )}
                  >
                    <button className="flex w-full items-center rounded-sm px-1.5 py-1 text-sm text-gray-900 hover:bg-gray-ultralight">
                      <span
                        className="material-icons mr-2 text-base font-semibold text-gray-dark"
                        aria-hidden="true"
                      >
                        text_format
                      </span>
                      Tekststijl
                      <span
                        className="material-icons absolute right-0 mr-2 text-lg font-semibold"
                        aria-hidden="true"
                      >
                        arrow_right
                      </span>
                    </button>
                  </HeadlessTippy>
                </li>*/}
                <li>
                  <div className="mb-0.5 mt-2 w-full border-y border-gray-50 px-2 text-xs font-semibold uppercase tracking-wider text-gray-400">
                    Acties
                  </div>
                  <ul>
                    <RemoveMenuItem element={element} close={close} />
                    <DuplicateMenuItem element={element} close={close} />
                  </ul>
                </li>
              </ul>
            </Popover.Panel>
          </Transition>
        </div>
      )}
    </Popover>
  );
};

const SelectHandle = React.forwardRef(SelectHandleWithoutRef);
export default SelectHandle;
