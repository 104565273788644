import { ConceptModel, IConcept } from "@/data-models/concept";
import { IAbstractEntity } from "@/data-models/entity";
import produce, { Draft, immerable } from "immer";
import {
  array,
  Describe,
  literal,
  nonempty,
  nullable,
  optional,
  string,
  type,
} from "superstruct";
import { QuantityOptions } from "./Quantity";
import QuantityComponent, {
  IQuantityComponent,
  QuantityComponentModel,
} from "./QuantityComponent";
import { NUMBER_PATTERN } from "./utils";

export interface IQuantityWithComponents {
  text?: string | null;
  components: IQuantityComponent[];
  interprets?: IConcept | null;
  placeholder?: string;
  format?: string;
  separator?: string;
  type: "quantity";
}

export const QuantityWithComponentsModel: Describe<IQuantityWithComponents> =
  type({
    type: literal("quantity"),
    format: optional(string()),
    interprets: optional(nullable(ConceptModel)),
    components: nonempty(array(QuantityComponentModel)),
    separator: optional(string()),
    text: optional(nullable(string())),
  });

class QuantityWithComponents implements IAbstractEntity {
  [immerable] = true;
  readonly NULL: "" = "";
  readonly type: "quantity" = "quantity";
  interprets?: IConcept | null;
  components: QuantityComponent[];
  separator?: string;
  format?: string;
  #regex: RegExp;
  #placeholder?: string;
  text: string;
  options: QuantityOptions;
  constructor(
    obj: Omit<IQuantityWithComponents, "type"> | IQuantityWithComponents,
    options: QuantityOptions = { includeUnitInText: false }
  ) {
    this.format = obj.format ?? NUMBER_PATTERN;
    this.#regex = new RegExp(this.format, "g");
    this.separator = obj.separator;
    this.#placeholder = obj.placeholder;
    this.components = obj.components.map((c) => new QuantityComponent(c));
    this.interprets = obj.interprets;
    this.options = options;
    this.text = obj.text || this.toString(this.options.includeUnitInText);
  }
  get isValid() {
    return (
      this.components.length > 0 &&
      this.components.every((c) => !!c.quantity.value)
    );
  }
  get placeholder() {
    return this.#placeholder || `... ${this.unitAsString}`;
  }
  /**
   * Returns a string representation of an object.
   * @param unit If true, include the unit in the string. (Default: true)
   * @returns
   */
  toString(unit: boolean = true): string {
    if (!this.isValid) return "";
    const withComponentUnits = unit && !this.componentUnitsAreIdentical();
    const withGlobalUnits = unit && this.componentUnitsAreIdentical();
    let text = this.components
      .map((c) =>
        withComponentUnits
          ? `${c.quantity.value} ${c.quantity.unit}`
          : c.quantity.value.toString()
      )
      .join(` ${this.separator} `);

    if (!withGlobalUnits) return text;
    return text + ` ${this.components[0].quantity.unit}`;
  }

  get value(): string {
    return this.toString(false);
  }

  componentUnitsAreIdentical() {
    return this.components.every((current, index, array) =>
      current.unitAsCoding.equals(array[0].unitAsCoding)
    );
  }
  get unitAsString() {
    if (this.componentUnitsAreIdentical()) {
      return this.components[0].quantity.unit;
    }
    return null;
  }

  parse(text: string) {
    return produce<QuantityWithComponents, Draft<QuantityWithComponents>>(
      this,
      (draft) => {
        // parse the values and assign them to the components
        const matches = [...text.matchAll(this.#regex)];
        draft.components.forEach((component, index) => {
          const value = parseFloat(matches[index]?.groups?.value ?? "");
          component.quantity.value = isNaN(value) ? draft.NULL : value;
        });
        // regenerate the equivalent text to show to user what we've understand
        draft.text = draft.toString(draft.options.includeUnitInText);
      }
    );
  }
  reformat(text: string) {
    const parsed = this.parse(text);
    return parsed.isValid ? parsed.text : text;
  }
}
export default QuantityWithComponents;
