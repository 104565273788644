import { Dialog, Transition } from "@headlessui/react";
import { CalculatorIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { Fragment, useState } from "react";
import {
  ReactEditor,
  RenderElementProps,
  useSelected,
  useSlateStatic,
} from "slate-react";
import { Transforms } from "slate";
import { array, is, optional, string, type, validate } from "superstruct";
import { CodingModel, QuantityModel } from "@/data-models/value-models/structs";
import cloneDeep from "lodash/cloneDeep";
import { IfhirR4 } from "@smile-cdr/fhirts";
import { ICalculatorElement } from "./CalculatorElement";
import { createQuantityEntity } from "@/data-models/quantity-entity";
import ReportButton from "@/components/base/ReportButton";
import useSMARTWebMessaging from "@/hooks/useSMARTWebMessaging";
import randomString from "@/util/randomString";
import { useController } from "react-hook-form";
import useSlateInputElement from "@/editor/utils/useSlateInputElement";

const CalculatorPreview = ({
  isOpen,
  close,
  title,
  url,
  onChange: handleChange,
}: {
  title: string;
  url: string;
  isOpen: boolean;
  close: () => void;
  onChange: (obj: IfhirR4.IObservation) => void;
}) => {
  const [messagingHandle] = useState<string>(() => randomString(10));

  useSMARTWebMessaging(
    (message) => handleChange(message.payload as IfhirR4.IObservation),
    { allowedOrigin: url, messagingHandle },
  );

  const processedUrl = new URL(url);
  processedUrl.searchParams.set("messagingHandle", messagingHandle);
  processedUrl.searchParams.set("targetOrigin", window.location.origin);

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        contentEditable={false}
        className="fixed inset-0 z-10 overflow-y-auto"
        onClose={close}
      >
        <div className="relative flex min-h-min flex-col items-center justify-center px-4 text-center">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0" />
          </Transition.Child>

          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="relative my-8 flex w-full max-w-5xl flex-grow flex-col overflow-hidden rounded-2xl border border-gray-200 bg-white p-6 text-left align-middle shadow-xl transition-all">
              <div className="absolute right-4 top-4">
                <button type="button" onClick={close}>
                  <XMarkIcon className="h-6 w-6 stroke-current" />
                </button>
              </div>
              <Dialog.Title
                as="h3"
                className="text-lg font-medium leading-6 text-gray-900"
              >
                {title}
              </Dialog.Title>
              <div className="relative mt-2 flex h-[600px] flex-grow flex-col">
                <iframe
                  id="calculator"
                  className="min-h-min min-w-fit flex-grow"
                  title={title}
                  src={processedUrl.toString()}
                />
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
};
const BACKWARDS_COMPATIBLE_CONTROLLER = {
  field: { onChange: () => {} },
};
export interface RenderCalculatorElementProps extends RenderElementProps {
  element: ICalculatorElement;
}
const TEMP_NAME = randomString(10);
export const RenderCalculatorElement = ({
  element,
  attributes,
  children,
}: RenderCalculatorElementProps) => {
  const { disabled } = useSlateInputElement(element);
  const entity = element.entity && createQuantityEntity(element.entity);
  const { name = TEMP_NAME } = element;
  const selected = useSelected();
  const [isOpen, setIsOpen] = useState(false);
  const editor = useSlateStatic() as ReactEditor;
  const path = ReactEditor.findPath(editor, element);
  const controlProps = useController({ name });

  // this is a hack to prevent an error when the controller is not present
  const {
    field: { onChange },
  } = controlProps ?? BACKWARDS_COMPATIBLE_CONTROLLER;
  const text = entity?.isValid
    ? `${element.title}: ${entity.toString()}`
    : element.title;

  const handleResultChange = (data: any) => {
    const dataCopy = cloneDeep(data);
    if (
      is(
        dataCopy,
        type({
          valueQuantity: QuantityModel,
          code: type({
            text: string(),
            coding: array(CodingModel),
          }),
          text: optional(type({ div: string() })),
        }),
      )
    ) {
      Transforms.setNodes<ICalculatorElement>(
        editor,
        {
          innerHTML: dataCopy.text?.div,
          entity: {
            quantity: dataCopy.valueQuantity,
            type: "quantity",
          },
        },
        { at: path },
      );
      onChange({ code: dataCopy.code, value: dataCopy.valueQuantity });
    } else {
      console.warn(
        "Received invalid message",
        validate(
          dataCopy,
          type({
            valueQuantity: QuantityModel,
            code: type({
              text: string(),
              coding: array(CodingModel),
            }),
            text: optional(type({ div: string() })),
          }),
        ),
      );
    }
  };
  return (
    <span {...attributes} className="inline-block" contentEditable={false}>
      <ReportButton
        onClick={() => setIsOpen(true)}
        contentEditable={false}
        disabled={disabled}
        active={selected}
        Icon={<CalculatorIcon className="inline h-4 w-4" />}
      >
        <span dangerouslySetInnerHTML={{ __html: element.innerHTML ?? text }} />
      </ReportButton>
      {children}
      <CalculatorPreview
        title={element.title}
        url={element.url}
        isOpen={isOpen}
        close={() => setIsOpen(false)}
        onChange={handleResultChange}
      />
    </span>
  );
};

export default RenderCalculatorElement;
