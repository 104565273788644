import classNames from "classnames";
import TD from "@/components/forms/TopDownForm";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { EMPTY_EXTRACTION } from "./PropertiesForm";
import { FieldValues } from "./types";

export function OMOPExtractionFieldSet({ className }: { className?: string }) {
  const { t } = useTranslation();
  const { register, setValue, watch } = useFormContext<FieldValues>();

  return (
    <fieldset className={classNames("block", className)}>
      <legend>OMOP CDM</legend>
      {watch("extraction.omop") ? (
        <>
          <TD.Field
            {...register("extraction.omop.path")}
            label="Column"
            small
            type="text"
          />
          <TD.Field
            {...register("extraction.omop.context")}
            label="Table"
            small
            type="text"
          />
        </>
      ) : (
        <TD.Button
          small
          style="outline"
          onClick={() => setValue("extraction.omop", EMPTY_EXTRACTION)}
        >
          {t("add-omop-extraction")}
        </TD.Button>
      )}
    </fieldset>
  );
}
