import { InformationCircleIcon } from "@heroicons/react/24/outline";
import classNames from "classnames";
import React from "react";

export interface ReportButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  children?: React.ReactNode;
  active?: boolean;
  enabled?: boolean;
  muted?: boolean;
  iconClassName?: string;
  Icon?: React.ReactNode;
  positionIcon?: "left" | "right";
  outline?: boolean;
}
const Styles = {
  DEFAULT:
    "border border-transparent hover:bg-gray-light hover:text-gray-darker disabled:bg-gray-ultralight disabled:text-gray-light disabled:border-gray-lighter select-none",
  DEFAULT_NOT_MUTED: "bg-gray-lighter text-gray-dark",
  DEFAULT_ENABLED: "bg-blue-ultralight text-blue-dark",
  DEFAULT_MUTED: "text-gray-light bg-transparent transition-all delay-100",
  OUTLINE:
    "bg-transparent border border-gray-light hover:border-gray-primary text-gray-dark hover:text-gray-darker",
  OUTLINE_ENABLED:
    "bg-transparent border border-blue-primary text-blue-primary ",
  ACTIVE: "ring-2 ring-blue-light",
};
const INFO_ICP = <InformationCircleIcon className="inline h-4 w-4" />;
function ReportButtonWithoutRef(
  {
    active,
    enabled,
    muted,
    outline,
    children,
    className,
    iconClassName,
    Icon = INFO_ICP,
    positionIcon = "right",
    ...props
  }: ReportButtonProps,
  ref: React.Ref<HTMLButtonElement>
) {
  return (
    <button
      ref={ref}
      type="button"
      className={classNames(
        className,
        "relative mx-px box-border select-none rounded px-1.5 py-0.5 text-sm",
        {
          [Styles.DEFAULT]: !outline && !enabled,
          [Styles.DEFAULT_ENABLED]: !outline && enabled && !muted,
          [Styles.DEFAULT_NOT_MUTED]: !outline && !muted && !enabled,
          [Styles.DEFAULT_MUTED]: muted,
          [Styles.OUTLINE]: outline && !enabled,
          [Styles.OUTLINE_ENABLED]: outline && enabled,
          [Styles.ACTIVE]: active,
        }
      )}
      {...props}
    >
      {positionIcon === "left" && (
        <span className={classNames("mr-1", iconClassName)}>{Icon}</span>
      )}
      {children}
      {positionIcon === "right" && (
        <span className={classNames("ml-1", iconClassName)}>{Icon}</span>
      )}
    </button>
  );
}
const ReportButton = React.forwardRef<HTMLButtonElement, ReportButtonProps>(
  ReportButtonWithoutRef
);
export default ReportButton;
