import MedicalIcon, { MEDICAL_ICONS } from "@/icons/MedicalIcon";
import {
  array,
  coerce,
  Describe,
  enums,
  optional,
  string,
  type,
} from "superstruct";
import { BlockModel } from "./block";
import { coerceNullToUndefined } from "./utils";
export interface ITemplateMeta {
  id: string;
  title: string;
  description: string;
  icon?: MedicalIcon;
  color?: "gray" | "blue";
  pathway?: string[];
  version?: string;
  status?: "draft" | "active" | "retired";
}

export const TemplateMetaModel: Describe<ITemplateMeta> = type({
  id: string(),
  title: string(),
  description: string(),
  icon: coerceNullToUndefined(optional(enums(MEDICAL_ICONS))),
  color: optional(enums(["gray", "blue"])),
  pathway: coerceNullToUndefined(
    optional(coerce(array(string()), string(), (t) => [t])),
  ),
});
export const TemplateTypeModel = type({
  ...TemplateMetaModel.schema,
  blocks: array(BlockModel),
});
