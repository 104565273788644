import { User } from "firebase/auth";
import { usePostHog } from "posthog-js/react";
import { useEffect } from "react";
import { getTenantAuth } from "@/services/tenant";

const InitPostHog = () => {
  const posthog = usePostHog();
  // subscribe to the user's auth state
  useEffect(() => {
    // initialize posthog and opt out of capturing if not in demo or staging
    try {
      if (!posthog) {
        console.warn("PostHog not initialized");
        return;
      }
    } catch (e) {
      console.warn("Failed to initialize PostHog", e);
    }
    // function identify the user in postHog
    const identify = (user: User | null) => {
      try {
        if (user) {
          posthog.identify(user.uid, { email: user.email }, {});

          console.debug(
            "Identifying user in PostHog",
            posthog.get_distinct_id()
          );
        } else {
          console.debug("Resetting PostHog user");
          posthog.reset();
        }
      } catch (e) {
        console.warn("Failed to identify user in PostHog", e);
      }
    };
    // identify the user if already logged in
    const user = getTenantAuth().currentUser;
    if (user) identify(user);
    // subscribe to auth state changes and re-identity
    const unsubscribe = getTenantAuth().onAuthStateChanged(identify);
    posthog.capture("Successfully initialized PostHog", posthog.config);

    return unsubscribe;
  }, [posthog]);
  return <></>;
};

export default InitPostHog;
