import classNames from "classnames";
import React from "react";

function Panel({ children, className }: React.HTMLAttributes<HTMLDivElement>) {
  return (
    <div
      className={classNames(
        "bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10",
        className
      )}
    >
      {children}
    </div>
  );
}

export default Panel;
