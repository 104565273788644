/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { Address } from './Address';
import type { Attachment_Input } from './Attachment_Input';
import type { CodeableConcept } from './CodeableConcept';
import type { ContactPoint } from './ContactPoint';
import type { Meta } from './Meta';
import type { models__api__fhir_r5__base__Identifier_Input } from './models__api__fhir_r5__base__Identifier_Input';
import type { Narrative } from './Narrative';
import type { PatientCommunication_Input } from './PatientCommunication_Input';
import type { PatientContact_Input } from './PatientContact_Input';
import type { PatientLink_Input } from './PatientLink_Input';
import type { PatientName } from './PatientName';
import type { Reference_Input } from './Reference_Input';

export type FHIRPatientIn = {
    resourceType?: FHIRPatientIn.resourceType;
    id?: (string | null);
    meta?: (Meta | null);
    text?: (Narrative | null);
    name: Array<PatientName>;
    link?: Array<PatientLink_Input>;
    photo?: (Array<Attachment_Input> | null);
    active?: (boolean | null);
    gender?: (string | null);
    telecom?: (Array<ContactPoint> | null);
    address?: (Array<Address> | null);
    contact?: (Array<PatientContact_Input> | null);
    language?: (string | null);
    birthDate?: (string | null);
    identifier?: Array<models__api__fhir_r5__base__Identifier_Input>;
    deceasedBoolean?: (boolean | null);
    deceasedDateTime?: (string | null);
    implicitRules?: (string | null);
    maritalStatus?: (CodeableConcept | null);
    communication?: (Array<PatientCommunication_Input> | null);
    multipleBirthBoolean?: (boolean | null);
    multipleBirthInteger?: (number | null);
    generalPractitioner?: (Array<Reference_Input> | null);
    managingOrganization?: (Reference_Input | null);
};

export namespace FHIRPatientIn {

    export enum resourceType {
        PATIENT = 'Patient',
    }


}

