import SubmissionStatusPanel from "@/components/SubmissionStatusPanel/SubmissionStatusPanel";
import { useQueryClient } from "react-query";
import { Link } from "react-router-dom";
import { useObservable } from "@/submit-actions/Observable";
import useLaunchedFrom from "@/hooks/useLaunchedFrom";
import { PageContainer } from "@/components/layout/PageContainer";
import {
  ActionSequencePreset,
  useActionSequencePreset,
} from "@/submit-actions/sequence";
import useCurrentReportId from "@/hooks/useCurrentReportId";
import useCurrentSubjectId from "@/hooks/useCurrentSubjectId";
import {
  DEFAULT_FHIR_ENDPOINT,
  FHIREndpoint,
  FHIREndpointContext,
} from "@/hooks/useFHIREndpoint";
import { Action, Instruction } from "@/submit-actions/Action";

function isInterSystemsDemo(endpoint: FHIREndpoint) {
  return (
    endpoint.baseURL.startsWith("https://ec2") ||
    endpoint.baseURL.startsWith("https://www.sof-intersystems.be")
  );
}

function ReportSubmitPage() {
  const isKWS = useLaunchedFrom("KWS");
  const isSynops = useLaunchedFrom("Synops");
  const maybeEndpoint = sessionStorage.getItem("fhirEndpoint");
  const endpoint: FHIREndpoint = maybeEndpoint
    ? JSON.parse(maybeEndpoint)
    : DEFAULT_FHIR_ENDPOINT;
  const preset: ActionSequencePreset = isKWS
    ? "KWS"
    : isSynops
      ? "SYNOPS"
      : isInterSystemsDemo(endpoint)
        ? "SMART-ON-FHIR"
        : "STANDALONE";

  console.debug("Using preset", preset, "with endpoint", endpoint, "from");

  return (
    <PageContainer className="h-full w-full bg-fill-secondary">
      <main className="mx-auto mt-10 max-w-2xl py-6 sm:px-6 lg:px-8">
        <FHIREndpointContext.Provider value={endpoint}>
          <Panel preset={preset} />
        </FHIREndpointContext.Provider>
      </main>
    </PageContainer>
  );
}

export default ReportSubmitPage;

function RenderItem({ item }: { item: Action | Instruction }) {
  const { status, renderMessage } = useObservable(item);
  if (status == "HIDDEN") return null;
  if (item instanceof Action)
    return (
      <SubmissionStatusPanel.Task status={status} retry={item.retry}>
        {renderMessage()}
      </SubmissionStatusPanel.Task>
    );
  if (item instanceof Instruction)
    return (
      <SubmissionStatusPanel.Instruction>
        {renderMessage()}
      </SubmissionStatusPanel.Instruction>
    );
  return null;
}

function Panel({ preset }: { preset: ActionSequencePreset }) {
  const queryClient = useQueryClient();
  const reportId = useCurrentReportId();
  const patientId = useCurrentSubjectId();
  const items = useActionSequencePreset(preset, {
    reportId,
    patientId,
    queryClient,
  });
  return (
    <SubmissionStatusPanel
      title="Bezig met indienen van verslag"
      description="De volgende stappen worden uitgevoerd:"
      footer={
        <p className="text-sm text-gray-500">
          Indien u terug wilt naar het gestructureerd verslag{" "}
          <Link className="text-blue-600 underline" to="../edit">
            klik hier
          </Link>
          . Anders mag u dit venster sluiten.
        </p>
      }
    >
      {items.map((item, index) => (
        <RenderItem key={index} item={item} />
      ))}
    </SubmissionStatusPanel>
  );
}
