import { useCallback } from "react";
import {
  FieldPath,
  FieldValues,
  PathValue,
  RegisterOptions,
  useFormContext,
  UseFormRegisterReturn,
} from "react-hook-form";

type Register<
  TFieldValues extends FieldValues,
  TFieldName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> = (
  name: TFieldName,
  options?: RegisterOptions<TFieldValues, TFieldName>
) => UseFormRegisterReturn<TFieldName>;

function useReformatter<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>(
  register: Register<TFieldValues, TName>,
  reformatter?: (
    value: PathValue<TFieldValues, TName>
  ) => PathValue<TFieldValues, TName>
) {
  const { setValue, getValues } = useFormContext<TFieldValues>();
  return useCallback<Register<TFieldValues, TName>>(
    (name, options) =>
      register(name, {
        onBlur: reformatter
          ? (event) => {
              const unformatted = getValues(name);
              const formatted = reformatter(unformatted);
              setValue(name, formatted);
              options?.onBlur && options.onBlur(event);
            }
          : options?.onBlur,
        ...options,
      }),
    [register, reformatter, getValues, setValue]
  );
}
export default useReformatter;
