import { ALL_PATIENTS_PATH } from "@/pages/patients/const";
import { LoaderFunction, redirect } from "react-router";
import { getTenantAuth } from "@/services/tenant";

export default (): LoaderFunction => async () => {
  const { currentUser } = getTenantAuth() ?? {};
  // this page is not for authenticated users
  if (currentUser) return redirect(ALL_PATIENTS_PATH);
  return null;
};
