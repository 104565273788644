import { useMemo } from "react";
import { Node } from "slate";
import { ReactEditor, useSlateStatic } from "slate-react";

export const useLevel = (node: Node) => {
  const editor = useSlateStatic() as ReactEditor;
  return useMemo(
    () => ReactEditor.findPath(editor, node).length,
    [node, editor]
  );
};
