import React from "react";
import {
  FieldPath,
  FieldPathValue,
  FieldValues,
  PathValue,
  useFormContext,
  Validate,
  ValidationRule,
} from "react-hook-form";
import { is, pattern, string } from "superstruct";
import Badge from "../Badge/Badge";
import StyledInput from "../Badge/StyledInput";
import useReformatter from "../../../hooks/useReformatter";

export interface NumericalInputProps<
  TFieldValues extends FieldValues = FieldValues,
  TFieldName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> {
  name: TFieldName;
  disabled?: boolean;
  placeholder?: string;
  reformatter?: (
    value: PathValue<TFieldValues, TFieldName>
  ) => PathValue<TFieldValues, TFieldName>;
  autoFocus?: boolean;
  defaultMode?: "edit" | "view";
  suffix?: React.ReactChild;
  prefix?: React.ReactChild;
  validate?:
    | Validate<FieldPathValue<TFieldValues, TFieldName>, TFieldValues>
    | Record<
        string,
        Validate<FieldPathValue<TFieldValues, TFieldName>, TFieldValues>
      >;
  pattern?: ValidationRule<RegExp>;
  min?: ValidationRule<string | number>;
  max?: ValidationRule<string | number>;
  required?: boolean;
  style?: "table" | "tile" | "default";
}

const NUMERICAL_VALIDATOR = (value: unknown) =>
  is(value, pattern(string(), /^[1-9]*([0-9].[0-9]+)?$/)) ||
  "gelieve een getal in te vullen";

export default function NumericalInput<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
  name,
  suffix,
  prefix,
  validate = NUMERICAL_VALIDATOR,
  placeholder,
  reformatter,
  style = "default",
  defaultMode,
  autoFocus: defaultAutoFocus = false,
  ...props
}: NumericalInputProps<TFieldValues, TName>) {
  const { register, formState, getFieldState, getValues } =
    useFormContext<TFieldValues>();

  // equipes the blur event handler with a reformat operation
  const registerWithReformat = useReformatter(register, reformatter);

  return (
    <Badge
      name={name}
      style={style}
      defaultMode={defaultMode}
      defaultAutoFocus={defaultAutoFocus}
    >
      <Badge.Editor prefix={prefix} suffix={suffix}>
        {({ autoFocus }) => (
          <StyledInput
            type="text"
            autoFocus={autoFocus}
            invalid={!!getFieldState(name, formState).error}
            {...registerWithReformat(name, {
              validate,
              ...props,
            })}
            size={10}
            placeholder={placeholder}
          />
        )}
      </Badge.Editor>
      <Badge.Viewer prefix={prefix} suffix={suffix}>
        {() => getValues(name)}
      </Badge.Viewer>
    </Badge>
  );
}
