import { Spinner } from "@/Icons";
import classNames from "classnames";
import { ButtonHTMLAttributes } from "react";

export default function SignInButton({
  children,
  loading,
  ...attrs
}: ButtonHTMLAttributes<HTMLButtonElement> & { loading?: boolean }) {
  return (
    <button
      {...attrs}
      disabled={loading || attrs.disabled}
      className={classNames(
        "inline-flex w-full items-center justify-center gap-2 rounded-md border border-gray-light  px-4 py-2 text-sm font-medium text-gray-primary shadow-sm",
        {
          "bg-white  hover:bg-gray-50 disabled:cursor-not-allowed disabled:border-gray-300 disabled:bg-gray-200 disabled:text-gray-400":
            !loading,
          "bg-gray-50 disabled:cursor-wait": loading,
        },
      )}
    >
      {children}
      {loading && (
        <Spinner className="h-5 w-5 animate-spin text-gray-primary" />
      )}
    </button>
  );
}
