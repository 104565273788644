import Tippy from "@tippyjs/react";
import classNames from "classnames";
import React from "react";

interface DragHandleProps
  extends Omit<React.HTMLProps<HTMLButtonElement>, "type" | "children"> {
  visible?: true | false | "on-hover";
  isDragging?: boolean;
}

function DragHandleWithoutRef(
  {
    isDragging,
    disabled,
    visible,
    tabIndex = -1,
    className,
    ...attributes
  }: DragHandleProps,
  ref?: React.Ref<HTMLButtonElement>,
) {
  return (
    <Tippy content="Verplaats deze vraag" delay={[300, 50]}>
      <button
        ref={ref}
        type="button"
        disabled={visible == false || disabled}
        tabIndex={tabIndex}
        className={classNames(
          "h-4 w-4 ",
          {
            "text-gray-light group-hover:hover:text-gray-primary":
              visible == true,
          },
          { "text-transparent": visible == false },
          { "group-hover:hover:text-gray-primary": visible == "on-hover" },
          {
            "cursor-grab": !isDragging,
            "cursor-grabbing": isDragging,
          },
          className,
        )}
        {...attributes}
      >
        <span className="material-icons flex h-4 w-4 items-center text-xl !leading-5">
          drag_indicator
        </span>
      </button>
    </Tippy>
  );
}
const DragHandle = React.forwardRef(DragHandleWithoutRef);
export default DragHandle;
