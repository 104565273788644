import { Editor } from "slate";
import { DEFAULT_MODES, SuggestionDetectMode } from "../detectQuery";
import insertConceptSuggestionInDocument from "./insertConceptSuggestion";
import SuggestEditor, { QueryChangeEventHandler } from "./SuggestEditor";
import { ISuggestEditor } from "./types";

type Unsubscribe = () => void;

export interface WithSuggestionsOptions {
  detectModes?: SuggestionDetectMode[];
  mute?: boolean;
}

function withSuggestions<T extends Editor>(
  editor: T,
  options: WithSuggestionsOptions = {},
): T & ISuggestEditor {
  const { detectModes, mute } = options;

  /**
   *  Assign context and detectModes as member variables
   */
  Object.assign(editor, { detectModes: detectModes ?? DEFAULT_MODES });
  Object.assign(editor, { mute });
  Object.assign(editor, { query: null, suggestQuery: null });

  const { onChange } = editor;

  const suggestEditor = editor as any as ISuggestEditor & T;

  // We follow a pub-sub pattern to notify all React components that wan't an update when the query changes
  // this also used in context of 'external stores' https://blog.saeloun.com/2021/12/30/react-18-usesyncexternalstore-api
  const listeners = new Set<QueryChangeEventHandler>(); // notice that this is closure variable. https://www.w3schools.com/js/js_function_closures.asp

  /**
   * Register callback functions that need to be invoked when the query in the editor has changed.
   * This function returns a method that unsubscribes the callback
   */
  suggestEditor.subscribeQuery = (callback): Unsubscribe => {
    listeners.add(callback);
    return () => {
      listeners.delete(callback);
    };
  };

  /**
   * Convert suggestion into an EntityElement and insert it into the editor tree
   */
  suggestEditor.insertSuggestion = (query, position, entity) =>
    insertConceptSuggestionInDocument(suggestEditor, query, position, entity);

  /**
   * Calculate the new query given the new state of the editor
   * Invoke callbacks to the QueryChange event listeners when the query changes
   */
  suggestEditor.onChange = () => {
    /**
     * check if the query has changed, if not trigger all the event listeners
     */

    const newQuery = SuggestEditor.detectQuery(suggestEditor);

    const { query: oldQuery } = suggestEditor;

    // both queries can be null or a Query object
    if (newQuery === null && oldQuery === null) return onChange();
    if (newQuery && oldQuery && oldQuery.equals(newQuery)) return onChange();
    /**
     * First set the new query before calling `onChange`. This makes sure the state of the editor
     * is consistent before rerendering the component tree with `onChange`.
     * In that way components can rely on the value of `editor.query` during rerendering.
     * ex. think of query highlighting decorators as an example
     */
    suggestEditor.lastQuery = suggestEditor.query;
    suggestEditor.query = newQuery;
    onChange(); // this will make sure the editor rerenders with latest state

    // iterate all event handlers and call them
    listeners.forEach((callback) => callback(suggestEditor.query));
    return;
  };
  return suggestEditor;
}

export default withSuggestions;
