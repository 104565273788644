/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { Period } from './Period';

export type PractitionerEmail = {
    id?: (string | null);
    use?: (string | null);
    rank?: (number | null);
    value: string;
    system?: PractitionerEmail.system;
    period?: (Period | null);
};

export namespace PractitionerEmail {

    export enum system {
        EMAIL = 'email',
    }


}

