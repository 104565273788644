import { Menu } from "@headlessui/react";
import { Cog6ToothIcon } from "@heroicons/react/24/outline";
import classNames from "classnames";
import React, { ButtonHTMLAttributes } from "react";

export interface BlockMenuButtonProps {}
function BlockMenuButton_(
  { className, ...props }: ButtonHTMLAttributes<HTMLButtonElement>,
  ref?: React.Ref<HTMLButtonElement>,
) {
  return (
    <Menu.Button
      ref={ref}
      className={classNames(
        "flex h-5 w-5 items-center justify-center text-gray-light hover:text-gray-primary",
        className,
      )}
      {...props}
    >
      <Cog6ToothIcon className={classNames("inline-block ")} />
    </Menu.Button>
  );
}

const BlockMenuButton = React.forwardRef(BlockMenuButton_);

export default BlockMenuButton;
