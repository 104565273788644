import classNames from "classnames";
import { ICoding } from "@/data-models/value-models";
import { HTMLAttributes, useState } from "react";
import handleBlur from "@/util/handleBlur";
import RenderCode from "./RenderCode";
import RenderSystem from "./RenderSystem";
import { DesignationModel, IDesignation } from "./models";
import { is } from "superstruct";
import { Spinner } from "@/Icons";
import SemanticAxis, { AddSemanticAxis } from "./SemanticAxis";
import { PlusIcon } from "@heroicons/react/24/solid";
import Card from "./Card";

type RenderCodingCardProps = {
  system: string;
  code: string;
  display: string;
  className?: string;
  onDesignationChange?: (designation: IDesignation) => void;
  onReset?: () => void;
  small?: boolean;
  minimal?: boolean;
  active?: boolean;
  selected?: boolean;
  designation?: IDesignation;
  semantic?: ICoding;
  generated?: boolean;
  onSemanticChange?: (semantic: ICoding) => void;
  isLoading?: boolean;
  defaultMode?: "view" | "edit";
  showDesignationMeta?: boolean;
};
function RenderCodingCard({
  system,
  code,
  display,
  designation,
  onDesignationChange,
  onSemanticChange,
  small,
  minimal,
  semantic,
  generated,
  active,
  isLoading,
  defaultMode,
  className,
  showDesignationMeta = false,
}: RenderCodingCardProps) {
  return (
    <Card small={small} minimal={minimal} active={active} className={className}>
      <Card.Header>
        <Designation
          className="grow overflow-x-clip"
          designation={designation}
          onDesignationChange={onDesignationChange}
          display={display}
          defaultMode={defaultMode}
          active={active}
          small={small}
          minimal={minimal}
        />
        {isLoading ? (
          <div>
            <Spinner className="inline h-5 w-5 animate-spin text-gray-400" />
          </div>
        ) : generated ? (
          <span
            className={classNames("ml-4 inline-flex items-center italic", {
              "text-gray-500": minimal && !active,
              "text-blue-100": minimal && active,
              "text-sm": !small,
              "text-xs": small,
            })}
          >
            <PlusIcon className="my-0 mr-1 inline h-4 w-4 py-0" />
            <span>Voeg nieuwe term/uitdrukking toe</span>
          </span>
        ) : semantic ? (
          <div>
            <SemanticAxis
              {...semantic}
              minimal={minimal}
              small={small}
              active={active}
            />
          </div>
        ) : onSemanticChange ? (
          <AddSemanticAxis onAdd={onSemanticChange} />
        ) : null}
      </Card.Header>
      {!minimal && (
        <Card.BodyRow>
          <div>
            <RenderSystem
              url={system}
              className="truncate whitespace-nowrap font-semibold"
            />
          </div>
          <div>
            <RenderCode code={code} />
          </div>
          <div className="shrink truncate">{display}</div>
          <div>(standard)</div>
        </Card.BodyRow>
      )}
    </Card>
  );
}

export default RenderCodingCard;

type DesignationProps = HTMLAttributes<HTMLDivElement> & {
  designation?: IDesignation;
  display: string;
  small?: boolean;
  minimal?: boolean;
  active?: boolean;
  defaultMode?: "view" | "edit";
  showDesignationMeta?: boolean;
  onDesignationChange?: (designation: IDesignation) => void;
};

function Designation({
  children,
  className,
  display,
  designation,
  defaultMode = "view",
  small,
  minimal,
  active,
  onDesignationChange,
  showDesignationMeta,
  ...props
}: DesignationProps) {
  const [isEditing, setIsEditing] = useState(defaultMode == "edit");

  return isEditing ? (
    <div className={className} {...props}>
      <form
        onReset={() => setIsEditing(false)}
        onSubmit={(e) => {
          e.preventDefault();
          const formData = new FormData(e.currentTarget);
          const data = Object.fromEntries(formData.entries());
          if (!is(data, DesignationModel)) {
            console.debug("Invalid designation");
            e.currentTarget.reset();
            return;
          }

          onDesignationChange?.(data);
          setIsEditing(false);
        }}
        onBlur={handleBlur({
          onFocusNull: (e) => e?.currentTarget.reset(),
          onFocusOutside: (e) => e?.currentTarget.reset(),
        })}
      >
        <textarea
          name="value"
          autoFocus
          rows={1}
          className={classNames(
            "w-full rounded border-gray-100 p-0 ring-1 ring-inset ring-blue-200 focus:outline-none",
            { "text-sm": small, "text-lg": !small },
            "bg-white text-gray-700 dark:text-gray-400",
          )}
          //type="text"
          defaultValue={designation?.value ?? display}
        />
        {!minimal && (
          <div className="mt-1 flex items-baseline gap-x-1">
            <select
              name="language"
              className="rounded border border-gray-100 py-0.5 pl-px pr-3 text-xs font-medium uppercase text-gray-400"
              defaultValue={designation?.language ?? "nl"}
            >
              <option value="nl">nl</option>
              <option value="en">en</option>
              <option value="fr">fr</option>
            </select>
            <input
              name="use[display]"
              type="text"
              className="rounded border border-gray-100 px-px py-0.5 text-xs text-gray-400"
              defaultValue={designation?.use?.display}
            />
          </div>
        )}
        <button type="submit" className="sr-only">
          Save
        </button>
      </form>
    </div>
  ) : (
    <div className={className} {...props}>
      {onDesignationChange ? (
        <button
          type="button"
          onClick={() => setIsEditing(true)}
          className="flex items-baseline rounded border border-transparent hover:bg-gray-50 hover:disabled:bg-transparent"
        >
          <h6
            title={designation?.value ?? display}
            className={classNames(
              "shrink truncate whitespace-nowrap align-baseline",
              { "text-sm": small, "text-lg": !small },
              {
                " text-gray-700 dark:text-gray-400": !active,
                "text-blue-50": active && minimal,
                "text-blue-800": active && !minimal,
              },
            )}
          >
            {designation?.value ?? display}
          </h6>
          <div
            className={classNames(
              "material-symbols-outlined ml-1 translate-y-0.5 align-text-bottom text-sm text-gray-400",
            )}
          >
            edit
          </div>
        </button>
      ) : (
        <h6
          title={designation?.value ?? display}
          className={classNames(
            "shrink truncate whitespace-nowrap align-baseline",
            { "text-sm": small, "text-lg": !small },
            {
              " text-gray-700 dark:text-gray-400": !active,
              "text-blue-50": active && minimal,
              "text-blue-800": active && !minimal,
            },
          )}
        >
          {designation?.value ?? display}
        </h6>
      )}
      {designation && !minimal && showDesignationMeta && (
        <div className="leading-none">
          <span className="text-xs font-medium uppercase text-gray-400">
            {designation.language}
          </span>
          <span className="mx-1 text-xs text-gray-400">&middot;</span>
          <span className="text-xs text-gray-400">
            {designation.use?.display}
          </span>
        </div>
      )}
      {children}
    </div>
  );
}

function ThumbsUpDown() {
  return (
    <div className="flex gap-x-0.5">
      <button
        type="button"
        className="flex h-5 w-5 items-center justify-center rounded-full p-0.5 hover:bg-gray-200 dark:hover:bg-gray-800"
      >
        <span className="material-symbols-outlined text-sm text-gray-dark dark:text-gray-600">
          thumb_up
        </span>
        <span className="sr-only">Thumbs up</span>
      </button>
      <button
        type="button"
        className="flex h-5 w-5  items-center justify-center rounded-full p-0.5 hover:bg-gray-200 dark:hover:bg-gray-800"
      >
        <span className="material-symbols-outlined text-sm text-gray-dark dark:text-gray-600">
          thumb_down
        </span>
        <span className="sr-only">Thumbs down</span>
      </button>
    </div>
  );
}
