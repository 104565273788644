import { useMemo } from "react";
import { WithBlockLayoutOptions } from "@/editor/legacy/BlockLayoutPlugin/types";
import { constructEditor } from "./constructEditor";

const useRichTextBlockEditor = (options?: WithBlockLayoutOptions) => {
  const { renderType = "qa-rich-text" } = options ?? {};
  /**
   * The empty dependency array is here on purpose.
   * This makes the editor stable. Updates to `options` should be passed through the reinitializeEditor() function
   */
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const editor = useMemo(() => constructEditor({ renderType }), [renderType]);
  return [editor, editor.plugins] as const;
};

export default useRichTextBlockEditor;
