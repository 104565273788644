import React, { useCallback, useEffect, useReducer } from "react";
import { ActionTypes, IQuestionItem, QAAction } from "../models";
import { IContent as IContent, ILegacyTextAnswer } from "../Answer/models";
import { Coding } from "@/data-models/value-models";
import { array, is } from "superstruct";
import { FieldValues } from "react-hook-form";
import isEnabled from "./isEnabled";
import { findAllCodesInAnswer, findAllCodesInValues } from "./findCodes";

function isLegacyAnswer(
  answer: IContent | undefined
): answer is ILegacyTextAnswer {
  if (!answer) return false;
  if (!is(answer, array()) || answer.length === 0) return false;
  // @ts-ignore
  return answer.every((n) => "children" in n);
}

function collectCodesFromParent(parentQuestion: IQuestionItem) {
  const allParentAnswerCodes: Coding[] = [];
  if (parentQuestion.answer && isLegacyAnswer(parentQuestion.answer))
    allParentAnswerCodes.push(...findAllCodesInAnswer(parentQuestion.answer));
  if (parentQuestion.values) {
    const codes = findAllCodesInValues(parentQuestion.values);
    allParentAnswerCodes.push(...codes);
  }
  return allParentAnswerCodes;
}

interface UseQuestionProps {
  indexPath?: number[];
  path: string[];
  question: IQuestionItem;
  parent?: IQuestionItem;
  dispatch?: React.Dispatch<QAAction>;
}
type UseQuestionResult = readonly [
  {
    indent: number;
    isMinimizable: boolean;
    enabled: boolean;
    required?: boolean;
    hasSubQuestions: boolean;
    isCollapsed: boolean;
    showAnswer: boolean;
    subQuestions: IQuestionItem[];
    values?: FieldValues;
    answer: ILegacyTextAnswer | undefined;
    code: IQuestionItem["code"];
    mode?: "content-editable" | "data-entry";
  },
  {
    remove: () => void;
    updateQuestion: (question: Partial<IQuestionItem>) => void;
    updateContent: (answer: IContent) => void;
    updateValues: (values: FieldValues) => void;
    toggle: () => void;
  }
];

function useQuestion({
  question: currentQuestion,
  parent: parentQuestion,
  path,
  indexPath,
  dispatch,
}: UseQuestionProps): UseQuestionResult {
  const hasSubQuestions =
    "questions" in currentQuestion && !!currentQuestion.questions?.length;

  //const isTopLevel = path.length == 1;
  const isMinimizable = hasSubQuestions;
  const {
    answer,
    values,
    enabledWhen,
    questions: subQuestions = [],
    code,
    mode,
    required,
  } = currentQuestion;

  // Collect all codes from parent answer and values
  const parentCodes: Coding[] = parentQuestion
    ? collectCodesFromParent(parentQuestion)
    : [];

  const enabled = isEnabled(enabledWhen, parentCodes);

  useEffect(() => {
    if (!enabled) {
      dispatch &&
        indexPath &&
        dispatch({ type: ActionTypes.CLEAR_VALUES, path: indexPath });
    }
  }, [enabled, dispatch, indexPath]);

  const [isCollapsed, toggle] = useReducer((v) => !v, false);
  const showAnswer = !!answer && enabled;

  const updateContent = useCallback(
    (content: IContent) => {
      dispatch &&
        indexPath &&
        dispatch({
          type: ActionTypes.UPDATE_ANSWER,
          answer: content,
          path: indexPath,
        });
    },
    [dispatch, indexPath]
  );

  const updateValues = useCallback(
    (values: FieldValues) => {
      dispatch &&
        indexPath &&
        dispatch({ type: ActionTypes.UPDATE_VALUES, values, path: indexPath });
    },
    [dispatch, indexPath]
  );

  const updateQuestion = useCallback(
    (question: Partial<IQuestionItem>) => {
      dispatch &&
        indexPath &&
        dispatch({
          type: ActionTypes.EDIT_QUESTION,
          question,
          path: indexPath,
        });
    },
    [dispatch, indexPath]
  );

  const remove = useCallback(
    () =>
      dispatch &&
      indexPath &&
      dispatch({ type: ActionTypes.REMOVE_QUESTION, path: indexPath }),
    [dispatch, indexPath]
  );

  const indent = path.length - 1;
  return [
    {
      indent,
      isMinimizable,
      hasSubQuestions,
      isCollapsed,
      enabled,
      showAnswer,
      subQuestions,
      values,
      answer: isLegacyAnswer(answer) ? answer : undefined, // FIXME: hack to test if answer can only be legacy text answer
      code,
      required,
      mode,
    },
    {
      remove,
      updateQuestion,
      updateValues,
      updateContent: updateContent,
      toggle,
    },
  ] as const;
}
export default useQuestion;
