import React, { Ref } from "react";

const AvatarWitoutRef = (
  { initials }: { initials: string },
  ref: Ref<HTMLSpanElement>
) => (
  <span
    ref={ref}
    className="inline-flex h-8 w-8 items-center justify-center rounded-full bg-gray-primary"
  >
    <span className="text-sm font-medium leading-none text-white">
      {initials}
    </span>
  </span>
);
const Avatar = React.forwardRef(AvatarWitoutRef);
export default Avatar;
