import TopDownForm from "@/components/forms/TopDownForm";
import { useLocation } from "react-router";

export default function Error() {
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  return (
    <div className="flex h-screen items-center justify-center">
      <main className="prose">
        <h2 className="text-2xl font-bold">Error while finding your patient</h2>
        <p className="text-sm">
          We tried to load the patient given the parameters provide from your
          EHR system, but we were unable to find a match or create a new
          patient.
        </p>
        <p className="text-sm">
          Contact your system administrator to fix this integration issue or
          contact us at <a href="mailto:admin@tiro.health">admin@tiro.health</a>
        </p>
        <details>
          <summary> Technical information</summary>
          <div className="pl-4">
            <p className="text-sm">
              Please make sure that at least a{" "}
              <code>patient=&lt;patient-id&gt;</code> parameter is provided in
              the URL. This parameter should be the patient&apos;s ID in your
              EHR system. It is used to match the patient in the system. If the
              patient is not found, a new patient will be created.
            </p>

            <form method="get">
              <h3 className="text-sm font-bold">Received parameters</h3>
              <p className="text-xs text-gray-primary">
                We received the following parameters through the URL. The form
                bellow allows you to edit them and launch again.
              </p>
              <TopDownForm.Field
                small
                name="patient"
                label="patient"
                defaultValue={params.get("patient") ?? ""}
              />
              <TopDownForm.Field
                small
                name="encounter"
                label="encounter"
                defaultValue={params.get("encounter") ?? ""}
              />
              <TopDownForm.Field
                small
                name="sex"
                label="sex"
                defaultValue={params.get("sex") ?? ""}
                list="sex"
              />
              <datalist id="sex">
                <option value="male">male</option>
                <option value="female">female</option>
                <option value="other">other</option>
              </datalist>
              <TopDownForm.Field
                small
                name="dob"
                type="date"
                label="dob"
                defaultValue={params.get("dob") ?? ""}
              />
              <TopDownForm.Field
                small
                name="user"
                label="user"
                defaultValue={params.get("user") ?? ""}
              />
              <TopDownForm.Button small type="submit">
                Launch
              </TopDownForm.Button>
            </form>
          </div>
        </details>
      </main>
    </div>
  );
}
