import {
  DocumentTextIcon,
  ArrowUpTrayIcon,
  ArrowPathIcon,
} from "@heroicons/react/24/outline";
import Tippy from "@tippyjs/react";
import classNames from "classnames";
import ReportButton from "@/components/base/ReportButton";
import { useRef, useReducer, useState } from "react";
import { BSON } from "realm-web";
import { is, number, type } from "superstruct";
import AttachmentPreview from "./AttachmentPreview";

export interface IInternalAttachment {
  title: string;
  attachmentId: BSON.ObjectID;
  url?: undefined;
}

export interface IExternalAttachment {
  title: string;
  url: string;
  attachmentId?: undefined;
}

export interface IEmptyAttachement {
  title?: undefined;
  url?: undefined;
  attachmentId?: undefined;
}

export type IAttachment =
  | IInternalAttachment
  | IExternalAttachment
  | IEmptyAttachement;

export interface ControlledAttachmentButtonProps extends Record<string, any> {
  onChange: (v: IAttachment) => void;
  attachment?: IAttachment;
  selected: boolean;
  readOnly?: boolean;
}

export interface UncontrolledAttachmentButtonProps extends Record<string, any> {
  onChange?: undefined;
  attachment?: undefined;
  selected: boolean;
  readOnly?: undefined;
}

export type AttachmentButtonProps =
  | ControlledAttachmentButtonProps
  | UncontrolledAttachmentButtonProps;

type Action = "RESET" | "START" | { progress: number } | "DONE";
type State =
  | { type: "EMPTY" }
  | { type: "LOADING"; progress: number }
  | { type: "PROVISIONED" };
function reducer(state: State, action: Action): State {
  let newState: State = state;
  switch (action) {
    case "RESET":
      newState = { type: "EMPTY" };
      break;
    case "START":
      newState = { type: "LOADING", progress: 0 };
      break;
    case "DONE":
      newState = { type: "PROVISIONED" };
      break;
    default:
      if (is(action, type({ progress: number() }))) {
        newState = { type: "LOADING", ...action };
      }
  }
  return newState;
}

const AttachmentButton = ({
  selected,
  children,
  readOnly,
  attachment: providedAttachment,
  onChange: providedSetAttachment,
  ...attributes
}: AttachmentButtonProps) => {
  // controlle the preview window open/closed state
  const [isOpen, setIsOpen] = useState(false);

  // this state is only relevant when this component is used uncontrolled
  const [localAttachment] = useState<IAttachment>(providedAttachment ?? {});

  // a developper can set this component in 'uncontrolled' mode by setting `onChange`=undefined
  const attachment = providedSetAttachment
    ? providedAttachment
    : localAttachment;

  const isProvisioned = attachment?.url || attachment?.attachmentId;
  const [state] = useReducer(
    reducer,
    isProvisioned ? { type: "PROVISIONED" } : { type: "EMPTY" },
  );

  const ref = useRef<HTMLInputElement>(null);

  // TODO: reimplement this with google storage
  if (state.type == "LOADING") {
    return (
      <button
        {...attributes}
        className={classNames(
          "relative mx-px inline-block select-none rounded border bg-gray-ultralight px-2 py-1 text-sm shadow",
          {
            "ring-2 ring-blue-light": selected,
          },
        )}
      >
        <span className="m-2 inline-flex items-center">
          <ArrowUpTrayIcon className="mx-1 h-4 w-4 text-primary" />
          <div className="mx-2 h-1 w-60 rounded-full bg-gray-light dark:bg-gray-dark">
            <div
              className="h-1.5 rounded-full bg-blue-700 transition-all duration-1000 ease-out"
              style={{ width: `${state.progress}%` }}
            ></div>
          </div>
        </span>
        {children}
      </button>
    );
  }

  if (isProvisioned) {
    return (
      <span {...attributes} className={classNames("inline-block select-none")}>
        <input
          type="file"
          className="sr-only"
          readOnly={readOnly}
          ref={ref}
          accept=".pdf"
        />
        <Tippy delay={[500, 50]} content="Open een preview van je bijlage.">
          <ReportButton
            outline
            onClick={() => setIsOpen(true)}
            active={selected}
            Icon={<DocumentTextIcon className="inline h-4 w-4" />}
          >
            {attachment!.title}
          </ReportButton>
        </Tippy>

        {!readOnly && (
          <Tippy
            delay={[500, 50]}
            content="Vervang je bijlage door een ander bestand."
          >
            <button
              type="button"
              onClick={() => ref.current?.click()}
              className="r-0 absolute inset-y-0 inline-flex items-center hover:bg-gray-ultralight"
            >
              <ArrowPathIcon className="mx-2 h-4 w-4 text-primary" />
            </button>
          </Tippy>
        )}
        {attachment!.title && (
          <AttachmentPreview
            title={attachment!.title}
            url={attachment.url!}
            isOpen={isOpen}
            close={() => setIsOpen(false)}
          />
        )}
        {children}
      </span>
    );
  }

  return (
    <button
      {...attributes}
      onClick={() => ref.current?.click()}
      className={classNames(
        "relative mx-px inline-block select-none rounded border bg-gray-ultralight px-2 py-1 text-sm shadow",
        {
          "ring-2 ring-blue-light": selected,
        },
      )}
    >
      <input type="file" className="sr-only" ref={ref} accept=".pdf" />
      <span className="inline-flex items-center text-primary">
        <ArrowUpTrayIcon className="mx-1 h-4 w-4" />
        Voeg een bijlage toe aan je verslag
      </span>
      {children}
    </button>
  );
};

export default AttachmentButton;
