import { LOGIN_PATH } from "@/pages/auth/login/const";
import { QueryClient } from "react-query";
import { LoaderFunction } from "react-router";
import { logout } from "@/services/auth/login";
import { redirectAndKeepQueryParams } from "../auth-flow";

export default (queryClient: QueryClient): LoaderFunction =>
  async ({ request }) => {
    logout();
    queryClient.clear();
    return redirectAndKeepQueryParams(request.url, `/${LOGIN_PATH}`);
  };
