import { sendEmailVerification } from "firebase/auth";
import { ActionFunction, redirect } from "react-router";
import { getTenantAuth } from "@/services/tenant";
import { ASK_USER_DATA_PATH } from "../ask-user-data/const";
import { LOGIN_PATH } from "../login/const";

export default (): ActionFunction =>
  async ({ request }): Promise<ActionData | Response> => {
    const auth = getTenantAuth();
    const user = auth.currentUser;
    if (!user) return redirect(LOGIN_PATH);
    const payload = await request.formData();
    const resend = payload.get("resend");
    if (resend) {
      const continueURL = new URL(ASK_USER_DATA_PATH, request.url);
      await sendEmailVerification(user, { url: continueURL.href });
      return { status: "resent", timestamp: Date.now() };
    }
    return null;
  };
export type ActionData = { status: "resent"; timestamp: number } | null;
