import {
  autoPlacement,
  FloatingPortal,
  offset,
  useFloating,
} from "@floating-ui/react";
import { Popover } from "@headlessui/react";
import { AdjustmentsVerticalIcon } from "@heroicons/react/24/outline";
import { default as TDForm } from "@/components/forms/TopDownForm";
import { FormEventHandler, useMemo } from "react";
import { is, string } from "superstruct";
import { v4 } from "uuid";
import { ExpansionProperties } from "./types";

type ExpansionPropertiesFormProps = {
  value: ExpansionProperties;
  onChange?: (value: Partial<ExpansionProperties>) => void;
};

function ExpansionPropertiesForm({
  value,
  onChange,
}: ExpansionPropertiesFormProps) {
  const id = useMemo(() => v4(), []);
  const handleSubmit: FormEventHandler<HTMLFormElement> = (event) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    let expansionProps: Partial<ExpansionProperties> = {};
    const valueSet = formData.get("valueSet") as null | string;
    if (valueSet) expansionProps.valueSet = valueSet;
    formData.get("prefix") === "on"
      ? (expansionProps.prefix = true)
      : (expansionProps.prefix = false);
    formData.get("fuzzy") === "on"
      ? (expansionProps.fuzzy = true)
      : (expansionProps.fuzzy = false);
    formData.get("acronym") === "on"
      ? (expansionProps.acronym = true)
      : (expansionProps.acronym = false);
    const count = Number(formData.get("count"));
    if (!isNaN(count)) expansionProps.count = count;
    onChange?.(expansionProps);
  };
  const { floatingStyles, refs } = useFloating({
    middleware: [offset(10), autoPlacement()],
  });
  return (
    <Popover id={id} className="relative">
      <Popover.Button
        ref={refs.setReference}
        className="rounded-md border border-gray-300 bg-white px-2 py-1.5 text-gray-700 shadow-sm hover:bg-gray-50 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-1"
      >
        <AdjustmentsVerticalIcon className="my-px h-5 w-5" aria-hidden="true" />
        <span className="sr-only">Expansion Properties</span>
      </Popover.Button>

      <FloatingPortal>
        <Popover.Panel
          ref={refs.setFloating}
          style={floatingStyles}
          className="rounded-md bg-white p-2 shadow-lg ring-1 ring-black ring-opacity-5"
        >
          <form onSubmit={handleSubmit}>
            <TDForm.Field
              id={`${id}-valueSet`}
              size={20}
              name="valueSet"
              label="ValueSet"
              onBlur={(event) => event.currentTarget.form?.requestSubmit()}
              disabled={!is(value.valueSet, string())}
              defaultValue={
                is(value.valueSet, string())
                  ? value.valueSet
                  : value.valueSet.url
              }
              small
            />
            <fieldset>
              <TDForm.Option
                id={`${id}-prefix`}
                name="prefix"
                label="Prefix"
                defaultChecked={value.prefix}
                small
                onInput={(event) => event.currentTarget.form?.requestSubmit()}
              />
              <TDForm.Option
                id={`${id}-fuzzy`}
                name="fuzzy"
                label="Fuzzy"
                defaultChecked={value.fuzzy}
                small
                onInput={(event) => event.currentTarget.form?.requestSubmit()}
              />
              <TDForm.Option
                id={`${id}-acronym`}
                name="acronym"
                label="Acronym"
                defaultChecked={value.acronym}
                small
                onInput={(event) => event.currentTarget.form?.requestSubmit()}
              />
            </fieldset>
            <TDForm.Field
              id={`${id}-count`}
              type="number"
              min={0}
              max={100}
              name="count"
              label="Count"
              defaultValue={value.count}
              onBlur={(event) => event.currentTarget.form?.requestSubmit()}
              small
            />
          </form>
        </Popover.Panel>
      </FloatingPortal>
    </Popover>
  );
}

export default ExpansionPropertiesForm;
