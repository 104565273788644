import { useEffect } from "react";
import {
  FieldPath,
  FieldValues,
  useFormContext,
  UseFormReturn,
} from "react-hook-form";
import { FieldChangeEvent } from "./store";
import { useFieldProvenance } from "./useFieldProvenance";

export default function useFieldProvenanceTracker<
  TFieldValues extends FieldValues = FieldValues,
  TPath extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>(path: TPath, formContext: UseFormReturn<TFieldValues, any, undefined>) {
  const getlastEvent = useFieldProvenance<
    (name: TPath) => FieldChangeEvent<TFieldValues, TPath> | undefined,
    TFieldValues
  >((state) => state.getLastFieldEvent);
  const track = useFieldProvenance((state) => state.track);
  const { watch } = useFormContext<TFieldValues>() ?? formContext;
  useEffect(() => {
    const subscription = watch((values, info) => {
      const { name, type } = info;
      if (path == name) {
        track({ name, by: { type: "user", id: "TODO" }, type });
      }
    });
    return () => subscription.unsubscribe();
  }, [path, watch, track]);
  return { lastEvent: getlastEvent(path) };
}
