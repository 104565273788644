import CopyReportToClipboard from "./CopyReportToClipboard";
import ForwardFHIRDocument from "./ForwardFHIRDocument";
import ForwardFHIRTransaction from "./ForwardFHIRTransaction";
import LaunchKWSAction from "./LaunchKWSAction";
import { Action, Instruction, run, Sequenceable as Chainable } from "./Action";
import { SubmissionContext } from "./types";
import { useEffect, useState } from "react";
import { PastFromClipboard } from "./PastFromClipboard";
import UIDoneMessage from "./CloseWindow";
import { CloseWindowInstruction } from "./CloseWindowInstruction";

export type ActionSequencePreset =
  | "KWS"
  | "STANDALONE"
  | "SYNOPS"
  | "SYNOPS-DEV"
  | "SMART-ON-FHIR";

type ActionSeqFactory = (
  context: SubmissionContext,
) => (Action | Instruction)[];

const PRESET_SEQ_MAP: Record<ActionSequencePreset, ActionSeqFactory> = {
  KWS: ({ queryClient: client, patientId, reportId }: SubmissionContext) => [
    new CopyReportToClipboard({ client, patientId, reportId }),
    new LaunchKWSAction({ client, patientId, reportId }),
    new PastFromClipboard(),
  ],
  STANDALONE: ({
    queryClient: client,
    patientId,
    reportId,
  }: SubmissionContext) => [
    new CopyReportToClipboard({ client, patientId, reportId }),
    new PastFromClipboard(),
  ],
  "SYNOPS-DEV": ({
    queryClient: client,
    patientId,
    reportId,
  }: SubmissionContext) => [
    new ForwardFHIRDocument({ client, patientId, reportId }),
    new UIDoneMessage(),
  ],
  SYNOPS: ({ queryClient: client, patientId, reportId }: SubmissionContext) => [
    new ForwardFHIRDocument({ client, patientId, reportId }),
    new CloseWindowInstruction(),
  ],
  "SMART-ON-FHIR": ({
    queryClient: client,
    patientId,
    reportId,
    fhirEndpoint,
  }: SubmissionContext) => {
    if (!fhirEndpoint) throw new Error("No FHIR endpoint provided");
    return [
      new ForwardFHIRTransaction({ client, patientId, reportId, fhirEndpoint }),
    ];
  },
};

export function chain(actions: Chainable[]) {
  actions.reduce((prev, current) => {
    prev.next = current;
    return current;
  });
}

export function useActionSequencePreset(
  preset: ActionSequencePreset,
  context: SubmissionContext,
  autoStart: boolean = true,
) {
  const [sequence] = useState(() => {
    const actions = PRESET_SEQ_MAP[preset](context);
    chain(actions);
    return actions;
  });
  useEffect(() => {
    const first = sequence[0];
    if (first.current["status"] === "QUEUED" && autoStart) {
      run(sequence[0]);
    }
  }, [sequence, autoStart]);
  console.debug("Preset sequence:", sequence);
  return sequence;
}
