import classNames from "classnames";
import React from "react";

export interface InputProps
  extends React.SelectHTMLAttributes<HTMLSelectElement> {
  error?: boolean;
  small?: boolean;
  extraSmall?: boolean;
}
function Select_(
  { className, error, small, extraSmall, ...props }: InputProps,
  ref?: React.Ref<HTMLSelectElement>,
) {
  return (
    <select
      ref={ref}
      className={classNames(
        "block w-full appearance-none border placeholder-gray-primary focus:outline-none sm:text-sm",
        {
          "border-gray-light focus:border-blue-primary focus:ring-blue-primary":
            !error,
          "border-red-500 ring-1 ring-red-500": error,
          "rounded-md px-3 py-2": !small,
          "rounded px-2 py-0.5 text-sm": small,
        },
        className,
      )}
      {...props}
    />
  );
}

const Select = React.forwardRef(Select_);

export default Select;
