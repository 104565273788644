import { useMemo } from "react";
import { SCTCoding } from "@/data-models/value-models/code";
import { Node } from "slate";
import { InlineEntityElement } from "@/editor/legacy/Entities/InlineEntityElement";
import { EntryElement } from "@/editor/legacy/EntryLayoutPlugin/EntryElement";
import { ConceptEntityModel } from "@/data-models/concept-entity";
import { is } from "superstruct";
import { default as RichTextContentModel } from "@/components/blocks/content/RichTextBlock/models";
import useBlock from "@/services/reports/useBlock";
import { BlockOut, ReportOut } from "@/services/reports/report-client";

const TARGET_CODES = [
  SCTCoding.fromSCT("271737000 |anemie (aandoening)|"),
  SCTCoding.fromSCT("14379009 |dimorfe anemie (aandoening)|"),
  SCTCoding.fromSCT("191268006 |chronische anemie (aandoening)|"),
  SCTCoding.fromSCT("44054006 |diabetes mellitus type 2 (aandoening)|"),
  SCTCoding.fromSCT("238136002 |morbide obesitas (aandoening)|"),
  SCTCoding.fromSCT("414916001 |obesitas (aandoening)|"),
  SCTCoding.fromSCT("770680004 |Prader-Willi-achtig syndroom (aandoening)|"),
  SCTCoding.fromSCT("21681000146100 |iatrogene obesitas (aandoening)|"),
  SCTCoding.fromSCT("264585001 |colostoma (afwijkende morfologie)|"),
  SCTCoding.fromSCT("29241000146106 |Malone-stoma (afwijkende morfologie)|"),
];
function blockHasTargetCode(block: BlockOut) {
  const { content } = block;
  // this algorithm works only for rich text context!
  if (!content) return false;
  if (!is(content, RichTextContentModel)) return;
  for (const topLevelNode of content) {
    for (const [node] of Node.nodes(topLevelNode, {
      pass: (n) => EntryElement.isLeftColumnEntry(n),
    })) {
      if (
        InlineEntityElement.isInlineEntityElement(node) &&
        is(node.entity, ConceptEntityModel)
      ) {
        for (const code of node.entity.coding) {
          if (TARGET_CODES.some((t) => t.equals(code))) {
            return true;
          }
        }
      }
    }
  }
  return false;
}

export const useLaagVariabeleZorg = (
  report: ReportOut,
  options?: { enabled?: boolean },
) => {
  const { enabled = true } = options ?? {};
  const pmhblock = report?.blocks?.find((b) =>
    b.blockType.startsWith("medical-history"),
  );
  const { data: block } = useBlock(pmhblock?.id);

  const isLVZ = useMemo(() => {
    if (!enabled) return;
    if (!block || !block.content) return false;
    return !blockHasTargetCode(block);
  }, [enabled, block]);
  return isLVZ;
};
