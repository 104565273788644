/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BlockIn } from '../models/BlockIn';
import type { BlockOut } from '../models/BlockOut';
import type { BlockUpdate } from '../models/BlockUpdate';
import type { JsonValue } from '../models/JsonValue';
import type { PatientIn } from '../models/PatientIn';
import type { PatientOut } from '../models/PatientOut';
import type { PatientUpdate } from '../models/PatientUpdate';
import type { ReportIn } from '../models/ReportIn';
import type { ReportOut } from '../models/ReportOut';
import type { ReportWithCanonicalBlockUrlsIn } from '../models/ReportWithCanonicalBlockUrlsIn';
import type { ReportWithCanonicalUrl } from '../models/ReportWithCanonicalUrl';
import type { UserOut } from '../models/UserOut';
import type { UserUpdate } from '../models/UserUpdate';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class V1Service {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Get Reports
     * Get all reports
     * @returns ReportOut Successful Response
     * @throws ApiError
     */
    public getReportsV1({
        offset,
        limit = 1000,
        sort = 'modified:desc',
        encounter,
        encounterIdentifier,
        subject,
    }: {
        offset?: number,
        limit?: number,
        sort?: string,
        encounter?: (string | null),
        /**
         * Filter by encounter identifier
         */
        encounterIdentifier?: (string | null),
        /**
         * Filter by subject reference
         */
        subject?: (string | number | null),
    }): CancelablePromise<Array<ReportOut>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v1/reports/',
            query: {
                'offset': offset,
                'limit': limit,
                'sort': sort,
                'encounter': encounter,
                'encounter:identifier': encounterIdentifier,
                'subject': subject,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Create Report
     * Create a new report
     * @returns ReportOut Successful Response
     * @throws ApiError
     */
    public createReportV1({
        requestBody,
    }: {
        requestBody: (ReportIn | ReportWithCanonicalBlockUrlsIn | ReportWithCanonicalUrl),
    }): CancelablePromise<ReportOut> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v1/reports/',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Report
     * Get a single report
     * @returns ReportOut Successful Response
     * @throws ApiError
     */
    public getReportV1({
        reportId,
        blocksEmbedded = false,
    }: {
        reportId: number,
        blocksEmbedded?: boolean,
    }): CancelablePromise<ReportOut> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v1/reports/{report_id}',
            path: {
                'report_id': reportId,
            },
            query: {
                'blocks_embedded': blocksEmbedded,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Delete Report
     * Delete a report
     * @returns void
     * @throws ApiError
     */
    public deleteReportV1({
        reportId,
    }: {
        reportId: number,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/api/v1/reports/{report_id}',
            path: {
                'report_id': reportId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Submit Report
     * Refresh table with given report id
     * @returns any Successful Response
     * @throws ApiError
     */
    public submitReportV1({
        reportId,
    }: {
        reportId: number,
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v1/reports/{report_id}/submit',
            path: {
                'report_id': reportId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Blocks From Report
     * Get all blocks for a report
     * @returns BlockOut Successful Response
     * @throws ApiError
     */
    public getBlocksFromReportV1({
        reportId,
    }: {
        reportId: number,
    }): CancelablePromise<Array<BlockOut>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v1/reports/{report_id}/blocks',
            path: {
                'report_id': reportId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Append Block To Report
     * Create a new block
     * @returns BlockOut Successful Response
     * @throws ApiError
     */
    public appendBlockToReportV1({
        reportId,
        requestBody,
    }: {
        reportId: number,
        requestBody: BlockIn,
    }): CancelablePromise<BlockOut> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v1/reports/{report_id}/blocks',
            path: {
                'report_id': reportId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Delete Block In Report
     * Delete a block in a report
     * @returns void
     * @throws ApiError
     */
    public deleteBlockInReportV1({
        reportId,
        blockId,
    }: {
        reportId: number,
        blockId: number,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/api/v1/reports/{report_id}/blocks',
            path: {
                'report_id': reportId,
            },
            query: {
                'block_id': blockId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Block At Position In Report
     * Get a block at a certain position for a report
     * @returns BlockOut Successful Response
     * @throws ApiError
     */
    public getBlockAtPositionInReportV1({
        reportId,
        position,
    }: {
        reportId: number,
        position: number,
    }): CancelablePromise<BlockOut> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v1/reports/{report_id}/blocks/{position}',
            path: {
                'report_id': reportId,
                'position': position,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Insert Or Move Block At Position In Report
     * Insert a new block at a position in a report or move an existing block to a position in a report
     * @returns BlockOut Successful Response
     * @throws ApiError
     */
    public insertOrMoveBlockAtPositionInReportV1({
        reportId,
        position,
        id,
        requestBody,
    }: {
        reportId: number,
        position: number,
        /**
         * The id of the block to move or copy
         */
        id?: (number | null),
        requestBody?: (BlockIn | null),
    }): CancelablePromise<BlockOut> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/api/v1/reports/{report_id}/blocks/{position}',
            path: {
                'report_id': reportId,
                'position': position,
            },
            query: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Fhir Document
     * Generate a FHIR document for a report
     * @returns any Successful Response
     * @throws ApiError
     */
    public getFhirDocumentV1({
        reportId,
        extractResources = false,
    }: {
        reportId: number,
        extractResources?: boolean,
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v1/reports/{report_id}/fhir-document',
            path: {
                'report_id': reportId,
            },
            query: {
                'extract_resources': extractResources,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Pdf
     * Generate a PDF of the report
     * @returns any Successful Response
     * @throws ApiError
     */
    public getPdfV1({
        reportId,
    }: {
        reportId: number,
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v1/reports/{report_id}/pdf',
            path: {
                'report_id': reportId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Html
     * Generate an HTML representation of the report
     * @returns any Successful Response
     * @throws ApiError
     */
    public getHtmlV1({
        reportId,
    }: {
        reportId: number,
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v1/reports/{report_id}/html',
            path: {
                'report_id': reportId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Plain Text
     * Generate plain text of the report
     * @returns any Successful Response
     * @throws ApiError
     */
    public getPlainTextV1({
        reportId,
    }: {
        reportId: number,
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v1/reports/{report_id}/plain-text',
            path: {
                'report_id': reportId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Table Records
     * Generate table record of the report
     * @returns any Successful Response
     * @throws ApiError
     */
    public getTableRecordsV1({
        reportId,
    }: {
        reportId: number,
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v1/reports/{report_id}/table-records',
            path: {
                'report_id': reportId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Refresh Table Record
     * Generate table record of the report
     * @returns any Successful Response
     * @throws ApiError
     */
    public refreshTableRecordV1({
        reportId,
    }: {
        reportId: number,
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'HEAD',
            url: '/api/v1/reports/{report_id}/table-record',
            path: {
                'report_id': reportId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Blocks
     * Get all blocks
     * @returns BlockOut Successful Response
     * @throws ApiError
     */
    public getBlocksV1({
        offset,
        limit = 1000,
        sort = 'modified:desc',
        type,
        subject,
    }: {
        offset?: number,
        limit?: number,
        sort?: string,
        type?: (string | null),
        subject?: (string | null),
    }): CancelablePromise<Array<BlockOut>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v1/blocks/',
            query: {
                'offset': offset,
                'limit': limit,
                'sort': sort,
                'type': type,
                'subject': subject,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Block
     * Get a single block
     * @returns BlockOut Successful Response
     * @throws ApiError
     */
    public getBlockV1({
        blockId,
    }: {
        blockId: number,
    }): CancelablePromise<BlockOut> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v1/blocks/{block_id}',
            path: {
                'block_id': blockId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update Block
     * Update a block
     * @returns BlockOut Successful Response
     * @throws ApiError
     */
    public updateBlockV1({
        blockId,
        requestBody,
    }: {
        blockId: number,
        requestBody: BlockUpdate,
    }): CancelablePromise<BlockOut> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/api/v1/blocks/{block_id}',
            path: {
                'block_id': blockId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Validate Block
     * Validate blocks
     * @returns any Successful Response
     * @throws ApiError
     */
    public validateBlockV1({
        requestBody,
    }: {
        requestBody?: Array<number>,
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v1/blocks/validate',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Refresh Table Records
     * Refresh table records
     * @returns any Successful Response
     * @throws ApiError
     */
    public refreshTableRecordsV1({
        blockId,
        force = false,
    }: {
        blockId: number,
        force?: boolean,
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'HEAD',
            url: '/api/v1/blocks/{block_id}/table-records',
            path: {
                'block_id': blockId,
            },
            query: {
                'force': force,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Block Table Records
     * Load table records
     * @returns any Successful Response
     * @throws ApiError
     */
    public getBlockTableRecordsV1({
        blockId,
        force = false,
    }: {
        blockId: number,
        force?: boolean,
    }): CancelablePromise<Array<any>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v1/blocks/{block_id}/table_records',
            path: {
                'block_id': blockId,
            },
            query: {
                'force': force,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Populate
     * Populate a block following a population strategy
     * @returns BlockOut Successful Response
     * @throws ApiError
     */
    public populateV1({
        blockId,
    }: {
        blockId: number,
    }): CancelablePromise<BlockOut> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v1/blocks/{block_id}/populate',
            path: {
                'block_id': blockId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Sign
     * Sign a block in a certain report
     * @returns BlockOut Successful Response
     * @throws ApiError
     */
    public signV1({
        blockId,
    }: {
        blockId: number,
    }): CancelablePromise<BlockOut> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v1/blocks/{block_id}/sign',
            path: {
                'block_id': blockId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Contained Resources
     * Get all FHIR resources contained in a block
     * @returns JsonValue Successful Response
     * @throws ApiError
     */
    public getContainedResourcesV1({
        blockId,
    }: {
        blockId: number,
    }): CancelablePromise<Array<Record<string, JsonValue>>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v1/blocks/{block_id}/contained',
            path: {
                'block_id': blockId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Add Contained Resource
     * Add a FHIR resource to a block
     * @returns any Successful Response
     * @throws ApiError
     */
    public addContainedResourceV1({
        blockId,
        requestBody,
    }: {
        blockId: number,
        requestBody: Record<string, JsonValue>,
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v1/blocks/{block_id}/contained',
            path: {
                'block_id': blockId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Contained Resource
     * Get a specific FHIR resource contained in a block
     * @returns any Successful Response
     * @throws ApiError
     */
    public getContainedResourceV1({
        blockId,
        resourceId,
    }: {
        blockId: number,
        resourceId: number,
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v1/blocks/{block_id}/contained/{resource_id}',
            path: {
                'block_id': blockId,
                'resource_id': resourceId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update Contained Resource
     * Update a FHIR resource contained in a block
     * @returns any Successful Response
     * @throws ApiError
     */
    public updateContainedResourceV1({
        blockId,
        resourceId,
        requestBody,
    }: {
        blockId: number,
        resourceId: number,
        requestBody: Record<string, JsonValue>,
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/api/v1/blocks/{block_id}/contained/{resource_id}',
            path: {
                'block_id': blockId,
                'resource_id': resourceId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Delete Contained Resource
     * Delete a FHIR resource contained in a block
     * @returns void
     * @throws ApiError
     */
    public deleteContainedResourceV1({
        blockId,
        resourceId,
    }: {
        blockId: number,
        resourceId: number,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/api/v1/blocks/{block_id}/contained/{resource_id}',
            path: {
                'block_id': blockId,
                'resource_id': resourceId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Users
     * Get all users
     * @returns UserOut Successful Response
     * @throws ApiError
     */
    public getUsersV1({
        offset,
        limit = 1000,
    }: {
        offset?: number,
        limit?: number,
    }): CancelablePromise<Array<UserOut>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v1/users/',
            query: {
                'offset': offset,
                'limit': limit,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get User Me
     * Get current user
     * @returns UserOut Successful Response
     * @throws ApiError
     */
    public getUserMeV1(): CancelablePromise<UserOut> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v1/users/me',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update Current User Data
     * Update user data
     * @returns UserOut Successful Response
     * @throws ApiError
     */
    public updateCurrentUserDataV1({
        requestBody,
    }: {
        requestBody: UserUpdate,
    }): CancelablePromise<UserOut> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/api/v1/users/me',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update User Data
     * Update user data
     * @returns UserOut Successful Response
     * @throws ApiError
     */
    public updateUserDataV1({
        userId,
        requestBody,
    }: {
        userId: number,
        requestBody: UserUpdate,
    }): CancelablePromise<UserOut> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/api/v1/users/{user_id}',
            path: {
                'user_id': userId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Patients
     * Get all patients
     * @returns PatientOut Successful Response
     * @throws ApiError
     */
    public getPatientsV1({
        offset,
        limit = 1000,
        identifier,
        sort,
    }: {
        offset?: number,
        limit?: number,
        identifier?: (string | null),
        sort?: (string | null),
    }): CancelablePromise<Array<PatientOut>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v1/patients/',
            query: {
                'offset': offset,
                'limit': limit,
                'identifier': identifier,
                'sort': sort,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Create Patient
     * Create patient
     * @returns PatientOut Successful Response
     * @throws ApiError
     */
    public createPatientV1({
        requestBody,
    }: {
        requestBody: PatientIn,
    }): CancelablePromise<PatientOut> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v1/patients/',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Patient
     * Get all users
     * @returns PatientOut Successful Response
     * @throws ApiError
     */
    public getPatientV1({
        patientId,
    }: {
        patientId: number,
    }): CancelablePromise<PatientOut> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v1/patients/{patient_id}',
            path: {
                'patient_id': patientId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update Patient
     * Get all users
     * @returns PatientOut Successful Response
     * @throws ApiError
     */
    public updatePatientV1({
        patientId,
        requestBody,
    }: {
        patientId: number,
        requestBody: PatientUpdate,
    }): CancelablePromise<PatientOut> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/api/v1/patients/{patient_id}',
            path: {
                'patient_id': patientId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Delete Patient
     * Get all users
     * @returns PatientOut Successful Response
     * @throws ApiError
     */
    public deletePatientV1({
        patientId,
    }: {
        patientId: number,
    }): CancelablePromise<PatientOut> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/api/v1/patients/{patient_id}',
            path: {
                'patient_id': patientId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
