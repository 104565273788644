export default function getSeparator(
  locale: string,
  separatorType: "group" | "decimal"
) {
  /**
   *
   * const numberWithGroupAndDecimalSeparator = 1000.1;
   * if (!Intl.NumberFormat) throw Error("This browser doesn't support Intl.")
   * return Intl.NumberFormat(locale)
   *  .formatToParts(numberWithGroupAndDecimalSeparator)
   *   .find(part => part.type === separatorType)
   *  .value;
   **/
  return separatorType == "group"
    ? "."
    : separatorType == "decimal"
    ? ","
    : undefined;
}
