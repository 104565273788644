import { IAbstractSuggestion } from "./types";
import { Coding } from "@/data-models/value-models";
import { Infer } from "superstruct";
import { Concept, ConceptModel } from "@/data-models/concept";

const SCT = "http://snomed.info/sct";
const FINDING_ABSENT = new Coding("373572006", SCT, "finding absent");
const DISORDER_EXCLUDED = new Coding("315215002", SCT, "disorder excluded");

export class ConceptSuggestion extends Concept implements IAbstractSuggestion {
  type = "concept" as const;
  token?: string;

  constructor(o: Infer<typeof ConceptModel>) {
    super(o);
  }

  get displayText() {
    return this.text;
  }

  get description() {
    return this.semanticContext?.display;
  }

  get position() {
    return { offset: 0, length: this.displayText.length };
  }

  get color() {
    if (!this.semanticContext) return "default";
    const coding = Coding.fromObj(this.semanticContext);
    if (coding.equals(FINDING_ABSENT) || coding.equals(DISORDER_EXCLUDED)) {
      return "red";
    }
    return "default";
  }
}
