import { useContext } from "react";
import { FieldPath, FieldValues } from "react-hook-form";
import { useStore } from "zustand";
import { FieldProvenanceContext } from "./context";
import { FieldProvenanceState, FieldProvenanceStore } from "./store";

export function useFieldProvenance<
  T,
  TFieldValues extends FieldValues = FieldValues,
  TPath extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>(selector: (state: FieldProvenanceState<TFieldValues, TPath>) => T): T {
  const store = useContext(FieldProvenanceContext) as FieldProvenanceStore<
    TFieldValues,
    TPath
  > | null;
  if (!store) throw new Error("Missing BearContext.Provider in the tree");
  return useStore(store, selector);
}
