import {
  CalculatorIcon,
  InformationCircleIcon,
} from "@heroicons/react/24/outline";
import { Ref } from "react";
import classNames from "classnames";
import React from "react";

export interface IButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  kind?:
    | "default"
    | "discrete"
    | "invisible"
    | "info"
    | "calculator"
    | "loader";
  label?: string;
  enabled?: boolean;
}

////   Styling   ////
const DEFAULT =
  "inline-flex justify-center rounded-md border py-2 px-4 text-sm font-medium focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-offset-2";
const BLUE = "border-transparent bg-blue-primary text-white hover:bg-blue-dark"; // for default
const DISCRETE = "border-gray-400 bg-white hover:bg-gray-ultralight";
const CALCULATOR =
  "relative mx-px inline-block select-none rounded border bg-gray-ultralight px-1 py-1 text-sm shadow focus:ring-2 focus:ring-blue-light";
const LOADER =
  "relative mx-px inline-block select-none rounded border bg-gray-ultralight px-1 py-1 text-sm shadow focus:ring-2 focus:ring-blue-light";
const DISABLED = "text-gray-400 bg-gray-ultralight";
const INVISIBLE =
  "text-gray-primary text-xs bg-fill-transparent py-1 hover:text-blue-primary focus:text-blue-primary";
const INFO =
  " bg-fill-transparent px-1 py-1 inline-flex items-center rounded-full border border-transparent text-white shadow-sm focus:text-blue-primary";

function ButtonWithoutRef(
  { kind, label, enabled, className, ...attributes }: IButtonProps,
  ref: Ref<HTMLButtonElement>
) {
  enabled = enabled === undefined ? true : false;
  switch (kind) {
    case "loader":
      return (
        <button
          ref={ref}
          {...attributes}
          className={classNames(LOADER, className)}
        >
          <CalculatorIcon className="h-8 w-8 stroke-current" />
        </button>
      );
    case "calculator":
      return (
        <button
          ref={ref}
          {...attributes}
          className={classNames(CALCULATOR, className)}
        >
          <CalculatorIcon className="h-8 w-8 stroke-current" />
        </button>
      );
    case "info":
      return (
        <button
          ref={ref}
          {...attributes}
          className={classNames(INFO, className)}
        >
          <InformationCircleIcon className="h-8 w-8 stroke-gray-primary hover:stroke-gray-800" />
        </button>
      );
    case "invisible":
      if (enabled) {
        return (
          <button
            ref={ref}
            {...attributes}
            className={classNames(INVISIBLE, className)}
          >
            {label}
          </button>
        );
      }
      break;
    default:
      return (
        <button
          ref={ref}
          {...attributes}
          className={classNames(
            DEFAULT,
            {
              [BLUE]:
                (kind === "default" || kind === undefined) && enabled === true,
              [DISCRETE]: kind === "discrete" && enabled === true,
              [DISABLED]: enabled === false,
            },
            className
          )}
          disabled={enabled === false}
        >
          {label}
        </button>
      );
  }
  return null;
}

const Button = React.forwardRef(ButtonWithoutRef);
export default Button;
