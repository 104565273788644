import { ICoding } from "@/data-models/value-models";
import { useMutation } from "react-query";
import client from "./client";

export async function validateCoding(coding: ICoding, url: string) {
  if (!coding.system) return false;
  if (!coding.code) return false;
  const response = await client.r5.validateValuesetCodeR5({
    url,
    requestBody: {
      coding,
    },
  });
  return (
    (response["result"]["code"] as boolean) &&
    (response["result"]["display"] as boolean) &&
    (response["result"]["valueset"] as boolean)
  );
}

function useValueSetValidator(url: string) {
  return useMutation(async (coding: ICoding) => validateCoding(coding, url));
}

export default useValueSetValidator;
