export * from "./content-client";
import { CONFIG } from "@/config";
import { getTenantAuth } from "@/services/tenant";
import { ContentClient } from "./content-client";

const getToken = async () => {
  // Retrieve the current user's ID token
  const user = getTenantAuth().currentUser;
  return (await user?.getIdToken()) ?? "";
};

/**
 * A client for the content engine
 */
const client = new ContentClient({
  BASE: CONFIG["CONTENT_ENGINE"],
  TOKEN: getToken,
  HEADERS: { "Access-Control-Max-Age": "86400" },
});

export default client;
