/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class DefaultService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Root
     * Root GET
     * @returns any Successful Response
     * @throws ApiError
     */
    public root(): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/',
        });
    }

    /**
     * Get Open Api
     * @returns any Successful Response
     * @throws ApiError
     */
    public getOpenApi(): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/openapi.json',
        });
    }

    /**
     * Get Docs
     * @returns any Successful Response
     * @throws ApiError
     */
    public getDocs(): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/docs',
        });
    }

    /**
     * Trigger Error
     * @returns any Successful Response
     * @throws ApiError
     */
    public triggerError(): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/sentry-debug',
        });
    }

}
