import {
  Control,
  Controller,
  FieldValues,
  useFormContext,
} from "react-hook-form";
import { PathsMatching } from "@/util/pathsMatching";
import { createValueSetExpansionQuery } from "@/services/content/useValueSetExpansion";
import { createdTypedDropdown } from "@/components/inputs/Dropdown/Dropdown";
import ObservationCode from "../ObservationCode";
import {
  Definition,
  ExpansionContains,
} from "@/services/content/content-client";
import {
  conceptAsCoding,
  ICodeableConcept,
  codingToConcept,
} from "@/data-models/value-models/types";
import { ConceptPath, IConceptObservation } from "./types";
import Tooltip from "@/components/info-cards/Tooltip";
import CodingTooltip from "@/components/base/CodingCard/CodingTooltip";
import { useQuery } from "react-query";
import { useFeatureFlagEnabled } from "posthog-js/react";

const CodingDropdown = createdTypedDropdown<ExpansionContains>();

function optionToKey(option: ExpansionContains) {
  return option.code;
}
function optionToDisplay(option: ExpansionContains) {
  return option.display || "No display";
}
function optionToDescription(option: ExpansionContains) {
  return (
    option.property?.find((p): p is Definition => p.code == "definition")
      ?.valueString || ""
  );
}

export interface ConceptObservationInputProps<
  TFieldValues extends FieldValues,
  TName extends ConceptPath<TFieldValues>,
> {
  name: TName;
  control?: Control<TFieldValues>;
  disabled?: boolean;
  code: ICodeableConcept;
  validCodedValueSet: string;
  normalCodedValueSet?: string;
  abnormalCodedValueSet?: string;
  criticalCodedValueSet?: string;
  options?: ExpansionContains[];
  active?: boolean;
}
function ConceptObservationInput<
  TFieldValues extends FieldValues = FieldValues,
  TName extends ConceptPath<TFieldValues> = ConceptPath<TFieldValues>,
>({
  name,
  code,
  disabled = false,
  validCodedValueSet,
  active,
  options: providedOptions = [],

  ...props
}: ConceptObservationInputProps<TFieldValues, TName>) {
  const { trigger } = useFormContext();
  const hasDefinedOptions = providedOptions.length > 0;
  const enabled = !hasDefinedOptions && disabled !== true;

  const showCodingCardTooltip = useFeatureFlagEnabled(
    "show-coding-card-tooltip",
  );

  const { data: options = providedOptions, isError } = useQuery({
    ...createValueSetExpansionQuery(validCodedValueSet),
    enabled,
  });
  const valuePath = `${name}.value` as PathsMatching<
    TFieldValues,
    IConceptObservation<null>
  >;
  return (
    <fieldset className="inline-flex items-baseline">
      <Controller<TFieldValues, typeof valuePath>
        name={valuePath}
        render={({ field: { ref, value, onChange, onBlur } }) => (
          <Tooltip enabled={showCodingCardTooltip && !!value}>
            <Tooltip.Trigger>
              <CodingDropdown
                ref={ref}
                prefix={
                  <ObservationCode name={`${name}.code`} code={code} hidden />
                }
                error={isError}
                options={options}
                optionToKey={optionToKey}
                optionToDisplay={optionToDisplay}
                optionToDescription={optionToDescription}
                placeholder={code.text}
                active={active}
                onBlur={onBlur}
                selected={value && conceptAsCoding(value)}
                onSelectedChange={(option) => {
                  onChange(option && codingToConcept(option));
                  trigger(valuePath);
                }}
                disabled={disabled}
              />
            </Tooltip.Trigger>
            <Tooltip.Content className="fill-gray-lighter">
              {value && <CodingTooltip coding={conceptAsCoding(value)} />}
            </Tooltip.Content>
          </Tooltip>
        )}
      />
    </fieldset>
  );
}

export default ConceptObservationInput;
