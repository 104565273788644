import { useState, useCallback } from "react";

export function useElementHeight() {
  const [containerHeight, setContainerHeight] = useState<number | "auto">(
    "auto"
  );
  const updateContainerHeight = useCallback((elem: HTMLElement | null) => {
    if (!elem) return;
    setContainerHeight(elem.offsetHeight);
  }, []);
  return [containerHeight, updateContainerHeight] as const;
}
