import { QueryClient } from "react-query";
import { createFHIRDocumentViewQuery } from "@/services/reports/useFHIRDocumentView";
import { Result } from "./types";
import { Action } from "./Action";
import {
  isWebView2,
  legacy__setFHIRDocument,
} from "@/services/smart-web-messaging/webview2-bridge";
import { sendSMARTWebMessage } from "@/services/smart-web-messaging";

export type ForwardFHIRDocumentStatus =
  | "QUEUED"
  | "RUNNING"
  | "READY"
  | "ERROR";

type Bundle = Record<string | number, unknown>;

class ForwardFHIRDocument extends Action {
  patientId: number;
  reportId: number;
  status: ForwardFHIRDocumentStatus;
  destination: FHIRDocumentDestination;
  client: QueryClient;
  STATUS_MESSAGE_MAP = {
    QUEUED: () => (
      <>Doorsturen van FHIR Document van huidig verslag naar het EPD.</>
    ),
    RUNNING: () => (
      <>
        Bezig met doorsturen van FHIR Document van huidig verslag naar het EPD.
      </>
    ),
    READY: () => (
      <>FHIR Document van huidig verslag succesvol doorgestuurd naar het EPD.</>
    ),
    ERROR: () => (
      <>
        Fout bij doorsturen van FHIR Document van huidig verslag naar het EPD.
      </>
    ),
  };
  retry = undefined;
  constructor({
    client,
    patientId,
    reportId,
    destination,
    status = "QUEUED",
  }: {
    client: QueryClient;
    patientId: number;
    reportId: number;
    status?: ForwardFHIRDocumentStatus;
    destination?: FHIRDocumentDestination;
  }) {
    super();
    this.client = client;
    this.status = status;
    this.patientId = patientId;
    this.reportId = reportId;
    this.destination = destination || new SMARTWebMessagingEndpoint();
  }

  get current() {
    return {
      status: this.status,
      renderMessage: this.STATUS_MESSAGE_MAP[this.status],
    };
  }

  updateStatus(status: ForwardFHIRDocumentStatus) {
    this.status = status;
    this.notify(this.current);
  }

  reset() {
    this.updateStatus("QUEUED");
  }

  async getBundle(): Promise<Bundle> {
    this.updateStatus("RUNNING");
    const document = this.client.fetchQuery<Bundle>(
      createFHIRDocumentViewQuery(this.reportId),
    );
    return document;
  }

  async execute() {
    try {
      const bundle = await this.getBundle();
      bundle["identifier"] = {
        system: "http://tiro.health/fhir/NamingSystem/report-identifier",
        value: this.reportId.toString(),
      };
      if (!this.destination.isAvailable) {
        console.error("Destination not available");
        this.updateStatus("ERROR");
        return [false, new Error("Destination not available")] as Result;
      }
      await this.destination.send(bundle);
      this.updateStatus("READY");
    } catch (error) {
      console.error("Error", error);
      this.updateStatus("ERROR");
      return [false, error] as Result;
    }
    return [true, null] as Result;
  }
}
export default ForwardFHIRDocument;

interface FHIRDocumentDestination {
  isAvailable: boolean;
  send: (bundle: Record<string | number, unknown>) => Promise<void>;
}

class SMARTWebMessagingEndpoint implements FHIRDocumentDestination {
  status: "AVAILABLE" | "UNAVAILABLE";
  constructor() {
    if (isWebView2()) {
      this.status = "AVAILABLE";
    } else {
      this.status = "UNAVAILABLE";
    }
  }
  get isAvailable() {
    return this.status === "AVAILABLE";
  }

  async send(bundle: Record<string, unknown>) {
    await legacy__setFHIRDocument(bundle);
    await sendSMARTWebMessage("fhir.http", { bundle });
  }
}
