import { PlusIcon } from "@heroicons/react/24/outline";
import { Editor, Transforms } from "slate";
import { ReactEditor, useSlateStatic } from "slate-react";
import React, { HTMLAttributes } from "react";
import { ENTRY_COLLECTION, TIME_TIMESTAMP } from "./consts";
import { IEntryCollectionElement } from "./types";
import { useTranslation } from "react-i18next";

export const AddTextEntryButton = () => {
  const { t } = useTranslation();
  const editor = useSlateStatic();
  return (
    <button
      type="button"
      onClick={() => {
        Editor.withoutNormalizing(editor, () => {
          Transforms.insertNodes(
            editor,
            { children: [{ text: "" }] },
            { at: [editor.children.length] }
          );
          Transforms.select(editor, [editor.children.length - 1]);
          ReactEditor.focus(editor as ReactEditor);
        });
      }}
      className="invisible my-2 inline-flex items-center space-x-2 rounded-sm px-2 py-1 text-gray-light transition-all delay-100 hover:bg-gray-light hover:text-primary focus:bg-gray-light focus:text-primary focus:outline-none focus:ring-2 focus:ring-blue-light current-block-focus-within:visible current-block-hover:visible"
    >
      <PlusIcon className="block h-4 w-4 stroke-current" />
      <span className="block text-sm">{t("voeg-tekst-toe")}</span>
    </button>
  );
};

export const AddTimestampedEntryButton = () => {
  const editor = useSlateStatic();
  return (
    <button
      type="button"
      onClick={() => {
        Editor.withoutNormalizing(editor, () => {
          Transforms.insertNodes<IEntryCollectionElement>(
            editor,
            {
              type: ENTRY_COLLECTION,
              layout: "two-column",
              maxChildren: 2,
              children: [
                { type: TIME_TIMESTAMP, children: [{ text: "" }] },
                { children: [{ text: "" }] },
              ],
            },
            { at: [editor.children.length] }
          );
          Transforms.select(
            editor,
            Editor.start(editor, [editor.children.length - 1])
          );
          ReactEditor.focus(editor as ReactEditor);
        });
      }}
      className="invisible my-2 inline-flex items-center space-x-2 rounded-sm px-2 py-1 text-gray-light transition-all delay-100 hover:bg-gray-light hover:text-primary focus:bg-gray-light focus:text-primary focus:outline-none focus:ring-2 focus:ring-blue-light current-block-focus-within:visible current-block-hover:visible"
    >
      <PlusIcon className="block h-4 w-4 stroke-current" />
      <span className="block text-sm">voeg gebeurtenis toe</span>
    </button>
  );
};

export const AddEntryButtonContainer = ({
  children,
}: HTMLAttributes<HTMLDivElement>) => {
  return <div className="w-full px-1">{children}</div>;
};
