import {
  ConceptEntity,
  ConceptEntityModel,
} from "@/data-models/concept-entity";
import { DateEntity, DateEntityModel } from "@/data-models/date-entity";
import {
  DateTimeEntity,
  DateTimeEntityModel,
} from "@/data-models/datetime-entity";
import {
  QuantityModel,
  createQuantityEntity,
} from "@/data-models/quantity-entity";
import { TimeEntity, TimeEntityModel } from "@/data-models/time-entity";
import { is } from "superstruct";
import { ITiroEditor } from "../../Base/editor";
import { ISuggestEditor } from "../Suggestions/SuggestEditor";
import ToggleElement from "../ToggleButtonPlugin/ToggleElement";
import { EntityElement } from "./EntityElement";
import { IAbstractEntityElement } from "./types";

function* serializeEntityToPlainText(
  editor: ITiroEditor,
  node: IAbstractEntityElement,
  inlineSCT: boolean,
) {
  const { entity, state, checked } = node;
  const text = [];
  if (state === "unknown" || checked === false) return;
  if (is(entity, ConceptEntityModel)) {
    text.push(new ConceptEntity(entity).generatePrintText(inlineSCT));
  }
  if (is(entity, QuantityModel)) {
    text.push(createQuantityEntity(entity).toString());
  }
  if (is(entity, DateTimeEntityModel)) {
    text.push(new DateTimeEntity(entity).toShortString());
  }
  if (is(entity, DateEntityModel)) {
    text.push(new DateEntity(entity).toShortString());
  }
  if (is(entity, TimeEntityModel)) {
    text.push(new TimeEntity(entity).toShortString());
  }

  switch (state) {
    case "absent":
      text.push("(afwezig)");
      break;
    case "present":
      text.push("(aanwezig)");
      break;
  }

  yield text.join(" ");
}
const withEntities = <
  T extends ISuggestEditor & ITiroEditor = ISuggestEditor & ITiroEditor,
>(
  editor: T,
) => {
  const { serializePlainText } = editor;
  editor.serializePlainText = (node, inlineSCT) => {
    if (ToggleElement.isToggleGroupElement(node)) {
      return serializeEntityToPlainText(editor, node, inlineSCT);
    }
    if (EntityElement.isEntityElement(node))
      return serializeEntityToPlainText(editor, node, inlineSCT);
    return serializePlainText(node, inlineSCT);
  };
  return editor;
};

export default withEntities;
