import createProtector, {
  CreateProtectorConfig,
  PROTECTOR_CONFIG_DEFAULTS,
} from "@/pages/auth/createProtector";
import { QueryClient } from "react-query";
import { LoaderFunction } from "react-router";
import { UserOut } from "@/services/reports/report-client";

export type CreateLoaderConfig = {
  queryClient?: QueryClient;
} & CreateProtectorConfig;

export type LoaderData = { userData: UserOut };

export const LOADER_CONFIG_DEFAULTS = {
  ...PROTECTOR_CONFIG_DEFAULTS,
};

export default (config: CreateLoaderConfig): LoaderFunction =>
  async ({ request, params, context }): Promise<LoaderData | Response> => {
    try {
      const { queryClient, ...protectorConfig } = config;
      const { currentUserDataOrFalseQuery } = protectorConfig;
      const protector = createProtector({ ...protectorConfig, queryClient });
      const response = await protector({ request, params, context });
      if (response) return response;
      const userData = await queryClient?.fetchQuery(
        currentUserDataOrFalseQuery
      );
      if (!userData) throw new Error("User data not found");
      return { userData };
    } catch (e) {
      throw new Error("User data not found");
    }
  };
