import EntityTooltip from "@/components/base/EntityTooltip";
import { RenderLeafProps } from "slate-react";
import { IEditorPlugin } from "../../Base/types";
import { INLINE_ENTITY_ELEMENT } from "./const";
import {
  RenderInlineEntityElement,
  RenderInlineEntityElementProps,
} from "./RenderInlineEntityElement";
import { InlineEntityElement } from "./InlineEntityElement";

export { EntityStyles } from "./StyledEntityContainers";
import { IInlineEntityElement } from "./types";
export const InlineEntityPlugin: IEditorPlugin<IInlineEntityElement> = {
  renderLeaf: ({ attributes, children, leaf }: RenderLeafProps) => {
    if ("entity" in leaf) {
      return (
        <EntityTooltip entity={(leaf as any).entity}>
          <span
            {...attributes}
            className="decoration-green-600/20 decoration-2 underline-offset-1 transition-opacity hover:underline"
          >
            {children}
          </span>
        </EntityTooltip>
      );
    }
  },
  renderElement: (props: RenderInlineEntityElementProps) => {
    const { element } = props;
    if (InlineEntityElement.isInlineEntityElement(element)) {
      return <RenderInlineEntityElement {...props} />;
    }
  },
  inlineTypes: [INLINE_ENTITY_ELEMENT],
  voidTypes: [INLINE_ENTITY_ELEMENT],
};

export default InlineEntityPlugin;
