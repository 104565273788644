import { ElementTransformer, TextMatchTransformer } from "@lexical/markdown";
import {
  $createParagraphNode,
  $createTextNode,
  $isLineBreakNode,
  $isParagraphNode,
  LineBreakNode,
  ParagraphNode,
  TextNode,
} from "lexical";

export const EMPTY_SPACE_FIX: TextMatchTransformer = {
  dependencies: [TextNode],
  export: (node) => {
    return null;
  },
  regExp: /&ZeroWidthSpace;/,
  importRegExp: /​​&ZeroWidthSpace;/,
  trigger: "",
  replace: (textNode) => {
    if (!textNode?.getParent()) return;
    textNode.replace($createTextNode("hoi"));
  },
  type: "text-match",
};

export const EMPTY_PARAGRAPH_FIX: ElementTransformer = {
  dependencies: [ParagraphNode],
  export: (node) => {
    if (!$isParagraphNode(node)) return null;
    if (node.getChildrenSize() > 0) return null;
    return "&ZeroWidthSpace;";
  },
  regExp: /^&ZeroWidthSpace;$/,
  replace: (parentNode, children, match) => {
    console.log(
      parentNode,
      parentNode.getPreviousSibling()?.getTextContent(),
      parentNode.getNextSibling()?.getTextContent(),
      children,
      match,
    );
    parentNode.append($createTextNode());
  },
  type: "element",
};
