import InlineChromiumBugfix from "@/components/InlineChromiumBugFix";
import { RenderTypedElementProps } from "../TypedElement";
import DateTimeObservationInput from "@/components/inputs/ObservationInput/DateTimeObservationInput/DateTimeObservationInput";
import useSlateInputElement from "@/editor/utils/useSlateInputElement";
import { SettingsPanelPortal } from "@/components/layout/SettingsPanel";
import { Transition } from "@headlessui/react";
import PropertiesForm from "./DateTimePropertiesForm";
import { IDateTimeObservationInputElement } from "./types";

type RenderDateTimeInputElementProps = RenderTypedElementProps & {
  element: IDateTimeObservationInputElement;
};
const RenderDateTimeObservationInput = (
  props: RenderDateTimeInputElementProps
) => {
  const { element, attributes, children } = props;
  const { children: _, name: __, ...other } = element;
  const { name, update, disabled, showProperties, active } =
    useSlateInputElement(element);
  return (
    <span {...attributes} tabIndex={-1}>
      <InlineChromiumBugfix />
      <DateTimeObservationInput
        {...other}
        name={name}
        disabled={disabled}
        active={active}
      />
      {children}
      <SettingsPanelPortal>
        <Transition show={showProperties} enter="ease-in-out duration-75">
          <PropertiesForm onSubmit={update} defaultValues={element} />
        </Transition>
      </SettingsPanelPortal>
      <InlineChromiumBugfix />
    </span>
  );
};

export default RenderDateTimeObservationInput;
