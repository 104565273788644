import { IEditorPlugin } from "../Base/types";
import { TypedElement } from "../TypedElement";
import { CONCEPT_INPUT_ELEMENT, VALUESET_INPUT_ELEMENT } from "./consts";
import RenderDropdownInputElement from "./RenderDropdownInputElement";
import RenderChipInputElement from "./RenderChipInputElement";
import RenderComboBoxInputElement from "./RenderComboBoxInputElement";
import { IConceptInputElement } from "./ConceptInputElement";

const ConceptInputPlugin: IEditorPlugin = {
  renderElement: (props) => {
    const { element } = props;
    if (!TypedElement.isTypedElement(element)) return;
    if (
      element.type == CONCEPT_INPUT_ELEMENT ||
      element.type == VALUESET_INPUT_ELEMENT
    ) {
      switch ((element as IConceptInputElement).inputType) {
        case "chips":
          return (
            <RenderChipInputElement
              {...props}
              element={element as IConceptInputElement<"chips">}
            />
          );
        case "combobox":
          return (
            <RenderComboBoxInputElement
              {...props}
              element={element as IConceptInputElement<"combobox">}
            />
          );
        case "dropdown":
        default:
          return (
            <RenderDropdownInputElement
              {...props}
              element={element as IConceptInputElement<"dropdown">}
            />
          );
      }
    }
    return;
  },
  inlineTypes: [CONCEPT_INPUT_ELEMENT, VALUESET_INPUT_ELEMENT],
  voidTypes: [CONCEPT_INPUT_ELEMENT, VALUESET_INPUT_ELEMENT],
};

export default ConceptInputPlugin;
