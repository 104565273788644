import { IEditorPlugin } from "../Base/types";
import CommentsInput from "@/components/inputs/CommentsInput/CommentsInput";
import { RenderTypedElementProps } from "../TypedElement";
import {
  CommentInputElement,
  ICommentInputElement,
} from "./CommentsInputElement";
import useSlateInputElement from "../utils/useSlateInputElement";

export function RenderCommentInputElement({
  element,
  attributes,
  children,
}: RenderTypedElementProps & { element: ICommentInputElement }) {
  const { name, disabled, active } = useSlateInputElement(element);
  return (
    <span {...attributes}>
      <CommentsInput name={name} disabled={disabled} active={active} />
      {children}
    </span>
  );
}

const CommentsInputPlugin: IEditorPlugin = {
  renderElement({ element, children, attributes }) {
    if (CommentInputElement.isCommentInputElement(element)) {
      return (
        <RenderCommentInputElement element={element} attributes={attributes}>
          {children}
        </RenderCommentInputElement>
      );
    }
  },
  inlineTypes: [CommentInputElement.type],
  voidTypes: [CommentInputElement.type],
};

export default CommentsInputPlugin;
