import client, { PatientOut, ApiError } from "./client";
import { useQuery, UseQueryOptions } from "react-query";
import keyFactory from "./keyFactory";
import { assert, integer } from "superstruct";

export interface UsePatientOptions<TData = PatientOut>
  extends UseQueryOptions<PatientOut, ApiError, TData> {}

export const createPatientQuery = (patientId: number) => {
  return {
    queryKey: keyFactory.patient(patientId),
    queryFn: () => {
      assert(patientId, integer());
      return client.v1.getPatientV1({ patientId });
    },
  };
};

export type CreatePatientQuery = typeof createPatientQuery;

function usePatient<TData = PatientOut>(
  patientId: number,
  options: UsePatientOptions<TData> = {}
) {
  return useQuery<PatientOut, ApiError, TData>({
    ...createPatientQuery(patientId),
    ...options,
  });
}
export default usePatient;
