import { TypedElement } from "../../TypedElement";
import {
  ColumnLayoutEntry,
  FieldEntryElement,
  FreeTextEntry,
  IEntryCollectionElement,
  LeftColumnEntry,
  QAEntryAnswer,
  QAEntryQuestion,
  QuestionAnswerEntry,
  RightColumnEntry,
  SubsectionElement,
  SubtitleElement,
  TimeEntryDescription,
  TimeEntryTimestamp,
  TimestampedEntry,
} from "./types";
import {
  ENTRY_COLLECTION,
  FIELD_ENTRY_ELEMENT,
  FREE_TEXT_ENTRY,
  QA_ANSWER,
  QA_ENTRY,
  QA_QUESTION,
  SUBSECTION_ENTRY,
  SUBTITLE_ENTRY as SUBTITLE_ENTRY,
  TIME_DESCRIPTION,
  TIME_ENTRY,
  TIME_TIMESTAMP,
} from "./consts";

export const EntryElement = {
  ...TypedElement,
  isFreeTextEntry: (n: any): n is FreeTextEntry => {
    return EntryElement.isTypedElement(n) && n.type === FREE_TEXT_ENTRY;
  },
  isQAEntry: (n: any): n is QuestionAnswerEntry => {
    return EntryElement.isTypedElement(n) && n.type === QA_ENTRY;
  },
  isQAEntryQuestion: (n: any): n is QAEntryQuestion => {
    return EntryElement.isTypedElement(n) && n.type === QA_QUESTION;
  },
  isQAEntryAnswer: (n: any): n is QAEntryAnswer => {
    return EntryElement.isTypedElement(n) && n.type === QA_ANSWER;
  },
  isTimestampedEntry: (n: any): n is TimestampedEntry => {
    return EntryElement.isTypedElement(n) && n.type === TIME_ENTRY;
  },
  isTimestampedEntryTimestamp: (n: any): n is TimeEntryTimestamp => {
    return EntryElement.isTypedElement(n) && n.type === TIME_TIMESTAMP;
  },
  isTimestampedEntryDescription: (n: any): n is TimeEntryDescription => {
    return EntryElement.isTypedElement(n) && n.type === TIME_DESCRIPTION;
  },
  isColumnLayoutEntry: (n: any): n is ColumnLayoutEntry => {
    return EntryElement.isQAEntry(n) || EntryElement.isTimestampedEntry(n);
  },
  isLeftColumnEntry: (n: any): n is LeftColumnEntry => {
    return (
      EntryElement.isTimestampedEntryTimestamp(n) ||
      EntryElement.isQAEntryQuestion(n)
    );
  },
  isRightColumnEntry: (n: any): n is RightColumnEntry => {
    return (
      EntryElement.isTimestampedEntryDescription(n) ||
      EntryElement.isQAEntryAnswer(n)
    );
  },
  isColumnEntry: (n: any): n is RightColumnEntry | LeftColumnEntry => {
    return (
      EntryElement.isRightColumnEntry(n) || EntryElement.isLeftColumnEntry(n)
    );
  },
  isSubtitle: (n: any): n is SubtitleElement => {
    return EntryElement.isTypedElement(n) && n.type == SUBTITLE_ENTRY;
  },
  isSubsection: (n: any): n is SubsectionElement => {
    return EntryElement.isTypedElement(n) && n.type == SUBSECTION_ENTRY;
  },
  isCollection: (n: any): n is IEntryCollectionElement => {
    return EntryElement.isTypedElement(n) && n.type == ENTRY_COLLECTION;
  },
  isFieldEntry: (n: any): n is FieldEntryElement => {
    return EntryElement.isTypedElement(n) && n.type === FIELD_ENTRY_ELEMENT;
  },
};
export default EntryElement;
