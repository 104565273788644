import classNames from "classnames";
import React, { useContext } from "react";
import { Cell } from "react-table";
import { RenderElementProps, useFocused, useSelected } from "slate-react";
import { ITableCellElement, ITableRowElement } from ".";
import { useDateReformatter } from "@/editor/legacy/DataFields/useDateReformatter";
import { useQuantityReformatter } from "@/editor/legacy/DataFields/useQuantityReformatter";

export const CellRenderContext = React.createContext<Cell<
  ITableRowElement,
  { type: string }
> | null>(null);

export interface RenderTableCellElementProps extends RenderElementProps {
  element: ITableCellElement;
}

export const RenderTableCellElement = ({
  attributes,
  children,
  element,
}: RenderTableCellElementProps) => {
  const selected = useSelected();
  const focused = useFocused();
  const cell = useContext(CellRenderContext);
  if (!cell) throw Error("Can't render cell outside of a row context.");

  const { dataType, quantity } = element;
  useQuantityReformatter(element, quantity, {
    enabled: dataType === "quantity",
  });
  useDateReformatter(element, { enabled: dataType === "date" });
  return (
    <div
      {...cell?.getCellProps({
        className: "relative max-h-min select-none border border-gray-200",
      })}
    >
      <div
        {...attributes}
        className={classNames(
          "prose relative h-full w-full select-auto px-2 py-1.5 text-sm",
          {
            "z-10 ring-2 ring-blue-light": !!selected && !!focused,
            "text-right": ["quantity", "date"].includes(
              (cell.column as any).dataType && !selected
            ),
          }
        )}
      >
        {children}
      </div>
    </div>
  );
};
