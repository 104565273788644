import { TypedElement, ITypedElement } from "../TypedElement";
import { Infer } from "superstruct";
import { IQuantityField } from "../legacy/DataFields/types";
import { QuantityModel } from "@/data-models/quantity-entity";
import { CALCULATOR_ELEMENT } from "./consts";

export interface ICalculatorElement
  extends ITypedElement,
    Omit<IQuantityField, "children"> {
  type: typeof CALCULATOR_ELEMENT;
  title: string;
  name: string;
  entity?: Infer<typeof QuantityModel>;
  url: string;
}
export const CalculatorElement = {
  ...TypedElement,
  isCalculatorElement: (o: any): o is ICalculatorElement => {
    return TypedElement.isTypedElement(o) && o.type === CALCULATOR_ELEMENT;
  },
};

export default CalculatorElement;
