import { PlusIcon } from "@heroicons/react/24/outline";
import ReportButton from "@/components/base/ReportButton";
import { Dispatch } from "react";
import formatOrdinals from "@/util/formatOrdinals";
import QuestionAnswer from ".";
import { ActionTypes, IQuestionGroup, QAAction } from "../models";
import { IterateQuestions } from "./IterateQuestions";

export interface QuestionGroupProps {
  question: IQuestionGroup;
  path: string[];
  indexPath: number[];
  dispatch: Dispatch<QAAction>;
}
function QuestionGroup({
  question: { template, questions },
  path,
  indexPath,
  dispatch,
}: QuestionGroupProps) {
  const handleAddQuestion = () => {
    dispatch({
      type: ActionTypes.ADD_QUESTION,
      path: [...indexPath, questions.length],
      question: {
        ...template,
        code: {
          ...template.code,
          text: formatOrdinals(questions.length + 1) + " " + template.code.text,
        },
      },
    });
  };
  return (
    <div className="-ml-4">
      {/** Quic fix double margin. Maybe look for better approach */}
      <IterateQuestions questions={questions} path={path} indexPath={indexPath}>
        {({ question: subQuestion, indexPath, path }) => (
          <QuestionAnswer
            question={subQuestion}
            path={path}
            indexPath={indexPath}
            dispatch={dispatch}
          />
        )}
      </IterateQuestions>
      <div className="pb-2 pl-4">
        <ReportButton
          onClick={handleAddQuestion}
          positionIcon="left"
          Icon={<PlusIcon className="inline h-4 w-4" />}
        >
          Voeg &apos;{template.code.text}&apos; toe
        </ReportButton>
      </div>
    </div>
  );
}

export default QuestionGroup;
