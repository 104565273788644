import format from "date-fns/format";
import isValid from "date-fns/isValid";
import parse from "date-fns/parse";
import { nlBE as locale } from "date-fns/locale";
import {
  pattern,
  string,
  type,
  literal,
  union,
  Infer,
  Describe,
} from "superstruct";
import { IAbstractEntity } from "./entity";
export interface IDateEntity {
  type: "date";
  date: string;
}

export const DateEntityModel: Describe<IDateEntity> = type({
  type: literal("date"),
  date: union([
    pattern(
      string(),
      /([0-9]([0-9]([0-9][1-9]|[1-9]0)|[1-9]00)|[1-9]000)(-(0[1-9]|1[0-2])(-(0[1-9]|[1-2][0-9]|3[0-1]))?)?/,
    ),
    literal(""),
  ]),
});

export class DateEntity implements IAbstractEntity {
  readonly type: "date" = "date";
  readonly date: string;
  readonly parsedDate: Date | null;
  constructor(entity: Infer<typeof DateEntityModel>) {
    this.date = entity.date;
    this.parsedDate = parse(this.date, "yyyy-MM-dd", new Date());
  }
  get text() {
    return this.toString() ?? "";
  }
  get isValid() {
    return !isValid(this.parsedDate) || !this.parsedDate;
  }
  get value() {
    return this.date;
  }
  toString() {
    return this.toShortString();
  }
  toShortString() {
    if (!isValid(this.parsedDate) || !this.parsedDate) return null;
    try {
      return format(this.parsedDate, "dd-MM-yyyy", { locale });
    } catch (e) {
      console.warn(e, this);
    }
  }

  toLongString() {
    if (!isValid(this.parsedDate) || !this.parsedDate) return null;
    try {
      return format(this.parsedDate, "PPP", { locale });
    } catch (e) {
      console.warn(e, this);
    }
  }
}
