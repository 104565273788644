import classNames from "classnames";
import React, { HTMLProps, Ref } from "react";

export interface StyledButtonProps extends HTMLProps<HTMLButtonElement> {
  type: "button" | "submit" | "reset" | undefined;
}
const StyledButtonWithoutRef = (
  { className, ...props }: StyledButtonProps,
  ref?: Ref<HTMLButtonElement>
) => (
  <button
    ref={ref}
    className={classNames(
      "rounded border border-transparent bg-transparent px-0.5 py-1.5 text-sm font-medium text-gray-primary hover:bg-gray-200 hover:text-gray-600 focus:outline-blue-200",
      className
    )}
    {...props}
  />
);

const StyledButton = React.forwardRef(StyledButtonWithoutRef);
export default StyledButton;
