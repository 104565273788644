import { QueryClient } from "react-query";
import { ActionFunction } from "react-router";
import keyFactory from "@/services/reports/keyFactory";
import client, { UserUpdate } from "@/services/reports/client";
import { userDataSchema } from "../schemas";
import { ZodFormattedError } from "zod";
import { jumpBack } from "../auth-flow";

export type CreateActionConfig = {
  queryClient: QueryClient;
  mutationFn: typeof client.v1.updateCurrentUserDataV1;
};
export const ACTION_CONFIG_DEFAULTS = {
  mutationFn: (arg: { requestBody: UserUpdate }) =>
    client.v1.updateCurrentUserDataV1(arg),
};
export default (config: CreateActionConfig): ActionFunction =>
  async ({ request }): Promise<ActionData | Response> => {
    const { queryClient, mutationFn } = config;
    const formPayload = Object.fromEntries(await request.formData());
    const result = userDataSchema.safeParse(formPayload);
    if (!result.success) {
      console.error(result.error);
      return { error: result.error.format() };
    }
    const updatedUserData = result.data;
    const userData = await mutationFn({ requestBody: updatedUserData });
    queryClient.setQueryData(keyFactory.currentUserData(), userData);
    return jumpBack(request.url);
  };

export type ActionData = {
  error: ZodFormattedError<
    {
      organisation: string;
      firstName: string;
      lastName: string;
      speciality: string;
    },
    string
  >;
};
