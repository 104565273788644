import React from "react";
import { FocusableBlockContent } from "../useFocusableBlockContent";
import { RenderTemplateBlockContentProps } from "../RenderTemplateBlockContent";

/**
 * Component responsible for:
 * 1. fetching block content from the report server
 * 2. rendering the content using other components
 * 3. writing back changes to the report server
 */
function RenderTableBlockContentWithoutRef(
  props: RenderTemplateBlockContentProps,
  ref?: React.Ref<FocusableBlockContent>,
) {
  return (
    <div className="flex w-full items-center justify-center">
      No designer available for tables.
    </div>
  );
}
const RenderTableBlockContent = React.forwardRef(
  RenderTableBlockContentWithoutRef,
);
export default RenderTableBlockContent;
