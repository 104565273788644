import keyFactory from "./keyFactory";
import { AnyContent } from "@/components/blocks/content";
import client from "./client";
import { Describe, is, string, validate } from "superstruct";
import useCurrentTemplateBlockId from "@/hooks/useCurrentTemplateBlockId";
import { LegacyReadTemplateBlock } from "./content-client";
import useAutoSync, {
  UseAutoSyncOptions,
  UseAutoSyncReturnType,
} from "@/hooks/useAutoSync";
import { useQueryClient } from "react-query";

export type MergeFunc<C> = (remote: C, local: C) => C;

export interface AutoSaveOptions {
  wait: number;
  maxWait?: number;
}

export interface UseTemplateBlockContentOptions<C>
  extends UseAutoSyncOptions<C> {
  templateBlockId?: string;
}

export interface UseTemplateBlockContentReturnType<C>
  extends UseAutoSyncReturnType<C> {}

/**
 * useAutoSyncTemplateBlockContent fetches a block's content given it's id and handles automatic updates for you.
 * It uses optmistic updates as described here: https://react-query-v3.tanstack.com/guides/optimistic-updates
 * @param options
 * @returns
 */
function useAutoSyncTemplateBlockContent<C extends AnyContent>(
  contentModel: Describe<C>,
  options?: UseTemplateBlockContentOptions<C>,
): UseTemplateBlockContentReturnType<C> {
  // determine the block id
  const contextBlockId = useCurrentTemplateBlockId();
  const blockId = options?.templateBlockId ?? contextBlockId;

  if (!is(blockId, string())) {
    throw Error(
      "Either provide a blockId or make sure there is a <CurrentBlockIdContext.Provider/> context.",
    );
  }
  const queryClient = useQueryClient();
  const queryKey = keyFactory.templateBlockContent(blockId);

  const queryFn = async () => {
    const block = await client.v1.getBlockByIdV1({
      blockId,
    });
    const result = validate(block.content, contentModel, {
      coerce: true,
      mask: true,
    });
    if (result[0]) throw result[0];
    return result[1];
  };

  const mutationFn = async (content: C) => {
    const prevBlock = queryClient.getQueryData<LegacyReadTemplateBlock>(
      queryKey.slice(0, -1),
    );
    if (!prevBlock) return;
    const block = await client.v1.putBlockWithIdV1({
      blockId,
      requestBody: { content },
    });
    return block.content;
  };
  // Create a mutator for the block with react-query
  return useAutoSync(queryKey, queryFn, mutationFn, options);
}

export default useAutoSyncTemplateBlockContent;
