import { EntityElement } from "@/editor/legacy/Entities/EntityElement";
import { Node } from "slate";
import { IQuestionAnswer } from "./types";

class QuestionValidator {
  question: IQuestionAnswer;
  constructor(question: IQuestionAnswer) {
    this.question = question;
  }

  isValid() {
    if (!this.question.required) return true;
    if (this.isAnswerEmpty()) return false;
    if (!this.areSubQuestionsValid()) return false;
    return true;
  }

  isAnswerEmpty(): boolean {
    const { answer } = this.question;
    if (!answer) return true;
    let hasEntities = false;
    // check if the answer contains entities by looping nodes in the answer document
    for (const [node] of Node.nodes({ children: answer })) {
      if (EntityElement.isEntityElement(node)) {
        hasEntities = true;
        const entity = EntityElement.entityFromPlainObject(node);

        // an entity that is not checkable should only have a valid entity
        // in order for the answer to be valid
        if (!EntityElement.isCheckable(node) && !entity.isValid) {
          return false;
        }
        // if the entity is checkable at least one should be checked
        if (EntityElement.isCheckable(node) && !entity.isValid) {
          // if it is checked, the answer is not empty
          if (EntityElement.isChecked(node)) return false;
        }
      }
    }

    // when the answer doesn't contain entities, it must have some plain text
    if (!hasEntities) {
      const text = answer.map((n) => Node.string(n).trim()).join("");
      if (text.length == 0) return false;
    }
    return true;
  }

  areSubQuestionsValid(): boolean {
    if (!this.question.questions) return true;

    return this.question.questions.every((q) =>
      new QuestionValidator(q).isValid()
    );
  }
}

export default QuestionValidator;
