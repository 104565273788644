import { RenderType } from "./types";
import { Spinner } from "@/Icons";
import React, { useCallback, useImperativeHandle, useRef } from "react";
import { validate } from "superstruct";
import { IQuestionnaireContent, QuestionnaireContentModel } from "./models";
import { FocusableBlockContent } from "../useFocusableBlockContent";
import Questionnaire from "@/components/questionnaire-v2";
import { useCreateQuestionnaireReducer } from "@/components/questionnaire-v2/createReducer";
import { QAAction } from "@/components/questionnaire-v2/models";
import { RenderTemplateBlockContentProps } from "../RenderTemplateBlockContent";
import useAutoSyncTemplateBlockContent from "@/services/content/useAutoSyncTemplateBlockContent";
import QuestionnaireComposer from "@/components/questionnaire-v2/QuestionnaireComposer";

interface RenderQuestionnaireTemplateBlockProps
  extends RenderTemplateBlockContentProps {
  renderType: RenderType;
}

function RenderQuestionnaireTemplateBlockContentWithoutRef(
  props: RenderQuestionnaireTemplateBlockProps,
  ref: React.Ref<FocusableBlockContent>,
) {
  const { draft, queryResult, setDraft } =
    useAutoSyncTemplateBlockContent<IQuestionnaireContent>(
      QuestionnaireContentModel,
    );

  const reducer = useCreateQuestionnaireReducer();
  const questionnaireRef = useRef<HTMLDivElement>(null);
  const dispatch = useCallback(
    (action: QAAction) => {
      setDraft((draft) => {
        if (!draft) {
          console.log(
            "No draft found for questionnaire block. Skipping updates...",
          );
          return;
        }
        const newQuestions = reducer(draft.questions, action);
        const newDraft: IQuestionnaireContent = {
          ...draft,
          questions: newQuestions,
        };
        return newDraft;
      });
    },
    [reducer, setDraft],
  );

  useImperativeHandle(
    ref,
    () => ({
      focus: () => questionnaireRef.current?.focus(),
    }),
    [],
  );

  if (queryResult.isLoading) {
    return (
      <div className="flex items-center justify-center">
        <Spinner className="mx-2 inline h-6 w-6 animate-spin text-gray-primary" />
      </div>
    );
  }
  const result = validate(draft, QuestionnaireContentModel, {
    mask: true,
    coerce: true,
  });
  if (result[0]) throw result[0];

  const validDraft = result[1];

  return (
    <QuestionnaireComposer
      ref={questionnaireRef}
      {...validDraft}
      dispatch={dispatch}
    />
  );
}

const RenderQuestionnaireBlock = React.forwardRef(
  RenderQuestionnaireTemplateBlockContentWithoutRef,
);
export default RenderQuestionnaireBlock;
