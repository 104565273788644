import {
  GoogleAuthProvider,
  OAuthProvider,
  signInWithCustomToken,
  signInWithEmailAndPassword,
  signInWithPopup,
} from "firebase/auth";
import { getTenantAuth } from "@/services/tenant";

export async function loginEmailPassword(args: {
  email: string;
  password: string;
}) {
  const auth = getTenantAuth();
  const result = await signInWithEmailAndPassword(
    auth,
    args.email,
    args.password
  );
  return result;
}

export async function loginWithGoogle(domain?: string) {
  const auth = getTenantAuth();
  const provider = new GoogleAuthProvider();
  domain && provider.setCustomParameters({ hd: domain });
  const result = await signInWithPopup(auth, provider);

  return result;
}

export async function loginWithMicrosoft(options?: { tenantId?: string }) {
  const { tenantId } = options ?? {};
  const auth = getTenantAuth();
  const provider = new OAuthProvider("microsoft.com");
  if (tenantId) {
    provider.setCustomParameters({ tenant: tenantId });
  }
  const result = await signInWithPopup(auth, provider);
  return result;
}

export async function loginWithIdToken(token: string) {
  const auth = getTenantAuth();
  const result = await signInWithCustomToken(auth, token);
  return result;
}

export async function logout() {
  const auth = getTenantAuth();

  console.debug("Log out " + auth.currentUser?.uid);
  await auth.signOut();
}
