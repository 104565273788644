import React from "react";
import Observable, { IObservable } from "./Observable";
import { Result } from "./types";

export interface Sequenceable {
  next: Sequenceable | null;
}

export interface Executable {
  next: Executable | null;
  execute: () => Promise<Result>;
}
export interface Resettable {
  next: Resettable | null;
  reset: () => void;
}
export type ActionState = {
  status: "HIDDEN" | "QUEUED" | "RUNNING" | "READY" | "ERROR";
  renderMessage: () => React.ReactNode;
};

export type InstructionState = {
  status: "HIDDEN" | "READY";
  renderMessage: () => React.ReactNode;
};

export abstract class Action<TState extends ActionState = ActionState>
  extends Observable<TState>
  implements IObservable<ActionState>, Resettable, Executable
{
  next: Action<ActionState> | Instruction<InstructionState> | null;

  constructor() {
    super();
    this.next = null;
  }
  abstract reset(): void;
  abstract retry?: () => Promise<void>;
  abstract execute(): Promise<Result>;
  abstract current: TState;
}

export abstract class Instruction<
    TState extends InstructionState = InstructionState,
  >
  extends Observable<TState>
  implements IObservable<InstructionState>, Resettable, Executable
{
  status: "HIDDEN" | "READY";
  constructor(status: "HIDDEN" | "READY" = "HIDDEN") {
    super();
    this.status = status;
  }
  next: null = null;
  reset() {
    this.status = "HIDDEN";
    this.notify(this.current);
  }
  async execute(): Promise<Result> {
    console.log("Executing instruction");
    this.status = "READY";
    this.notify(this.current);
    return [true, null] as Result;
  }
  abstract current: TState;
}

export async function run<TAction extends Executable>(action: TAction) {
  await action.execute().then(async ([success, error]) => {
    if (success && action.next) {
      await run(action.next);
    }
    if (error) {
      console.error(error);
    }
  });
}

export async function reset<TAction extends Resettable>(action: TAction) {
  action.reset();
  if (action.next) {
    reset(action.next);
  }
}

export async function retry<TAction extends Action<ActionState>>(
  action: TAction,
) {
  reset(action);
  await run(action);
}
