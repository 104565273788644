import { XMarkIcon } from "@heroicons/react/24/outline";
import classNames from "classnames";
import React from "react";
import { useTranslation } from "react-i18next";
import RemoveWarning from "./RemoveWarning";

type RemoveButtonProps = Omit<
  React.ButtonHTMLAttributes<HTMLButtonElement>,
  "onClick"
> & {
  warningTitle: string;
  warningMessage: string;
  onRemove?: () => void;
  onCancel?: () => void;
};
function RemoveButton_(
  {
    children,
    className,
    onRemove,
    onCancel,
    warningTitle,
    warningMessage,
    ...attributes
  }: RemoveButtonProps,
  ref?: React.Ref<HTMLButtonElement>,
) {
  const { t } = useTranslation();
  const handleRemove = () => {
    setShowWarning(false);
    onRemove?.();
  };
  const handleCancel = () => {
    setShowWarning(false);
    onCancel?.();
  };
  const [showWarning, setShowWarning] = React.useState(false);
  return (
    <>
      <RemoveWarning
        open={showWarning}
        confirm={handleRemove}
        cancel={handleCancel}
        title={warningTitle}
        message={warningMessage}
        cancelMessage={t("cancel")}
        confirmMessage={t("remove")}
      />
      <button
        ref={ref}
        disabled={!onRemove}
        onClick={() => setShowWarning(true)}
        className={classNames(
          "flex h-5 w-5 items-center justify-center text-gray-light hover:text-gray-primary disabled:cursor-not-allowed disabled:text-gray-ultralight disabled:hover:text-gray-ultralight",
          className,
        )}
        {...attributes}
      >
        {children}
        <XMarkIcon className="inline-block fill-current" />
      </button>
    </>
  );
}

const RemoveButton = React.forwardRef(RemoveButton_);

export default RemoveButton;
