import { TimeEntity } from "@/data-models/time-entity";
import React from "react";
import { EntityEditorProps } from ".";
import FormContainer, { FormContainerProps } from "./FormContainer";
export interface TimeEntityEditorProps extends EntityEditorProps<TimeEntity> {}

const TimeFormContainer = React.forwardRef<
  HTMLFormElement,
  FormContainerProps<TimeEntity>
>(FormContainer);
const TimeEntityEditorWithoutRef = (
  props: TimeEntityEditorProps,
  ref: React.Ref<HTMLFormElement>
) => {
  return (
    <TimeFormContainer ref={ref} {...props}>
      {({ register }) => (
        <div>
          <div className="mx-3 text-xs uppercase tracking-wide text-gray-primary">
            <span>Tijdstip</span>
          </div>
          <div className="mt-1 grid grid-cols-3 space-x-2 px-3">
            <input
              {...register("entity.time")}
              placeholder="tijdstip"
              type="time"
              className="col-span-2 mt-1 block rounded-sm border border-gray-200 px-1.5 py-1 focus:border-blue-200 focus:ring-1 focus:ring-blue-200 sm:text-sm"
              autoFocus
            />
          </div>
        </div>
      )}
    </TimeFormContainer>
  );
};
const TimeEntityEditor = React.forwardRef(TimeEntityEditorWithoutRef);
export default TimeEntityEditor;
