import classNames from "classnames";
import React, { HTMLAttributes, Ref } from "react";

export interface UnderlinedProps extends HTMLAttributes<HTMLSpanElement> {
  selected?: boolean;
  inActive?: boolean;
}
function UnderlinedWithoutRef(
  { children, className, inActive, selected, ...attributes }: UnderlinedProps,
  ref: Ref<HTMLSpanElement>
) {
  return (
    <span
      ref={ref}
      className={classNames(
        "cursor-pointer select-auto",
        "underline decoration-gray-light underline-offset-1",
        "selection:bg-blue-100 selection:text-blue-dark selection:decoration-blue-400",
        "hover:text-blue-500 hover:decoration-blue-200  hover:decoration-2",
        "focus:bg-blue-100 focus:text-blue-dark focus:decoration-blue-400",
        {
          "bg-blue-100 text-blue-dark decoration-blue-400 ": selected,
          "text-gray-400": inActive,
        },
        className
      )}
      {...attributes}
    >
      {children}
    </span>
  );
}
const Underlined = React.forwardRef(UnderlinedWithoutRef);
export default Underlined;
