import { CONFIG } from "@/config";
import {
  array,
  boolean,
  Describe,
  nonempty,
  nullable,
  optional,
  string,
  type,
} from "superstruct";
import { Coding, ICoding } from "./value-models";
import { CodingModel } from "./value-models/structs";

export interface IConcept {
  text: string;
  coding: ICoding[];
  language?: string;
  use?: ICoding;
  definition?: string;
  generated?: boolean | null;
  preferred?: boolean | null;
  printText?: string;
}
export const ConceptModel: Describe<IConcept> = type({
  text: string(),
  printText: optional(string()),
  generated: optional(nullable(boolean())),
  preferred: optional(nullable(boolean())),
  coding: nonempty(array(CodingModel)),
  use: optional(CodingModel),
  language: optional(string()),
  definition: optional(string()),
});

export class Concept implements IConcept {
  text: string;
  coding: Coding[];
  generated?: boolean | null;
  preferred?: boolean | null;
  language?: string;
  use?: Coding;
  definition?: string;
  printText?: string;
  constructor(o: IConcept) {
    this.text = o.text;
    this.coding = o.coding.map((c) => Coding.fromObj(c));
    this.generated = o.generated;
    this.preferred = o.preferred;
    this.language = o.language;
    this.definition = o.definition;
    this.use = o.use && Coding.fromObj(o.use);
    this.printText = o.printText;
  }
  get key() {
    return `${this.coding[0].system}#${this.coding[0].code} "${this.text}"`;
  }
  get semanticKey() {
    return `${this.coding[0].system}#${this.coding[0].code}`;
  }
  get semanticContext() {
    return this.coding.find((c) => c.system == CONFIG.SEMANTIC_AXIS_URI);
  }

  get display() {
    return this.coding[0].display;
  }

  renderLanguageFlag() {
    if (this.language == "en") return "🇬🇧";
    if (this.language == "nl") return "🇳🇱";
    return "";
  }

  hasCodes() {
    return this.coding.length > 0;
  }
}
