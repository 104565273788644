import React, { useImperativeHandle, useRef } from "react";

function useFocusableBlockContent(ref?: React.Ref<FocusableBlockContent>) {
  const containerRef = useRef<HTMLDivElement>(null);
  useImperativeHandle(
    ref,
    () => ({
      focus: () => containerRef.current?.focus(),
    }),
    [],
  );
  return containerRef;
}
export default useFocusableBlockContent;

export interface FocusableBlockContent {
  focus: () => void;
}
