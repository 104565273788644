import { useQuery, UseQueryOptions } from "react-query";
import client from "./client";
import keyFactory from "./keyFactory";
type QueryKey = ReturnType<typeof keyFactory.pdfView>;
export interface UsePDFViewOptions
  extends UseQueryOptions<Blob, unknown, Blob, QueryKey> {}

function usePDFView(reportId: number, options: UsePDFViewOptions = {}) {
  return useQuery(
    keyFactory.pdfView(reportId),
    () => client.v1.getPdfV1({ reportId }).then((res) => res.blob()),
    { ...options, enabled: false }
  );
}

export default usePDFView;
