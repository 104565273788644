import { useCallback, useEffect, useState } from "react";

import { Range, Transforms } from "slate";
import { ReactEditor, useSelected, useSlateStatic } from "slate-react";
import classNames from "classnames";
import { is } from "superstruct";
import { RenderTypedElementProps } from "../../TypedElement";
import { IBadgeElement } from "./BadgeElement";
import { RenderBadge } from "../Entities/StyledEntityContainers";
import { QuantityModel } from "@/data-models/quantity-entity";
import { TimeEntityModel, TimeEntity } from "@/data-models/time-entity";
import {
  DateTimeEntityModel,
  DateTimeEntity,
} from "@/data-models/datetime-entity";
import { DateEntityModel, DateEntity } from "@/data-models/date-entity";
import TimeEntityTooltip from "@/components/base/EntityTooltip/TimeEntityTooltip";
import DateEntityTooltip from "@/components/base/EntityTooltip/DateEntityTooltip";
import RenderQuantityBadgeElement from "./RenderQuantityBadgeElement";
import DateTimeEntityTooltip from "@/components/base/EntityTooltip/DateTimeEntityTooltip";
import HoveringEntityEditor from "../../../components/legacy/EntityEditor/HoveringEntityEditor";
import { IEntityElement } from "../Entities/types";

export interface RenderBadgeElementProps extends RenderTypedElementProps {
  element: IBadgeElement;
}

const RenderBadgeElement = ({
  element,
  attributes,
  children,
}: RenderBadgeElementProps) => {
  const { entity } = element;
  const editor = useSlateStatic();
  const selected = useSelected();

  const [showEditor, setShowEditor] = useState(false);
  useEffect(() => {
    const { selection } = editor;
    const isFocused = ReactEditor.isFocused(editor);
    const hasCollapsedSelection =
      selected && selection && Range.isCollapsed(selection);
    if (isFocused && hasCollapsedSelection) {
      setShowEditor(true);
    }
  }, [selected, editor]);

  const handleEntityChange = useCallback(
    (entity: IEntityElement["entity"]) => {
      const path = ReactEditor.findPath(editor, element);
      Transforms.setNodes(editor, { entity }, { at: path });
    },
    [element, editor]
  );
  return (
    <span
      {...attributes}
      className={classNames("inline-block text-left", {
        "cursor-pointer": element.inline === false,
      })}
      contentEditable={false}
    >
      <span>
        <HoveringEntityEditor
          open={showEditor}
          close={() => setShowEditor(false)}
          element={element}
        >
          <span className="inline-flex items-center">
            {is(entity, QuantityModel) && (
              <RenderQuantityBadgeElement
                entity={entity}
                onEntityChange={handleEntityChange}
                openEditor={() => setShowEditor(true)}
              />
            )}
            {is(entity, TimeEntityModel) && (
              <TimeEntityTooltip entity={entity}>
                <RenderBadge
                  active={!!entity.time}
                  onClick={() => setShowEditor((v) => !v)}
                  className="align-bottom text-sm"
                >
                  {new TimeEntity(entity).toString() || "--:--"}
                </RenderBadge>
              </TimeEntityTooltip>
            )}
            {is(entity, DateEntityModel) && (
              <DateEntityTooltip entity={entity}>
                <RenderBadge
                  active={!!entity.date}
                  onClick={() => setShowEditor((v) => !v)}
                  className="align-bottom text-sm"
                >
                  {new DateEntity(entity).toShortString() ?? "dd-mm-jjjj"}
                </RenderBadge>
              </DateEntityTooltip>
            )}
            {is(entity, DateTimeEntityModel) && (
              <DateTimeEntityTooltip entity={entity}>
                <RenderBadge
                  active={!!entity.datetime}
                  onClick={() => setShowEditor((v) => !v)}
                  className="align-bottom text-sm"
                >
                  {new DateTimeEntity(entity).toShortString() ??
                    "dd-mm-jjjj --:--"}
                </RenderBadge>
              </DateTimeEntityTooltip>
            )}
            {children}
          </span>
        </HoveringEntityEditor>
      </span>
    </span>
  );
};

export default RenderBadgeElement;
