import { CONFIG } from "@/config";
import { init as initApm } from "@elastic/apm-rum";
import { PostHogProvider } from "posthog-js/react";
import { Router } from "@/routing/Routes";
import { QueryClientProvider } from "react-query";
import { RouterProvider } from "react-router-dom";
import { queryClient } from "@/services/query";
import { ReactQueryDevtools } from "react-query/devtools";
import InitPostHog from "@/components/InitPostHog";
import InitAPM from "@/components/InitAPM";

initApm({
  distributedTracing: true,
  distributedTracingOrigins: [CONFIG.REPORT_SERVER, CONFIG.CONTENT_ENGINE],
  serviceName: "atticus-frontend",
  serverUrl: import.meta.env.REACT_APP_APM_HOST,
  serverUrlPrefix: import.meta.env.REACT_APP_APM_URL_PREFIX,
  logLevel: "warn",
  serviceVersion: import.meta.env.REACT_APP_VERSION,
  environment: CONFIG.ENV,
});
const PH_OPTIONS = {
  api_host: import.meta.env.REACT_APP_PUBLIC_POSTHOG_HOST,
};

declare global {
  interface Window {
    chrome?: {
      webview: {
        postMessage: (message: Object) => Promise<void>;
        addEventListener: (
          event: "message",
          callback: (arg: MessageEvent) => void,
        ) => void;
        removeEventListener: (
          event: "message",
          callback: (arg: MessageEvent) => void,
        ) => void;
        hostObjects: {
          bridge: Promise<{
            setFHIRDocument: (params: string) => Promise<void>;
          }>;
        };
      };
    };
  }
}

const App = () => {
  return (
    <PostHogProvider
      options={{
        ...PH_OPTIONS,
        disable_session_recording:
          !CONFIG.isDemo && !CONFIG.isStaging && !CONFIG.isDev,
      }}
      apiKey={import.meta.env.REACT_APP_PUBLIC_POSTHOG_KEY}
    >
      <QueryClientProvider client={queryClient}>
        <InitPostHog />
        <InitAPM />
        <RouterProvider router={Router} />
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </PostHogProvider>
  );
};

export default App;
