import { DateTimeEntity } from "@/data-models/datetime-entity";
import React from "react";
import { EntityEditorProps } from ".";
import FormContainer, { FormContainerProps } from "./FormContainer";
export interface DateTimeEntityEditorProps
  extends EntityEditorProps<DateTimeEntity> {}
const DateTimeFormContainer = React.forwardRef<
  HTMLFormElement,
  FormContainerProps<DateTimeEntity>
>(FormContainer);

const DateTimeEntityEditorWithoutRef = (
  props: DateTimeEntityEditorProps,
  ref?: React.Ref<HTMLFormElement>
) => {
  return (
    <DateTimeFormContainer ref={ref} {...props}>
      {({ register }) => (
        <div>
          <div className="mx-3 text-xs uppercase tracking-wide text-gray-primary">
            <span>Datum en tijdstip</span>
          </div>
          <div className="mt-1 grid grid-cols-3 space-x-2 px-3">
            <input
              {...register("entity.datetime")}
              placeholder="datum en tijdstip"
              type="datetime-local"
              className="col-span-2 mt-1 block rounded-sm border border-gray-200 px-1.5 py-1 focus:border-blue-200 focus:ring-1 focus:ring-blue-200 sm:text-sm"
              autoFocus
            />
          </div>
        </div>
      )}
    </DateTimeFormContainer>
  );
};
const DateTimeEntityEditor = React.forwardRef(DateTimeEntityEditorWithoutRef);
export default DateTimeEntityEditor;
