/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Body_validate_valueset_code_r5 } from "../models/Body_validate_valueset_code_r5";
import type { CreateLocalCodeSystem } from "../models/CreateLocalCodeSystem";
import type { CreateSCTCodeSystem } from "../models/CreateSCTCodeSystem";
import type { CreateValueSetDoc } from "../models/CreateValueSetDoc";
import type { DefinedValueSet } from "../models/DefinedValueSet";
import type { ExpandableValueSetDoc } from "../models/ExpandableValueSetDoc";
import type { MutateLocalCodeSystem } from "../models/MutateLocalCodeSystem";
import type { MutateSCTCodeSystem } from "../models/MutateSCTCodeSystem";
import type { MutateValueSetDoc } from "../models/MutateValueSetDoc";
import type { QuestionnaireDoc } from "../models/QuestionnaireDoc";
import type { ReadCodeSystemDoc } from "../models/ReadCodeSystemDoc";
import type { ReadValueSetDoc } from "../models/ReadValueSetDoc";
import type { SearchSet_Annotated_Union_app_models_documents_valueset_v1_ReadValueSetDoc__app_models_documents_codesystem_v1_ReadCodeSystemDoc___FieldInfo_default_Ellipsis__discriminator__resourceType___extra______ } from "../models/SearchSet_Annotated_Union_app_models_documents_valueset_v1_ReadValueSetDoc__app_models_documents_codesystem_v1_ReadCodeSystemDoc___FieldInfo_default_Ellipsis__discriminator__resourceType___extra______";
import type { SearchSet_QuestionnaireDoc_ } from "../models/SearchSet_QuestionnaireDoc_";
import type { SearchSet_ReadCodeSystemDoc_ } from "../models/SearchSet_ReadCodeSystemDoc_";
import type { SearchSet_ReadTemplateBlock_ } from "../models/SearchSet_ReadTemplateBlock_";
import type { SearchSet_ReadTemplateReportDoc_ } from "../models/SearchSet_ReadTemplateReportDoc_";
import type { SearchSet_ReadValueSetDoc_ } from "../models/SearchSet_ReadValueSetDoc_";

import type { CancelablePromise } from "../core/CancelablePromise";
import type { BaseHttpRequest } from "../core/BaseHttpRequest";

export class R5Service {
  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Get All Code Systems
   * @returns SearchSet_ReadCodeSystemDoc_ Successful Response
   * @throws ApiError
   */
  public getAllCodeSystemsR5({
    name,
    title,
    url,
    version,
    offset,
    limit = 100,
    format,
    session,
  }: {
    name?: string;
    title?: string;
    url?: string;
    version?: string;
    offset?: number;
    limit?: number;
    format?: string;
    session?: string;
  }): CancelablePromise<SearchSet_ReadCodeSystemDoc_> {
    return this.httpRequest.request({
      method: "GET",
      url: "/r5/CodeSystem/",
      cookies: {
        session: session,
      },
      query: {
        name: name,
        title: title,
        url: url,
        version: version,
        offset: offset,
        limit: limit,
        _format: format,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Create Code System
   * @returns any Successful Response
   * @throws ApiError
   */
  public createCodeSystemR5({
    requestBody,
    session,
  }: {
    requestBody: CreateLocalCodeSystem | CreateSCTCodeSystem;
    session?: string;
  }): CancelablePromise<any> {
    return this.httpRequest.request({
      method: "POST",
      url: "/r5/CodeSystem/",
      cookies: {
        session: session,
      },
      body: requestBody,
      mediaType: "application/json",
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Codesystem Lookup
   * @returns any Successful Response
   * @throws ApiError
   */
  public codesystemLookupR5({
    system,
    code,
    version,
    format,
    session,
  }: {
    system: string;
    code: string;
    version?: string;
    format?: string;
    session?: string;
  }): CancelablePromise<any> {
    return this.httpRequest.request({
      method: "GET",
      url: "/r5/CodeSystem/$lookup",
      cookies: {
        session: session,
      },
      query: {
        system: system,
        code: code,
        version: version,
        _format: format,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Validate Codesystem Code
   * @returns any Successful Response
   * @throws ApiError
   */
  public validateCodesystemCodeR5({
    code,
    display,
    system = "http://snomed.info/sct",
    session,
  }: {
    code: string;
    display: string;
    system?: string;
    session?: string;
  }): CancelablePromise<any> {
    return this.httpRequest.request({
      method: "GET",
      url: "/r5/CodeSystem/$validate-code",
      cookies: {
        session: session,
      },
      query: {
        code: code,
        display: display,
        system: system,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Get Code System By Id
   * @returns any Successful Response
   * @throws ApiError
   */
  public getCodeSystemByIdR5({
    codesystemId,
    format,
    session,
  }: {
    codesystemId: string;
    format?: string;
    session?: string;
  }): CancelablePromise<any> {
    return this.httpRequest.request({
      method: "GET",
      url: "/r5/CodeSystem/{codesystem_id}",
      path: {
        codesystem_id: codesystemId,
      },
      cookies: {
        session: session,
      },
      query: {
        _format: format,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Update Code System By Id
   * @returns ReadCodeSystemDoc Successful Response
   * @throws ApiError
   */
  public updateCodeSystemByIdR5({
    codeSystemId,
    requestBody,
    session,
  }: {
    codeSystemId: string;
    requestBody: MutateLocalCodeSystem | MutateSCTCodeSystem;
    session?: string;
  }): CancelablePromise<ReadCodeSystemDoc> {
    return this.httpRequest.request({
      method: "PUT",
      url: "/r5/CodeSystem/{code_system_id}",
      path: {
        code_system_id: codeSystemId,
      },
      cookies: {
        session: session,
      },
      body: requestBody,
      mediaType: "application/json",
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Delete Code System By Id
   * @returns any Successful Response
   * @throws ApiError
   */
  public deleteCodeSystemByIdR5({
    codeSystemId,
    session,
  }: {
    codeSystemId: string;
    session?: string;
  }): CancelablePromise<any> {
    return this.httpRequest.request({
      method: "DELETE",
      url: "/r5/CodeSystem/{code_system_id}",
      path: {
        code_system_id: codeSystemId,
      },
      cookies: {
        session: session,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Reindex Code System By Id
   * @returns ReadCodeSystemDoc Successful Response
   * @throws ApiError
   */
  public reindexCodeSystemByIdR5({
    codeSystemId,
    format,
    session,
  }: {
    codeSystemId: string;
    format?: string;
    session?: string;
  }): CancelablePromise<ReadCodeSystemDoc> {
    return this.httpRequest.request({
      method: "POST",
      url: "/r5/CodeSystem/{code_system_id}/$reindex",
      path: {
        code_system_id: codeSystemId,
      },
      cookies: {
        session: session,
      },
      query: {
        _format: format,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Codesystem Lookup By Id
   * @returns any Successful Response
   * @throws ApiError
   */
  public codesystemLookupByIdR5({
    codesystemId,
    code,
    format,
    session,
  }: {
    codesystemId: string;
    code: string;
    format?: string;
    session?: string;
  }): CancelablePromise<any> {
    return this.httpRequest.request({
      method: "GET",
      url: "/r5/CodeSystem/{codesystem_id}/$lookup",
      path: {
        codesystem_id: codesystemId,
      },
      cookies: {
        session: session,
      },
      query: {
        code: code,
        _format: format,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Validate Codesystem Code By Id
   * @returns any Successful Response
   * @throws ApiError
   */
  public validateCodesystemCodeByIdR5({
    session,
  }: {
    session?: string;
  }): CancelablePromise<any> {
    return this.httpRequest.request({
      method: "GET",
      url: "/r5/CodeSystem/{codesystem_id}/$validate-code",
      cookies: {
        session: session,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Render Codesystem Edit Page
   * @returns any Successful Response
   * @throws ApiError
   */
  public renderCodesystemEditPageR5({
    codesystemId,
    session,
  }: {
    codesystemId: string;
    session?: string;
  }): CancelablePromise<any> {
    return this.httpRequest.request({
      method: "GET",
      url: "/r5/CodeSystem/{codesystem_id}/$edit",
      path: {
        codesystem_id: codesystemId,
      },
      cookies: {
        session: session,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Handle Codesystem Edit Action
   * @returns any Successful Response
   * @throws ApiError
   */
  public handleCodesystemEditActionR5({
    codesystemId,
    session,
  }: {
    codesystemId: string;
    session?: string;
  }): CancelablePromise<any> {
    return this.httpRequest.request({
      method: "POST",
      url: "/r5/CodeSystem/{codesystem_id}/$edit",
      path: {
        codesystem_id: codesystemId,
      },
      cookies: {
        session: session,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Get All Valuesets
   * @returns SearchSet_ReadValueSetDoc_ Successful Response
   * @throws ApiError
   */
  public getAllValuesetsR5({
    name,
    title,
    url,
    version,
    offset,
    limit = 100,
    format,
    session,
  }: {
    name?: string;
    title?: string;
    url?: string;
    version?: string;
    offset?: number;
    limit?: number;
    format?: string;
    session?: string;
  }): CancelablePromise<SearchSet_ReadValueSetDoc_> {
    return this.httpRequest.request({
      method: "GET",
      url: "/r5/ValueSet/",
      cookies: {
        session: session,
      },
      query: {
        name: name,
        title: title,
        url: url,
        version: version,
        offset: offset,
        limit: limit,
        _format: format,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Create Valueset
   * @returns ReadValueSetDoc Successful Response
   * @throws ApiError
   */
  public createValuesetR5({
    requestBody,
    session,
  }: {
    requestBody: CreateValueSetDoc;
    session?: string;
  }): CancelablePromise<ReadValueSetDoc> {
    return this.httpRequest.request({
      method: "POST",
      url: "/r5/ValueSet/",
      cookies: {
        session: session,
      },
      body: requestBody,
      mediaType: "application/json",
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Validate Valueset Code
   * @returns any Successful Response
   * @throws ApiError
   */
  public validateValuesetCodeR5({
    url,
    valueSetVersion,
    code,
    system,
    display,
    session,
    requestBody,
  }: {
    /**
     * The canonical URI of the ValueSet
     */
    url: string;
    /**
     * The version of the ValueSet
     */
    valueSetVersion?: string;
    code?: string;
    system?: string;
    display?: string;
    session?: string;
    requestBody?: Body_validate_valueset_code_r5;
  }): CancelablePromise<any> {
    return this.httpRequest.request({
      method: "POST",
      url: "/r5/ValueSet/$validate-code",
      cookies: {
        session: session,
      },
      query: {
        url: url,
        valueSetVersion: valueSetVersion,
        code: code,
        system: system,
        display: display,
      },
      body: requestBody,
      mediaType: "application/json",
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Expand Valueset
   * @returns ExpandableValueSetDoc Successful Response
   * @throws ApiError
   */
  public expandValuesetR5({
    url,
    valueSetVersion,
    filter,
    acronym = false,
    code = true,
    fuzzy = false,
    prefix = true,
    displayLanguage = "nl",
    format,
    offset,
    count = 100,
    session,
  }: {
    url: string;
    valueSetVersion?: string;
    filter?: string;
    acronym?: boolean;
    code?: boolean;
    fuzzy?: boolean;
    prefix?: boolean;
    displayLanguage?: string;
    format?: string;
    offset?: number;
    count?: number;
    session?: string;
  }): CancelablePromise<ExpandableValueSetDoc> {
    return this.httpRequest.request({
      method: "GET",
      url: "/r5/ValueSet/$expand",
      cookies: {
        session: session,
      },
      query: {
        url: url,
        valueSetVersion: valueSetVersion,
        filter: filter,
        acronym: acronym,
        code: code,
        fuzzy: fuzzy,
        prefix: prefix,
        displayLanguage: displayLanguage,
        _format: format,
        offset: offset,
        count: count,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Expand Valueset With Body
   * @returns ExpandableValueSetDoc Successful Response
   * @throws ApiError
   */
  public expandValuesetWithBodyR5({
    url,
    valueSetVersion,
    filter,
    acronym = false,
    code = true,
    fuzzy = false,
    prefix = true,
    displayLanguage = "nl",
    offset,
    count = 100,
    session,
    requestBody,
  }: {
    url?: string;
    valueSetVersion?: string;
    filter?: string;
    acronym?: boolean;
    code?: boolean;
    fuzzy?: boolean;
    prefix?: boolean;
    displayLanguage?: string;
    offset?: number;
    count?: number;
    session?: string;
    requestBody?: DefinedValueSet;
  }): CancelablePromise<ExpandableValueSetDoc> {
    return this.httpRequest.request({
      method: "POST",
      url: "/r5/ValueSet/$expand",
      cookies: {
        session: session,
      },
      query: {
        url: url,
        valueSetVersion: valueSetVersion,
        filter: filter,
        acronym: acronym,
        code: code,
        fuzzy: fuzzy,
        prefix: prefix,
        displayLanguage: displayLanguage,
        offset: offset,
        count: count,
      },
      body: requestBody,
      mediaType: "application/json",
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Get Valueset By Id
   * @returns ReadValueSetDoc Successful Response
   * @throws ApiError
   */
  public getValuesetByIdR5({
    valuesetId,
    session,
  }: {
    valuesetId: string;
    session?: string;
  }): CancelablePromise<ReadValueSetDoc> {
    return this.httpRequest.request({
      method: "GET",
      url: "/r5/ValueSet/{valueset_id}",
      path: {
        valueset_id: valuesetId,
      },
      cookies: {
        session: session,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Update Valueset By Id
   * @returns ReadValueSetDoc Successful Response
   * @throws ApiError
   */
  public updateValuesetByIdR5({
    valuesetId,
    requestBody,
    session,
  }: {
    valuesetId: string;
    requestBody: MutateValueSetDoc;
    session?: string;
  }): CancelablePromise<ReadValueSetDoc> {
    return this.httpRequest.request({
      method: "PUT",
      url: "/r5/ValueSet/{valueset_id}",
      path: {
        valueset_id: valuesetId,
      },
      cookies: {
        session: session,
      },
      body: requestBody,
      mediaType: "application/json",
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Delete Valueset By Id
   * @returns any Successful Response
   * @throws ApiError
   */
  public deleteValuesetByIdR5({
    valuesetId,
    session,
  }: {
    valuesetId: string;
    session?: string;
  }): CancelablePromise<any> {
    return this.httpRequest.request({
      method: "DELETE",
      url: "/r5/ValueSet/{valueset_id}",
      path: {
        valueset_id: valuesetId,
      },
      cookies: {
        session: session,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Reindex Valueset By Id
   * @returns ReadValueSetDoc Successful Response
   * @throws ApiError
   */
  public reindexValuesetByIdR5({
    valuesetId,
    session,
  }: {
    valuesetId: string;
    session?: string;
  }): CancelablePromise<ReadValueSetDoc> {
    return this.httpRequest.request({
      method: "POST",
      url: "/r5/ValueSet/{valueset_id}/$reindex",
      path: {
        valueset_id: valuesetId,
      },
      cookies: {
        session: session,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Expand Valueset By Id
   * @returns ExpandableValueSetDoc Successful Response
   * @throws ApiError
   */
  public expandValuesetByIdR5({
    valuesetId,
    filter,
    acronym = false,
    code = true,
    fuzzy = false,
    prefix = true,
    displayLanguage = "nl",
    offset,
    count = 100,
    format,
    session,
  }: {
    valuesetId: string;
    filter?: string;
    acronym?: boolean;
    code?: boolean;
    fuzzy?: boolean;
    prefix?: boolean;
    displayLanguage?: string;
    offset?: number;
    count?: number;
    format?: string;
    session?: string;
  }): CancelablePromise<ExpandableValueSetDoc> {
    return this.httpRequest.request({
      method: "GET",
      url: "/r5/ValueSet/{valueset_id}/$expand",
      path: {
        valueset_id: valuesetId,
      },
      cookies: {
        session: session,
      },
      query: {
        filter: filter,
        acronym: acronym,
        code: code,
        fuzzy: fuzzy,
        prefix: prefix,
        displayLanguage: displayLanguage,
        offset: offset,
        count: count,
        _format: format,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Render Codesystem Edit Page
   * @returns any Successful Response
   * @throws ApiError
   */
  public renderCodesystemEditPageR51({
    valuesetId,
    session,
  }: {
    valuesetId: string;
    session?: string;
  }): CancelablePromise<any> {
    return this.httpRequest.request({
      method: "GET",
      url: "/r5/ValueSet/{valueset_id}/$edit",
      path: {
        valueset_id: valuesetId,
      },
      cookies: {
        session: session,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Handle Codesystem Edit Action
   * @returns any Successful Response
   * @throws ApiError
   */
  public handleCodesystemEditActionR51({
    valuesetId,
    session,
  }: {
    valuesetId: string;
    session?: string;
  }): CancelablePromise<any> {
    return this.httpRequest.request({
      method: "POST",
      url: "/r5/ValueSet/{valueset_id}/$edit",
      path: {
        valueset_id: valuesetId,
      },
      cookies: {
        session: session,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Get All Resources
   * @returns SearchSet_QuestionnaireDoc_ Successful Response
   * @throws ApiError
   */
  public getAllResourcesR5({
    title,
    url,
    version,
    offset,
    limit = 100,
    format,
    session,
  }: {
    title?: string;
    url?: string;
    version?: string;
    offset?: number;
    limit?: number;
    format?: string;
    session?: string;
  }): CancelablePromise<SearchSet_QuestionnaireDoc_> {
    return this.httpRequest.request({
      method: "GET",
      url: "/r5/Questionnaire/",
      cookies: {
        session: session,
      },
      query: {
        title: title,
        url: url,
        version: version,
        offset: offset,
        limit: limit,
        _format: format,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Create Questionnaire
   * @returns any Successful Response
   * @throws ApiError
   */
  public createQuestionnaireR5({
    requestBody,
    session,
  }: {
    requestBody: QuestionnaireDoc;
    session?: string;
  }): CancelablePromise<any> {
    return this.httpRequest.request({
      method: "POST",
      url: "/r5/Questionnaire/",
      cookies: {
        session: session,
      },
      body: requestBody,
      mediaType: "application/json",
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Get Questionnaire By Id
   * @returns any Successful Response
   * @throws ApiError
   */
  public getQuestionnaireByIdR5({
    questionnaireId,
    format,
    session,
  }: {
    questionnaireId: string;
    format?: string;
    session?: string;
  }): CancelablePromise<any> {
    return this.httpRequest.request({
      method: "GET",
      url: "/r5/Questionnaire/{questionnaire_id}",
      path: {
        questionnaire_id: questionnaireId,
      },
      cookies: {
        session: session,
      },
      query: {
        _format: format,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Update Questionnaire By Id
   * @returns QuestionnaireDoc Successful Response
   * @throws ApiError
   */
  public updateQuestionnaireByIdR5({
    questionnaireId,
    requestBody,
    session,
  }: {
    questionnaireId: string;
    requestBody: QuestionnaireDoc;
    session?: string;
  }): CancelablePromise<QuestionnaireDoc> {
    return this.httpRequest.request({
      method: "PUT",
      url: "/r5/Questionnaire/{questionnaire_id}",
      path: {
        questionnaire_id: questionnaireId,
      },
      cookies: {
        session: session,
      },
      body: requestBody,
      mediaType: "application/json",
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Render Questionnaire Edit Page
   * @returns any Successful Response
   * @throws ApiError
   */
  public renderQuestionnaireEditPageR5({
    questionnaireId,
    session,
  }: {
    questionnaireId: string;
    session?: string;
  }): CancelablePromise<any> {
    return this.httpRequest.request({
      method: "GET",
      url: "/r5/Questionnaire/{questionnaire_id}/$edit",
      path: {
        questionnaire_id: questionnaireId,
      },
      cookies: {
        session: session,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Handle Questionnaire Edit Action
   * @returns any Successful Response
   * @throws ApiError
   */
  public handleQuestionnaireEditActionR5({
    questionnaireId,
    session,
  }: {
    questionnaireId: string;
    session?: string;
  }): CancelablePromise<any> {
    return this.httpRequest.request({
      method: "POST",
      url: "/r5/Questionnaire/{questionnaire_id}/$edit",
      path: {
        questionnaire_id: questionnaireId,
      },
      cookies: {
        session: session,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Get All Resources
   * @returns SearchSet_ReadTemplateBlock_ Successful Response
   * @throws ApiError
   */
  public getAllResourcesR51({
    title,
    url,
    version,
    offset,
    limit = 100,
    format,
    session,
  }: {
    title?: string;
    url?: string;
    version?: string;
    offset?: number;
    limit?: number;
    format?: string;
    session?: string;
  }): CancelablePromise<SearchSet_ReadTemplateBlock_> {
    return this.httpRequest.request({
      method: "GET",
      url: "/r5/TemplateBlock/",
      cookies: {
        session: session,
      },
      query: {
        title: title,
        url: url,
        version: version,
        offset: offset,
        limit: limit,
        _format: format,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Get Template Block By Id
   * @returns any Successful Response
   * @throws ApiError
   */
  public getTemplateBlockByIdR5({
    templateBlockId,
    format,
    session,
  }: {
    templateBlockId: string;
    format?: string;
    session?: string;
  }): CancelablePromise<any> {
    return this.httpRequest.request({
      method: "GET",
      url: "/r5/TemplateBlock/{template_block_id}",
      path: {
        template_block_id: templateBlockId,
      },
      cookies: {
        session: session,
      },
      query: {
        _format: format,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Render Questionnaire Edit Page
   * @returns any Successful Response
   * @throws ApiError
   */
  public renderQuestionnaireEditPageR51({
    templateId,
    session,
  }: {
    templateId: string;
    session?: string;
  }): CancelablePromise<any> {
    return this.httpRequest.request({
      method: "GET",
      url: "/r5/TemplateBlock/{template_id}/$edit",
      path: {
        template_id: templateId,
      },
      cookies: {
        session: session,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Handle Questionnaire Edit Action
   * @returns any Successful Response
   * @throws ApiError
   */
  public handleQuestionnaireEditActionR51({
    templateId,
    session,
  }: {
    templateId: string;
    session?: string;
  }): CancelablePromise<any> {
    return this.httpRequest.request({
      method: "POST",
      url: "/r5/TemplateBlock/{template_id}/$edit",
      path: {
        template_id: templateId,
      },
      cookies: {
        session: session,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Get All Resources
   * @returns SearchSet_ReadTemplateReportDoc_ Successful Response
   * @throws ApiError
   */
  public getAllResourcesR52({
    title,
    url,
    version,
    offset,
    limit = 100,
    format,
    session,
  }: {
    title?: string;
    url?: string;
    version?: string;
    offset?: number;
    limit?: number;
    format?: string;
    session?: string;
  }): CancelablePromise<SearchSet_ReadTemplateReportDoc_> {
    return this.httpRequest.request({
      method: "GET",
      url: "/r5/TemplateReport/",
      cookies: {
        session: session,
      },
      query: {
        title: title,
        url: url,
        version: version,
        offset: offset,
        limit: limit,
        _format: format,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Get Template Report By Id
   * @returns any Successful Response
   * @throws ApiError
   */
  public getTemplateReportByIdR5({
    templateReportId,
    format,
    session,
  }: {
    templateReportId: string;
    format?: string;
    session?: string;
  }): CancelablePromise<any> {
    return this.httpRequest.request({
      method: "GET",
      url: "/r5/TemplateReport/{template_report_id}",
      path: {
        template_report_id: templateReportId,
      },
      cookies: {
        session: session,
      },
      query: {
        _format: format,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Render Questionnaire Edit Page
   * @returns any Successful Response
   * @throws ApiError
   */
  public renderQuestionnaireEditPageR52({
    templateId,
    session,
  }: {
    templateId: string;
    session?: string;
  }): CancelablePromise<any> {
    return this.httpRequest.request({
      method: "GET",
      url: "/r5/TemplateReport/{template_id}/$edit",
      path: {
        template_id: templateId,
      },
      cookies: {
        session: session,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Handle Questionnaire Edit Action
   * @returns any Successful Response
   * @throws ApiError
   */
  public handleQuestionnaireEditActionR52({
    templateId,
    session,
  }: {
    templateId: string;
    session?: string;
  }): CancelablePromise<any> {
    return this.httpRequest.request({
      method: "POST",
      url: "/r5/TemplateReport/{template_id}/$edit",
      path: {
        template_id: templateId,
      },
      cookies: {
        session: session,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Get All Resources
   * @returns SearchSet_Annotated_Union_app_models_documents_valueset_v1_ReadValueSetDoc__app_models_documents_codesystem_v1_ReadCodeSystemDoc___FieldInfo_default_Ellipsis__discriminator__resourceType___extra______ Successful Response
   * @throws ApiError
   */
  public getAllResourcesR53({
    name,
    title,
    url,
    version,
    offset,
    limit = 100,
    format,
    session,
  }: {
    name?: string;
    title?: string;
    url?: string;
    version?: string;
    offset?: number;
    limit?: number;
    format?: string;
    session?: string;
  }): CancelablePromise<SearchSet_Annotated_Union_app_models_documents_valueset_v1_ReadValueSetDoc__app_models_documents_codesystem_v1_ReadCodeSystemDoc___FieldInfo_default_Ellipsis__discriminator__resourceType___extra______> {
    return this.httpRequest.request({
      method: "GET",
      url: "/r5/",
      cookies: {
        session: session,
      },
      query: {
        name: name,
        title: title,
        url: url,
        version: version,
        offset: offset,
        limit: limit,
        _format: format,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }
}
