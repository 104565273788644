const keyFactory = {
  users: () => ["users"],
  userData: (userId: number | undefined | "me") => [
    ...keyFactory.users(),
    userId,
  ],
  hasUserData: (userId: number | undefined | "me") => [
    ...keyFactory.userData(userId),
    "isValid",
  ],
  currentUserData: () => keyFactory.userData("me"),
  allReports: (options?: Record<string, any>) =>
    options ? ["reports", options] : (["reports"] as const),
  report: (reportId: number | undefined, options?: unknown) =>
    [...keyFactory.allReports(), reportId, options] as const,
  reportHeader: (reportId: number | undefined) => [
    ...keyFactory.allReports(),
    reportId,
    "header",
  ],
  reportFooter: (reportId: number | undefined) => [
    ...keyFactory.allReports(),
    reportId,
    "footer",
  ],
  reportBlocks: (reportId: number | undefined) =>
    [...keyFactory.report(reportId), "blocks"] as const,
  reportDispatch: (options = {}) => [
    ...keyFactory.allReports(),
    "dispatch",
    options,
  ],
  reportViews: (reportId: number | undefined) =>
    [...keyFactory.report(reportId), "views"] as const,
  fhirDocumentView: (
    reportId: number | undefined,
    options: Record<string, any> = {},
  ) => [...keyFactory.reportViews(reportId), "fhirDocument", options],
  plainTextView: (reportId: number | undefined) => [
    ...keyFactory.reportViews(reportId),
    "plainText",
  ],
  htmlView(reportId: number | undefined) {
    return [...keyFactory.reportViews(reportId), "htmlView"] as const;
  },
  inlineSCTView: (reportId: number | undefined) => [
    ...keyFactory.reportViews(reportId),
    "inlineSCT",
  ],
  pdfView: (reportId: number | undefined) =>
    [...keyFactory.reportViews(reportId), "pdfView"] as const,
  block: (blockId: number | undefined) => ["blocks", blockId],
  allBlocks: (options = {}) => ["blocks", options],
  patient: (patientId: number | undefined) => ["patient", patientId],
  allPatients: (options = {}) => ["patients", options],
  getFHIRR5Composition: (reportId: number) => ["r5", "Composition", reportId],
  exportTableMeta: (options = {}) => ["export", "tables", options],
  exportTable: (tableId: string | undefined, options = {}) => [
    "export",
    "table",
    tableId,
    options,
  ],
  patientCountDashboard: (options = {}) => [
    "dashboard",
    "patientCount",
    options,
  ],
  groupedExportTablesv2: () => ["v2", "export", "tables"],
  getTablePreviewv2: (tableId: string | undefined, options = {}) => [
    "v2",
    "export",
    "tables",
    tableId,
    options,
  ],
  getTableMetav2: (tableId: string | undefined, options = {}) => [
    "v2",
    "export",
    "tables",
    tableId,
    "meta",
    options,
  ],
};
export default keyFactory;
