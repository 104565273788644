import React from "react";
import { Infer } from "superstruct";
import { ClockIcon } from "@heroicons/react/24/outline";
import Tippy from "@tippyjs/react";
import { TimeEntityModel } from "@/data-models/time-entity";

export interface ITimeEntityTooltipProps {
  entity: Infer<typeof TimeEntityModel>;
  children: React.ReactElement;
}

const TimeEntityTooltip = ({ entity, children }: ITimeEntityTooltipProps) => {
  const { time } = entity;
  return (
    <Tippy
      delay={[500, 50]}
      interactive
      content={
        <div>
          <div className="my-1 flex w-full items-center justify-center font-light">
            <ClockIcon className="mr-1 inline h-4 w-4 stroke-current" />
            <span className="whitespace-nowrap font-normal sm:text-sm">
              {time}
            </span>
          </div>
        </div>
      }
    >
      {children}
    </Tippy>
  );
};
export default TimeEntityTooltip;
