import { IPractitioner } from "@smile-cdr/fhirts/dist/FHIR-R4/interfaces/IPractitioner";
import { IPractitionerRole } from "@smile-cdr/fhirts/dist/FHIR-R4/interfaces/IPractitionerRole";

function practitionerToDisplay(practitioner: IPractitioner): string {
  if (practitioner.name) {
    const prefixes = practitioner.name?.[0].prefix || [];
    const given = practitioner.name?.[0].given || [];
    const givenInitials = given.map((name) => name.slice(0, 1) + ".");
    const family = practitioner.name?.[0].family;
    const display = [...prefixes, ...givenInitials, family]
      .filter(Boolean)
      .join(" ");
    if (display) return display;
  }
  if (practitioner.identifier) {
    const display = practitioner.identifier?.[0].value;
    if (display) return display;
  }
  if (practitioner.id) {
    const display = "Practitioner/" + practitioner.id;
    return display;
  }
  return `Unknown Practitioner`;
}

function practionerRoleToDisplay(practitionerRole: IPractitionerRole): string {
  if (practitionerRole.practitioner?.display) {
    return practitionerRole.practitioner.display;
  }
  if (practitionerRole.id) {
    const display = "PractitionerRole/" + practitionerRole.id;
    return display;
  }
  return `Unknown PractitionerRole`;
}

function otherResourceToDisplay(resource: any): string {
  if (resource.id) {
    const display = resource.resourceType + "/" + resource.id;
    return display;
  }
  return `Unknown ${resource.resourceType}`;
}

export function resourceToDisplay(resource: any): string {
  switch (resource.resourceType) {
    case "Practitioner":
      return practitionerToDisplay(resource as IPractitioner);
    case "PractitionerRole":
      return practionerRoleToDisplay(resource as IPractitionerRole);
    default:
      return otherResourceToDisplay(resource);
  }
}
