import reportClient, { Composition } from "@/services/reports/client";
import keyFactory from "@/services/reports/keyFactory";
import { createFHIRR5CompositionQuery } from "@/services/reports/useComposition";
import { sendSMARTWebMessage } from "@/services/smart-web-messaging";
import { useEffect } from "react";
import { QueryObserver, useQueryClient } from "react-query";

// todo replace this with a generated model
type DocumentOut = {
  entry: {
    resource: {
      id: string;
      title: string;
    };
  }[];
};

type Options = {
  enabled?: boolean;
};
export default function useCompositionSync(
  reportId: number,
  options: Options = {},
) {
  const { enabled } = options;
  const client = useQueryClient();
  useEffect(() => {
    const compositionObserver = new QueryObserver<Composition>(
      client,
      createFHIRR5CompositionQuery({ reportId }),
    );
    // Should we retrieve the composition from the host first and sync with the "existing report" ??
    // Or create an empty composition when mounting
    return compositionObserver.subscribe(({ data: composition }) => {
      if (!composition) {
        console.debug(
          "No FHIR Composition found in query cache, waiting for updates...",
          composition,
        );
        return;
      }
      composition.id = "report";
      composition.title = "Report";
      const payload = { resource: composition };
      sendSMARTWebMessage("scratchpad.update", payload);
    });
  }, [client, reportId, enabled]);
}
