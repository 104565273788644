import type { Data } from "@dnd-kit/core";
import { AnimateLayoutChanges, useSortable } from "@dnd-kit/sortable";
import { useSortableTreeContext } from "./SortableTreeContext";
import { getProjection } from "./utilities";

interface SortableTreeItemChildrenProps extends ReturnType<typeof useSortable> {
  projected: ReturnType<typeof getProjection> | null;
}

interface SortableTreeItemProps<T> {
  id: string;
  data?: Data<T>;
  children: (props: SortableTreeItemChildrenProps) => JSX.Element;
}

const animateLayoutChanges: AnimateLayoutChanges = ({
  isSorting,
  wasDragging,
}) => (isSorting || wasDragging ? false : true);

function SortableTreeItem<T>({ id, data, children }: SortableTreeItemProps<T>) {
  const { projected } = useSortableTreeContext();
  const result = useSortable({
    id,
    data,
    animateLayoutChanges,
  });

  return children({ ...result, projected });
}
export default SortableTreeItem;
