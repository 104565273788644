import { Spinner } from "@/Icons";
import { HTMLProps } from "react";
export interface LoadingDataBlockProps extends HTMLProps<HTMLDivElement> {
  message?: string;
}
function LoadingDataBlock({
  className,
  message,
  ...attributes
}: LoadingDataBlockProps) {
  return (
    <div className={className} {...attributes}>
      <h2 className="text-2xl font-bold leading-7 text-primary sm:truncate sm:text-3xl">
        {message}
        <Spinner className="mx-2 mb-3 inline h-7 w-7 animate-spin" />
      </h2>
    </div>
  );
}
export default LoadingDataBlock;
