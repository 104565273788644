import { IEditorPlugin } from "@/editor/Base/types";
import { MarkdownInputElement } from "./MarkdownInputElement";
import { RenderMarkdownInputElement } from "./RenderMarkdownInputElement";

export const MarkdownPlugin: IEditorPlugin = {
  renderElement({ element, children, attributes }) {
    if (MarkdownInputElement.isMarkdownInputElement(element))
      return (
        <RenderMarkdownInputElement element={element} attributes={attributes}>
          {children}
        </RenderMarkdownInputElement>
      );
  },
  voidTypes: [MarkdownInputElement.type],
};
