import { IEditorPlugin } from "../Base/types";
import { TypedElement } from "../TypedElement";
import {
  CONCEPT_OBSERVATION_INPUT_ELEMENT,
  DATE_TIME_OBSERVATION_INPUT_ELEMENT,
  QUANTITY_COMPONENTS_OBSERVATION_INPUT_ELEMENT,
  QUANTITY_OBSERVATION_INPUT_ELEMENT,
} from "./consts";
import RenderChipsInputElement from "./RenderChipsObservationInput";
import RenderDateTimeObservationInput from "./RenderDateTimeObservationInput";
import RenderDropdownInputElement from "./RenderDropdownObservationInput";
import RenderQuantityComponentsObservationInput from "./RenderQuantityComponentsObservationInput";
import RenderQuantityObservationInput from "./RenderQuantityObservationInput";
import {
  IConceptObservationInputElement,
  IDateTimeObservationInputElement,
  IQuantityComponentsObservationInputElement,
  IQuantityObservationInputElement,
} from "./types";

const ObservationInputPlugin: IEditorPlugin = {
  inlineTypes: [
    QUANTITY_COMPONENTS_OBSERVATION_INPUT_ELEMENT,
    QUANTITY_OBSERVATION_INPUT_ELEMENT,
    DATE_TIME_OBSERVATION_INPUT_ELEMENT,
    CONCEPT_OBSERVATION_INPUT_ELEMENT,
  ],
  voidTypes: [
    QUANTITY_COMPONENTS_OBSERVATION_INPUT_ELEMENT,
    QUANTITY_OBSERVATION_INPUT_ELEMENT,
    DATE_TIME_OBSERVATION_INPUT_ELEMENT,
    CONCEPT_OBSERVATION_INPUT_ELEMENT,
  ],
  renderElement: (props) => {
    const { element } = props;
    if (!TypedElement.isTypedElement(element)) return;
    switch (element.type) {
      case QUANTITY_COMPONENTS_OBSERVATION_INPUT_ELEMENT:
        return (
          <RenderQuantityComponentsObservationInput
            {...props}
            element={element as IQuantityComponentsObservationInputElement}
          />
        );
      case QUANTITY_OBSERVATION_INPUT_ELEMENT:
        return (
          <RenderQuantityObservationInput
            {...props}
            element={element as IQuantityObservationInputElement}
          />
        );
      case DATE_TIME_OBSERVATION_INPUT_ELEMENT:
        return (
          <RenderDateTimeObservationInput
            {...props}
            element={element as IDateTimeObservationInputElement}
          />
        );
      case CONCEPT_OBSERVATION_INPUT_ELEMENT:
        if ((element as IConceptObservationInputElement).inputType == "chips")
          return (
            <RenderChipsInputElement
              {...props}
              element={element as IConceptObservationInputElement}
            />
          );
        // this is the default option for backwards compatibility, so no need to check for it
        //if ((element as IConceptObservationInputElement).inputType == "dropdown")
        return (
          <RenderDropdownInputElement
            {...props}
            element={element as IConceptObservationInputElement}
          />
        );
    }
    return;
  },
};

export default ObservationInputPlugin;
