import {
  ReactEditor,
  RenderElementProps,
  useFocused,
  useSelected,
  useSlateStatic,
} from "slate-react";
import { TripleStateButton } from "./TripleState";
import { SubtitleElement } from "./types";
import { Path } from "slate";
import { EntityStyles } from "../Entities/StyledEntityContainers";
import classNames from "classnames";
export interface RenderSubtitleElementProps extends RenderElementProps {
  element: SubtitleElement;
}

export const RenderSubtitleElement = ({
  element,
  attributes,
  children,
}: RenderSubtitleElementProps) => {
  const editor = useSlateStatic() as ReactEditor;
  const path = ReactEditor.findPath(editor, element);
  const selected = useSelected();
  const focused = useFocused();
  const parentPath = Path.parent(path);
  return (
    <div className="not-prose relative w-full select-none" {...attributes}>
      {/*<s className="font-medium text-xl leading-10 mb-1 tracking-wide">*/}
      <div className="relative flex text-lg font-medium">
        <TripleStateButton
          className="select-none"
          element={element}
          propagate={parentPath}
          contentEditable={false}
        />
        <div
          className={classNames(
            "current-entry mr-8 grow cursor-pointer rounded-sm px-2 text-lg font-medium hover:bg-gray-600/5",
            { "bg-gray-600/5": selected && focused }
          )}
        >
          <EntityStyles.Provider value={{ underline: false }}>
            {children}
          </EntityStyles.Provider>
        </div>
      </div>
      {/*</h3>*/}
    </div>
  );
};
