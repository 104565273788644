import { Menu, Transition } from "@headlessui/react";
import classNames from "classnames";
import React, { Fragment } from "react";

export type PreviewMode = "text" | "pdf" | "fhir";
export const PreviewModeTextEntries: [PreviewMode, string][] = [
  ["text", "Tekstverslag"],
  ["pdf", "PDF"],
  ["fhir", "FHIR Document"],
];
export const PreviewModeTextMap = new Map(PreviewModeTextEntries);

function PreviewMenu({
  setMode,
  children,
}: React.PropsWithChildren<{
  mode: PreviewMode | false;
  setMode: React.Dispatch<React.SetStateAction<PreviewMode | false>>;
}>) {
  return (
    <Menu as="div" className="relative -ml-px block">
      {children}
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute left-0 z-10 mt-2 -ml-1 w-56 origin-top-left rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div className="py-1">
            {PreviewModeTextEntries.map(([mode, text]) => (
              <Menu.Item key={mode}>
                {({ active }) => (
                  <button
                    data-transaction-name={`Export ${text}`}
                    onClick={() => setMode(mode)}
                    className={classNames(
                      active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                      "block w-full px-4 py-2 text-left text-sm"
                    )}
                  >
                    {text}
                  </button>
                )}
              </Menu.Item>
            ))}
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
}

export default PreviewMenu;
