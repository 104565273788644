import { TypedElement } from "../../TypedElement";
import { CHECK_LIST_ELEMENT } from "./consts";
import { ICheckListElement } from "./types";

const CheckListElement = {
  ...Element,
  isCheckListElement(o: any): o is ICheckListElement {
    return TypedElement.isTypedElement(o) && o.type === CHECK_LIST_ELEMENT;
  },
};

export default CheckListElement;
