import { useRouteError } from "react-router";

export default function Error() {
  const error = useRouteError();
  return (
    <div className="flex h-screen items-center justify-center">
      <main className="prose">
        <h2 className="text-2xl font-bold">
          Error while loading existing reports
        </h2>
        <p className="text-sm">
          We tried to load reports given the parameters provide from your EHR
          system, but we were unable to their was an error during the process.
        </p>
        <p className="text-sm">
          Contact your system administrator to fix this integration issue or
          contact us at <a href="mailto:admin@tiro.health">admin@tiro.health</a>
        </p>
        <details>
          <summary> Technical information</summary>
          <div className="pl-4">
            {error && (
              <p className="text-sm">
                <code>{JSON.stringify(error)}</code>
              </p>
            )}
          </div>
        </details>
      </main>
    </div>
  );
}
