import createProtector, {
  PROTECTOR_CONFIG_DEFAULTS,
} from "@/pages/auth/createProtector";
import { ALL_REPORTS_PAGE, REPORT_PAGE } from "@/pages/patients/reports/const";
import { REPORT_EDIT_PAGE } from "@/pages/patients/reports/edit/const";
import { QueryClient } from "react-query";
import { redirect, RouteObject } from "react-router";
import {
  ALL_PATIENTS_PATH,
  PATIENT_DETAILS_PAGE,
  PATIENT_DETAILS_PATH,
} from "./const";
import { NEW_REPORT_PAGE } from "./reports/new/const";
import createEditRoute from "./reports/edit/createRoute";
import createSubmitRoute from "./reports/submit/createRoute";
import createPatientDispatchRoute from "./dispatch/createRoute";
import createReportDispachRoute from "./reports/dispatch/createRoute";
import PatientsOverView from "./overview/PatientsOverview";
import createCurrentSubjectLoader, {
  CURRENT_SUBJECT_ROUTE_ID,
} from "./createCurrentSubjectLoader";
import createCurrentReportLoader, {
  CURRENT_REPORT_ROUTE_ID,
} from "./reports/createCurrentReportLoader";
import Layout from "./Layout";
import NewReportPage from "./reports/new/NewReportPage";
import createAction from "./reports/new/createAction";
import client from "@/services/reports/client";
import { createTemplateReportQuery } from "@/services/useCreateReportFromTemplate";
import HTMLPrintPreview from "@/components/HTMLPrintPreview/HTMLPrintPreview";
import createBlockEditRoute from "./reports/blocks/edit/createRoute";

export default function createRoute(queryClient: QueryClient): RouteObject {
  return {
    path: ALL_PATIENTS_PATH,
    loader: createProtector({ ...PROTECTOR_CONFIG_DEFAULTS, queryClient }),
    element: <Layout />,
    children: [
      createPatientDispatchRoute(queryClient),
      { index: true, element: <PatientsOverView /> },
      {
        id: CURRENT_SUBJECT_ROUTE_ID,
        loader: createCurrentSubjectLoader(queryClient),
        path: PATIENT_DETAILS_PAGE,
        children: [
          { index: true, loader: () => redirect(ALL_REPORTS_PAGE) },
          {
            path: ALL_REPORTS_PAGE,
            children: [
              /** Edit report */
              {
                id: CURRENT_REPORT_ROUTE_ID,
                loader: createCurrentReportLoader(queryClient),
                path: REPORT_PAGE,
                handle: {
                  getBackLinkProps: ({ patientId }: { patientId: string }) => ({
                    to: `/${PATIENT_DETAILS_PATH}`.replace(
                      ":patientId",
                      patientId,
                    ),
                    children: "Verslagen",
                  }),
                },
                children: [
                  { index: true, loader: () => redirect(REPORT_EDIT_PAGE) },
                  createEditRoute(),
                  createSubmitRoute(queryClient),
                  {
                    path: "html-preview",
                    element: <HTMLPrintPreview />,
                  },
                  {
                    path: "blocks",
                    children: [
                      {
                        path: ":blockId",
                        children: [createBlockEditRoute()],
                      },
                    ],
                  },
                ],
              },
              /** Create new report */
              {
                action: createAction({
                  queryClient,
                  createReportFn: (args) => client.v1.createReportV1(args),
                  createTemplateReportQuery,
                }),
                handle: {
                  getBackLinkProps: () => ({
                    children: "Patienten",
                    to: { pathname: `/${ALL_PATIENTS_PATH}` },
                  }),
                },
                path: NEW_REPORT_PAGE,
                element: <NewReportPage />,
              },
              createReportDispachRoute(queryClient),
              { index: true, loader: () => redirect(NEW_REPORT_PAGE) },
            ],
          },
        ],
      },
    ],
  };
}
