import { useCallback } from "react";

/**
 * Multiple mentions pointing to the same entity can be added to the editor.
 * This hook keeps track of the mentions and calls the onAddEntity and onRemoveEntity callbacks
 * while keeping track of mentions referencing the same entity.
 *
 * @param UseMentionCallbacksArg
 */
export function useMentionCallbacks<TEntity extends {}>({
  onAddEntity,
  onRemoveEntity,
  nodeKeyToId,
}: UseMentionCallbacksArg<TEntity>) {
  const onAddMention = useCallback(
    (mentionKey: string, linkId: string, entity: TEntity) => {
      // check if id already exists
      let mentionAlreadyExists = false;
      for (const id of nodeKeyToId.values()) {
        if (id === linkId) mentionAlreadyExists = true;
      }

      // now we have checked if the entity already exists,
      // we can add the current mention
      console.debug("Register mention", mentionKey, linkId);
      nodeKeyToId.set(mentionKey, linkId);
      if (!mentionAlreadyExists) {
        onAddEntity?.(linkId, entity);
      }
    },
    [onAddEntity, nodeKeyToId]
  );

  const onRemoveMention = useCallback(
    (mentionKey: string) => {
      const id = nodeKeyToId.get(mentionKey);
      console.debug("Unregister mention", mentionKey, id);
      // if id is undefined, we don't have to do anything
      if (!id) return;
      const mentionDeleted = nodeKeyToId.delete(mentionKey);
      if (!mentionDeleted) console.warn("Mention was not deleted");

      //check if mention is still present by checking it's id
      let mentionStillPresent = false;
      for (const mentionId of nodeKeyToId.values()) {
        if (mentionId === id) mentionStillPresent = true;
        console.debug("Mention still present", id === mentionId, mentionId);
      }
      if (!mentionStillPresent) {
        onRemoveEntity?.(id);
      }
    },
    [onRemoveEntity, nodeKeyToId]
  );
  return { onAddMention, onRemoveMention, nodeKeyToId };
}
type UseMentionCallbacksArg<TEntity> = {
  onAddEntity: ((id: string, entity: TEntity) => void) | undefined;
  onRemoveEntity: ((id: string) => void) | undefined;
  nodeKeyToId: Map<string, string>;
};
