import { QueryClient, useQuery } from "react-query";
import { LoaderFunction, useRouteLoaderData } from "react-router";
import { PatientOut } from "@/services/reports/report-client";
import { createPatientQuery } from "@/services/reports/usePatient";
import { z } from "zod";

export const CURRENT_SUBJECT_ROUTE_ID = "current-subject";

type Data = { subject: PatientOut };

export default (client: QueryClient): LoaderFunction =>
  async ({ params }): Promise<Data> => {
    const patientId = z.coerce.number().parse(params.patientId);
    const patient = await client.fetchQuery(createPatientQuery(patientId));
    return {
      subject: patient,
    };
  };

export function useCurrentSubject() {
  const { subject: initialSubject } = useRouteLoaderData(
    CURRENT_SUBJECT_ROUTE_ID,
  ) as Data;
  const { data: subject = initialSubject } = useQuery({
    ...createPatientQuery(initialSubject.id),
    initialData: initialSubject,
  });
  return subject;
}
