import { FocusableBlockContent } from "@/components/blocks/content/useFocusableBlockContent";
import { useFormValuesChange } from "@/components/forms/OnChangePlugin";
import React from "react";
import { DefaultValues, useForm } from "react-hook-form";
import TextField from "./TextField";
type FieldValues = { text: string };

export type FreeTextProps<TFieldValues extends FieldValues> = {
  values?: DefaultValues<TFieldValues>;
  placeholder?: string;
  disabled?: boolean;
  onChange: (values: TFieldValues) => void;
  onSubmit: (values: TFieldValues) => void;
};
function FreeText_(
  {
    values,
    placeholder,
    disabled = false,
    onChange,
    onSubmit,
  }: FreeTextProps<FieldValues>,
  ref: React.Ref<FocusableBlockContent>,
) {
  const { control, handleSubmit, watch, register, unregister, getValues } =
    useForm<FieldValues>({
      defaultValues: values,
    });

  useFormValuesChange(onChange, { watch });

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {/** FIXME typing here */}
      <TextField
        name="text"
        control={control}
        formContext={{ register, unregister, getValues }}
        placeholder={placeholder}
        disabled={disabled}
        className="min-h-[4rem]"
      />
    </form>
  );
}
const FreeText = React.forwardRef(FreeText_);
export default FreeText;
