import classNames from "classnames";
import QuantityWithComponents, {
  IQuantityWithComponents,
} from "@/data-models/quantity-entity/QuantityWithComponents";
import React, { useCallback, useMemo } from "react";
import { Resolver } from "react-hook-form";
import { EntityEditorProps } from "..";
import FormContainer, { FormContainerProps } from "../FormContainer";
import { useTranslation } from "react-i18next";

export interface QuantityWithComponentsEditorProps
  extends EntityEditorProps<IQuantityWithComponents> {}

const QuantityWithComponentsFormContainer = React.forwardRef<
  HTMLFormElement,
  FormContainerProps<IQuantityWithComponents>
>(FormContainer);

const QuantityWithComponentsEditorWithoutRef = (
  props: QuantityWithComponentsEditorProps,
  ref?: React.Ref<HTMLFormElement>
) => {
  const { t } = useTranslation();
  const { entity } = props;
  const { interprets } = entity;
  const quantity = useMemo(() => new QuantityWithComponents(entity), [entity]);
  const resolver: Resolver<{ entity: IQuantityWithComponents }> = useCallback(
    ({ entity }) => {
      const parsedQuantity = quantity.parse(entity?.text || "");
      return quantity.isValid
        ? { errors: {}, values: { entity: parsedQuantity } }
        : {
            errors: {
              entity: {
                text: { type: "value", message: "Invalid text input" },
              },
            },
            values: {},
          };
    },
    [quantity]
  );

  return (
    <QuantityWithComponentsFormContainer
      ref={ref}
      {...props}
      entity={quantity}
      resolver={resolver}
      shouldUseNativeValidation
    >
      {({ register, formState: { errors } }) => (
        <div>
          <div className="mx-3 text-xs uppercase tracking-wide text-gray-primary">
            <span>{t("meting")!}</span>
            {interprets && (
              <>
                <span className="px-px">&#8213;</span>
                <span className="relative font-medium tracking-wide hover:underline">
                  {interprets.text}
                </span>
              </>
            )}
          </div>
          <div className="mt-1 flex space-x-1 px-2">
            <input
              type="text"
              {...register("entity.text")}
              placeholder="{t('meetwaarde')}"
              autoFocus
              onFocus={(e: React.FocusEvent<HTMLInputElement>) =>
                e.target.select()
              }
              className={classNames(
                "mt-1 block rounded-sm border border-gray-200 px-1.5 py-1 focus:border-blue-200 focus:ring-1 focus:ring-blue-200 sm:text-sm",
                { "border-red-600 ring-1 ring-red-600": !!errors.entity?.text }
              )}
            />
            {quantity.unitAsString && (
              <div className="mt-1 rounded-sm px-0.5 py-1 sm:text-sm">
                {quantity.unitAsString}
              </div>
            )}
          </div>
          <button tabIndex={-1} type="submit" className="sr-only">
            {t("sla-wijzigingen-op")}
          </button>
          <button tabIndex={-1} type="reset" className="sr-only">
            {t("verwijderen-wijzigingen")}
          </button>
        </div>
      )}
    </QuantityWithComponentsFormContainer>
  );
};
const QuantityWithComponentsEditor = React.forwardRef(
  QuantityWithComponentsEditorWithoutRef
);
export default QuantityWithComponentsEditor;
