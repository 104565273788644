import useStableCallback from "@/hooks/useStableCallback";
import { useEffect } from "react";
import { FieldValues, useFormContext, UseFormWatch } from "react-hook-form";

function useFormValuesChange<TFieldValues extends FieldValues>(
  onChange: ((values: TFieldValues) => void) | undefined,
  { watch: providedWatch }: { watch?: UseFormWatch<TFieldValues> }
) {
  const onChangeStable = useStableCallback(onChange);
  const { watch = providedWatch } = useFormContext() ?? {};

  if (!watch)
    throw new Error(
      "useOnChangePlugin must be used within a FormProvider or provided with a watch function."
    );

  useEffect(() => {
    const subscription = watch((values) => {
      onChangeStable?.(values as TFieldValues);
    });
    return () => subscription.unsubscribe();
  }, [watch, onChangeStable]);
}

export default useFormValuesChange;
