import { RenderElementProps } from "slate-react";
import { IEditorPlugin } from "../../Base/types";
import CheckElement from "./CheckElement";
import CheckListElement from "./CheckListElement";
import { CHECK_ELEMENT } from "./consts";
import RenderCheckElement, {
  RenderCheckElementProps,
} from "./RenderCheckElement";
import RenderCheckListElement, {
  RenderCheckListElementProps,
} from "./RenderCheckListElement";
export { default as withCheck } from "./withCheck";

const CheckPlugin: IEditorPlugin = {
  renderElement(props: RenderElementProps) {
    const { element } = props;
    if (CheckElement.isCheckElement(element)) {
      return <RenderCheckElement {...(props as RenderCheckElementProps)} />;
    }
    if (CheckListElement.isCheckListElement(element)) {
      return (
        <RenderCheckListElement {...(props as RenderCheckListElementProps)} />
      );
    }
  },
  inlineTypes: [CHECK_ELEMENT],
};
export default CheckPlugin;
