import classNames from "classnames";
import { InlineEditForm } from "@/components/controlled/InlineEditForm";
import { Formik } from "formik";
import React, { HTMLAttributes } from "react";
import ReactJson from "react-json-view";

export interface JSONEditPanelProps<T extends Record<string, any>>
  extends Omit<HTMLAttributes<HTMLDivElement>, "onSubmit"> {
  innerRef?: React.Ref<HTMLDivElement>;
  title: string;
  initialValues: T;
  onSubmit: (values: T) => void;
}

// eslint-disable-next-line react/display-name
const JSONEditPanelWithoutRef = <T extends Record<string, any>>({
  className,
  title,
  initialValues,
  onSubmit,
  innerRef,
  ...attrs
}: JSONEditPanelProps<T>) => {
  return (
    <div
      ref={innerRef}
      className={classNames(
        "not-prose z-20 rounded-md bg-white py-2 shadow-lg",
        className
      )}
      {...attrs}
    >
      <Formik<T> initialValues={initialValues} onSubmit={onSubmit}>
        {({ values, setValues }) => (
          <InlineEditForm className="px-1 py-2" isEditing={true}>
            <div>
              <div className="mx-3 text-xs uppercase tracking-wide text-gray-primary">
                <h4>{title}</h4>
              </div>

              <div className="mt-3 px-3">
                <ReactJson
                  src={values}
                  onEdit={({ updated_src: values }) => setValues(values as T)}
                  onAdd={({ updated_src: values }) => setValues(values as T)}
                  onDelete={({ updated_src: values }) => setValues(values as T)}
                />
              </div>

              <div className="mt-2 px-3">
                <button
                  type="submit"
                  className="rounded-md bg-gray-ultralight px-3 py-2 text-gray-dark sm:text-sm"
                >
                  Opslaan
                </button>
              </div>
            </div>
          </InlineEditForm>
        )}
      </Formik>
    </div>
  );
};

const JSONEditPanel = React.forwardRef<HTMLDivElement, JSONEditPanelProps<any>>(
  (props, ref) => <JSONEditPanelWithoutRef innerRef={ref} {...props} />
);
export default JSONEditPanel;
