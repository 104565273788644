import { useParams } from "react-router";
import { coerce, create, integer, string } from "superstruct";

export default function useCurrentReportId() {
  const { reportId } = useParams<{ reportId: string }>();
  return create(
    reportId,
    coerce(integer(), string(), (v) => parseInt(v)),
  );
}
