import debounce from "lodash.debounce";
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  MutationKey,
  QueryKey,
  useMutation,
  UseMutationOptions,
  UseMutationResult,
  useQuery,
  useQueryClient,
  UseQueryResult,
} from "react-query";
import { AnyContent } from "@/components/blocks/content";
import { func, is } from "superstruct";
import { useRegisterSaveAll } from "./useSave";

export type MergeFunc<C> = (remote: C, local: C) => C;

export interface AutoSaveOptions {
  wait: number;
  maxWait?: number;
}

export interface UseAutoSyncOptions<C> {
  mutationKey?: MutationKey;
  autoSaveOptions?: AutoSaveOptions;
  alertIfUnsavedChanges?: boolean;
  merge?: MergeFunc<C>;
  enabled?: boolean;
  autoSaveDraft?: boolean;
  defaultDraft?: C;
  dependentQueries?: QueryKey[];
  onMutationSucces?: UseMutationOptions<
    C,
    Error,
    C,
    { prevContent: C }
  >["onSuccess"];
}

export interface UseAutoSyncReturnType<C> {
  draft?: C;
  setDraft: Dispatch<SetStateAction<C | undefined>>;
  save: () => void;
  saveDebounced: () => void;
  queryResult: UseQueryResult<C, Error>;
  mutationResult: UseMutationResult<C, Error, C, { prevContent: C }>;
}

/**
 * Empty function used to avoid the overhead of `lodash.debounce` if autoSaveOptions are not used.
 */
const EmptyDebounceFunc = Object.assign(() => {}, {
  flush: () => {},
  cancel: () => {},
});

/**
 * useAutoSync handles automatic updates for you.
 * It uses optmistic updates as described here: https://react-query-v3.tanstack.com/guides/optimistic-updates
 * @param options
 * @returns
 */
function useAutoSync<C extends AnyContent>(
  queryKey: QueryKey,
  queryFn: () => Promise<C>,
  mutationFn: (content: C) => Promise<C>,
  options?: UseAutoSyncOptions<C>,
): UseAutoSyncReturnType<C> {
  // determine the block id
  const {
    mutationKey,
    autoSaveOptions = { wait: 1000, maxWait: 5000 },
    alertIfUnsavedChanges = true,
    merge,
    enabled = true,
    onMutationSucces,
    defaultDraft,
    autoSaveDraft = true,
    dependentQueries = [],
  } = options ?? {};
  const queryKeyRef = useRef(queryKey);
  const queryClient = useQueryClient();

  const [draft, setDraft] = useState<C | undefined>(defaultDraft);
  // create a stable ref to the draft so we can memoize the save function
  const draftRef = useRef<C | undefined>(undefined);
  draftRef.current = draft;

  // Retrieve the block with react-query
  const queryResult = useQuery<C, Error>(queryKey, queryFn, {
    enabled,
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60, // 1 minute
  });

  // Create a mutator for the block with react-query
  const mutationResult = useMutation<C, Error, C, { prevContent: C }>(
    mutationFn,
    {
      mutationKey,
      onMutate(draft) {
        // optimistically update the cache
        queryClient.cancelQueries(queryKey);
        const prevContent = queryClient.getQueryData<C>(queryKey);
        if (!prevContent) return; // no optmistic update possible
        queryClient.setQueryData(queryKey, draft);
        // optimistically clear our draft state
        setDraft(undefined);
        return { prevContent };
      },
      onError(error, draft, context) {
        const { prevContent } = context ?? {};
        if (prevContent) queryClient.setQueryData(queryKey, prevContent);
        // reset the draft to the last known draft unless the user made more changes
        if (draft !== undefined) {
          setDraft(draft);
        }
      },
      onSuccess(data, variables, context) {
        queryClient.setQueryData(queryKey, data);
        onMutationSucces && onMutationSucces(data, variables, context);
      },
      onSettled() {
        queryClient.invalidateQueries(queryKey);
        dependentQueries.forEach((queryKey) =>
          queryClient.invalidateQueries(queryKey),
        );
      },
    },
  );
  const { mutate } = mutationResult;

  // return a stable save function
  const save = useCallback(() => {
    if (draftRef.current !== undefined) {
      mutate(draftRef.current);
    }
  }, [mutate]);

  // memoize a debounced save function
  const saveDebounced = useMemo(
    () =>
      autoSaveOptions?.wait === undefined
        ? EmptyDebounceFunc
        : debounce(save, autoSaveOptions?.wait, {
            // only pass maxWait to the options if maxWait is defined
            // if maxWait is undefined it is set to 0
            ...(autoSaveOptions?.maxWait !== undefined
              ? { maxWait: autoSaveOptions?.maxWait }
              : {}),
          }),
    [autoSaveOptions?.maxWait, autoSaveOptions?.wait, save],
  );

  // create a function which saves and cancels the debounced save
  const saveAndCancelDebounced = useMemo(
    () => () => {
      saveDebounced.cancel();
      save();
    },
    [save, saveDebounced],
  );

  // clean up saveDebounced on unmount to avoid leaks
  useEffect(() => {
    const prevSaveDebounced = saveDebounced;
    return () => {
      prevSaveDebounced.cancel();
    };
  }, [saveDebounced]);

  // call saveDebounced when the draft changes
  useEffect(() => {
    // check that autoSave is enabled and there are local changes to save
    if (
      autoSaveOptions?.wait !== undefined &&
      draft !== undefined &&
      autoSaveDraft
    ) {
      saveDebounced();
    }
  }, [saveDebounced, draft, autoSaveOptions?.wait, autoSaveDraft]);

  // confirm before the user leaves if the draft value isn't saved
  useEffect(() => {
    const shouldPreventUserFromLeaving =
      draft !== undefined && alertIfUnsavedChanges;

    const alertUserIfDraftIsUnsaved = (e: BeforeUnloadEvent) => {
      console.log({ shouldPreventUserFromLeaving });
      if (shouldPreventUserFromLeaving) {
        // Cancel the event
        e.preventDefault(); // If you prevent default behavior in Mozilla Firefox prompt will always be shown
        // Chrome requires returnValue to be set
        e.returnValue = "";
      } else {
        // the absence of a returnValue property on the event will guarantee the browser unload happens
        delete e["returnValue"];
      }
    };

    // only add beforeUnload if there is unsaved work to avoid performance penalty
    if (shouldPreventUserFromLeaving) {
      window.addEventListener("beforeunload", alertUserIfDraftIsUnsaved);
    }
    // document.addEventListener("visibilitychange", saveDraftOnVisibilityChange);
    return () => {
      if (shouldPreventUserFromLeaving) {
        window.removeEventListener("beforeunload", alertUserIfDraftIsUnsaved);
      }
      // document.removeEventListener("visibilitychange", saveDraftOnVisibilityChange);
    };
  }, [alertIfUnsavedChanges, draft, saveAndCancelDebounced]);

  useEffect(() => {
    const serverData = queryResult.data;
    if (serverData !== undefined && merge !== undefined) {
      setDraft((localData) => {
        if (localData !== undefined) {
          return merge(serverData, localData);
        }
      });
    }
  }, [merge, queryResult.data]);

  const setDraftWrapper = useCallback<Dispatch<SetStateAction<C | undefined>>>(
    (updater) => {
      if (is(updater, func())) {
        const updaterFn = updater as (prevValue: C) => C;
        setDraft((draft) => {
          const prevValue =
            draft ?? queryClient.getQueryData<C>(queryKeyRef.current);
          if (prevValue === undefined) return draft;
          return updaterFn(prevValue);
        });
      } else {
        setDraft(updater);
      }
    },
    [queryClient, queryKeyRef],
  );

  useRegisterSaveAll(save);

  return {
    draft: draft ?? queryResult.data,
    setDraft: setDraftWrapper,
    save: saveAndCancelDebounced,
    saveDebounced,
    mutationResult,
    queryResult,
  };
}

export default useAutoSync;
