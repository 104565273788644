import MedicalIcon from "@/icons/MedicalIcon";
import { useQuery } from "react-query";
import { Form, useNavigation, useParams } from "react-router-dom";
import { createTemplateQuery } from "@/services/content/useTemplates";
import { SPECIALTY_TO_PATHWAYS } from "@/pages/auth/sign-up/SpecialismListBox";
import { useMemo } from "react";
import { is, string } from "superstruct";
import { useContentClient } from "@/services/content/ContentClientContext";
import TemplateTile from "@/components/template-tiles/TemplateTile";

export interface TemplateItem {
  id: string;
  title: string;
  description: string;
  icon?: MedicalIcon;
  color?: "gray" | "blue";
  pathway?: string[];
}

export type PickATemplateFormProps = { specialty: string | undefined };

const PickATemplateForm = ({ specialty }: PickATemplateFormProps) => {
  const { state, formData } = useNavigation();
  const client = useContentClient();
  const { data: templates = [] } = useQuery({
    ...createTemplateQuery(client),
  });
  const filteredTemplates = useMemo(
    () => filterTemplates(templates, specialty),
    [templates, specialty],
  );
  const { encounter } = useParams<"encounter">(); // Storing the encounter as a url param seems like a bad idea
  return (
    <Form className="block" method="POST">
      {encounter && <input type="hidden" name="encounter" value={encounter} />}
      <fieldset disabled={state == "submitting"}>
        <ul className="shadow-xs mt-6 grid grid-cols-1 gap-6 rounded-md border border-blue-ultralight bg-white p-6 sm:grid-cols-2">
          {filteredTemplates.map((template) => (
            <TemplateTile
              id={template.id}
              title={template.title}
              description={template.description}
              icon={template.icon}
              key={template.id}
              version={template.version}
              status={template.status}
              isSubmitting={formData?.get("template") === template.id}
            />
          ))}
          <li className="relative flow-root rounded-md border border-dashed border-content-primary border-opacity-40 p-4">
            <div className="relative -m-2 flex items-center justify-center space-x-4 rounded-xl p-4 focus-within:ring-2 focus-within:ring-blue-500">
              <a
                href="mailto:info@tiro.health?subject=Aanvraag%20nieuwe%20template"
                className="flex items-center justify-center rounded-md border  border-content-primary bg-fill-secondary px-8 py-3 font-primary text-content-primary"
              >
                <span>
                  Hier uw template? Contacteer ons en wij voegen het toe!
                </span>
              </a>
            </div>
          </li>
        </ul>
      </fieldset>
    </Form>
  );
};
export default PickATemplateForm;

function filterTemplates(templates: any[], specialty?: string) {
  if (!specialty) {
    return templates;
  }
  const pathways = SPECIALTY_TO_PATHWAYS[specialty] ?? [];
  return templates.filter((t) => {
    if (pathways.length == 0) {
      return false;
    }
    if (pathways.length == 1 && pathways[0] == "all") {
      return true;
    }
    if (is(t.pathway, string())) {
      t.pathway = [t.pathway];
    }
    return t.pathway && t.pathway.some((p: string) => pathways.includes(p));
  });
}
