enum ErrorCodes {
  UNAUTHENTICATED_WHILE_EMBEDDED = "UNAUTHENTICATED_WHILE_EMBEDDED",
}
export class UnauthenticatedWhileEmbeddedError extends Error {
  code: ErrorCodes;
  constructor() {
    super("UnauthenticatedWhileEmbeddedError");
    this.code = ErrorCodes.UNAUTHENTICATED_WHILE_EMBEDDED;
  }

  get message() {
    return "Unauthenticated while in embedded mode.";
  }
}
