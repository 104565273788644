import useCurrentReportId from "@/hooks/useCurrentReportId";
import { useMemo } from "react";
import usePDFView from "@/services/reports/usePDFView";

/**
 * useDownloadPDFLink
 * @returns a function that generates a link to download the pdf of the current note
 */
function useDownloadPDFLink() {
  const reportId = useCurrentReportId();

  const { data } = usePDFView(reportId);

  const link = useMemo(() => data && URL.createObjectURL(data), [data]);
  return link;
}
export default useDownloadPDFLink;
