import { Infer, literal, pattern, string, type, union } from "superstruct";
import { nlBE as locale } from "date-fns/locale";
import format from "date-fns/format";
import isValid from "date-fns/isValid";
import parse from "date-fns/parse";
import { IAbstractEntity } from "./entity";

export interface ITimeEntity {
  type: "time";
  time: string;
}

export const TimeEntityModel = type({
  type: literal("time"),
  time: union([
    pattern(
      string(),
      /([01][0-9]|2[0-3]):[0-5][0-9](:([0-5][0-9]|60)(\.[0-9]+)?)?/,
    ),
    literal(""),
  ]),
});

export class TimeEntity implements IAbstractEntity {
  readonly type: "time" = "time";
  readonly time: string;
  readonly parsedTime: Date | null;
  constructor(entity: Infer<typeof TimeEntityModel>) {
    this.time = entity.time;
    this.parsedTime =
      this.time.length > 0 ? parse(this.time, "HH:mm", new Date()) : null;
  }
  get text() {
    return this.toString() ?? "";
  }
  get isValid() {
    return !isValid(this.parsedTime) || !this.parsedTime;
  }
  get value() {
    return this.time;
  }
  toString() {
    return this.toShortString();
  }
  toShortString() {
    if (!isValid(this.parsedTime) || !this.parsedTime) return "";
    try {
      return format(this.parsedTime, "p", { locale });
    } catch (e) {
      console.warn(e, this);
      return "";
    }
  }
  toLongString() {
    if (!isValid(this.parsedTime) || !this.parsedTime) return null;
    try {
      return format(this.parsedTime, "ppp", { locale });
    } catch (e) {
      console.warn(e, this);
      return null;
    }
  }
}
