import { HTMLProps } from "react";
import classNames from "classnames";
import { Tab } from "@headlessui/react";
import { useQueries } from "react-query";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";

import keyFactory from "@/services/reports/keyFactory";
import client, { SimpleViewDefinition } from "@/services/reports/client";
import { Spinner } from "@/Icons";

interface TableProps extends HTMLProps<HTMLDivElement> {
  tableIds: string[];
  groupId: string | null;
}

function Table({ tableIds, groupId, className, ...props }: TableProps) {
  const metaResults = useQueries(
    tableIds.map((tableId) => ({
      queryKey: keyFactory.getTableMetav2(tableId),
      queryFn: () => client.v2.getTableMetaV2({ tableId }),
      staleTime: 1000 * 60 * 60, // 1 hour
    })),
  );
  const results = useQueries(
    tableIds.map((tableId) => ({
      queryKey: keyFactory.getTablePreviewv2(tableId, { groupId }),
      queryFn: () =>
        client.v2.getTableByIdV2({ tableId, report: groupId || undefined }),
      staleTime: 1000 * 60 * 60, // 1 hour
    })),
  );
  return (
    <div className={classNames("ph-no-capture relative", className)} {...props}>
      <div className="h-full w-full flex-col overflow-x-scroll">
        <Tab.Group defaultIndex={0} key={tableIds.join(",")}>
          <Tab.List className="flex space-x-2 p-1">
            {tableIds.map((tableId, index) => (
              <Tab
                key={tableId}
                className={({ selected }) =>
                  classNames(
                    {
                      "bg-blue-100 text-blue-700": selected,
                      "text-gray-500 hover:text-gray-700": !selected,
                    },
                    "text-ellipsis whitespace-nowrap rounded-sm px-2 py-1 text-sm font-medium",
                  )
                }
              >
                {metaResults[index].isLoading == true
                  ? "loading..."
                  : metaResults[index].isError
                    ? "Error"
                    : metaResults[index].data?.name}
              </Tab>
            ))}
          </Tab.List>
          <Tab.Panels>
            {tableIds.map((tableId, index) => {
              const table = results[index].data;
              if (!table) return null;
              return (
                <Tab.Panel
                  key={tableId}
                  unmount={false}
                  className="ag-theme-alpine absolute bottom-0 top-9 w-full"
                >
                  <AgGridReact
                    rowData={table} // Row Data for Rows
                    columnDefs={
                      metaResults[index].data &&
                      getColumns(metaResults[index].data!)
                    } // Column Defs for Columns
                  />
                </Tab.Panel>
              );
            })}
          </Tab.Panels>
        </Tab.Group>
      </div>
    </div>
  );
}

export default Table;

function getColumns(
  viewDefinition: SimpleViewDefinition,
  extraFields: string[] = ["Template", "Block", "Created", "Patient", "Author"],
) {
  return extraFields
    .map((col) => ({
      headerName: col,
      field: col,
    }))
    .concat(
      viewDefinition.select?.[0].column.map((col: { name: string }) => ({
        headerName: col.name,
        field: col.name,
      })),
    );
}

export function TableLoader() {
  return (
    <div className="mt-2 flex flex-grow items-center justify-center self-stretch overflow-hidden rounded-md border border-blue-ultralight bg-white shadow-sm">
      <Spinner className="m-auto h-8 w-8 animate-spin" />
    </div>
  );
}
