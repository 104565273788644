import InlineChromiumBugfix from "@/components/InlineChromiumBugFix";
import { RenderTypedElementProps } from "../TypedElement";
import { SettingsPanelPortal } from "@/components/layout/SettingsPanel";
import { Transition } from "@headlessui/react";
import PropertiesForm from "./QuantityPropertiesForm";
import useSlateInputElement from "@/editor/utils/useSlateInputElement";
import { IQuantityComponentsObservationInputElement } from "./types";
import QuantityObservationInputGroup from "@/components/inputs/ObservationInput/QuantityObservationInput/InputGroup";

type RenderQuantityComponentsInputElementProps = RenderTypedElementProps & {
  element: IQuantityComponentsObservationInputElement;
};
const RenderQuantityComponentsObservationInput = (
  props: RenderQuantityComponentsInputElementProps
) => {
  const { element, attributes, children } = props;
  const { children: _, name: __, ...other } = element;
  const { name, update, disabled, showProperties, active } =
    useSlateInputElement(element);
  return (
    <span {...attributes} tabIndex={-1}>
      <InlineChromiumBugfix />
      <QuantityObservationInputGroup
        name={name}
        active={active}
        disabled={disabled}
        {...other}
      />
      {children}
      <InlineChromiumBugfix />
      <SettingsPanelPortal>
        <Transition show={showProperties} enter="ease-in-out duration-75">
          <PropertiesForm onSubmit={update} defaultValues={element} />
        </Transition>
      </SettingsPanelPortal>
    </span>
  );
};

export default RenderQuantityComponentsObservationInput;
