import React from "react";
import { RenderElementProps } from "slate-react";
import { RenderColumnEntry } from "./Columns";
import { QuestionAnswerEntry } from "./types";

export interface RenderQuestionAnswerEntryProps extends RenderElementProps {
  element: QuestionAnswerEntry;
}

export const RenderQuestionAnswerEntry = (
  props: RenderQuestionAnswerEntryProps
) => {
  return <RenderColumnEntry {...props} />;
};
