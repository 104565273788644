/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Body_login } from "../models/Body_login";

import type { CancelablePromise } from "../core/CancelablePromise";
import type { BaseHttpRequest } from "../core/BaseHttpRequest";

export class DefaultService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Get Service Status
   * Check if Elasticsearch is running
   * @returns any Successful Response
   * @throws ApiError
   */
  public getServiceStatus({
    format,
    session,
  }: {
    format?: string;
    session?: string;
  }): CancelablePromise<any> {
    return this.httpRequest.request({
      method: "GET",
      url: "/",
      cookies: {
        session: session,
      },
      query: {
        _format: format,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Info
   * Info about the API and author itsef
   * @returns any Successful Response
   * @throws ApiError
   */
  public info(): CancelablePromise<any> {
    return this.httpRequest.request({
      method: "GET",
      url: "/info",
    });
  }

  /**
   * Login
   * Login with Google
   * @returns any Successful Response
   * @throws ApiError
   */
  public login({
    requestBody,
  }: {
    requestBody: Body_login;
  }): CancelablePromise<any> {
    return this.httpRequest.request({
      method: "POST",
      url: "/login-with-google",
      body: requestBody,
      mediaType: "application/json",
      errors: {
        422: `Validation Error`,
      },
    });
  }
}
