import { Link, useSearchParams } from "react-router-dom";
import useReports from "@/services/reports/useReports";
import Reference from "@/data-models/value-models/reference";
import Table, { useTablePagination } from "@/components/data-table/Table";
import RemoveReportButton from "./RemoveReportButton";
import Tippy from "@tippyjs/react";
import React, { useMemo } from "react";
import classNames from "classnames";
import { ArrowSmallRightIcon } from "@heroicons/react/24/outline";
import { printDate } from "@/text-printers/print-date";
import { useTranslation } from "react-i18next";
import { Spinner } from "@/Icons";
import { useCurrentSubject } from "@/pages/patients/createCurrentSubjectLoader";
import { CONFIG } from "@/config";
import Identifier from "@/data-models/value-models/identifier";

interface ReportsTableProps extends React.HTMLAttributes<HTMLDivElement> {}

interface ReportsOverviewProps extends ReportsTableProps {}

export const ReportsTable = ({
  className,
  ...attributes
}: ReportsOverviewProps) => {
  const patient = useCurrentSubjectReference();
  const encounter = useCurrentEncounterReference();
  const { t } = useTranslation();
  const { limit, offset } = useTablePagination({ limit: 20 });
  const { data: reports, isLoading } = useReports({
    offset,
    limit,
    ...patient.toURLTokens("subject"),
    ...encounter?.toURLTokens("encounter"),
  });
  if (isLoading)
    return (
      <div className="flex h-full flex-col items-center justify-center">
        <p className="text-base leading-6 text-gray-dark">
          <Spinner className="mx-1 inline h-5 w-5 animate-spin text-gray-primary" />
          <span>Loading reports...</span>
        </p>
      </div>
    );
  return (
    <Table.Container className={classNames(className)} {...attributes}>
      <Table.Root>
        <Table.Head>
          <Table.HeaderRow className="font-primary">
            <Table.HeaderCell>
              <span className="sr-only">id</span>
            </Table.HeaderCell>
            <Table.HeaderCell>{t("contact")}</Table.HeaderCell>
            <Table.HeaderCell>{t("template")}</Table.HeaderCell>
            <Table.HeaderCell>{t("gecreeerd-op")}</Table.HeaderCell>
            <Table.HeaderCell>{t("gecreeerd-door")}</Table.HeaderCell>
            <Table.HeaderCell>
              <span className="sr-only">{t("openen")}</span>
            </Table.HeaderCell>
            <Table.HeaderCell>
              <span className="sr-only">{t("verwijderen")}</span>
            </Table.HeaderCell>
          </Table.HeaderRow>
        </Table.Head>
        <Table.Body>
          {reports?.map((r) => (
            <Table.Row key={r.id} className="group">
              <Table.Cell className="relative group-hover:underline">
                <Link
                  className="absolute inset-0"
                  to={`../${r.id}`}
                  relative="path"
                />
                <span className="text-xs text-gray-primary">{r.id}</span>
              </Table.Cell>
              <Table.Cell className="relative group-hover:underline">
                <Link
                  className="absolute inset-0"
                  to={`../${r.id}`}
                  relative="path"
                />
                {r.encounter?.identifier?.value}
              </Table.Cell>
              <Table.Cell className="relative group-hover:underline">
                <Link
                  className="absolute inset-0"
                  to={`../${r.id}`}
                  relative="path"
                />
                {r.template}
              </Table.Cell>
              <Table.Cell className="relative">
                <Link
                  className="absolute inset-0"
                  to={`../${r.id}`}
                  relative="path"
                />
                {r.created && printDate(r.created.timestamp)}
              </Table.Cell>
              <Table.Cell className="relative">
                <Link
                  className="absolute inset-0"
                  to={`../${r.id}`}
                  relative="path"
                />
                {r.created?.user.display}
              </Table.Cell>
              <Table.Cell className="relative text-gray-light group-hover:text-gray-primary">
                <Link
                  className="absolute inset-0"
                  to={`../${r.id}`}
                  relative="path"
                />
                <Tippy content="Openen dit verslag">
                  <ArrowSmallRightIcon className="inline-block h-5 w-5 fill-current" />
                </Tippy>
              </Table.Cell>
              <Table.Cell>
                <Tippy content="Verwijder dit verslag">
                  <RemoveReportButton reportId={r.id} />
                </Tippy>
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table.Root>
      {reports?.length === 0 && (
        <div className="flex h-full flex-col items-center justify-center py-4">
          <p className="text-sm leading-6 text-gray-primary">
            {t("geen-verslagen-gevonden")}
          </p>
        </div>
      )}
      <Table.Pagination />
    </Table.Container>
  );
};

const ReportsOverview = ({ className }: ReportsTableProps) => {
  const { t } = useTranslation();
  return (
    <div
      className={classNames("flex flex-grow flex-col items-stretch", className)}
    >
      <div className="flex justify-between">
        <h2 className="font-primary text-2xl font-semibold text-primary sm:truncate">
          {t("recente-verslagen")}
        </h2>
      </div>
      <main className="relative mb-10 mt-4 overflow-hidden rounded-md border border-blue-ultralight bg-white shadow-sm">
        <ReportsTable />
      </main>
    </div>
  );
};
export default ReportsOverview;

// Helper functions

function parseEncounterReferenceFromURLParams(params: URLSearchParams) {
  const encounterToken = params.get("encounter");
  const encounterIdentifier = encounterToken
    ? Identifier.fromToken(encounterToken, CONFIG.ENCOUNTER_ID_URI)
    : undefined;
  const encounter =
    encounterIdentifier &&
    new Reference({ identifier: encounterIdentifier, type: "Encounter" });
  return encounter;
}

function useCurrentEncounterReference() {
  const [params] = useSearchParams();
  return parseEncounterReferenceFromURLParams(params);
}

function useCurrentSubjectReference() {
  const subject = useCurrentSubject();
  const ref = useMemo(
    () =>
      new Reference({ reference: `Patient/${subject.id}`, type: "Patient" }),
    [subject.id],
  );
  return ref;
}
