import { BundleEntry } from "@smile-cdr/fhirts/dist/FHIR-R4/classes/bundleEntry";
import { QuestionnaireResponseAnswer } from "@smile-cdr/fhirts/dist/FHIR-R4/classes/questionnaireResponseAnswer";
import { QuestionnaireResponseItem } from "@smile-cdr/fhirts/dist/FHIR-R4/classes/questionnaireResponseItem";
import { Resource } from "@smile-cdr/fhirts/dist/FHIR-R4/classes/resource";
import { IBundle } from "@smile-cdr/fhirts/dist/FHIR-R4/interfaces/IBundle";
import { Patient } from "@smile-cdr/fhirts/dist/FHIR-R4/classes/patient";
import { FHIREndpoint } from "@/hooks/useFHIREndpoint";
import { QueryClient } from "react-query";
import { createFHIRDocumentViewQuery } from "@/services/reports/useFHIRDocumentView";
import { ForwardFHIRDocumentStatus } from "./ForwardFHIRDocument";
import { Result } from "./types";
import { Action } from "./Action";

class DelayedReference {
  type: string;
  reference: string | null;
  display: string | null;
  constructor(type: string) {
    this.type = type;
    this.reference = null;
    this.display = null;
  }
  setReference(reference: string, display: string) {
    this.reference = reference;
    this.display = display;
  }
  toJSON() {
    if (this.reference == null) {
      throw new Error("DelayedReference not set");
    }
    if (this.display == null) {
      return {
        reference: this.reference,
        type: this.type,
      };
    }
    return {
      reference: this.reference,
      type: this.type,
      display: this.display,
    };
  }
}

class ForwardFHIRTransaction extends Action {
  patientId: number;
  reportId: number;
  status: ForwardFHIRDocumentStatus;
  client: QueryClient;
  fhirEndpoint: FHIREndpoint;
  patientReference: DelayedReference;
  retry = undefined;
  constructor({
    client,
    patientId,
    reportId,
    fhirEndpoint,
    status = "QUEUED",
  }: {
    client: QueryClient;
    patientId: number;
    reportId: number;
    status?: ForwardFHIRDocumentStatus;
    fhirEndpoint: FHIREndpoint;
  }) {
    super();
    this.patientReference = new DelayedReference("Patient");
    this.client = client;
    this.status = status;
    this.patientId = patientId;
    this.reportId = reportId;
    this.fhirEndpoint = fhirEndpoint;
  }

  get current() {
    return {
      status: this.status,
      renderMessage: this.getRenderMessageFunc(),
    };
  }
  updateStatus(status: ForwardFHIRDocumentStatus) {
    this.status = status;
    this.notify(this.current);
  }

  reset() {
    this.updateStatus("QUEUED");
  }

  async getBundle(): Promise<IBundle> {
    this.updateStatus("RUNNING");
    const document = await this.client.fetchQuery<IBundle>(
      createFHIRDocumentViewQuery(this.reportId, {
        includeExtractedResources: true,
      }),
    );
    console.debug(
      "Received document, start converting to transaction",
      document,
    );
    return await this.buildTransaction(document);
  }

  async execute() {
    try {
      const bundle = await this.getBundle();
      bundle["identifier"] = {
        system: "http://tiro.health/fhir/NamingSystem/report-identifier",
        value: this.reportId.toString(),
      };
      const response = await fetch(this.fhirEndpoint.baseURL, {
        method: "POST",
        headers: this.fhirEndpoint.headers,
        body: JSON.stringify(bundle),
      });
      if (!response.ok) {
        throw new Error(`Failed to archive document: ${response.statusText}`);
      }
    } catch (error) {
      console.debug(error);
      this.updateStatus("ERROR");
      return [false, error] as Result;
    }
    this.updateStatus("READY");
    return [true, null] as Result;
  }

  convertResourceToBundleEntry(resource: Resource): BundleEntry {
    if (isPatient(resource)) {
      const linkMatch = resource.link?.find((link) =>
        link.other.reference?.startsWith(this.fhirEndpoint.baseURL),
      );
      if (linkMatch) {
        const relativeReference = linkMatch.other.reference!.replace(
          this.fhirEndpoint.baseURL,
          "",
        );
        this.patientReference.setReference(
          relativeReference,
          linkMatch.other.display!,
        );
        return {
          request: {
            method: "GET",
            url: relativeReference,
          },
        };
      }
    }
    if (resource.resourceType == "Composition") {
      // @ts-ignore
      resource.subject = this.patientReference;

      // @ts-ignore
      resource.title = resource.section[0].title;
    }
    if (resource.resourceType == "Observation") {
      // @ts-ignore
      resource.subject = this.patientReference;
    }
    if (resource.resourceType == "QuestionnaireResponse") {
      // @ts-ignore
      resource.item = removeEmtpyQuestionnaireItems(resource.item);
      // @ts-ignore
      if (resource.item?.length == 0) {
        // @ts-ignore
        delete resource.item;
      }

      // @ts-ignore
      resource.subject = this.patientReference;

      // @ts-ignore
      resource.status = "completed";

      // @ts-ignore
      resource["identifier"] = undefined;
    }
    return {
      request: { method: "POST", url: resource.resourceType },
      resource,
    };
  }
  getRenderMessageFunc() {
    switch (this.status) {
      case "QUEUED":
        return () => <>Doorsturen van huidig verslag naar het EPD.</>;
      case "RUNNING":
        return () => <>Bezig met doorsturen van huidig verslag naar het EPD.</>;
      case "READY":
        return () => <>Huidig verslag succesvol doorgestuurd naar het EPD.</>;
      case "ERROR":
        return () => <>Fout bij doorsturen huidig verslag naar het EPD.</>;
    }
  }

  async buildTransaction(document: IBundle) {
    const entries: BundleEntry[] = [];
    for (const entry of document.entry || []) {
      if (!entry.resource) continue;
      if (entry.resource.resourceType == "Practitioner") continue;
      entries.push(this.convertResourceToBundleEntry(entry.resource));
    }
    const transaction: IBundle = {
      resourceType: "Bundle",
      type: "transaction",
      entry: entries,
    };
    return transaction;
  }
}

export default ForwardFHIRTransaction;

function isPatient(resource: Resource): resource is Patient {
  return resource.resourceType == "Patient";
}

function removeEmtpyQuestionnaireItems(
  items: QuestionnaireResponseItem[],
): QuestionnaireResponseItem[] | undefined {
  return items?.filter((item) => {
    if (item.item) {
      item.item = removeEmtpyQuestionnaireItems(item.item);
      if (item.item?.length == 0) {
        delete item.item;
      }
    }
    if (item.answer && item.answer.length > 0) {
      item.answer = removeEmptyAnwserValues(item.answer);
    }
    if (item.answer && item.answer.length == 0) {
      delete item.answer;
    }
    return !!item.answer?.length || !!item.item?.length;
  });
}

function removeEmptyAnwserValues(answer: QuestionnaireResponseAnswer[]) {
  return answer?.filter((valueObj) => {
    const nonEmptyEntries = Object.entries(valueObj).filter(([key, value]) => {
      if (value instanceof Array) {
        return !isEmptyArray(value);
      }
      if (value instanceof Object) {
        return !isEmptyObject(value);
      }
      return true;
    });
    return !isEmptyArray(nonEmptyEntries);
  });
}

function isEmptyObject(obj: any) {
  return Object.keys(obj).length === 0 && obj.constructor === Object;
}

function isEmptyArray(arr: any[]) {
  return arr.length === 0;
}
