import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { Fragment } from "react";

export interface AttachmentPreviewProps {
  title: string;
  url: string;
  isOpen: boolean;
  close: () => void;
}

const AttachmentPreview = ({
  isOpen,
  close,
  title,
  url,
}: {
  title: string;
  url: string;
  isOpen: boolean;
  close: () => void;
}) => {
  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-10 overflow-y-auto"
        onClose={close}
      >
        <div className="relative flex min-h-screen flex-col items-center justify-center px-4 text-center">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0" />
          </Transition.Child>

          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="relative my-8 flex w-full max-w-5xl flex-grow flex-col overflow-hidden rounded-2xl border border-gray-200 bg-white p-6 text-left align-middle shadow-xl transition-all">
              <div className="absolute top-4 right-4">
                <button type="button" onClick={close}>
                  <XMarkIcon className="h-6 w-6 stroke-current" />
                </button>
              </div>
              <Dialog.Title
                as="h3"
                className="text-lg font-medium leading-6 text-gray-900"
              >
                {title}
              </Dialog.Title>
              <div className="relative mt-2 flex flex-grow flex-col">
                <iframe
                  className="w-full flex-grow overflow-hidden"
                  title={title}
                  src={url}
                />
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
};

export default AttachmentPreview;
