import classNames from "classnames";
import TD from "@/components/forms/TopDownForm";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { EMPTY_EXTRACTION } from "./PropertiesForm";
import { FieldValues } from "./types";

export function FHIRExtractionFieldSet({ className }: { className?: string }) {
  const { t } = useTranslation();
  const { register, setValue, watch } = useFormContext<FieldValues>();
  return (
    <fieldset className={classNames("block", className)}>
      <legend>FHIR R5</legend>
      {watch("extraction.fhir") ? (
        <>
          <TD.Field
            {...register("extraction.fhir.path")}
            label="Extraction Path"
            small
            type="text"
          />
          <TD.Field
            {...register("extraction.fhir.context")}
            label="Extraction Context"
            small
            type="text"
          />
          <TD.Option
            {...register("extraction.fhir.reverse")}
            label="Reverse reference"
            small
          />
        </>
      ) : (
        <TD.Button
          small
          style="outline"
          onClick={() => setValue("extraction.fhir", EMPTY_EXTRACTION)}
        >
          {t("add-fhir-extraction")}
        </TD.Button>
      )}
    </fieldset>
  );
}
