/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { PatientLinkReference_Output } from './PatientLinkReference_Output';

export type PatientLink_Output = {
    id?: (string | null);
    type: PatientLink_Output.type;
    other: PatientLinkReference_Output;
};

export namespace PatientLink_Output {

    export enum type {
        REFER = 'refer',
    }


}

