import React from "react";
import usePatients from "@/services/reports/usePatients";
import { printDate } from "@/text-printers/print-date";
import Table, { useTablePagination } from "@/components/data-table/Table";
import RemovePatientButton from "./RemovePatientButton";
import { Link } from "react-router-dom";
import Tippy from "@tippyjs/react";
import { ArrowSmallRightIcon } from "@heroicons/react/24/outline";
import { useTranslation } from "react-i18next";
import { usePostHog } from "posthog-js/react";
import classNames from "classnames";
//TODO: make story
const SEX_NL = { male: "man", female: "vrouw", other: "andere" };

const PatientsTable = ({
  className,
  ...attrs
}: React.HTMLAttributes<HTMLDivElement>) => {
  const { t } = useTranslation();
  const posthog = usePostHog();
  const { limit, offset } = useTablePagination({ limit: 10 });
  const { data: patients, isLoading } = usePatients({ limit, offset });
  // we need the fullname to sort on
  const patientsWithname = patients?.map((s) => ({
    ...s,
    name: `${s.firstName ?? ""} ${s.lastName ?? ""}`,
  }));

  const handleClick = (patientId: number) => {
    const result = posthog.capture("Patient selected", { patientId });
    console.log(result);
  };

  if (isLoading)
    return <h3 className="text-lg leading-6 text-gray-dark">Loading...</h3>;
  return (
    <Table.Container
      limit={limit}
      className={classNames(className, "ph-no-capture")}
      {...attrs}
    >
      <Table.Root>
        <Table.Head>
          <Table.HeaderRow>
            <Table.HeaderCell>
              <span className="sr-only">id</span>
            </Table.HeaderCell>
            <Table.HeaderCell>{t("identificatienummer")}</Table.HeaderCell>
            <Table.HeaderCell>{t("naam")}</Table.HeaderCell>
            <Table.HeaderCell>{t("geslacht")}</Table.HeaderCell>
            <Table.HeaderCell>{t("geboortedatum")}</Table.HeaderCell>
            <Table.HeaderCell>{t("laatst-gewijzigd-verslag")}</Table.HeaderCell>
            <Table.HeaderCell>
              <span className="sr-only">{t("openen")}</span>
            </Table.HeaderCell>
            <Table.HeaderCell>
              <span className="sr-only">{t("verwijderen")}</span>
            </Table.HeaderCell>
          </Table.HeaderRow>
        </Table.Head>
        <Table.Body>
          {patientsWithname?.length === 0 && (
            <Table.Row>
              <Table.Cell colSpan={8}>
                <div className="flex h-full items-center justify-center">
                  <p className="text-sm text-gray-primary">
                    {t("geen-patienten-gevonden")}
                  </p>
                </div>
              </Table.Cell>
            </Table.Row>
          )}
          {patientsWithname?.map((s, index) => (
            <Table.Row key={s.id} className="group">
              <Table.Cell className="text-xs">
                <Link
                  className="sr-only"
                  to={`${s.id}/reports/new`}
                  onClick={() => handleClick(s.id)}
                >
                  <span className="">{offset + index + 1}</span>
                </Link>
              </Table.Cell>
              <Table.Cell className="relative group-hover:underline">
                <Link
                  className="absolute inset-0"
                  to={`${s.id}/reports/new`}
                  onClick={() => handleClick(s.id)}
                />
                {s.identifier?.[0].value}
              </Table.Cell>
              <Table.Cell className="relative group-hover:underline">
                <Link
                  className="absolute inset-0"
                  to={`${s.id}/reports/new`}
                  onClick={() => handleClick(s.id)}
                />
                {s.name}
              </Table.Cell>
              <Table.Cell className="relative">
                <Link
                  className=" absolute inset-0"
                  to={`${s.id}/reports/new`}
                  onClick={() => handleClick(s.id)}
                />
                {s.gender && SEX_NL[s.gender as keyof typeof SEX_NL]}
              </Table.Cell>
              <Table.Cell className="relative">
                <Link
                  className="absolute inset-0"
                  to={`${s.id}/reports/new`}
                  onClick={() => handleClick(s.id)}
                />
                {s.birthDate && printDate(s.birthDate)}
              </Table.Cell>
              <Table.Cell className="relative">
                <Link
                  className="absolute inset-0"
                  to={`${s.id}/reports/new`}
                  onClick={() => handleClick(s.id)}
                />
                {s.lastModifiedReportTimestamp &&
                  printDate(s.lastModifiedReportTimestamp)}
              </Table.Cell>
              <Table.Cell className="relative text-gray-light group-hover:text-gray-primary">
                <Link
                  className="absolute inset-0"
                  to={`${s.id}/reports/new`}
                  onClick={() => handleClick(s.id)}
                />
                <Tippy content={t("openen-verslagen-van-deze-patient")}>
                  <ArrowSmallRightIcon className="inline-block h-5 w-5 fill-current" />
                </Tippy>
              </Table.Cell>
              <Table.Cell>
                <Tippy content={t("verwijder-deze-patient")}>
                  <RemovePatientButton patientId={s.id} />
                </Tippy>
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table.Root>
      <Table.Pagination />
    </Table.Container>
  );
};

export default PatientsTable;
