import React, { useReducer } from "react";
import { useCreateQuestionnaireReducer } from "./createReducer";
import { IQuestionNode, QAAction } from "./models";
import useStableCallback from "@/hooks/useStableCallback";

export interface UseQuestionnaireControllerProps {
  defaultQuestions?: IQuestionNode[];
  questions?: IQuestionNode[];
  onQuestionsChange?: (v: IQuestionNode[]) => void;
  dispatch?: React.Dispatch<QAAction>;
}
const DEFAULT_QA_ITEMS: IQuestionNode[] = [];

function useQuestionnaireController({
  questions: providedQuestions,
  defaultQuestions: initialQuestions,
  dispatch: providedDispatch,
  onQuestionsChange,
}: UseQuestionnaireControllerProps = {}) {
  const onQuestionsChangeStable = useStableCallback(onQuestionsChange);
  const isControlled = providedQuestions !== undefined;

  const reducer = useCreateQuestionnaireReducer({
    onQuestionsChange: onQuestionsChangeStable,
  });
  const [localQuestions, localDispatch] = useReducer(
    reducer,
    initialQuestions ?? DEFAULT_QA_ITEMS,
  );
  // Switch between external controlled or local controlled states
  const questions = isControlled ? providedQuestions : localQuestions;

  const dispatch = isControlled
    ? providedDispatch ?? localDispatch
    : localDispatch;
  return [questions, dispatch] as const;
}

export default useQuestionnaireController;
