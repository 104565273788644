import { Editor, Range, Transforms } from "slate";
import { TypedElement } from "./TypedElement";
import { ITiroElement } from "./Base/types";
import { ITiroEditor } from "./Base/editor";

const withInlineVoid =
  ({
    inlineTypes,
    voidTypes,
  }: {
    inlineTypes?: string[];
    voidTypes?: string[];
  } = {}) =>
  <T extends ITiroEditor>(editor: T): T => {
    const {
      isInline,
      isVoid,
      deleteBackward,
      deleteForward,
      deleteFragment,
      plugins,
    } = editor;

    editor.deleteBackward = (unit) => {
      const { selection } = editor;
      if (selection && Range.isCollapsed(selection)) {
        const beforePoint = Editor.before(editor, selection, {
          unit: "offset",
          distance: 1,
        });
        const block = Editor.above(editor, {
          match: (n) => Editor.isBlock(editor, n),
        });
        if (!block) return; // should always exist
        const [blockNode, blockPath] = block;

        const beforeIsVoid =
          beforePoint &&
          Editor.above(editor, {
            at: beforePoint,
            match: (n) => Editor.isVoid(editor, n),
          });

        if (Editor.isVoid(editor, blockNode)) {
          Transforms.removeNodes(editor, { at: blockPath });
          return;
        }
        if (beforeIsVoid) {
          const [, path] = beforeIsVoid;
          if (Editor.isEmpty(editor, blockNode)) {
            Transforms.removeNodes(editor, { at: blockPath });
          }
          const beforeVoid = Editor.before(editor, path);
          const afterVoid = Editor.after(editor, path);
          if (beforeVoid && afterVoid)
            Transforms.select(
              editor,
              Editor.range(editor, beforeVoid, afterVoid)
            );
          return;
        }
      }
      deleteBackward(unit);
    };
    editor.deleteForward = (unit) => {
      const { selection } = editor;
      if (selection && Range.isCollapsed(selection)) {
        const locationAfter = Editor.after(editor, selection, {
          unit: "offset",
          distance: 1,
        });
        const currentBlockEntry = Editor.above(editor, {
          match: (n) => Editor.isBlock(editor, n),
        });
        if (!currentBlockEntry) return; // should always exist
        const [currentBlock, currentPath] = currentBlockEntry;

        const afterIsVoid =
          locationAfter &&
          Editor.above(editor, {
            at: locationAfter,
            match: (n) => Editor.isVoid(editor, n),
          });
        if (Editor.isVoid(editor, currentBlock)) {
          Transforms.removeNodes(editor, { at: currentPath });
          return;
        }
        if (afterIsVoid) {
          const [, path] = afterIsVoid;
          if (Editor.isEmpty(editor, currentBlock)) {
            Transforms.removeNodes(editor, { at: currentPath });
          }
          Transforms.select(editor, path);
          return;
        }
      }
      deleteForward(unit);
    };

    editor.deleteFragment = (direction) => {
      return deleteFragment(direction);
    };

    editor.isInline = (n) => {
      const { inline } = n as ITiroElement;
      if (inline !== undefined) {
        return inline;
      }
      if (TypedElement.isTypedElement(n)) {
        if (inlineTypes && inlineTypes.includes(n.type)) {
          return true;
        }
        if (
          plugins &&
          plugins.some((p) => p.inlineTypes && p.inlineTypes.includes(n.type))
        ) {
          return true;
        }
      }
      return isInline(n);
    };

    editor.isVoid = (n) => {
      const { void: _void } = n as ITiroElement;
      if (_void !== undefined) {
        return _void;
      }

      if (TypedElement.isTypedElement(n)) {
        if (voidTypes && voidTypes.includes(n.type)) {
          return true;
        }
        if (
          plugins &&
          plugins.some((p) => p.voidTypes && p.voidTypes.includes(n.type))
        )
          return true;
      }

      return isVoid(n);
    };

    return editor;
  };

export default withInlineVoid;
