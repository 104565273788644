import { Coding } from "@/data-models/value-models";
import { IEnabledWhen, IEnabledWhenCondition } from "../models";

function isEnabled(enabledWhen: IEnabledWhen | undefined, codes: Coding[]) {
  if (!enabledWhen) return true;
  const eval_func = (c: IEnabledWhenCondition) =>
    c.operator == "="
      ? codes.some((codeA) => codeA.equals(c.operand))
      : c.operator == "!="
      ? !codes.some((codeA) => codeA.equals(c.operand))
      : c.operator == "exists"
      ? c.operand
        ? codes.length > 0
        : codes.length == 0
      : false;
  if (enabledWhen.mode == "all") return enabledWhen.conditions.every(eval_func);
  if (enabledWhen.mode == "any") return enabledWhen.conditions.some(eval_func);
  return false;
}
export default isEnabled;
