import { useEffect } from "react";
import { toast } from "react-hot-toast";
import { useQuery } from "react-query";
import { Form, useActionData } from "react-router-dom";
import { currentUserQuery } from "@/services/auth/useAuthUser";
import Panel from "../Panel";
import { ActionData } from "./createAction";

export interface SendVerificationPanelProps {}

export function SendVerificationPanel() {
  const result = useActionData() as ActionData | undefined;
  const { data: user } = useQuery(currentUserQuery);
  useEffect(() => {
    const id = result?.timestamp.toString();
    result?.status == "resent" &&
      toast.success("Verificatielink opnieuw verzonden!", { id });
  }, [result?.status, result?.timestamp]);

  return (
    <Panel>
      <Form className="space-y-2 whitespace-pre-line" method="POST">
        <h3 className="text-xl font-medium leading-6 text-gray-900">
          Email bevestigen
        </h3>
        <p className="text-sm">
          Er is een verificatielink verzonden naar <em>{user?.email}</em>. Klik
          op de link zodat we je kunnen identificeren.
        </p>
        <p className="mt-4 text-sm">
          Geen mail ontvangen?
          <br />
          <button
            type="submit"
            name="resend"
            value="true"
            className="text-blue-primary underline"
          >
            Verzend de verificatielink opnieuw
          </button>
        </p>
      </Form>
    </Panel>
  );
}
