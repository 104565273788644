import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { Fragment } from "react";
import ReactJson from "react-json-view";

interface ExportModalViewProps {
  isOpen: boolean;
  close: () => void;
  block: any;
}

export const ExportModalView = ({
  isOpen,
  close,
  block,
}: ExportModalViewProps) => {
  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog
        className="fixed inset-0 z-50 overflow-y-auto"
        open={isOpen}
        onClose={close}
      >
        <div className="min-h-screen px-4 text-center">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-black opacity-30" />
          </Transition.Child>
          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="inline-block h-screen align-middle"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="my-10 inline-block w-full max-w-4xl transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle text-primary shadow-xl transition-all">
              <div className="absolute right-4 top-4">
                <button type="button" onClick={close}>
                  <XMarkIcon className="h-6 w-6 stroke-current" />
                </button>
              </div>
              <Dialog.Title
                as="h3"
                className="font-primary text-lg font-medium leading-6 text-primary"
              >
                JSON voorstelling van huidige bouwblok
              </Dialog.Title>
              <div className="mt-6 select-auto">
                <ReactJson
                  src={block}
                  collapsed={1}
                  enableClipboard={({ src }) =>
                    navigator.clipboard.writeText(JSON.stringify(src, null, 4))
                  }
                />
              </div>
              <div className="mt-4">
                <button
                  type="button"
                  className="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                  onClick={() =>
                    navigator.clipboard.writeText(JSON.stringify(block))
                  }
                >
                  Copy
                </button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
};
