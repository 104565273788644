import QuantityEntityTooltip from "@/components/base/EntityTooltip/QuantityEntityTooltip";
import { createQuantityEntity, IQuantity } from "@/data-models/quantity-entity";
import { RenderBadge } from "../Entities/StyledEntityContainers";
export interface QuantityEntityBadgeProps {
  entity: IQuantity;
  onEntityChange: (entity: IQuantity) => void;
  openEditor: () => void;
}

function QuantityEntityBadge({
  entity,
  openEditor,
  onEntityChange: handleEntityChange,
}: QuantityEntityBadgeProps) {
  const quantityEntity = createQuantityEntity(entity as any);
  return (
    <>
      <QuantityEntityTooltip entity={quantityEntity}>
        <RenderBadge
          active={quantityEntity.isValid}
          onClick={openEditor}
          className="align-bottom text-sm"
        >
          {quantityEntity.toString() || quantityEntity.placeholder}
        </RenderBadge>
      </QuantityEntityTooltip>
    </>
  );
}

export default QuantityEntityBadge;
