import React from "react";

type SVGProps = React.SVGAttributes<SVGElement>;

export const BinIcon = (props: SVGProps) => {
  return (
    <svg
      aria-hidden="true"
      focusable="false"
      width="1em"
      height="1em"
      style={{
        msTransform: "rotate(360deg)",
        WebkitTransform: "rotate(360deg)",
        transform: "rotate(360deg)",
      }}
      preserveAspectRatio="xMidYMid meet"
      viewBox="0 0 16 16"
      {...props}
    >
      <g>
        <path d="M2 5v10c0 .55.45 1 1 1h9c.55 0 1-.45 1-1V5H2zm3 9H4V7h1v7zm2 0H6V7h1v7zm2 0H8V7h1v7zm2 0h-1V7h1v7z" />
        <path d="M13.25 2H10V.75A.753.753 0 0 0 9.25 0h-3.5A.753.753 0 0 0 5 .75V2H1.75a.752.752 0 0 0-.75.75V4h13V2.75a.752.752 0 0 0-.75-.75zM9 2H6v-.987h3V2z" />
      </g>
    </svg>
  );
};

export const SaveIcon = (props: SVGProps) => {
  return (
    <svg
      aria-hidden="true"
      focusable="false"
      width="1em"
      height="1em"
      style={{
        msTransform: "rotate(360deg)",
        WebkitTransform: "rotate(360deg)",
        transform: "rotate(360deg)",
      }}
      preserveAspectRatio="xMidYMid meet"
      viewBox="0 0 32 32"
      {...props}
    >
      <path d="M27.71 9.29l-5-5A1 1 0 0 0 22 4H6a2 2 0 0 0-2 2v20a2 2 0 0 0 2 2h20a2 2 0 0 0 2-2V10a1 1 0 0 0-.29-.71zM12 6h8v4h-8zm8 20h-8v-8h8zm2 0v-8a2 2 0 0 0-2-2h-8a2 2 0 0 0-2 2v8H6V6h4v4a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V6.41l4 4V26z" />
    </svg>
  );
};

export const Spinner = (props: SVGProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 100 100"
    {...props}
  >
    <circle
      cx="50"
      cy="50"
      fill="none"
      stroke="currentColor"
      strokeWidth="8"
      r="35"
      strokeDasharray="164.93361431346415 56.97787143782138"
    />
  </svg>
);

export const Drag = (props: React.HTMLAttributes<SVGElement>) => (
  <svg {...props} viewBox="0 0 24 24">
    <path
      xmlns="http://www.w3.org/2000/svg"
      d="M11 18c0 1.1-.9 2-2 2s-2-.9-2-2 .9-2 2-2 2 .9 2 2zm-2-8c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0-6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm6 4c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm0 2c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0 6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z"
    />
  </svg>
);

export const Export = (props: React.HTMLAttributes<SVGElement>) => (
  <svg {...props} viewBox="0 0 18 24" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13 1V5H17L13 1Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.2727 6.4C11.8207 6.4 11.4545 6.04201 11.4545 5.6V0H2.25818C1.0125 0.00399872 0.00408436 0.990003 0 2.208V21.792C0.0040896 23.01 1.0125 23.996 2.25818 24H15.7418C16.9875 23.996 17.9959 23.01 18 21.792V6.4H12.2727ZM12.8536 12.5681C12.7002 12.7191 12.4906 12.8051 12.2727 12.8051C12.0549 12.8051 11.8452 12.7191 11.6918 12.5681L9.81818 10.7281V16.8001C9.81818 17.2421 9.45205 17.6001 9 17.6001C8.54795 17.6001 8.18182 17.2421 8.18182 16.8001V10.7281L6.3082 12.5681C5.98705 12.8821 5.46752 12.8821 5.14638 12.5681C4.82523 12.2541 4.82523 11.7461 5.14638 11.4321L8.4191 8.23206C8.57251 8.08107 8.78217 7.99506 9.00003 7.99506C9.21789 7.99506 9.42752 8.08107 9.58095 8.23206L12.8537 11.4321C13.0081 11.5821 13.0961 11.7871 13.0961 12.0001C13.0961 12.2131 13.0081 12.4181 12.8537 12.5681L12.8536 12.5681Z"
      fill="white"
    />
  </svg>
);

export const WindBack = (props: React.HTMLAttributes<SVGElement>) => (
  <svg
    {...props}
    viewBox="0 0 24 24"
    strokeWidth="2"
    stroke="currentColor"
    fill="none"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    {" "}
    <path
      stroke="none"
      d="M0 0h24v24H0z"
      transform="scale(1, -1) translate(0, -24)"
    />{" "}
    <path
      transform="scale(1, -1) translate(0, -24)"
      d="M4.05 11a8 8 0 1 1 .5 4m-.5 5v-5h5"
    />
  </svg>
);

export const StethoscopeIcon = (props: React.HTMLAttributes<SVGElement>) => (
  <svg
    aria-hidden="true"
    focusable="false"
    data-prefix="fas"
    data-icon="stethoscope"
    stroke="currentColor"
    role="img"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 512 512"
    {...props}
  >
    <path
      fill="currentColor"
      d="M447.1 112c-34.2.5-62.3 28.4-63 62.6-.5 24.3 12.5 45.6 32 56.8V344c0 57.3-50.2 104-112 104-60 0-109.2-44.1-111.9-99.2C265 333.8 320 269.2 320 192V36.6c0-11.4-8.1-21.3-19.3-23.5L237.8.5c-13-2.6-25.6 5.8-28.2 18.8L206.4 35c-2.6 13 5.8 25.6 18.8 28.2l30.7 6.1v121.4c0 52.9-42.2 96.7-95.1 97.2-53.4.5-96.9-42.7-96.9-96V69.4l30.7-6.1c13-2.6 21.4-15.2 18.8-28.2l-3.1-15.7C107.7 6.4 95.1-2 82.1.6L19.3 13C8.1 15.3 0 25.1 0 36.6V192c0 77.3 55.1 142 128.1 156.8C130.7 439.2 208.6 512 304 512c97 0 176-75.4 176-168V231.4c19.1-11.1 32-31.7 32-55.4 0-35.7-29.2-64.5-64.9-64zm.9 80c-8.8 0-16-7.2-16-16s7.2-16 16-16 16 7.2 16 16-7.2 16-16 16z"
    ></path>
  </svg>
);

export const TIcon = (props: React.HTMLAttributes<SVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
    <g data-name="Layer 2">
      <g data-name="text">
        <rect width="24" height="24" opacity="0" />
        <path d="M20 4H4a1 1 0 0 0-1 1v3a1 1 0 0 0 2 0V6h6v13H9a1 1 0 0 0 0 2h6a1 1 0 0 0 0-2h-2V6h6v2a1 1 0 0 0 2 0V5a1 1 0 0 0-1-1z" />
      </g>
    </g>
  </svg>
);

export const ToggleIcon = (props: React.HTMLAttributes<SVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="64"
    height="64"
    viewBox="0 0 64 64"
    {...props}
  >
    <path d="M47 15H17C7.626 15 0 22.626 0 32s7.626 17 17 17h30c9.374 0 17-7.626 17-17s-7.626-17-17-17zm0 32H17C8.729 47 2 40.271 2 32s6.729-15 15-15h30c8.271 0 15 6.729 15 15s-6.729 15-15 15z" />
    <path d="M47 19c-7.168 0-13 5.832-13 13s5.832 13 13 13 13-5.832 13-13-5.832-13-13-13zm0 24c-6.065 0-11-4.935-11-11s4.935-11 11-11 11 4.935 11 11-4.935 11-11 11z" />
  </svg>
);
