import { Decorator } from "@storybook/react";
import React from "react";

export const BadgeNameContext = React.createContext<string | null>(null);
export const BadgeColorContext = React.createContext<"blue" | "green">("blue");
export const BadgeStyleContext = React.createContext<
  "default" | "table" | "tile"
>("default");
export const BadgeSwitchModeContext = React.createContext<
  ((mode: "edit" | "view") => void) | null
>(null);
export const BadgeModeContext = React.createContext<"edit" | "view">("view");
export const BadgeAutoFocusContext = React.createContext<boolean>(false);
export type BadgeContextArgs = {
  onSwitchToMode: (mode: "edit" | "view") => void;
};
export const WithBadgeContext = <TArgs extends BadgeContextArgs>({
  mode,
  autoFocus,
  name,
  switchToMode,
  style = "default",
  color = "blue",
}: {
  autoFocus: boolean;
  mode: "edit" | "view";
  style: "tile" | "table" | "default";
  color?: "blue" | "green";
  name: string;
  switchToMode?: (mode: "edit" | "view") => void;
}) => {
  const decorator: Decorator<TArgs> = (Story, { args }) => {
    return (
      <BadgeSwitchModeContext.Provider
        value={switchToMode ?? args.onSwitchToMode}
      >
        <BadgeNameContext.Provider value={name}>
          <BadgeAutoFocusContext.Provider value={autoFocus}>
            <BadgeModeContext.Provider value={mode}>
              <BadgeStyleContext.Provider value={style}>
                <BadgeColorContext.Provider value={color}>
                  <Story />
                </BadgeColorContext.Provider>
              </BadgeStyleContext.Provider>
            </BadgeModeContext.Provider>
          </BadgeAutoFocusContext.Provider>
        </BadgeNameContext.Provider>
      </BadgeSwitchModeContext.Provider>
    );
  };
  return decorator;
};
