import { CellProps } from "./Cells";
import PeriodInput from "@/components/inputs/DateInput/PeriodInput";

export interface PeriodCellProps extends Omit<CellProps<"PERIOD">, "type"> {}

const PeriodCell = ({ column, name }: PeriodCellProps) => {
  return (
    <PeriodInput
      name={name}
      startName={`${name}.start`}
      endName={`${name}.end`}
      startDateRequired={column.startDateRequired}
      endDateRequired={column.endDateRequired}
      style="table"
    />
  );
};

export default PeriodCell;
