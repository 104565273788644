import classNames from "classnames";
import { ICoding } from "@/data-models/value-models";
import React, { Ref } from "react";
import { ExpansionContains } from "@/services/content/content-client";
import { MenuItemProps } from "./types";

export function getSemantic(c: ExpansionContains) {
  return c.property?.find((p) => p.code === "semantic_axis");
}

export function getKey(c: ExpansionContains) {
  return `${c.system}|${c.code} ${c.display}`;
}

export function getGenerated(c: ExpansionContains) {
  return c.property?.find((p) => p.code === "generated");
}

export type ConceptMenuItemProps<C extends ExpansionContains = ICoding> =
  MenuItemProps<C>;
function ConceptMenuItemWithoutRef<C extends ExpansionContains>(
  { item, active, ...props }: ConceptMenuItemProps<C>,
  ref: Ref<HTMLLIElement>,
) {
  const description = getSemantic(item)?.valueCoding?.display;
  const generated = getGenerated(item);
  return (
    <li
      ref={ref}
      key={getKey(item)}
      data-transaction-name="select-concept-suggestion"
      className={classNames(
        "flex cursor-pointer select-none items-baseline whitespace-nowrap px-3 py-2 text-left focus:ring-0",
        { "bg-blue-primary text-white": active },
        {
          "bg-white text-gray-800": !active,
        },
        "relative",
      )}
      {...props}
    >
      <span className="flex-1 truncate tabular-nums">{item.display}</span>
      {description && (
        <span
          className={classNames(
            "ml-4 text-xs",
            {
              "text-blue-100": active,
              "text-gray-primary": !active,
            },
            { italic: !!generated },
          )}
        >
          {description}
        </span>
      )}
      {generated && (
        <span
          className={classNames("ml-4 italic", {
            "text-blue-100": active,
            "text-gray-primary": !active,
          })}
        >
          Voeg nieuwe term/uitdrukking toe?
        </span>
      )}
    </li>
  );
}
const VSCodingMenuItem = React.forwardRef(ConceptMenuItemWithoutRef);
export default VSCodingMenuItem;
