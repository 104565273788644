/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { data_contracts__common__fhir__r5__datatypes__complex__Identifier_Output } from './data_contracts__common__fhir__r5__datatypes__complex__Identifier_Output';

export type PatientLinkReference_Output = {
    id?: (string | null);
    type?: PatientLinkReference_Output.type;
    display?: (string | null);
    reference: string;
    identifier?: (data_contracts__common__fhir__r5__datatypes__complex__Identifier_Output | null);
};

export namespace PatientLinkReference_Output {

    export enum type {
        PATIENT = 'Patient',
    }


}

