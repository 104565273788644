import { Menu } from "@headlessui/react";
import { UserReference } from "@/data-models/block";
import React from "react";
import { useTranslation } from "react-i18next";

export interface LastModifiedMenuItemProps {
  user: UserReference;
  timestamp: string;
}
function LastModifiedMenuItem({ user, timestamp }: LastModifiedMenuItemProps) {
  const { t } = useTranslation();
  const timestampDate = new Date(timestamp);
  return (
    <Menu.Item disabled>
      <div className="block min-w-[200px] border-t border-gray-ultralight px-3 py-3 text-xs text-gray-900">
        <h6 className="leading-3 text-gray-primary">
          {t("laatst-gewijzigd-door")}
        </h6>
        <p className="mt-1">
          <span className="cursor-pointer whitespace-nowrap underline-offset-1 hover:text-blue-400 hover:underline hover:decoration-blue-400">
            {user.display}
          </span>
          <span> {t("op")} </span>
          <span className="cursor-pointer whitespace-nowrap underline-offset-1 hover:text-blue-400 hover:underline hover:decoration-blue-400">
            {timestampDate.toLocaleString()}
          </span>
          <span>.</span>
        </p>
      </div>
    </Menu.Item>
  );
}

export default LastModifiedMenuItem;
