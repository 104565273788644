import MarkdownInput from "@/components/inputs/MarkdownInput";
import { CellProps } from "./Cells";

export interface MarkdownCellProps extends CellProps<"MARKDOWN"> {}
function MarkdownCell({ name }: MarkdownCellProps) {
  return (
    <MarkdownInput
      name={name}
      className="rounded-xs h-full w-full border-transparent hover:border-gray-light"
    />
  );
}

export default MarkdownCell;
