import {
  ExclamationTriangleIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import classNames from "classnames";
import { useState } from "react";

function DemoBanner({ className }: { className?: string }) {
  const [isHidden, setIsHidden] = useState(false);
  if (isHidden) return null;
  return (
    <div
      className={classNames(
        className,
        "box-border flex items-center gap-x-6 border border-b-orange-500 bg-orange-200 px-6 py-2.5 sm:px-3.5 sm:before:flex-1"
      )}
    >
      <p className="text-sm leading-6 text-orange-900">
        <ExclamationTriangleIcon
          className="mx-1 inline h-4 w-4 space-x-0.5 text-orange-600"
          aria-hidden="true"
        />
        <strong className="font-semibold">This is a demo instance.</strong>
        <span className="font-normal">
          {" "}
          Please do not store any sensitive patient data.
        </span>
        {/*<a
          href="https://docs.tiro.health/faq#demo-instance"
          target="_blank"
          rel="noreferrer"
          className="mx-2 inline-flex items-center gap-x-1 rounded border border-orange-950 px-2 py-1 text-sm font-semibold text-orange-900 hover:border-orange-600 hover:bg-orange-600 hover:text-white"
        >
          More info
          <ArrowRightIcon className="inline h-4 w-4" aria-hidden="true" />
        </a>*/}
      </p>
      <div className="flex flex-1 justify-end">
        <button
          onClick={() => setIsHidden(true)}
          type="button"
          className="-m-3 p-3 focus-visible:outline-offset-[-4px]"
        >
          <span className="sr-only">Dismiss</span>
          <XMarkIcon className="h-5 w-5 text-orange-950" aria-hidden="true" />
        </button>
      </div>
    </div>
  );
}

export default DemoBanner;
