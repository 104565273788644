import { apm } from "@elastic/apm-rum";
import { QueryClient } from "react-query";
import { instance, is } from "superstruct";

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 3, // retry 3 times before failing
      onError: (error) => {
        if (is(error, instance(Error))) {
          apm.captureError(error);
        } else {
          apm.captureError("Unknown error");
          console.log("Couldn't send specific error to APM", error);
        }
      },
    },
  },
});
