import { CONFIG } from "@/config";
import { ICoding } from "@/data-models/value-models";
import { toKebabCase } from "./utils";

export const SEMANTIC_AXES: ICoding[] = [
  "Procedure",
  "Surgical procedure",
  "Diagnostic procedure",
  "Evaluation procedure",
  "Regiman/therapy",
  "Substance",
  "Finding",
  "Disorder",
].map((display) => ({
  system: CONFIG.SEMANTIC_AXIS_URI,
  code: toKebabCase(display),
  display,
}));
