import classNames from "classnames";
import React from "react";

export interface CardProps extends React.HTMLProps<HTMLDivElement> {
  legacy?: boolean;
}
function Card({ className, legacy = false, ...props }: CardProps) {
  return (
    <div
      className={classNames(
        {
          "rounded-xl border border-gray-light bg-white shadow-md": legacy,
          "rounded-md border border-gray-light bg-white shadow-sm": !legacy,
        },
        className,
      )}
      {...props}
    />
  );
}
export default Card;
