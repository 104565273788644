import { RenderElementProps } from "slate-react";
import { TypedElement } from "../TypedElement";
import { IEditorPlugin } from "../Base/types";
import RenderAttachmentElement from "./RenderAttachmentElement";
import AttachementElement from "./AttachmentElement";
import { ATTACHMENT_ELEMENT } from "./consts";

const AttachmentPlugin: IEditorPlugin = {
  renderElement: ({ element, attributes, children }: RenderElementProps) => {
    if (!TypedElement.isTypedElement(element)) {
      return;
    }
    if (AttachementElement.isAttachment(element))
      return (
        <RenderAttachmentElement element={element} attributes={attributes}>
          {children}
        </RenderAttachmentElement>
      );
  },
  inlineTypes: [ATTACHMENT_ELEMENT],
  voidTypes: [ATTACHMENT_ELEMENT],
};

export default AttachmentPlugin;
