import { CSSProperties, Dispatch } from "react";
import { CSS } from "@dnd-kit/utilities";
import { QAAction, IQuestionItem } from "../models";
import QALayout from "./Layout";
import useQuestion from "./useQuestion";
import SortableTreeItem from "../SortableTree/SortableItem";
import { serializePath } from "../SortableTree/utilities";
import classNames from "classnames";
import { SettingsPanelPortal } from "@/components/layout/SettingsPanel";
import QuestionPropertiesForm from "./QuestionSettings/PropertiesForm";
import { useSemaphore } from "../Semaphore";
import { Transition } from "@headlessui/react";
import { IterateQuestions } from "./IterateQuestions";
import Answer from "../Answer/Answer";
import { useTranslation } from "react-i18next";
import { QuestionSettingValues } from "./QuestionSettings";
import { Element } from "slate";
import { IBaseInputElement } from "@/editor/utils/useSlateInputElement";
import { QUANTITY_OBSERVATION_INPUT_ELEMENT } from "@/editor/ObservationInputPlugin/consts";
import { MARKDOWN_INPUT_ELEMENT } from "@/editor/MarkdownInputPlugin/consts";
import { CONCEPT_INPUT_ELEMENT } from "@/editor/ConceptInputPlugin/consts";
import { COMMENTS_INPUT_ELEMENT } from "@/editor/CommentsInputPlugin/consts";
import { CHIP_INPUT_ELEMENT } from "@/editor/ChipInputPlugin/consts";
import { IConceptInputElement } from "@/editor/ConceptInputPlugin/ConceptInputElement";

export interface QuestionAnswerComposerProps {
  question: IQuestionItem;
  dispatch?: Dispatch<QAAction>;
  path: string[];
  indexPath: number[];
  clone?: boolean;
}

function QuestionAnswerComposer({
  question,
  indexPath,
  path,
  dispatch,
  clone,
}: QuestionAnswerComposerProps) {
  const [questionState, { remove, updateContent, updateQuestion }] =
    useQuestion({
      question,
      indexPath,
      path,
      dispatch,
    });
  const pathString = serializePath(path);
  const { t } = useTranslation();
  const { hasSemaphore, request: requestSemaphore } = useSemaphore(
    path.join("-"),
  );
  return (
    <QALayout.Container>
      <SortableTreeItem id={pathString}>
        {({
          attributes,
          listeners,
          setNodeRef,
          transform,
          transition,
          isDragging,
          projected,
        }) =>
          isDragging ? (
            <div
              className={classNames(
                "z-10 -mb-px",
                "relative box-border flex items-center border border-gray-300 bg-white p-2.5 text-gray-700",
                "!h-1.5 border-blue-500 bg-blue-500 !p-0",
                "before:absolute before:-left-2 before:-top-1 before:block before:h-3 before:w-3 before:rounded-full before:border before:border-blue-500 before:bg-white before:content-['']",
                {
                  "border-blue-500 bg-blue-500 before:border-blue-500":
                    projected?.allowDrop != false,
                  "border-red-300 bg-red-500 before:border-red-500":
                    projected?.allowDrop == false,
                },
              )}
              ref={setNodeRef}
              style={
                {
                  transform: CSS.Translate.toString(transform),
                  transition,
                  left: projected && `${projected.depth}rem`,
                } as CSSProperties
              }
            />
          ) : (
            <QALayout.QuestionContainer
              ref={setNodeRef}
              indent={questionState.indent}
              className={classNames("border-collapse rounded-md", {
                "border-l-2 !border-blue-primary": hasSemaphore,
                "border-gray-200": !hasSemaphore,
              })}
            >
              <QALayout.RemoveButton onClick={remove} />
              <QALayout.Toolbar>
                <QALayout.DragHandle
                  {...attributes}
                  {...listeners}
                  style={
                    {
                      transform: CSS.Translate.toString(transform),
                      transition,
                    } as CSSProperties
                  }
                  visible
                  isDragging={isDragging}
                />
              </QALayout.Toolbar>
              <QALayout.Question
                code={question.code}
                onClick={requestSemaphore}
                extraction={question.extraction}
              />
              <QALayout.Answer show>
                <Answer
                  linkId={path.join(".")}
                  content={questionState.answer}
                  onContentChange={updateContent}
                />
              </QALayout.Answer>
            </QALayout.QuestionContainer>
          )
        }
      </SortableTreeItem>
      <QALayout.SubQuestionContainer show={!clone}>
        <IterateQuestions
          questions={questionState.subQuestions}
          path={path}
          indexPath={indexPath}
        >
          {({ question: childQuestion, indexPath, path }) => (
            <QuestionAnswerComposer
              question={childQuestion}
              dispatch={dispatch}
              indexPath={indexPath}
              path={path}
            />
          )}
        </IterateQuestions>
      </QALayout.SubQuestionContainer>
      <SettingsPanelPortal>
        <Transition show={hasSemaphore} enter="ease-in-out duration-75">
          <QuestionPropertiesForm
            actionLabel={t("update-question")}
            defaultValues={getQuestionSettingValues(questionState)}
            onSubmit={updateQuestion}
          />
        </Transition>
      </SettingsPanelPortal>
    </QALayout.Container>
  );
}
export default QuestionAnswerComposer;

type QuestionState = ReturnType<typeof useQuestion>[0];
function getQuestionSettingValues(state: QuestionState): QuestionSettingValues {
  const type = getQuestionType(state);
  const common = {
    coding: state.code.coding[0],
    text: state.code.text,
    mode: "data-entry",
    extraction: null,
    type,
  } as const;
  switch (type) {
    case "coding": {
      const inputElement = getInputElements(
        state.answer,
      )[0] as IConceptInputElement;
      return {
        ...common,
        options: inputElement.options,
        name: inputElement.name,
      };
    }
    default:
      return {
        ...common,
      };
  }
}

function getQuestionType(state: QuestionState) {
  if (!state.answer) {
    if (state.subQuestions.length > 0) return "group";
    return "error";
  }

  const inputElements = getInputElements(state.answer);
  if (inputElements.length === 0) {
    return "error";
  }
  if (inputElements.length === 1) {
    switch (inputElements[0].type) {
      case QUANTITY_OBSERVATION_INPUT_ELEMENT:
        return "quantity";
      case MARKDOWN_INPUT_ELEMENT:
        return "text";
      case CONCEPT_INPUT_ELEMENT:
        return "coding";
      default:
        return "error";
    }
  }
  if (inputElements.length > 1) {
    if (inputElements.every((element) => element.type === CHIP_INPUT_ELEMENT)) {
      return "coding";
    }
    return "group";
  }
  return "error";
}

function getInputElements(
  elements: Element[] | undefined,
): IBaseInputElement[] {
  if (elements == undefined) return [];
  function isInputElement(element: Element): element is IBaseInputElement {
    return "name" in element && "type" in element;
  }
  if (elements.length === 0) {
    return [];
  }
  return elements.reduce((acc, element) => {
    if (isInputElement(element) && element.type !== COMMENTS_INPUT_ELEMENT) {
      return [...acc, element];
    }
    if (Element.isElement(element)) {
      return [
        ...acc,
        ...getInputElements(element.children.filter(Element.isElement)),
      ];
    }
    return acc;
  }, [] as IBaseInputElement[]);
}
