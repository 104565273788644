import { RenderPlaceholderProps, useFocused } from "slate-react";

export const RenderPlaceholder = ({
  attributes,
  children,
}: RenderPlaceholderProps) => {
  const focused = useFocused();
  return (
    <span {...attributes} className="text-gray-primary">
      {focused ? "" : children}
    </span>
  );
};
