import React from "react";
import {
  FieldValues,
  FormProvider,
  SubmitErrorHandler,
  SubmitHandler,
  useForm,
  UseFormProps,
  UseFormReturn,
} from "react-hook-form";
import { func, is } from "superstruct";
import Field from "./Field";
import Label from "./Label";
import Submit from "./Submit";
import Option from "./Option";
import Button from "./Button";
import Input from "./Input";
import Select from "./Select";

export interface TopDownFormProps<TFieldValues extends FieldValues>
  extends UseFormProps<TFieldValues> {
  onSubmit?: SubmitHandler<TFieldValues>;
  onError?: SubmitErrorHandler<TFieldValues>;
  className?: string;
  children?:
    | React.ReactNode
    | ((props: UseFormReturn<TFieldValues>) => React.ReactNode);
}
const NOOP = () => undefined;
/**
 * `<form/>` that is wired up with react-hook-forms wired.
 * Fields are designed to have labels on top, hence the name TopDownForm.
 *
 * If children is a function component then UseForm props are passed.
 * If children are ReactNode, then it will be wrapped with a FormProvider is set.
 */
function TopDownForm<TFieldValues extends FieldValues = FieldValues>({
  onSubmit = NOOP,
  onError,
  children,
  className,
  ...useFormProps
}: TopDownFormProps<TFieldValues>) {
  const methods = useForm(useFormProps);
  const { handleSubmit } = methods;
  return (
    <form onSubmit={handleSubmit(onSubmit, onError)} className={className}>
      <FormProvider {...methods}>
        {is(children, func()) ? children(methods) : children}
      </FormProvider>
    </form>
  );
}
TopDownForm.Option = Option;
TopDownForm.Label = Label;
TopDownForm.Input = Input;
TopDownForm.Select = Select;
TopDownForm.Field = Field;
TopDownForm.Submit = Submit;
TopDownForm.Button = Button;

export default TopDownForm;
