import classNames from "classnames";
import { ErrorMessage } from "formik";
import { useTranslation } from "react-i18next";
import { useActionData } from "react-router";
import { Form } from "react-router-dom";
import { ActionData } from "./createAction";

function ResetPasswordPanel() {
  const { t } = useTranslation();
  const result = useActionData() as ActionData | undefined;
  switch (result?.status) {
    case "success":
      return (
        <div className="space-y-6 bg-white px-4 py-8 shadow sm:space-y-5 sm:rounded-lg sm:px-10">
          <div className="text-sm text-gray-dark">
            <h3 className="text-lg font-medium leading-6 text-gray-900">
              Wachtwoord opnieuw instellen
            </h3>
            <p className="mt-2">
              Er is een email verzonden naar <em>{result.email}</em> met een
              link om je wachtwoord te resetten.
            </p>
            <p className="mt-1">
              <a href="/login" className="decoration text-sm text-blue-primary">
                Ga naar de login pagina.
              </a>
            </p>
          </div>
        </div>
      );
    default: {
      const errorCode =
        result?.status == "error"
          ? result?.errorCode ?? "invalid-email"
          : undefined;
      const errorMessage = errorCode && t(errorCode)!;
      return (
        <div className="space-y-6 bg-white px-4 py-8 shadow sm:space-y-5 sm:rounded-lg sm:px-10">
          <div>
            <h3 className="text-lg font-medium leading-6 text-gray-900">
              Wachtwoord opnieuw instellen
            </h3>
            <p className="mt-1 text-sm text-gray-dark">
              Verzend een link waarmee je je wachtwoord opnieuw kan instellen
              naar je email adres.
            </p>
          </div>
          <Form method="POST" className="space-y-6">
            <div>
              <label
                htmlFor="email"
                className="block text-sm font-medium text-gray-dark"
              >
                Email
              </label>
              <div className="mt-1">
                <input
                  id="email"
                  type="email"
                  name="email"
                  required
                  autoComplete="email"
                  className={classNames(
                    "block w-full appearance-none rounded-md border px-3 py-2 placeholder-gray-400 shadow-sm focus:outline-none sm:text-sm",
                    {
                      "border-gray-light focus:border-blue-500 focus:ring-blue-500":
                        result?.status != "error",
                      "border-red-500 ring-1 ring-red-500":
                        result?.status == "error",
                    }
                  )}
                  placeholder="email"
                />
                {errorMessage && (
                  <p className="mt-1 text-sm text-red-600">{errorMessage}</p>
                )}
              </div>
            </div>

            <div>
              <button
                type="submit"
                className="flex w-full justify-center rounded-md border border-transparent bg-blue-primary px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 disabled:bg-gray-400 hover:disabled:bg-gray-400 focus:disabled:ring-gray-primary"
              >
                Reset mijn wachtwoord
              </button>
            </div>
          </Form>
        </div>
      );
    }
  }
}

export default ResetPasswordPanel;
