import {
  Control,
  FieldPath,
  FieldValues,
  Path,
  PathValue,
  useController,
} from "react-hook-form";
import MarkdownTextEditor from "@/components/MarkdownTextEditor";
import { UseFormRegisterContext, useEntityHandlers } from "./useEntityHandlers";
import RenderEntityTooltip, {
  UseFormGetValuesContext,
} from "./RenderEntityTooltip";
import { DefinedValueSet } from "@/services/content/content-client";
import classNames from "classnames";

export type MarkdownInputProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends Path<TFieldValues> = Path<TFieldValues>,
> = {
  name: TName;
  className?: string;
  required?: boolean;
  disabled?: boolean;
  readOnly?: boolean;
  border?: true | "hide";
  active?: boolean;
  defaultValue?: PathValue<TFieldValues, TName>;
  valueSet?: string | DefinedValueSet;
  placeholder?: string;
  control?: Control<TFieldValues>;
  formContext?: UseFormRegisterContext<TFieldValues> &
    UseFormGetValuesContext<TFieldValues>;
};

function MarkdownInput<TFieldValues extends FieldValues = FieldValues>({
  name,
  className,
  defaultValue,
  placeholder,
  valueSet,
  disabled,
  readOnly = false,
  border = true,
  active,
  required,
  control,
  formContext,
}: MarkdownInputProps<TFieldValues>) {
  const {
    field: { value, onChange, onBlur },
  } = useController({ name, control, defaultValue, rules: { required } });

  const { handleAddEntity, handleRemoveEntity } =
    useEntityHandlers(formContext);
  return (
    <MarkdownTextEditor
      className={classNames(
        "text-gray-dark sm:text-sm",
        "relative flex items-stretch rounded-md px-1.5 py-1 text-sm",
        {
          "cursor-text  border-gray-light": !disabled && !readOnly,
          "cursor-not-allowed border-gray-lighter bg-gray-ultralight text-gray-light":
            disabled,
          "cursor-not-allowed border-gray-light": readOnly,
          "border outline-1 focus-within:border-blue-600 focus-within:outline focus-within:outline-blue-600":
            border != "hide",
          "outline-non border border-transparent focus-within:outline-none":
            border == "hide",
        },
        { "ring ring-blue-400 ring-offset-1": active },
        className,
      )}
      disabled={disabled}
      active={active}
      value={value}
      placeholder={placeholder}
      valueSet={valueSet}
      onChange={onChange}
      onBlur={onBlur}
      onAddEntity={handleAddEntity}
      onRemoveEntity={handleRemoveEntity}
      renderEntity={(id) => (
        <RenderEntityTooltip
          id={id as FieldPath<TFieldValues>}
          context={formContext}
        />
      )}
    />
  );
}

export default MarkdownInput;
