import classNames from "classnames";
import { RenderTypedElementProps } from "../../TypedElement";
import { IInlineEntityElement } from "./types";
import { RenderMarked } from "./StyledEntityContainers";
import { EntityElement } from "./EntityElement";
import { ReactEditor, useSlateStatic } from "slate-react";
import { Transforms } from "slate";
import { IConceptEntity } from "@/data-models/concept-entity";
import { useCallback } from "react";
import { ISuggestEditor } from "../Suggestions/SuggestEditor";
import InlineChromiumBugfix from "@/components/InlineChromiumBugFix";

export interface RenderInlineEntityElementProps
  extends RenderTypedElementProps {
  element: IInlineEntityElement;
}

export const RenderInlineEntityElement = ({
  element,
  attributes,
  children,
}: RenderInlineEntityElementProps) => {
  const { entity, reference } = element;
  const isEmpty = !EntityElement.isEntityElement(element);
  const { code } = reference ?? {};
  const editor = useSlateStatic() as ISuggestEditor;
  const path = ReactEditor.findPath(editor, element);

  const handleEntityChange = useCallback(
    (entity: IConceptEntity) => {
      const path = ReactEditor.findPath(editor, element);
      Transforms.select(editor, path);
      Transforms.setNodes(editor, { entity: entity }, { at: path });
    },
    [element, editor],
  );
  const text = EntityElement.isEntityElement(element)
    ? EntityElement.text(element)
    : element.placeholder ?? "empty entity";

  /** AVR 20220105
   *  inline-block for the span bellow is essential for the selection to work
   *  if absent you can't click behind a inline-void when it is the last element of a block
   */

  return (
    <span
      {...attributes}
      className={classNames("inline-block text-left", {
        "cursor-pointer": element.inline === false,
      })}
    >
      <InlineChromiumBugfix />
      <RenderMarked className={classNames({ "text-primary/60": isEmpty })}>
        {text}
        {children}
      </RenderMarked>
      <InlineChromiumBugfix />
      {reference && (
        <button
          tabIndex={-1}
          className="border-gray material-icons relative top-1 m-1 rounded-md border text-base leading-none hover:border-blue-400 hover:text-blue-primary"
        >
          cached
        </button>
      )}
    </span>
  );
};

export default RenderInlineEntityElement;
