import { IConcept } from "@/data-models/concept";
import { ValidationScore } from "@/services/validation/ValidationScore";
import QuestionValidator from "./QuestionValidator";
import { IQuestionAnswer } from "./types";

export class QuestionnaireScore extends ValidationScore {
  #invalid: IConcept[];
  #valid: IConcept[];
  constructor(valid: IConcept[] = [], invalid: IConcept[] = []) {
    super();
    this.#invalid = invalid;
    this.#valid = valid;
  }
  get total(): number {
    return this.#invalid.length + this.#valid.length;
  }

  score(): number {
    const total = this.countTotal();
    if (total == 0) return 0;
    return this.countValid() / total;
  }

  countTotal(): number {
    return this.total;
  }
  countValid(): number {
    return this.#valid.length;
  }

  countInvalid(): number {
    return this.total - this.#valid.length;
  }

  addValidQuestion(question: IQuestionAnswer) {
    this.#valid.push(question.code);
  }
  addInvalidQuestion(question: IQuestionAnswer) {
    this.#invalid.push(question.code);
  }

  static fromQuestionnaire(questions: IQuestionAnswer[]) {
    const score = new QuestionnaireScore();
    questions
      .filter((q) => q.required)
      .forEach((q) => {
        new QuestionValidator(q).isValid()
          ? score.addValidQuestion(q)
          : score.addInvalidQuestion(q);
      });
    return score;
  }
  get missing(): string[] {
    return this.#invalid.map((c) => c.text);
  }
}
