import BlockErrorBoundary from "@/components/BlockErrorBoundary";
import { DraggableProvidedDragHandleProps } from "react-beautiful-dnd";
import useBlock, {
  useBlockLinkCreator,
  useBlockMutator,
} from "@/services/reports/useBlock";
import BlockContainer from "./common/Container";
import RenderBlockContent from "./content/RenderBlockContent";
import { CurrentReportBlockIdContext } from "@/hooks/useCurrentReportBlockId";
import { AnyRenderTypeModel } from "./content";
import { assert } from "superstruct";
import { BlockIn } from "@/services/reports/report-client";
import useContentFocus from "./useContentFocus";
import BlockLoadingSkeleton from "./BlockLoadingSkeleton";
import { UserTimestamp } from "@/data-models/block";
import BlockDragHandle from "./common/DragHandle";
import { useCallback, useState } from "react";
import BlockMenu from "./common/Menu";
import BlockTitleInput from "./common/TitleInput";
import LastModifiedMenuItem from "./common/Menu/LastModifiedMenuItem";
import { ExportModalView } from "./common/Menu/ExportModalView";
import { ProvideCurrentBlock } from "@/hooks/useCurrentBlock";
import RemoveButton from "@/components/base/RemoveWithWarning/RemoveButton";
import BlockImportButtonGroup from "@/components/BlockImportButtonGroup/BlockImportButtonGroup";
import { useCurrentSubject } from "@/pages/patients/createCurrentSubjectLoader";
import { useFeatureFlagEnabled } from "posthog-js/react";
import { Portal } from "@/components/base/Portal";
import { autoPlacement, useFloating } from "@floating-ui/react";
import { ArrowUpTrayIcon, PencilSquareIcon } from "@heroicons/react/24/outline";
import { useTranslation } from "react-i18next";

export interface BlockProps {
  id: number;
  reportId: number;
  readOnly?: boolean;
  dragHandleProps?: DraggableProvidedDragHandleProps;
  /**
   * Optional function to insert a new block after this block
   */
  insert?: (block: BlockIn) => void;

  /**
   * Optional function to remove this block from the report
   * If not provided, the remove button will not be shown
   */
  remove?: () => void;
}

function RenderBlock({
  id,
  reportId,
  readOnly,
  remove,
  dragHandleProps,
}: BlockProps) {
  const [ref, focus] = useContentFocus();
  const { data: block } = useBlock(id);
  const { mutate: createLink } = useBlockLinkCreator(id);
  const { mutate: update } = useBlockMutator(id);
  const subject = useCurrentSubject();
  const enableBlockImport = useFeatureFlagEnabled("block-import");
  const handleTitleChange = useCallback(
    (title: string) => {
      update({ title });
      focus();
    },
    [update, focus],
  );
  return (
    <BlockLoadingSkeleton block={block}>
      {({ block }) => {
        const { renderType, blockType, title = "", lastModified } = block;

        assert(renderType, AnyRenderTypeModel);
        return (
          <CurrentReportBlockIdContext.Provider value={id}>
            <BlockContainer.Card id={`block-container-${id}`}>
              <BlockContainer.Toolbar>
                <BlockDragHandle {...dragHandleProps} />
              </BlockContainer.Toolbar>
              <BlockContainer.Header>
                <BlockTitleInput
                  title={title}
                  onTitleChange={handleTitleChange}
                  blockType={blockType}
                />
                {enableBlockImport && (
                  <BlockImportButtonGroup
                    blockType={blockType}
                    linkedBlock={
                      block.fromBlock
                        ? {
                            title: block.title,
                            id: block.fromBlock,
                            created: block.created as UserTimestamp,
                          }
                        : null
                    }
                    onLinkedBlockChange={(target) =>
                      target && createLink(target.id)
                    }
                    currentBlockId={id}
                    subjectId={subject.id}
                  />
                )}
                {/*toevoegen:  verslag importeren */}
                {/*toevoegen:  "undo" knop */}
                <RenderBlockMenu lastModified={lastModified as UserTimestamp} />
                {remove && (
                  <RemoveButton
                    onRemove={remove}
                    warningTitle="Bouwblok verwijderen"
                    warningMessage="Weet je zeker dat je deze bouwblok wilt verwijderen?"
                  />
                )}
              </BlockContainer.Header>
              <BlockContainer.Body>
                <BlockErrorBoundary blockId={block.id.toString()}>
                  <RenderBlockContent
                    key={`${block.id}-${block.fromBlock}`} // this is a hack to make sure a block is rerendered when linking it to another block
                    ref={ref}
                    blockId={id}
                    reportId={reportId}
                    renderType={renderType}
                    readOnly={readOnly}
                  />
                </BlockErrorBoundary>
              </BlockContainer.Body>
            </BlockContainer.Card>
          </CurrentReportBlockIdContext.Provider>
        );
      }}
    </BlockLoadingSkeleton>
  );
}

export default RenderBlock;

export function RenderBlockMenu({
  lastModified,
}: {
  lastModified: UserTimestamp;
}) {
  const { t } = useTranslation();
  const [isExportModalOpen, setIsExportModalOpen] = useState(false);
  const { refs, floatingStyles } = useFloating({
    middleware: [autoPlacement({ alignment: "start" })],
  });
  return (
    <BlockMenu.Wrapper>
      <BlockMenu.Button ref={refs.setReference} />
      <Portal>
        <BlockMenu.Items
          ref={refs.setFloating}
          className="absolute right-0 z-20 w-max"
          style={floatingStyles}
        >
          <LastModifiedMenuItem {...(lastModified as UserTimestamp)} />
          {/**<LoadLastVersionMenuItem />*/}
          <BlockMenu.LinkItem to="/templates/1298472" target="_blank">
            <PencilSquareIcon className="inline-block h-4 w-4 stroke-current" />
            <span className="ml-2">Bewerken in Atticus Designer</span>
          </BlockMenu.LinkItem>
          {/**<DuplicateMenuItem disabled />**/}
          {/**<ParseToJSONMenuItem disabled />**}
          {/**<ImportJSONMenuItem disabled />**/}
          <BlockMenu.ButtonItem onClick={() => setIsExportModalOpen(true)}>
            <ArrowUpTrayIcon className="inline h-4 w-4 stroke-current" />
            <span className="ml-2">{t("exporteren-naar-json")}</span>
          </BlockMenu.ButtonItem>
        </BlockMenu.Items>
      </Portal>
      <ProvideCurrentBlock>
        {(block) => (
          <ExportModalView
            block={block}
            isOpen={isExportModalOpen}
            close={() => setIsExportModalOpen(false)}
          />
        )}
      </ProvideCurrentBlock>
    </BlockMenu.Wrapper>
  );
}
