import { Editor, NodeEntry, Path, Transforms } from "slate";
import { ReactEditor } from "slate-react";
import { TypedElement } from "../../TypedElement";
import { CHECK_ELEMENT } from "./consts";
import { ICheckElement } from "./types";

const EMPTY_CHECK_ELEMENT: ICheckElement = {
  type: CHECK_ELEMENT,
  checked: false,
  children: [{ text: "" }],
};

const CheckElement = {
  ...Element,
  isCheckElement(o: any): o is ICheckElement {
    return TypedElement.isTypedElement(o) && o.type === CHECK_ELEMENT;
  },
  toggle(editor: Editor, path: Path) {
    Transforms.select(editor, path);
    const [element] = Editor.node(editor, path) as NodeEntry<ICheckElement>;
    Transforms.setNodes(editor, { checked: !element.checked }, { at: path });
    const nextLeaf = Editor.next(editor);
    if (nextLeaf) Transforms.select(editor, nextLeaf[1]);
    ReactEditor.focus(editor);
  },
  EMPTY: EMPTY_CHECK_ELEMENT,
};

export default CheckElement;
