import { CONFIG } from "@/config";
import { Nav } from "@/components/navbar/NavBar";
import { ProfileMenu } from "@/components/navbar/ProfileMenu";
import { Outlet, useLocation } from "react-router";
import { is, string, type } from "superstruct";
import Aside from "./Aside";

function Layout() {
  const { state } = useLocation();
  const pathname = is(state, type({ from: string() }))
    ? state.from
    : CONFIG.REDIRECT;
  return (
    <div className="relative min-h-screen bg-fill-secondary">
      <Nav className="sticky top-0 z-20">
        <Nav.Left>
          <Nav.Logo />
          <Nav.BackLink to={{ pathname }}>Ga Terug</Nav.BackLink>
        </Nav.Left>
        <Nav.Right>
          <Nav.ProfileSlot>
            <ProfileMenu />
          </Nav.ProfileSlot>
        </Nav.Right>
      </Nav>
      <div className="max-w-8xl absolute bottom-0 top-14 mx-auto w-full items-stretch lg:flex lg:gap-x-16 lg:px-8">
        <Aside />
        <main className="mt-8 px-4 sm:px-6 lg:mt-0 lg:flex-auto lg:px-0 lg:py-20">
          <Outlet />
        </main>
      </div>
    </div>
  );
}
export default Layout;
