import {
  any,
  array,
  Describe,
  enums,
  literal,
  object,
  optional,
} from "superstruct";
import { IQuestionItem } from "@/components/questionnaire-v2";
import { RENDER_TYPE } from "./consts";
import { IQuestionGroup } from "@/components/questionnaire-v2/models";
export interface IQuestionnaireContent {
  questions: (IQuestionItem | IQuestionGroup)[];
  orientation?: "vertical" | "horizontal";
}
export const QuestionnaireContentModel: Describe<IQuestionnaireContent> =
  object({
    orientation: optional(enums(["vertical", "horizontal"])),
    questions: array(any()),
  });

export const RenderTypeModel = literal(RENDER_TYPE);

export default QuestionnaireContentModel;
