import { RenderTiroElementProps } from "../../Base/types";
import { RenderColumnEntry } from "./Columns";
import { RenderQuestionAnswerEntryProps } from "./QuestionAnswer";
import {
  RenderSubsectionElement,
  RenderSubsectionElementProps,
} from "./Subsection";
import { IEntryCollectionElement } from "./types";

export interface RenderEntryCollectionProps extends RenderTiroElementProps {
  element: IEntryCollectionElement;
}
export const RenderEntryCollection = (props: RenderEntryCollectionProps) => {
  const { element } = props;

  switch (element.layout) {
    case "two-column":
      return RenderColumnEntry(
        props as unknown as RenderQuestionAnswerEntryProps
      );
    case "tree":
    default:
      return RenderSubsectionElement(
        props as unknown as RenderSubsectionElementProps
      );
  }
};
