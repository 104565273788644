import {
  FieldValues,
  Path,
  PathValue,
  useFormContext,
  UseFormRegister,
  UseFormUnregister,
} from "react-hook-form";
import { useCallback } from "react";

export function useEntityHandlers<TFieldValues extends FieldValues>(
  formRegisterContext?: UseFormRegisterContext<TFieldValues>
) {
  const formContext = useFormContext<TFieldValues>();
  const { register, unregister } =
    formRegisterContext || formContext || DUMMY_REGISTER_CTX;

  const handleAddEntity = useCallback(
    (id: string, entity: unknown) => {
      console.debug("Register entity", id, entity);
      const name = id as Path<TFieldValues>;
      register(name, { value: entity as PathValue<TFieldValues, typeof name> });
    },
    [register]
  );

  const handleRemoveEntity = useCallback(
    (id: string) => {
      console.log("Unregister entity", id);
      unregister(id as Path<TFieldValues>);
    },
    [unregister]
  );
  return { handleAddEntity, handleRemoveEntity };
}
const DUMMY_REGISTER_CTX = {
  register: () => {
    console.warn("No form context passed to useRegisterEntities.");
  },
  unregister: () => {
    console.debug("No form context passed to useRegisterEntities.");
  },
} as const;
export type UseFormRegisterContext<TFieldValues extends FieldValues> = {
  register: UseFormRegister<TFieldValues>;
  unregister: UseFormUnregister<TFieldValues>;
};
