import { Dialog, Transition } from "@headlessui/react";
import { CONFIG } from "@/config";
import { Fragment, useCallback } from "react";
import { PatientIn } from "@/services/reports/report-client";
import useNewPatient from "@/services/reports/useNewPatient";
import NewPatientForm from "./NewPatientForm";
interface INewSubjectModal {
  open: boolean;
  close: () => void;
}

const NewPatientModal = ({ open, close }: INewSubjectModal) => {
  const { mutate } = useNewPatient();
  const handleNewSubject = useCallback(
    (values: PatientIn) => {
      mutate(values);
      close();
    },
    [close, mutate]
  );

  return (
    <Transition show={open} as={Fragment}>
      <Dialog open={open} onClose={close} className="w-full">
        {/* The backdrop, rendered as a fixed sibling to the panel container */}
        <Transition.Child
          as="div"
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
          className="fixed inset-0 z-10 bg-black/30"
          aria-hidden="true"
        />
        {/* Full-screen container to center the panel */}

        <div className="fixed inset-0 z-10 flex items-center justify-center overflow-y-auto p-4">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <Dialog.Panel className="mx-auto w-full max-w-xl rounded bg-white">
              <NewPatientForm
                onSubmit={handleNewSubject}
                onReset={close}
                showTestPatientButton={CONFIG.isDemo}
              />
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
};

export default NewPatientModal;
