import { UnauthenticatedWhileEmbeddedError } from "@/errors";
import { isLaunchedFrom } from "@/hooks/useLaunchedFrom";
import { QueryClient } from "react-query";
import { LoaderFunction } from "react-router";
import { currentUserQuery } from "@/services/auth/useAuthUser";
import { jumpBack } from "../auth-flow";

export default (queryClient: QueryClient): LoaderFunction =>
  async ({ request }) => {
    const url = new URL(request.url);
    const isSynops = isLaunchedFrom(url, "Synops");
    const currentUser = await queryClient.fetchQuery(currentUserQuery);
    if (currentUser) return jumpBack(url);
    if (isSynops && !currentUser) {
      throw new UnauthenticatedWhileEmbeddedError();
    }
    return null;
  };
