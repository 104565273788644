import Chip from "@/components/base/Chip";
import { ReactEditor, useSlate } from "slate-react";
import { Editor, Transforms } from "slate";
import { CHECK_ELEMENT } from "@/editor/legacy/CheckPlugin/consts";
import { PlusIcon } from "@heroicons/react/20/solid";
import classNames from "classnames";
import CheckElement from "./CheckElement";
import { useTranslation } from "react-i18next";

export const AddCheckElement = () => {
  const editor = useSlate();
  const { t } = useTranslation();
  const handleClick = () => {
    const { selection } = editor;
    const isFocused = ReactEditor.isFocused(editor);
    let index = editor.children.length - 1;
    if (selection && isFocused) index = selection.anchor.path[0];
    const path = [index + 1];
    Editor.withoutNormalizing(editor, () => {
      Transforms.insertNodes(
        editor,
        {
          children: [
            { text: "" },
            { type: CHECK_ELEMENT, children: [{ text: "" }] },
            { text: "" },
          ],
        },
        { at: path }
      );
      Transforms.select(editor, { path: [...path, 1, 0], offset: 0 });
    });
    ReactEditor.focus(editor);
  };
  const [check] = Editor.nodes(editor, {
    match: (n) => CheckElement.isCheckElement(n),
    at: [],
    mode: "all",
  });
  const hasChecks = !!check;
  return (
    <Chip
      tabIndex={-1}
      onClick={handleClick}
      className={classNames("mx-2 border-opacity-0 hover:border-opacity-100", {
        hidden: !hasChecks,
        inline: hasChecks,
      })}
    >
      <PlusIcon className="h-4 w-4 fill-current" />
      <span>{t("voeg-item-toe")}</span>
    </Chip>
  );
};

export default AddCheckElement;
