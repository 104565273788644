import React from "react";
import Input, { InputProps } from "./Input";
import Label, { LabelProps } from "./Label";
import classNames from "classnames";

export interface FieldProps extends InputProps {
  label?: string | null;
  labelProps?: LabelProps;
  InputComponent?:
    | React.ForwardRefExoticComponent<
        React.InputHTMLAttributes<HTMLInputElement> &
          React.RefAttributes<HTMLInputElement>
      >
    | keyof JSX.IntrinsicElements;
  extraText?: React.ReactElement;
}
function FieldWithoutRef(
  {
    className,
    id,
    label,
    labelProps,
    small,
    extraSmall,
    InputComponent = Input,
    extraText,
    children,
    ...inputProps
  }: FieldProps,
  ref?: React.Ref<HTMLInputElement>,
) {
  return (
    <div className={classNames(className, "py-1")}>
      {label && (
        <Label
          htmlFor={id}
          {...labelProps}
          className={classNames("block", labelProps?.className)}
          small={small}
          extraSmall={extraSmall}
        >
          {label}
        </Label>
      )}
      <div>
        <InputComponent
          ref={ref}
          id={id}
          {...inputProps}
          small={small}
          extraSmall={extraSmall}
        >
          {children}
        </InputComponent>
        {extraText && <div className="mt-1">{extraText}</div>}
      </div>
    </div>
  );
}

const Field = React.forwardRef(FieldWithoutRef);
export default Field;
