import { $nodesOfType, LexicalEditor } from "lexical";
import { useEffect, useRef } from "react";
import { MentionNode } from "./MentionNode";

/**
 * Map between mention node keys and their corresponding link id.
 *
 * - The *link id* is a semantically unique identifier for the entity the mention is pointing to.
 * - The *mention node key* is a unique identifier for the mention node.
 * So multiple mentions can point to the same entity and this map keeps track of that.
 * @param editor
 * @returns
 */
function useMentionKeyToIdMap(editor: LexicalEditor) {
  // create a mutable map using a ref
  const nodeKeyToIdRef = useRef<Map<string, string>>(new Map());

  // initialize the map by iterating over all mention nodes
  useEffect(() => {
    editor.getEditorState().read(() => {
      $nodesOfType(MentionNode).forEach((node) => {
        const mentionNode = node as MentionNode;
        const linkId = mentionNode.getHref().substring(1);
        nodeKeyToIdRef.current.set(mentionNode.getKey(), linkId);
      });
    });
  }, [editor, nodeKeyToIdRef]);
  return nodeKeyToIdRef.current;
}

export default useMentionKeyToIdMap;
