import React, { Ref, HTMLAttributes } from "react";
import classNames from "classnames";
import { SubmitHandler, useForm } from "react-hook-form";
import { PatientIn } from "@/services/reports/report-client";
import { useTranslation } from "react-i18next";
import { generateTestPatient } from "./generateTestPatient";
import { IIdentifier } from "@/data-models/value-models/types";
import { CONFIG } from "@/config";

export interface FormValues {
  identifier: string | undefined;
  firstName: string | undefined;
  lastName: string | undefined;
  gender: "male" | "female" | "other" | "";
  dob: string | undefined;
}

function transformIdentifierList(
  value: string | undefined,
): IIdentifier[] | undefined {
  return value !== undefined
    ? [{ value, system: CONFIG.PATIENT_ID_URI }]
    : undefined;
}

function transform(
  handler: SubmitHandler<PatientIn>,
): SubmitHandler<FormValues> {
  return (values) => {
    const { identifier, firstName, lastName, gender, dob } = values;
    const patientIn: PatientIn = {
      identifier: transformIdentifierList(identifier),
      firstName,
      lastName,
      gender: gender || undefined,
      birthDate: dob ? dob : undefined,
    };
    handler(patientIn);
  };
}

export interface NewPatientFormProps
  extends Omit<HTMLAttributes<HTMLDivElement>, "onSubmit" | "onReset"> {
  showTestPatientButton?: boolean;
  onSubmit?: (values: PatientIn) => void;
  onReset?: () => void;
}
const NOOP = () => {};
const NewPatientFormWithoutRef = (
  props: NewPatientFormProps,
  ref: Ref<HTMLFormElement>,
) => {
  const {
    onSubmit: providedHandleSubmit = NOOP,
    onReset: handleReset = NOOP,
    showTestPatientButton,
  } = props;
  const { t } = useTranslation();
  const {
    handleSubmit,
    reset,
    formState: { isValid },
    register,
  } = useForm<FormValues>();

  const handleCreateTestPatient = () => {
    const values = generateTestPatient();
    reset(values, { keepDirtyValues: true, keepDirty: true });
  };

  return (
    <form
      ref={ref}
      className="contents"
      onSubmit={handleSubmit(transform(providedHandleSubmit))}
      onReset={handleReset}
    >
      <div className="mt-5 md:col-span-2 md:mt-0">
        <div className="overflow-hidden shadow sm:rounded-md">
          <div className="bg-white px-4 py-5 sm:p-6">
            <div>
              <h3 className="text-lg font-medium leading-6 text-gray-900">
                {t("make-new-patient.title")}
              </h3>
              <p className="mt-1 text-sm text-gray-primary">
                {t("make-new-patient.desc")}
              </p>
              {showTestPatientButton && (
                <p className="mt-2 text-sm text-gray-primary">
                  <span>Snel een test-patiënt aanmaken?</span>
                  <button
                    type="button"
                    onClick={handleCreateTestPatient}
                    className="ml-1 text-blue-primary hover:text-blue-700 hover:underline"
                  >
                    Genereer een test patiënt
                  </button>
                </p>
              )}
            </div>
            <div className="mt-6 grid grid-cols-6 gap-6">
              <div className="col-span-6 sm:col-span-6">
                <label
                  htmlFor="patient-id"
                  className="block text-sm font-medium text-gray-dark"
                >
                  Identificatienummer
                </label>
                <input
                  {...register("identifier")}
                  type="text"
                  id="patient-id"
                  autoFocus
                  className="mt-1 block w-full rounded-md border-gray-light shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                  required
                />
              </div>

              <div className="col-span-6 sm:col-span-3">
                <label
                  htmlFor="first-name"
                  className="block text-sm font-medium text-gray-dark"
                >
                  Voornaam
                </label>
                <input
                  {...register("firstName")}
                  type="text"
                  id="first-name"
                  className="mt-1 block w-full rounded-md border-gray-light shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                />
              </div>
              <div className="col-span-6 sm:col-span-3">
                <label
                  htmlFor="last-name"
                  className="block text-sm font-medium text-gray-dark"
                >
                  Familienaam
                </label>
                <input
                  {...register("lastName")}
                  type="text"
                  id="last-name"
                  className="mt-1 block w-full rounded-md border-gray-light shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                />
              </div>

              <div className="col-span-6 sm:col-span-3">
                <label
                  htmlFor="gender"
                  className="block text-sm font-medium text-gray-dark"
                >
                  Geslacht
                </label>
                <select
                  {...register("gender")}
                  id="gender"
                  className={classNames(
                    "mt-1 block w-full rounded-md border-gray-light shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm",
                  )}
                  required
                >
                  <option disabled value="">
                    -
                  </option>
                  <option value="male">man</option>
                  <option value="female">vrouw</option>
                  <option value="other">andere</option>
                </select>
              </div>
              <div className="col-span-6 sm:col-span-3">
                <label
                  htmlFor="date-of-birth"
                  className="block text-sm font-medium text-gray-dark"
                >
                  Geboortedatum
                </label>
                <input
                  {...register("dob")}
                  type="date"
                  id="date-of-birth"
                  className="mt-1 block w-full rounded-md border-gray-light shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                  required
                />
              </div>
            </div>
          </div>

          <div className="space-x-2 bg-gray-50 px-4 py-3 text-right sm:px-6">
            <button
              type="reset"
              className="rounded-md border border-gray-light bg-white px-4 py-2 text-sm font-medium text-gray-dark shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 disabled:bg-gray-50 disabled:text-gray-400"
            >
              Annuleren
            </button>
            <button
              type="submit"
              disabled={!isValid}
              className="inline-flex justify-center rounded-md border border-transparent bg-blue-primary px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 disabled:bg-gray-light disabled:text-gray-400"
            >
              Bewaar patiënt
            </button>
          </div>
        </div>
      </div>
    </form>
  );
};

const NewPatientForm = React.forwardRef(NewPatientFormWithoutRef);
export default NewPatientForm;
