export const SMART_PATH = "smart";

export const SMART_ISS_TO_CLIENT_ID = new Map([
  [
    "https://fhir-ehr-code.cerner.com/r4/ec2458f2-1e24-41c8-b71b-0e701af7583d",
    "b0a05985-4f94-4a3a-8951-1a24c9b90b2d",
  ],
  [
    "https://fhir.epic.com/interconnect-fhir-oauth/api/FHIR/R4",
    "a3a4889f-b04c-431b-9f3f-f32fc7867f52",
  ],
  ["https://launch.smarthealthit.org/v/r4/fhir", "smart-launcher"],
  [
    "https://ec2-52-1-14-178.compute-1.amazonaws.com/demo/fhir",
    "jTXwn4BCf3KxsBo7L7ebKilY2SbrgD0ETowmseO98Bs",
  ],
  [
    "https://www.sof-intersystems.be/demo/fhir",
    "jTXwn4BCf3KxsBo7L7ebKilY2SbrgD0ETowmseO98Bs",
  ],
]);

export const SMART_ISS_TO_CLIENT_SECRET = new Map([
  [
    "https://ec2-52-1-14-178.compute-1.amazonaws.com/demo/fhir",
    "PCvP1TlkWps_GSwIAoQnqU2cTR4MREFTm7559jhMyACR4vgUD8DMAZFh2LllNeYiTXnEnyzuauU5Zy93ZvaQdA",
  ],
  [
    "https://www.sof-intersystems.be/demo/fhir",
    "jTXwn4BCf3KxsBo7L7ebKilY2SbrgD0ETowmseO98Bs",
  ],
]);

export const SMART_ISS_TO_DEFAULT_SCOPE = new Map([
  [
    "https://ec2-52-1-14-178.compute-1.amazonaws.com/demo/fhir",
    "launch patient/*.* openid fhirUser",
  ],
  [
    "https://www.sof-intersystems.be/demo/fhir",
    "launch patient/*.* openid fhirUser",
  ],
]);
