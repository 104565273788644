import ChipInput, { getPosition } from "@/components/inputs/ChipInput";
import Chip from "@/components/base/Chip/Chip";
import { createValueSetExpansionQuery } from "@/services/content/useValueSetExpansion";
import {
  conceptAsCoding,
  ICodeableConcept,
  codingToConcept,
  IHumanReadableCoding,
} from "@/data-models/value-models/types";
import { ExpansionContains } from "@/services/content/content-client";
import ObservationCode from "../ObservationCode";
import { Control, FieldValues } from "react-hook-form";
import { PathsMatching } from "@/util/pathsMatching";
import { ConceptPath, IConceptObservation } from "./types";
import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";
import classNames from "classnames";
import { Spinner } from "@/Icons";
import Tippy from "@tippyjs/react";
import { useQuery } from "react-query";
import React from "react";
import Tooltip from "@/components/info-cards/Tooltip";
import { Coding } from "@/data-models/value-models";
import CodingTooltip from "@/components/base/CodingCard/CodingTooltip";
import { useFeatureFlagEnabled } from "posthog-js/react";

export interface ChipObservationInputProps<
  TFieldValues extends FieldValues,
  TName extends ConceptPath<TFieldValues>,
> {
  name: TName;
  className?: string;
  control?: Control<TFieldValues>;
  disabled?: boolean;
  code: ICodeableConcept;
  validCodedValueSet: string;
  normalCodedValueSet?: string;
  abnormalCodedValueSet?: string;
  criticalCodedValueSet?: string;
  active?: boolean;
  hideLabel?: boolean;
}
const CodingChipInput = React.forwardRef(
  ChipInput<ExpansionContains, ICodeableConcept>,
);
function ChipObservationInput<
  TFieldValues extends FieldValues = FieldValues,
  TName extends ConceptPath<TFieldValues> = ConceptPath<TFieldValues>,
>({
  name,
  code,
  disabled,
  active,
  hideLabel,
  validCodedValueSet,
  className,
}: ChipObservationInputProps<TFieldValues, TName>) {
  const {
    data: options = [],
    isError,
    isLoading,
  } = useQuery(createValueSetExpansionQuery(validCodedValueSet));
  const valuePath = `${name}.value` as PathsMatching<
    TFieldValues,
    IConceptObservation
  >;
  const showCodingCardTooltip = useFeatureFlagEnabled(
    "show-coding-card-tooltip",
  );
  return (
    <fieldset
      className={classNames(
        "group/obs inline-flex items-baseline gap-0.5 rounded-sm",
        className,
      )}
      disabled={disabled}
    >
      <p className="ml-1 min-w-[100px] border-l border-gray-lighter p-0.5 pb-2 text-sm font-medium">
        <span className="mr-3 rounded-sm px-1 py-0.5 pl-1.5 group-hover/obs:bg-gray-ultralight">
          <ObservationCode
            name={`${name}.code`}
            code={code}
            hidden={hideLabel}
          />
        </span>
      </p>
      {isError && (
        <Tippy content="Couldn't load options to populate the chip buttons.">
          <Chip
            position="independent"
            disabled
            className="border-orange-500 !pl-1 text-orange-600"
            active={active}
            Icon={
              <ExclamationTriangleIcon
                className="inline h-5 w-5 text-orange-400"
                aria-hidden="true"
              />
            }
          >
            No options
          </Chip>
        </Tippy>
      )}
      {isLoading && (
        <Tippy content="Loading options to populate the chip buttons.">
          <span>
            <Chip
              position="independent"
              disabled
              className={classNames("border-gray-500 text-gray-600")}
              active={active}
              Icon={
                <Spinner
                  className="inline h-5 w-5 animate-spin text-gray-400"
                  aria-hidden="true"
                />
              }
            >
              Loading
            </Chip>
          </span>
        </Tippy>
      )}
      {options.map((option, index) => (
        <Tooltip
          key={`${option.system}|${option.code}`}
          enabled={showCodingCardTooltip}
        >
          <Tooltip.Trigger>
            <CodingChipInput
              name={valuePath}
              equals={(a, b) => a.code === b.code && a.system === b.system}
              getDisplay={(value) => value?.display ?? "Unkown"}
              value={option}
              active={active}
              disabled={disabled}
              transform={{
                input: codingToConcept,
                output: conceptAsCoding,
              }}
              position={getPosition(index, options.length, "left")}
            />
          </Tooltip.Trigger>
          <Tooltip.Content className="fill-gray-lighter">
            <CodingTooltip coding={option as IHumanReadableCoding} />
          </Tooltip.Content>
        </Tooltip>
      ))}
    </fieldset>
  );
}

export default ChipObservationInput;
