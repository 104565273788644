/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { data_contracts__common__fhir__r5__datatypes__complex__Identifier_Input } from './data_contracts__common__fhir__r5__datatypes__complex__Identifier_Input';

export type PatientLinkReference_Input = {
    id?: (string | null);
    type?: PatientLinkReference_Input.type;
    display?: (string | null);
    reference: string;
    identifier?: (data_contracts__common__fhir__r5__datatypes__complex__Identifier_Input | null);
};

export namespace PatientLinkReference_Input {

    export enum type {
        PATIENT = 'Patient',
    }


}

