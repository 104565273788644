export const RENDER_TYPES = [
  "rich-text-with-questionnaire",
  "qa-rich-text",
  "timestamped-rich-text",
  "table-rich-text",
] as const;

// These blocktypes are allowed for legacy reports, but should not be used in new reports
export const LEGACY_RENDER_TYPES = [
  "qa-rich-text",
  "timestamped-rich-text",
  "table-rich-text",
];
