import { Editor } from "slate";
import { SuggestionPosition } from "@/components/legacy/suggestions/types";
import { ISuggestEditor } from "./types";
import detectQuery, { Query } from "../detectQuery";

export type QueryChangeEventHandler = (query: Query | null) => any;

const SuggestEditor = {
  ...Editor,
  detectQuery,
  subscribeQuery: (editor: ISuggestEditor, callback: QueryChangeEventHandler) =>
    editor.subscribeQuery(callback),
  insertSuggestion: (
    editor: ISuggestEditor,
    query: Query,
    position: SuggestionPosition,
    entity: any,
  ) => editor.insertSuggestion(query, position, entity),
};

export default SuggestEditor;
