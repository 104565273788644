import { MarkdownText } from "@/components/MarkdownTextEditor/MarkdownTextEditor";
import { ITypedElement, TypedElement } from "@/editor/TypedElement";
import { FieldPathByValue, FieldValues } from "react-hook-form";
import { DefinedValueSet } from "@/services/content/content-client";
import { COMMENTS_INPUT_ELEMENT } from "./consts";

export interface ICommentInputElement<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPathByValue<TFieldValues, MarkdownText> = FieldPathByValue<
    TFieldValues,
    MarkdownText
  >,
> extends ITypedElement {
  type: typeof COMMENTS_INPUT_ELEMENT;
  name: TName;
  defaultValue?: string;
  valueSet?: string | DefinedValueSet;
  children: [{ text: string }];
}

export const CommentInputElement = {
  ...TypedElement,
  isCommentInputElement(o: unknown): o is ICommentInputElement {
    return TypedElement.isTypedElement(o) && o.type === COMMENTS_INPUT_ELEMENT;
  },
  type: COMMENTS_INPUT_ELEMENT,
};
