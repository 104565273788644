import PID from "@/components/base/PID";
import { CONFIG } from "@/config";
import Identifier from "@/data-models/value-models/identifier";
import React from "react";
import { QueryClient } from "react-query";
import keyFactory from "@/services/reports/keyFactory";
import { PatientOut } from "@/services/reports/report-client";
import { createPatientQuery } from "@/services/reports/usePatient";
import { createReportQuery } from "@/services/reports/useReport";
import { Result } from "./types";
import { Action } from "./Action";

type LaunchKWSStatus = "QUEUED" | "RUNNING" | "READY" | "ERROR";

const ALLOWED_CNR_URIS = [
  "http://tiro.health/fhir/NamingSystem/encounter-id",
  "http://tiro.health/fhir/NamingSystem/nxh-cnr",
];

class LaunchKWSAction extends Action {
  patientId: number;
  reportId: number;
  status: LaunchKWSStatus;
  client: QueryClient;
  constructor({
    client,
    patientId,
    reportId,
    status = "QUEUED",
  }: {
    client: QueryClient;
    patientId: number;
    reportId: number;
    status?: LaunchKWSStatus;
  }) {
    super();
    this.client = client;
    this.status = status;
    this.patientId = patientId;
    this.reportId = reportId;
  }
  retry = undefined;
  get current() {
    return {
      status: this.status,
      renderMessage: this.getMessageRenderFunc(),
    };
  }

  reset() {
    this.updateStatus("QUEUED");
  }

  updateStatus(status: LaunchKWSStatus) {
    this.status = status;
    this.notify(this.current);
  }

  /**
   * Get the KWS EADNR from the patient
   * @returns EADNR if it is found, otherwise null
   */
  async getEADNR() {
    const patient = await this.client.fetchQuery({
      ...createPatientQuery(this.patientId),
      staleTime: Infinity,
    });
    const eadnr = patient.identifier?.find(
      (id) => id.system == "http://tiro.health/fhir/NamingSystem/nxh-eadnr",
    );
    const defaultId = patient.identifier?.find(
      (id) => id.system == "http://tiro.health/fhir/NamingSystem/patient-id",
    );
    return eadnr?.value ?? defaultId?.value ?? null;
  }

  /**
   *  Get the KWS CNR from the report
   * @returns CNR if it is found, otherwise null
   */
  async getCNR() {
    const report = await this.client.fetchQuery({
      ...createReportQuery(this.reportId, { blocksEmbedded: false }),
      staleTime: Infinity,
    });
    const { encounter } = report ?? {};
    if (ALLOWED_CNR_URIS.includes(encounter?.identifier?.system ?? "")) {
      return encounter?.identifier?.value ?? null;
    }
    return null;
  }

  /**
   *  Build the launch URL for KWS
   * @returns launchURL, EADNR and CNR
   */
  async buildLaunchURL() {
    const eadnr = await this.getEADNR();
    const cnr = await this.getCNR();
    const url = new URL("http://localhost:12345/showContact");

    url.searchParams.set("page", "Report");
    if (eadnr) {
      url.searchParams.set("eadnr", eadnr);
    }

    if (cnr) {
      url.searchParams.set("cnr", cnr);
    }
    return { launchURL: url, eadnr, cnr };
  }

  async execute() {
    try {
      this.updateStatus("RUNNING");
      const { launchURL } = await this.buildLaunchURL();
      const result = await fetch(launchURL.toString(), {
        method: "GET",
        mode: "no-cors",
      });
      if (result.status < 400) {
        this.updateStatus("READY");
      } else {
        this.updateStatus("ERROR");
        return [false, new Error("Failed to launch KWS")] as Result;
      }
    } catch (error) {
      this.updateStatus("ERROR");
      return [false, error] as Result;
    }
    return [true, null] as Result;
  }

  get patientDisplayID() {
    const patient = this.client.getQueryData<PatientOut>(
      keyFactory.patient(this.patientId),
    );
    const { identifier = [] } = patient ?? {};
    return identifier && identifier[0].value;
  }

  getMessageRenderFunc(): () => React.ReactNode {
    const messageMap: Record<LaunchKWSStatus, () => JSX.Element> = {
      QUEUED: () => (
        <>
          Pati&euml;nt <PID>{this.patientDisplayID}</PID> openen in KWS.
        </>
      ),
      RUNNING: () => (
        <>
          Bezig met openen van pati&euml;nt <PID>{this.patientDisplayID}</PID>{" "}
          in KWS.
        </>
      ),
      READY: () => (
        <>
          KWS succesvol geopend op pati&euml;nt{" "}
          <PID>{this.patientDisplayID}</PID>.
        </>
      ),
      ERROR: () => (
        <>
          Fout bij openen van pati&euml;nt <PID>{this.patientDisplayID}</PID> in
          KWS. Het lijkt erop dat u niet bent ingelogd in KWS.
        </>
      ),
    };
    return messageMap[this.status];
  }
}
export default LaunchKWSAction;
