import {
  MessageType,
  RequestMessage,
  sendSMARTWebMessage,
} from "@/services/smart-web-messaging/messaging";
import { isWebView2 } from "@/services/smart-web-messaging/webview2-bridge";
import { useCallback, useEffect } from "react";
import { useQueryClient } from "react-query";

type SmartWebMessageHandlerOptions = { enabled?: boolean };
type SendMessageFn = (payload: unknown) => void;

/*
 * This hook is used to handle messages from the host that are of a specific type.
 * @param messageType The type of message to handle.
 * @param handler The function to call when a message of the correct type is received. This function MUST have a stable reference. (i.e. useCallback)
 * @param options The options for the hook.
 */
export default function useSMARTWebMessageHandler(
  messageType: MessageType,
  handler: (payload: unknown) => boolean,
  options: SmartWebMessageHandlerOptions = {},
): SendMessageFn {
  const queryClient = useQueryClient();
  const { enabled = true } = options;
  useEffect(() => {
    if (isWebView2() && enabled) {
      /**
       * Handle the message event from the host and call the handler if the message is of the correct type.
       */
      const messageHandler = (event: MessageEvent) => {
        const raw = event.data;
        const parsed = RequestMessage.safeParse(raw);
        if (!parsed.success) return;
        if (parsed.data.messageType == messageType)
          handler(parsed.data.payload);
      };

      //register
      window.chrome!.webview.addEventListener("message", messageHandler);
      //unregister
      return () => {
        window.chrome!.webview.removeEventListener("message", messageHandler);
      };
    }
  }, [queryClient, enabled, handler, messageType]);

  return useCallback(
    (payload) => sendSMARTWebMessage(messageType, payload),
    [messageType],
  );
}
