import { Component, ErrorInfo, ReactNode } from "react";
import { ReactEditor } from "slate-react";

interface Props {
  children: ReactNode;
  editor: ReactEditor;
}

interface State {
  hasError: boolean;
}

class EditorErrorBoundary extends Component<Props, State> {
  public state: State = {
    hasError: false,
  };

  public static getDerivedStateFromError(): State {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error("Uncaught error while rendering editor.:", error, errorInfo);
  }

  public render() {
    if (this.state.hasError) {
      if (ReactEditor.isFocused(this.props.editor)) {
        ReactEditor.blur(this.props.editor);
      }
    }
    return this.props.children;
  }
}

export default EditorErrorBoundary;
