import { CellProps } from "./Cells";
import PeriodInput from "@/components/inputs/DateInput/PeriodInput";
import { useFormContext } from "react-hook-form";
import {
  DateContent,
  DateContentModel,
  PeriodContent,
  PeriodContentModel,
} from "../types";
import { is } from "superstruct";
import DateInput from "@/components/inputs/DateInput";
import { useState } from "react";

export interface DateOrPeriodCellProps
  extends Omit<CellProps<"DATE_OR_PERIOD">, "type"> {}
const isDate = (content: unknown): content is DateContent =>
  is(content, DateContentModel);
const isPeriod = (content: unknown): content is PeriodContent =>
  is(content, PeriodContentModel);

const DateOrPeriodCell = (props: DateOrPeriodCellProps) => {
  const { name, column } = props;
  const { watch, unregister, register, setValue } = useFormContext();
  const [mode, setMode] = useState<"edit" | "read">("read");
  // called when switching from date to period
  const handleSwitchToPeriod = () => {
    const date = watch(name);
    setMode("edit");
    unregister(name);
    register(`${name}.start`);
    register(`${name}.end`);
    setValue(`${name}.start`, date);
    setValue(`${name}.end`, "");
  };

  // called when switching from period to date
  const handleSwitchToDate = () => {
    const start = watch(`${name}.start`);
    setMode("edit");
    unregister(`${name}.start`);
    register(name);
    setValue(name, start);
  };
  return (
    <>
      {isDate(watch(name)) && (
        <DateInput
          defaultMode={mode == "read" ? "view" : mode}
          required={column.required}
          style="table"
          name={name}
          switchToPeriod={handleSwitchToPeriod}
        />
      )}
      {isPeriod(watch(name)) && (
        <PeriodInput
          defaultMode={mode == "read" ? "view" : mode}
          style="table"
          name={name}
          startName={`${name}.start`}
          endName={`${name}.end`}
          startDateRequired={column.required}
          switchToDate={handleSwitchToDate}
        />
      )}
    </>
  );
};

export default DateOrPeriodCell;
