import { QueryClient } from "react-query";
import { RouteObject } from "react-router";
import { REPORT_DISPATCH_PAGE } from "./const";
import createLoader, { LOADER_CONFIG_DEFAULTS } from "./createLoader";
import Error from "./Error";
import Loader from "./Loader";

export default function createRoute(queryClient: QueryClient): RouteObject {
  return {
    path: `${REPORT_DISPATCH_PAGE}`,
    loader: createLoader({ queryClient, ...LOADER_CONFIG_DEFAULTS }),
    element: <Loader />,
    errorElement: <Error />,
  };
}
