import { RenderElementProps } from "slate-react";
import { IEditorPlugin } from "../../Base/types";
import { TypedElement } from "../../TypedElement";
import { EntityElement } from "../Entities/EntityElement";
import RenderDropdownEntityElement from "./RenderDropdownEntityElement";
import { DROPDOWN_ENTITY_ELEMENT } from "./consts";
import { IDropdownElement } from "./DropdownElement";

export interface RenderDropdownEntityElementProps extends RenderElementProps {
  element: IDropdownElement;
}
export const DropdownEntity = {
  ...EntityElement,
  isDropdownEntityElement: (o: any): o is IDropdownElement => {
    return TypedElement.isTypedElement(o) && o.type === DROPDOWN_ENTITY_ELEMENT;
  },
};
const DropdownPlugin: IEditorPlugin = {
  renderElement: (props) => {
    const { element } = props;
    if (DropdownEntity.isDropdownEntityElement(element)) {
      return <RenderDropdownEntityElement {...props} element={element} />;
    }
    return;
  },
  inlineTypes: [DROPDOWN_ENTITY_ELEMENT],
  voidTypes: [DROPDOWN_ENTITY_ELEMENT],
};

export default DropdownPlugin;
