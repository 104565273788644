import { PageContainer } from "@/components/layout/PageContainer";

const ReportLoading = () => (
  <PageContainer>
    <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl">
      Verslag aan het laden...
    </h2>
  </PageContainer>
);

export default ReportLoading;
