import { MarkdownText } from "@/components/MarkdownTextEditor/MarkdownTextEditor";
import { ITypedElement, TypedElement } from "@/editor/TypedElement";
import { FieldPathByValue, FieldValues } from "react-hook-form";
import { DefinedValueSet } from "services/content/content-client";
import { MARKDOWN_INPUT_ELEMENT } from "./consts";

export interface IMarkdownInputElement<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPathByValue<TFieldValues, MarkdownText> = FieldPathByValue<
    TFieldValues,
    MarkdownText
  >
> extends ITypedElement {
  type: typeof MARKDOWN_INPUT_ELEMENT;
  name: TName;
  defaultValue?: string;
  placeholder?: string;
  valueSet?: string | DefinedValueSet;
  children: [{ text: string }];
}

export const MarkdownInputElement = {
  ...TypedElement,
  isMarkdownInputElement(o: unknown): o is IMarkdownInputElement {
    return TypedElement.isTypedElement(o) && o.type === MARKDOWN_INPUT_ELEMENT;
  },
  type: MARKDOWN_INPUT_ELEMENT,
};
