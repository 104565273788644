import { FieldPathByValue, FieldValues, useFormContext } from "react-hook-form";
import Badge from "../../Badge/Badge";
import StyledInput from "../../Badge/StyledInput";
import { DATE_PRINT_MAP } from "../../DateInput/formatter";
import { IConcept } from "@/data-models/concept";
import ObservationCode from "../ObservationCode";
import { is, nonempty, string } from "superstruct";
import { useTranslation } from "react-i18next";
import { DATE_VALIDATOR_MAP } from "./validator";

interface IDateTimeObservationValue {
  code?: IConcept;
  value: string;
}

export interface DateTimeObservationInputProps<
  TFieldValues extends FieldValues = FieldValues,
> {
  name: FieldPathByValue<TFieldValues, IDateTimeObservationValue>;
  active?: boolean;
  /** The measured quantity accompanied with codes. */
  code: IConcept;
  dateType?: "date" | "month" | "time" | "datetime-local";
  style?: "table" | "tile" | "default";
  /** Wether or not to disable the inputs */
  disabled?: boolean;
  /** Wether or not to show the label */
  hideLabel?: boolean;
  /** Callback when the value is invalid */
  onInvalid?: (value: string) => void;
}

function DateTimeObservationInput<
  TFieldValues extends FieldValues = FieldValues,
>({
  name,
  code,
  active,
  disabled,
  onInvalid,
  dateType = "date",
  style = "default",
  hideLabel = false,
}: DateTimeObservationInputProps<TFieldValues>) {
  const { t } = useTranslation();
  const { register, getValues, getFieldState, formState } =
    useFormContext<TFieldValues>();

  const dateFieldName: FieldPathByValue<TFieldValues, string> =
    `${name}.value` as any;

  return (
    <Badge
      name={name}
      style={style}
      isEmptyFn={({ value }) => !is(value?.value, nonempty(string()))}
      onInvalid={(value) => onInvalid?.(value as string)}
    >
      <Badge.Editor
        active={active}
        prefix={
          <ObservationCode
            name={`${name}.code`}
            code={code}
            hidden={hideLabel}
          />
        }
        disabled={disabled}
      >
        {({ autoFocus }) => (
          <>
            <StyledInput
              type={dateType}
              autoFocus={autoFocus}
              data-testid={dateType}
              invalid={!!getFieldState(name, formState).error}
              {...register(dateFieldName, {
                disabled,
                pattern: DATE_VALIDATOR_MAP[dateType](t),
              })}
              size={10}
            />
          </>
        )}
      </Badge.Editor>
      <Badge.Viewer
        disabled={disabled}
        prefix={
          <ObservationCode
            name={`${name}.code`}
            code={code}
            hidden={hideLabel}
          />
        }
      >
        {() => (
          <time dateTime={getValues(dateFieldName)}>
            {DATE_PRINT_MAP[dateType](getValues(dateFieldName))}
          </time>
        )}
      </Badge.Viewer>
    </Badge>
  );
}

export default DateTimeObservationInput;
