import {
  CheckCircleIcon,
  ExclamationCircleIcon,
  PlayIcon,
} from "@heroicons/react/24/outline";
import { Spinner } from "@/Icons";
import React from "react";
import { ActionStatus } from "@/submit-actions/types";
import { ArrowPathIcon } from "@heroicons/react/24/solid";
import { useTranslation } from "react-i18next";

type TaskItemProps = {
  status: ActionStatus;
  children: React.ReactNode;
  retry?: () => any | Promise<any>;
};

function TaskItem({ children, status, retry }: TaskItemProps) {
  const { t } = useTranslation();
  const showRetry = retry && status == "ERROR";
  return (
    <section className="flex items-center gap-2 px-4 py-1">
      {status == "RUNNING" && (
        <Spinner className="inline h-5 w-5 animate-spin" />
      )}
      {status == "READY" && (
        <CheckCircleIcon className="inline h-5 w-5 text-green-500" />
      )}
      {status == "ERROR" && (
        <ExclamationCircleIcon className="inline h-5 w-5 text-red-500" />
      )}
      {status == "QUEUED" && (
        <div className="inline-block h-5 w-5 rounded-full border border-gray-200" />
      )}
      <p className="text-sm text-gray-700">{children}</p>
      {showRetry ? (
        <button
          onClick={retry}
          className="whitespace-nowrap text-sm font-medium text-blue-500 hover:underline"
        >
          {t("retry")} <ArrowPathIcon className="inline h-4 w-4" />
        </button>
      ) : null}
    </section>
  );
}

function InstructionItem({ children }: { children: React.ReactNode }) {
  return (
    <section className="flex items-center gap-2 px-4 py-3">
      <PlayIcon className="inline h-5 w-5 text-blue-500" />
      <p className="text-sm font-medium text-gray-700">{children}</p>
    </section>
  );
}

export type SubmissionStatusPanelProps = {
  children: React.ReactNode;
  footer?: React.ReactNode;
  title: string;
  description: string;
};

function SubmissionStatusPanel({
  children,
  title,
  footer,
  description,
}: SubmissionStatusPanelProps) {
  return (
    <main className="divide-y divide-gray-200 rounded-md border border-gray-200 bg-white">
      <header className="px-4 py-3">
        <h2 className="flex gap-1 text-lg font-medium text-gray-900">
          {title}
        </h2>
        <p className="text-sm text-gray-500">{description}</p>
      </header>
      <div className="last:rounded-m-md divide-y divide-gray-200 bg-gray-50">
        {children}
      </div>
      <footer className="px-4 py-3">{footer}</footer>
    </main>
  );
}

SubmissionStatusPanel.Task = TaskItem;
SubmissionStatusPanel.Instruction = InstructionItem;

export default SubmissionStatusPanel;
