import { ExclamationCircleIcon } from "@heroicons/react/24/outline";
import { ArrowUpCircleIcon } from "@heroicons/react/24/solid";
import classNames from "classnames";
import formatDistance from "date-fns/formatDistance";
import parseISO from "date-fns/parseISO";
import { nlBE } from "date-fns/locale";
import { saveAllAsync } from "@/hooks/useSave";
import { Spinner } from "@/Icons";
import { useIsMutating, useMutation, useQuery } from "react-query";
import { createReportQuery } from "@/services/reports/useReport";
import { integer, is, literal, tuple } from "superstruct";
interface ReportActionToolbarProps {
  reportId: number;
  className?: string;
}

function ReportActionToolbar({
  className,
  reportId,
}: ReportActionToolbarProps) {
  const { mutate: saveAll, isLoading, isError } = useMutation(saveAllAsync);

  return (
    <form
      className={classNames("flex justify-end gap-1 px-1 py-1.5", className)}
      onSubmit={(event) => saveAll()}
    >
      <div className="flex-grow" />
      <SaveDraft reportId={reportId} />
      <Submit status={isLoading ? "pending" : isError ? "error" : "idle"} />
    </form>
  );
}
export default ReportActionToolbar;

function getLastModifiedRelative(lastModified: string) {
  const lastModifiedParsed = parseISO(lastModified + "Z");
  return formatDistance(lastModifiedParsed, new Date(), {
    addSuffix: true,
    includeSeconds: true,
    locale: nlBE,
  });
}

function SaveDraft({ reportId }: { reportId: number }) {
  // Get the last modified timestamp from the report
  const { data: lastModifiedTimestamp } = useQuery({
    ...createReportQuery(reportId, { blocksEmbedded: false }),
    staleTime: 1000 * 10, // 10 seconds
    refetchInterval: 1000 * 10, // 10 seconds
    select: (data) => data.lastModified.timestamp,
  });

  const lastModifiedRelative =
    lastModifiedTimestamp && getLastModifiedRelative(lastModifiedTimestamp);
  // Check if there are any mutations in progress for the blocks
  const isMutating =
    useIsMutating({
      predicate: (mutation) =>
        is(mutation.options.mutationKey, tuple([literal("blocks"), integer()])),
    }) > 0;

  return (
    <button
      type="button"
      className={classNames(
        "inline-flex items-center justify-center gap-1 rounded-md border border-gray-light drop-shadow-lg",
        "px-4 py-2 sm:w-auto",
        "font-primary text-sm font-normal text-gray-primary",
        "bg-white hover:bg-fill-secondary focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2",
      )}
    >
      {!isMutating && (
        <span className="material-symbols-outlined text-xl font-light text-opacity-70">
          save
        </span>
      )}
      {isMutating && <Spinner className="inline h-5 w-5 animate-spin" />}
      {!isMutating && lastModifiedRelative && (
        <span className="drop-shadow-sm">
          Laatst opgeslaan {lastModifiedRelative}
        </span>
      )}
      {!isMutating && !lastModifiedRelative && (
        <span className="drop-shadow-sm">Nog niet opgeslaan</span>
      )}
      {isMutating && <span className="drop-shadow-sm">Bezig met opslaan</span>}
    </button>
  );
}

function Submit({
  status = "idle",
}: {
  status?: "idle" | "pending" | "error";
}) {
  return (
    <button
      type="submit"
      formAction="./submit"
      className={classNames(
        "inline-flex items-center justify-center gap-1 rounded-md border border-transparent",
        "px-4 py-2 sm:w-auto",
        "font-primary text-base font-medium text-white drop-shadow-lg",
        { "bg-red-500 text-red-50": status == "error" },
        { "bg-gray-light text-gray-primary": status == "pending" },
        {
          "bg-blue-tiro hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2":
            status == "idle",
        },
      )}
    >
      {status == "pending" && (
        <Spinner className="inline h-5 w-5 animate-spin" />
      )}
      {status == "pending" && (
        <span className="drop-shadow-sm">Bezig met indienen</span>
      )}
      {status == "idle" && (
        <ArrowUpCircleIcon className="inline h-5 w-5 drop-shadow-sm" />
      )}
      {status == "idle" && <span className="drop-shadow-sm">Indienen</span>}
      {status == "error" && (
        <ExclamationCircleIcon className="inline h-5 w-5 text-red-500" />
      )}
      {status == "error" && (
        <span className="drop-shadow-sm">Fout bij indienen</span>
      )}
    </button>
  );
}
