import { z } from "zod";

export default z.object({
  code: z.object({
    code: z.string(),
    display: z.string(),
    system: z.string(),
  }),
  text: z.string().nullable(),
  orientation: z.enum(["horizontal", "vertical"]),
  answerType: z.enum(["markdown", "chips", "quantity"]),
});
