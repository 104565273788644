import range from "lodash/range";
import { useMemo } from "react";

const DOTS = "..." as const;

export interface UsePaginationProps {
  total: number | undefined;
  limit: number;
  siblings?: number;
  page: number;
}
/**
 * usePagination hook
 * source: https://www.freecodecamp.org/news/build-a-custom-pagination-component-in-react/
 * @param param0
 * @returns
 */
function usePagination({
  total: totalCount,
  limit: pageSize,
  siblings: siblingCount = 1,
  page: currentPage,
}: UsePaginationProps) {
  const paginationRange = useMemo(() => {
    // if totalCount is 0 or undefined, we return early
    if (!totalCount) return;

    const totalPageCount = Math.ceil(totalCount / pageSize);

    // Pages count is determined as 2x siblingCount + firstPage + lastPage + currentPage + 2x DOTS
    const totalPageNumbers = siblingCount + 6;

    /*
          Case 1:
          If the number of pages is less than the page numbers we want to show in our
          paginationComponent, we return the range [1..totalPageCount]
        */
    if (totalPageNumbers >= totalPageCount) {
      return range(1, totalPageCount + 1);
    }

    /*
            Calculate left and right sibling index and make sure they are within range 1 and totalPageCount
        */
    const leftSiblingIndex = Math.max(currentPage - siblingCount, 1);
    const rightSiblingIndex = Math.min(
      currentPage + siblingCount,
      totalPageCount,
    );

    /*
          We do not show dots just when there is just one page number to be inserted between the extremes of sibling and the page limits i.e 1 and totalPageCount. Hence we are using leftSiblingIndex > 2 and rightSiblingIndex < totalPageCount - 2
        */
    const shouldShowLeftDots = leftSiblingIndex > 2;
    const shouldShowRightDots = rightSiblingIndex < totalPageCount - 2;

    const firstPageIndex = 1;
    const lastPageIndex = totalPageCount;

    /*
            Case 2: No left dots to show, but rights dots to be shown
        */
    if (!shouldShowLeftDots && shouldShowRightDots) {
      let leftItemCount = 3 + 2 * siblingCount;
      let leftRange = range(1, leftItemCount);

      return [...leftRange, DOTS, totalPageCount];
    }

    /*
            Case 3: No right dots to show, but left dots to be shown
            
        */
    if (shouldShowLeftDots && !shouldShowRightDots) {
      let rightItemCount = 3 + 2 * siblingCount;
      let rightRange = range(
        totalPageCount + 1 - rightItemCount + 1,
        totalPageCount + 1,
      );
      return [firstPageIndex, DOTS, ...rightRange];
    }

    /*
            Case 4: Both left and right dots to be shown
        */
    if (shouldShowLeftDots && shouldShowRightDots) {
      let middleRange = range(leftSiblingIndex, rightSiblingIndex + 1);
      return [firstPageIndex, DOTS, ...middleRange, DOTS, lastPageIndex];
    }
  }, [totalCount, pageSize, siblingCount, currentPage]);

  return paginationRange;
}

export default usePagination;
