import { TiroElement } from "../../Base/TiroElement";
import { IDateField, IFreeTextField, IQuantityField } from "./types";

export const DataFieldElement = {
  ...TiroElement,
  isFreeTextField: (o: any): o is IFreeTextField => {
    return "dataType" in o ? o.dataType === "free-text" : true;
  },
  isDateField: (o: any): o is IDateField => {
    if (!TiroElement.isTiroElement(o)) return false;
    return "dataType" in o && o.dataType === "date";
  },
  isQuantityField: (o: any): o is IQuantityField => {
    if (!TiroElement.isTiroElement(o)) return false;
    return "dataType" in o && o.dataType === "quantity";
  },
};
