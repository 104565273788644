import { CheckIcon } from "@heroicons/react/24/outline";
import classNames from "classnames";
import { Spinner } from "@/Icons";
import React from "react";

export interface StyledButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  loading?: boolean;
  success?: boolean;
  group?: "first" | "middle" | "last";
  Icon?: React.ReactNode;
}

function StyledButton_(
  {
    type = "button",
    className,
    children,
    success,
    loading,
    Icon,
    group,
    ...props
  }: StyledButtonProps,
  ref: React.Ref<HTMLButtonElement>
) {
  return (
    <button
      ref={ref}
      type={type}
      disabled={loading}
      className={classNames(
        className,
        "relative inline-flex items-center border border-gray-ultralight px-4 py-2 text-sm font-medium text-gray-ultralight focus:z-10 focus:border-blue-primary focus:outline-none focus:ring-1 focus:ring-blue-primary",
        {
          "rounded-l-md border-r-0": group === "first",
          "rounded-r-md": group === "last",
          "rounded-none border-r-0": group === "middle",
          "rounded-md": !group,
        },
        { "hover:bg-gray-primary hover:text-white": !loading },
        { "cursor-progress": loading, "cursor-pointer": !loading }
      )}
      {...props}
    >
      <span className="h-5">
        {!loading && !success && Icon}
        {loading && (
          <Spinner className="-ml-1 mr-2 h-5 w-5 animate-spin text-gray-ultralight" />
        )}
        {success && (
          <CheckIcon className="-ml-1 mr-2 h-5 w-5 text-gray-ultralight" />
        )}
      </span>
      <span>{children}</span>
    </button>
  );
}
const StyledButton = React.forwardRef(StyledButton_);
export default StyledButton;
