/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BaseHttpRequest } from './core/BaseHttpRequest';
import type { OpenAPIConfig } from './core/OpenAPI';
import { FetchHttpRequest } from './core/FetchHttpRequest';

import { DefaultService } from './services/DefaultService';
import { ExportService } from './services/ExportService';
import { FhirR5Service } from './services/FhirR5Service';
import { V1Service } from './services/V1Service';
import { V2Service } from './services/V2Service';

type HttpRequestConstructor = new (config: OpenAPIConfig) => BaseHttpRequest;

export class ReportClient {

    public readonly default: DefaultService;
    public readonly export: ExportService;
    public readonly fhirR5: FhirR5Service;
    public readonly v1: V1Service;
    public readonly v2: V2Service;

    public readonly request: BaseHttpRequest;

    constructor(config?: Partial<OpenAPIConfig>, HttpRequest: HttpRequestConstructor = FetchHttpRequest) {
        this.request = new HttpRequest({
            BASE: config?.BASE ?? '',
            VERSION: config?.VERSION ?? '1',
            WITH_CREDENTIALS: config?.WITH_CREDENTIALS ?? false,
            CREDENTIALS: config?.CREDENTIALS ?? 'include',
            TOKEN: config?.TOKEN,
            USERNAME: config?.USERNAME,
            PASSWORD: config?.PASSWORD,
            HEADERS: config?.HEADERS,
            ENCODE_PATH: config?.ENCODE_PATH,
        });

        this.default = new DefaultService(this.request);
        this.export = new ExportService(this.request);
        this.fhirR5 = new FhirR5Service(this.request);
        this.v1 = new V1Service(this.request);
        this.v2 = new V2Service(this.request);
    }
}

