import { PlusIcon } from "@heroicons/react/24/outline";
import { useTranslation } from "react-i18next";
import ReportButton from "@/components/base/ReportButton";
import { SettingsPanelPortal } from "@/components/layout/SettingsPanel";
import { Transition } from "@headlessui/react";
import { useSemaphore } from "../Semaphore";
import { IQuestionItem } from "../models";
import QuestionPropertiesForm from "../QuestionAnswer/QuestionSettings/PropertiesForm";
interface AddQuestionButtonProps {}

function AddQuestionButton({
  onAddQuestion,
}: {
  onAddQuestion: (q: IQuestionItem) => void;
}) {
  const { t } = useTranslation();
  const { hasSemaphore, request, release } = useSemaphore("add-question");
  const handleSubmit = (q: IQuestionItem) => {
    onAddQuestion(q);
    release();
  };
  return (
    <>
      <ReportButton
        onClick={request}
        Icon={<PlusIcon className="inline h-4 w-4" />}
        positionIcon="left"
      >
        {t("add-question")}
      </ReportButton>
      <SettingsPanelPortal>
        <Transition show={hasSemaphore} enter="ease-in-out duration-75">
          <QuestionPropertiesForm
            onSubmit={handleSubmit}
            actionLabel={t("add-question")}
          />
        </Transition>
      </SettingsPanelPortal>
    </>
  );
}

export default AddQuestionButton;
