import { useCallback, useContext, useEffect, useRef } from "react";
import { ValidationScoreBoardContext } from "./context";
import { ValidationScore } from "./ValidationScore";

interface UseValidationScoreBoardOptions {
  /**
   * The initial score to use for the current component
   * @default { valid: true, message: "" }
   * */
  initScore: ValidationScore;

  /**
   * If true, the component will throw an error if no ValidationScoreBoard is found in the context
   * @default true
   * */
  failIfNotMounted?: boolean;
}

type UseValidationScoreBoardReturn = readonly [
  ValidationScore,
  (score: ValidationScore) => void
];

function useValidationScoreBoard(
  key: string,
  { initScore, failIfNotMounted = true }: UseValidationScoreBoardOptions
): UseValidationScoreBoardReturn {
  // make sure the initial value provided by the user is stable
  const initScoreRef = useRef(initScore);

  // retrieve the ValidationScoreBoard instance from current context
  const vsb = useContext(ValidationScoreBoardContext);
  if (!vsb && failIfNotMounted) {
    throw Error(
      "Only useValidationScoreBoard inside a <ValidationScoreBoardContext.Provider/>"
    );
  }

  // Register current component as a supplier of a ValidationScore
  // unmounting the compontent will remove the score
  useEffect(() => {
    vsb?.updateScore(key, initScoreRef.current);
    return () => vsb?.removeScore(key);
  }, [key, vsb]);

  const updateScore = useCallback(
    (score: ValidationScore) => {
      vsb?.updateScore(key, score);
    },
    [key, vsb]
  );

  return [vsb?.scoreBoard.get(key) ?? initScore, updateScore] as const;
}

export default useValidationScoreBoard;
