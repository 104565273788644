import { RenderConceptInputElementProps } from ".";
import InlineChromiumBugfix from "@/components/InlineChromiumBugFix";
import CodingDropdownInput from "@/components/inputs/Dropdown/CodingDropdownInput";
import { SettingsPanelPortal } from "@/components/layout/SettingsPanel";
import { Transition } from "@headlessui/react";
import PropertiesForm from "./PropertiesForm";
import useSlateInputElement from "@/editor/utils/useSlateInputElement";
import {
  codingToConcept,
  conceptAsCoding,
} from "@/data-models/value-models/types";
function RenderDropdownInputElement({
  element,
  attributes,
  children,
}: RenderConceptInputElementProps) {
  const { children: _, name: __, type, inputType, ...other } = element;
  const { name, update, showProperties, disabled, active } =
    useSlateInputElement(element);

  return (
    <span {...attributes} tabIndex={-1}>
      <InlineChromiumBugfix />
      <CodingDropdownInput
        disabled={disabled}
        active={active}
        name={name}
        transform={CODEABLECONCEPT_TRANSFORM}
        {...other}
      />
      <InlineChromiumBugfix />
      {children}
      <SettingsPanelPortal>
        <Transition show={showProperties} enter="ease-in-out duration-75">
          <PropertiesForm
            actionLabel="Update dropdown"
            onSubmit={update}
            defaultValues={element}
          />
        </Transition>
      </SettingsPanelPortal>
    </span>
  );
}
export default RenderDropdownInputElement;

const CODEABLECONCEPT_TRANSFORM = {
  input: conceptAsCoding,
  output: codingToConcept,
};
