import useStableCallback from "@/hooks/useStableCallback";
import { useEffect, useState } from "react";

type Listener<TValue> = (obj: TValue) => void;
type Unsubscribe = () => void;

export interface IObservable<TValue> {
  subscribe(listener: Listener<TValue>): Unsubscribe;
  current: TValue;
}

/**
 * Represents an abstract class for creating observable objects.
 */
abstract class Observable<T> {
  listeners: Listener<T>[];

  constructor() {
    this.listeners = [];
  }

  /**
   * Subscribes a listener to the observable.
   * @param listener - The listener function to be subscribed.
   * @returns An unsubscribe function that can be used to remove the listener.
   */
  subscribe(listener: Listener<T>): Unsubscribe {
    this.listeners.push(listener);
    return () => {
      this.listeners = this.listeners.filter((l) => l !== listener);
    };
  }

  notify(obj: T) {
    // Notify the listeners
    this.listeners.forEach((listener) => listener(obj));
  }
}

export default Observable;

type UseObservableOptions<T> = {
  observable: IObservable<T>;
  initialSnapshot?: T | (() => T);
  onLoad?: (obj: T) => void;
};

/**
 * @deprecated Use `useObservable` instead.
 */
export function useObservableLegacy<T>(options: UseObservableOptions<T>) {
  const {
    observable,
    initialSnapshot = () => observable.current,
    onLoad,
  } = options;
  const [snapshot, setSnapshot] = useState<T>(initialSnapshot);
  const onLoadStable = useStableCallback(onLoad);
  useEffect(() => {
    // Subscribe to changes in the sequence
    const unsubscribe = observable.subscribe(setSnapshot);
    // Execute the sequence asynchronously
    onLoadStable?.(observable.current);
    // Unsubscribe from all actions
    return unsubscribe;
    // The sequence is stable, so we don't need to re-run this effect
  }, [onLoadStable, observable]);
  return snapshot;
}

/**
 * Custom hook that manages the state of an observable object.
 * This hook will automatically re-render the component whenever the observable object changes.
 * @param observable - The observable object to be managed.
 * @returns The current state of the observable object.
 */
export function useObservable<TState>(observable: IObservable<TState>) {
  const [state, setState] = useState(() => observable.current);
  useEffect(() => {
    const unsubscribe = observable.subscribe(setState);
    return unsubscribe;
  }, [observable]);
  return state;
}
