import { User } from "firebase/auth";
import { useEffect } from "react";
import { apm } from "@elastic/apm-rum";
import { getTenantAuth } from "@/services/tenant";

const InitAPM = () => {
  // subscribe to the user's auth state
  useEffect(() => {
    // function identify the user in APM
    const identify = (user: User | null) => {
      try {
        if (user) {
          apm.setUserContext({
            id: user.uid,
            email: user.email || undefined,
            username: user.displayName || undefined,
          });

          console.debug("Identifying user in APM");
        } else {
          console.debug("Resetting APM user");
          apm.setUserContext({});
        }
      } catch (e) {
        console.warn("Failed to identify user in PostHog", e);
      }
    };
    // identify the user if already logged in
    const user = getTenantAuth().currentUser;
    if (user) identify(user);
    // subscribe to auth state changes and re-identity
    const unsubscribe = getTenantAuth().onAuthStateChanged(identify);

    return unsubscribe;
  }, []);
  return <></>;
};

export default InitAPM;
