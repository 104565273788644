import { ServerIcon, UserCircleIcon } from "@heroicons/react/24/outline";
import classNames from "classnames";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";
import { PROFILE_SUBPATH } from "./Profile/consts";
import { SERVER_SETTINGS_SUBPATH } from "./ServerSettings/const";

function useNavigation() {
  const { t } = useTranslation();

  const subNavigation = useMemo(
    () => [
      {
        name: t("profiel"),
        href: `${PROFILE_SUBPATH}`,
        Icon: UserCircleIcon,
      },
      /**{
              name: t("gebruikers-instellingen"),
              href: "../settings",
              icon: CogIcon,
            },**/
      {
        name: t("server-instellingen"),
        href: `${SERVER_SETTINGS_SUBPATH}`,
        Icon: ServerIcon,
      },
    ],
    [t]
  );
  const { pathname } = useLocation();
  return subNavigation.map((item) => ({
    ...item,
    current: pathname.endsWith(item.href),
  }));
}
function Aside({ className }: { className?: string }) {
  const navigation = useNavigation();
  const { state } = useLocation();
  return (
    <aside
      className={classNames(
        "flex border-b border-gray-900/5 py-4 lg:block lg:w-64 lg:flex-none lg:border-r lg:py-20",
        className
      )}
    >
      <nav className="flex-none px-4 sm:px-6 lg:px-0">
        <ul
          role="list"
          className="flex gap-x-3 gap-y-1 whitespace-nowrap lg:flex-col"
        >
          {navigation.map((item) => (
            <li key={item.name}>
              <Link
                to={item.href}
                state={state}
                className={classNames(
                  item.current
                    ? "bg-slate-100 text-blue-600"
                    : "text-gray-700 hover:bg-slate-200 hover:text-blue-500",
                  "group flex gap-x-3 rounded-md py-2 pl-2 pr-3 text-sm font-semibold leading-6"
                )}
              >
                <item.Icon
                  className={classNames(
                    item.current
                      ? "text-blue-600"
                      : "text-slate-400 group-hover:text-blue-600",
                    "h-6 w-6 shrink-0"
                  )}
                  aria-hidden="true"
                />
                {item.name}
              </Link>
            </li>
          ))}
        </ul>
      </nav>
    </aside>
  );
}
export default Aside;
