import { BaseEntry } from "./types";

export interface Shortcuts {
  [key: string]: Partial<BaseEntry>;
}

export const SHORTCUTS: Shortcuts = {
  "#": { format: "heading-one", type: "entry-field" },
  "##": { format: "heading-two", type: "entry-field" },
  "[?]": { state: "unknown", type: "entry-field" },
  "[v]": { state: "present", type: "entry-field" },
  "[x]": { state: "absent", type: "entry-field" },
  "[Q]": { format: "bold", type: "entry-field" },
  "Q.": { format: "bold", type: "entry-field" },
};
