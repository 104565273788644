import React, {
  forwardRef,
  Fragment,
  HTMLAttributes,
  SetStateAction,
  useCallback,
  useState,
} from "react";
import QuestionAnswerComposer from "./QuestionAnswer/QuestionAnswerComposer";
import useQuestionnaireController, {
  UseQuestionnaireControllerProps,
} from "./useQuestionnaireController";
import QuestionnaireLayout from "./Layout";
import QALayout, { QuestionAnswerStyleProvider } from "./QuestionAnswer/Layout";
import SortableTree from "./SortableTree/SortableTree";
import { ActionTypes, IQuestionItem, RecursiveQuestionNode } from "./models";
import { IterateQuestions } from "./QuestionAnswer/IterateQuestions";
import createUniqueQuestionKey from "./QuestionAnswer/createUniqueQuestionKey";
import { Dialog, Transition } from "@headlessui/react";
import AddQuestionPanel from "./add-question/AddQuestionPanel";
import ReportButton from "../base/ReportButton";
import { PlusIcon } from "@heroicons/react/24/outline";
export interface QuestionnaireComposerProps
  extends HTMLAttributes<HTMLDivElement>,
    UseQuestionnaireControllerProps {
  orientation?: "vertical" | "horizontal";
}

const ROOT_PATH: string[] = [];
const ROOT_INDEX_PATH: number[] = [];
function QuestionnaireComposerWithoutRef(
  {
    questions: providedQuestions,
    defaultQuestions,
    dispatch: providedDispatch,
    onQuestionsChange,
    orientation = "vertical",
    className,
    ...attributes
  }: QuestionnaireComposerProps,
  ref?: React.Ref<HTMLDivElement>,
) {
  const autoFocusRef = React.useRef<HTMLInputElement>(null);
  const [isAddQuestionDialogOpen, setIsAddQustionDialogOpen] = useState(false);

  const [questions, dispatch] = useQuestionnaireController({
    questions: providedQuestions,
    dispatch: providedDispatch,
    defaultQuestions,
    onQuestionsChange,
  });

  const handleAddQuestion = useCallback(
    (question: IQuestionItem) => {
      dispatch({
        type: ActionTypes.ADD_QUESTION,
        path: [questions.length],
        question,
      });
      setIsAddQustionDialogOpen(false);
    },
    [dispatch, questions.length],
  );

  const handleItemsChange = useCallback(
    (
      updater: SetStateAction<
        RecursiveQuestionNode<(typeof questions)[number]>[]
      >,
    ) => dispatch({ type: ActionTypes.UPDATE_QUESTIONS, updater }),
    [dispatch],
  );

  return (
    <QuestionnaireLayout.Container
      ref={ref}
      className={className}
      {...attributes}
    >
      <QuestionAnswerStyleProvider value={{ orientation }}>
        <QuestionnaireLayout.Body>
          <SortableTree.Manager
            indentationWidth={1}
            items={questions}
            getItemId={createUniqueQuestionKey}
            onItemsChange={handleItemsChange}
          >
            <IterateQuestions
              questions={questions}
              path={ROOT_PATH}
              indexPath={ROOT_INDEX_PATH}
            >
              {({ question, ...props }) =>
                question.repeating ? (
                  <QuestionAnswerComposer
                    dispatch={dispatch}
                    question={question.template}
                    {...props}
                  />
                ) : (
                  <QuestionAnswerComposer
                    dispatch={dispatch}
                    question={question}
                    {...props}
                  />
                )
              }
            </IterateQuestions>
            <SortableTree.DragOverlay>
              {({ activeQuestion }) => (
                <QALayout.Container>
                  <QALayout.QuestionContainer>
                    <QALayout.Toolbar className="bg-white/80">
                      <QALayout.DragHandle visible isDragging />
                    </QALayout.Toolbar>
                    <QALayout.Question
                      code={activeQuestion.code}
                      className="bg-white/80 opacity-50"
                    />
                  </QALayout.QuestionContainer>
                </QALayout.Container>
              )}
            </SortableTree.DragOverlay>
          </SortableTree.Manager>
          <div>
            <ReportButton
              positionIcon="left"
              onClick={() => setIsAddQustionDialogOpen(true)}
              className="flex items-center opacity-80 current-block-hover:opacity-100"
              Icon={<PlusIcon className="inline h-4 w-4" />}
            >
              Voeg vraag toe
            </ReportButton>
          </div>
        </QuestionnaireLayout.Body>
      </QuestionAnswerStyleProvider>
      <Transition.Root show={isAddQuestionDialogOpen} as={Fragment}>
        <Dialog
          open={isAddQuestionDialogOpen}
          onClose={() => setIsAddQustionDialogOpen(false)}
        >
          {/* The backdrop, rendered as a fixed sibling to the panel container */}
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>
          {/* Full-screen scrollable container */}
          <div className="fixed inset-0 w-screen overflow-y-auto">
            {/* Container to center the panel */}
            <div className="flex min-h-full items-center justify-center p-4">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="mx-auto max-w-5xl rounded bg-white p-4">
                  <Dialog.Title className="text-xl font-medium text-gray-darker">
                    Voeg vraag toe
                  </Dialog.Title>
                  <AddQuestionPanel
                    autoFocusRef={autoFocusRef}
                    className="mt-4"
                    onSubmit={handleAddQuestion}
                  />
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </QuestionnaireLayout.Container>
  );
}
const QuestionnaireComposer = forwardRef(QuestionnaireComposerWithoutRef);
export default QuestionnaireComposer;
