import { CONFIG } from "@/config";
import Identifier from "@/data-models/value-models/identifier";
import { formatDateStringToFhir } from "@/text-parsers";
import { parseGenderString } from "@/text-parsers/parse-gender";
import { I18nOptions } from "@/text-parsers/parse-date";
import { LoaderFunctionArgs } from "react-router";
import { PatientIn } from "@/services/reports/client";

/**
 * Parses date of birth from URL params and removes it from the params
 * @param params
 * @param options
 * @returns
 */
export function parseDateFromURLParams(
  params: URLSearchParams,
  options?: I18nOptions,
) {
  const dobString = params.get("dob") || params.get("age");
  params.delete("dob");
  params.delete("age");
  if (!dobString) return null;
  return formatDateStringToFhir(dobString, options);
}

/**
 * Parses gender from URL params and removes it from the params
 * @param params
 * @returns
 */
export function parseGenderFromURLParams(params: URLSearchParams) {
  const genderString = params.get("sex") || params.get("gender");
  params.delete("sex");
  params.delete("gender");
  if (!genderString) return null;
  const gender = parseGenderString(genderString);
  return gender;
}

export function parseSubjectIdentifierFromURLParams(params: URLSearchParams) {
  const subject = params.get("patient") || params.get("subject");
  params.delete("patient");
  params.delete("subject");
  if (!subject) return null;
  const identifier = Identifier.fromToken(subject, CONFIG.PATIENT_ID_URI);
  return identifier;
}

export default function parseURLParams(request: LoaderFunctionArgs["request"]) {
  const url = new URL(request.url);
  const params = url.searchParams;
  const birthDate = parseDateFromURLParams(params) || undefined;
  const gender = parseGenderFromURLParams(params) || undefined;
  const patientIdentifier =
    parseSubjectIdentifierFromURLParams(params) || undefined;

  const identifier = patientIdentifier && [patientIdentifier];
  const patient: PatientIn | undefined = identifier && {
    identifier,
    birthDate,
    gender,
  };
  return { identifier: patientIdentifier, patient, unparsed: params };
}
