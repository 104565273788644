import React from "react";
import client from "./client";
import { ContentClient } from "./content-client";

export const ContentClientContext = React.createContext<ContentClient>(client);
export default ContentClientContext;

export function useContentClient() {
  return React.useContext(ContentClientContext);
}
