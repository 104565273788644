import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon, ChevronDownIcon } from "@heroicons/react/20/solid";
import classNames from "classnames";
import React, { Fragment, Ref, useCallback } from "react";
import { Concept } from "@/data-models/concept";
import {
  autoUpdate,
  FloatingPortal,
  useFloating,
  useMergeRefs,
} from "@floating-ui/react";

import ReportButton from "@/components/base/ReportButton";
import {
  ExclamationTriangleIcon,
  TrashIcon,
} from "@heroicons/react/24/outline";
import Tippy from "@tippyjs/react";

const stringify = <T extends Object | string>(obj: T | null): string => {
  if (typeof obj === "string") {
    return obj;
  }
  return obj?.toString() ?? "";
};

export interface DropdownProps<T extends Object | string>
  extends Omit<
    React.PropsWithChildren<React.ButtonHTMLAttributes<HTMLButtonElement>>,
    "style" | "prefix" | "suffix"
  > {
  options: T[];
  OptionToIcon?: (props: { value: T }) => JSX.Element;
  optionToDisplay?: (option: T) => string | React.ReactNode;
  optionToKey?: (option: T) => string;
  optionToDescription?: (option: T) => string | undefined;
  selected?: T | null;
  prefix?: string | React.ReactNode;
  placeholder?: string;
  suffix?: string | React.ReactNode;
  onSelectedChange: (v: T | null) => void;
  style?: "default" | "table" | "tile";
  error?: boolean;
  active?: boolean;
  allowClear?: boolean;
}

export const Dropdown_ = <T extends Object | string>(
  {
    options,
    selected,
    prefix,
    suffix,
    placeholder,
    className,
    onSelectedChange,
    OptionToIcon,
    optionToDisplay = stringify,
    optionToKey = stringify,
    optionToDescription,
    children,
    allowClear = true,
    style = "default",
    active = false,
    error,
    disabled,
    ...attributes
  }: DropdownProps<T>,
  ref: Ref<HTMLButtonElement>
) => {
  const { floatingStyles, refs, update } = useFloating<HTMLButtonElement>({
    whileElementsMounted: autoUpdate,
    placement: "bottom-start",
  });
  const buttonRef = useMergeRefs([refs.setReference, ref]);

  const by = useCallback(
    (a: T, b: T) => !!a && !!b && optionToKey(a) === optionToKey(b),
    [optionToKey]
  );
  return (
    <Listbox
      value={selected}
      onChange={onSelectedChange}
      by={by}
      disabled={disabled}
    >
      {({ open }) => (
        <>
          <Listbox.Button
            data-testid="dropdown-button"
            ref={buttonRef}
            as={ReportButton}
            enabled={!!selected}
            active={active || open}
            Icon={
              error ? (
                <Tippy content="Er ging iets mis bij het ophalen van de opties.">
                  <span>
                    <ExclamationTriangleIcon
                      className="h-5 w-5 text-orange-400"
                      aria-hidden="true"
                    />
                  </span>
                </Tippy>
              ) : (
                <ChevronDownIcon
                  className={classNames("block h-4 w-4", {
                    "text-blue-dark": !!selected,
                    "text-gray-primary": !selected,
                  })}
                />
              )
            }
            iconClassName="self-center inline-flex items-center rounded-full hover:bg-primary/10 focus:bg-primary/70 focus:text-white focus:outline-none"
            className={classNames(
              className,
              "inline-flex items-baseline rounded px-2.5 py-0.5 text-sm font-medium"
            )}
            {...attributes}
          >
            <span className="pr-1">{prefix}</span>
            {selected ? (
              <span>{optionToDisplay(selected)}</span>
            ) : (
              <span className="w-min whitespace-nowrap font-normal">
                {placeholder && placeholder.length ? placeholder : "..."}
              </span>
            )}
            {children}

            <span className="pl-1">{suffix}</span>
          </Listbox.Button>
          <FloatingPortal>
            <div ref={refs.setFloating} style={floatingStyles}>
              <Transition show={open} as={Fragment}>
                <Listbox.Options
                  // this is a hack to make the positioning of the floating element update when the listbox opens
                  ref={() => update()}
                  static
                  data-testid="dropdown-options"
                  className="absolute z-10 mt-1 max-h-60 min-w-[4rem] overflow-auto rounded-md bg-white py-1 text-base shadow-md ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
                >
                  {options.map((t) => (
                    <Listbox.Option
                      key={optionToKey(t)}
                      className={({ active }) =>
                        classNames(
                          active
                            ? "bg-blue-primary text-white"
                            : "text-gray-900",
                          "relative cursor-default select-none py-1.5 pl-3 pr-9"
                        )
                      }
                      value={t}
                    >
                      {({ selected, active }) => (
                        <>
                          <span
                            className={classNames(
                              selected ? "font-semibold" : "font-normal",
                              "select-none truncate text-sm",
                              "flex items-center"
                            )}
                          >
                            <>{OptionToIcon && <OptionToIcon value={t} />}</>
                            {optionToDisplay(t)}
                          </span>
                          {optionToDescription && optionToDescription(t) && (
                            <span
                              className={classNames(
                                active ? "text-white" : "text-gray-primary",
                                "ml-2 truncate text-xs"
                              )}
                            >
                              {optionToDescription(t)}
                            </span>
                          )}
                          {selected ? (
                            <span
                              className={classNames(
                                active ? "text-white" : "text-blues-600",
                                "absolute inset-y-0 right-0 flex select-none items-center pl-1 pr-2"
                              )}
                            >
                              <CheckIcon
                                className="inline h-4 w-4"
                                aria-hidden="true"
                              />
                            </span>
                          ) : null}
                        </>
                      )}
                    </Listbox.Option>
                  ))}
                  {allowClear && selected && (
                    <Listbox.Option
                      value={null}
                      className={({ active }) =>
                        classNames(
                          active
                            ? "bg-blue-primary text-white"
                            : "text-gray-500",
                          "relative flex cursor-default select-none items-center gap-1 py-1 pl-3 pr-9"
                        )
                      }
                    >
                      <TrashIcon className="h-4 w-4" />
                      <span
                        className={classNames(
                          selected ? "font-normal" : "font-light",
                          "block select-none truncate text-xs"
                        )}
                      >
                        Huidige optie wissen
                      </span>
                    </Listbox.Option>
                  )}
                </Listbox.Options>
              </Transition>
            </div>
          </FloatingPortal>
        </>
      )}
    </Listbox>
  );
};

export const createdTypedDropdown = <T extends string | Object>() =>
  React.forwardRef<HTMLButtonElement, DropdownProps<T>>(Dropdown_);

const ConceptDropdown = createdTypedDropdown<Concept>();
export default ConceptDropdown;
