import { useLocation, useNavigate } from "react-router";

const DebugRoute = () => {
  const location = useLocation();
  const navigate = useNavigate();
  return (
    <div className="text-sm">
      <h1 className="text-base font-medium">DebugRoute</h1>
      <p>location.pathname: {location.pathname}</p>
      <p>location.search: {location.search}</p>
      <p>location.hash: {location.hash}</p>
      <p>location.state: {JSON.stringify(location.state)}</p>
      <button
        className="rounded border border-gray-primary px-1"
        onClick={() => navigate(-1)}
      >
        Go back
      </button>
    </div>
  );
};

export default DebugRoute;
