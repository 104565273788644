import { XMarkIcon } from "@heroicons/react/24/outline";
import classNames from "classnames";
import FieldArray from "@/components/forms/FieldArray";
import TD from "@/components/forms/TopDownForm";
import { useFormContext } from "react-hook-form";
import { EMPTY_CONDITION } from "./PropertiesForm";
import { FieldValues } from "./types";

export function ConditionalityFieldSet({ className }: { className?: string }) {
  const { register, control, setValue, watch } = useFormContext<FieldValues>();
  return (
    <fieldset className={classNames("block", className)}>
      <legend>Conditionality</legend>
      {watch("enabledWhen") ? (
        <FieldArray name="enabledWhen.conditions" control={control}>
          {({ fields, append, remove }) => {
            if (fields.length === 0)
              return (
                <TD.Button
                  small
                  style="outline"
                  onClick={() => append(EMPTY_CONDITION)}
                >
                  Add Condition
                </TD.Button>
              );
            return (
              <>
                {fields.map(({ id }, index) => (
                  <fieldset key={id} className="relative mt-1 pl-2">
                    <legend className="group/conditon-legend block w-full border-b border-gray-lighter pb-1 text-xs font-medium uppercase text-gray-dark">
                      Condition {index + 1}
                      <button
                        type="button"
                        className="absolute right-0 top-0"
                        onClick={() => remove(index)}
                      >
                        <XMarkIcon className="h-4 w-4 text-gray-100 group-hover/condition-legend:text-gray-400" />
                      </button>
                    </legend>
                    <fieldset className="mt-0.5 flex flex-row justify-between">
                      <legend className="block text-sm">Operator</legend>
                      <div>
                        <label className="flex items-center gap-1 text-xs">
                          <input
                            id={`${id}-equal`}
                            type="radio"
                            value="="
                            {...register(
                              `enabledWhen.conditions.${index}.operator`,
                              { required: true },
                            )}
                            className="h-3 w-3 rounded-full border-gray-light text-blue-primary focus:ring-blue-500"
                          />
                          <span>equal</span>
                        </label>
                        <label className="flex items-center gap-1 text-xs">
                          <input
                            id={`${id}-not-equal`}
                            value="!="
                            type="radio"
                            {...register(
                              `enabledWhen.conditions.${index}.operator`,
                              { required: true },
                            )}
                            className="h-3 w-3 rounded-full border-gray-light text-blue-primary focus:ring-blue-500"
                          />
                          <span>not equal</span>
                        </label>
                      </div>
                    </fieldset>
                    <fieldset className="mt-4">
                      <TD.Field
                        type="text"
                        {...register(
                          `enabledWhen.conditions.${index}.operand.display`,
                          {
                            required: true,
                          },
                        )}
                        label="Display:"
                        small
                      />
                      <TD.Field
                        type="text"
                        {...register(
                          `enabledWhen.conditions.${index}.operand.system`,
                          {
                            required: true,
                          },
                        )}
                        label="System:"
                        small
                      />
                      <TD.Field
                        type="text"
                        {...register(
                          `enabledWhen.conditions.${index}.operand.code`,
                          {
                            required: true,
                          },
                        )}
                        label="Code:"
                        small
                      />
                    </fieldset>
                  </fieldset>
                ))}
                <div className="pl-2">
                  <TD.Button
                    small
                    style="outline"
                    onClick={() => append(EMPTY_CONDITION)}
                  >
                    Add Condition
                  </TD.Button>
                </div>
              </>
            );
          }}
        </FieldArray>
      ) : (
        <TD.Button
          small
          style="outline"
          onClick={() =>
            setValue("enabledWhen", {
              conditions: [EMPTY_CONDITION],
              mode: "all",
            })
          }
        >
          Add Condition
        </TD.Button>
      )}
    </fieldset>
  );
}
