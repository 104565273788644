import { IMergeTag } from "@/components/legacy/MergeTag/types";
import { ITypedElement, TypedElement } from "../../TypedElement";
import { MERGE_TAG_ELEMENT } from "./consts";

export interface IMergeTagElement extends ITypedElement {
  type: typeof MERGE_TAG_ELEMENT;
  entity: IMergeTag;
}

export const MergeTagElement = {
  ...TypedElement,
  isMergeTagElement(element: any): element is IMergeTagElement {
    return (
      TypedElement.isTypedElement(element) && element.type === MERGE_TAG_ELEMENT
    );
  },
};
