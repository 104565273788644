import classNames from "classnames";
import { Path } from "slate";
import {
  ReactEditor,
  RenderElementProps,
  useFocused,
  useSelected,
  useSlateStatic,
} from "slate-react";
import { EntityContextWrapper } from "../Entities/ContextWrapper";
import { EntityStyles } from "../Entities/StyledEntityContainers";
import { TripleStateButton } from "./TripleState";
import { QAEntryQuestion } from "./types";
export interface RenderQuestionElementProps extends RenderElementProps {
  element: QAEntryQuestion;
}
export const RenderQuestionElement = ({
  attributes,
  children,
  element,
}: RenderQuestionElementProps) => {
  const editor = useSlateStatic() as ReactEditor;
  const path = ReactEditor.findPath(editor, element);
  const selected = useSelected();
  const focused = useFocused();
  const parentPath = Path.parent(path);
  return (
    <div className="inline-flex w-full font-medium" {...attributes}>
      <TripleStateButton
        className="select-none"
        element={element}
        propagate={parentPath}
      />

      <EntityStyles.Provider value={{ underline: false }}>
        <EntityContextWrapper.Provider value={element.state ?? "present"}>
          <div
            className={classNames(
              "grow rounded-sm px-2 current-entry-focus-within:bg-gray-400/5 current-entry-focus-within:focus-within:bg-gray-800/5 current-entry-hover:bg-gray-400/5 current-entry-hover:hover:bg-gray-800/5",
              { "bg-gray-800/5": selected && focused }
            )}
          >
            {children}
          </div>
        </EntityContextWrapper.Provider>
      </EntityStyles.Provider>
    </div>
  );
};
