import { withHistory } from "slate-history";
import { pipe } from "@/util/pipe";
import { IAnswerEditor } from "./models";
import { createSuggestionEditor } from "@/editor/legacy/Suggestions/SuggestEditor";
import AttachmentPlugin from "@/editor/AttachmentPlugin";
import { IEditorPlugin } from "@/editor/Base/types";
import CalculatorPlugin from "@/editor/CalculatorPlugin";
import withInlineVoid from "@/editor/withInlineVoid";
import { SuggestPlugin } from "@/editor/legacy/Suggestions";
import TextFieldPlugin from "@/editor/TextFieldPlugin/TextFieldPlugin";
import ObservationInputPlugin from "@/editor/ObservationInputPlugin/ObervationInputPlugin";
import CommentsPlugin from "@/editor/CommentsPlugin";
import ConceptInputPlugin from "@/editor/ConceptInputPlugin";
import ChipInputPlugin from "@/editor/ChipInputPlugin/ChipInputPlugin";
import { MarkdownPlugin } from "@/editor/MarkdownInputPlugin/MarkdownPlugin";
import ReferenceInputPlugin from "@/editor/ReferenceInputPlugin";
import CommentsInputPlugin from "@/editor/CommentsInputPlugin";

const PLUGINS: IEditorPlugin[] = [
  ReferenceInputPlugin,
  ObservationInputPlugin,
  ConceptInputPlugin,
  ChipInputPlugin,
  MarkdownPlugin,
  CalculatorPlugin,
  TextFieldPlugin,
  AttachmentPlugin,
  SuggestPlugin,
  CommentsPlugin,
  CommentsInputPlugin,
];

const constructAnswerEditor = (
  options: {
    mute?: boolean;
  } = {},
): IAnswerEditor => {
  const baseEditor = createSuggestionEditor(PLUGINS, options);
  const mixins = [withHistory, withInlineVoid()];
  const fullEditor = pipe(...mixins)(baseEditor);
  return fullEditor;
};

export default constructAnswerEditor;
