import { ConceptModel, IConcept } from "@/data-models/concept";
import { Coding, ITiroQuantity } from "@/data-models/value-models";
import { QuantityModel } from "@/data-models/value-models/structs";
import { immerable } from "immer";
import { Describe, nullable, optional, type } from "superstruct";

export interface IQuantityComponent {
  interprets?: IConcept | null;
  quantity: ITiroQuantity;
}
export const QuantityComponentModel: Describe<IQuantityComponent> = type({
  quantity: QuantityModel,
  interprets: optional(nullable(ConceptModel)),
});

class QuantityComponent implements IQuantityComponent {
  [immerable] = true;
  interprets?: IConcept | null;
  quantity: ITiroQuantity;
  constructor(obj: IQuantityComponent) {
    this.quantity = obj.quantity;
    this.interprets = obj.interprets;
  }
  get unitAsCoding() {
    return new Coding(
      this.quantity.code,
      this.quantity.system,
      this.quantity.unit
    );
  }
}
export default QuantityComponent;
