import { FirebaseError } from "firebase/app";
import {
  createUserWithEmailAndPassword,
  sendEmailVerification,
} from "firebase/auth";
import { ActionFunction, redirect } from "react-router";
import { getTenantAuth } from "@/services/tenant";
import { z } from "zod";
import { ASK_USER_DATA_PATH } from "../ask-user-data/const";
import { VERIFY_EMAIL_PATH } from "../verify-email/const";

export default (): ActionFunction =>
  async ({ request }) => {
    const payload = await request.formData();
    const email = z.string().parse(payload.get("email"));
    const password = z.string().parse(payload.get("password"));
    const auth = getTenantAuth();
    try {
      const user = await createUserWithEmailAndPassword(auth, email, password);
      if (!user.user.emailVerified) {
        const continueURL = new URL(ASK_USER_DATA_PATH, request.url);
        await sendEmailVerification(user.user, { url: continueURL.href });
        return redirect(`/${VERIFY_EMAIL_PATH}`);
      }
    } catch (error) {
      if (error instanceof FirebaseError) {
        return { error };
      }
    }

    return redirect(`/${ASK_USER_DATA_PATH}`);
  };
