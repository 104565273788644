/// <reference types="vite-plugin-svgr/client" />
import React from "react";
import heart from "@/icons/filled/body/heart.svg?react";
import blood_bag from "@/icons/filled/blood/blood_bag.svg?react";
import surgical_sterilization from "@/icons/filled/devices/surgical_sterilization.svg?react";
import respirology from "@/icons/filled/specialties/respirology.svg?react";
import oncology from "@/icons/outline/specialties/oncology.svg?react";
import { bowel, migraine, prostaat } from "./MedicalIcons";
import classNames from "classnames";

const ICON_MAP = {
  prostaat,
  migraine,
  heart,
  blood_bag,
  surgical_sterilization,
  bowel,
  respirology,
  oncology,
} as const;

type MedicalIcon = keyof typeof ICON_MAP;
export const MEDICAL_ICONS = Object.keys(ICON_MAP) as MedicalIcon[];

export interface MedicalIconProps extends React.SVGAttributes<SVGElement> {
  icon: MedicalIcon;
}

function MedicalIcon({
  icon,
  className = "text-gray-primary",
  ...props
}: MedicalIconProps) {
  const Icon = ICON_MAP[icon];
  return <Icon className={classNames("h-10 w-10", className)} {...props} />;
}

export default MedicalIcon;
