import { Transition } from "@headlessui/react";
import React, { useContext } from "react";
import DragHandle from "./DragHandle";
import MinimizeButton from "./MinimizeButton";
import QuestionButton from "./QuestionButton";
import classNames from "classnames";
import RemoveReportButton from "./RemoveButton";
import Tooltip from "@/components/info-cards/Tooltip";
import { IQuestionItem } from "..";
import ConceptTooltip from "@/components/base/CodingCard/ConceptTooltip";
import CodingTooltip from "@/components/base/CodingCard/CodingTooltip";
import { conceptAsCoding } from "@/data-models/value-models/types";

export interface QuestionAnswerStyle {
  border?: false;
  orientation?: "vertical" | "horizontal";
}

const QuestionAnswerStyleContext = React.createContext<QuestionAnswerStyle>({});

export const QuestionAnswerStyleProvider = QuestionAnswerStyleContext.Provider;

export interface ToolbarProps extends React.HTMLProps<HTMLDivElement> {}

function Toolbar({ children, className, style, ...props }: ToolbarProps) {
  const indentSize = 2.5;
  return (
    <div
      style={{ left: `-${indentSize}rem`, ...style }}
      className={classNames(
        `absolute top-1 flex h-4 w-10 items-center justify-end gap-x-1 px-1 text-sm font-medium text-transparent group-hover:text-slate-400 group-hover:hover:text-gray-primary`,
        className,
      )}
      {...props}
    >
      {children}
    </div>
  );
}

export interface QuestionContainerProps
  extends React.HTMLProps<HTMLDivElement> {
  isDragging?: boolean;
  isSubtitle?: boolean;

  /** Indicates wether this question is checked */
  checked?: boolean;

  /** Callback that handles check change request.*/
  onToggleCheck?: () => any;
  indent?: number;
}

function QuestionContainerWithoutRef(
  {
    children,
    indent = 0,
    isSubtitle,
    className,
    ...attributes
  }: QuestionContainerProps,
  ref: React.Ref<HTMLDivElement>,
) {
  return (
    <div
      ref={ref}
      className={classNames(
        "group relative mt-2 flex flex-wrap items-center justify-start gap-x-2 pl-1 pr-10",
        { "rounded-sm border-l border-gray-lighter": !isSubtitle },
        className,
      )}
      style={{ marginLeft: `${indent}rem` }}
      {...attributes}
    >
      {children}
    </div>
  );
}

const QuestionContainer = React.forwardRef(QuestionContainerWithoutRef);
export interface QuestionProps
  extends Omit<React.HTMLAttributes<HTMLButtonElement>, "children"> {
  /** Handle the edit question request. */
  onEdit?: () => void;
  isSubtitle?: boolean;
  code: IQuestionItem["code"];
  extraction?: IQuestionItem["extraction"];
}

function QuestionWithoutRef(
  {
    onEdit: handleEdit,
    code,
    extraction,
    isSubtitle,
    className,
    ...attributes
  }: QuestionProps,
  ref?: React.Ref<HTMLButtonElement>,
) {
  const { orientation } = useContext(QuestionAnswerStyleContext);
  return (
    <dt
      className={classNames(
        "peer relative self-start text-gray-primary",
        {
          "text-sm font-normal": !isSubtitle,
          "text-base font-medium": isSubtitle,
          "w-1/5 max-w-[12rem] ": orientation === "horizontal" && !isSubtitle,
          "w-full": orientation === "vertical" || isSubtitle,
        },
        className,
      )}
      {...attributes}
    >
      <Tooltip
        delay={{ open: 400, close: 200 }}
        allowedPlacements={["top-start", "bottom-start"]}
      >
        <QuestionButton
          className="w-full"
          type="button"
          ref={ref}
          tabIndex={-1}
          onClick={handleEdit}
        >
          <Tooltip.Trigger>
            <span>{code?.text}</span>
          </Tooltip.Trigger>
        </QuestionButton>
        <Tooltip.Content className="fill-gray-lighter">
          <CodingTooltip coding={conceptAsCoding(code)} />
        </Tooltip.Content>
      </Tooltip>
    </dt>
  );
}
const Question = React.forwardRef<
  HTMLButtonElement,
  React.PropsWithChildren<QuestionProps>
>(QuestionWithoutRef);

export interface AnswerContainerProps
  extends Omit<React.HTMLProps<HTMLElement>, "as" | "ref"> {
  show?: boolean;
}
function AnswerContainer({
  show = true,
  className,
  children,
  ...props
}: AnswerContainerProps) {
  const { orientation = "vertical" } = useContext(QuestionAnswerStyleContext);
  return (
    <Transition
      as="dd"
      show={show}
      enter="transition duration-200 ease-in overflow-hidden"
      enterFrom="transform opacity-0"
      enterTo="transform opacity-100"
      leave="transition duration-100 ease-out overflow-hidden"
      leaveFrom="transform opacity-100"
      leaveTo="transform opacity-0"
      className={classNames(className, "text-sm text-gray-900", {
        "mt-1 w-full": orientation === "vertical",
        grow: orientation === "horizontal",
      })}
      {...props}
    >
      {children}
    </Transition>
  );
}
export interface SubQuestionContainerProps {
  show?: boolean;
}

function SubQuestionContainer({
  show = true,
  children,
}: React.PropsWithChildren<SubQuestionContainerProps>) {
  return (
    <Transition
      as="dl"
      show={show}
      enter="transition duration-200 ease-in overflow-hidden"
      enterFrom="transform opacity-0"
      enterTo="transform opacity-100"
      leave="transition duration-100 ease-out overflow-hidden"
      leaveFrom="transform opacity-100"
      leaveTo="transform opacity-0"
      className="relative"
    >
      {children}
    </Transition>
  );
}

function Container({
  className,
  show = true,
  children,
  ...props
}: Omit<React.HTMLProps<HTMLDivElement>, "as" | "ref"> & { show?: boolean }) {
  return (
    <Transition
      as="div"
      show={show}
      className={classNames("pb-2", className)}
      {...props}
    >
      {children}
    </Transition>
  );
}

const QuestionAnswerLayout = {
  Container,
  QuestionContainer,
  RemoveButton: RemoveReportButton,
  Toolbar,
  DragHandle,
  MinimizeButton,
  Question,
  Answer: AnswerContainer,
  SubQuestionContainer,
};

export default QuestionAnswerLayout;
