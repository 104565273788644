/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { LegacyReadTemplateBlock } from "../models/LegacyReadTemplateBlock";
import type { LegacyUpdateTemplateBlock } from "../models/LegacyUpdateTemplateBlock";

import type { CancelablePromise } from "../core/CancelablePromise";
import type { BaseHttpRequest } from "../core/BaseHttpRequest";

export class BlocksService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Get All Blocks
   * Overview of all template blocks available in the Content Engine.
   * @returns any Successful Response
   * @throws ApiError
   */
  public getAllBlocksV1({
    query,
    offset,
    limit = 20,
    embed,
    session,
  }: {
    query: string;
    offset?: number;
    limit?: number;
    embed?: Array<"content">;
    session?: string;
  }): CancelablePromise<any> {
    return this.httpRequest.request({
      method: "GET",
      url: "/v1/blocks/",
      cookies: {
        session: session,
      },
      query: {
        query: query,
        offset: offset,
        limit: limit,
        embed: embed,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Get Block By Id
   * Get template block given it's id.
   * @returns LegacyReadTemplateBlock Successful Response
   * @throws ApiError
   */
  public getBlockByIdV1({
    blockId,
    session,
  }: {
    blockId: string;
    session?: string;
  }): CancelablePromise<LegacyReadTemplateBlock> {
    return this.httpRequest.request({
      method: "GET",
      url: "/v1/blocks/{block_id}",
      path: {
        block_id: blockId,
      },
      cookies: {
        session: session,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Put Block With Id
   * Update a template block given it's id.
   * @returns LegacyReadTemplateBlock Successful Response
   * @throws ApiError
   */
  public putBlockWithIdV1({
    blockId,
    requestBody,
    session,
  }: {
    blockId: string;
    requestBody: LegacyUpdateTemplateBlock;
    session?: string;
  }): CancelablePromise<LegacyReadTemplateBlock> {
    return this.httpRequest.request({
      method: "PUT",
      url: "/v1/blocks/{block_id}",
      path: {
        block_id: blockId,
      },
      cookies: {
        session: session,
      },
      body: requestBody,
      mediaType: "application/json",
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Delete Block With Id
   * Delete a template block given it's id.
   * @returns any Successful Response
   * @throws ApiError
   */
  public deleteBlockWithIdV1({
    blockId,
    session,
  }: {
    blockId: string;
    session?: string;
  }): CancelablePromise<any> {
    return this.httpRequest.request({
      method: "DELETE",
      url: "/v1/blocks/{block_id}",
      path: {
        block_id: blockId,
      },
      cookies: {
        session: session,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }
}
