import ValidationCard from "../components/InfoSideBar/ValidationCard/ValidationCard";
import InfoSideBar from "@/components/InfoSideBar/InfoSideBar";

const LaagVariabeleZorg = () => {
  return (
    <ValidationCard title="Laag Variabele Zorg">
      <ValidationCard.Tags>
        <button className="lg:mt- inline-flex items-baseline rounded-full bg-blue-100 px-1.5 py-0.5 text-xs font-medium text-blue-dark md:mt-2">
          DRG 480
        </button>
        <button className="inline-flex items-baseline rounded-full bg-orange-100 px-1.5 py-0.5 text-xs font-medium text-orange-800 md:mt-2 lg:mt-0">
          Ernstgraad 1
        </button>
      </ValidationCard.Tags>

      <ValidationCard.Content>
        <p className="py-2 text-sm font-medium text-gray-primary">
          Aanwezigheid co-morbiditeiten?
        </p>
        <ul>
          {[
            "diabetes mellitus T2",
            "obesitas",
            "overgewicht",
            "hartdecompensatie",
            "anemie",
          ].map((disorder) => (
            <li key={disorder}>
              <a
                href="#"
                className="block px-2 text-sm  text-gray-400 hover:text-blue-500"
              >
                {disorder}
              </a>
            </li>
          ))}
        </ul>
      </ValidationCard.Content>
    </ValidationCard>
  );
};
export const GeenLaagVariabeleZorg = () => {
  return (
    <ValidationCard title="Laag Variabele Zorg">
      <ValidationCard.Tags>
        <button className="lg:mt- inline-flex items-baseline rounded-full bg-blue-100 px-1.5 py-0.5 text-xs font-medium text-blue-dark line-through md:mt-2">
          DRG 480
        </button>
        <button className="inline-flex items-baseline rounded-full bg-orange-100 px-1.5 py-0.5 text-xs font-medium text-orange-800 md:mt-2 lg:mt-0">
          Ernstgraad 2
        </button>
      </ValidationCard.Tags>
      <ValidationCard.Warning
        title="Opgelet"
        description=" Volgens de informatie in dit verslag, behoort dit verblijf niet meer onder Laag Variabele Zorg DRG 480."
      />
    </ValidationCard>
  );
};

export const QualityPanel = ({ lvz }: { lvz?: boolean }) => {
  return (
    <InfoSideBar>
      <InfoSideBar.Section title="Radicale prostatectomie hospitalisatieverslag">
        {lvz !== undefined &&
          (lvz ? <LaagVariabeleZorg /> : <GeenLaagVariabeleZorg />)}
        {/**<Card3 />**/}
      </InfoSideBar.Section>
    </InfoSideBar>
  );
};
