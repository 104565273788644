import Chip from "@/components/base/Chip";
import EntityTooltip from "@/components/base/EntityTooltip";
import InlineChromiumBugfix from "@/components/InlineChromiumBugFix";
import { ConceptEntityModel } from "@/data-models/concept-entity";
import React, { useCallback } from "react";
import {
  ReactEditor,
  useFocused,
  useSelected,
  useSlateStatic,
} from "slate-react";
import { is } from "superstruct";
import { ISuggestEditor } from "../Suggestions/SuggestEditor";
import { RenderTypedElementProps } from "../../TypedElement";
import CheckElement from "./CheckElement";
import { ICheckElement } from "./types";

export interface RenderCheckElementProps extends RenderTypedElementProps {
  element: ICheckElement;
}
function RenderCheckElement(props: RenderCheckElementProps) {
  const { element, attributes, children } = props;
  const editor = useSlateStatic() as ISuggestEditor;
  const selected = useSelected();
  const focused = useFocused();
  const active = selected && focused;

  const handleClick = useCallback(
    (e: React.MouseEvent) => {
      const path = ReactEditor.findPath(editor, element);
      CheckElement.toggle(editor, path);
      e.preventDefault();
    },
    [element, editor],
  );

  const { entity } = element;

  if (!entity) {
    return (
      <span {...attributes} className="inline-flex !p-0">
        <Chip
          disabled
          tabIndex={-1}
          className="relative -top-1.5 cursor-text space-x-0 leading-7"
        >
          <InlineChromiumBugfix />
          <span className="min-w-[5px] text-center">{children}</span>
          <InlineChromiumBugfix />
        </Chip>
      </span>
    );
  }
  if (is(entity, ConceptEntityModel)) {
    return (
      <span
        {...attributes}
        contentEditable={false}
        className="!m-0 inline-block leading-7"
      >
        <EntityTooltip entity={entity}>
          <span>
            <Chip
              onClick={handleClick}
              tabIndex={-1}
              checked={element.checked}
              color={element.checked || active ? "blue" : "gray"}
              className="mx-px tabular-nums"
              active={active}
            >
              {entity.text}
            </Chip>

            {children}
          </span>
        </EntityTooltip>
      </span>
    );
  }

  return (
    <span {...attributes} contentEditable={false} className="!m-0">
      <Chip
        onClick={handleClick}
        tabIndex={-1}
        checked={element.checked}
        color={element.checked || active ? "blue" : "gray"}
        className="mx-2"
        active={active}
      >
        {entity.toString()}
      </Chip>
      {children}
    </span>
  );
}

export default RenderCheckElement;
