import { Concept, ConceptModel, IConcept } from "@/data-models/concept";
import { IAbstractEntity } from "@/data-models/entity";
import { ITiroQuantity } from "@/data-models/value-models";
import { UCUM } from "@/data-models/value-models/code";
import { CodingModel, QuantityModel } from "@/data-models/value-models/structs";
import {
  assert,
  Describe,
  is,
  literal,
  nullable,
  number,
  optional,
  string,
  type,
} from "superstruct";
import { QuantityOptions } from "./Quantity";
import { IQuantityComponent } from "./QuantityComponent";

export interface ISimpleQuantity {
  text?: string | null;
  quantity: ITiroQuantity;
  components?: IQuantityComponent[];
  format?: RegExp;
  separator?: string;
  interprets?: IConcept | null;
  placeholder?: string;
  type: "quantity";
}

export const SimpleQuantityModel: Describe<ISimpleQuantity> = type({
  type: literal("quantity"),
  quantity: QuantityModel,
  interprets: optional(nullable(ConceptModel)),
  text: optional(nullable(string())),
});

class SimpleQuantity implements ISimpleQuantity, IAbstractEntity {
  readonly type: "quantity" = "quantity";
  interprets?: IConcept | null;
  quantity: ITiroQuantity;
  #placeholder?: string;
  text: string;
  constructor(
    obj: Omit<ISimpleQuantity, "type">,
    options: QuantityOptions = { includeUnitInText: false }
  ) {
    this.quantity = obj.quantity;
    this.interprets = obj.interprets;
    this.#placeholder = obj.placeholder;
    this.text = this.toString() || "";
  }

  get isValid() {
    return is(this.quantity.value, number());
  }

  get value() {
    return this.quantity.value;
  }

  get placeholder() {
    return this.#placeholder || `... ${this.quantity.unit}`;
  }

  toString() {
    if (!this.isValid) return "";
    return `${this.quantity.value} ${this.quantity.unit}`;
  }

  get unitAsConcept(): IConcept | null {
    if (!this.quantity) return null;
    return new Concept({
      text: this.quantity.unit,
      coding: [
        {
          system: this.quantity.system,
          code: this.quantity.code,
          display: this.quantity.unit,
        },
      ],
    });
  }
  set unitAsConcept(concept: IConcept | null) {
    if (!concept) return;
    const ucumCode =
      concept?.coding.find((coding) => coding.system === UCUM) ??
      concept?.coding[0];
    assert(ucumCode, CodingModel);
    this.quantity = {
      value: "",
      code: ucumCode.code,
      system: ucumCode.system,
      unit: ucumCode.display,
    };
  }
  static fromConcepts(unit: Concept, interprets?: Concept) {
    if (!unit.hasCodes())
      throw Error("Unit must have codes in order to be used in a quantity.");
    const unitCoding = unit.coding[0];
    return new SimpleQuantity({
      quantity: {
        unit: unit.text,
        code: unitCoding.code,
        system: unitCoding.system,
        value: "",
      },
      interprets,
    });
  }
  parse(text: string) {
    const value = parseFloat(text);
    this.quantity.value = isNaN(value) ? "" : value;
    this.text = this.toString();
    return this;
  }
}
export default SimpleQuantity;
