import { QueryClient } from "react-query";
import { LoaderFunction } from "react-router";
import {
  CurrentUserQuery,
  currentUserQuery,
} from "@/services/auth/useAuthUser";
import {
  CurrentUserDataOrFalseQuery,
  currentUserDataOrFalseQuery,
} from "@/services/reports/useUserData";
import { LOGIN_PATH } from "../login/const";
import isUserDataComplete from "../isUserDataComplete";
import { jumpBack, redirectAndKeepQueryParams } from "../auth-flow";

export type CreateLoaderConfig = {
  queryClient: QueryClient;
  currentUserQuery: CurrentUserQuery;
  currentUserDataOrFalseQuery: CurrentUserDataOrFalseQuery;
};
export const LOADER_CONFIG_DEFAULTS = {
  currentUserQuery,
  currentUserDataOrFalseQuery,
};
export default (config: CreateLoaderConfig): LoaderFunction =>
  async ({ request }): Promise<Response | null> => {
    const { queryClient, currentUserQuery, currentUserDataOrFalseQuery } =
      config;

    const user = await queryClient.fetchQuery(currentUserQuery);
    if (!user) return redirectAndKeepQueryParams(request.url, `/${LOGIN_PATH}`);

    try {
      // catch user data fetch errors, they are not critical
      const userData = await queryClient.fetchQuery(
        currentUserDataOrFalseQuery
      );
      if (userData && isUserDataComplete(userData))
        return jumpBack(request.url);
    } catch (e) {
      console.warn("Couldn't fetch user data", e);
    }

    return null;
  };
