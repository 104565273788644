import classNames from "classnames";
import { useCallback } from "react";
import { RenderElementProps } from "slate-react";
import { RenderLeafProps } from "slate-react/dist/components/editable";
import { IEditorPlugin } from "./types";

export const useTiroRenderElement = (plugins: IEditorPlugin[]) =>
  useCallback(
    (props: RenderElementProps) => {
      for (const plugin of plugins) {
        const { renderElement } = plugin;
        let jsxElement = renderElement && renderElement(props);
        if (jsxElement) {
          return jsxElement;
        }
      }
      const { children, attributes, element } = props;
      return (
        <div
          {...attributes}
          className={classNames({
            inline: !!element.inline,
          })}
        >
          {children}
        </div>
      );
    },
    [plugins]
  );

export const useTiroRenderLeaf = (plugins: IEditorPlugin[]) =>
  useCallback(
    (props: RenderLeafProps) => {
      for (const plugin of plugins) {
        const { renderLeaf } = plugin;
        let jsxElement = renderLeaf && renderLeaf(props);
        if (jsxElement) {
          return jsxElement;
        }
      }
      const { leaf, attributes, children } = props;
      return (
        <span
          // The following is a workaround for a Chromium bug where,
          // if you have an inline at the end of a block,
          // clicking the end of a block puts the cursor inside the inline
          // instead of inside the final {text: ''} node
          // https://github.com/ianstormtaylor/slate/issues/4704#issuecomment-1006696364
          className={classNames({ "pl-[0.1px]": leaf.text === "" })}
          {...attributes}
        >
          {children}
        </span>
      );
    },
    [plugins]
  );
