import { useEffect } from "react";
import { Element, Node, Transforms } from "slate";
import {
  ReactEditor,
  useFocused,
  useSelected,
  useSlateStatic,
} from "slate-react";
import { reformatDateString } from "@/text-parsers";

export const useDateReformatter = (
  element: Element,
  options?: { enabled?: boolean }
) => {
  const { enabled = true } = options ?? {};
  const selected = useSelected();
  const focused = useFocused();
  const editor = useSlateStatic() as ReactEditor;
  useEffect(() => {
    if (!enabled) return;
    if (!selected || !focused) {
      const path = ReactEditor.findPath(editor, element);
      const { selection } = editor;
      const currentText = Node.string(element);
      const newText = reformatDateString(currentText);
      if (newText) {
        Transforms.insertText(editor, newText, { at: path });
        selection && Transforms.setSelection(editor, selection);
      }
    }
  }, [selected, focused, enabled, editor, element]);
};
