import { TrashIcon } from "@heroicons/react/20/solid";
import { Dispatch, useCallback, useRef, useState } from "react";
import React from "react";
import { DropTargetMonitor, useDrag, useDrop, XYCoord } from "react-dnd";
import RenderAnswer from "./Answer/RenderAnswer";
import { DragTypes } from "./constants";
import { QAAction, IQuestionAnswer, ActionTypes, DragItem } from "./types";
import classNames from "classnames";
import Tippy from "@tippyjs/react";
import { ChevronDownIcon } from "@heroicons/react/24/outline";
import SplitButton from "./SplitButton";
import StyledButton from "./StyledButton";
import { Transition } from "@headlessui/react";
import { useTranslation } from "react-i18next";
import { ITiroElement } from "@/data-models/nodes";

export interface QAItemProps {
  question: IQuestionAnswer;
  dispatch: Dispatch<QAAction>;
  gridTemplateColumns: string; // this can eventually be part of a useContext ??
  path: number[];
  onSplit?: () => void;
}
function rectContains(rect: DOMRect, xy: XYCoord) {
  return (
    xy.x > rect.x &&
    xy.x < rect.x + rect.width &&
    xy.y > rect.y &&
    xy.y < rect.y + rect.height
  );
}

function getDragSide(
  dropElement: React.RefObject<HTMLDivElement>,
  buttonElement: React.RefObject<HTMLButtonElement>,
  monitor: DropTargetMonitor<DragItem>
): "TOP" | "BOTTOM" | "OVER" | null {
  if (!dropElement.current) return null;
  if (!buttonElement.current) return null;

  // Determine mouse position
  const clientOffset = monitor.getClientOffset();
  if (!clientOffset) return null;

  // Determine rectangle on screen
  const questionBoundingRect = dropElement.current.getBoundingClientRect();
  const buttonBoundingRect = buttonElement.current.getBoundingClientRect();

  if (rectContains(buttonBoundingRect, clientOffset)) return "OVER";

  // Get vertical middle
  const questionMiddleY =
    (questionBoundingRect.bottom - questionBoundingRect.top) * 0.5 +
    questionBoundingRect.top;

  // Get pixels to the top
  const hoverClientY = clientOffset.y;

  // Only perform the move when the mouse has crossed half of the items height
  // When dragging downwards, only move when the cursor is below 50%
  // When dragging upwards, only move when the cursor is above 50%
  if (
    hoverClientY > questionMiddleY &&
    hoverClientY < questionBoundingRect.bottom
  )
    return "BOTTOM";
  if (hoverClientY < questionMiddleY && hoverClientY > questionBoundingRect.top)
    return "TOP";
  return null;
}

function QAItem({
  question: currentQuestion,
  path,
  dispatch,
  gridTemplateColumns,
  onSplit: handleSplit,
}: QAItemProps) {
  const questionButtonRef = useRef<HTMLButtonElement>(null);
  const dropRef = useRef<HTMLDivElement>(null);
  const hasSubQuestions = "questions" in currentQuestion;
  const isTopLevel = path.length == 1;
  const isMinimizable = isTopLevel && hasSubQuestions;
  const allowBottomDropZone = !hasSubQuestions;
  const { answer } = currentQuestion;

  const [nestedQuestionVisible, setNestedQuestionsVisible] =
    useState(isMinimizable);

  const [showDropZone, setShowDropZone] = useState<
    "TOP" | "BOTTOM" | "OVER" | null
  >(null);

  const [{ isDragging }, dragRef, previewRef] = useDrag<
    DragItem,
    unknown,
    { isDragging: boolean }
  >(
    () => ({
      type: DragTypes.QUESTION_ANSWER,
      item: { ...currentQuestion, path },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
    }),
    [currentQuestion, path.join()]
  );

  const [{ isOver }, drop] = useDrop<DragItem, void, { isOver: boolean }>(
    {
      accept: DragTypes.QUESTION_ANSWER,
      collect(monitor) {
        return {
          isOver: monitor.isOver(),
        };
      },
      drop(item, monitor) {
        if (!dropRef.current) return setShowDropZone(null);
        const dropSide = getDragSide(dropRef, questionButtonRef, monitor);
        switch (dropSide) {
          case "TOP":
            return dispatch({
              type: ActionTypes.MOVE_QUESTION,
              src: item.path,
              dest: path,
            });
          case "OVER":
            return dispatch({
              type: ActionTypes.MOVE_QUESTION,
              src: item.path,
              dest: [...path, 0],
            });
          case "BOTTOM": {
            if (!allowBottomDropZone) return;
            let bottomPath = [...path];
            bottomPath[path.length - 1] += 1;
            return dispatch({
              type: ActionTypes.MOVE_QUESTION,
              src: item.path,
              dest: bottomPath,
            });
          }
        }
      },
      hover(item, monitor) {
        if (!dropRef.current) return setShowDropZone(null);
        let dragSide = getDragSide(dropRef, questionButtonRef, monitor);
        if (!allowBottomDropZone && dragSide === "BOTTOM") dragSide = null;
        setShowDropZone(dragSide);
      },
    },
    [path.join(), allowBottomDropZone, dispatch, setShowDropZone, getDragSide]
  );

  const handleUpdateAnswer = useCallback(
    (answer: ITiroElement[]) => {
      dispatch({ type: ActionTypes.UPDATE_ANSWER, answer, path });
    },
    [dispatch, path]
  );

  const handleRemoveQuestion = useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault();
      dispatch({ type: ActionTypes.REMOVE_QUESTION, path });
    },
    [dispatch, path]
  );
  const { t } = useTranslation();
  const indent = path.length - 1;
  drop(dropRef);
  return (
    <div className="relative">
      <div
        className="absolute z-10 w-full sm:grid"
        style={{ gridTemplateColumns }}
      >
        <div
          className={classNames(
            "h-2 transition duration-300 ease-in-out sm:col-span-3 sm:col-start-2",
            {
              "bg-transparent": showDropZone !== "TOP" || !isOver,
              "bg-blue-light": isOver && showDropZone == "TOP",
            }
          )}
          style={{ marginLeft: `${indent * 16}px`, marginRight: "16px" }}
        />
      </div>
      {handleSplit && <SplitButton onClick={handleSplit} />}
      <div ref={previewRef}>
        <div
          ref={dropRef}
          className="group w-full py-0.5 sm:col-span-full sm:grid sm:gap-1 sm:py-1"
          style={{ gridTemplateColumns }}
        >
          <div className="mx-0.5 my-3.5 hidden h-4 w-12 space-x-1 justify-self-end text-sm font-medium text-transparent group-hover:text-gray-400 sm:flex">
            <Tippy content={t("verwijder-deze-vraag")} delay={[300, 50]}>
              <button
                type="button"
                className="relative flex items-center group-hover:hover:text-gray-primary"
                tabIndex={-1}
                onClick={handleRemoveQuestion}
              >
                <TrashIcon className="h-4 w-4" />
              </button>
            </Tippy>
            <Tippy content="Verplaats deze vraag" delay={[300, 50]}>
              <button
                ref={dragRef}
                type="button"
                tabIndex={-1}
                className={classNames(
                  "block h-4 w-4 group-hover:hover:text-gray-primary",
                  {
                    "cursor-grab": !isDragging,
                    "cursor-grabbing": isDragging,
                  }
                )}
              >
                <span className="material-icons flex h-4 w-4 items-center text-xl !leading-5">
                  drag_indicator
                </span>
              </button>
            </Tippy>
          </div>
          <div className="mx-0.5 my-3.5 h-4 items-center space-x-1 sm:flex">
            <button
              type="button"
              tabIndex={-1}
              disabled={!isMinimizable}
              className={classNames({
                "text-transparent": !isMinimizable,
                "text-gray-400": isMinimizable,
              })}
              onClick={() => setNestedQuestionsVisible((v) => !v)}
            >
              <ChevronDownIcon
                className={classNames(
                  "h-4 w-4 stroke-current transition-transform ease-in-out",
                  { "-rotate-90": !nestedQuestionVisible }
                )}
              />
            </button>
            <input
              type="checkbox"
              checked={currentQuestion["checked"] == true}
              onChange={() => {
                dispatch({ type: ActionTypes.TOGGLE_STATE, path });
              }}
              className={classNames(
                "rounded border-gray-light text-blue-primary focus:ring-blue-500",
                // @ts-ignore
                { invisible: currentQuestion["enableWhen"] == undefined }
              )}
            />
            {/*<CheckButton
              className={classNames({
                invisible: !currentQuestion.state,
                visible: !!currentQuestion.state,
              })}/>*/}
          </div>
          <dt
            className="peer relative py-1 text-sm font-medium text-gray-primary"
            style={{ paddingLeft: `${indent * 16}px` }}
          >
            <div className="absolute left-0 flex py-1.5">
              {Array(indent)
                .fill("·")
                .map((s, i) => (
                  <div
                    className={classNames("flex w-4 justify-center")}
                    key={i}
                  >
                    {s}
                  </div>
                ))}
            </div>
            <div>
              <Tippy content="Bewerk deze vraag" delay={[300, 50]}>
                <StyledButton
                  ref={questionButtonRef}
                  tabIndex={-1}
                  type="button"
                  className={classNames("border-2 text-left", {
                    "border-blue-light ring-blue-light":
                      showDropZone === "OVER" && isOver,
                    "border-transparent ring-transparent":
                      showDropZone !== "OVER" || !isOver,
                  })}
                >
                  {currentQuestion.code.text}
                </StyledButton>
              </Tippy>
            </div>
          </dt>
          {answer && currentQuestion.enableWhen != "CHECKED" && (
            <RenderAnswer
              answer={answer}
              onAnswerChange={handleUpdateAnswer}
              className="mx-0.5 mt-1 self-center rounded-md border border-gray-light focus-within:border-blue-tiro sm:col-span-1 sm:col-start-4 sm:mt-0"
            />
          )}
        </div>
        <Transition
          show={!isMinimizable || nestedQuestionVisible}
          enter="transition duration-200 ease-in overflow-hidden"
          enterFrom="transform opacity-0"
          enterTo="transform opacity-100"
          leave="transition duration-100 ease-out overflow-hidden"
          leaveFrom="transform opacity-100"
          leaveTo="transform opacity-0"
        >
          <dl>
            {currentQuestion.questions?.map((childQuestion, index) => (
              <QAItem
                key={childQuestion.code.text}
                question={childQuestion}
                dispatch={dispatch}
                gridTemplateColumns={gridTemplateColumns}
                path={[...path, index]}
              />
            ))}
          </dl>
        </Transition>
      </div>
      <div
        className="absolute z-10 w-full sm:grid"
        style={{ gridTemplateColumns }}
      >
        <div
          className={classNames(
            "h-2 transition duration-300 ease-in-out sm:col-span-3 sm:col-start-2",
            {
              "bg-transparent": showDropZone !== "BOTTOM" || !isOver,
              "bg-blue-light": isOver && showDropZone == "BOTTOM",
            }
          )}
          style={{ marginLeft: `${indent * 16}px`, marginRight: "16px" }}
        />
      </div>
    </div>
  );
}
export default QAItem;
