import { useTranslation } from "react-i18next";
import { RouteObject } from "react-router";
import PageContainer from "../PageContainer";
import { SIGN_UP_PATH } from "./const";
import createAction from "./createAction";
import createLoader from "./createLoader";
import EmailPassSignupPanel from "./EmailPassSignupPanel";

export default function createRoute(): RouteObject {
  return {
    path: SIGN_UP_PATH,
    element: <EmailPassSignupPage />,
    action: createAction(),
    loader: createLoader(),
  };
}

function EmailPassSignupPage() {
  const { t } = useTranslation();
  return (
    <PageContainer logo className="h-full" title={t("new-account.title")!}>
      <EmailPassSignupPanel />
    </PageContainer>
  );
}
