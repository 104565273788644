import QuantityCell from "./QuantityCell";
import { Column, ColumnType } from "../types";
import DropdownCell from "./DropdownCell";
import TextCell from "./TextCell/TextCell";
import DateCell from "./DateCell";
import PeriodCell from "./PeriodCell";
import DateOrPeriodCell from "./DateOrPeriodCell";
import MarkdownCell from "./MarkdownCell";

export type CellProps<T extends ColumnType> = {
  type: T;
  column: Column<T>;
  name: string;
};

const Cells = <T extends ColumnType>(props: CellProps<T>) => {
  const { type } = props;

  switch (type) {
    case "DATE":
      return <DateCell {...(props as CellProps<"DATE">)} />;
    case "PERIOD":
      return <PeriodCell {...(props as CellProps<"PERIOD">)} />;
    case "DATE_OR_PERIOD":
      return <DateOrPeriodCell {...(props as CellProps<"DATE_OR_PERIOD">)} />;
    case "DROPDOWN":
      return <DropdownCell {...(props as CellProps<"DROPDOWN">)} />;
    case "TEXT":
      return <TextCell {...(props as CellProps<"TEXT">)} />;
    case "MARKDOWN":
      return <MarkdownCell {...(props as CellProps<"MARKDOWN">)} />;
    case "QUANTITY":
      return <QuantityCell {...(props as CellProps<"QUANTITY">)} />;
  }
  return null;
};

export default Cells;
