/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { Address } from './Address';
import type { Attachment_Output } from './Attachment_Output';
import type { ContactPoint } from './ContactPoint';
import type { Meta } from './Meta';
import type { models__api__fhir_r5__base__Identifier_Output } from './models__api__fhir_r5__base__Identifier_Output';
import type { Narrative } from './Narrative';
import type { PractitionerCommunication_Output } from './PractitionerCommunication_Output';
import type { PractitionerQualification_Output } from './PractitionerQualification_Output';

export type FHIRPractitionerOut = {
    resourceType?: FHIRPractitionerOut.resourceType;
    id: string;
    meta?: (Meta | null);
    text?: (Narrative | null);
    name: any[];
    photo?: (Array<Attachment_Output> | null);
    active?: (boolean | null);
    gender?: (string | null);
    telecom?: (Array<ContactPoint> | null);
    address?: (Array<Address> | null);
    language?: (string | null);
    birthDate?: (string | null);
    identifier?: Array<models__api__fhir_r5__base__Identifier_Output>;
    deceasedBoolean?: (boolean | null);
    deceasedDateTime?: (string | null);
    implicitRules?: (string | null);
    qualification?: (Array<PractitionerQualification_Output> | null);
    communication?: (Array<PractitionerCommunication_Output> | null);
    contact?: (any[] | null);
};

export namespace FHIRPractitionerOut {

    export enum resourceType {
        PRACTITIONER = 'Practitioner',
    }


}

