import classNames from "classnames";
import {
  FieldPath,
  FieldValues,
  useFormContext,
  UseFormRegister,
  UseFormTrigger,
  UseFormUnregister,
} from "react-hook-form";
import { IDesignation } from "../models";

type DesignationFieldsetProps<TFieldValues extends FieldValues> = {
  name?: string;
  designation?: IDesignation;
  onDesignationChange?: (designation: IDesignation) => void;
  display: string;
  small?: boolean;
  minimal?: boolean;
  className?: string;
  defaultMode?: "view" | "edit";
  showDesignationMeta?: boolean;
  formContext?: {
    register: UseFormRegister<TFieldValues>;
    unregister: UseFormUnregister<TFieldValues>;
    trigger: UseFormTrigger<TFieldValues>;
  };
};
const DEFAULT_FORM_CONTEXT = {
  register: () => {},
  unregister: () => {},
  trigger: () => {},
};
function DesignationFieldset<TFieldValues extends FieldValues>({
  name,
  className,
  small,
  minimal,
  formContext: providedFormContext,
  ...props
}: DesignationFieldsetProps<TFieldValues>) {
  const formContext = useFormContext<TFieldValues>();
  const { register } =
    providedFormContext ?? formContext ?? DEFAULT_FORM_CONTEXT;
  const designationValuePath = (
    name ? `${name}.value` : "value"
  ) as FieldPath<TFieldValues>;
  const languagePath = (
    name ? `${name}.language` : "language"
  ) as FieldPath<TFieldValues>;
  const usePath = (
    name ? `${name}.use.display` : "use.display"
  ) as FieldPath<TFieldValues>;
  return (
    <div className={className} {...props}>
      <fieldset name="">
        <textarea
          {...register(designationValuePath)}
          autoFocus
          rows={1}
          className={classNames(
            "w-full rounded border-gray-100 p-px",
            { "text-sm": small, "text-lg": !small },
            "bg-white text-gray-700 dark:text-gray-400"
          )}
        />
        {!minimal && (
          <div className="mt-1 flex items-baseline gap-x-1">
            <select
              {...register(languagePath)}
              className="rounded border border-gray-100 bg-none px-2 py-0.5 text-center text-xs font-medium uppercase text-gray-400"
            >
              <option value="nl">nl</option>
              <option value="en">en</option>
              <option value="fr">fr</option>
            </select>
            <input
              {...register(usePath)}
              type="text"
              className="w-full rounded border border-gray-100 px-px py-0.5 text-xs text-gray-400"
            />
          </div>
        )}
      </fieldset>
    </div>
  );
}

export default DesignationFieldset;
