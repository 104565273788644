import omit from "lodash/omit";
import { Descendant, Editor, Node, NodeEntry, Text, Transforms } from "slate";
import { formatDateStringToFhir } from "@/text-parsers";
import { formatQuantityStringToFhir } from "@/text-parsers/parse-quantity";
import { IDateField, IQuantityField } from "./types";

export function normalizeDateField(
  editor: Editor,
  [node, path]: NodeEntry<IDateField>,
): boolean | void {
  // no inline elements are allowed
  for (const [childNode, childPath] of Node.children(editor, path)) {
    if (!Text.isText(childNode)) {
      Transforms.unwrapNodes(editor, { at: childPath });
      return true;
    }
  }
  // TODO: merge multiple text nodes

  // AVR 20220318 SAFETY MEASSURE since we had a case where a element had zero children,
  // no clue how that happend, see TIRO-1009 for the resulting error
  if ((node.children as Descendant[]).length == 0) return;
  const { text, entity } = node.children[0];
  // parse text as fhir
  const date = formatDateStringToFhir(text);
  if (date) {
    if (!entity || entity.date != date) {
      Transforms.setNodes<IDateField>(
        editor,
        { entity: { date } },
        { at: path.concat(0) },
      );
      return true;
    }
  }
  if (!date) {
    if (entity) {
      Transforms.unsetNodes<IDateField>(editor, "entity", {
        at: path.concat(0),
      });
      return true;
    }
  }
}

export function normalizeQuantityField(
  editor: Editor,
  [node, path]: NodeEntry<IQuantityField>,
): boolean | void {
  // no inline elements are allowed
  for (const [childNode, childPath] of Node.children(editor, path)) {
    if (!Text.isText(childNode)) {
      Transforms.unwrapNodes(editor, { at: childPath });
      return true;
    }
  }
  // TODO: merge multiple text nodes

  // AVR 20220318 SAFETY MEASSURE since we had a case where a element had zero children,
  // no clue how that happend, see TIRO-1009 for the resulting error
  if ((node.children as Descendant[]).length == 0) return;
  const { text, entity } = node.children[0];

  // parse text as fhir
  const quantity = formatQuantityStringToFhir(text, node.quantity);
  if (quantity) {
    if (!entity || entity.quantity.value != quantity.value) {
      Transforms.setNodes<IQuantityField>(
        editor,
        {
          entity: { ...omit(node, "type", "dateType", "children"), quantity },
        },
        { at: path.concat(0) },
      );
      return true;
    }
  }
  if (!quantity) {
    if (entity) {
      Transforms.unsetNodes<IQuantityField>(editor, "entity", {
        at: path.concat(0),
      });
      return true;
    }
  }
}
