import Tippy from "@tippyjs/react";
import classNames from "classnames";
import { use } from "i18next";
import { FieldValues, Path, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { is, nonempty, string } from "superstruct";
import Badge from "../Badge/Badge";
import StyledInput from "../Badge/StyledInput";
import { BadgeInputProps } from "../types";
import { DATE_PRINT_MAP } from "./formatter";
import { DATE_VALIDATOR_MAP } from "./validator";

export interface PeriodInputProps<
  TFieldValues extends FieldValues = FieldValues,
> extends BadgeInputProps<TFieldValues> {
  name: Path<TFieldValues>;
  startName: Path<TFieldValues>;
  endName: Path<TFieldValues>;
  startDateRequired?: boolean;
  endDateRequired?: boolean;
  placeholder?: string;
  autoFocus?: boolean;
  dateType?: "date" | "month" | "time" | "datetime-local";
  style?: "default" | "table" | "tile";
  defaultMode?: "edit" | "view";
  switchToDate?: () => any;
}
export default function PeriodInput<
  TFieldValues extends FieldValues = FieldValues,
>({
  name,
  startName,
  endName,
  autoFocus: defaultAutoFocus = false,
  suffix,
  prefix,
  validate,
  placeholder = "?",
  startDateRequired = false,
  endDateRequired = false,
  dateType = "date",
  style = "default",
  switchToDate,
  defaultMode,
}: PeriodInputProps<TFieldValues>) {
  const { t } = useTranslation();
  const { register, getValues, getFieldState, formState } =
    useFormContext<TFieldValues>();
  const startIsEmpty =
    !getValues(startName) || !is(getValues(startName), nonempty(string()));

  return (
    <Badge<TFieldValues>
      name={name}
      style={style}
      isEmptyFn={({ getValues }) =>
        !is(getValues(startName), nonempty(string())) &&
        !is(getValues(endName), nonempty(string()))
      }
      defaultAutoFocus={!!defaultAutoFocus}
      defaultMode={defaultMode}
    >
      <Badge.Editor prefix={prefix} suffix={suffix}>
        {({ autoFocus }) => (
          <>
            <StyledInput
              key="star"
              type={dateType}
              invalid={
                !!getFieldState(startName, formState).error &&
                !!getFieldState(endName, formState).isDirty
              }
              {...register(startName, {
                validate,
                pattern: DATE_VALIDATOR_MAP[dateType](t),
                required: startDateRequired,
              })}
              autoFocus={autoFocus && startIsEmpty}
              placeholder={placeholder}
              size={10}
            />
            <span className="mx-1 text-center text-gray-dark"> &rarr; </span>
            <StyledInput
              key="end"
              type={dateType}
              invalid={!!getFieldState(endName, formState).error}
              {...register(endName, {
                validate,
                required: endDateRequired,
                pattern: DATE_VALIDATOR_MAP[dateType](t),
              })}
              autoFocus={autoFocus && !startIsEmpty}
              placeholder={placeholder}
              size={10}
            />
            {switchToDate && (
              <SwitchToDate switchToDate={switchToDate} style={style} />
            )}
          </>
        )}
      </Badge.Editor>
      <Badge.Viewer prefix={prefix} suffix={suffix}>
        {() => (
          <span>
            {getValues(startName)
              ? DATE_PRINT_MAP[dateType](getValues(startName), {
                  errorOnInvalid: false,
                })
              : placeholder}
            <span className="px-2"> &#8594; </span>
            {getValues(endName)
              ? DATE_PRINT_MAP[dateType](getValues(endName), {
                  errorOnInvalid: false,
                })
              : placeholder}
          </span>
        )}
      </Badge.Viewer>
    </Badge>
  );
}

interface SwitchToDateProps {
  switchToDate: () => any;
  style: "table" | "tile" | "default";
}

function SwitchToDate({ switchToDate, style }: SwitchToDateProps) {
  const { t } = useTranslation();
  return (
    <span
      className={classNames(
        "ml-1 inline-flex items-center gap-1 hover:text-primary",
        {
          "text-gray-light": style === "table",
          "text-gray-primary": style !== "table",
        },
      )}
    >
      <Tippy delay={[500, 50]} content={t("remove-end-date")} placement="right">
        <button
          type="button"
          onClick={switchToDate}
          className={classNames(
            "mx-1 inline-flex h-4 w-4 items-center rounded-lg border bg-white text-xs font-medium shadow-sm focus-within:border-blue-primary hover:border-blue-primary hover:bg-blue-primary hover:text-white focus:border focus:border-blue-primary focus:text-blue-dark focus:outline-none focus:ring-0",
            {
              "border-gray-light text-gray-light": style === "table",
              "border-gray-primary text-gray-dark": style !== "table",
            },
          )}
        >
          <span className="material-symbols-outlined hover-text-white w-4 text-center text-xs hover:text-white ">
            remove
          </span>
        </button>
      </Tippy>
    </span>
  );
}
