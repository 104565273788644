import {
  ArrowLongLeftIcon,
  ArrowLongRightIcon,
  ChevronDownIcon,
  ChevronUpIcon,
} from "@heroicons/react/20/solid";
import classNames from "classnames";
import { useURLParams } from "@/hooks/useURLParams";
import React, { useCallback, useContext, useMemo } from "react";
import { Link, useLocation } from "react-router-dom";
import { coerce, create, integer, is, pattern, string } from "superstruct";
import usePagination from "@/hooks/usePagination";
import { useTranslation } from "react-i18next";

/** Style Context */
type TableStyle = { condensed?: true };
const TableStyleContext = React.createContext<TableStyle>({});

/** Sort Context */
type SortId = string;
type SortDirection = "asc" | "desc";
type Sort<TSortId extends SortId = SortId> = `${TSortId}:${SortDirection}`;
const TableSortContext = React.createContext<{
  id: SortId | null;
  direction: SortDirection | null;
  sort: (search: string, nextId: SortId) => string;
} | null>(null);

const tokenToSort = (token: Sort) => {
  const [id, direction] = token.split(":") as [string, SortDirection];
  return { id, direction };
};
/** Pagination Context */
const TablePaginationContext = React.createContext<{
  offset: number;
  limit: number;
  page: number;
  total?: number;
  toNextPage: (search: string) => string;
  toPreviousPage: (search: string) => string;
  toPage: (search: string, index: number) => string;
} | null>(null);

export function useTableSort() {
  const sort = useCallback((search: string, nextId: string) => {
    const params = new URLSearchParams(search);
    const sortToken = params.get("sort");
    if (!sortToken) {
      params.set("sort", `${nextId}:asc`);
      return params.toString();
    }
    const { id, direction } = is(sortToken, pattern(string(), /\d+:(asc|desc)/))
      ? tokenToSort(sortToken as Sort)
      : { id: null, direction: null };
    const nextDirection = id == nextId && direction === "asc" ? "desc" : "asc";
    params.set("sort", `${nextId}:${nextDirection}`);
    return params.toString();
  }, []);
  const params = useURLParams();
  return useMemo(() => {
    const sortToken = params.get("sort");
    const { id, direction } = is(sortToken, pattern(string(), /\d+:(asc|desc)/))
      ? tokenToSort(sortToken as Sort)
      : { id: null, direction: null };
    return {
      id,
      direction,
      sort,
    };
  }, [params, sort]);
}

const StringIntegerModel = pattern(string(), /\d+/);
const IntStringModel = coerce(integer(), StringIntegerModel, (value) =>
  parseInt(value),
);

export function useTablePagination({
  limit: defaultLimit = 10,
  total,
}: { limit?: number; total?: number } = {}) {
  const params = useURLParams();
  const offsetString = params.get("offset");
  const limitString = params.get("limit");

  const toNextPage = useCallback(
    (search: string) => {
      const params = new URLSearchParams(search);
      const offsetString = params.get("offset");
      const offset = is(offsetString, StringIntegerModel)
        ? create(offsetString, IntStringModel)
        : 0;
      const limitString = params.get("limit");
      const limit = is(limitString, StringIntegerModel)
        ? create(limitString, IntStringModel)
        : defaultLimit;
      params.set("offset", `${offset + limit}`);
      return params.toString();
    },
    [defaultLimit],
  );

  const toPreviousPage = useCallback(
    (search: string) => {
      const params = new URLSearchParams(search);
      const offsetString = params.get("offset");
      const offset = is(offsetString, StringIntegerModel)
        ? create(offsetString, IntStringModel)
        : 0;
      const limitString = params.get("limit");
      const limit = is(limitString, StringIntegerModel)
        ? create(limitString, IntStringModel)
        : defaultLimit;
      params.set("offset", `${Math.max(offset - limit, 0)}`);
      return params.toString();
    },
    [defaultLimit],
  );

  const toPage = useCallback(
    (search: string, page: number) => {
      const params = new URLSearchParams(search);
      const limitString = params.get("limit");
      const limit = is(limitString, StringIntegerModel)
        ? create(limitString, IntStringModel)
        : defaultLimit;
      params.set("offset", `${Math.max((page - 1) * limit, 0)}`);
      return params.toString();
    },
    [defaultLimit],
  );

  return useMemo(() => {
    const offset = is(offsetString, StringIntegerModel)
      ? create(offsetString, IntStringModel)
      : 0;
    const limit = is(limitString, StringIntegerModel)
      ? create(limitString, IntStringModel)
      : defaultLimit;
    const page = Math.floor(offset / limit) + 1;
    return {
      offset,
      limit,
      page,
      toNextPage,
      toPreviousPage,
      toPage,
      total,
    };
  }, [
    offsetString,
    limitString,
    defaultLimit,
    total,
    toNextPage,
    toPreviousPage,
    toPage,
  ]);
}

function TH({
  className: providedClassName,
  children,
  sortId,
  ...attributes
}: React.HTMLProps<HTMLTableCellElement> & {
  sortId?: string;
}) {
  const { condensed } = useContext(TableStyleContext);
  const tableSort = useContext(TableSortContext);

  if (!tableSort) {
    throw new Error("TableSortContext is not provided");
  }

  const { id, direction, sort } = tableSort;
  const { search } = useLocation();
  const className = classNames(
    "sticky top-0 z-0 border-b border-gray-300 bg-white bg-opacity-60 text-left text-sm font-semibold text-gray-dark backdrop-blur backdrop-filter",
    { "px-2 py-3.5": condensed, "px-3 py-4": !condensed },
    providedClassName,
  );
  if (sortId) {
    return (
      <th className={className} {...attributes}>
        <Link
          id="button-sort-id"
          to={{ search: sort(search, sortId) }}
          className="group inline-flex"
        >
          {children}
          <span
            className={classNames("ml-2 flex-none rounded", {
              "bg-gray-200 group-hover:bg-gray-light": id === sortId,
            })}
          >
            <ChevronUpIcon
              className={classNames("h-5 w-5", {
                block: direction == "asc" && id == sortId,
                hidden: direction != "asc" || id != sortId,
              })}
              aria-hidden="true"
            />
            <ChevronDownIcon
              className={classNames("h-5 w-5", {
                block: direction == "desc" && id == sortId,
                hidden: direction != "desc" && id == sortId,
                "invisible text-gray-light group-hover:visible group-focus:visible":
                  id !== sortId,
              })}
              aria-hidden="true"
            />
          </span>
        </Link>
      </th>
    );
  }
  return (
    <th className={className} {...attributes}>
      {children}
    </th>
  );
}

function TD({
  className,
  children,
  ...attributes
}: React.HTMLProps<HTMLTableCellElement>) {
  const { condensed } = useContext(TableStyleContext);
  return (
    <td
      className={classNames(
        "whitespace-nowrap border-b border-gray-200 font-secondary text-sm text-gray-500",
        { "px-2 py-2": condensed, "px-3 py-4": !condensed },
        className,
      )}
      {...attributes}
    >
      {children}
    </td>
  );
}
const TableRoot = ({
  children,
  className,
  ...attributes
}: React.HTMLProps<HTMLTableElement>) => (
  <table
    className={classNames(
      "min-w-full border-separate border-spacing-0",
      className,
    )}
    {...attributes}
  >
    {children}
  </table>
);

const TableContainer = ({
  children,
  limit,
  total,
  condensed,
  className,
  ...attributes
}: React.PropsWithChildren<
  TableStyle & {
    limit?: number;
    total?: number;
  } & React.HTMLProps<HTMLDivElement>
>) => {
  const tableSort = useTableSort();
  const tablePagination = useTablePagination({ limit, total });
  return (
    <div
      className={classNames("relative overflow-scroll", className)}
      {...attributes}
    >
      <TablePaginationContext.Provider value={tablePagination}>
        <TableSortContext.Provider value={tableSort}>
          <TableStyleContext.Provider value={{ condensed }}>
            {children}
          </TableStyleContext.Provider>
        </TableSortContext.Provider>
      </TablePaginationContext.Provider>
    </div>
  );
};

function Pagination() {
  const { t } = useTranslation();
  const tablePagination = useContext(TablePaginationContext);
  if (!tablePagination) {
    throw new Error("TablePaginationContext is not provided");
  }
  const { toNextPage, toPreviousPage, toPage, total, limit, page } =
    tablePagination;
  const { search } = useLocation();
  const range = usePagination({ total, limit, page });

  return (
    <nav className="sticky bottom-0 left-0 right-0 z-10 -mt-0.5 flex items-center justify-between border-t border-gray-200 bg-white bg-opacity-60 px-4 pb-5 backdrop-blur backdrop-filter sm:px-10">
      <div className="-mt-px flex w-0 flex-1">
        <Link
          to={{ search: toPreviousPage(search) }}
          className="inline-flex items-center border-t-2 border-transparent pr-1 pt-4 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700"
        >
          <ArrowLongLeftIcon
            className="mr-3 h-5 w-5 text-gray-400"
            aria-hidden="true"
          />
          {t("vorige")}
        </Link>
      </div>
      {range && (
        <div className="hidden md:-mt-px md:flex">
          {range.map((pageIndex, index) => {
            if (pageIndex === "...") {
              return (
                <span
                  key={`${index}_ellipsis`}
                  className="inline-flex items-center border-t-2 border-transparent px-4 pt-4 text-sm font-medium text-gray-500"
                >
                  {pageIndex}
                </span>
              );
            }
            return (
              <Link
                key={pageIndex}
                to={{
                  search: toPage(search, pageIndex),
                }}
                className={classNames(
                  "inline-flex items-center border-t-2 px-4 pt-4 text-sm font-medium",
                  {
                    "border-blue-500 text-blue-600": pageIndex === page,
                    "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700":
                      pageIndex !== page,
                  },
                )}
              >
                {pageIndex}
              </Link>
            );
          })}
        </div>
      )}
      <div className="-mt-px flex w-0 flex-1 justify-end">
        <Link
          to={{ search: toNextPage(search) }}
          className="inline-flex items-center border-t-2 border-transparent pl-1 pt-4 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700"
        >
          {t("volgende")}
          <ArrowLongRightIcon
            className="ml-3 h-5 w-5 text-gray-400"
            aria-hidden="true"
          />
        </Link>
      </div>
    </nav>
  );
}

const Table = {
  Container: TableContainer,
  Pagination: Pagination,
  Root: TableRoot,
  Head: (
    props: React.DetailedHTMLProps<
      React.HTMLAttributes<HTMLTableSectionElement>,
      HTMLTableSectionElement
    >,
  ) => <thead {...props} />,
  HeaderRow: (
    props: React.DetailedHTMLProps<
      React.HTMLAttributes<HTMLTableRowElement>,
      HTMLTableRowElement
    >,
  ) => <tr {...props} />,
  HeaderCell: TH,
  Body: (
    props: React.DetailedHTMLProps<
      React.HTMLAttributes<HTMLTableSectionElement>,
      HTMLTableSectionElement
    >,
  ) => <tbody {...props} />,
  Row: ({
    className,
    ...props
  }: React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLTableRowElement>,
    HTMLTableRowElement
  >) => <tr className={className} {...props} />,
  Cell: TD,
};

export default Table;
