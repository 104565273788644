import classNames from "classnames";
import React, { HTMLProps, Ref } from "react";

export interface ChipProps
  extends Omit<HTMLProps<HTMLButtonElement>, "type" | "ref"> {
  /** entity represented by chip is considered truthy */
  checked?: boolean;
  /** chip has been focused by keyboard or mouse */
  active?: boolean;
  faded?: boolean;
  position?: "left" | "right" | "center" | "independent";
  Icon?: React.ReactNode;
  iconClassName?: string;
  iconPosition?: "left" | "right";
  choice?: string;
}

////   Styling   ////
// dont use whitespace-nowrap: https://github.com/ianstormtaylor/slate/issues/2166
const Styles = {
  BASE: "relative inline-flex w-max min-w-max items-baseline space-x-0.5 overflow-hidden py-0.5 text-sm border tabular-nums",
  DEFAULT:
    "text-gray-dark border-gray-primary focus:text-blue-dark focus:border-blue-dark hover:text-blue-dark hover:border-blue-dark bg-white",
  DISABLED:
    "text-gray-light border-gray-light bg-gray-ultralight cursor-not-allowed",
  ICON_LEFT: "pl-1.5 pr-3",
  ICON_RIGHT: "pl-3 pr-1.5",
  NO_ICON: "px-3",
  CHECKED:
    "bg-blue-ultralight text-blue-dark border border-blue-ultralight hover:border-blue-dark",
  ACTIVE: "ring-2 ring-blue-primary ring-offset-1",
  FADED:
    "text-gray-light border-gray-light hover:text-blue-dark hover:border-blue-dark",
  POSITION: {
    left: "rounded-r-sm rounded-l-full",
    right: "rounded-l-sm rounded-r-full",
    center: "rounded-sm",
    independent: "rounded-full",
  },
};

function Chip_(
  {
    className,
    checked,
    active,
    children,
    faded,
    Icon,
    iconClassName,
    iconPosition = "left",
    position = "independent",
    choice: providedChoice = "",
    disabled,
    ...attributes
  }: ChipProps,
  ref?: Ref<HTMLButtonElement>,
) {
  // this line provides backwards compatibility with the older api of Chip
  let choice = providedChoice || children;
  return (
    <button
      ref={ref}
      type="button"
      data-testid="check-button"
      aria-checked={checked}
      className={classNames(
        Styles.BASE,
        {
          [Styles.ICON_LEFT]: !!Icon && iconPosition === "left",
          [Styles.ICON_RIGHT]: !!Icon && iconPosition === "right",
          [Styles.NO_ICON]: !Icon,
          [Styles.POSITION[position]]: position,
          [Styles.ACTIVE]: active,
          [Styles.DEFAULT]: !disabled && !checked && !faded,
          [Styles.DISABLED]: disabled,
          [Styles.CHECKED]: checked,
          [Styles.FADED]: faded,
        },
        className,
      )}
      disabled={disabled}
      {...attributes}
    >
      {iconPosition === "left" && Icon && (
        <span className={classNames("mr-0.5", iconClassName)}>{Icon}</span>
      )}
      <span className="whitespace-pre">{choice}</span>
      {iconPosition === "right" && Icon && (
        <span className={classNames("ml-0.5", iconClassName)}>{Icon}</span>
      )}
    </button>
  );
}

const Chip = React.forwardRef(Chip_);

export default Chip;
