import produce, { setAutoFreeze } from "immer";
import { WritableDraft } from "immer/dist/internal";
import setWith from "lodash/setWith";
import clone from "lodash/clone";
import uuidjs from "uuidjs";
import { useMemo } from "react";
import { useValidationScoreBoard } from "@/services/validation";
import { QAAction, ActionTypes, IQuestionAnswer } from "./types";
import { QuestionnaireScore } from "./QuestionnaireScore";

setAutoFreeze(false);
const PATH_SEP: `.${keyof IQuestionAnswer}.` = ".questions." as const; // safety check to make sure this equals a field of a question group

function getCommonPath(path1: number[], path2: number[]) {
  let i;
  for (i = 0; i < Math.min(path1.length, path2.length); i++) {
    if (path1[i] != path2[i]) return path1.slice(0, i);
  }
  return path1.slice(0, i);
}

function getQuestionByPath(
  questions: WritableDraft<IQuestionAnswer[]>,
  path: number[],
) {
  const parentPath = [...path];
  const index = parentPath.pop() as number;
  return parentPath.reduce((childQuestions, index) => {
    const rootQuestion = childQuestions[index];
    if ("questions" in rootQuestion) {
      return rootQuestion.questions as WritableDraft<IQuestionAnswer>[];
    }
    rootQuestion.questions = [];
    return rootQuestion.questions as WritableDraft<IQuestionAnswer>[];
  }, questions)[index];
}

function getChildQuestionsByPath(
  questions: WritableDraft<IQuestionAnswer[]>,
  path: number[],
) {
  return path.reduce((childQuestions, index) => {
    const rootQuestion = childQuestions[index];
    if ("questions" in rootQuestion) {
      return rootQuestion.questions as WritableDraft<IQuestionAnswer>[];
    }
    rootQuestion.questions = [];
    return rootQuestion.questions as WritableDraft<IQuestionAnswer>[];
  }, questions);
}

interface CreateReducerOptions {
  onStateChange?: (
    state: IQuestionAnswer[],
    newState: IQuestionAnswer[],
  ) => void;
}
function createReducer(options: CreateReducerOptions = {}) {
  function reducer(state: IQuestionAnswer[], action: QAAction) {
    let newState = state;
    switch (action.type) {
      case ActionTypes.APPEND_QUESTION:
        {
          const path = action.path.join(PATH_SEP);
          newState = setWith(clone(state), path, action.question, clone);
        }
        break;
      case ActionTypes.EDIT_QUESTION: {
        const path = action.path.join(PATH_SEP);
        newState = setWith(clone(state), path, action.question, clone);
        break;
      }
      case ActionTypes.MOVE_QUESTION: {
        newState = produce(state, (questions) => {
          const commonPath = getCommonPath(action.src, action.dest);
          if (commonPath.length == action.src.length) return;
          /* Recalculate destination path taking into account that
           * the source question is already removed and can affect the positioning of the destination
           */
          if (
            action.src.length <= action.dest.length && // removing the source element can only affect dest element if
            action.src.length == commonPath.length + 1 &&
            action.src[commonPath.length] < action.dest[commonPath.length]
          )
            action.dest[commonPath.length] -= 1;
          // split path and leaf index for source
          const srcParentPath = [...action.src];
          const srcIndex = srcParentPath.pop() as number;
          const srcQuestions = getChildQuestionsByPath(
            questions,
            srcParentPath,
          );
          // remove the source question
          const srcQuestion = srcQuestions.splice(srcIndex, 1)[0];
          // split path and leaf index for dest
          const destParentPath = [...action.dest];
          const destIndex = destParentPath.pop() as number;
          const destQuestions = getChildQuestionsByPath(
            questions,
            destParentPath,
          );
          // insert it into the destination path
          destQuestions.splice(destIndex, 0, srcQuestion);
        });
        break;
      }
      case ActionTypes.REMOVE_QUESTION: {
        const leafIndex = action.path.pop();
        if (leafIndex === undefined)
          throw Error("Invalid path passed to reducer.");
        newState = produce(newState, (questions) => {
          let leafQuestions = getChildQuestionsByPath(questions, action.path);
          leafQuestions.splice(leafIndex, 1);
        });
        break;
      }
      case ActionTypes.TOGGLE_STATE: {
        newState = produce(newState, (questions) => {
          const targetQuestion = getQuestionByPath(questions, action.path);
          targetQuestion.checked = !targetQuestion.checked;
        });
        break;
      }
      case ActionTypes.UPDATE_ANSWER: {
        newState = produce(newState, (questions) => {
          const targetQuestion = getQuestionByPath(questions, action.path);
          targetQuestion.answer = action.answer;
        });
        break;
      }
    }
    const { onStateChange } = options;
    onStateChange && onStateChange(state, newState);
    return newState;
  }
  return reducer;
}
export default createReducer;

interface UseQuestionnaireReducerOptions {
  key?: string;
  initScore?: QuestionnaireScore;
  enableScoring?: boolean;
}

export function useQuestionnaireReducer(
  options: UseQuestionnaireReducerOptions = {},
) {
  const generatedKey = useMemo(() => uuidjs.genV4().urn, []);
  const {
    enableScoring,
    initScore = new QuestionnaireScore(),
    key = generatedKey,
  } = options;
  const [, updateScore] = useValidationScoreBoard(key, {
    initScore,
    failIfNotMounted: false,
  });
  return useMemo(() => {
    const options: CreateReducerOptions = enableScoring
      ? {
          onStateChange: (state, newState) => {
            updateScore(QuestionnaireScore.fromQuestionnaire(newState));
          },
        }
      : {};
    return createReducer(options);
  }, [updateScore, enableScoring]);
}
