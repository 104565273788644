import React, { useState } from "react";
import ReportButton from "@/components/base/ReportButton";
import { PlusIcon } from "@heroicons/react/24/solid";
import MarkdownTextEditor from "@/components/MarkdownTextEditor";

interface CommentsComponentProps
  extends Pick<React.HTMLAttributes<HTMLSpanElement>, "className"> {
  value?: string;
  onChange?: (value: string) => void;
  onBlur?: () => void;
  readonly?: boolean;
  disabled?: boolean;
  active?: boolean;
  children?: React.ReactNode;
}

export function CommentsEditor_(
  {
    value,
    onChange,
    onBlur,
    readonly,
    children,
    disabled,
    active,
    ...attributes
  }: CommentsComponentProps,
  ref: React.Ref<HTMLSpanElement>,
) {
  const [isEditing, setIsEditing] = useState(false);
  const isEmpty = value?.trim() === "" || value === undefined;
  const showButton = isEmpty && !isEditing;
  const handleBlur = () => {
    setIsEditing(false);
    onBlur?.();
  };
  return (
    <span
      ref={ref}
      {...attributes}
      className="relative inline-flex items-baseline"
    >
      <span className={readonly ? "hidden" : ""}>{children}</span>
      {showButton ? (
        <ReportButton
          onClick={() => setIsEditing(true)}
          disabled={disabled}
          className="ml-2 inline-flex items-baseline border-transparent text-xs text-transparent hover:border-gray-light hover:text-gray-primary focus:border-gray-lighter focus:text-gray-primary"
          outline
          positionIcon="left"
          Icon={<PlusIcon className="inline h-4 w-4" />}
        >
          <span>Voeg opmerking toe</span>
        </ReportButton>
      ) : (
        <MarkdownTextEditor
          value={value}
          className="ml-1 inline-flex flex-col rounded-none border-l border-gray-lighter px-1 pb-1 text-sm focus-within:border-l-2 focus-within:border-gray-dark"
          onBlur={handleBlur}
          onChange={onChange}
          autoFocus={isEditing}
        />
      )}
      {children}
    </span>
  );
}

const CommentsEditor = React.forwardRef(CommentsEditor_);
export default CommentsEditor;
