import { PlusIcon } from "@heroicons/react/24/outline";
import Chip from "@/components/base/Chip";
import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { Transforms } from "slate";
import { ReactEditor, useFocused, useSlateStatic } from "slate-react";
import { RenderTypedElementProps } from "../../TypedElement";
import { CHECK_ELEMENT } from "./consts";

export interface RenderCheckListElementProps extends RenderTypedElementProps {}
function RenderCheckListElement({
  children,
  attributes,
  element,
}: RenderCheckListElementProps) {
  const { t } = useTranslation();
  const focused = useFocused();
  const editor = useSlateStatic();
  const handleClick = useCallback(() => {
    const path = ReactEditor.findPath(editor, element);
    Transforms.insertNodes(
      editor,
      { type: CHECK_ELEMENT, children: [{ text: "" }] },
      { at: [...path, element.children.length], select: true }
    );
  }, [element, editor]);
  return (
    <ul {...attributes} className="!m-0 !list-none space-y-1 !p-0">
      {React.Children.map(children, (node) => (
        <li className="!m-0 !p-0">{node}</li>
      ))}
      {focused && (
        <li contentEditable={false} className="!m-0 !p-0">
          <Chip
            tabIndex={-1}
            onClick={handleClick}
            className="!m-0 border-opacity-0 hover:border-opacity-100"
          >
            <PlusIcon className="h-4 w-4 fill-current" />
            <span>{t("voeg-item-toe")}</span>
          </Chip>
        </li>
      )}
    </ul>
  );
}
export default RenderCheckListElement;
