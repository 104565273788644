import { Describe, enums, union } from "superstruct";

import { default as RichTextContentModel } from "./RichTextBlock/models";
import { RenderType as RichTextRenderType } from "./RichTextBlock/types";
import { RENDER_TYPES as RICHTEXT_RENDER_TYPES } from "./RichTextBlock/consts";
import { default as TableContentModel } from "./Table/models";
import { RenderType as TableRenderType } from "./Table/types";
import { RENDER_TYPE as TABLE_RENDER_TYPE } from "./Table/consts";
import { default as QuestionnaireContentModel } from "./Questionnaire/models";
import { RenderType as QuestionnaireRenderType } from "./Questionnaire/types";
import { RENDER_TYPE as QUESTIONNAIRE_RENDER_TYPE } from "./Questionnaire/consts";
import { default as FreeTextContentModel } from "./FreeText/models";
import { RenderType as FreeTextRenderType } from "./FreeText/types";
import { RENDER_TYPE as FREE_TEXT_RENDER_TYPE } from "./FreeText/consts";

import { RENDER_TYPES } from "./consts";
import { AnyRenderType } from "./types";

export const AnyContentModel = union([
  RichTextContentModel,
  TableContentModel,
  QuestionnaireContentModel,
  FreeTextContentModel,
]);

export const AnyRenderTypeModel: Describe<AnyRenderType> = enums(RENDER_TYPES);

type ModelTypeByRenderType<R extends AnyRenderType> =
  R extends RichTextRenderType
    ? typeof RichTextContentModel
    : R extends TableRenderType
      ? typeof TableContentModel
      : R extends QuestionnaireRenderType
        ? typeof QuestionnaireContentModel
        : R extends FreeTextRenderType
          ? typeof FreeTextContentModel
          : never;

/*function contentModel<R extends RichTextRenderType>(
  renderType: R
): typeof RichTextContentModel;
function contentModel<R extends TableRenderType>(
  renderType: R
): typeof TableContentModel;
function contentModel<R extends QuestionnaireRenderType>(
  renderType: R
): typeof QuestionnaireContentModel;
function contentModel<R extends FreeTextRenderType>(
  renderType: R
): typeof FreeTextContentModel;
function contentModel(renderType: AnyRenderType): typeof QuestionnaireContentModel | typeof TableContentModel | typeof RichTextContentModel | typeof FreeTextContentModel;
¨*/
function contentModel<R extends AnyRenderType>(
  renderType: R,
): ModelTypeByRenderType<R> {
  if (RICHTEXT_RENDER_TYPES.includes(renderType as any)) {
    return RichTextContentModel as ModelTypeByRenderType<R>;
  }
  if (TABLE_RENDER_TYPE == renderType) {
    return TableContentModel as ModelTypeByRenderType<R>;
  }
  if (QUESTIONNAIRE_RENDER_TYPE == renderType) {
    return QuestionnaireContentModel as ModelTypeByRenderType<R>;
  }
  if (FREE_TEXT_RENDER_TYPE == renderType) {
    return FreeTextContentModel as ModelTypeByRenderType<R>;
  }
  throw Error(
    `Received invalid renderType="${renderType}".\n Supported renderTypes are: \n-${RENDER_TYPES.join(
      "\n-",
    )}`,
  );
}

export default contentModel;
