import { RouteObject } from "react-router";
import PageContainer from "../PageContainer";
import { VERIFY_EMAIL_PATH } from "./const";
import createAction from "./createAction";
import createLoader from "./createLoader";
import { SendVerificationPanel } from "./SendVerificationPanel";

export default function createRoute(): RouteObject {
  return {
    path: VERIFY_EMAIL_PATH,
    element: <SendEmailVerificationPage />,
    action: createAction(),
    loader: createLoader(),
  };
}

const SendEmailVerificationPage = () => (
  <PageContainer logo className="h-full">
    <SendVerificationPanel />
  </PageContainer>
);
