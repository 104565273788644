import React from "react";
import { Editor } from "slate";
import { RenderElementProps } from "slate-react";
import { IEditorPlugin } from "../../Base/types";
import { HotKeys } from "../../hotkeys";
import { isEventHandled } from "../../util";
import RenderToggleButtonElement from "./RenderToggleButtonElement";
import { TOGGLE_ELEMENT, TOGGLE_GROUP_ELEMENT } from "./conts";
import ToggleElement from "./ToggleElement";

const ToggleButtonPlugin: IEditorPlugin = {
  handleKeyDown: (e: React.KeyboardEvent, editor) => {
    if (!isEventHandled(e) && HotKeys.isToggle(e)) {
      for (const toggleEntry of Editor.nodes(editor, {
        match: ToggleElement.isToggleEntityElement,
      })) {
        const [, path] = toggleEntry;
        if (toggleEntry) {
          ToggleElement.toggle(editor, path);
          e.preventDefault();
        }
        break;
      }
    }
  },
  renderElement: ({ element, attributes, children }: RenderElementProps) => {
    if (ToggleElement.isToggleEntityElement(element)) {
      return (
        <RenderToggleButtonElement element={element} attributes={attributes}>
          {children}
        </RenderToggleButtonElement>
      );
    }
    if (ToggleElement.isToggleGroupElement(element)) {
      return (
        <span {...attributes} className="select-none">
          {children}
        </span>
      );
    }
  },
  voidTypes: [TOGGLE_ELEMENT],
  inlineTypes: [TOGGLE_ELEMENT, TOGGLE_GROUP_ELEMENT],
};
export default ToggleButtonPlugin;
