import classNames from "classnames";
import { useRef, useState, useEffect } from "react";
import Split from "split-grid";

export function Gutter({
  gridTemplateColumns,
  onGridTemplateColumns: handleGridTemplateColumns,
  height,
}: {
  gridTemplateColumns: string;
  onGridTemplateColumns: (gridTemplateColumns: string) => void;
  height: number | "auto";
}) {
  const gutterRef = useRef<HTMLDivElement | null>(null);
  const [active, setActive] = useState(false);

  useEffect(() => {
    if (!gutterRef.current) return;
    const split = Split({
      columnGutters: [{ element: gutterRef.current, track: 3 }],
      writeStyle: (grid, gireTemplateProp, gridTemplateStyle) =>
        handleGridTemplateColumns(gridTemplateStyle),
      onDragStart: () => setActive(true),
      onDragEnd: () => setActive(false),
    });

    return () => split.destroy();
  }, [handleGridTemplateColumns]);
  return (
    <div
      className="absolute inset-x-0 top-0 sm:grid sm:gap-1"
      style={{ gridTemplateColumns }}
    >
      <div
        ref={gutterRef}
        className="group relative z-10 col-start-4 col-end-4 h-full w-5 hover:cursor-col-resize"
      >
        <div
          className={classNames(
            "absolute mx-auto w-2  bg-gray-200/0 transition duration-300 ease-in-out",
            {
              "bg-gray-200/100": active,
              "hover:bg-gray-200/100 group-hover:bg-gray-200/100": !active,
            }
          )}
          style={{ height }}
        />
      </div>
    </div>
  );
}
