import { useTranslation } from "react-i18next";

export default function Separator() {
  const { t } = useTranslation();
  return (
    <div className="relative mt-6">
      <div className="absolute inset-0 flex items-center">
        <div className="w-full border-t border-gray-light" />
      </div>
      <div className="relative flex justify-center text-sm">
        <span className="bg-gray-50 px-2 text-gray-primary">
          {t("of-login-met")}
        </span>
      </div>
    </div>
  );
}
