import classNames from "classnames";
import { Spinner } from "@/Icons";
import { HTMLAttributes } from "react";

function BlockLoadingSkeleton<T>({
  block,
  children,
  className,
  ...props
}: {
  block: T | undefined;
  children: (props: { block: T }) => JSX.Element;
} & Omit<HTMLAttributes<HTMLDivElement>, "children">) {
  if (!block) {
    return (
      <div
        className={classNames(
          "flex h-full w-full items-center justify-center",
          className,
        )}
        {...props}
      >
        <Spinner className="block h-10 w-10 animate-spin text-gray-primary" />
      </div>
    );
  }
  return children({ block });
}

export default BlockLoadingSkeleton;
