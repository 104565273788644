import classNames from "classnames";
import { AbstractInputProps } from "@/components/inputs/types";
import { useMultiFormRef } from "@/components/MultiFormSubmit/MultiFormRefContext";
import { useFHIRPaths } from "@/hooks/useFHIRPath";
import React from "react";
import {
  DeepPartial,
  DefaultValues,
  FieldValues,
  FormProvider,
  useForm,
} from "react-hook-form";
import { useFormValuesChange } from "@/components/forms/OnChangePlugin";
import { func, is } from "superstruct";

export type AnswerElement =
  | React.ReactComponentElement<"div", AbstractInputProps>[]
  | React.ReactComponentElement<"div", AbstractInputProps>;

export interface AnswerFormProps<TAnswerValues extends FieldValues>
  extends Omit<React.HTMLProps<HTMLFormElement>, "onSubmit"> {
  linkId: string;
  initialValues?: DeepPartial<TAnswerValues>;
  initialExpressions?: Record<string, string>;
  values?: DefaultValues<TAnswerValues>;
  onValuesChange?: (values: TAnswerValues) => void;
  onSubmit?: (values: TAnswerValues) => void;
}
function mergeObjects<T extends Record<string, any>>(...objects: T[]): T {
  return Object.assign({}, ...objects);
}

function AnswerForm<TAnswerValues extends FieldValues = FieldValues>({
  linkId,
  children,
  initialValues: providedInitialValues,
  initialExpressions,
  values: providedValues,
  onValuesChange,
  onSubmit,
  className,
}: AnswerFormProps<TAnswerValues>) {
  const initialExpressionEntries = Object.entries(initialExpressions ?? {});
  const results = useFHIRPaths(
    initialExpressionEntries.map((e) => e[1]),
    {}
  );
  const calulcatedInitialValues = results
    ? Object.fromEntries(
        initialExpressionEntries.map(([k], i) => [k, results[i]])
      )
    : {};
  // auto submit when form is valid
  const initialValues = mergeObjects(
    calulcatedInitialValues,
    providedInitialValues ?? {}
  ) as DefaultValues<TAnswerValues>;
  const methods = useForm<TAnswerValues>({
    defaultValues: providedValues ?? initialValues,
  });
  const { watch } = methods;
  const ref = React.useRef<HTMLFormElement>(null);
  useMultiFormRef(linkId, ref); // register form ref for multi form submit

  useFormValuesChange(onValuesChange, { watch });

  return (
    <form
      ref={ref}
      onSubmit={
        onSubmit
          ? methods.handleSubmit(onSubmit)
          : (event) => {
              console.log(event);
              event.preventDefault();
            }
      }
      className={classNames("rounded focus-within:outline-blue-100", className)}
    >
      <FormProvider {...methods}>
        {is(children, func()) ? children(methods) : children}
      </FormProvider>
    </form>
  );
}
export default AnswerForm;
