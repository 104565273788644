import { literal } from "superstruct";
import { RENDER_TYPE } from "./consts";
import { ITableContent } from "./types";
export { default as ContentModel } from "./models";

export type RenderType = typeof RENDER_TYPE;
export const RenderTypeModel = literal(RENDER_TYPE);
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export type IContent = ITableContent;

export { RENDER_TYPE };

export { default as RenderTableBlockContent } from "./RenderBlockContent";
export { default as RenderTableTemplateBlockContent } from "./RenderTemplateBlockContent";
