import { useQuery, UseQueryOptions } from "react-query";
import client from "./client";
import keyFactory from "./keyFactory";
export type CodeLookup = any;
interface UseCodeLookupOptions {
  version?: string;
  display?: string;
}

export const createCodeLookupQuery = ({
  code,
  system,
}: {
  code: string;
  system: string;
}) => ({
  queryKey: keyFactory.codeLookup(code, system),
  queryFn: () => client.r5.codesystemLookupR5({ system, code }),
});

function useCodeLookup<TError = unknown, TData = CodeLookup>(
  code: string,
  system: string,
  options: UseCodeLookupOptions &
    UseQueryOptions<CodeLookup, TError, TData> = {}
) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { version, display, ...other } = options;
  return useQuery<CodeLookup, TError, TData>({
    ...createCodeLookupQuery({ code, system }),
    ...other,
  });
}

export default useCodeLookup;
