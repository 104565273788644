import { Node, NodeEntry, Text, Transforms } from "slate";
import { ITiroEditor } from "../../Base/editor";
import ToggleElement from "./ToggleElement";

const withToggles = <T extends ITiroEditor = ITiroEditor>(editor: T): T => {
  const { normalizeNode } = editor;
  editor.normalizeNode = (entry: NodeEntry<Node>) => {
    const [node, path] = entry;

    if (ToggleElement.isToggleGroupElement(node)) {
      if (node.children.every((n) => Text.isText(n))) {
        Transforms.unwrapNodes(editor, { at: path });
        return;
      }
      for (const [childNode, childPath] of Node.children(editor, path)) {
        if (ToggleElement.isToggleEntityElement(childNode)) continue;
        if (Text.isText(childNode) && Node.string(childNode).length === 0)
          continue;
        Transforms.unwrapNodes(editor, {
          at: childPath,
          split: true,
          mode: "all",
          match: (n) => ToggleElement.isToggleGroupElement(n),
        });
        return;
      }
    }
    return normalizeNode(entry);
  };
  return editor;
};

export default withToggles;
