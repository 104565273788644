import classNames from "classnames";
import Textfield from "@/components/inputs/TextField/TextField";
import { useFormContext } from "react-hook-form";
import { useFocused, useReadOnly, useSelected } from "slate-react";
import { IEditorPlugin } from "../Base/types";
import { RenderTypedElementProps, TypedElement } from "../TypedElement";

export const TEXT_FIELD_ELEMENT_TYPE = "text-field";
type ITextFieldElement = {
  type: typeof TEXT_FIELD_ELEMENT_TYPE;
  name: string;
};
const TextFieldElement = {
  ...TypedElement,
  isTextFieldElement(element: unknown): element is ITextFieldElement {
    return (
      TextFieldElement.isTypedElement(element) &&
      element.type === TEXT_FIELD_ELEMENT_TYPE
    );
  },
};
type RenderTextFieldElementProps = RenderTypedElementProps & {
  element: ITextFieldElement;
};
const RenderTextFieldElement = ({
  attributes,
  children,
  element: { name },
}: RenderTextFieldElementProps) => {
  const { control } = useFormContext();
  const readonly = useReadOnly();
  const focused = useFocused();
  const selected = useSelected();
  const active = focused && selected;
  return (
    <div {...attributes}>
      <Textfield
        name={name}
        disabled={readonly}
        className={classNames(
          "min-h-[2rem] rounded-md bg-gray-ultralight shadow-sm",
          {
            "border-2 border-blue-primary outline-1 outline-blue-primary":
              active,
            "border border-gray-light": !active,
          }
        )}
        control={control}
      />
      {children}
    </div>
  );
};

const TextFieldPlugin: IEditorPlugin = {
  renderElement: (props) => {
    const { element } = props;
    if (TextFieldElement.isTextFieldElement(element)) {
      return <RenderTextFieldElement {...props} element={element} />;
    }
  },
  voidTypes: [TEXT_FIELD_ELEMENT_TYPE],
};

export default TextFieldPlugin;
