import { QueryClient } from "react-query";
import { RouteObject } from "react-router";
import PageContainer from "../PageContainer";
import AskUserDataPanel from "./AskUserDataPanel";
import { ASK_USER_DATA_PATH } from "./const";
import createAction, { ACTION_CONFIG_DEFAULTS } from "./createAction";
import createLoader, { LOADER_CONFIG_DEFAULTS } from "./createLoader";

export default (queryClient: QueryClient): RouteObject => ({
  path: ASK_USER_DATA_PATH,
  action: createAction({ queryClient, ...ACTION_CONFIG_DEFAULTS }),
  loader: createLoader({ queryClient, ...LOADER_CONFIG_DEFAULTS }),
  element: <AskUserDataPage />,
});

const AskUserDataPage = () => (
  <PageContainer logo className="h-full">
    <AskUserDataPanel />
  </PageContainer>
);
