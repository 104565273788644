import React from "react";
import { is } from "superstruct";
import { AnyRenderType } from "./types";
import {
  RenderRichTextBlockContent,
  RenderTypeModel as RichTextRenderTypeModel,
} from "./RichTextBlock";
import {
  RenderTableBlockContent,
  RenderTypeModel as TableRenderTypeModel,
} from "./Table";
import {
  RenderQuestionnaireBlockContent,
  RenderTypeModel as QuestionnaireRenderTypeModel,
} from "./Questionnaire";
import {
  RenderFreeTextBlockContent,
  RenderTypeModel as FreeTextRenderTypeModel,
} from "./FreeText";
import { FocusableBlockContent } from "./useFocusableBlockContent";

export interface RenderBlockContentProps<
  R extends AnyRenderType = AnyRenderType,
> {
  blockId: number;
  reportId: number;
  renderType: R;
  readOnly?: boolean;
}

function RenderBlocContentWithoutRef(
  props: RenderBlockContentProps,
  ref: React.Ref<FocusableBlockContent>,
): JSX.Element {
  const { renderType } = props;
  try {
    if (is(renderType, RichTextRenderTypeModel)) {
      return (
        <RenderRichTextBlockContent
          ref={ref}
          {...props}
          renderType={renderType}
        />
      );
    }
    if (is(renderType, TableRenderTypeModel)) {
      return (
        <RenderTableBlockContent ref={ref} {...props} renderType={renderType} />
      );
    }

    if (is(renderType, QuestionnaireRenderTypeModel)) {
      return (
        <RenderQuestionnaireBlockContent
          ref={ref}
          {...props}
          renderType={renderType}
        />
      );
    }
    if (is(renderType, FreeTextRenderTypeModel)) {
      return (
        <RenderFreeTextBlockContent
          ref={ref}
          {...props}
          renderType={renderType}
        />
      );
    }
    throw Error(
      `No render functions found for block with type: ${props.renderType}`,
    );
  } catch (error) {
    console.warn("Couldn't render building block with props:", props);
    throw error;
  }
}

const RenderBlockContent = React.memo(
  React.forwardRef(RenderBlocContentWithoutRef),
);

export default RenderBlockContent;
