import AttachmentButton, {
  IAttachment,
} from "@/components/AttachmentButton/AttachmentButton";
import { Transforms } from "slate";
import {
  ReactEditor,
  RenderElementProps,
  useSelected,
  useSlateStatic,
} from "slate-react";
import { IAttachmentElement } from "./AttachmentElement";

export interface RenderAttachmentElementProps extends RenderElementProps {
  element: IAttachmentElement;
}

function RenderAttachmentElement({
  element,
  attributes,
  children,
}: RenderAttachmentElementProps) {
  const selected = useSelected();
  const editor = useSlateStatic();
  const path = ReactEditor.findPath(editor, element);
  const handleAttachmentChange = (values: IAttachment) =>
    Transforms.setNodes(editor, { entity: values }, { at: path });
  const { entity, readOnly } = element;
  return (
    <span {...attributes} contentEditable={false}>
      <AttachmentButton
        attachment={entity}
        selected={selected}
        onChange={handleAttachmentChange}
        readOnly={readOnly}
      />
      {children}
    </span>
  );
}

export default RenderAttachmentElement;
