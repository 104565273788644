import {
  ArrowSmallDownIcon,
  ArrowSmallRightIcon,
} from "@heroicons/react/24/outline";
import classNames from "classnames";
import EntityTooltip from "@/components/base/EntityTooltip";
import { useCallback, useContext, useMemo, useState } from "react";
import { Editor, Path, Transforms } from "slate";
import {
  ReactEditor,
  useFocused,
  useSelected,
  useSlateStatic,
} from "slate-react";
import { RenderTiroElementProps } from "../../Base/types";
import { useLevel } from "../../Base/useLevel";
import { useFormattingClassnames } from "../../BasicFormatting/useFormattingClassnames";
import { EntityContextWrapper } from "../Entities/ContextWrapper";
import { EntryElement } from "./EntryElement";
import { TripleStateButton } from "./TripleState";
import { FieldEntryElement, IEntryCollectionElement } from "./types";

export interface RenderFieldEntryProps extends RenderTiroElementProps {
  element: FieldEntryElement;
}

export interface PopoverPanelProps {
  element: RenderFieldEntryProps["element"];
  show: boolean;
  close: () => void;
}

export const RenderFieldEntry = (props: RenderFieldEntryProps) => {
  const { element, attributes, children } = props;
  const { entity } = element;
  const editor = useSlateStatic() as ReactEditor;
  const path = useMemo(
    () => ReactEditor.findPath(editor, element),
    [editor, element]
  );
  const [isEditing, setIsEditing] = useState(
    element.initialQuery !== undefined
  );
  const focused = useFocused();
  const selected = useSelected();
  const [isRootOfHierarchy, isChildOfColumn] = useMemo(() => {
    const parentEntry = Editor.parent(editor, path);
    const [parentBlock] = parentEntry;
    if (Editor.isEditor(parentBlock)) return [false, false];
    const isFirstChild = path[path.length - 1] === 0;
    const isColumnLayoutChild =
      EntryElement.isCollection(parentBlock) &&
      parentBlock.layout === "two-column";
    return [isFirstChild, !isFirstChild && isColumnLayoutChild];
  }, [editor, path]);

  const handleAddAnswer = useCallback(
    (layout: "two-column" | "tree") => {
      Editor.withoutNormalizing(editor, () => {
        Transforms.insertNodes(
          editor,
          { children: [{ text: "" }] },
          { at: Path.next(path) }
        );
        Transforms.wrapNodes(
          editor,
          { layout, type: "collection" } as IEntryCollectionElement,
          { at: Editor.range(editor, path, Path.next(path)) }
        );
      });
      Transforms.select(editor, Editor.end(editor, path));
      ReactEditor.focus(editor);
    },
    [editor, path]
  );
  const handleAddAnswerRight = useCallback(
    () => handleAddAnswer("two-column"),
    [handleAddAnswer]
  );
  const handleAddAnswerBottom = useCallback(
    () => handleAddAnswer("tree"),
    [handleAddAnswer]
  );
  const classes = useFormattingClassnames(element);
  const entityContext = useContext(EntityContextWrapper);
  const level = useLevel(element);
  const parentPath = Path.parent(path);
  return (
    <div
      {...attributes}
      className={classNames(
        "current-entry prose-tiro group relative flex grow items-center rounded-sm",
        "hover:bg-gray-dark/5 current-entry-hover:bg-gray-400/5 current-entry-hover:hover:bg-gray-dark/5",
        { "bg-gray-600/5": selected && focused },
        { "ring ring-blue-200": focused && selected && !isEditing },
        { "-ml-5 -mr-10 pl-6": level === 1, "pl-6": level > 1 }
      )}
    >
      {/** dummy container to be sure that all other DOM elements are inside a contentEditable={false} zone */}
      <div className="contents" contentEditable={false}>
        <TripleStateButton
          className="absolute left-2 block select-none"
          element={element}
          propagate={parentPath}
        />
        <div className="prose-tiro relative flex grow px-1.5">
          <EntityTooltip entity={entity}>
            <button
              type="button"
              tabIndex={-1}
              onClick={(e) => {
                setIsEditing(true);
                Transforms.select(editor, path);
                ReactEditor.blur(editor);
                e.preventDefault();
              }}
              className={classNames(
                "rounded-sm text-left font-medium",
                {
                  "!hover:text-gray-light !text-gray-primary": !element.entity,
                  "hover:text-blue-400": element.entity,
                  "line-through": entityContext === "absent",
                },
                classes
              )}
            >
              {element.entity?.text || "change concept"}
            </button>
          </EntityTooltip>
        </div>
      </div>
      {children}
      {!isRootOfHierarchy && !isChildOfColumn && (
        <div className="flex grow justify-around px-4" contentEditable={false}>
          <button
            type="button"
            tabIndex={-1}
            onClick={handleAddAnswerBottom}
            className={classNames(
              "flex h-full select-none rounded-sm text-xs text-gray-primary hover:bg-black/10",
              {
                visible: focused && selected,
                "invisible hover:visible group-hover:visible":
                  !focused || !selected,
              }
            )}
          >
            <span className="material-icons inline align-bottom text-xs">
              add
            </span>
            maak boom
            <ArrowSmallDownIcon className="inline h-4 w-4 stroke-current align-bottom" />
          </button>
          <button
            type="button"
            tabIndex={-1}
            onClick={handleAddAnswerRight}
            className={classNames(
              "flex h-full select-none rounded-sm text-xs text-gray-primary hover:bg-black/10",
              {
                visible: focused && selected,
                "invisible hover:visible group-hover:visible":
                  !focused || !selected,
              }
            )}
          >
            <span className="material-icons inline align-bottom text-xs">
              add
            </span>
            voeg antwoord toe
            <ArrowSmallRightIcon className="inline h-4 w-4 stroke-current align-bottom" />
          </button>
        </div>
      )}
      <div className="grow select-none" contentEditable={false} />
    </div>
  );
};
