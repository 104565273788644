import classNames from "classnames";
import { HTMLAttributes } from "react";
import { useTranslation } from "react-i18next";

interface PatientTableContainerProps extends HTMLAttributes<HTMLDivElement> {
  onAddNewPatient?: () => void;
}
const PatientTableContainer = ({
  children,
  className,
  onAddNewPatient: onClickNewPatient,
}: PatientTableContainerProps) => {
  const { t } = useTranslation();
  return (
    <div
      className={classNames(
        "flex flex-col px-4 font-primary sm:px-6 lg:px-8",
        className
      )}
    >
      <div className="pb-2 shadow-sm sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-xl font-semibold text-gray-900">
            {t("patienten")}
          </h1>
          <p className="mt-2 text-sm text-gray-dark">
            {t(
              "lijst-met-alle-patienten-gekoppeld-aan-je-account-met-hun-identificatienummer-naam-geslacht-en-geboortedatum-indien-beschikbaar"
            )}
          </p>
        </div>
        <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
          <button
            type="button"
            onClick={onClickNewPatient}
            className="inline-flex items-center justify-center rounded-md border border-transparent bg-blue-primary px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 sm:w-auto"
          >
            {t("voeg-patient-toe")}
          </button>
        </div>
      </div>
      {children}
    </div>
  );
};

export default PatientTableContainer;
