import React from "react";

import { createContext } from "react";
import {
  createFieldProvenanceStore,
  FieldProvenanceProps,
  FieldProvenanceStore,
} from "./store";

export const FieldProvenanceContext = createContext<FieldProvenanceStore>(
  createFieldProvenanceStore()
);

export type BearProviderProps = React.PropsWithChildren<FieldProvenanceProps>;
