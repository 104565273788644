import { isKeyHotkey } from "is-hotkey";

export const HotKeys = {
  isSplitBlock: isKeyHotkey("enter"),
  isInsertLineBreak: isKeyHotkey("shift+enter"),
  isIndent: isKeyHotkey("tab"),
  isTab: isKeyHotkey("shift?+tab"),
  isToggle: isKeyHotkey(["space", "enter"]),
  isEscape: isKeyHotkey("escape"),
  isBackspace: isKeyHotkey("backspace"),
  isDelete: isKeyHotkey("delete"),
};
