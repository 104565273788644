import { ReactEditor, RenderElementProps } from "slate-react";
import { IEditorPlugin } from "../../Base/types";
import {
  ENTRY_COLLECTION,
  FIELD_ENTRY_ELEMENT,
  QA_ANSWER,
  QA_ENTRY,
  QA_QUESTION,
  SUBSECTION_ENTRY,
  SUBTITLE_ENTRY as SUBTITLE_ENTRY,
  TIME_DESCRIPTION,
  TIME_ENTRY,
  TIME_TIMESTAMP,
} from "./consts";
import React from "react";
import { RenderFreeTextEntry, RenderFreeTextEntryProps } from "./FreeText";
import { RenderColumnEntry, RenderColumnEntryProps } from "./Columns";
import {
  RenderTimestampElement,
  RenderTimestampElementProps,
} from "./Timestamp";
import { RenderSubtitleElement, RenderSubtitleElementProps } from "./Subtitle";
import {
  RenderSubsectionElement,
  RenderSubsectionElementProps,
} from "./Subsection";
import { RenderQuestionElement, RenderQuestionElementProps } from "./Question";
import {
  RenderQuestionAnswerEntry,
  RenderQuestionAnswerEntryProps,
} from "./QuestionAnswer";
import { RenderAnswerElement, RenderAnswerElementProps } from "./Answer";
import {
  RenderTimeDescriptionElement,
  RenderTimeDescriptionElementProps,
} from "./TimeDescription";
import { isEventHandled } from "../../util";
import { HotKeys } from "../../hotkeys";
import { Editor, Node, Range, Transforms } from "slate";
import TiroEditor, { ITiroEditor } from "../../Base/editor";
import { EntryElement } from "./EntryElement";
import {
  RenderEntryCollection,
  RenderEntryCollectionProps,
} from "./Collection";
import { RenderFieldEntry, RenderFieldEntryProps } from "./Field";
import { BaseEntry } from "./types";
import isHotkey from "is-hotkey";

const handleKeyDown = (e: React.KeyboardEvent, editor: ITiroEditor) => {
  if (!isEventHandled(e) && isHotkey("enter", e)) {
    const { selection } = editor;
    if (
      ReactEditor.isFocused(editor) &&
      selection &&
      Range.isCollapsed(selection)
    ) {
      if (!isEventHandled(e) && HotKeys.isIndent(e)) {
        if (
          Editor.above(editor, { match: (n) => EntryElement.isFieldEntry(n) })
        ) {
          Editor.insertNode(editor, { children: [{ text: "" }] });
          e.preventDefault();
          return;
        }
      }
    }
  }
  if (!isEventHandled(e) && isHotkey("alt+Q", e)) {
    const { selection } = editor;
    if (
      ReactEditor.isFocused(editor) &&
      selection &&
      Range.isCollapsed(selection)
    ) {
      const [node, path] = Editor.parent(editor, selection.anchor);
      if (EntryElement.isFieldEntry(node)) {
        Transforms.unsetNodes(editor, ["entity", "type"], {
          match: (n) => Editor.isBlock(editor, n),
          mode: "lowest",
        });
        if (node.entity) {
          Transforms.insertText(editor, node.entity.text, {
            at: Editor.start(editor, path),
          });
        }
      } else {
        const [node, path] = Editor.node(editor, selection.anchor);
        Transforms.insertText(editor, "", {
          at: Editor.range(editor, path),
        });
        ReactEditor.blur(editor);
        Transforms.setNodes(
          editor,
          {
            type: FIELD_ENTRY_ELEMENT,
            initialQuery: Node.string(node),
            children: [{ text: "" }],
          },
          { match: (n) => Editor.isBlock(editor, n), mode: "lowest" }
        );
      }
      e.preventDefault();
    }
  }
  if (!isEventHandled(e) && HotKeys.isIndent(e)) {
    const { selection } = editor;
    if (ReactEditor.isFocused(editor) && !!selection) {
      const { selection: selectionBefore, children: childrenBefore } = editor;
      TiroEditor.insertIndent(editor);
      const { selection: selectionAfter, children: childrenAfter } = editor;
      if (
        selectionBefore != selectionAfter ||
        childrenBefore != childrenAfter
      ) {
        e.preventDefault();
      }
    }
  }
  if (!isEventHandled(e) && HotKeys.isTab(e)) {
    const { selection } = editor;
    if (ReactEditor.isFocused(editor) && !!selection) {
      const { selection: selectionBefore, children: childrenBefore } = editor;
      TiroEditor.tabFocus(editor, { reverse: e.shiftKey });
      const { selection: selectionAfter, children: childrenAfter } = editor;
      if (
        selectionBefore != selectionAfter ||
        childrenBefore != childrenAfter
      ) {
        e.preventDefault();
      }
    }
  }

  if (!isEventHandled(e) && HotKeys.isBackspace(e)) {
    const { selection } = editor;
    if (
      ReactEditor.isFocused(editor) &&
      !!selection &&
      Editor.above(editor, { match: (n) => Editor.isVoid(editor, n) })
    ) {
      if (e.repeat) return e.preventDefault();
      Editor.deleteBackward(editor, { unit: "block" });
      e.preventDefault();
    }
  }
  if (!isEventHandled(e) && HotKeys.isDelete(e)) {
    const { selection } = editor;
    if (
      ReactEditor.isFocused(editor) &&
      !!selection &&
      Editor.above(editor, { match: (n) => Editor.isVoid(editor, n) })
    ) {
      if (e.repeat) return e.preventDefault();
      Editor.deleteForward(editor, { unit: "block" });
      e.preventDefault();
    }
  }
  if (!isEventHandled(e) && HotKeys.isSplitBlock(e)) {
    const { selection } = editor;
    if (
      ReactEditor.isFocused(editor) &&
      !!selection &&
      Editor.above(editor, { match: (n) => Editor.isVoid(editor, n) })
    ) {
      Editor.insertNode(editor, { children: [{ text: "" }] });
      e.preventDefault();
    }
  }
};

export const EntryLayoutPlugin: IEditorPlugin = {
  handleKeyDown,
  renderElement: (props: RenderElementProps) => {
    const { element } = props;
    const { type = null } = element as BaseEntry;

    switch (type) {
      case ENTRY_COLLECTION:
        return (
          <RenderEntryCollection {...(props as RenderEntryCollectionProps)} />
        );
      case SUBTITLE_ENTRY:
        return (
          <RenderSubtitleElement {...(props as RenderSubtitleElementProps)} />
        );
      case SUBSECTION_ENTRY:
        return (
          <RenderSubsectionElement
            {...(props as RenderSubsectionElementProps)}
          />
        );
      case QA_ENTRY:
        return (
          <RenderQuestionAnswerEntry
            {...(props as RenderQuestionAnswerEntryProps)}
          />
        );
      case QA_QUESTION:
        return (
          <RenderQuestionElement {...(props as RenderQuestionElementProps)} />
        );
      case QA_ANSWER:
        return <RenderAnswerElement {...(props as RenderAnswerElementProps)} />;
      case TIME_ENTRY:
        console.warn(
          `${TIME_ENTRY} is deprecated. You can replace this with a simple free text field.`
        );
        return <RenderColumnEntry {...(props as RenderColumnEntryProps)} />;
      case TIME_TIMESTAMP:
        return (
          <RenderTimestampElement {...(props as RenderTimestampElementProps)} />
        );
      case TIME_DESCRIPTION:
        console.warn(
          `${TIME_DESCRIPTION} is deprecated. You can replace this with a simple free text field.`
        );
        return (
          <RenderTimeDescriptionElement
            {...(props as RenderTimeDescriptionElementProps)}
          />
        );
      case FIELD_ENTRY_ELEMENT:
        return <RenderFieldEntry {...(props as RenderFieldEntryProps)} />;
      default:
        return <RenderFreeTextEntry {...(props as RenderFreeTextEntryProps)} />;
    }
  },
  voidTypes: [FIELD_ENTRY_ELEMENT],
};
