import { TypedElement } from "../../TypedElement";
import { QUESTIONNAIRE_ELEMENT } from "./consts";
import { IQuestionnaireElement } from "./types";

export const QuestionnaireElement = {
  ...TypedElement,
  isQuestionnaireElement: (o: any): o is IQuestionnaireElement => {
    return TypedElement.isTypedElement(o) && o.type === QUESTIONNAIRE_ELEMENT;
  },
};
