import Kbd from "@/components/base/Kbd";
import { Instruction } from "./Action";

export class PastFromClipboard extends Instruction {
  get current() {
    return {
      status: this.status,
      renderMessage: () => (
        <>
          U mag de verslagtekst plakken met <Kbd>CTRL</Kbd> + <Kbd>V</Kbd> in
          het verslagveld van het EPD.
        </>
      ),
    };
  }
}
