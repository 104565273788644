import { LinkIcon } from "@heroicons/react/20/solid";
import { CONFIG } from "@/config";
import { useTranslation } from "react-i18next";

function ServerSettings() {
  const { t } = useTranslation();
  return (
    <div className="mx-auto max-w-2xl space-y-16 sm:space-y-20 lg:mx-0 lg:max-w-none">
      <form className="divide-y divide-gray-200">
        {/* Profile section */}
        <div className="px-4 lg:pb-8">
          <div>
            <h2 className="text-lg font-medium leading-6 text-gray-900">
              {t("atticus-server-instellingen")}
            </h2>
          </div>
          <div className="mt-6 max-w-xl space-y-2">
            <div className="col-span-12">
              <label
                htmlFor="data-exporter"
                className="block text-sm font-medium text-gray-dark"
              >
                {t("versie")}
              </label>
              <input
                type="text"
                name="data-export"
                value={CONFIG.version}
                className="mt-1 w-full rounded-md border border-gray-light px-3 py-2 shadow-sm focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm"
                readOnly
              />
            </div>

            <div className="col-span-12">
              <label
                htmlFor="lastName"
                className="block text-sm font-medium text-gray-dark"
              >
                {t("content-engine")}
              </label>
              <div className="relative mt-1 flex w-full rounded-md shadow-sm">
                <input
                  type="text"
                  name="data-export"
                  value={CONFIG["CONTENT_ENGINE"]}
                  className="relative flex-1 rounded-l-md border border-gray-light px-3 py-2 shadow-sm focus:z-10 focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm"
                  readOnly
                />
                <a
                  href={CONFIG["CONTENT_ENGINE"]}
                  target="_blank"
                  className="inline-flex items-center rounded-r-md border border-l-0 border-gray-light bg-gray-50 px-3 text-sm text-gray-primary"
                  rel="noreferrer"
                >
                  <LinkIcon className="h-4 w-4" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}

export default ServerSettings;
