import CodingTooltip from "@/components/base/CodingCard/CodingTooltip";
import Tooltip from "@/components/info-cards/Tooltip";
import { IHumanReadableCoding } from "@/data-models/value-models/types";
import { useFeatureFlagEnabled } from "posthog-js/react";
import { HTMLAttributes } from "react";

export default function UnitCoding({
  hidden,
  coding,
  ...props
}: HTMLAttributes<HTMLSpanElement> & {
  hidden?: boolean;
  coding: IHumanReadableCoding;
}) {
  const showCodingCardTooltip = useFeatureFlagEnabled(
    "show-coding-card-tooltip",
  );
  return (
    <Tooltip enabled={showCodingCardTooltip}>
      <Tooltip.Trigger>
        <span
          {...props}
          className={`whitespace-nowrap px-1 ${hidden ? "hidden" : "inline"}`}
        >
          {coding.display}
        </span>
      </Tooltip.Trigger>
      <Tooltip.Content className="fill-gray-lighter">
        <CodingTooltip coding={coding} showCard />
      </Tooltip.Content>
    </Tooltip>
  );
}
