import { QueryClient } from "react-query";
import { LoaderFunction } from "react-router";
import { createPatientQuery } from "@/services/reports/usePatient";
import { createReportQuery } from "@/services/reports/useReport";
import { z } from "zod";

export default (queryClient: QueryClient): LoaderFunction =>
  async ({ params }) => {
    const patientId = z.coerce.number().parse(params.patientId, {
      errorMap: () => {
        return { message: "Patient ID is not a number" };
      },
    });
    queryClient.prefetchQuery(createPatientQuery(patientId));

    const reportId = z.coerce.number().parse(params.reportId, {
      errorMap: () => {
        return { message: "Report ID is not a number" };
      },
    });
    queryClient.prefetchQuery(
      createReportQuery(reportId, { blocksEmbedded: false })
    );

    return null;
  };
