import { useEffect } from "react";
import { is, literal, pattern, string, type } from "superstruct";
import useStableCallback from "./useStableCallback";
interface useWebMessageOptions {
  allowedOrigin: string;
  messagingHandle: string;
}

export type IRequestMessage = {
  payload: Record<string, any>;
  messageId: string;
  messagingHandle: string;
  messageType: string;
};

export type IResponseMessage = {
  payload: Record<string, any>;
  responseToMessageId: string;
  messagingHandle: string;
};
const message = (handle: string, payload = type({})) =>
  type({
    payload,
    messagingHandle: literal(handle),
    messageType: pattern(
      string(),
      /(scratchpad\.(create|read|update|delete)|ui\.done)/,
    ),
    messageId: string(),
  });

/**
 * This hook should be migrated to smart-web-messaging
 */
async function useSMARTWebMessaging(
  onMessageReceived: (
    message: IRequestMessage,
    response: (message: IResponseMessage) => void,
  ) => void,
  options: useWebMessageOptions,
) {
  const { allowedOrigin, messagingHandle } = options;
  const onStableMessage = useStableCallback(onMessageReceived);
  useEffect(() => {
    const callback = (e: MessageEvent) => {
      const { data, origin } = e;
      const allowedOriginURL = new URL(allowedOrigin);
      const event_origin = new URL(origin);
      if (
        event_origin.host === allowedOriginURL.host &&
        is(data, message(messagingHandle))
      ) {
        onStableMessage(data, (response: Object) => {
          e.source?.postMessage(response, { targetOrigin: e.origin });
        });
      }
    };
    window.addEventListener("message", callback, false);
    return () => {
      window.removeEventListener("message", callback);
    };
  }, [onStableMessage, allowedOrigin, messagingHandle]);
}

export default useSMARTWebMessaging;
