import classNames from "classnames";
import SlateSuggestEditor from "@/components/controlled/SlateSuggestEditor";

import { useEffect, useMemo } from "react";
import AddCheckElement from "@/editor/legacy/CheckPlugin/AddCheckElement";
import constructAnswerEditor from "./createAnswerEditor";
import { ITiroElement } from "@/data-models/nodes";
import AnswerForm from "./AnswerForm";
import { FieldValues } from "react-hook-form";

export interface AnswerProps<
  C extends ITiroElement[] = ITiroElement[],
  V extends FieldValues = FieldValues,
> {
  linkId: string;
  className?: string;
  content: C | undefined;
  onContentChange: (v: C) => void;
}

const Styles = {
  CONTENT_EDITABLE:
    "border border-gray-200 focus-within:border-blue-primary focus-within:outline-1 focus-within:outline-blue-primary focus-within:drop-shadow-sm px-1 py-0.5",
  DATA_INPUT: "",
};
function Answer<
  C extends ITiroElement[] = ITiroElement[],
  V extends FieldValues = FieldValues,
>({ linkId, content, className, onContentChange }: AnswerProps<C, V>) {
  const editor = useMemo(() => constructAnswerEditor(), []);
  useEffect(() => {
    console.log("editor changed");
    editor.onChange();
  }, [content]);
  console.log(content);

  const Footer = useMemo(() => <AddCheckElement />, []);
  if (!content) return null;

  return (
    <AnswerForm linkId={linkId}>
      <SlateSuggestEditor
        className={classNames(
          "relative rounded  bg-white",
          Styles.DATA_INPUT,
          className,
        )}
        editableFooter={Footer}
        onDrop={console.log}
        editor={editor}
        readOnly
        value={content}
        setValue={onContentChange}
      />
    </AnswerForm>
  );
}
export default Answer;
