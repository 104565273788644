import classNames from "classnames";
import React, { HTMLProps, Ref } from "react";

export interface MenuListProps extends HTMLProps<HTMLUListElement> {
  open?: boolean;
}
function MenuListWithoutRef(
  { open = true, className, children, ...props }: MenuListProps,
  ref: Ref<HTMLUListElement>,
) {
  return (
    <ul
      ref={ref}
      className={classNames(
        className,
        "rounded-md bg-white py-0.5 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm",
        { hidden: !open, visible: open },
      )}
      {...props}
    >
      {children}
    </ul>
  );
}
const MenuList = React.forwardRef(MenuListWithoutRef);
export default MenuList;
