import { useCallback, useRef } from "react";
import { FocusableBlockContent } from "./content/useFocusableBlockContent";

function useContentFocus() {
  const contentRef = useRef<FocusableBlockContent>(null);
  const focusContent = useCallback(
    () => contentRef.current?.focus(),
    [contentRef],
  );

  return [contentRef, focusContent] as const;
}
export default useContentFocus;
