import { Transition } from "@headlessui/react";
import { ChevronUpIcon } from "@heroicons/react/24/outline";
import classNames from "classnames";
import React, { FormEventHandler } from "react";

export interface TableGroupOption {
  id: string;
  name: string;
  tables: TableOption[];
}

interface TableOption {
  id: string;
  name: string;
}

export type TableSelectionFieldsetProps = { groups: TableGroupOption[] };

/**
 * Form with radio buttons and nested checkboxes for selecting tables to export
 * @param param0
 */
export default function TableSelectionFieldset({
  groups,
}: TableSelectionFieldsetProps) {
  const [selectedGroup, setSelectedGroup] = React.useState<string | null>(null);
  const handleGroupInput = (event: React.FormEvent<HTMLInputElement>) => {
    setSelectedGroup(event.currentTarget.value);
    const groupId = event.currentTarget.value;
    const group = groups.find((g) => g.id === groupId);
    const tableIds = group?.tables.map((t) => t.id) ?? [];
    const nodeList = event.currentTarget.form?.elements.namedItem("table");
    if (!event.currentTarget.form) {
      console.warn("No form found for", event.currentTarget);
    }
    if (nodeList instanceof NodeList) {
      nodeList.forEach((node) => {
        if (node instanceof HTMLInputElement)
          node.checked = tableIds.includes(node.value);
      });
    } else {
      console.warn("No table checkboxes found for", event.currentTarget);
    }
  };

  const handleInputChange: FormEventHandler<HTMLFieldSetElement> = (event) => {
    event.currentTarget.form?.requestSubmit();
  };
  return (
    <fieldset className="flex flex-col gap-1" onInput={handleInputChange}>
      {groups.map((group) => (
        <div
          key={group.id}
          className={classNames("rounded px-3 py-1.5", {
            "bg-gray-50": group.id != selectedGroup,
            "bg-blue-50": group.id == selectedGroup,
          })}
        >
          <div className="flex items-baseline">
            <input
              id={group.id}
              type="radio"
              name="group-id"
              value={group.id}
              className="h-3 w-3 rounded-full text-blue-500"
              checked={group.id === selectedGroup}
              onChange={handleGroupInput}
            />
            <input
              type="hidden"
              value={group.name}
              name="group"
              disabled={group.id !== selectedGroup}
            />

            <label
              htmlFor={group.id}
              className="ml-2 flex grow cursor-pointer items-baseline justify-between text-sm text-gray-darker hover:text-gray-900 disabled:cursor-not-allowed disabled:text-gray-500 disabled:opacity-50"
            >
              <span>{group.name}</span>
              <ChevronUpIcon
                className={classNames("inline h-4 w-4 text-gray-dark", {
                  "rotate-180 transform transition-transform":
                    group.id == selectedGroup,
                })}
              />
            </label>
          </div>
          <Transition
            as="div"
            className="ml-5 mt-1"
            show={group.id === selectedGroup}
            unmount={false}
          >
            {group.tables.map((table) => (
              <div key={table.id} className="flex items-baseline">
                <input
                  id={`${group.id}-${table.id}`}
                  type="checkbox"
                  name="table"
                  className="h-3 w-3 rounded text-blue-500 disabled:cursor-not-allowed disabled:opacity-50"
                  disabled={group.id !== selectedGroup}
                  value={table.id}
                />
                <label
                  htmlFor={`${group.id}-${table.id}`}
                  className="ml-2 text-sm text-gray-darker disabled:cursor-not-allowed disabled:text-gray-500 disabled:opacity-50"
                >
                  {table.name}
                </label>
              </div>
            ))}
          </Transition>
        </div>
      ))}
    </fieldset>
  );
}
