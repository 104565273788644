import AttachmentPlugin from "@/editor/AttachmentPlugin";
import { IEditorPlugin } from "@/editor/Base/types";
import CalculatorPlugin from "@/editor/CalculatorPlugin";
import { InlineEntityPlugin } from "@/editor/legacy/Entities/InlineEntityPlugin";
import DropdownPlugin from "@/editor/legacy/DropdownPlugin";
import BadgePlugin from "@/editor/legacy/BadgePlugin";
import { EntryLayoutPlugin } from "@/editor/legacy/EntryLayoutPlugin/Plugin";
import TablePlugin from "@/editor/legacy/TablePlugin";
import { SuggestPlugin } from "@/editor/legacy/Suggestions";
import QuestionnairePlugin from "@/editor/legacy/QuestionnairePlugin";
import { RenderType } from "../types";
import BasicFormattingPlugin from "@/editor/BasicFormatting/BasicFormattingPlugin";
import BlockLayoutPlugin from "@/editor/legacy/BlockLayoutPlugin";
import ToggleButtonPlugin from "@/editor/legacy/ToggleButtonPlugin";
import CheckPlugin from "@/editor/legacy/CheckPlugin";

/**
 * Rich text with a forced entry layout.
 */
const ENTRY_LAYOUT_PLUGINS: IEditorPlugin[] = [
  BlockLayoutPlugin,
  InlineEntityPlugin,
  CalculatorPlugin,
  BadgePlugin,
  ToggleButtonPlugin,
  DropdownPlugin,
  AttachmentPlugin,
  EntryLayoutPlugin,
  SuggestPlugin,
];
/**
 * Rich text with support for embedded Questionnaires
 */
const QUESTIONNAIRE_PLUGINS: IEditorPlugin[] = [
  BlockLayoutPlugin,
  InlineEntityPlugin,
  CalculatorPlugin,
  BadgePlugin,
  ToggleButtonPlugin,
  DropdownPlugin,
  QuestionnairePlugin,
  AttachmentPlugin,
  CheckPlugin,
  SuggestPlugin,
  BasicFormattingPlugin,
];
/**
 *  Rich text with support for a table
 */
const TABLE_PLUGINS: IEditorPlugin[] = [
  BlockLayoutPlugin,
  InlineEntityPlugin,
  CalculatorPlugin,
  CheckPlugin,
  BadgePlugin,
  ToggleButtonPlugin,
  DropdownPlugin,
  QuestionnairePlugin,
  TablePlugin,
  AttachmentPlugin,
  EntryLayoutPlugin,
  SuggestPlugin,
];
function getPlugins(renderType: RenderType) {
  switch (renderType) {
    case "qa-rich-text":
      return ENTRY_LAYOUT_PLUGINS;
    case "timestamped-rich-text":
      return ENTRY_LAYOUT_PLUGINS;
    case "table-rich-text":
      return TABLE_PLUGINS;
    case "rich-text-with-questionnaire":
      return QUESTIONNAIRE_PLUGINS;
  }
}

export default getPlugins;
