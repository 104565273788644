import QuantityWithComponents, {
  IQuantityWithComponents,
  QuantityWithComponentsModel,
} from "./QuantityWithComponents";
import SimpleQuantity, {
  ISimpleQuantity,
  SimpleQuantityModel,
} from "./SimpleQuantity";
import QuantityComponent, {
  IQuantityComponent,
  QuantityComponentModel,
} from "./QuantityComponent";
import { union, is } from "superstruct";

export const QuantityModel = union([
  SimpleQuantityModel,
  QuantityWithComponentsModel,
]);

export type IQuantity = ISimpleQuantity | IQuantityWithComponents;
export type Quantity = SimpleQuantity | QuantityWithComponents;
/**
 * Creates a Quantity instance based give a plain object.
 */
export function createQuantityEntity(obj: ISimpleQuantity): SimpleQuantity;
export function createQuantityEntity(
  obj: IQuantityWithComponents
): QuantityWithComponents;
export function createQuantityEntity(obj: IQuantity): Quantity;
export function createQuantityEntity(obj: IQuantity): Quantity {
  if (is(obj, SimpleQuantityModel)) return new SimpleQuantity(obj);
  if (is(obj, QuantityWithComponentsModel))
    return new QuantityWithComponents(obj);
  throw Error("Could not transform object into a quantity instance.");
}

export type { IQuantityComponent, IQuantityWithComponents, ISimpleQuantity };
export {
  QuantityComponent,
  QuantityWithComponents,
  SimpleQuantity,
  QuantityComponentModel,
  QuantityWithComponentsModel,
  SimpleQuantityModel,
};
