import { sendPasswordResetEmail } from "firebase/auth";
import { ActionFunction } from "react-router";
import { getTenantAuth } from "@/services/tenant";
import { z, ZodError } from "zod";
import { ASK_USER_DATA_PATH } from "../ask-user-data/const";

export default (): ActionFunction =>
  async ({ request }): Promise<ActionData> => {
    const payload = await request.formData();
    const auth = getTenantAuth();
    const continueURL = new URL(ASK_USER_DATA_PATH, request.url);
    try {
      const email = z
        .string()
        .email("invalid-email")
        .parse(payload.get("email"));
      await sendPasswordResetEmail(auth, email, {
        url: continueURL.toString(),
      });
      return { status: "success", email };
    } catch (e) {
      if (e instanceof ZodError) {
        return { errorCode: e.message, status: "error" };
      }
      throw e;
    }
  };
export type ActionData =
  | { errorCode?: string; status: "error" }
  | { status: "success"; email: string };
