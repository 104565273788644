import fhirpath from "fhirpath";
import React from "react";
type FHIRPathEnvironment = Record<string, any>;
export const FHIRPathExpressionEnvironment =
  React.createContext<FHIRPathEnvironment>({});
function useFHIRPath(
  path: string | undefined | null,
  resource?: unknown,
  env?: FHIRPathEnvironment
): any[] | undefined {
  const expressionContext = React.useContext(FHIRPathExpressionEnvironment);
  if (!path) return undefined;
  const result = resource
    ? fhirpath.evaluate(resource, path, env ?? expressionContext)
    : undefined;
  return result;
}

export function useFHIRPaths(
  paths: string[],
  resource?: unknown,
  env?: FHIRPathEnvironment
): any[][] | undefined {
  const expressionContext = React.useContext(FHIRPathExpressionEnvironment);
  const result = resource
    ? paths.map((p) => fhirpath.evaluate(resource, p, env ?? expressionContext))
    : undefined;
  return result;
}

interface FHIRPathProps {
  path: string;
  resource: unknown;
  children: (result?: any[]) => JSX.Element;
}

export function FHIRPath({ path, resource, children }: FHIRPathProps) {
  return children(useFHIRPath(path, resource));
}

export default useFHIRPath;
