import React from "react";
import { is, string } from "superstruct";

export const CurrentTemplateBlockIdContext = React.createContext<string | null>(
  null
);

function useCurrentTemplateBlockId() {
  const currentTemplateBlockId = React.useContext(
    CurrentTemplateBlockIdContext
  );
  if (!is(currentTemplateBlockId, string())) {
    throw new Error("CurrentTemplateBlockIdContext is not set");
  }
  return currentTemplateBlockId;
}
export default useCurrentTemplateBlockId;
