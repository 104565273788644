import { union } from "superstruct";
import { ConceptEntityModel } from "./concept-entity";
import { DateEntityModel } from "./date-entity";
import { DateTimeEntityModel } from "./datetime-entity";
import { QuantityModel } from "./quantity-entity";
import { TimeEntityModel } from "./time-entity";

export const EntityModel = union([
  ConceptEntityModel,
  TimeEntityModel,
  DateEntityModel,
  DateTimeEntityModel,
  QuantityModel,
]);
export interface IAbstractEntity {
  text: string;
  isValid: boolean;
  value: number | string | Date | boolean;
}
