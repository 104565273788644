import MergeTag from "@/components/legacy/MergeTag/MergeTag";
import { IMergeTag } from "@/components/legacy/MergeTag/types";
import InlineChromiumBugfix from "@/components/InlineChromiumBugFix";
import React, { useCallback } from "react";
import { Transforms } from "slate";
import { ReactEditor, useSlateStatic } from "slate-react";
import { RenderTypedElementProps } from "../../TypedElement";
import { IMergeTagElement } from "./MergeTagElement";

export interface RenderMergeTagElementProps extends RenderTypedElementProps {
  element: IMergeTagElement;
}
function RenderMergeTagElement({
  element,
  attributes,
  children,
}: RenderMergeTagElementProps) {
  const { entity: mergeTag } = element;
  const editor = useSlateStatic();

  // save the resolved entity to the element
  const handleResolve = useCallback(
    (mergeTag: IMergeTag) => {
      const path = ReactEditor.findPath(editor, element);
      Transforms.setNodes(editor, { entity: mergeTag }, { at: path });
    },
    [element, editor],
  );

  return (
    <span {...attributes} contentEditable={false}>
      <InlineChromiumBugfix />
      <MergeTag
        mergeTag={mergeTag}
        onResolve={handleResolve}
        className="select-auto"
      />
      {children}
      <InlineChromiumBugfix />
    </span>
  );
}

export default RenderMergeTagElement;
