import Button from "@/components/base/Button";
import { Spinner } from "@/Icons";
import { useMutation } from "react-query";
import useIdToken from "@/services/auth/useIdToken";
import PageContainer from "./PageContainer";
import Panel from "./Panel";

export interface ShowIdTokenProps {}

function ShowIdToken() {
  console.log("Rendering ShowIdToken");
  const { data: token } = useIdToken({ refetchOnMount: false });
  const { mutate, isLoading, isSuccess } = useMutation((token: string) =>
    navigator.clipboard.writeText(token)
  );
  return (
    <PageContainer logo>
      <Panel>
        <p className="prose prose-sm">
          {!token && (
            <div>
              <Spinner className="h-6 w-6 animate-spin" />
            </div>
          )}
          {token && <pre>{token}</pre>}
          {token && (
            <Button
              type="button"
              onClick={() => token && mutate(token)}
              label={isSuccess ? "Gekopieerd!" : "Kopieer"}
              kind={isLoading ? "loader" : "default"}
            />
          )}
        </p>
      </Panel>
    </PageContainer>
  );
}

export default ShowIdToken;
