import { apm } from "@elastic/apm-rum";
import { useEffect } from "react";

export enum TransactionTypes {
  PAGE_LIFETIME = "page-lifetime",
  CUSTOM = "custom",
}

function useMeasureComponentLifeTime(
  name?: string,
  type: TransactionTypes = TransactionTypes.PAGE_LIFETIME
) {
  /**
   * Measure the lifetime of a component and logs it into Elastic APM.
   * The lifetime is measure as the timestamp when the component is unmounted or the window is closed minus the timestamp when the component is mounted.
   */

  useEffect(() => {
    const tx = apm.startTransaction(name, type);
    const handleEndTransaction = () => tx?.end();
    window.addEventListener("beforeunload", handleEndTransaction);

    return () => {
      window.removeEventListener("beforeunload", handleEndTransaction);
      handleEndTransaction();
    };
  }, [name, type]);
}
export default useMeasureComponentLifeTime;
