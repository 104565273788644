import { MarkdownInput } from "@/components/inputs/MarkdownInput";
import { RenderTypedElementProps } from "@/editor/TypedElement";
import useSlateInputElement from "@/editor/utils/useSlateInputElement";
import { IMarkdownInputElement } from "./MarkdownInputElement";

export function RenderMarkdownInputElement({
  element,
  attributes,
  children,
}: RenderTypedElementProps & { element: IMarkdownInputElement }) {
  const { name, disabled, active } = useSlateInputElement(element);
  return (
    <div {...attributes}>
      <MarkdownInput
        name={name}
        className="min-h-[2.25rem] text-base sm:min-h-[2rem] sm:text-sm"
        disabled={disabled}
        active={active}
        defaultValue={element.defaultValue}
        placeholder={element.placeholder}
        valueSet={element.valueSet}
      />
      {children}
    </div>
  );
}
