import { Menu, Transition } from "@headlessui/react";
import classNames from "classnames";
import React, { Fragment } from "react";

interface BlockMenuItemsProps {
  open?: boolean;
  children?: React.ReactNode;
  className?: string;
  style?: React.CSSProperties;
}

function BlockMenuItems_(
  {
    open,
    children,
    className,
    style,
  }: React.PropsWithChildren<BlockMenuItemsProps>,
  ref?: React.Ref<HTMLDivElement>,
) {
  return (
    <Transition
      show={open}
      as={Fragment}
      enter="transition-opacity ease-out duration-200"
      enterFrom="opacity-0 scale-95"
      enterTo="opacity-100 scale-100"
      leave="transition-opacity ease-in duration-75"
      leaveFrom="opacity-100 scale-100"
      leaveTo="opacity-0 scale-95"
    >
      <Menu.Items
        ref={ref}
        static
        className={classNames(
          "rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none",
          className,
        )}
        style={style}
      >
        {children}
      </Menu.Items>
    </Transition>
  );
}
const BlockMenuItems = React.forwardRef(BlockMenuItems_);
export default BlockMenuItems;
