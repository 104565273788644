import classNames from "classnames";
import { Logo } from "@/components/Logo";
import React from "react";

interface PageContainerProps
  extends Omit<React.HTMLAttributes<HTMLDivElement>, "title"> {
  title?: string;
  logo?: boolean;
}
function PageContainer({
  children,
  title,
  logo,
  className,
}: PageContainerProps) {
  return (
    <div
      className={classNames(
        "flex items-stretch justify-center overflow-y-scroll bg-gray-50 px-4 py-12 sm:px-6 lg:px-8",
        className
      )}
    >
      <div className="h-fit w-full max-w-lg space-y-8">
        <div className="flex flex-col justify-center">
          {logo && (
            <Logo
              className="block h-8 w-auto"
              color="#007FFF"
              aria-label="Tiro.health"
            />
          )}
          {title && (
            <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
              {title}
            </h2>
          )}
        </div>
        {children}
      </div>
    </div>
  );
}
export default PageContainer;
