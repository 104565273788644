import RenderBlock from "@/components/blocks/RenderBlock";
import { useBlockNavigationSync } from "@/hooks/smart-web-messaging/useBlockScrollNavigationSync";
import useCompositionSync from "@/hooks/smart-web-messaging/useCompositionSync";
import useCurrentBlockId from "@/hooks/useCurrentBlockId";
import useCurrentReportId from "@/hooks/useCurrentReportId";
import useCurrentSubjectId from "@/hooks/useCurrentSubjectId";
import useLaunchedFrom from "@/hooks/useLaunchedFrom";

export default function BlockEditPage() {
  const reportId = useCurrentReportId();
  const patientId = useCurrentSubjectId();
  const blockId = useCurrentBlockId();
  const isSynops = useLaunchedFrom("Synops");
  useBlockNavigationSync({ reportId, patientId }, { enabled: isSynops });
  useCompositionSync(reportId, { enabled: isSynops });
  return (
    <div>
      <RenderBlock key={blockId} id={blockId} reportId={reportId} />
    </div>
  );
}
