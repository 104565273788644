import React, { RefObject } from "react";

export const MultiFormRefContext = React.createContext<
  Record<string, RefObject<HTMLFormElement>>
>({});

export function MultiFormRefProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const formRefs = React.useRef<Record<string, RefObject<HTMLFormElement>>>({});
  return (
    <MultiFormRefContext.Provider value={formRefs.current}>
      {children}
    </MultiFormRefContext.Provider>
  );
}

export function useMultiFormRef(
  formId: string,
  ref: RefObject<HTMLFormElement>
) {
  const formRefs = React.useContext(MultiFormRefContext);
  formRefs[formId] = ref;
}

export function useMultiFormSubmit() {
  const formRefs = React.useContext(MultiFormRefContext);
  const submit = React.useCallback(() => {
    Object.values(formRefs).forEach((formRef) => {
      formRef.current?.requestSubmit();
    });
  }, [formRefs]);
  return submit;
}
