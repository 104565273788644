import Identifier from "@/data-models/value-models/identifier";
import { AuthCredential, signInWithCredential } from "firebase/auth";
import React from "react";
import { useMutation } from "react-query";
import { useLoaderData } from "react-router";
import { getTenantAuth, ISS_TO_PROVIDER } from "@/services/tenant";
import { LoaderData } from "./loader";

function SMARTRedirectData() {
  const { id_token, patient, encounter } = useLoaderData() as LoaderData;
  const { mutate: signIn } = useMutation((credential: AuthCredential) =>
    signInWithCredential(getTenantAuth(), credential)
  );
  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    const idToken = formData.get("id_token") as string;
    const provider =
      ISS_TO_PROVIDER["https://launch.smarthealthit.org/v/r4/fhir"]();
    const credential = provider.credential({ idToken });
    signIn(credential);
  };

  const patientIdentifier =
    patient.identifier && new Identifier(patient.identifier?.[0]! as any);
  const encounterIdentifier =
    encounter.identifier && new Identifier(encounter.identifier?.[0]! as any);
  return (
    <main className="overflow-y-scroll p-10">
      <section className="prose">
        <h2>Current practitioner</h2>
        <pre>{id_token}</pre>
        <form onSubmit={handleSubmit}>
          <label>
            <code>id_token</code>
            <input
              type="text"
              name="id_token"
              readOnly
              className="block"
              value={id_token}
            />
          </label>
          <button
            type="submit"
            className="rounded-md border border-gray-200 p-2"
          >
            Sign-in
          </button>
        </form>
        <form action="/patients/dispatch" method="get">
          <label>
            <code>patient</code>
            <input
              type="text"
              name="patient"
              readOnly
              className="block"
              value={patientIdentifier?.toToken() ?? patient.id}
            />
          </label>
          <label>
            <code>encounter</code>
            <input
              type="text"
              name="encounter"
              readOnly
              className="block"
              value={encounterIdentifier?.toToken() ?? encounter.id}
            />
          </label>
          <button
            type="submit"
            className="rounded-md border border-gray-200 p-2"
          >
            Dispatch
          </button>
        </form>
      </section>
      <section className="prose">
        <details>
          <summary>Current patient</summary>
          <pre>{JSON.stringify(patient, null, 2)}</pre>
        </details>
      </section>
      <section className="prose">
        <details>
          <summary>Current encounter</summary>
          <pre>{JSON.stringify(encounter, null, 2)}</pre>
        </details>
      </section>
      {/*<form
        className="mt-4"
        method="post"
        action={token_endpoint}
      >
        <label className="block">
          <code>grant_type</code>
          <input
            type="text"
            readOnly
            name="grant_type"
            className="block"
            defaultValue="authorization_code"
          />
        </label>
        <label className="block">
          <code>code</code>
          <input
            type="text"
            readOnly
            name="code"
            className="block"
            defaultValue={code}
          />
        </label>
        <label className="block">
          <code>redirect_uri</code>
          <input
            type="text"
            readOnly
            name="redirect_uri"
            className="block"
            defaultValue="https://write.tiro.local:3000/smart/redirect"
          />
        </label>
        <label className="block">
          <code>code_verifier</code>
          <input
            type="text"
            disabled
            className="block"
            name="code_verifier"
          />
        </label>
        <label className="block">
          <code>client_id</code>
          <input
            type="text"
            readOnly
            name="client_id"
            className="block"
            defaultValue="write.tiro.local"
          />
        </label>
        <button
          type="submit"
          className="rounded-md border border-gray-200 p-2 "
        >
          Launch SMART App
        </button>
  </form>*/}
    </main>
  );
}

export default SMARTRedirectData;
