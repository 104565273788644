import Tippy from "@tippyjs/react";
import classNames from "classnames";
import { Node } from "slate";
import { RenderElementProps, useFocused, useSelected } from "slate-react";
import { TimeEntryTimestamp } from "./types";

export interface RenderTimestampElementProps extends RenderElementProps {
  element: TimeEntryTimestamp;
}

export const RenderTimestampElement = ({
  attributes,
  children,
  element,
}: RenderTimestampElementProps) => {
  const selected = useSelected();
  const focused = useFocused();
  const active = selected && focused;
  const { isValid } = element;
  const validated = isValid !== undefined;
  //useDateReformatter(element);

  const tooltip = isValid
    ? `Datum: ${Node.string(element)}`
    : "Deze datum herkennen we niet.";
  return (
    <div
      {...attributes}
      className={classNames(
        "relative w-full rounded-sm px-2 current-entry-hover:bg-gray-400/5 current-entry-hover:hover:bg-gray-800/5",
        { "bg-gray-800/5": active }
      )}
    >
      {Node.string(element).length == 0 && (
        <span className="absolute inset-x-2 inline-block select-none text-right text-primary/30 current-entry-hover:text-primary/50">
          datum
        </span>
      )}
      <Tippy content={tooltip} delay={[500, 50]}>
        <span
          className={classNames(
            "relative inline-block w-full text-right font-medium tabular-nums tracking-wide text-gray-600",
            {
              "decoration-green-600/20 decoration-2 underline-offset-1 transition-opacity hover:underline":
                validated && element.isValid,
              "underline decoration-red-600/20 underline-offset-1 hover:decoration-red-600/40 hover:decoration-2":
                validated && !element.isValid && !selected,
            }
          )}
        >
          {children}
        </span>
      </Tippy>
    </div>
  );
};
