import { Menu } from "@headlessui/react";
import classNames from "classnames";
import React from "react";

export type BlockMenuLinkProps = React.ButtonHTMLAttributes<HTMLButtonElement>;

export default function BlockMenuLink({
  children,
  className,
  ...props
}: BlockMenuLinkProps) {
  return (
    <Menu.Item
      as="button"
      className={({ active }) =>
        classNames(
          {
            "bg-white": !active,
            "bg-gray-50 text-gray-600": active,
          },
          "block w-full whitespace-nowrap px-3 py-2 text-left text-sm text-gray-dark",
          className,
        )
      }
      {...props}
    >
      {children}
    </Menu.Item>
  );
}
