import {
  ConceptEntity,
  ConceptEntityModel,
} from "@/data-models/concept-entity";
import { DateEntity, DateEntityModel } from "@/data-models/date-entity";
import {
  DateTimeEntity,
  DateTimeEntityModel,
} from "@/data-models/datetime-entity";
import { IAbstractEntity, EntityModel } from "@/data-models/entity";
import {
  QuantityModel,
  createQuantityEntity,
} from "@/data-models/quantity-entity";
import { TimeEntity, TimeEntityModel } from "@/data-models/time-entity";
import { is } from "superstruct";
import { TiroElement } from "../../Base/TiroElement";
import { IEntityElement, STATES } from "./types";

export const EntityElement = {
  ...TiroElement,
  isEntityElement: (o: any): o is IEntityElement => {
    return (
      "entity" in o &&
      is(o.entity, EntityModel) &&
      EntityElement.isTiroElement(o)
    );
  },
  entityFromPlainObject(element: IEntityElement): IAbstractEntity {
    const { entity } = element;
    if (is(entity, ConceptEntityModel)) return new ConceptEntity(entity);
    if (is(entity, QuantityModel)) return createQuantityEntity(entity);
    if (is(entity, DateEntityModel)) return new DateEntity(entity);
    if (is(entity, DateTimeEntityModel)) return new DateTimeEntity(entity);
    if (is(entity, TimeEntityModel)) return new TimeEntity(entity);

    throw Error(
      "Element is not a valid EntityElement. Unable to deterime its entity type. (entity= " +
        JSON.stringify(entity) +
        " )"
    );
  },
  isCheckable(element: IEntityElement) {
    if (element.checked === true || element.checked === false) return true;
    if (element.active === true || element.active === false) return true;
    if (STATES.includes(element.state as any)) return true;
    return false;
  },
  isChecked(element: IEntityElement) {
    if (element.checked === true) return true;
    if (element.active === true) return true;
    if (element.state === "present") return true;
    return false;
  },
  text(element: IEntityElement) {
    return this.entityFromPlainObject(element).text;
  },
};
