import { DragOverlay } from "@dnd-kit/core";
import React from "react";
import { createPortal } from "react-dom";
import { RecursiveQuestionNode, WithKey } from "../models";
import { adjustTranslate, dropAnimationConfig } from "./config";
import { useSortableTreeContext } from "./SortableTreeContext";
import { Flattened } from "./types";
export interface SortableTreeDragOverlayChildrenProps<T> {
  activePathString: string;
  activeQuestion: Flattened<RecursiveQuestionNode<WithKey<T>>>;
}
export interface SortableTreeDragOverlayProps<T> {
  children: (props: SortableTreeDragOverlayChildrenProps<T>) => React.ReactNode;
}

function SortableTreeDragOverlay<T extends Record<string, any>>({
  children,
}: SortableTreeDragOverlayProps<T>) {
  const { activePathString, activeItem: activeQuestion } =
    useSortableTreeContext();
  return createPortal(
    <DragOverlay
      dropAnimation={dropAnimationConfig}
      modifiers={[adjustTranslate]}
    >
      {activePathString && activeQuestion
        ? children({
            activeQuestion: activeQuestion as Flattened<
              RecursiveQuestionNode<WithKey<T>>
            >,
            activePathString,
          })
        : null}
    </DragOverlay>,
    document.body
  );
}
export default SortableTreeDragOverlay;
