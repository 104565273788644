import { User } from "firebase/auth";
import { getTenantAuth } from "@/services/tenant";
import keyFactory from "./keyFactory";

export function getCurrentUser(): Promise<User | null> {
  const auth = getTenantAuth();
  return new Promise((resolve, reject) => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      unsubscribe();
      resolve(user);
    }, reject);
  });
}

export const currentUserQuery = {
  queryKey: keyFactory.currentUser(),
  queryFn: getCurrentUser,
  initialData: () => getTenantAuth().currentUser,
};
export type CurrentUserQuery = Pick<
  typeof currentUserQuery,
  "queryKey" | "queryFn"
>;
