import {
  DragDropContext,
  Draggable,
  DraggableProvided,
  Droppable,
  OnDragEndResponder,
} from "react-beautiful-dnd";
import classNames from "classnames";
import { useCallback } from "react";

export interface BlockListProps<TBlock extends { id: number | string }> {
  blocks: TBlock[];
  addItem?: JSX.Element | null;
  reorder: (props: { srcBlockId: TBlock["id"]; dstIndex: number }) => void;
  children: (props: {
    index: number;
    block: TBlock;
    provided: DraggableProvided;
  }) => JSX.Element;
}

function BlockList<TBlock extends { id: number | string }>({
  blocks,
  children,
  reorder,
  addItem,
}: BlockListProps<TBlock>) {
  const onDragEnd = useCallback<OnDragEndResponder>(
    (result) => {
      if (!result.destination) {
        return;
      }
      if (result.source.droppableId === "patient-file" && blocks) {
        reorder({
          srcBlockId: blocks[result.source.index].id,
          dstIndex: result.destination.index,
        });
      }
    },
    [reorder, blocks],
  );

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="patient-file">
        {(provided, snapshot) => (
          <div className="flex w-full flex-col ">
            <ul
              className={classNames({ "opacity-50": snapshot.isDraggingOver })}
              ref={provided.innerRef}
              {...provided.droppableProps}
            >
              {blocks.map((item: (typeof blocks)[number], index) => (
                <Draggable
                  draggableId={item.id.toString()}
                  index={index}
                  isDragDisabled={!item.id}
                  key={item.id}
                >
                  {(provided) => (
                    <li
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      className="relative"
                    >
                      {children({ index, block: item, provided })}
                    </li>
                  )}
                </Draggable>
              ))}
              {addItem && <li className="mb-20">{addItem}</li>}
              {provided.placeholder}
            </ul>
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
}

export default BlockList;
