import React from "react";
import { useSyncExternalStore } from "use-sync-external-store/shim";

const SemaphoreContext = React.createContext<{
  subscribe: (callback: () => void) => () => void;
  getSnapshot: () => string | null;
} | null>(null);

const SemphoreControlContext = React.createContext<{
  request: (key: string) => void;
  release: (key: string) => void;
} | null>(null);

export const SemaphoreProvider = ({
  children,
}: React.PropsWithChildren<{}>) => {
  const semaphore = React.useRef<string | null>(null);

  const listeners = React.useRef<Set<() => void>>(new Set());
  const unsubscribe = React.useCallback((callback: () => void) => {
    listeners.current.delete(callback);
  }, []);

  const subscribe = React.useCallback(
    (callback: () => void) => {
      listeners.current.add(callback);
      callback();
      return () => unsubscribe(callback);
    },
    [unsubscribe],
  );

  const notify = React.useCallback(() => {
    listeners.current.forEach((callback) => callback());
  }, []);

  const getSnapshot = React.useCallback(() => semaphore.current, []);

  const request = React.useCallback(
    (key: string) => {
      semaphore.current = key;
      notify();
    },
    [notify],
  );

  const release = React.useCallback(
    (key: string) => {
      if (semaphore.current === key) {
        semaphore.current = null;
        notify();
      }
    },
    [notify],
  );

  const controlContext = React.useMemo(
    () => ({
      request,
      release,
    }),
    [request, release],
  );

  const context = React.useMemo(
    () => ({
      subscribe,
      getSnapshot,
    }),
    [subscribe, getSnapshot],
  );

  return (
    <SemphoreControlContext.Provider value={controlContext}>
      <SemaphoreContext.Provider value={context}>
        {children}
      </SemaphoreContext.Provider>
    </SemphoreControlContext.Provider>
  );
};
interface UseSemaphoreOptions {
  failIfContextNotFound?: boolean;
}
const NOOP = () => {};
export const useSemaphore = (
  key: string,
  options: UseSemaphoreOptions = {},
) => {
  const { failIfContextNotFound = true } = options;
  let context = React.useContext(SemaphoreContext);
  if (!context) {
    if (failIfContextNotFound) throw new Error("No semaphore context found");
    context = {
      subscribe: () => NOOP,
      getSnapshot: () => null,
    };
  }
  const semaphore = useSyncExternalStore(
    context.subscribe,
    context.getSnapshot,
  );
  const { request: requestSemaphore = NOOP, release: releaseSemaphore = NOOP } =
    React.useContext(SemphoreControlContext) ?? {};
  const request = React.useCallback(
    () => requestSemaphore(key),
    [requestSemaphore, key],
  );
  const release = React.useCallback(
    () => releaseSemaphore(key),
    [releaseSemaphore, key],
  );
  return { hasSemaphore: semaphore == key, request, release };
};
