import classNames from "classnames";
import { Drag } from "@/Icons";
import React, { HTMLAttributes } from "react";

export interface BlockDragHandleProps
  extends HTMLAttributes<HTMLButtonElement> {}
const BlockDragHandle = ({ ...attributes }: BlockDragHandleProps) => {
  return (
    <button
      {...attributes}
      className={classNames(
        "inline-flex h-full cursor-move items-center justify-start text-gray-light hover:text-primary focus:outline-none",
      )}
    >
      <Drag className="h-[18px] w-[18px] fill-current" />
    </button>
  );
};

export default BlockDragHandle;
