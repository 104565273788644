import React from "react";
import { Spinner } from "@/Icons";
import { ExclamationCircleIcon } from "@heroicons/react/24/outline";

function EmptySuggestionItem({
  status,
}: {
  status: "loading" | "idle" | "success" | "error";
}) {
  switch (status) {
    case "loading":
      return (
        <li className="relative w-32 select-none bg-white text-gray-900 hover:bg-gray-ultralight">
          <span className="flex h-full w-full whitespace-nowrap py-2 pl-3 pr-3 text-left focus:outline-none focus:ring-0">
            <span className="flex-1">Laden</span>
            <Spinner className="h-5 w-5 animate-spin" />
          </span>
        </li>
      );
    case "idle":
    case "success":
      return null;
    case "error":
      return (
        <li className="relative w-32 select-none bg-white text-gray-400 hover:bg-gray-ultralight">
          <span className="flex h-full w-full whitespace-nowrap py-2 pl-3 pr-3 text-left focus:outline-none focus:ring-0">
            <span className="flex-1">
              Er ging iets fout tijdens het ophalen van suggesties.
            </span>
            <ExclamationCircleIcon className="h-5 w-5" />
          </span>
        </li>
      );
  }
}

export default EmptySuggestionItem;
