/**
 * This component is a workaround for a bug in Chromium-based browsers where empty contentEditable elements are not rendered.
 * @returns
 */
export default function InlineChromiumBugfix() {
  return (
    <span contentEditable={false} style={{ fontSize: 0 }}>
      ${String.fromCodePoint(160) /* Non-breaking space */}
    </span>
  );
}
