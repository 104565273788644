import classNames from "classnames";
import { IEditorPlugin, RenderTiroElementProps } from "../Base/types";
import useFormattingClassnames from "./useFormattingClassnames";

const RenderFormattedElement = ({
  element,
  attributes,
  children,
}: RenderTiroElementProps) => {
  const classes = useFormattingClassnames(element);
  return (
    <div {...attributes} className={classNames(classes)}>
      {children}
    </div>
  );
};

const BasicFormattingPlugin: IEditorPlugin = {
  renderElement: RenderFormattedElement,
};

export default BasicFormattingPlugin;
