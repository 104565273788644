import { useEffect, useState } from "react";
import {
  $getSelection,
  $isRangeSelection,
  COMMAND_PRIORITY_CRITICAL,
  LexicalEditor,
  SELECTION_CHANGE_COMMAND,
} from "lexical";
import { mergeRegister } from "@lexical/utils";
import { getSelectedNode } from "../utils/getSelectionNode";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { $isMentionNode, MentionNode } from "../MentionPlugin/MentionNode";
import { createPortal } from "react-dom";
import { Transition } from "@headlessui/react";
import { flip, offset, useFloating } from "@floating-ui/react";
import useEditorFocus from "../utils/useEditorFocus";

function useFloatingMentionTooltip(
  editor: LexicalEditor,
  anchorElem: HTMLElement,
  renderEntity?: (id: string) => JSX.Element | null,
): JSX.Element | null {
  const [hasFocus, ref] = useEditorFocus();
  const [mentionNode, setMentionNode] = useState<MentionNode | null>(null);

  useEffect(() => {
    function updateToolbar() {
      const selection = $getSelection();
      if ($isRangeSelection(selection)) {
        const node = getSelectedNode(selection);
        const mentionNode = $isMentionNode(node) ? node : null;
        setMentionNode(mentionNode);
      }
    }
    return mergeRegister(
      editor.registerUpdateListener(({ editorState }) => {
        editorState.read(() => {
          updateToolbar();
        });
      }),
      editor.registerCommand(
        SELECTION_CHANGE_COMMAND,
        () => {
          updateToolbar();
          return false;
        },
        COMMAND_PRIORITY_CRITICAL,
      ),
    );
  }, [editor]);

  // use the mention node to get the DOM element and the corresponding form value
  const reference =
    mentionNode && editor.getElementByKey(mentionNode?.getKey());
  const id = mentionNode?.getId() ?? null;

  // position the floating card
  const { refs, floatingStyles } = useFloating({
    elements: { reference, floating: ref.current },
    placement: "top-start",
    middleware: [offset({ crossAxis: -8, mainAxis: 4 }), flip()],
  });
  const show = !!id && !!renderEntity && hasFocus;
  return createPortal(
    <Transition
      as="div"
      ref={refs.setFloating}
      show={show}
      style={floatingStyles}
    >
      {show ? renderEntity(id) : null}
    </Transition>,
    anchorElem,
  );
}

function FloatingMentionTooltipPlugin({
  anchorElem = document.body,
  renderEntity,
}: {
  anchorElem?: HTMLElement;
  renderEntity?: (id: string) => JSX.Element | null;
}) {
  const [editor] = useLexicalComposerContext();
  return useFloatingMentionTooltip(editor, anchorElem, renderEntity);
}

export default FloatingMentionTooltipPlugin;
