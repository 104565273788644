import classNames from "classnames";
import React, { useState, HTMLAttributes, useReducer } from "react";
import { Gutter } from "./Gutter";
import QAItem from "./QAItem";
import { QAAction, IQuestionAnswer } from "./types";
import { useElementHeight } from "./useElementHeight";
import { useQuestionnaireReducer } from "./createReducer";

const DEFAULT_QA_ITEMS: IQuestionAnswer[] = [];

interface UseMaybeControlledReducer {
  initialQuestions?: IQuestionAnswer[];
  questions?: IQuestionAnswer[];
  dispatch?: React.Dispatch<QAAction>;
}
function useMaybeControlledReducer({
  questions: providedQuestions,
  initialQuestions,
  dispatch: providedDispatch,
}: UseMaybeControlledReducer = {}) {
  const reducer = useQuestionnaireReducer();
  const [localQuestions, localDispatch] = useReducer(
    reducer,
    initialQuestions ?? DEFAULT_QA_ITEMS
  );
  // Switch between external controlled or local controlled states
  const questions = providedQuestions ?? localQuestions;
  const dispatch = providedDispatch ?? localDispatch;
  return [questions, dispatch] as const;
}

export interface QAFormProps
  extends HTMLAttributes<HTMLFormElement>,
    UseMaybeControlledReducer {
  title?: string;
  description?: string;
  onSplit?: (index: number) => void;
  gridTemplateColumns?: string;
}

function QuestionAnswerForm({
  title,
  description,
  questions: providedQuestions,
  initialQuestions,
  dispatch: providedDispatch,
  className,
  onSplit: handleSplit,
  gridTemplateColumns: providedGridTemplateColumns = "60px 50px 200px 1fr",
  ...props
}: QAFormProps) {
  const [height, updateElementHeight] = useElementHeight();

  const [gridTemplateColumns, setGridTemplateColumns] = useState(
    providedGridTemplateColumns
  );

  const [questions, dispatch] = useMaybeControlledReducer({
    questions: providedQuestions,
    dispatch: providedDispatch,
    initialQuestions,
  });

  return (
    <form className={classNames("bg-white", className)} {...props}>
      <div className="divide-y divide-gray-200">
        {(title || description) && (
          <div>
            <h3 className="text-lg font-medium leading-6 text-gray-900">
              {title}
            </h3>
            <p className="mt-1 max-w-2xl text-sm text-gray-primary">
              {description}
            </p>
          </div>
        )}
        <div
          key={questions.length} // this triggers a remount when then number questions and hence will retrigger the ref callback
          ref={updateElementHeight}
          className={classNames("relative mt-5 w-full", {
            "py-4": !title && !description,
            "pb-4": title || description,
          })}
        >
          <Gutter
            gridTemplateColumns={gridTemplateColumns}
            onGridTemplateColumns={setGridTemplateColumns}
            height={height}
          />
          <dl>
            {questions.map((childQuestion, index) => (
              <QAItem
                dispatch={dispatch}
                key={childQuestion.code.text}
                question={childQuestion}
                onSplit={
                  !!handleSplit && index > 0
                    ? () => handleSplit(index)
                    : undefined
                }
                gridTemplateColumns={gridTemplateColumns}
                path={[index]}
              />
            ))}
          </dl>
        </div>
      </div>
    </form>
  );
}

export default QuestionAnswerForm;
