import { useCallback, useState } from "react";
import { assert } from "superstruct";
import { DraggableProvidedDragHandleProps } from "react-beautiful-dnd";
import BlockErrorBoundary from "@/components/BlockErrorBoundary";
import BlockMenu from "./common/Menu";
import BlockContainer from "./common/Container";
import RenderTemplateBlockContent from "./content/RenderTemplateBlockContent";
import { AnyRenderTypeModel } from "./content";
import useContentFocus from "./useContentFocus";
import BlockLoadingSkeleton from "./BlockLoadingSkeleton";
import {
  useTemplateBlock,
  useTemplateBlockMutator,
} from "@/services/content/useTemplateBlock";
import { CurrentTemplateBlockIdContext } from "@/hooks/useCurrentTemplateBlockId";
import { LegacyUpdateTemplateBlock } from "@/services/content/content-client";
import BlockDragHandle from "./common/DragHandle";
import RemoveReportButton from "@/components/questionnaire-v2/QuestionAnswer/RemoveButton";
import BlockTitleInput from "./common/TitleInput";
import { ArrowUpTrayIcon, ClipboardIcon } from "@heroicons/react/24/outline";
import { useTranslation } from "react-i18next";
import { Portal } from "../base/Portal";
import { autoPlacement, useFloating } from "@floating-ui/react";
import useCurrentUserData from "@/services/reports/useUserData";
import format from "date-fns/format";

export interface RenderTemplateBlockProps {
  id: string;
  readOnly?: boolean;
  dragHandleProps?: DraggableProvidedDragHandleProps;
  insert: (block: LegacyUpdateTemplateBlock) => void;
  remove: () => void;
}

function RenderTemplateBlock({
  id,
  readOnly,
  remove,
  dragHandleProps,
}: RenderTemplateBlockProps) {
  const [ref, focus] = useContentFocus();
  const { data: block } = useTemplateBlock(id);
  const { mutate: update } = useTemplateBlockMutator(id);
  const handleTitleChange = useCallback(
    (title: string) => {
      update({ title });
      focus();
    },
    [update, focus],
  );
  return (
    <CurrentTemplateBlockIdContext.Provider value={id}>
      <BlockContainer.Card id={`template-block-container-${id}`}>
        <BlockContainer.Toolbar>
          <BlockDragHandle {...dragHandleProps} />
        </BlockContainer.Toolbar>
        <BlockLoadingSkeleton block={block}>
          {({ block }) => {
            const { renderType, blockType, title } = block;
            assert(renderType, AnyRenderTypeModel);
            return (
              <>
                <BlockContainer.Header>
                  <BlockTitleInput
                    title={title ?? ""}
                    onTitleChange={handleTitleChange}
                    blockType={blockType}
                  />
                  {/*toevoegen:  "undo" knop */}
                  <RemoveReportButton
                    onClick={() => remove()}
                    disabled={!remove}
                  />
                  <RenderTemplateBlockMenu identifier="" />
                </BlockContainer.Header>
                <BlockContainer.Body>
                  <BlockErrorBoundary blockId={id}>
                    <RenderTemplateBlockContent
                      ref={ref}
                      templateBlockId={id}
                      renderType={renderType}
                      readOnly={readOnly}
                    />
                  </BlockErrorBoundary>
                </BlockContainer.Body>
              </>
            );
          }}
        </BlockLoadingSkeleton>
      </BlockContainer.Card>
    </CurrentTemplateBlockIdContext.Provider>
  );
}

export default RenderTemplateBlock;

export function RenderTemplateBlockMenu({
  identifier,
}: {
  identifier: string;
}) {
  const { t } = useTranslation();
  const { refs, floatingStyles } = useFloating({
    middleware: [autoPlacement()],
  });
  const { data: user } = useCurrentUserData();
  return (
    <BlockMenu.Wrapper>
      <BlockMenu.Button ref={refs.setReference} />
      <Portal>
        <BlockMenu.Items
          ref={refs.setFloating}
          className="absolute right-0 z-20 w-max"
          style={floatingStyles}
        >
          <div className="block min-w-[200px] border-t border-gray-ultralight px-3 py-3 text-xs text-gray-900">
            <h6 className="font-medium uppercase leading-3 text-gray-primary/60">
              Last Modified
            </h6>
            <p className="mt-2">
              <span className="cursor-pointer whitespace-nowrap underline-offset-1 hover:text-blue-400 hover:underline hover:decoration-blue-400">
                {user?.firstName + " " + user?.lastName}
              </span>
              <span> on </span>
              <p className="cursor-pointer whitespace-nowrap underline-offset-1 hover:text-blue-400 hover:underline hover:decoration-blue-400">
                {format(new Date(), "PPPpp")}
                <span>.</span>
              </p>
            </p>
          </div>
          <div className="block min-w-[200px] border-y border-gray-ultralight px-3 py-3 text-xs text-gray-900">
            <h6 className="font-medium uppercase leading-3 text-gray-primary/60">
              Technical Details
            </h6>
            <div className="mt-2 flex gap-1">
              <span className="w-14 text-gray-primary">Identifier</span>
              <code>{identifier}</code>
            </div>
            <div className="flex gap-1">
              <span className="w-14 text-gray-primary">Version</span>
              <code>0.0.1</code>
            </div>
          </div>
          <BlockMenu.ButtonItem
            onClick={() =>
              navigator.clipboard.writeText(identifier).catch(console.error)
            }
          >
            <ClipboardIcon className="inline h-4 w-4 stroke-current" />
            <span className="ml-2">Copy ID</span>
            <code className="ml-2 rounded-sm bg-gray-100 px-1 py-0.5 font-mono text-xs text-gray-900">
              {identifier}
            </code>
          </BlockMenu.ButtonItem>
          <BlockMenu.ButtonItem>
            <ArrowUpTrayIcon className="inline h-4 w-4 stroke-current" />
            <span className="ml-2">{t("exporteren-naar-json")}</span>
          </BlockMenu.ButtonItem>
        </BlockMenu.Items>
      </Portal>
    </BlockMenu.Wrapper>
  );
}
