import schema from "./ask-user-data/schema";

export default function isUserDataComplete(
  user: Partial<Record<string, unknown>> | false
): boolean {
  if (!user) return false;
  if (user.is_valid === false) return false;
  const parsed = schema.safeParse(user);
  if (!parsed.success) console.debug("isUserDataComplete", parsed.error);
  return parsed.success;
}
