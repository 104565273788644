import { MenuOption } from "@lexical/react/LexicalTypeaheadMenuPlugin";
import classNames from "classnames";
import { IDesignation } from "@/components/base/CodingCard/models";
import RenderCodingCard from "@/components/base/CodingCard/RenderCodingCard";
import { IHumanReadableCoding } from "@/data-models/value-models/types";
import {
  ExpansionContains,
  Generated,
  SemanticAxis,
} from "@/services/content/content-client";

export class MentionTypeaheadOption extends MenuOption {
  display: string;
  code: string;
  system: string;
  designation?: IDesignation;
  generated?: boolean;
  semantic?: IHumanReadableCoding;
  property: ExpansionContains["property"];

  constructor(
    code: string,
    system: string,
    display: string,
    semantic?: IHumanReadableCoding,
    generated?: boolean
  ) {
    super(`${system}|${code}`);
    this.code = code;
    this.display = display;
    this.system = system;
    this.semantic = semantic;
    this.generated = generated;
  }

  static fromExpansion(coding: ExpansionContains) {
    return new MentionTypeaheadOption(
      coding.code,
      coding.system!,
      coding.display,
      coding.property?.find(
        (p): p is SemanticAxis => p.code === "semantic_axis"
      )?.valueCoding,
      coding.property?.find(
        (p): p is Generated => p.code === "generated"
      )?.valueBoolean
    );
  }
}
export function MentionsTypeaheadMenuItem({
  index,
  isSelected,
  onClick,
  onMouseEnter,
  option: {
    code,
    system,
    display,
    designation,
    generated,
    semantic,
    key,
    setRefElement,
  },
}: {
  index: number;
  isSelected: boolean;
  onClick: () => void;
  onMouseEnter: () => void;
  option: MentionTypeaheadOption;
}) {
  return (
    <li
      key={key}
      tabIndex={-1}
      className={classNames("item cursor-pointer whitespace-nowrap", {
        "selected bg-gray-100": isSelected,
      })}
      ref={setRefElement}
      role="option"
      aria-selected={isSelected}
      id={"typeahead-item-" + index}
      onMouseEnter={onMouseEnter}
      onClick={onClick}
    >
      <RenderCodingCard
        code={code}
        display={display}
        system={system}
        designation={designation}
        minimal
        small
        active={isSelected}
        generated={generated}
        semantic={semantic}
      />
    </li>
  );
}
