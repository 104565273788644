import { Coding } from "@/data-models/value-models";
import { CodeableConceptModel } from "@/data-models/value-models/structs";
import { FieldValues } from "react-hook-form";
import { is, tuple, type } from "superstruct";
import { ILegacyTextAnswer } from "../Answer/models";

export function findAllCodesInAnswer(answer: ILegacyTextAnswer): Coding[] {
  return answer.reduce((acc, { children }) => {
    const codes = children.reduce((acc, { entity }) => {
      if (entity) {
        acc.push(...entity.coding);
      }
      return acc;
    }, [] as Coding[]);
    return [...acc, ...codes].map(Coding.fromObj);
  }, [] as Coding[]);
}

export function findAllCodesInValues(values: FieldValues): Coding[] {
  return Object.entries(values)
    .map((kv) => kv[1])
    .reduce((acc, value) => {
      if (value && is(value, CodeableConceptModel)) {
        acc.push(...value.coding);
        return acc;
      }
      if (
        value &&
        is(
          value,
          type({ code: CodeableConceptModel, value: CodeableConceptModel })
        )
      ) {
        acc.push(...value.value.coding);
        return acc;
      }
      return acc;
    }, [] as Coding[])
    .map(Coding.fromObj);
}
