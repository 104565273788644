import { FirebaseError } from "firebase/app";
import Panel from "../Panel";
import PasswordInput from "@/components/PasswordInput/PasswordInput";
import TDForm from "@/components/forms/TopDownForm";
import { useTranslation } from "react-i18next";
import { Form, useActionData, useNavigation } from "react-router-dom";
import { ActionData } from "./createAction";
import { AuthErrorCodes } from "firebase/auth";

type ErrorFieldType = "email" | "password" | "general";
function useErrorMessage({
  error,
  type,
}: {
  error?: FirebaseError | undefined;
  type?: string | undefined;
}): [string | undefined, ErrorFieldType | undefined] {
  const { t } = useTranslation();
  if (type !== "email/pass") return [undefined, undefined];
  switch (error?.code) {
    case AuthErrorCodes.INVALID_PASSWORD:
      return [t("je-wachtwoord-klopt-niet")!, "password"];
    case "auth/user-not-found":
      return [t("je-email-adres-herkennen-we-niet")!, "email"];
    case "auth/account-exists-with-different-credential":
      return [t("je-ziekenhuis-gebruikt-een-andere-login-methode")!, "general"];
    default:
      return error ? [error?.message, "general"] : [undefined, undefined];
  }
}

function EmailPassLoginPanel(): JSX.Element {
  const { t } = useTranslation();
  const actionData = useActionData() as ActionData | undefined;
  const { state, formData } = useNavigation();
  const isSubmitting =
    state === "submitting" && formData?.get("type") === "email/pass";
  const [errorMessage, errorType] = useErrorMessage(actionData ?? {});
  return (
    <Panel>
      <Form className="space-y-6" method="POST">
        {errorMessage && (
          <div className="text-red-600 sm:text-sm">{errorMessage ?? ""}</div>
        )}
        <input type="hidden" name="type" value="email/pass" readOnly />
        <TDForm.Field
          label={t("email")!}
          id="email"
          name="email"
          type="email"
          autoComplete="email"
          placeholder={t("email")!}
          error={errorType == "email"}
          required
        />
        <TDForm.Field
          InputComponent={PasswordInput}
          label={t("wachtwoord")!}
          id="password"
          name="password"
          required
          autoComplete="current-password"
          extraText={
            <a
              href="./reset-password"
              className="text-sm font-medium text-blue-primary hover:text-blue-500"
            >
              {t("wachtwoord-vergeten")}
            </a>
          }
          error={errorType == "password"}
          placeholder={t("wachtwoord")!}
        />

        <div className="flex items-center">
          <input
            id="email-stay-logged-in"
            type="checkbox"
            name="stay-logged-in"
            className="h-4 w-4 rounded border-gray-light text-blue-primary focus:ring-blue-500"
          />
          <label
            htmlFor="email-stay-logged-in"
            className="ml-2 block text-sm text-gray-700"
          >
            {t("blijf-ingelogd-op-deze-computer")}
          </label>
        </div>
        <TDForm.Submit
          isSubmitting={isSubmitting}
          extraText={
            <a
              href="./sign-up"
              className="text-sm font-medium text-blue-primary hover:text-blue-500"
            >
              {t("nog-geen-account-maak-er-hier-een-aan")}
            </a>
          }
        >
          {t("log-in")}
        </TDForm.Submit>
      </Form>
    </Panel>
  );
}

export default EmailPassLoginPanel;
