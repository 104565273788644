import classNames from "classnames";
import SubjectBadge from "@/components/controlled/SubjectBadge";
import ExportButtonGroup from "@/components/ReportActionButton/ExportButtonGroup";
import useLaunchedFrom from "@/hooks/useLaunchedFrom";
import { Nav } from "@/components/navbar/NavBar";
import { ProfileMenu } from "@/components/navbar/ProfileMenu";
import { Outlet, Params, useMatch, useMatches } from "react-router";
import { LinkProps } from "react-router-dom";
import { z } from "zod";
import { REPORT_PATH } from "./reports/const";

type BackLinkProps = Pick<LinkProps, "to" | "children">;
type GetBackLinkProps = (params: Params<string>) => BackLinkProps;
type MatchWithMaybeHandle = {
  handle?: { getBackLinkProps?: GetBackLinkProps };
  params: Params<string>;
};
type MatchWithHandle = {
  handle: { getBackLinkProps: GetBackLinkProps };
  params: Params<string>;
};
function useBackLink(): BackLinkProps | null {
  const matches = useMatches() as MatchWithMaybeHandle[];

  const lastMatch = matches
    .reverse()
    .find(
      (match): match is MatchWithHandle => !!match.handle?.getBackLinkProps,
    );
  if (!lastMatch) return null;
  const { handle, params } = lastMatch;
  return handle.getBackLinkProps(params);
}

function useIsReport() {
  const match = useMatch({ path: REPORT_PATH, end: false });
  const parseResult = z.coerce.number().safeParse(match?.params?.reportId);
  return parseResult.success;
}

function Layout() {
  const hideNavBar = useLaunchedFrom("Synops");
  const backLinkProps = useBackLink();
  const isReport = useIsReport();

  return (
    <>
      {!hideNavBar && (
        <Nav className="sticky top-0 z-10">
          <Nav.Left>
            <Nav.Logo />
            {backLinkProps && <Nav.BackLink {...backLinkProps} />}
          </Nav.Left>
          <Nav.Center>
            {isReport && (
              <SubjectBadge className="m-2 w-fit justify-self-center" />
            )}
          </Nav.Center>
          <Nav.Right>
            {isReport && <ExportButtonGroup />}
            <div className="mr-auto" />
            <Nav.ProfileSlot>
              <ProfileMenu />
            </Nav.ProfileSlot>
          </Nav.Right>
        </Nav>
      )}
      <div
        className={classNames("absolute", {
          "bottom-0 top-14 w-full": !hideNavBar,
          "inset-0": hideNavBar,
        })}
      >
        <Outlet />
      </div>
    </>
  );
}

export default Layout;
