import { IfhirR4, fhirR4 } from "@smile-cdr/fhirts";
import Tippy from "@tippyjs/react";
import fhirpath from "fhirpath";
import Underlined from "@/components/base/Underlined";
import useFHIRSearch, { FHIRSearchTarget } from "@/hooks/useFHIRSearch";
import { Spinner } from "@/Icons";
import { IMergeTag } from "./types";
import { PatientOut } from "@/services/reports/report-client";
import React, { MouseEventHandler } from "react";
import classNames from "classnames";
import { useCurrentSubject } from "@/pages/patients/createCurrentSubjectLoader";

export interface MergeTagProps extends React.HTMLAttributes<HTMLSpanElement> {
  mergeTag: IMergeTag;
  onResolve: (mergeTag: IMergeTag) => void;
}

function createObservationReference(observation: IfhirR4.IObservation) {
  let display = observation.code?.text;
  if (!observation.effectiveDateTime)
    display += `op ${observation.effectiveDateTime}`;
  if (observation.device?.display)
    display += ` – ${observation.device.display}`;
  return {
    reference: `Observation/${observation.id}`,
    display,
  };
}

function createPatientReference(patient: IfhirR4.IPatient) {
  const displayName =
    patient.name?.[0].text ??
    patient.name?.[0].given?.join(" ") + " " + patient.name?.[0].family;
  return {
    reference: `Patient/${patient.id}`,
    display: `Patient - ${displayName}`,
  };
}

function createReference(resource: fhirR4.Resource) {
  switch (resource.resourceType) {
    case "Observation":
      return createObservationReference(resource as IfhirR4.IObservation);
    case "Patient":
      return createPatientReference(resource as IfhirR4.IPatient);
    default:
      if (!("id" in resource)) throw new Error("Invalid resource");
      return {
        reference: `${resource.resourceType}/${resource.id}`,
      };
  }
}

function replaceVariablesInQuery(
  search: FHIRSearchTarget,
  subject: PatientOut,
) {
  return {
    ...search,
    query: Object.fromEntries(
      Object.entries(search.query).map(([key, value]) => [
        key,
        value.replace(/{{subject}}/g, subject?.id.toString() ?? ""),
      ]),
    ),
  };
}
export function MergeTag({
  mergeTag,
  onResolve,
  className,
  ...props
}: MergeTagProps) {
  const subject = useCurrentSubject();
  const { search, name, path, reference, text } = mergeTag;
  const hasResolved = !!reference;
  const { isFetching, refetch } = useFHIRSearch<fhirR4.Resource | undefined>(
    replaceVariablesInQuery(search, subject),
    {
      select: (bundle) => bundle.entry?.[0].resource,
      staleTime: hasResolved ? Infinity : 0,
      onSettled: (resource) => {
        console.log("settled", resource);
      },
      onSuccess: (resource) => {
        resource &&
          onResolve({
            ...mergeTag,
            reference: createReference(resource),
            text: fhirpath
              .evaluate(resource, path)
              .map((t) => t.toString())
              .join(""),
          });
      },
    },
  );
  const handleClick: MouseEventHandler = (event) => {
    event.preventDefault();
    refetch();
  };
  if (!hasResolved && isFetching)
    return (
      <Tippy content={`We halen de data voor deze merge tag op.`}>
        <Underlined inActive>
          {name} <Spinner className="inline h-4 w-4 animate-spin" />
        </Underlined>
      </Tippy>
    );
  if (!hasResolved)
    return (
      <Tippy
        content={`Er ging iets mis tijdens het vervangen van deze merge tag.`}
      >
        <Underlined inActive>
          {name}{" "}
          <span className="material-symbols-outlined text-sm">warning</span>
        </Underlined>
      </Tippy>
    );
  return (
    <span
      className={classNames("inline-flex align-bottom", className)}
      {...props}
    >
      <Tippy content={reference?.display}>
        <Underlined {...props}>{text}</Underlined>
      </Tippy>
      {reference && (
        <button
          type="button"
          tabIndex={-1}
          className="border-gray material-icons m-1 rounded-md border text-base leading-none hover:border-blue-400 hover:text-blue-primary"
          onClick={handleClick}
        >
          cached
        </button>
      )}
    </span>
  );
}

export default MergeTag;
