import { RenderLeafProps } from "slate-react";
import { IEditorPlugin } from "../../Base/types";
import RenderSuggestLeaf, { RenderSuggestLeafProps } from "./RenderSuggestLeaf";

export const SuggestPlugin: IEditorPlugin = {
  renderLeaf: (props: RenderLeafProps) => {
    const { leaf } = props;
    if ("highlight" in leaf) {
      return <RenderSuggestLeaf {...(props as RenderSuggestLeafProps)} />;
    }
  },
};

export default SuggestPlugin;
