import { Text } from "slate";
import { TiroElement } from "../../Base/TiroElement";
import { ITiroElement } from "../../Base/types";
import { TITLE_ELEMENT } from "./consts";

export interface ITitleElement extends ITiroElement {
  type: typeof TITLE_ELEMENT;
  children: [Text];
}

const TitleElement = {
  ...TiroElement,
  isTitleElement(o: unknown): o is ITitleElement {
    return TiroElement.isTiroElement(o) && o.type === TITLE_ELEMENT;
  },
};
export default TitleElement;
