import classNames from "classnames";
import React, { useMemo } from "react";
import { Editor, Range } from "slate";
import {
  ReactEditor,
  RenderElementProps,
  useFocused,
  useSelected,
  useSlateStatic,
} from "slate-react";
import { useLevel } from "../../Base/useLevel";
import { EntityContextWrapper } from "../Entities/ContextWrapper";
import SelectHandle from "./SelectHandle";
import { SubsectionElement } from "./types";

export interface RenderSubsectionElementProps extends RenderElementProps {
  element: SubsectionElement;
}
export const RenderSubsectionElement = ({
  element,
  attributes,
  children,
}: RenderSubsectionElementProps) => {
  const state = element.children[0].state ?? "present";
  const childrenArray = React.Children.toArray(children);
  const level = useLevel(element);
  const selected = useSelected();
  const focused = useFocused();
  const editor = useSlateStatic() as ReactEditor;

  const { selection } = editor;
  const shouldHighlight = useMemo(() => {
    const path = ReactEditor.findPath(editor, element);
    const range = Editor.range(editor, path);
    const intersection = selection && Range.intersection(range, selection);
    return (
      selected && focused && intersection && Range.equals(intersection, range)
    );
  }, [element, editor, selected, focused, selection]);

  if (childrenArray.length == 0) {
    throw Error("Invalid document state. Subsection can't rendered if empty.");
  }
  return (
    <EntityContextWrapper.Provider value={state}>
      <div
        {...attributes}
        className={classNames(
          "current-entry pl-8",
          { "-ml-8 -mr-10 pl-6": level === 1 },
          { "rounded-sm ring ring-blue-200": shouldHighlight }
        )}
      >
        <div className="-ml-8 mb-1 flex">
          {level === 1 && <SelectHandle element={element} />}
          {childrenArray[0]}
        </div>
        <div
          className={classNames("space-y-1", { hidden: state === "absent" })}
        >
          {childrenArray.slice(1)}
        </div>
        {state == "absent" && (
          <div
            className={classNames(
              "invisible relative -mt-2 ml-3 select-none px-px current-block-focus-within:visible current-block-hover:visible"
            )}
            contentEditable={false}
          >
            <span className="px-2 text-xs font-normal italic">
              {childrenArray.length - 1} rijen ingeklapt
            </span>
          </div>
        )}
      </div>
    </EntityContextWrapper.Provider>
  );
};
