import Chip, { ChipProps } from "@/components/base/Chip";
import { ICoding } from "@/data-models/value-models";
import React from "react";
import { useController } from "react-hook-form";

export interface ChipInputProps<
  TChipInputValue,
  TChipOuputValue = TChipInputValue,
> extends Omit<
    ChipProps,
    | "ref"
    | "type"
    | "onClick"
    | "onBlur"
    | "value"
    | "children"
    | "defaultValue"
  > {
  name: string;
  value: TChipInputValue;
  defaultValue?: TChipInputValue | null;
  equals: (a: TChipInputValue, b: TChipInputValue) => boolean;
  getDisplay: (value: TChipInputValue) => string;
  transform?: {
    input: (value: TChipInputValue) => TChipOuputValue;
    output: (value: TChipOuputValue) => TChipInputValue;
  };
}

export const ChipInput = <
  TChipInputValue extends {} = ICoding,
  TChipOuputValue = TChipInputValue,
>(
  {
    name,
    value: providedValue,
    defaultValue,
    equals,
    getDisplay,
    transform,
    ...props
  }: ChipInputProps<TChipInputValue, TChipOuputValue>,
  ref?: React.Ref<HTMLButtonElement>,
) => {
  const {
    field: { onChange, value, ref: fieldRef, ...fieldProps },
  } = useController({ name, defaultValue });
  const checked =
    !!value && equals(transform?.output(value) ?? value, providedValue);
  const handleClick = () => {
    onChange(
      checked ? false : transform?.input(providedValue) ?? providedValue,
    );
  };
  return (
    <Chip
      ref={ref}
      checked={checked}
      onClick={handleClick}
      {...fieldProps}
      {...props}
    >
      {getDisplay(providedValue)}
    </Chip>
  );
};
export default ChipInput;
