/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { Address } from './Address';
import type { Attachment_Input } from './Attachment_Input';
import type { ContactPoint } from './ContactPoint';
import type { Meta } from './Meta';
import type { models__api__fhir_r5__base__Identifier_Input } from './models__api__fhir_r5__base__Identifier_Input';
import type { Narrative } from './Narrative';
import type { PractitionerCommunication_Input } from './PractitionerCommunication_Input';
import type { PractitionerQualification_Input } from './PractitionerQualification_Input';

export type FHIRPractitionerIn = {
    resourceType?: FHIRPractitionerIn.resourceType;
    id?: (string | null);
    meta?: (Meta | null);
    text?: (Narrative | null);
    name: any[];
    photo?: (Array<Attachment_Input> | null);
    active?: (boolean | null);
    gender?: (string | null);
    telecom?: (Array<ContactPoint> | null);
    address?: (Array<Address> | null);
    language?: (string | null);
    birthDate?: (string | null);
    identifier?: Array<models__api__fhir_r5__base__Identifier_Input>;
    deceasedBoolean?: (boolean | null);
    deceasedDateTime?: (string | null);
    implicitRules?: (string | null);
    qualification?: (Array<PractitionerQualification_Input> | null);
    communication?: (Array<PractitionerCommunication_Input> | null);
};

export namespace FHIRPractitionerIn {

    export enum resourceType {
        PRACTITIONER = 'Practitioner',
    }


}

