import React, { useContext } from "react";
import classNames from "classnames";
import { Combobox } from "@headlessui/react";
import { is } from "superstruct";
import { FloatingPortal } from "@floating-ui/react";
import { useController } from "react-hook-form";
import { CodingModel } from "@/data-models/value-models/structs";
import useCodeCombobox from "@/components/base/CodeCombobox/useCodeCombobox";
import Button from "@/components/base/CodeCombobox/Button";
import { IHumanReadableCoding } from "@/data-models/value-models/types";
import CodeComboboxResults, {
  CodingResultsContainerRefContext,
} from "@/components/base/CodeCombobox/CodeComboboxResults";
import { DefinedValueSet } from "@/services/content/content-client";

export type CodingComboboxProps = {
  name: string;
  className?: string;
  defaultCode?: IHumanReadableCoding | null;
  placeholder?: string;
  size?: number;
  count?: number;
  active?: boolean;
  disabled?: boolean;
  disableWhenQueryIsEmpty?: boolean;
  valueSet: string | DefinedValueSet;
};

function CodingComboboxInput({
  valueSet,
  name,
  defaultCode = null,
  disableWhenQueryIsEmpty,
  disabled,
  size = 100,
  placeholder,
  count,
  active,
  className,
  ...props
}: CodingComboboxProps) {
  const buttonRef = React.useRef<HTMLButtonElement>(null);
  const containerRef = useContext(CodingResultsContainerRefContext);
  const {
    isOpen,
    filteredCodes,
    handleQueryChange,
    by,
    displayValue,
    handleCodeChange,
    referenceProps,
    floatingProps,
    fetchStatus,
    helpers,
  } = useCodeCombobox(valueSet, {
    count,
    allowedPlacements: ["bottom-start", "bottom-end"],
    disableWhenQueryIsEmpty,
  });
  const { field } = useController({
    name,
    defaultValue: defaultCode,
    rules: { validate: (value) => is(value, CodingModel) },
  });

  return (
    <Combobox
      as="div"
      className={classNames(
        "inline-flex max-w-md items-baseline justify-between",
        className,
      )}
      value={field.value as IHumanReadableCoding | null}
      onChange={handleCodeChange(field.onChange)}
      by={by}
      disabled={disabled}
      {...props}
      nullable
    >
      {({ open }) => (
        <div
          {...referenceProps}
          className={classNames("relative grow rounded-md shadow-sm", {
            "ring-2 ring-blue-600 ring-offset-1": active,
          })}
        >
          <Combobox.Input
            autoComplete="off"
            className="w-full rounded-md border-0 bg-white py-1.5 pl-3 pr-8 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-blue-600 disabled:cursor-not-allowed disabled:bg-gray-100 disabled:text-gray-500 sm:text-sm sm:leading-6"
            onClick={() => buttonRef.current?.click()}
            type="text"
            placeholder={placeholder}
            size={size}
            onChange={handleQueryChange}
            displayValue={displayValue}
            onBlur={field.onBlur}
          />
          <Combobox.Button
            ref={buttonRef}
            as={Button}
            loading={fetchStatus.isFetching}
            error={fetchStatus.isError}
            success={fetchStatus.isSuccess}
          />
          <FloatingPortal root={containerRef.current}>
            <CodeComboboxResults
              {...floatingProps}
              open={open && isOpen}
              minimal
              codes={filteredCodes}
              helpers={helpers}
            />
          </FloatingPortal>
        </div>
      )}
    </Combobox>
  );
}

export default CodingComboboxInput;
