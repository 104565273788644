import { RenderType } from "./types";
import { Spinner } from "@/Icons";
import React from "react";
import { FocusableBlockContent } from "../useFocusableBlockContent";
import { RenderBlockContentProps } from "../RenderBlockContent";
import FreeText from "@/components/free-text-v1/FreeText";
import { IFreeTextContent } from "./models";
import useAutoSyncBlockContent from "@/hooks/useAutoSyncBlockContent";

interface RenderFreeTextBlockContentProps extends RenderBlockContentProps {
  renderType: RenderType;
}

function RenderFreeTextBlockContentWithoutRef(
  { reportId, blockId }: RenderFreeTextBlockContentProps,
  ref: React.Ref<FocusableBlockContent>,
) {
  const { draft, queryResult, setDraft } =
    useAutoSyncBlockContent<IFreeTextContent>(blockId, reportId);

  const handleDataChange = React.useCallback(
    (data) => setDraft((prev) => ({ ...prev, data })),
    [setDraft],
  );

  if (queryResult.isLoading) {
    return (
      <div className="flex items-center justify-center">
        <Spinner className="mx-2 inline h-6 w-6 animate-spin text-gray-primary" />
      </div>
    );
  }
  return (
    <FreeText
      ref={ref}
      placeholder="Typ hier uw tekst of gebruik '/' voor suggesties"
      values={draft?.data}
      onChange={handleDataChange}
      onSubmit={handleDataChange}
    />
  );
}

const RenderQuestionnaireBlockContent = React.forwardRef(
  RenderFreeTextBlockContentWithoutRef,
);
export default RenderQuestionnaireBlockContent;
