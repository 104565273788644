import {
  string,
  optional,
  boolean,
  number,
  Describe,
  nullable,
  coerce,
  union,
  literal,
  type,
  nonempty,
  array,
  enums,
} from "superstruct";
import { ICoding, ITiroQuantity } from ".";
import { INarrative, ICodeableConcept } from "./types";

export const CodingModel: Describe<ICoding> = type({
  system: string(),
  version: optional(string()),
  code: string(),
  display: string(),
  userSelected: optional(boolean()),
});

export const CodeableConceptModel: Describe<ICodeableConcept> = type({
  text: string(),
  coding: nonempty(array(CodingModel)),
});

export const QuantityModel: Describe<ITiroQuantity> = type({
  value: coerce(
    union([number(), literal("")]),
    string(),
    (s) => s && parseFloat(s)
  ),
  unit: string(),
  system: string(),
  code: string(),
  minValue: optional(nullable(number())),
  maxValue: optional(nullable(number())),
});

export const NarrativeModel: Describe<INarrative> = type({
  status: enums(["generated", "extensions", "additional", "empty"]),
  div: string(),
});
