import { FieldPath, FieldValues } from "react-hook-form";
import { createStore } from "zustand";

export type FieldChangeEvent<
  TFieldValues extends FieldValues = FieldValues,
  TPath extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> = {
  name: TPath;
  by: {
    type: "user" | "system";
    id: string | number;
  };
  type?: string;
};

export interface FieldProvenanceProps<
  TFieldValues extends FieldValues = FieldValues,
  TPath extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> {
  events: FieldChangeEvent<TFieldValues, TPath>[];
}
export interface FieldProvenanceState<
  TFieldValues extends FieldValues = FieldValues,
  TPath extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> {
  events: FieldChangeEvent<TFieldValues, TPath>[];
  track: <TEventPath extends TPath = TPath>(
    event: FieldChangeEvent<TFieldValues, TEventPath>
  ) => void;
  getLastFieldEvent: <TEventPath extends TPath = TPath>(
    name: TEventPath
  ) => FieldChangeEvent<TFieldValues, TEventPath> | undefined;
}

export type FieldProvenanceStore<
  TFieldValues extends FieldValues = FieldValues,
  TPath extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> = ReturnType<typeof createFieldProvenanceStore<TFieldValues, TPath>>;

export function createFieldProvenanceStore<
  TFieldValues extends FieldValues = FieldValues,
  TPath extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>(initProps?: Partial<FieldProvenanceProps<TFieldValues, TPath>>) {
  const DEFAULT_PROPS: FieldProvenanceProps<TFieldValues, TPath> = {
    events: [],
  };
  return createStore<FieldProvenanceState<TFieldValues, TPath>>()(
    (set, get) => ({
      ...DEFAULT_PROPS,
      ...initProps,
      track: (event) =>
        areEventsEqual(event, get().events[get().events.length - 1]) ||
        set((state) => ({ events: [...state.events, event] })),
      getLastFieldEvent: <TEventPath extends TPath = TPath>(name: TEventPath) =>
        get()
          .events.filter(
            (event): event is FieldChangeEvent<TFieldValues, TEventPath> =>
              event.name == name
          )
          .pop(),
    })
  );
}

function areEventsEqual(
  event1: FieldChangeEvent,
  event2?: FieldChangeEvent
): boolean {
  return (
    event1.name == event2?.name &&
    event1.by.id == event2?.by.id &&
    event1.by.type == event2?.by.type
  );
}
