import { ExclamationCircleIcon, XMarkIcon } from "@heroicons/react/20/solid";
import classNames from "classnames";
import React from "react";

export interface ValidationCardProps
  extends React.HTMLAttributes<HTMLDivElement> {
  title: string;
}

const ValidationCardTags = (props: React.HTMLAttributes<HTMLDivElement>) => (
  <div className="flex-auto space-x-1 py-1 " {...props} />
);
const ValidationCardContent = (props: React.HTMLAttributes<HTMLDivElement>) => (
  <div {...props} />
);

interface ValidationCardProgressProps
  extends React.HTMLAttributes<HTMLDivElement> {
  value: number;
  max?: number;
}

const ValidationCardProgress = ({
  value,
  max = 100,
  className,
  ...props
}: ValidationCardProgressProps) => (
  <div
    className={classNames(
      "h-2.5 w-full rounded-full bg-gray-200 dark:bg-gray-dark",
      className
    )}
    {...props}
  >
    <div
      className="h-2.5 rounded-full bg-blue-500"
      style={{ width: `${(value / max) * 100}%` }}
    ></div>
  </div>
);
interface ValidationCardWarningProps
  extends React.HTMLAttributes<HTMLDivElement> {
  title: string;
  description: string;
}
const ValidationCardWarning = ({
  title,
  description,
  className,
  ...props
}: ValidationCardWarningProps) => (
  <div
    className={classNames("my-4 rounded-xl bg-yellow-50 p-2", className)}
    {...props}
  >
    <div className="flex">
      <div className="flex-shrink-0">
        <ExclamationCircleIcon className="h-5 w-5 text-yellow-400" />
      </div>
      <div className="ml-2">
        <h3 className="text-sm font-medium text-yellow-800">{title}</h3>
        <div className="mt-2 text-xs text-yellow-700">
          <p>{description}</p>
        </div>
      </div>
    </div>
  </div>
);

function ValidationCard({
  className,
  title,
  children,
  ...props
}: ValidationCardProps) {
  return (
    <div
      className={classNames(
        "w-full border-y border-gray-100 px-2 py-1",
        className
      )}
      {...props}
    >
      <div className="flex">
        <div className="flex-auto space-y-2 px-1 py-1">
          <h3 className="text-base font-semibold text-gray-dark">{title}</h3>
          <div className="pl-2">{children}</div>
        </div>
        <div className="flex-shrink-0 py-0 pr-0">
          <button
            type="button"
            className="rounded-md bg-white text-gray-400 hover:text-gray-primary focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
          >
            <XMarkIcon className="h-5 w-5" />
          </button>
        </div>
      </div>
    </div>
  );
}

ValidationCard.Tags = ValidationCardTags;
ValidationCard.Content = ValidationCardContent;
ValidationCard.Progress = ValidationCardProgress;
ValidationCard.Warning = ValidationCardWarning;

export default ValidationCard;
