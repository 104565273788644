import { useFieldProvenance } from "@/components/inputs/field-provenance/useFieldProvenance";
import { IConcept } from "@/data-models/concept";
import { ICoding, IQuantity } from "@/data-models/value-models";
import { ICodeableConcept, IRange } from "@/data-models/value-models/types";
import { useFHIREndpoint } from "@/hooks/useFHIREndpoint";
import { useCurrentSubject } from "@/pages/patients/createCurrentSubjectLoader";
import { createObservationBasedPrepopulationQuery } from "@/prepopulation/observation-based";
import {
  FieldPath,
  FieldPathByValue,
  FieldValues,
  RegisterOptions,
  useFormContext,
  UseFormRegister,
} from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { is, literal, nullable, optional } from "superstruct";
import Badge from "../../Badge/Badge";
import StyledInput from "../../Badge/StyledInput";
import ObservationCode from "../ObservationCode";
import UnitCoding from "../UnitCoding";
import useFieldProvenanceTracker from "@/components/inputs/field-provenance/useFieldProvenanceTracker";
import { Spinner } from "@/Icons";

export interface IQuantityObservationValue {
  code?: IConcept;
  value: IQuantity<null>;
}

const DECIMAL_TRANSFORMER = <TFieldValues extends FieldValues>(
  register: UseFormRegister<TFieldValues>
) => {
  return <TFieldName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>>(
    name: TFieldName,
    options?: Omit<
      RegisterOptions<TFieldValues, TFieldName>,
      "valueAsDate" | "valueAsNumber" | "valueAsBoolean"
    >
  ) => {
    return register(name, {
      ...options,
      onChange: (e) => {
        e.target.value = e.target.value.replace(/,/g, ".");
      },
    });
  };
};

export interface QuantityObservationInputProps<
  TFieldValues extends FieldValues
> {
  /** Highlight the input */
  active?: boolean;
  name: FieldPathByValue<TFieldValues, IQuantityObservationValue>;
  /** The measured quantity accompanied with codes. */
  code: IConcept;
  /** The range of values that are allowed for the value of the observation. */
  absoluteRange?: IRange;
  /** The unit of the value of the observation. */
  unit: ICoding;
  style?: "table" | "tile" | "default";
  /** Wether or not to disable the inputs */
  disabled?: boolean;
  /** Wether or not to show the label */
  hideLabel?: boolean;
  /** Prepopulation strategy */
  prepopulation?: "observation" | null;
}
const IS_EMPTY = ({ value }: { value: IQuantityObservationValue }) =>
  is(value?.value?.value, nullable(optional(literal(""))));
function QuantityObservationInput<
  TFieldValues extends FieldValues = FieldValues
>({
  name,
  code,
  absoluteRange,
  unit,
  style = "default",
  disabled = false,
  hideLabel = false,
  prepopulation = null,
  active,
}: QuantityObservationInputProps<TFieldValues>) {
  const { t } = useTranslation();

  const quantityFieldName: FieldPathByValue<TFieldValues, IQuantity> =
    `${name}.value` as any;

  const formContext = useFormContext<TFieldValues>();
  const { register, getFieldState, formState, getValues } = formContext;
  const { lastEvent: lastEventQuantity } = useFieldProvenanceTracker(
    // @ts-ignore
    `${quantityFieldName}.value`,
    formContext
  );

  return (
    <Badge
      name={name}
      style={style}
      defaultMode={prepopulation == "observation" ? "view" : "edit"}
      isEmptyFn={IS_EMPTY}
      color={
        lastEventQuantity?.by.type == "user"
          ? "blue"
          : lastEventQuantity?.by.type == "system"
          ? "green"
          : "blue"
      }
    >
      <Badge.Editor
        active={active}
        prefix={
          <ObservationCode
            name={`${name}.code`}
            code={code}
            hidden={hideLabel}
          />
        }
        suffix={<UnitCoding coding={unit} hidden={!unit?.display} />}
        disabled={disabled}
      >
        {({ autoFocus }) => (
          <>
            <StyledInput
              type="text"
              inputMode="decimal"
              disabled={disabled}
              autoFocus={autoFocus}
              invalid={
                // this should be possible with pseudo selectors
                !!getFieldState<any>(`${quantityFieldName}.value`, formState)
                  .error
              }
              {...DECIMAL_TRANSFORMER(register)<any>(
                `${quantityFieldName}.value`,
                {
                  pattern: {
                    value: /^(-?\d+\.?\d*)?$/g,
                    message: t("invalidNumber"),
                  },
                  max: absoluteRange?.high?.value && {
                    value: absoluteRange?.high?.value,
                    message: `Waarde moet kleiner zijn dan ${absoluteRange?.high?.value} ${absoluteRange?.high?.unit}`,
                  },
                  min: absoluteRange?.low?.value && {
                    value: absoluteRange?.low?.value,
                    message: `Waarde moet groter zijn dan ${absoluteRange?.low?.value} ${absoluteRange?.low?.unit}`,
                  },
                  disabled,
                }
              )}
              size={6}
            />
            <input
              type="hidden"
              {...register<any>(`${quantityFieldName}.unit`)}
              defaultValue={unit?.display}
            />
            <input
              type="hidden"
              {...register<any>(`${quantityFieldName}.system`)}
              defaultValue={unit?.system}
            />
            <input
              type="hidden"
              {...register<any>(`${quantityFieldName}.code`)}
              defaultValue={unit?.code}
            />
          </>
        )}
      </Badge.Editor>
      <Badge.Viewer
        prefix={
          <ObservationCode
            name={`${name}.code`}
            code={code}
            hidden={hideLabel}
          />
        }
        suffix={<UnitCoding coding={unit} hidden={!unit?.display} />}
      >
        {() => getValues<any>(`${quantityFieldName}.value`)}
      </Badge.Viewer>
    </Badge>
  );
}

export default QuantityObservationInput;

type NoPrepopulation = {
  strategy: null;
  code?: never;
};

type ObservationBasedPrepopulation = {
  strategy: "observation";
  code: ICodeableConcept;
};
type PrepopulationStrategy = ObservationBasedPrepopulation | NoPrepopulation;

type UseInitialValueProps = PrepopulationStrategy & { name: string };

/**
 * @param prepopulation
 * @deprecated This is a temporary solution. Prepopulation must be handled on form level.
 **/
function useInitialValue({ code, strategy, name }: UseInitialValueProps) {
  const { id: patientId } = useCurrentSubject();
  const track = useFieldProvenance((s) => s.track);
  const endpoint = useFHIREndpoint();
  return useQuery({
    ...createObservationBasedPrepopulationQuery({
      codes: code?.coding!,
      subject: {
        reference: `Patient/${patientId}`,
      },
      afterDate: new Date("2000-01-01"),
      endpoint,
      type: "quantity",
      enabled: strategy == "observation",
    }),
    onSuccess: () => {
      track({
        name,
        by: { type: "system", id: "TODO" },
        type: "prepopulation",
      });
    },
  });
}
