import client from "./client";
import keyFactory from "./keyFactory";

export const createReportQuery = (
  reportId: number,
  options: { blocksEmbedded: boolean } = { blocksEmbedded: true }
) => {
  return {
    queryKey: keyFactory.report(reportId),
    queryFn: () => client.v1.getReportV1({ reportId, ...options }),
    staleTime: 1000 * 60, // 1 minute
  };
};
