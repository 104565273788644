import {
  MutationFunction,
  QueryKey,
  useMutation,
  useQuery,
  useQueryClient,
} from "react-query";
import keyFactory from "./keyFactory";
import client from "./client";
import blockListReducer, {
  Action,
  ActionType,
} from "@/components/blocks/blockListReducer";
import { BlockIn, BlockOut, ReportOut } from "./report-client";

const useBlockMutation = <T extends ActionType, R = unknown>(
  reportId: number | undefined,
  type: T,
  queryKey: QueryKey,
  mutationFn: MutationFunction<R, Omit<Action<BlockIn, T>, "type">>,
) => {
  const queryClient = useQueryClient();
  const blockMutator = useMutation<
    R,
    Error,
    Omit<Action<BlockIn, T>, "type">,
    { prevBlocks?: BlockOut[] }
  >(mutationFn, {
    onMutate: async (action) => {
      // these queries will be return invalid data anyway
      await queryClient.cancelQueries(queryKey);

      // load current state from cache
      const prevBlocks = queryClient.getQueryData<BlockOut[]>(queryKey);

      // if the cache is valid, set
      if (prevBlocks) {
        const nextBlocks = blockListReducer(prevBlocks, {
          ...action,
          type,
        } as Action<BlockIn, T>);
        nextBlocks &&
          queryClient.setQueryData<BlockOut[]>(queryKey, nextBlocks);
      }
      return { prevBlocks };
    },
    // If the mutation fails, use the context returned from onMutate to roll back
    onError: (err, action, context) => {
      if (!context) return;
      queryClient.setQueryData(queryKey, context.prevBlocks);
    },
  });
  // wrappers to be exposed
  const { mutate } = blockMutator;

  return mutate;
};

export function createReportBlockListQuery(reportId: number) {
  return {
    queryKey: keyFactory.reportBlocks(reportId),
    queryFn: () =>
      client.v1.getBlocksFromReportV1({
        reportId,
      }),
  };
}

export const useReportBlockList = (reportId: number) => {
  const queryOptions = createReportBlockListQuery(reportId);
  const {
    data: blocks,
    status,
    error,
  } = useQuery({
    ...queryOptions,
    staleTime: 60 * 1000, // 1 minute
  });
  const append = useBlockMutation(
    reportId,
    "APPEND",
    queryOptions.queryKey,
    async ({ block }) =>
      await client.v1.appendBlockToReportV1({
        reportId,
        requestBody: block,
      }),
  );
  const insert = useBlockMutation(
    reportId,
    "INSERT",
    queryOptions.queryKey,
    async ({ index, block }) =>
      await client.v1.insertOrMoveBlockAtPositionInReportV1({
        reportId,
        position: index,
        requestBody: block,
      }),
  );
  const remove = useBlockMutation(
    reportId,
    "REMOVE",
    queryOptions.queryKey,
    async ({ blockId }) =>
      await client.v1.deleteBlockInReportV1({ blockId, reportId }),
  );
  const reorder = useBlockMutation(
    reportId,
    "REORDER",
    queryOptions.queryKey,
    async ({ srcBlockId, dstIndex }) => {
      await client.v1.insertOrMoveBlockAtPositionInReportV1({
        reportId,
        position: dstIndex,
        id: srcBlockId,
      });
    },
  );
  return {
    append,
    insert,
    remove,
    reorder,
    blocks,
    status,
    error,
  };
};
