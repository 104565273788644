import { CheckIcon } from "@heroicons/react/24/outline";
import classNames from "classnames";
import { Spinner } from "@/Icons";
import React from "react";
import { Control, FieldValues } from "react-hook-form";

export interface SubmitProps<TFieldValues extends FieldValues>
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  control?: Control<TFieldValues>;
  extraText?: React.ReactElement;
  small?: boolean;
  isSubmitting?: boolean;
  isSubmitSuccessful?: boolean;
}
function Submit<TFieldValues extends FieldValues = FieldValues>({
  children,
  extraText,
  small,
  isSubmitSuccessful,
  isSubmitting,
  className,
  ...buttonProps
}: SubmitProps<TFieldValues>) {
  return (
    <div>
      <button
        type="submit"
        className={classNames(
          "mt-1 flex w-fit items-center justify-center space-x-1 border border-transparent bg-blue-primary  text-white shadow-sm hover:bg-blue-dark focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 disabled:bg-gray-400 hover:disabled:bg-gray-400 focus:disabled:ring-gray-primary",
          {
            "rounded px-2 py-1 text-xs font-normal": small,
            "rounded-md px-4 py-2 text-sm font-medium": !small,
          },
          className
        )}
        {...buttonProps}
      >
        <span className="text-center">{children}</span>
        {isSubmitting && (
          <Spinner className="h-4 w-4 animate-spin text-center" />
        )}
        {isSubmitSuccessful && <CheckIcon className="h-4 w-4 text-center" />}
      </button>
      {extraText && <div className="mt-1">{extraText}</div>}
    </div>
  );
}
export default Submit;
