import {
  $convertFromMarkdownString,
  $convertToMarkdownString,
} from "@lexical/markdown";
import { ElementNode } from "lexical";
import { EMPTY_PARAGRAPH_FIX, EMPTY_SPACE_FIX } from "./EMPTY_PARAGRAPH_FIX";
import { LINE_BREAK_FIX } from "./LINE_BREAK_FIX";
import { MENTION } from "./MentionPlugin/MENTION_TRANSFORMER";

export function $convertToMarkdown(node?: ElementNode | undefined) {
  return $convertToMarkdownString(
    [MENTION, LINE_BREAK_FIX, EMPTY_PARAGRAPH_FIX, EMPTY_SPACE_FIX],
    node,
  );
}

export function $convertFromMarkdown(
  markdown: string,
  node?: ElementNode | undefined,
) {
  try {
    return $convertFromMarkdownString(
      markdown,
      [MENTION, LINE_BREAK_FIX, EMPTY_PARAGRAPH_FIX],
      node,
    );
  } catch (e) {
    console.warn(e);
  }
}
