import { Tab } from "@headlessui/react";
import classNames from "classnames";
import { Ref } from "react";
import React from "react";
import {
  ImperativePanelHandle,
  Panel,
  PanelGroup,
  PanelOnCollapse,
  PanelProps,
  PanelResizeHandle,
} from "react-resizable-panels";
import {
  ChevronDoubleLeftIcon,
  ChevronDoubleRightIcon,
} from "@heroicons/react/24/solid";

export interface ThreeColumnsPageProps
  extends React.HTMLAttributes<HTMLDivElement> {
  children: React.ReactNode[];
}

function ThreeColumnsPage({
  children,
  className,
  ...props
}: ThreeColumnsPageProps) {
  const childrenArray = React.Children.toArray(children);

  if (childrenArray.length !== 3) {
    throw new Error("ThreeColumnsPage expects exactly 3 children");
  }

  const [leftColumn, centerColumn, rightColumn] = childrenArray;
  return (
    <PanelGroup
      direction="horizontal"
      className={classNames("bg-gray-ultralight", className)}
      {...props}
    >
      {leftColumn}
      <PanelResizeHandle className="relative -left-0.5 z-10 w-1 cursor-col-resize hover:bg-gray-300" />
      {centerColumn}
      <PanelResizeHandle className="relative -right-0.5 z-10 w-1 cursor-col-resize hover:bg-gray-300" />
      {rightColumn}
    </PanelGroup>
  );
}

export interface ColumnsProps extends PanelProps {
  children?: React.ReactNode[] | React.ReactNode;
}
export interface LeftColumnsProps extends ColumnsProps {
  isCollapsed?: boolean;
  onCollapse?: PanelOnCollapse;
  collapse?: () => void;
}

export interface RightColumnsProps extends ColumnsProps {
  tabs?: {
    name: string;
    content: React.ReactNode;
  }[];
}

function LeftColumn(
  {
    children,
    onCollapse,
    defaultSize,
    collapse,
    isCollapsed,
    className,
    ...props
  }: LeftColumnsProps,
  ref?: Ref<ImperativePanelHandle>,
) {
  return (
    <Panel
      ref={ref}
      order={0}
      defaultSize={defaultSize}
      onCollapse={onCollapse}
      collapsible
      className={classNames(
        "relative border-r border-gray-light bg-white",
        className,
      )}
      {...props}
    >
      <div className="absolute right-2 top-0.5 z-10">
        {!isCollapsed && collapse && (
          <button onClick={() => collapse()}>
            <ChevronDoubleLeftIcon className="h-5 w-5 rounded-sm p-px text-gray-500 hover:bg-gray-200 hover:text-gray-600" />
          </button>
        )}
      </div>
      <div className="relative h-full overflow-auto">
        <div className="h-full px-2 py-6">{children}</div>
      </div>
    </Panel>
  );
}

interface CenterColumnProps extends ColumnsProps {
  isCollapsed?: boolean;
  collapse?: () => void;
  expand?: () => void;
}

function CenterColumn({
  children,
  isCollapsed,
  expand,
  className,
  ...props
}: CenterColumnProps) {
  return (
    <Panel order={1} className={classNames("relative", className)} {...props}>
      <div className="absolute left-1 top-0.5">
        {isCollapsed && expand && (
          <button onClick={() => expand()}>
            <ChevronDoubleRightIcon className="h-5 w-5 rounded p-px text-gray-500 hover:bg-gray-200 hover:text-gray-600" />
          </button>
        )}
      </div>
      {/** This id is important for the scroll navigation */}
      <div
        id="center_column"
        className="mx-auto h-full w-full min-w-[30%] max-w-[900px] overflow-y-scroll bg-gray-ultralight px-4 pt-6 sm:px-6 lg:px-8 xl:max-w-7xl"
      >
        {children}
      </div>
    </Panel>
  );
}

function RightColumn({
  children,
  tabs,
  className,
  defaultSize,
  ...props
}: RightColumnsProps) {
  return (
    <Panel
      order={2}
      defaultSize={defaultSize}
      minSize={0}
      className={classNames("border-l border-gray-light bg-white", className)}
      {...props}
    >
      <div className="relative h-full ">
        {!!tabs && (
          <Tab.Group>
            <Tab.List className="sticky top-0 flex w-full bg-white pt-6">
              {tabs.map((tab) => (
                <Tab
                  key={tab.name}
                  className={({ selected }) =>
                    classNames(
                      "w-full border-b-2 border-gray-light bg-white pb-1 text-left text-sm uppercase focus:outline-none focus:ring-0",
                      selected
                        ? "border-b-2 border-blue-primary font-medium text-primary"
                        : "text-gray-light hover:text-primary",
                    )
                  }
                >
                  {tab.name}
                </Tab>
              ))}
            </Tab.List>
            <Tab.Panels className="mt-2 h-full">
              {tabs.map((tab) => (
                <Tab.Panel key={tab.name} className={classNames("h-full")}>
                  {tab.content}
                </Tab.Panel>
              ))}
            </Tab.Panels>
          </Tab.Group>
        )}
        {!tabs && children}
      </div>
    </Panel>
  );
}

ThreeColumnsPage.LeftColumn = React.forwardRef(LeftColumn);
ThreeColumnsPage.CenterColumn = CenterColumn;
ThreeColumnsPage.RightColumn = RightColumn;

export default ThreeColumnsPage;
