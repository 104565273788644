import { Dialog, Transition } from "@headlessui/react";
import { ExclamationCircleIcon } from "@heroicons/react/24/outline";
import { XMarkIcon } from "@heroicons/react/24/solid";
import classNames from "classnames";
import React, { Fragment } from "react";
import { useTranslation } from "react-i18next";
import useRemovePatient from "@/services/reports/useRemovePatient";

interface RemovePatientButtonProps
  extends React.HTMLAttributes<HTMLButtonElement> {
  patientId?: number;
}
function AssociatedReportsLeftWarning({
  open,
  close,
}: {
  open: boolean;
  close: () => void;
}) {
  const { t } = useTranslation();
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={close}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
                <div>
                  <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-red-100">
                    <ExclamationCircleIcon
                      className="h-6 w-6 text-red-600"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="mt-3 text-center sm:mt-5">
                    <Dialog.Title
                      as="h3"
                      className="text-base font-semibold leading-6 text-gray-900"
                    >
                      {t("kan-patient-niet-verwijderen")}
                    </Dialog.Title>
                    <div className="mt-2">
                      <p className="text-sm text-gray-500">
                        {t(
                          "er-zijn-nog-rapporten-aan-deze-patient-gekoppeld-verwijder-deze-rapporten-eerst"
                        )}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="mt-5 sm:mt-6">
                  <button
                    type="button"
                    className="inline-flex w-full justify-center rounded-md border border-transparent bg-blue-primary px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 sm:text-sm"
                    onClick={() => close()}
                  >
                    {t("sluit-deze-melding")}
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

function RemovePatientButton_(
  { patientId, className, ...attributes }: RemovePatientButtonProps,
  ref: React.ForwardedRef<HTMLButtonElement>
) {
  const [showWarning, setShowWarning] = React.useState<Error | false>(false);
  const { mutate } = useRemovePatient({
    onError: (error) => {
      error.body.detail.code == "REPORTS_ASSOCIATED" && setShowWarning(error);
    },
  });
  return (
    <>
      <AssociatedReportsLeftWarning
        open={!!showWarning}
        close={() => setShowWarning(false)}
      />
      <button
        ref={ref}
        className={classNames(
          "h-5 w-5 text-gray-light hover:text-gray-primary",
          className
        )}
        onClick={() => patientId && mutate(patientId)}
        {...attributes}
      >
        <XMarkIcon className="inline-block fill-current" />
      </button>
    </>
  );
}
const RemovePatientButton = React.forwardRef(RemovePatientButton_);
export default RemovePatientButton;
