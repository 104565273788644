import i18next from "i18next";

import en from "@/i18n/translation/en.json";
import nl from "@/i18n/translation/nl.json";
import fr from "@/i18n/translation/fr.json";
import { I18nextProvider, useTranslation } from "react-i18next";
import React, { useCallback, useEffect } from "react";
import { useNavigate, useLocation } from "react-router";
import { useSearchParams } from "react-router-dom";

i18next.init({
  lng: "nl", // if you're using a language detector, do not define the lng option
  react: {
    useSuspense: false,
  },
  resources: {
    nl: {
      translation: nl,
    },
    en: {
      translation: en,
    },
    fr: {
      translation: fr,
    },
  },
});
export function useLanguage() {
  const { i18n } = useTranslation();
  return i18n.language;
}

export function useLanguageSwitch() {
  const navigate = useNavigate();
  const location = useLocation();
  const f = useCallback(
    (lang: string) => {
      const search = new URLSearchParams(location.search);
      search.set("lang", lang);
      navigate({ ...location, search: search.toString() });
    },
    [navigate, location],
  );
  return f;
}

export function I18nLanguageSwitcher({
  defaultLanguage = "nl",
  children,
}: {
  defaultLanguage: string;
  children?: React.ReactNode;
}) {
  const { i18n } = useTranslation();
  const [search] = useSearchParams();
  const lang = new URLSearchParams(search).get("lang") ?? defaultLanguage;
  useEffect(() => {
    if (lang !== i18n.language) {
      i18n.changeLanguage(lang);
    }
  }, [lang, i18n]);
  return <>{children}</>;
}

export function I18nProvider({
  children,
  defaultLanguage = "nl",
}: React.PropsWithChildren<{ defaultLanguage: string }>) {
  return (
    <I18nextProvider i18n={i18next}>
      <I18nLanguageSwitcher defaultLanguage={defaultLanguage} />
      {children}
    </I18nextProvider>
  );
}

export default i18next;
