import { useParams } from "react-router";
import { coerce, create, integer, string } from "superstruct";

function useCurrentSubjectId() {
  const { patientId } = useParams<{ patientId: string }>();
  return create(
    patientId,
    coerce(integer(), string(), (v) => parseInt(v))
  );
}

export default useCurrentSubjectId;
