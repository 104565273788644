import { ISuggestEditor } from "@/editor/legacy/Suggestions/SuggestEditor/types";
import { createBaseEditor, ITiroEditor } from "@/editor/Base/editor";
import { IEditorPlugin } from "@/editor/Base/types";
import withSuggestions, { WithSuggestionsOptions } from "./withSuggestions";

const createSuggestionEditor = (
  plugins: IEditorPlugin[] = [],
  options: WithSuggestionsOptions = {},
) => {
  let editor = withSuggestions(createBaseEditor(plugins), options);
  return editor as ISuggestEditor & ITiroEditor;
};

export default createSuggestionEditor;
