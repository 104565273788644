import { Disclosure } from "@headlessui/react";
import {
  ChevronLeftIcon,
  Bars3Icon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { Link } from "react-router-dom";
import { ProfileMenu } from "./ProfileMenu";
import classNames from "classnames";
import { HTMLAttributes } from "react";
import LogoLink from "./LogoLink";
import useLaunchedFrom from "@/hooks/useLaunchedFrom";
interface NavBarProps extends HTMLAttributes<HTMLDivElement> {
  showNav?: boolean;
}

const NavBar = ({ showNav = false, className, ...props }: NavBarProps) => {
  const isEmbedded = useLaunchedFrom("Synops");
  if (isEmbedded) return null;

  return (
    <Disclosure
      as="nav"
      className={classNames(
        "border-b border-gray-light bg-gray-dark shadow-sm",
        className,
      )}
      {...props}
    >
      {({ open }) => (
        <>
          <div className="mx-auto px-2 sm:px-6 lg:px-8">
            <div className="relative flex h-14 justify-between">
              <div className="flex flex-1 items-center justify-center sm:items-stretch sm:justify-start">
                <div className="flex justify-center sm:items-stretch sm:justify-start">
                  <div className="flex flex-shrink-0 items-center">
                    <LogoLink color="white" />
                  </div>
                </div>
                <div className="flex flex-shrink-0 items-center px-4">
                  <Link
                    to={{ pathname: "/templates" }}
                    className=" inline-flex h-9 items-center rounded border border-white bg-opacity-70 pl-3 pr-5 text-white hover:bg-gray-primary sm:text-sm"
                  >
                    <ChevronLeftIcon className="mr-2 inline h-4 w-4 stroke-current" />
                    <span>Templates</span>
                  </Link>
                </div>
                <div className="mr-auto" />
                <div className="mr-12 flex w-full flex-grow items-center justify-end">
                  <button className="float-right h-9 rounded-md border border-white px-3 text-white hover:bg-gray-primary sm:text-sm">
                    Publish
                  </button>
                </div>
              </div>
              <div className="absolute inset-y-0 right-0 z-10 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
                {/* Profile dropdown */}
                <ProfileMenu />
              </div>
            </div>
          </div>
        </>
      )}
    </Disclosure>
  );
};

export default NavBar;
