import { useParams } from "react-router";
import { coerce, create, integer, string } from "superstruct";

export default function useCurrentBlockId() {
  const { blockId } = useParams<{ blockId: string }>();
  return create(
    blockId,
    coerce(integer(), string(), (v) => parseInt(v)),
  );
}
