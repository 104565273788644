import { ChevronLeftIcon } from "@heroicons/react/24/outline";
import classNames from "classnames";
import { Link } from "react-router-dom";
import { LinkProps } from "react-router-dom";

type StyledBackLinkProps = LinkProps;
function StyledBackLink({
  children,
  className,
  ...props
}: StyledBackLinkProps) {
  return (
    <Link
      className={classNames(
        " inline-flex h-9 items-center rounded border border-white bg-opacity-70 px-3 text-white hover:bg-gray-primary sm:pr-5 sm:text-sm",
        className,
      )}
      {...props}
    >
      <ChevronLeftIcon className="inline h-4 w-4 stroke-current sm:mr-2" />
      <span className="hidden sm:inline">{children}</span>
    </Link>
  );
}

export default StyledBackLink;
