import { QueryClient } from "react-query";
import { RouteObject } from "react-router";
import { REPORT_SUBMIT_PAGE } from "./consts";
import createAction from "./createAction";
import createLoader from "./createLoader";
import ReportSubmitPage from "./ReportSubmitPage";

export default (queryClient: QueryClient): RouteObject => ({
  path: REPORT_SUBMIT_PAGE,
  element: <ReportSubmitPage />,
  loader: createLoader(queryClient),
  action: createAction(queryClient),
});
