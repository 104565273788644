import classNames from "classnames";
import React from "react";

export interface LabelProps
  extends React.LabelHTMLAttributes<HTMLLabelElement> {
  small?: boolean;
  extraSmall?: boolean;
}
function Label_(
  { className, small, extraSmall, ...props }: LabelProps,
  ref: React.Ref<HTMLLabelElement>,
) {
  return (
    <label
      ref={ref}
      className={classNames(
        "font-medium text-gray-dark",
        {
          "text-base": !small && !extraSmall,
          "text-sm": small,
          "text-xs": extraSmall,
        },
        className,
      )}
      {...props}
    />
  );
}
const Label = React.forwardRef(Label_);
export default Label;
