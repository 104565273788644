import { RenderElementProps } from "slate-react";
import { IEditorPlugin } from "../../Base/types";
import { MERGE_TAG_ELEMENT } from "./consts";
import { MergeTagElement } from "./MergeTagElement";
import RenderMergeTagElement, {
  RenderMergeTagElementProps,
} from "./RenderMergeTagElement";

const MergeTagPlugin: IEditorPlugin = {
  renderElement(props: RenderElementProps) {
    const { element } = props;
    if (MergeTagElement.isMergeTagElement(element)) {
      return (
        <RenderMergeTagElement {...(props as RenderMergeTagElementProps)} />
      );
    }
  },
  inlineTypes: [MERGE_TAG_ELEMENT],
  voidTypes: [MERGE_TAG_ELEMENT],
};
export default MergeTagPlugin;
