import React from "react";
import SimpleQuantity, {
  ISimpleQuantity,
} from "@/data-models/quantity-entity/SimpleQuantity";
import { EntityEditorProps } from "..";
import FormContainer, { FormContainerProps } from "../FormContainer";

export interface SimpleQuantityEditorProps
  extends EntityEditorProps<ISimpleQuantity> {}

const SimpleQuantityFormContainer = React.forwardRef<
  HTMLFormElement,
  FormContainerProps<ISimpleQuantity>
>(FormContainer);

const SimpleQuantityEditorWithoutRef = (
  props: SimpleQuantityEditorProps,
  ref?: React.Ref<HTMLFormElement>
) => {
  const entity = new SimpleQuantity(props.entity);
  const { interprets } = entity;
  return (
    <SimpleQuantityFormContainer ref={ref} {...props} entity={entity}>
      {({ register }) => (
        <div>
          <div className="mx-3 text-xs uppercase tracking-wide text-gray-primary">
            <span>Meting</span>
            {interprets && (
              <>
                <span className="px-px">&#8213;</span>
                <span className="relative font-medium tracking-wide hover:underline">
                  {interprets.text}
                </span>
              </>
            )}
          </div>
          <div className="mt-1 flex space-x-1 px-2">
            <input
              placeholder="meetwaarde"
              type="number"
              step={0.01}
              autoFocus
              onFocus={(e: React.FocusEvent<HTMLInputElement>) =>
                e.target.select()
              }
              className="mt-1 block rounded-sm border border-gray-200 px-1.5 py-1 focus:border-blue-200 focus:ring-1 focus:ring-blue-200 sm:text-sm"
              {...register("entity.quantity.value", {
                setValueAs: (value: string) => {
                  const converted = parseFloat(value);
                  return isNaN(converted) ? "" : converted;
                },
                max: entity.quantity?.maxValue ?? undefined,
                min: entity.quantity?.minValue ?? undefined,
              })}
            />
            <div className="mt-1 rounded-sm px-0.5 py-1 sm:text-sm">
              {entity.quantity?.unit}
            </div>
          </div>
        </div>
      )}
    </SimpleQuantityFormContainer>
  );
};
const SimpleQuantityEditor = React.forwardRef(SimpleQuantityEditorWithoutRef);
export default SimpleQuantityEditor;
