import { Menu, Transition } from "@headlessui/react";
import { ChevronDownIcon, XMarkIcon } from "@heroicons/react/24/outline";
import classNames from "classnames";
import React, { Fragment, useMemo, useState } from "react";
import Modal from "../base/Modal";
import useDownloadPDF from "./useDownloadPDFLink";
import useDownloadPlainText from "./useDownloadPlainText";
import PreviewMenu, { PreviewMode, PreviewModeTextMap } from "./PreviewMenu";
import { is, string } from "superstruct";
import usePlainTextView from "@/services/reports/usePlainTextView";
import useFHIRDocumentView from "@/services/reports/useFHIRDocumentView";
import ReactJson from "react-json-view";
import useCurrentReportId from "@/hooks/useCurrentReportId";
import StyledButton from "./StyledButton";
import { Spinner } from "@/Icons";
import MonoTextPreview from "@/components/base/MonoTextPreview";
import { useQuery } from "react-query";
import { CONFIG } from "@/config";
import { getTenantAuth } from "@/services/tenant";

interface ExportMenuProps {
  openPreview: (mode?: PreviewMode) => void;
}
function ExportMenu({
  children,
  openPreview,
}: React.PropsWithChildren<ExportMenuProps>) {
  const pdfLink = useDownloadPDF();
  const plainTextLink = useDownloadPlainText();

  /**
   * Every option in the menu has a action that is either a link or a function.
   * A link will be rendered as an anchor tag, a function will be rendered as a button.
   */
  const ExportOptionActionMap: Record<string, (() => void) | string> = useMemo(
    () =>
      ({
        "Open voorbeeldverslag": () => openPreview(),
        "Open FHIR Document": () => openPreview("fhir"),
        "Download PDF": pdfLink || "#",
        "Download textverslag": plainTextLink || "#",
      }) as const,
    [pdfLink, plainTextLink, openPreview],
  );

  return (
    <Menu as="div" className="relative z-10 -ml-px block">
      {children}
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute left-0 z-10 -ml-1 mt-2 w-56 origin-top-left rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div className="py-1">
            {Object.entries(ExportOptionActionMap).map(([option, action]) => (
              <Menu.Item key={option}>
                {({ active }) =>
                  is(action, string()) ? (
                    <a
                      href={action}
                      className={classNames(
                        active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                        "block w-full px-4 py-2 text-left text-sm",
                      )}
                    >
                      {option}
                    </a>
                  ) : (
                    <button
                      type="button"
                      onClick={action}
                      className={classNames(
                        active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                        "block w-full px-4 py-2 text-left text-sm",
                      )}
                    >
                      {option}
                    </button>
                  )
                }
              </Menu.Item>
            ))}
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
}

function ExportButtonGroup({
  className,
}: React.PropsWithChildren<{ className?: string }>) {
  const reportId = useCurrentReportId();
  const [mode, setMode] = useState<PreviewMode | false>(false);
  const { data: plainText, isFetching: isPlainTextFetching } = usePlainTextView(
    reportId,
    {
      enabled: mode === "text",
      initialData: "Tekstverslag",
    },
  );
  const { data: fhirDocument, isFetching: isFHIRDocumentFetching } =
    useFHIRDocumentView(reportId, {
      enabled: mode === "fhir",
      includeExtractedResources: true,
    });
  const url = new URL(`api/v1/reports/${reportId}/pdf`, CONFIG.REPORT_SERVER);

  const { data: pdfURI, isFetching: isPDFFetching } = useQuery(
    ["reports", reportId, "views", "pdf"],
    async () => {
      const { currentUser } = getTenantAuth();
      if (!currentUser) throw new Error("User not logged in");
      const idToken = await currentUser.getIdToken();
      const headers = {
        Accept: "application/pdf",
        Authorization: `Bearer ${idToken}`,
      };
      const response = await fetch(url.href, { headers });
      const blob = await response.blob();
      console.debug(blob);
      return URL.createObjectURL(blob);
    },
    {
      enabled: mode === "pdf",
      staleTime: 1000 * 30,
    },
  );
  const isFetching =
    mode == "fhir"
      ? isFHIRDocumentFetching
      : mode == "text"
        ? isPlainTextFetching
        : mode == "pdf"
          ? isPDFFetching
          : false;
  return (
    <div className={classNames("rounded-md font-primary shadow-sm", className)}>
      <ExportMenu openPreview={(mode) => setMode(mode ?? "text")}>
        <StyledButton group="first" onClick={() => setMode("text")}>
          Open voorbeeld verslag
        </StyledButton>
        <Menu.Button as={StyledButton} group="last" className="!px-1.5">
          <span className="sr-only">Toon opties</span>
          <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
        </Menu.Button>
      </ExportMenu>
      <Modal
        isOpen={!!mode}
        onClose={() => setMode(false)}
        className="flex max-h-full flex-col self-stretch sm:max-w-3xl"
      >
        <button
          className="absolute right-2 top-2"
          type="button"
          onClick={() => setMode(false)}
        >
          <span className="sr-only">Close</span>
          <XMarkIcon className="h-8 w-8 text-gray-primary" aria-hidden="true" />
        </button>
        <div className="items-bottom my-2 flex w-full justify-start">
          <PreviewMenu mode={mode} setMode={setMode}>
            <Menu.Button className="inline-flex w-full items-center justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-blue-primary focus:ring-offset-2 focus:ring-offset-gray-100">
              {mode && PreviewModeTextMap.get(mode)}
              <ChevronDownIcon
                className="-mr-1 ml-2 inline h-5 w-5"
                aria-hidden="true"
              />
            </Menu.Button>
          </PreviewMenu>
          <p className="flex items-center text-gray-primary">
            {isFetching && (
              <>
                <Spinner className=" mx-1 h-4 w-4 animate-spin" />
                <span>Laatste versie ophalen...</span>
              </>
            )}
          </p>
        </div>
        <div className="ph-no-capture relative grow overflow-y-scroll rounded border border-gray-light px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
          {mode == "text" && <MonoTextPreview>{plainText}</MonoTextPreview>}
          {mode == "fhir" && <ReactJson src={fhirDocument ?? {}} />}
          {mode == "pdf" && (
            <embed
              className="absolute inset-0 h-full w-full"
              src={pdfURI ?? ""}
            />
          )}
        </div>
      </Modal>
    </div>
  );
}

export default ExportButtonGroup;
