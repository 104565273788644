import { useQuery } from "react-query";
import client from "./client";
import keyFactory from "./keyFactory";

type UseValueSetsOptions = { enabled?: boolean };
type UseValueSetsQuery = { title?: string; url?: string; version?: string };
function useValueSets(
  query: UseValueSetsQuery,
  options: UseValueSetsOptions = {}
) {
  return useQuery(
    keyFactory.allValueSets(query),
    () => client.r5.getAllValuesetsR5(query),
    {
      keepPreviousData: true,
      select: (bundle) => bundle.entry?.map((entry) => entry.resource),
      ...options,
    }
  );
}
export default useValueSets;
