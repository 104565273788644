import { AllEntityTypes } from "@/editor/legacy/Entities/types";
import { Editor, Transforms } from "slate";
import { Query } from "../detectQuery";
import { SuggestionPosition } from "@/components/legacy/suggestions/types";

function insertConceptSuggestionInDocument(
  editor: Editor,
  query: Query,
  position: SuggestionPosition,
  entity: any,
  entityType: AllEntityTypes = "entity",
) {
  let targetRange = query.targetRange;
  if (!targetRange) {
    const queryRange = query.queryRange;
    targetRange = {
      anchor: {
        ...queryRange.anchor,
        offset: queryRange.anchor.offset + position.offset,
      },
      focus: {
        ...queryRange.focus,
        offset: queryRange.anchor.offset + position.offset + position.length,
      },
    };
  }
  const newEntity = {
    type: entityType,
    children: [{ text: "" }],
    entity,
  };
  Transforms.select(editor, targetRange);
  Transforms.insertNodes(editor, newEntity, {
    at: targetRange,
    select: true,
    voids: false,
  });
  Transforms.move(editor, { distance: 1 });
}

export default insertConceptSuggestionInDocument;
