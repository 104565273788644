import NumericalInput from "@/components/inputs/NumericalInput";
import { CellProps } from "./Cells";

export interface QuantityCellProps extends CellProps<"QUANTITY"> {}

const QuantityCell = ({ name, column }: QuantityCellProps) => {
  return <NumericalInput name={name} suffix={column.unit.text} style="table" />;
};

export default QuantityCell;
