import { Combobox } from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";
import classNames from "classnames";
import { matchSorter } from "match-sorter";
import React, { useContext, useState } from "react";
import organisations from "./organisations.json";

export interface IOrganisationComboboxContext {
  query: string;
  setQuery: (query: string) => void;
  filteredOrganisations: string[];
}

const OrganisationComboboxContext =
  React.createContext<IOrganisationComboboxContext | null>(null);

export interface OrganisationComboboxInput
  extends React.InputHTMLAttributes<HTMLInputElement> {}

function OrganisationComboboxInput({
  className,
  ...otherProps
}: OrganisationComboboxInput) {
  const context = useContext(OrganisationComboboxContext);
  if (!context) {
    throw Error(
      "<OrganisationCombobox.Input/> can only be used inside a <OrganisationCombobox/> context.",
    );
  }
  const { query, setQuery, filteredOrganisations } = context;
  return (
    <div className="relative">
      <Combobox.Input
        as="input"
        className={classNames(
          "w-full rounded-md border border-gray-light bg-white py-2 pl-3 pr-10 shadow-sm focus:border-blue-500 focus:outline-none focus:ring-1 focus:ring-blue-500 sm:text-sm",
          className,
        )}
        onChange={(event) => setQuery(event.target.value)}
        displayValue={(value: string) => value}
        {...otherProps}
      />
      <Combobox.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
        <ChevronUpDownIcon
          className="h-5 w-5 text-gray-400"
          aria-hidden="true"
        />
      </Combobox.Button>

      {filteredOrganisations.length > 0 && (
        <Combobox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
          {filteredOrganisations.map((organisation) => (
            <Combobox.Option
              key={organisation}
              value={organisation}
              className={({ active }) =>
                classNames(
                  "relative cursor-default select-none py-2 pl-8 pr-4",
                  active ? "bg-blue-primary text-white" : "text-gray-900",
                )
              }
            >
              {({ active, selected }) => (
                <>
                  <span
                    className={classNames(
                      "block truncate",
                      selected && "font-semibold",
                    )}
                  >
                    {organisation}
                  </span>

                  {selected && (
                    <span
                      className={classNames(
                        "absolute inset-y-0 left-0 flex items-center pl-1.5",
                        active ? "text-white" : "text-blue-primary",
                      )}
                    >
                      <CheckIcon className="h-5 w-5" aria-hidden="true" />
                    </span>
                  )}
                </>
              )}
            </Combobox.Option>
          ))}
        </Combobox.Options>
      )}
      {filteredOrganisations.length == 0 && (
        <Combobox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
          <Combobox.Option
            key={query}
            value={query}
            className={({ active }) =>
              classNames(
                "relative cursor-default select-none py-2 pl-8 pr-4",
                active ? "bg-blue-primary text-white" : "text-gray-900",
              )
            }
          >
            {({ active, selected }) => (
              <>
                <span
                  className={classNames(
                    "block truncate",
                    selected && "font-semibold",
                  )}
                >
                  {query}
                </span>

                {selected && (
                  <span
                    className={classNames(
                      "absolute inset-y-0 left-0 flex items-center pl-1.5",
                      active ? "text-white" : "text-blue-primary",
                    )}
                  >
                    <CheckIcon className="h-5 w-5" aria-hidden="true" />
                  </span>
                )}
              </>
            )}
          </Combobox.Option>
        </Combobox.Options>
      )}
    </div>
  );
}

function OrganisationComboboxLabel({
  className,
  children,
  ...otherProps
}: Omit<React.LabelHTMLAttributes<HTMLLabelElement>, "as">) {
  return (
    <Combobox.Label
      as="label"
      className={classNames(
        "block text-sm font-medium text-gray-dark",
        className,
      )}
      {...otherProps}
    >
      {children}
    </Combobox.Label>
  );
}

export interface OrganisationComboboxProps
  extends Omit<
    React.HTMLAttributes<HTMLDivElement>,
    "defaultValue" | "by" | "value" | "onChange"
  > {
  name?: string;
  selected?: string | null;
  onSelectedChange?: (organisation: string | null) => void;
  defaultValue?: string | null;
}

function OrganisationCombobox({
  className,
  selected,
  name,
  onSelectedChange,
  children,
  ...otherProps
}: OrganisationComboboxProps) {
  const [query, setQuery] = useState("");
  const extendedOrganisations = selected
    ? organisations.includes(selected)
      ? organisations
      : [...organisations, selected]
    : organisations;
  const filteredOrganisations = matchSorter(extendedOrganisations, query);
  return (
    <Combobox
      as="div"
      name={name}
      value={selected}
      onChange={onSelectedChange}
      className={className}
      {...otherProps}
    >
      <OrganisationComboboxContext.Provider
        value={{ query, setQuery, filteredOrganisations }}
      >
        {children}
      </OrganisationComboboxContext.Provider>
    </Combobox>
  );
}

OrganisationCombobox.Input = OrganisationComboboxInput;
OrganisationCombobox.Label = OrganisationComboboxLabel;

export default OrganisationCombobox;
