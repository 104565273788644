import {
  autoPlacement,
  FloatingPortal,
  Placement,
  useFloating,
} from "@floating-ui/react";
import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon, PlusIcon } from "@heroicons/react/24/outline";
import classNames from "classnames";
import { ICoding } from "@/data-models/value-models";
import { Fragment, InputHTMLAttributes } from "react";
import { SEMANTIC_AXES } from "./const";

const SEMANTIC_TO_CLASSES = {
  finding: "bg-blue-500 dark:bg-blue-100",
  disorder: "bg-emerald-500 dark:bg-emerald-100",
  procedure: "bg-purple-500  dark:bg-purple-100",
  "surgical-procedure": "bg-purple-500 dark:bg-purple-100",
  "evaluation-procedure": "bg-purple-500 dark:bg-purple-100",
  "diagnostic-procedure": "bg-purple-500 dark:bg-purple-100",
  substance: "bg-yellow-500 dark:bg-yellow-100",
  medication: "bg-orange-500 dark:bg-orange-200",
  "qualifier value": "bg-purple-500 dark:bg-purple-200",
  unknown: "bg-gray-500 dark:bg-gray-200",
} as const;

export function getSemanticClasses(code: string) {
  return (
    SEMANTIC_TO_CLASSES[code as keyof typeof SEMANTIC_TO_CLASSES] ??
    SEMANTIC_TO_CLASSES.unknown
  );
}
type AddSemanticAxisProps = {
  className?: string;
  onAdd?: (c: ICoding) => void;
  placement?: Placement;
};
export function AddSemanticAxis({
  className,
  onAdd,
  placement = "bottom-start",
}: AddSemanticAxisProps) {
  const { floatingStyles, refs } = useFloating({
    placement,
    middleware: [
      autoPlacement({
        allowedPlacements: [
          "bottom-start",
          "bottom-end",
          "top-start",
          "top-end",
        ],
      }),
    ],
  });
  return (
    <Listbox
      ref={refs.setReference}
      as="div"
      className="relative"
      onChange={(value: ICoding | null) => value && onAdd?.(value)}
    >
      <Listbox.Button
        type="button"
        className={({ open }) =>
          classNames(
            "rounded-sm px-1 py-0.5 text-xs",
            "border",
            {
              "border-gray-400 bg-white text-gray-500 hover:bg-gray-50 dark:border-gray-600 hover:dark:bg-gray-500":
                !open,
              "border-blue-400 bg-blue-50 text-blue-500 dark:border-blue-600 hover:dark:bg-blue-500":
                open,
            },
            className
          )
        }
      >
        <PlusIcon className="mr-0.5 inline h-3 w-3" />
        <span>add semantic</span>
      </Listbox.Button>
      <Transition
        as={Fragment}
        leave="transition ease-in duration-100"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <Listbox.Options
          ref={refs.setFloating}
          style={floatingStyles}
          className="z-10 max-h-60 w-fit overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
        >
          {SEMANTIC_AXES.map((coding) => (
            <Listbox.Option
              key={coding.code}
              className={({ active }) =>
                classNames(
                  active ? "bg-blue-600 text-white" : "text-gray-900",
                  "relative cursor-default select-none py-2 pl-3 pr-9"
                )
              }
              value={coding}
            >
              {({ selected, active }) => (
                <>
                  <div className="flex items-center">
                    <span
                      className={classNames(
                        getSemanticClasses(coding.code),
                        "inline-block h-2 w-2 flex-shrink-0 rounded-full"
                      )}
                      aria-hidden="true"
                    />
                    <span
                      className={classNames(
                        selected ? "font-semibold" : "font-normal",
                        "ml-3 block truncate"
                      )}
                    >
                      {coding.display}
                    </span>
                  </div>

                  {selected ? (
                    <span
                      className={classNames(
                        active ? "text-white" : "text-blue-600",
                        "absolute inset-y-0 right-0 flex items-center pr-4"
                      )}
                    >
                      <CheckIcon className="h-5 w-5" aria-hidden="true" />
                    </span>
                  ) : null}
                </>
              )}
            </Listbox.Option>
          ))}
        </Listbox.Options>
      </Transition>
    </Listbox>
  );
}
export default AddSemanticAxis;
