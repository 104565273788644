import { reformatDateString } from "@/text-parsers";
import { BaseElement, Editor, Node, NodeEntry, Text, Transforms } from "slate";
import { ITiroElement } from "../../Base/types";
import { ITypedElement } from "../../TypedElement";
import {
  FREE_TEXT_ENTRY,
  QA_ENTRY,
  QA_QUESTION,
  SUBSECTION_ENTRY,
  TIME_ENTRY,
  TIME_TIMESTAMP,
} from "./consts";
import { EntryElement } from "./EntryElement";
import {
  FreeTextEntry,
  SubsectionElement,
  SubtitleElement,
  TimeEntryTimestamp,
} from "./types";

export function normalizeSubtitleEntry(
  [, path]: NodeEntry<SubtitleElement>,
  editor: Editor
): boolean | void {
  // make sure every entry-subtitle is wrapped with entry-subsection
  const [parentNode] = Editor.parent(editor, path);
  if (!EntryElement.isSubsection(parentNode)) {
    Transforms.wrapNodes(
      editor,
      { type: SUBSECTION_ENTRY, children: [] } as ITypedElement,
      { at: path }
    );
    return true;
  }

  // make sure every child is inline
  for (const [childNode, childPath] of Node.children(editor, path)) {
    if (Editor.isBlock(editor, childNode)) {
      Transforms.unwrapNodes(editor, { at: childPath });
      return true;
    }
  }
}

export function normalizeSubsectionEntry(
  [node, path]: NodeEntry<SubsectionElement>,
  editor: Editor
): boolean | void {
  // make sure that the first element of a subsection is a subtitle
  if (!EntryElement.isSubtitle(node.children[0])) {
    Transforms.unwrapNodes(editor, {
      at: path,
    });
    return true;
  }

  // make sure other elements aren't a subtitle
  for (const [childNode, childPath] of Node.children(editor, path)) {
    if (childPath.slice(-1)[0] === 0) continue;
    if (EntryElement.isSubtitle(childNode)) {
      Transforms.setNodes(
        editor,
        { type: FREE_TEXT_ENTRY } as Partial<FreeTextEntry>,
        { at: childPath }
      );
      return true;
    }
  }
}

export function normalizeColumnLayoutEntry(
  [node, path]: NodeEntry<ITypedElement>,
  editor: Editor,
  type: typeof TIME_ENTRY | typeof QA_ENTRY | string
): boolean | void {
  // make sure the first node is a qa-question node, else unwrap the node
  const supposedLeftColumnNode = node?.children[0];
  const supposedRightPath = path.concat(0);
  if ([TIME_ENTRY, QA_ENTRY].includes(type)) {
    if (!EntryElement.isLeftColumnEntry(supposedLeftColumnNode)) {
      const newNode =
        type === TIME_ENTRY
          ? { type: TIME_TIMESTAMP, mute: true }
          : { type: QA_QUESTION };
      Transforms.setNodes(editor, newNode as Partial<BaseElement>, {
        at: path,
      });
      return true;
    }
  }
  // if there is only one node, normalize to a free-text-entry
  if (node.children.length === 1) {
    Transforms.setNodes<FreeTextEntry>(
      editor,
      { type: FREE_TEXT_ENTRY } as Partial<FreeTextEntry>,
      { at: supposedRightPath }
    );
    Transforms.unwrapNodes(editor, { at: path });
    return true;
  }

  /*// make sure the second node is a qa-answer node
  const supposedRightColumnNode = node?.children[1];
  if (!EntryElement.isRightColumnEntry(supposedRightColumnNode)) {
    const newNode =
      type === TIME_ENTRY ? { type: TIME_DESCRIPTION } : { type: QA_ANSWER };
    Transforms.setNodes(editor, newNode as Partial<BaseElement>, {
      at: Editor.range(
        editor,
        path.concat(1),
        path.concat(node.children.length - 1)
      ),
    });
    return true;
  }

  if (node.children.length > 2) {
    Transforms.mergeNodes(editor, {
      at: Editor.range(
        editor,
        path.concat(1),
        path.concat(node.children.length - 1)
      ),
    });
    return true;
  }
  */
  // if the qa node contains only whitespaces => remove the structure and replace it by a free-text
  if (Node.string(node).trim().length === 0) {
    Transforms.setNodes(
      editor,
      { type: FREE_TEXT_ENTRY } as Partial<FreeTextEntry>,
      { at: path }
    );
    return true;
  }
}

export function normalizeLeftColumnEntry(
  [, path]: NodeEntry<ITypedElement>,
  editor: Editor
): boolean | void {
  // every child node of qa-question/timestamp entry should be inline
  for (const [childNode, childPath] of Node.children(editor, path)) {
    if (Editor.isBlock(editor, childNode)) {
      if ((childNode as ITiroElement).inline === false) {
        Transforms.unsetNodes(editor, "inline", { at: childPath });
        return;
      }
      Transforms.unwrapNodes(editor, { at: childPath });
      return true;
    }
  }
}

export function normalizeRightColumnEntry(): boolean | void {}

export function normalizeFreeTextEntry(
  [, path]: NodeEntry<FreeTextEntry>,
  editor: Editor
): boolean | void {
  // make sure every child is inline
  for (const [childNode, childPath] of Node.children(editor, path)) {
    if (Editor.isBlock(editor, childNode)) {
      if ((childNode as ITiroElement).inline === false) {
        Transforms.unsetNodes(editor, "inline", { at: childPath });
        return;
      }
      Transforms.unwrapNodes(editor, { at: childPath });
      return true;
    }
  }
}

export function normalizeTimestamp(
  [node, path]: NodeEntry<TimeEntryTimestamp>,
  editor: Editor
): boolean | void {
  if (!node.mute) {
    Transforms.setNodes(editor, { mute: true } as Partial<TimeEntryTimestamp>, {
      at: path,
      match: (n) => n === node,
    });
  }
  for (const [childNode, childPath] of Node.children(editor, path)) {
    if (!Text.isText(childNode)) {
      Transforms.unwrapNodes(editor, { at: childPath });
      return true;
    }
  }
  const currentDate = Node.string(node);
  const formattedDate = reformatDateString(currentDate);
  if (formattedDate) {
    if (!node.isValid) {
      Transforms.setNodes(
        editor,
        { isValid: true } as Partial<TimeEntryTimestamp>,
        { at: path }
      );
      return true;
    }
  }
  if (!formattedDate) {
    if (node.isValid || !("isValid" in node)) {
      Transforms.setNodes(
        editor,
        { isValid: false } as Partial<TimeEntryTimestamp>,
        { at: path }
      );
      return true;
    }
  }
}
