import classNames from "classnames";
import React, { useContext } from "react";
import { assert, func, is } from "superstruct";
import {
  BadgeStyleContext,
  BadgeModeContext,
  BadgeSwitchModeContext,
  BadgeAutoFocusContext,
  BadgeColorContext,
} from "./context";

type PickAttrs = "prefix" | "suffix";
interface BadgeAttrs {
  prefix?: React.ReactChild | null; // string, int, or component
  suffix?: React.ReactChild | null;
}
export const ViewBadgeFix = ({
  className,
  ...props
}: React.HTMLProps<HTMLSpanElement>) => (
  <span className={classNames("inline-flex px-1", className)} {...props} />
);

type ViewBadgeChildren =
  | React.ReactNode
  | ((props: {
      mode: "edit" | "view";
      style: "tile" | "table" | "default";
      autoFocus: boolean;
    }) => React.ReactNode);

export type ViewBadgeProps = BadgeAttrs &
  Omit<React.ButtonHTMLAttributes<HTMLButtonElement>, PickAttrs> & {
    children: ViewBadgeChildren;
  };

function ViewBadge({
  prefix,
  suffix,
  children,
  className,
  ...attributes
}: ViewBadgeProps) {
  const style = useContext(BadgeStyleContext);
  const color = useContext(BadgeColorContext);
  const mode = useContext(BadgeModeContext);
  const autoFocus = useContext(BadgeAutoFocusContext);
  const switchMode = useContext(BadgeSwitchModeContext);
  if (!switchMode)
    throw new Error(
      "ViewBadge must be used within a BadgeSwitchModeContext.Provider"
    );
  if (mode == "edit") return null;
  return (
    <button
      type="button"
      onClick={() => switchMode("edit")}
      data-testid="view-badge"
      className={classNames(
        "mr-px inline-flex min-w-max items-baseline rounded-[0.25rem] border border-transparent px-3 py-1.5 font-medium leading-3 ",
        {
          "text-sm": style !== "tile",
          "text-xl": style === "tile",
          "text-blue-dark hover:border-blue-primary": color == "blue",
          "text-emerald-700 hover:border-emerald-500": color == "green",
          "bg-blue-ultralight": style !== "table" && color === "blue",
          "bg-emerald-100/90": style !== "table" && color === "green",
          "h-full w-full border-white bg-white": style === "table",
        },
        className
      )}
      {...attributes}
    >
      {prefix && <ViewBadgeFix>{prefix}</ViewBadgeFix>}
      {is(children, func()) ? children({ mode, style, autoFocus }) : children}
      {suffix && <ViewBadgeFix>{suffix}</ViewBadgeFix>}
    </button>
  );
}

ViewBadge.Fix = ViewBadgeFix;

export default ViewBadge;
