/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type SubjectReference = {
    reference: string;
    type?: SubjectReference.type;
    identifier?: null;
    display?: (string | null);
    id?: (number | null);
};

export namespace SubjectReference {

    export enum type {
        PATIENT = 'Patient',
    }


}

