export * from "./report-client";
import { CONFIG } from "@/config";
import { getTenantAuth } from "@/services/tenant";
import { ReportClient } from "./report-client";

export const getHeaders = async (): Promise<Record<string, string>> => {
  // Retrieve the current user's ID token
  const user = getTenantAuth().currentUser;
  const idToken = await user?.getIdToken(); // no refresh here! Refresh is handled by useAuthUser
  if (idToken) return { Authorization: `Bearer ${idToken}` };
  return {};
  // keep in mind that number of calls is limited by Firebase
};

/**
 * Create a new instance of the ReportClient
 */
const client = new ReportClient({
  BASE: CONFIG.REPORT_SERVER,
  HEADERS: getHeaders,
});

export default client;
