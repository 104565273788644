import { QueryClient } from "react-query";
import { LoaderFunction } from "react-router";
import { createReportQuery } from "@/services/reports/useReport";
import { z } from "zod";

export const CURRENT_REPORT_ROUTE_ID = "current-report";

export default (client: QueryClient): LoaderFunction =>
  async ({ params }): Promise<null> => {
    const reportId = z.coerce.number().parse(params.reportId);
    client.prefetchQuery(
      createReportQuery(reportId, { blocksEmbedded: false })
    );
    return null;
  };
