import { Argument } from "classnames";
import { Editor, Node } from "slate";
import { ReactEditor, useSlateStatic } from "slate-react";
import { ITiroElement } from "../Base/types";

const FORMAT_TO_CLASSES = new Map(
  Object.entries({
    "heading-one": "text-xl font-bold leading-8",
    "heading-two": "text-lg font-semibold",
    bold: "font-semibold",
  }),
);

export const useFormattingClassnames = (node: Node): Argument => {
  const editor = useSlateStatic() as ReactEditor;
  let { format } = node as ITiroElement;
  if (format) return FORMAT_TO_CLASSES.get(format);
  const path = ReactEditor.findPath(editor, node);
  const [blockEntry] = Editor.nodes<ITiroElement>(editor, {
    at: path,
    match: (n) => Editor.isBlock(editor, n),
  });
  if (!blockEntry) return "prose-tiro";
  format = blockEntry[0].format;
  if (!format) return "";
  return FORMAT_TO_CLASSES.get(format);
};

export default useFormattingClassnames;
