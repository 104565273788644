import { ITiroQuantity } from "@/data-models/value-models";
import { useEffect } from "react";
import { Element, Node, Transforms } from "slate";
import {
  ReactEditor,
  useFocused,
  useSelected,
  useSlateStatic,
} from "slate-react";
import { reformatQuantity } from "@/text-parsers";

export const useQuantityReformatter = <T extends Pick<ITiroQuantity, "unit">>(
  element: Element,
  quantity: T,
  options?: { enabled?: boolean }
) => {
  const { enabled = true } = options ?? {};
  const selected = useSelected();
  const focused = useFocused();
  const active = selected && focused;
  const editor = useSlateStatic() as ReactEditor;
  useEffect(() => {
    if (!enabled) return;
    if (!active) {
      const path = ReactEditor.findPath(editor, element);
      const currentText = Node.string(element);
      const newText = reformatQuantity(currentText, quantity);
      if (newText) {
        const { selection } = editor;
        Transforms.insertText(editor, newText, { at: path });
        selection && Transforms.setSelection(editor, selection);
      }
    }
  }, [active, enabled, editor, element, quantity]);
};
