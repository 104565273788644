import NavBar from "@/components/navbar/TemplateEditorNav";
import { QueryClient } from "react-query";
import { Outlet, RouteObject } from "react-router";
import TemplateEditor from "./TemplateEditor";
import createProtector, {
  PROTECTOR_CONFIG_DEFAULTS,
} from "../auth/createProtector";

export default function createRoute(queryClient: QueryClient): RouteObject {
  return {
    loader: createProtector({ ...PROTECTOR_CONFIG_DEFAULTS, queryClient }),
    path: "templates",
    element: <Layout />,
    children: [
      {
        element: <TemplateEditor />,
        path: ":templateId",
      },
    ],
  };

  function Layout() {
    return (
      <div className="relative h-screen w-screen">
        <NavBar />
        <Outlet />
      </div>
    );
  }
}
