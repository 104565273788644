import { useMutation, UseMutationOptions, useQueryClient } from "react-query";
import client, { PatientIn, PatientOut } from "./client";
import keyFactory from "./keyFactory";
import { ApiError } from "./report-client";

function useNewPatient(
  options?: UseMutationOptions<PatientOut, ApiError, PatientIn, unknown>
) {
  const queryClient = useQueryClient();
  const { onSuccess: providedOnSuccess, ...rest } = options || {};
  return useMutation(
    (patient: PatientIn) =>
      client.v1.createPatientV1({
        requestBody: patient,
      }),
    {
      ...rest,
      onSuccess(patient, variables, context) {
        queryClient.setQueryData(keyFactory.patient(patient.id), patient);
        queryClient.setQueryData<PatientOut[]>(
          keyFactory.allPatients(),
          (old) => (old ? [patient, ...old] : [patient])
        );
        queryClient.invalidateQueries(keyFactory.allPatients());
        providedOnSuccess && providedOnSuccess(patient, variables, context);
      },
    }
  );
}

export default useNewPatient;
