import { ValueSetMeta } from "@/components/controlled/ValueSetInput/ValueSetInputv2";
import { DATE_MODEL } from "@/components/inputs/DateInput/utils";
import { IConcept } from "@/data-models/concept";
import { TiroDescendant } from "@/data-models/nodes";
import { ICoding } from "@/data-models/value-models";

import { Describe, literal, type, union } from "superstruct";

export const COLUMN_TYPES = [
  "TEXT",
  "DATE",
  "PERIOD",
  "DATE_OR_PERIOD",
  "DROPDOWN",
  "QUANTITY",
  "MARKDOWN",
] as const;
export type ColumnType = (typeof COLUMN_TYPES)[number];

export interface BaseColumn {
  width?:
    | "auto"
    | `${number}fr`
    | "min-content"
    | "max-content"
    | "fit-content";
  code: IConcept;
  type: ColumnType;
  id: string;
}

export interface DraftColumn {
  code: null;
  type: ColumnType;
}

export interface DateColumn extends BaseColumn {
  type: "DATE";
}
export interface PeriodColumn extends BaseColumn {
  type: "PERIOD";
  startDateRequired?: boolean;
  endDateRequired?: boolean;
}
export interface DateOrPeriodColumn extends BaseColumn {
  type: "DATE_OR_PERIOD";
  required?: boolean;
}
export interface QuantityColumn extends BaseColumn {
  type: "QUANTITY";
  unit: IConcept;
}
export interface DropdownColumn extends BaseColumn {
  type: "DROPDOWN";
  valueSet?: ValueSetMeta;
  options?: ICoding[];
}
export interface MarkdownColumn extends BaseColumn {
  type: "MARKDOWN";
}
export type TemplateRow<TColumns extends Column[] = Column[]> = {
  row: Row<TColumns>;
  name?: string;
};

export type TableInstance<TColumns extends Column[] = Column[]> = {
  columns: TColumns;
  template?: TemplateRow<TColumns>[];
  data: Row<TColumns>[];
};

export interface TextColumn extends BaseColumn {
  type: "TEXT";
  placeholder?: string;
  mode?: "data-entry" | "content-editable";
  className?: string;
}

export type Column<T extends ColumnType = ColumnType> = T extends "DATE"
  ? DateColumn
  : T extends "PERIOD"
    ? PeriodColumn
    : T extends "DATE_OR_PERIOD"
      ? DateOrPeriodColumn
      : T extends "QUANTITY"
        ? QuantityColumn
        : T extends "DROPDOWN"
          ? DropdownColumn
          : T extends "TEXT"
            ? TextColumn
            : T extends "MARKDOWN"
              ? MarkdownColumn
              : never;

export type Content<T extends ColumnType> = T extends "DATE"
  ? DateContent
  : T extends "PERIOD"
    ? PeriodContent
    : T extends "DATE_OR_PERIOD"
      ? DateContent | PeriodContent
      : T extends "QUANTITY"
        ? Quantity
        : T extends "DROPDOWN"
          ? DropdownContent
          : T extends "TEXT"
            ? TiroDescendant[]
            : T extends "MARKDOWN"
              ? MarkdownContent
              : never;

export interface PeriodContent {
  start: DateOrEmpty;
  end: DateOrEmpty;
}

export const DateContentModel: Describe<DateContent> = union([
  DATE_MODEL,
  literal(""),
]);
export const PeriodContentModel: Describe<PeriodContent> = type({
  start: union([DATE_MODEL, literal("")]),
  end: union([DATE_MODEL, literal("")]),
});

export interface Quantity {
  value: NumberOrEmpty; // <= is recorded in the table cell
  unit: string; // <= is specified during "add new column" procedure with a html input field
  code: string; // <= in the future this will be given by the Content Engine
  system: string; // <= in the future this will be given by the Content Engine
}

export type DateOrEmpty = string;
export type DateContent = DateOrEmpty;
export type NumberOrEmpty = number | null;
export type DropdownContent = IConcept | null;
export type MarkdownContent = string | null;

/**
 * This typescript construction maps over the column types, extracts the id, type pairs and creates a record with those pairs.
 */
export type Row<TColumns extends Column[] = Column[]> = {
  //Content<Extract<TColumns[number]["type"], { id: K }>>
  [K in TColumns[number]["id"]]: any;
};
