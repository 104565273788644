import classNames from "classnames";
import { useMemo } from "react";
import {
  FieldValues,
  useController,
  UseControllerProps,
} from "react-hook-form";
import { Descendant } from "slate";
import { Slate } from "slate-react";
import { CellProps } from "@/components/table-v2/cells/Cells";
import SuggestEditable from "@/editor/legacy/Suggestions/SuggestEditable";
import { PathsMatching } from "@/util/pathsMatching";
import createTextCellEditor from "./createTextCellEditor";
import { RenderPlaceholder } from "./RenderPlaceholder";

type SlatePath<TFieldValues extends FieldValues> = PathsMatching<
  TFieldValues,
  Descendant[]
>;
export interface TextCellProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends SlatePath<TFieldValues> = SlatePath<TFieldValues>,
> extends UseControllerProps<TFieldValues>,
    Omit<CellProps<"TEXT">, "type"> {
  name: TName;
  placeholder?: string;
  className?: string;
}

const STYLES = {
  focused:
    "focus-within:border rounded-md focus-within:border-blue-primary focus-within:ring-0",
};
const DEFAULT_VALUE = [{ children: [{ text: "" }] }];
export default function TextCell<
  TFieldValues extends FieldValues = FieldValues,
>({
  name,
  rules,
  control,
  shouldUnregister,
  defaultValue,
  column,
  placeholder = "...",
}: TextCellProps<TFieldValues>) {
  const {
    field: { value = DEFAULT_VALUE, onChange, onBlur },
  } = useController<TFieldValues>({
    name,
    defaultValue,
    shouldUnregister,
    rules,
    control,
  });

  const editor = useMemo(() => createTextCellEditor(), []);
  return (
    <Slate editor={editor} value={value} onChange={onChange}>
      <SuggestEditable
        className={classNames(
          "w-full rounded-md border border-white px-2 py-1",
          column.mode != "data-entry" && STYLES.focused,
        )}
        renderPlaceholder={RenderPlaceholder}
        placeholder={placeholder}
        plugins={editor.plugins}
        editableClassname="focus:outline-none !whitespace-pre"
        onBlur={onBlur}
        readOnly={column.mode == "data-entry"}
      />
    </Slate>
  );
}
