import { RenderConceptInputElementProps } from ".";
import InlineChromiumBugfix from "@/components/InlineChromiumBugFix";
import { SettingsPanelPortal } from "@/components/layout/SettingsPanel";
import { Transition } from "@headlessui/react";
import PropertiesForm from "./PropertiesForm";
import useSlateInputElement from "@/editor/utils/useSlateInputElement";
import ValueSetChipInput from "@/components/inputs/ChipInput/ValueSetChipInput";
function RenderChipInputElement({
  element,
  attributes,
  children,
}: RenderConceptInputElementProps) {
  const { name, children: _, type, inputType, ...other } = element;
  const { update, showProperties, disabled, active, initialValue } =
    useSlateInputElement(element);
  return (
    <span {...attributes} tabIndex={-1} className="mx-0.5">
      <InlineChromiumBugfix />
      <ValueSetChipInput
        defaultValue={initialValue}
        disabled={disabled}
        active={active}
        name={name}
        {...other}
      />
      <InlineChromiumBugfix />
      {children}
      <SettingsPanelPortal>
        <Transition show={showProperties} enter="ease-in-out duration-75">
          <PropertiesForm
            actionLabel="Update dropdown"
            onSubmit={update}
            defaultValues={element}
          />
        </Transition>
      </SettingsPanelPortal>
    </span>
  );
}
export default RenderChipInputElement;
