import {
  TableInstance,
  DateColumn,
  DropdownColumn,
  QuantityColumn,
  Row,
  TextColumn,
  PeriodColumn,
  DateOrPeriodColumn,
  MarkdownColumn,
} from "./types";
import {
  any,
  array,
  Describe,
  record,
  string,
  type,
  literal,
  union,
} from "superstruct";
import { ConceptModel } from "@/data-models/concept";
import { ValueSetModel } from "@/data-models/valueset";

export const DateColumnModel: Describe<DateColumn> = type({
  id: string(),
  code: ConceptModel,
  type: literal("DATE"),
});
export const PeriodColumnModel: Describe<PeriodColumn> = type({
  id: string(),
  code: ConceptModel,
  type: literal("PERIOD"),
});
export const DateOrPeriodColumnModel: Describe<DateOrPeriodColumn> = type({
  id: string(),
  code: ConceptModel,
  type: literal("DATE_OR_PERIOD"),
});
export const DropdownColumnModel: Describe<DropdownColumn> = type({
  id: string(),
  code: ConceptModel,
  type: literal("DROPDOWN"),
  valueSet: ValueSetModel,
});
export const TextColumnModel: Describe<TextColumn> = type({
  id: string(),
  code: ConceptModel,
  type: literal("TEXT"),
});
export const QuantityColumnMode: Describe<QuantityColumn> = type({
  id: string(),
  code: ConceptModel,
  type: literal("QUANTITY"),
  unit: ConceptModel,
});

export const MarkdownColumnModel: Describe<MarkdownColumn> = type({
  id: string(),
  code: ConceptModel,
  type: literal("MARKDOWN"),
});
export const ColumnModel = union([
  DateColumnModel,
  PeriodColumnModel,
  DateOrPeriodColumnModel,
  DropdownColumnModel,
  TextColumnModel,
  QuantityColumnMode,
  MarkdownColumnModel,
]);
export const RowModel: Describe<Row> = record(string(), any());
export const TableModel: Describe<TableInstance> = type({
  columns: array(ColumnModel),
  data: array(RowModel),
});
