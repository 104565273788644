import { RouteObject } from "react-router";
import { SMART_REDIRECT_PATH } from "./consts";
import loader from "./loader";
import SMARTRedirectData from "./RedirectData";

export default (): RouteObject => ({
  path: SMART_REDIRECT_PATH,
  element: <SMARTRedirectData />,
  loader,
});
