import { QueryClient } from "react-query";
import { RouteObject } from "react-router";
import { PROFILE_SUBPATH } from "./consts";
import createAction, { ACTION_CONFIG_DEFAULTS } from "./createAction";
import createLoader, { LOADER_CONFIG_DEFAULTS } from "./createLoader";
import UserDataError from "./Error";
import ProfileForm from "./ProfileForm";

export default (queryClient: QueryClient): RouteObject => ({
  path: PROFILE_SUBPATH,
  action: createAction({ queryClient, ...ACTION_CONFIG_DEFAULTS }),
  loader: createLoader({ queryClient, ...LOADER_CONFIG_DEFAULTS }),
  element: <ProfileForm />,
  errorElement: <UserDataError />,
});
