import { CONFIG } from "@/config";
import { createContext, useContext } from "react";

export type FHIREndpoint = {
  baseURL: string;
  headers: Record<string, string>;
};
export const DEFAULT_FHIR_ENDPOINT: FHIREndpoint = {
  baseURL: CONFIG.fhirURL,
  headers: { accept: "application/fhir+json" },
};

export const FHIREndpointContext = createContext<FHIREndpoint | null>(
  DEFAULT_FHIR_ENDPOINT
);

export function useFHIREndpoint() {
  const endpoint = useContext(FHIREndpointContext);
  if (!endpoint) throw new Error("No FHIR endpoint found in context");
  return endpoint;
}
