import Tippy from "@tippyjs/react";
import { Row } from "./types";
import React, { useRef } from "react";
import { useDrag, useDrop } from "react-dnd";
import { UseFieldArrayMove } from "react-hook-form";

interface RowsProps<TRow extends Row> {
  children: React.ReactNode;
  rowIndex: number;
  moveRow: UseFieldArrayMove;
  removeRow: (index: number) => void;
  defaultValues?: TRow;
}
const DND_ITEM_TYPE = "row";

interface DragAbleItem {
  type: typeof DND_ITEM_TYPE;
  rowIndex: number;
}

const Rows = <TRow extends Row = Row>({
  children,
  rowIndex,
  moveRow,
  removeRow,
}: RowsProps<TRow>) => {
  const dropRef = useRef<HTMLDivElement>(null);

  const [, drop] = useDrop<DragAbleItem>(
    () => ({
      accept: DND_ITEM_TYPE,
      hover(item, monitor) {
        if (!dropRef.current) {
          return;
        }
        const dragIndex = item.rowIndex;
        const hoverIndex = rowIndex;
        if (dragIndex === hoverIndex) {
          return;
        }
        // Determine rectangle on screen
        const hoverBoundingRect = dropRef.current.getBoundingClientRect();
        // Get vertical middle
        const hoverMiddleY =
          (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
        // Determine mouse position
        const clientOffset = monitor.getClientOffset();
        // Get pixels to the top
        const hoverClientY = clientOffset!.y - hoverBoundingRect.top;
        // Only perform the move when the mouse has crossed half of the items height
        // When dragging downwards, only move when the cursor is below 50%
        // When dragging upwards, only move when the cursor is above 50%
        // Dragging downwards
        if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
          //return
        }
        // Dragging upwards
        if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
          //return
        }
        // Time to actually perform the action
        moveRow(dragIndex, hoverIndex);

        // Note: we're mutating the monitor item here!
        // Generally it's better to avoid mutations,
        // but it's good here for the sake of performance
        // to avoid expensive index searches.
        item.rowIndex = hoverIndex;
      },
    }),
    [rowIndex]
  );

  const [_, drag, preview] = useDrag<
    DragAbleItem,
    unknown,
    { isDragging: boolean }
  >(
    () => ({
      type: DND_ITEM_TYPE,
      item: { type: DND_ITEM_TYPE, rowIndex },

      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
    }),
    [rowIndex]
  );
  preview(drop(dropRef));

  return (
    // Row
    <div ref={preview} className="group contents">
      <div ref={dropRef} className="contents">
        <div className="flex h-full items-center justify-start border-b border-r border-gray-light p-1 text-gray-light group-last:border-b-0">
          <Tippy content="Verwijder deze rij" delay={[300, 50]}>
            <button
              type="button"
              className="relative flex h-5 w-5 items-center hover:text-gray-primary"
              tabIndex={-1}
              onClick={() => removeRow(rowIndex)}
            >
              <span className="material-symbols-outlined text-justify align-middle text-lg">
                delete
              </span>
            </button>
          </Tippy>
          <Tippy content="Verplaats deze rij" delay={[300, 50]}>
            <button
              ref={drag}
              type="button"
              tabIndex={-1}
              className="relative flex h-5 w-5 items-center hover:text-gray-primary"
            >
              <span className="material-icons cursor-grab text-justify align-middle text-lg">
                drag_indicator
              </span>
            </button>
          </Tippy>
        </div>
      </div>
      {/* row data */}
      {children}
    </div>
  );
};

export default Rows;
