import React from "react";
import {
  FieldArrayPath,
  FieldValues,
  useFieldArray,
  UseFieldArrayProps,
  UseFieldArrayReturn,
} from "react-hook-form";

function FieldArray<
  TFieldValues extends FieldValues = FieldValues,
  TFieldArrayName extends FieldArrayPath<TFieldValues> = FieldArrayPath<TFieldValues>,
  TKeyName extends string = "id"
>({
  children: childrenFunc,
  ...props
}: UseFieldArrayProps<TFieldValues, TFieldArrayName, TKeyName> & {
  children: (
    props: UseFieldArrayReturn<TFieldValues, TFieldArrayName, TKeyName>
  ) => React.ReactNode;
}) {
  const result = useFieldArray<TFieldValues, TFieldArrayName, TKeyName>(props);
  return <>{childrenFunc(result)}</>;
}
export default FieldArray;
