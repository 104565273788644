import { TypedElement } from "../../TypedElement";
import { BADGE_ELEMENT } from "./consts";
import { IAbstractEntityElement } from "../Entities/types";
import { IQuantity } from "@/data-models/quantity-entity";
import { IDateEntity } from "@/data-models/date-entity";
import { IDateTimeEntity } from "@/data-models/datetime-entity";
import { ITimeEntity } from "@/data-models/time-entity";

export interface IBadgeElement extends IAbstractEntityElement {
  readonly type: typeof BADGE_ELEMENT;
  entity: IQuantity | IDateEntity | IDateTimeEntity | ITimeEntity;
  children: [{ text: "" }];
}

const BadgeElement = {
  ...TypedElement,
  isBadgeElement: (o: any): o is IBadgeElement =>
    BadgeElement.isTypedElement(o) && o.type === BADGE_ELEMENT,
};

export default BadgeElement;
