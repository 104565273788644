import { Editor, Node, NodeEntry } from "slate";
import { DataFieldElement } from ".";
import { normalizeDateField, normalizeQuantityField } from "./normalisation";

export const withDataFields = <T extends Editor = Editor>(
  editor: T
): T & Editor => {
  const { normalizeNode } = editor;

  editor.normalizeNode = (entry: NodeEntry<Node>) => {
    const [node, path] = entry;

    if (DataFieldElement.isDateField(node)) {
      if (normalizeDateField(editor, [node, path])) return;
    }

    if (DataFieldElement.isQuantityField(node)) {
      if (normalizeQuantityField(editor, [node, path])) return;
    }
    return normalizeNode(entry);
  };
  return editor;
};
