import { ALL_PATIENTS_PATH } from "@/pages/patients/const";
import { redirect } from "react-router";

/**
 * Jump to auth flow while preserving current path in a 'redirect' query param.
 * Keep all other query params.
 */
export function jumpToAuthFlow(fromURL: string | URL, path: string) {
  const url = new URL(fromURL);
  const queryParams = new URLSearchParams(url.search);
  queryParams.set("redirect", url.pathname);
  const continueURL = new URL(path, url.origin);
  continueURL.search = queryParams.toString();
  return redirect(continueURL.href);
}

/**
 * Redirect to a path and preserve the existing query params.
 */
export function redirectAndKeepQueryParams(fromURL: string | URL, to: string) {
  const url = new URL(fromURL);
  const queryParams = new URLSearchParams(url.search);
  const continueURL = new URL(to, url.origin);
  continueURL.search = queryParams.toString();
  return redirect(continueURL.href);
}

/**
 * Jump back to the path specified in the 'redirect' query param.
 * Remove the 'redirect' query param.
 */
export function jumpBack(fromURL: string | URL) {
  const url = new URL(fromURL);
  const queryParams = new URLSearchParams(url.search);
  const to = queryParams.get("redirect");
  const continueURL = new URL(to || `/${ALL_PATIENTS_PATH}`, url.origin);
  queryParams.delete("redirect");
  continueURL.search = queryParams.toString();
  console.debug("Jumping back to", continueURL.href);
  return redirect(continueURL.href);
}
