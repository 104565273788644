import { IEditorPlugin } from "../../Base/types";
import RenderTitleElement from "./RenderTitleElement";
import TitleElement from "./TitleElement";

const BlockLayoutPlugin: IEditorPlugin = {
  renderElement(props) {
    const { element } = props;
    if (TitleElement.isTitleElement(element)) {
      return <RenderTitleElement {...props} element={element} />;
    }
  },
};

export default BlockLayoutPlugin;
