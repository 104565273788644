/**
 * Check if the current environment is a WebView2.
 */
export function isWebView2() {
  try {
    return !!window.chrome?.webview;
  } catch (e) {
    return false;
  }
}

export async function legacy__setFHIRDocument(bundle: Record<string, unknown>) {
  if (!isWebView2()) {
    console.error("No WebView2 host, dropping message.", bundle);
    return;
  }
  const bridge = await window.chrome?.webview.hostObjects.bridge;
  bridge?.setFHIRDocument(JSON.stringify(bundle));
}
