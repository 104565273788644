import { DragEventHandler, KeyboardEvent, useCallback, useEffect } from "react";
import { Editable, ReactEditor, useSlateStatic } from "slate-react";
import { EditableProps } from "slate-react/dist/components/editable";
import { IEditorPlugin } from "./types";
import Editor, { ITiroEditor } from "./editor";
import { isEventHandled } from "../util";
import { HotKeys } from "../hotkeys";
import { useTiroRenderElement, useTiroRenderLeaf } from "./renderHooks";
import { DOMNode } from "slate-react/dist/utils/dom";

const TiroEditable = ({
  plugins = [],
  onKeyDown,
  autoFocus,
  renderElement: providedRenderElement,
  renderLeaf: providedRenderLeaf,
  ...props
}: EditableProps & { plugins?: IEditorPlugin[] }) => {
  const editor = useSlateStatic() as ITiroEditor;
  const handleDrop = useCallback<DragEventHandler<DOMNode>>(
    (event) => {
      /** workaround because Slate handles drop events by default. If we have voids using dnd this creates trouble.
       * https://github.com/ianstormtaylor/slate/issues/4888
       */
      if (event.target) return;
      const node = ReactEditor.toSlateNode(editor, event.target);
      const path = ReactEditor.findPath(editor, node);
      const voidMatch =
        Editor.isVoid(editor, node) ||
        Editor.void(editor, {
          at: path,
          voids: true,
        });
      const disableSlateOnDrop = voidMatch ? true : null;
      return disableSlateOnDrop;
    },
    [editor]
  );
  const handleKeyDown = useCallback(
    (e: KeyboardEvent<HTMLDivElement>) => {
      for (const plugin of plugins) {
        const { handleKeyDown } = plugin;
        !!handleKeyDown && handleKeyDown(e, editor);
      }
      const { selection } = editor;

      if (ReactEditor.isFocused(editor) && !!selection) {
        if (!isEventHandled(e) && HotKeys.isTab(e)) {
          if (Editor.tabFocus(editor, { reverse: e.shiftKey }))
            e.preventDefault();
        }
      }
      onKeyDown && onKeyDown(e);
    },
    [editor, onKeyDown, plugins]
  );
  useEffect(() => {
    if (autoFocus) {
      const timer = setTimeout(() => ReactEditor.focus(editor as ReactEditor));
      return () => clearTimeout(timer);
    }
  }, [autoFocus, editor]);
  useEffect(() => Editor.normalize(editor), [editor]);

  const renderElement = useTiroRenderElement(plugins);
  const renderLeaf = useTiroRenderLeaf(plugins);
  return (
    <Editable
      onKeyDown={handleKeyDown}
      onDrop={handleDrop}
      renderElement={providedRenderElement ?? renderElement}
      renderLeaf={providedRenderLeaf ?? renderLeaf}
      {...props}
    />
  );
};
export default TiroEditable;
