import { IIdentifier } from "./types";

/**
 * Identifier class for creating and parsing identifiers
 */
class Identifier implements IIdentifier {
  system: string;
  value: string;
  constructor({ system, value }: IIdentifier) {
    this.system = system;
    this.value = value;
  }

  toToken() {
    return `${this.system}|${this.value}`;
  }

  static fromToken(token: string, defaultSystem: string | null) {
    const [value, system = defaultSystem] = token.split("|").reverse();
    if (!system) {
      throw new Error("Unable to parse identifier from token");
    }
    return new Identifier({ system, value });
  }
}

export default Identifier;
