import { Expression } from "../types";

export interface IFHIRQueryExpression extends Expression {
  language: "application/x-fhir-query";
}

export class FHIRQueryExpressionCompiler {
  constructor() {}

  static isFHIRQueryExpression(
    expression: Expression,
  ): expression is IFHIRQueryExpression {
    return expression.language === "application/x-fhir-query";
  }

  compile(expression: IFHIRQueryExpression): string {
    return expression.expression;
  }

  getKey(expression: IFHIRQueryExpression): string {
    return expression.name + expression.expression + expression.language;
  }
}
