import { ReactEditor, Slate } from "slate-react";
import { Descendant } from "slate";
import SuggestEditable, {
  SuggestEditableProps,
} from "@/editor/legacy/Suggestions/SuggestEditable";
import { ITiroEditor } from "@/editor/Base/editor";
import { ISuggestEditor } from "@/editor/legacy/Suggestions/SuggestEditor";
import { ITiroElement } from "@/data-models/nodes";

export interface SlateSuggestEditorProps<
  N extends ITiroElement[] = ITiroElement[],
  T extends ITiroEditor & ReactEditor & ISuggestEditor = ITiroEditor &
    ReactEditor &
    ISuggestEditor,
> extends Omit<SuggestEditableProps, "value" | "plugins"> {
  editor: T;
  value: N;
  setValue?: (s: N) => void;
}

export const SlateSuggestEditor = <
  N extends ITiroElement[] = ITiroElement[],
  T extends ITiroEditor & ReactEditor & ISuggestEditor = ITiroEditor &
    ReactEditor &
    ISuggestEditor,
>({
  editor,
  value,
  setValue,
  children,
  ...otherProps
}: SlateSuggestEditorProps<N, T>) => {
  const { plugins } = editor;

  const handleChange = (value: Descendant[]) => {
    setValue && setValue(value as N);
  };

  return (
    <Slate editor={editor} value={value} onChange={handleChange}>
      {children}
      <SuggestEditable {...otherProps} plugins={plugins} />
    </Slate>
  );
};

export default SlateSuggestEditor;
