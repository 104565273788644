import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { mergeRegister } from "@lexical/utils";
import { BLUR_COMMAND, COMMAND_PRIORITY_LOW, FOCUS_COMMAND } from "lexical";
import React, { useEffect, useState } from "react";

const useEditorFocus = () => {
  const ref = React.useRef<HTMLDivElement>(null);
  const [editor] = useLexicalComposerContext();
  // Possibly use useRef for synchronous updates but no re-rendering effect
  const [hasFocus, setFocus] = useState(false);

  useEffect(
    () =>
      mergeRegister(
        editor.registerCommand(
          BLUR_COMMAND,
          ({ relatedTarget }) => {
            if (
              ref.current &&
              relatedTarget instanceof HTMLElement &&
              ref.current.contains(relatedTarget)
            ) {
              return true;
            }
            setFocus(false);
            return false;
          },
          COMMAND_PRIORITY_LOW,
        ),
        editor.registerCommand(
          FOCUS_COMMAND,
          () => {
            setFocus(true);
            return false;
          },
          COMMAND_PRIORITY_LOW,
        ),
      ),
    [editor],
  );

  return [hasFocus, ref] as const;
};
export default useEditorFocus;
