import React from "react";
import { Infer } from "superstruct";
import { CalendarIcon } from "@heroicons/react/24/outline";
import Tippy from "@tippyjs/react";
import { DateTimeEntityModel } from "@/data-models/datetime-entity";
import format from "date-fns/format";
import { nlBE } from "date-fns/locale";

export interface IDateTimeEntityTooltipProps {
  entity: Infer<typeof DateTimeEntityModel>;
  children: React.ReactElement;
}

const DateTimeEntityTooltip = ({
  entity,
  children,
}: IDateTimeEntityTooltipProps) => {
  const { datetime } = entity;
  if (!datetime) return children;
  const dateStr = format(new Date(datetime), "PPPPpppp", { locale: nlBE });
  return (
    <Tippy
      delay={[500, 50]}
      interactive
      content={
        <div>
          <div className="items-bottom my-1 flex w-full justify-center font-light">
            <CalendarIcon className="-mb-1 mr-1 inline h-4 w-4 stroke-current" />
            <span className="whitespace-pre font-normal sm:text-sm">
              {dateStr}
            </span>
          </div>
        </div>
      }
    >
      {children}
    </Tippy>
  );
};
export default DateTimeEntityTooltip;
