import { useCallback, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";

export const useURLParams = () => {
  const { search } = useLocation();
  const params = useMemo(() => new URLSearchParams(search), [search]);
  return params;
};

export const useURLParamsState = () => {
  const { search, pathname } = useLocation();
  const navigate = useNavigate();
  const params = useMemo(() => new URLSearchParams(search), [search]);
  const setParams = useCallback(
    (newParams: Record<string, string | null>) => {
      const url = new URL(window.location.href);
      const search = url.searchParams;
      Object.entries(newParams).forEach(([k, v]) =>
        v ? search.set(k, v) : search.delete(k)
      );
      navigate({ pathname, search: search.toString() });
    },
    [pathname, navigate]
  );
  return [params, setParams] as const;
};
