import CodingDropdownInput from "@/components/inputs/Dropdown/CodingDropdownInput";
import {
  codingToConcept,
  conceptAsCoding,
} from "@/data-models/value-models/types";
import { CellProps } from "./Cells";

export interface DropdownCellProps
  extends Omit<CellProps<"DROPDOWN">, "type"> {}

const DropdownCell = ({ column, name }: DropdownCellProps) => {
  return (
    <CodingDropdownInput
      name={name}
      valueSet={column.valueSet?.url}
      options={column.options}
      transform={CODEABLECONCEPT_TRANSFORM}
    />
  );
};

const CODEABLECONCEPT_TRANSFORM = {
  input: conceptAsCoding,
  output: codingToConcept,
};

export default DropdownCell;
