import Tippy from "@tippyjs/react";
import classNames from "classnames";
import parse from "date-fns/parse";
import { useCurrentSubject } from "@/pages/patients/createCurrentSubjectLoader";
import React, { HTMLAttributes, Ref } from "react";
import { useTranslation } from "react-i18next";
import { calculateAge } from "@/util/date";

//TODO: Make story

interface SubjectBadgeProps extends HTMLAttributes<HTMLDivElement> {}

const SubjectBadgeWithoutRef = (
  props: SubjectBadgeProps,
  ref: Ref<HTMLDivElement>,
) => {
  const { t } = useTranslation();
  const { className, ...otherProps } = props;
  const subject = useCurrentSubject();
  const birthDate =
    subject.birthDate && parse(subject.birthDate, "yyyy-MM-dd", new Date());
  const identifierDisplay =
    subject.identifier?.[0].value ?? subject.id.toString();
  return (
    <div
      ref={ref}
      className={classNames(className, "relative")}
      {...otherProps}
    >
      <div className="relative flex h-9 items-stretch divide-x divide-[#DFEBF3] rounded-md border border-[#DFEBF3] bg-white font-primary text-primary">
        <div className="relative flex items-center space-x-2 px-5">
          <span className="whitespace-pre-line font-semibold sm:text-sm">
            {t("patient")}
          </span>
          <Tippy content={subject?.id}>
            <code className="max-w-[100px] truncate rounded-md border border-gray-200 bg-blue-50 px-0.5 py-px font-mono text-xs">
              {identifierDisplay ?? " - "}
            </code>
          </Tippy>
        </div>
        <div className="relative flex items-center space-x-2 px-5">
          <span className="whitespace-pre-line font-normal sm:text-sm">
            {t("leeftijd")}: {birthDate ? calculateAge(birthDate) : " - "}
          </span>
        </div>
        <div className="relative flex items-center space-x-2 px-5">
          <span className="whitespace-pre-line font-normal sm:text-sm">
            {t("geslacht")}:{" "}
            {subject?.gender === "male"
              ? "man"
              : subject?.gender === "female"
                ? "vrouw"
                : subject?.gender === "other"
                  ? "andere"
                  : " - "}
          </span>
        </div>
      </div>
    </div>
  );
};

const SubjectBadge = React.forwardRef(SubjectBadgeWithoutRef);
export default SubjectBadge;
