/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { Address } from './Address';
import type { Attachment_Output } from './Attachment_Output';
import type { CodeableConcept } from './CodeableConcept';
import type { ContactPoint } from './ContactPoint';
import type { data_contracts__common__fhir__r5__datatypes__complex__Identifier_Output } from './data_contracts__common__fhir__r5__datatypes__complex__Identifier_Output';
import type { Meta } from './Meta';
import type { Narrative } from './Narrative';
import type { PatientCommunication_Output } from './PatientCommunication_Output';
import type { PatientContact_Output } from './PatientContact_Output';
import type { PatientLink_Output } from './PatientLink_Output';
import type { PatientName } from './PatientName';
import type { Reference_Output } from './Reference_Output';

export type FHIRPatientOut = {
    resourceType?: FHIRPatientOut.resourceType;
    id?: (string | null);
    meta?: (Meta | null);
    text?: (Narrative | null);
    name?: Array<PatientName>;
    link?: Array<PatientLink_Output>;
    photo?: (Array<Attachment_Output> | null);
    active?: (boolean | null);
    gender?: (string | null);
    telecom?: (Array<ContactPoint> | null);
    address?: (Array<Address> | null);
    contact?: (Array<PatientContact_Output> | null);
    language?: (string | null);
    birthDate?: (string | null);
    identifier?: Array<data_contracts__common__fhir__r5__datatypes__complex__Identifier_Output>;
    deceasedBoolean?: (boolean | null);
    deceasedDateTime?: (string | null);
    implicitRules?: (string | null);
    maritalStatus?: (CodeableConcept | null);
    communication?: (Array<PatientCommunication_Output> | null);
    multipleBirthBoolean?: (boolean | null);
    multipleBirthInteger?: (number | null);
    generalPractitioner?: (Array<Reference_Output> | null);
    managingOrganization?: (Reference_Output | null);
};

export namespace FHIRPatientOut {

    export enum resourceType {
        PATIENT = 'Patient',
    }


}

