import RemoveButton from "@/components/base/RemoveWithWarning/RemoveButton";
import React from "react";
import { useTranslation } from "react-i18next";
import useRemoveReport from "@/services/reports/useRemoveReport";

interface RemoveReportButtonProps
  extends React.HTMLAttributes<HTMLButtonElement> {
  reportId: number;
}

function RemoveReportButton_(
  { reportId, className, ...attributes }: RemoveReportButtonProps,
  ref: React.Ref<HTMLButtonElement>
) {
  const { t } = useTranslation();
  const { mutate } = useRemoveReport();
  return (
    <RemoveButton
      ref={ref}
      onRemove={() => mutate(reportId)}
      warningTitle={t("remove-report-warning-title")}
      warningMessage={t("remove-report-warning-message")}
      {...attributes}
    />
  );
}
const RemoveReportButton = React.forwardRef(RemoveReportButton_);

export default RemoveReportButton;
