import classNames from "classnames";
import React from "react";

export interface ButtonProps
  extends Omit<React.ButtonHTMLAttributes<HTMLButtonElement>, "style"> {
  extraText?: React.ReactElement;
  small?: boolean;
  style?: "default" | "outline";
}
function Button({
  children,
  extraText,
  small,
  style = "default",
  ...buttonProps
}: ButtonProps) {
  return (
    <div>
      <button
        type="submit"
        className={classNames(
          "mt-1 flex w-fit items-center justify-center space-x-1 border focus:outline-none focus:ring-2 focus:ring-offset-2",
          {
            "border-transparent bg-blue-primary  text-white shadow-sm hover:bg-blue-dark focus:ring-blue-500 disabled:bg-gray-400 hover:disabled:bg-gray-400 focus:disabled:ring-gray-primary":
              style == "default",
            "border-gray-light bg-white text-gray-dark hover:border-gray-primary disabled:bg-gray-light hover:disabled:bg-gray-light focus:disabled:ring-gray-primary":
              style == "outline",
          },
          {
            "rounded px-2 py-1 text-xs font-normal": small,
            "rounded-md px-4 py-2 text-sm font-medium": !small,
          }
        )}
        {...buttonProps}
      >
        <span className="text-center">{children}</span>
      </button>
      {extraText && <div className="mt-1">{extraText}</div>}
    </div>
  );
}
export default Button;
