import EntityTooltip from "@/components/base/EntityTooltip";
import { ConceptEntity } from "@/data-models/concept-entity";
import React, { MouseEventHandler, useCallback } from "react";
import { Transforms } from "slate";
import { ReactEditor, useSlateStatic } from "slate-react";
import { RenderBadge } from "../Entities/StyledEntityContainers";
import { HotKeys } from "../../hotkeys";
import { ISuggestEditor } from "../Suggestions/SuggestEditor";
import { RenderTypedElementProps } from "../../TypedElement";
import { isEventHandled } from "../../util";
import ToggleElement, { IToggleElement } from "./ToggleElement";

export interface RenderToggleButtonElementProps
  extends RenderTypedElementProps {
  element: IToggleElement;
}
const RenderToggleButtonElement = ({
  element,
  attributes,
  children,
}: RenderToggleButtonElementProps) => {
  const entity = new ConceptEntity(element.entity);
  const editor = useSlateStatic() as ISuggestEditor;
  const handleClick: MouseEventHandler = useCallback(
    (e) => {
      if (!isEventHandled(e)) {
        const path = ReactEditor.findPath(editor, element);
        ToggleElement.toggle(editor, path);
        Transforms.select(editor, path);
        ReactEditor.focus(editor);
        e.preventDefault();
      }
    },
    [editor, element],
  );

  const handleKeyDown: React.KeyboardEventHandler = useCallback(
    (e) => {
      if (!isEventHandled(e) && HotKeys.isToggle(e)) {
        const path = ReactEditor.findPath(editor, element);
        ToggleElement.toggle(editor, path);
        e.preventDefault();
      }
    },
    [editor, element],
  );
  return (
    <span {...attributes} className="inline-block" contentEditable={false}>
      <EntityTooltip entity={entity}>
        <RenderBadge
          active={element.active ?? element.checked}
          onClick={handleClick}
          onKeyDown={handleKeyDown}
        >
          {entity.text}
          {children}
        </RenderBadge>
      </EntityTooltip>
    </span>
  );
};

export default RenderToggleButtonElement;
