import { Transition } from "@headlessui/react";
import {
  CheckCircleIcon,
  ExclamationCircleIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import React, { Fragment, SVGAttributes } from "react";
import { resolveValue, toast, Toaster } from "react-hot-toast";
import { Renderable } from "react-hot-toast";
export enum NotificationType {
  Success = "SUCCESS",
  Warning = "WARNING",
  Loading = "LOADING",
}
const Spinner = ({ className }: SVGAttributes<SVGElement>) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 100 100"
  >
    <circle
      cx="50"
      cy="50"
      fill="none"
      stroke="currentColor"
      strokeWidth="8"
      r="35"
      strokeDasharray="164.93361431346415 56.97787143782138"
    ></circle>
  </svg>
);
const Notification = ({
  close,
  visible,
  title,
  message,
  type,
}: {
  visible: boolean;
  close: () => void;
  title: Renderable;
  message?: string;
  type: NotificationType;
}) => (
  <Transition
    show={visible}
    as={Fragment}
    enter="transform ease-out duration-300 transition"
    enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
    enterTo="translate-y-0 opacity-100 sm:translate-x-0"
    leave="transition ease-in duration-100"
    leaveFrom="opacity-100"
    leaveTo="opacity-0"
  >
    <div className="pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5">
      <div className="p-4">
        <div className="flex items-start">
          <div className="flex-shrink-0">
            {type === NotificationType.Success && (
              <CheckCircleIcon
                className="h-6 w-6 text-green-400"
                aria-hidden="true"
              />
            )}
            {type === NotificationType.Warning && (
              <ExclamationCircleIcon
                className="h-6 w-6 text-orange-400"
                aria-hidden="true"
              />
            )}
            {type === NotificationType.Loading && (
              <Spinner className="... mr-3 h-6 w-6 animate-spin text-blue-400" />
            )}
          </div>
          <div className="ml-3 w-0 flex-1 pt-0.5">
            {title && (
              <p className="text-sm font-medium text-gray-900">{title}</p>
            )}
            {message && (
              <p className="mt-1 text-sm text-gray-primary">{message}</p>
            )}
          </div>
          <div className="ml-4 flex flex-shrink-0">
            <button
              className="inline-flex rounded-md bg-white text-gray-400 hover:text-gray-primary focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
              onClick={close}
            >
              <span className="sr-only">Close</span>
              <XMarkIcon className="h-5 w-5" aria-hidden="true" />
            </button>
          </div>
        </div>
      </div>
    </div>
  </Transition>
);
export const NotificationsProvider = ({
  children,
}: React.PropsWithChildren<{}>) => {
  return (
    <>
      <Toaster position="top-right">
        {(t) => {
          switch (t.type) {
            case "success":
              return (
                <Notification
                  visible={t.visible}
                  close={() => toast.dismiss(t.id)}
                  title={resolveValue(t.message, t)}
                  type={NotificationType.Success}
                />
              );
            case "error":
              return (
                <Notification
                  visible={t.visible}
                  close={() => toast.dismiss(t.id)}
                  title={resolveValue(t.message, t)}
                  type={NotificationType.Warning}
                />
              );
            case "loading":
              return (
                <Notification
                  visible={t.visible}
                  close={() => toast.dismiss(t.id)}
                  title={resolveValue(t.message, t)}
                  type={NotificationType.Loading}
                />
              );
            default:
              throw Error("Invalid notifcation type.");
          }
        }}
      </Toaster>
      {children}
    </>
  );
};
