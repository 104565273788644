import PID from "@/components/base/PID";
import { CONFIG } from "@/config";
import Identifier from "@/data-models/value-models/identifier";
import { QueryClient } from "react-query";
import reportClient, { PatientOut } from "@/services/reports/client";
import keyFactory from "@/services/reports/keyFactory";
import { SubmissionContext } from "./types";
import { Action, ActionState, retry } from "./Action";
import { IObservable } from "./Observable";

type Status =
  | "READY"
  | "CLIPBOARD_ERROR"
  | "REPORT_ERROR"
  | "LOADING_REPORT"
  | "COPYING_TO_CLIPBOARD"
  | "QUEUED";

class CopyReportToClipboard extends Action implements IObservable<ActionState> {
  reportId: number;
  patientId: number;
  private __status: Status;
  client: QueryClient;

  constructor({
    client,
    reportId,
    patientId,
    status = "QUEUED",
  }: {
    client: QueryClient;
    reportId: number;
    patientId: number;
    status?: Status;
  }) {
    super();
    this.__status = status;
    this.client = client;
    this.reportId = reportId;
    this.patientId = patientId;
  }

  retry: () => Promise<void> = () => retry(this);

  get current() {
    return {
      renderMessage: this.getMessageRenderFunc(),
      status: this.status,
    };
  }

  get plainTextQuery() {
    return {
      queryKey: keyFactory.plainTextView(this.reportId),
      queryFn: () =>
        reportClient.v1.getPlainTextV1({ reportId: this.reportId }),
    };
  }

  reset() {
    this.updateStatus("QUEUED");
  }

  static fromContext(context: SubmissionContext) {
    const { reportId, queryClient: client, patientId } = context;
    return new CopyReportToClipboard({ client, reportId, patientId });
  }

  get patientDisplayID() {
    const patient = this.client.getQueryData<PatientOut>(
      keyFactory.patient(this.patientId),
    );
    const { identifier = [] } = patient ?? {};
    return identifier && identifier[0].value;
  }
  private async fetchReport() {
    try {
      const data = await this.client.fetchQuery(this.plainTextQuery);
      return [data, null] as const;
    } catch (e) {
      return [null, e] as const;
    }
  }

  private async copyToClipboard(payload: string) {
    try {
      await navigator.clipboard.writeText(payload);
      return [true, null] as const;
    } catch (e) {
      return [false, e] as const;
    }
  }

  async execute(): Promise<[boolean, Error | null]> {
    this.updateStatus("LOADING_REPORT");
    const [payload, reportError] = await this.fetchReport();
    if (!payload) {
      this.updateStatus("REPORT_ERROR");
      return [false, reportError] as [false, Error];
    }
    this.updateStatus("COPYING_TO_CLIPBOARD");
    const [result, clipboardError] = await this.copyToClipboard(payload);
    if (!result) {
      this.updateStatus("CLIPBOARD_ERROR");
      return [false, clipboardError] as [false, Error];
    }
    this.updateStatus("READY");
    return [true, null] as [boolean, Error | null];
  }

  private updateStatus(status: Status) {
    this.__status = status;
    this.notify(this.current);
  }

  get status() {
    const statusMap: Record<Status, ActionState["status"]> = {
      CLIPBOARD_ERROR: "ERROR",
      REPORT_ERROR: "ERROR",
      LOADING_REPORT: "RUNNING",
      COPYING_TO_CLIPBOARD: "RUNNING",
      QUEUED: "QUEUED",
      READY: "READY",
    };
    return statusMap[this.__status];
  }

  getMessageRenderFunc() {
    const messageMap: Record<Status, () => JSX.Element> = {
      READY: () => (
        <>
          Verslagtekst voor pati&euml;nt <PID>{this.patientDisplayID}</PID> is
          succesvol aangemaakt en gekopieerd naar het klembord.
        </>
      ),
      REPORT_ERROR: () => (
        <>
          Fout bij aanmaken van tekstverslag voor pati&euml;nt{" "}
          <PID>{this.patientDisplayID}</PID>.
        </>
      ),
      CLIPBOARD_ERROR: () => (
        <>Fout bij kopi&euml;ren van verslagtekst naar het klembord.</>
      ),
      LOADING_REPORT: () => (
        <>
          Bezig met aanmaken van tekstverslag voor pati&euml;nt{" "}
          <PID>{this.patientDisplayID}</PID>.
        </>
      ),
      COPYING_TO_CLIPBOARD: () => (
        <>Bezig met kopi&euml;ren van verslagtekst naar het klembord.</>
      ),
      QUEUED: () => (
        <>
          Kopi&euml;ren van verslagtekst naar het klembord voor pati&euml;nt{" "}
          <PID>{this.patientDisplayID}</PID>.
        </>
      ),
    };
    return messageMap[this.__status];
  }
}

export default CopyReportToClipboard;
