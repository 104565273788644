/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { SimpleViewDefinition } from '../models/SimpleViewDefinition';
import type { TableFormat } from '../models/TableFormat';
import type { TableGroup } from '../models/TableGroup';
import type { WorkbookFormat } from '../models/WorkbookFormat';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class V2Service {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Get All Grouped Tables
     * Get all exports from all reports
     * @returns TableGroup Successful Response
     * @throws ApiError
     */
    public getAllGroupedTablesV2({
        by = 'report',
    }: {
        by?: 'report',
    }): CancelablePromise<Array<TableGroup>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v2/export/tables/groups',
            query: {
                'by': by,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Table By Id
     * Get a specific table
     * @returns any Successful Response
     * @throws ApiError
     */
    public getTableByIdV2({
        tableId,
        report,
        filename,
        format,
    }: {
        tableId: string,
        report?: (string | null),
        /**
         * The name of the file to export
         */
        filename?: (string | null),
        /**
         * The format of the export
         */
        format?: TableFormat,
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v2/export/tables/{table_id}',
            path: {
                'table_id': tableId,
            },
            query: {
                'report': report,
                '_filename': filename,
                '_format': format,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get All Tables By Id
     * @returns any Successful Response
     * @throws ApiError
     */
    public getAllTablesByIdV2({
        table,
        report,
        filename,
        format,
    }: {
        table?: Array<string>,
        report?: (string | null),
        /**
         * The name of the file to export
         */
        filename?: (string | null),
        /**
         * The format of the export
         */
        format?: WorkbookFormat,
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v2/export/tables',
            query: {
                'table': table,
                'report': report,
                '_filename': filename,
                '_format': format,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Table Meta
     * Get a specific table metadata
     * @returns SimpleViewDefinition Successful Response
     * @throws ApiError
     */
    public getTableMetaV2({
        tableId,
    }: {
        tableId: string,
    }): CancelablePromise<SimpleViewDefinition> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v2/export/tables/{table_id}/meta',
            path: {
                'table_id': tableId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
