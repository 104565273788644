import useCurrentReportId from "@/hooks/useCurrentReportId";
import { useMemo } from "react";
import usePlainTextView from "@/services/reports/usePlainTextView";

/**
 * useDownloadPlainText
 * @returns a function that will download the plain-text of the current note
 */
function useDownloadPlainText() {
  const reportId = useCurrentReportId();

  const { data } = usePlainTextView(reportId, {
    refetchOnMount: false,
    select: (data) => new Blob([data]),
  });

  const link = useMemo(() => data && URL.createObjectURL(data), [data]);
  return link;
}
export default useDownloadPlainText;
