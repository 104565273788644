import InlineChromiumBugfix from "@/components/InlineChromiumBugFix";
import { RenderTypedElementProps } from "../TypedElement";
import useSlateInputElement from "@/editor/utils/useSlateInputElement";
import { SettingsPanelPortal } from "@/components/layout/SettingsPanel";
import { Transition } from "@headlessui/react";
import PropertiesForm from "./ConceptPropertiesForm";
import ChipObservationInput from "@/components/inputs/ObservationInput/ConceptObservationInput/ChipObservationInput";
import { IConceptObservationInputElement } from "./types";

type RenderChipsInputElementProps = RenderTypedElementProps & {
  element: IConceptObservationInputElement;
};

const RenderChipsInputElement = (props: RenderChipsInputElementProps) => {
  const { element, attributes, children } = props;
  const { name, update, disabled, showProperties, active } =
    useSlateInputElement(element);
  const { children: _, name: __, inputType, ...other } = element;
  return (
    <span {...attributes} tabIndex={-1}>
      <InlineChromiumBugfix />
      <ChipObservationInput
        name={name}
        {...other}
        disabled={disabled}
        active={active}
        className="mr-1"
      />
      {children}
      <InlineChromiumBugfix />
      <SettingsPanelPortal>
        <Transition show={showProperties} enter="ease-in-out duration-75">
          <PropertiesForm onSubmit={update} defaultValues={element} />
        </Transition>
      </SettingsPanelPortal>
    </span>
  );
};

export default RenderChipsInputElement;
