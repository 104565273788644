import { withHistory } from "slate-history";
import { IEntryEditor } from "@/editor/legacy/EntryLayoutPlugin/withEntryLayout";
import withInlineVoid from "@/editor/withInlineVoid";
import createSuggestionEditor from "@/editor/legacy/Suggestions/SuggestEditor/createSuggestionEditor";
import { ISuggestEditor } from "@/editor/legacy/Suggestions/SuggestEditor/types";
import { ITiroEditor } from "@/editor/Base/editor";
import { pipe } from "@/util/pipe";
import getPlugins from "./getPlugins";
import getMixins from "./getMixins";
import { RenderType } from "../types";
import { WithSuggestionsOptions } from "@/editor/legacy/Suggestions/SuggestEditor/withSuggestions";

export interface ConstructEditorOptions extends WithSuggestionsOptions {
  renderType: RenderType;
}

type IEditor = ISuggestEditor & IEntryEditor & ITiroEditor;

export const constructEditor = (options?: ConstructEditorOptions) => {
  const { renderType = "rich-text-with-questionnaire", ...suggestOptions } =
    options ?? {};
  const plugins = getPlugins(renderType);
  const baseEditor = createSuggestionEditor(plugins, suggestOptions);
  const mixins = [withHistory, withInlineVoid(), ...getMixins(renderType)];
  const fullEditor = pipe(...mixins)(baseEditor) as IEditor;
  return fullEditor;
};
