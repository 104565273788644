import parse from "date-fns/parse";
import format from "date-fns/format";
import nlBE from "date-fns/locale/nl-BE";
import { utcToZonedTime } from "date-fns-tz";
import { isValidDate } from "../util/isValidDate";
import { Locale } from "date-fns";

function getReferencedDate({ timezone = "Europe/Brussels" }: I18nOptions = {}) {
  const utcDate = new Date(new Date().getFullYear(), 6, 15, 12, 0, 0);
  const zonedDate = utcToZonedTime(utcDate, timezone);
  return zonedDate;
}

const SEPARATORS = [/\//g, /\./g, /\s/g] as const;

const FORMAT_TO_FHIR_FORMAT = {
  yyyy: "yyyy",
  //  "MM-yyyy": "yyyy-MM",
  // "dd-MM-yyyy": "yyyy-MM-dd",
  // MMyyyy: "yyyy-MM",
  // ddMMyyyy: "yyyy-MM-dd",
  "yyyy-MM": "yyyy-MM",
  "yyyy-MM-dd": "yyyy-MM-dd",
  yyyyMM: "yyyy-MM",
  yyyyMMdd: "yyyy-MM-dd",
};
// [parse format, display format, fhir format]
const ORDERED_FORMATS = [
  ["yy", "yyyy", "yyyy"],
  ["yyyy", "yyyy", "yyyy"],
  ["MM-yy", "MM-yyyy", "yyyy-MM"],
  ["LLL-yy", "MM-yyyy", "yyyy-MM"],
  ["LLL-yyyy", "MM-yyyy", "yyyy-MM"],
  ["LLLL-yy", "MM-yyyy", "yyyy-MM"],
  ["LLLL-yyyy", "MM-yyyy", "yyyy-MM"],
  ["MM-yyyy", "MM-yyyy", "yyyy-MM"],
  ["MMyyyy", "MM-yyyy", "yyyy-MM"],
  ["M-yyyy", "MM-yyyy", "yyyy-MM"],
  ["M-yy", "MM-yyyy", "yyyy-MM"],
  ["Myyyy", "MM-yyyy", "yyyy-MM"],
  ["dd-MM-yy", "dd-MM-yyyy", "yyyy-MM-dd"],
  ["d-M-yy", "dd-M-yy", "yyyy-MM-dd"],
  ["dd-MM-yyyy", "dd-MM-yyyy", "yyyy-MM-dd"],
  ["dd-M-yyyy", "dd-MM-yyyy", "yyyy-MM-dd"],
  ["d-MMM-yy", "dd-MM-yyyy", "yyyy-MM-dd"],
  ["d-MMM-yyyy", "dd-MM-yyyy", "yyyy-MM-dd"],
  ["d-MMMM-yy", "dd-MM-yyyy", "yyyy-MM-dd"],
  ["d-MMMM-yyyy", "dd-MM-yyyy", "yyyy-MM-dd"],
  ["dd-MMM-yy", "dd-MM-yyyy", "yyyy-MM-dd"],
  ["dd-MMM-yyyy", "dd-MM-yyyy", "yyyy-MM-dd"],
  ["dd-MMMM-yy", "dd-MM-yyyy", "yyyy-MM-dd"],
  ["dd-MMMM-yyyy", "dd-MM-yyyy", "yyyy-MM-dd"],
  ["dMMyy", "dd-MM-yyyy", "yyyy-MM-dd"],
  ["ddMMyy", "dd-MM-yyyy", "yyyy-MM-dd"],
  ["ddMMyyyy", "dd-MM-yyyy", "yyyy-MM-dd"],
  ["dMyyyy", "dd-MM-yyyy", "yyyy-MM-dd"],
  ["dMyy", "dd-MM-yyyy", "yyyy-MM-dd"],
  ["yyyy-MM-dd", "dd-MM-yyyy", "yyyy-MM-dd"],
  ["yyyyMMdd", "dd-MM-yyyy", "yyyy-MM-dd"],
] as const;

const NL_TODAY = ["heden", "vandaag", "nu"];
export type I18nOptions = { locale?: Locale; timezone?: string };

export function reformatDateString(
  value: string,
  { locale = nlBE }: I18nOptions = {},
): string | null {
  let result: Date | null = null;

  value = value.trim();
  if (NL_TODAY.includes(value.toLowerCase())) {
    return format(new Date(), "dd-MM-yyyy");
  }
  for (const sep of SEPARATORS) {
    value = value.replace(sep, "-");
  }
  for (const yearConstraint of [
    [new Date().getFullYear() - 100, new Date().getFullYear() + 1],
    null,
  ]) {
    for (const [detected, preferred] of ORDERED_FORMATS) {
      result = parse(value, detected, new Date(), { locale });
      if (
        !!yearConstraint &&
        (result.getFullYear() <= yearConstraint[0] ||
          result.getFullYear() >= yearConstraint[1])
      )
        continue;
      if (isValidDate(result)) {
        return format(result, preferred, { locale });
      }
    }
  }
  return null;
}
export function formatDateStringToFhir(
  value: string,
  { locale = nlBE, timezone = "Europe/Brussels" }: I18nOptions = {},
): string | null {
  if (value.length > 0) {
    for (const sep of SEPARATORS) {
      value = value.replace(sep, "-");
    }
    for (const [detected, preferred] of Object.entries(FORMAT_TO_FHIR_FORMAT)) {
      const parsedDate = parse(
        value,
        detected,
        getReferencedDate({ timezone }),
        { locale },
      );
      if (isValidDate(parsedDate)) {
        return format(parsedDate, preferred, { locale });
      }
    }
  }
  return null;
}
export function parseDateString(
  value: string,
  { locale = nlBE, timezone = "Europe/Brussels" }: I18nOptions = {},
): Date | null {
  value = value.trim();
  if (NL_TODAY.includes(value.toLowerCase())) {
    return new Date();
  }
  for (const sep of SEPARATORS) {
    value = value.replaceAll(sep, "-");
  }
  for (const [detected] of ORDERED_FORMATS) {
    const parsedDate = parse(value, detected, getReferencedDate(), { locale });
    if (isValidDate(parsedDate)) {
      return parsedDate;
    }
  }
  return null;
}
