import {
  createEditor,
  Editor,
  EditorInterface,
  Node,
  Range,
  Text,
  Transforms,
} from "slate";
import { withReact } from "slate-react";
import { IEditorPlugin } from "./types";

export interface TiroEditorInterface extends EditorInterface {
  insertIndent: (editor: ITiroEditor) => void;
  tabFocus: (editor: ITiroEditor, options?: { reverse?: boolean }) => boolean;
  serializePlainText: (
    editor: ITiroEditor,
    node: Node,
    inlineSCT: boolean
  ) => Generator<string>;
  //serializeInlineSNOMEDCT: (node:Node)=>string
}

export interface ITiroEditor extends Editor {
  plugins: IEditorPlugin[];
  designMode: boolean;
  insertIndent: () => void;
  tabFocus: (options?: { reverse?: boolean }) => boolean;
  serializePlainText: (node: Node, inlineSCT: boolean) => Generator<string>;
}

export const createBaseEditor = (plugins: IEditorPlugin[] = []) => {
  const editor = withBase(withReact(createEditor()), plugins);
  return editor;
};

function* serializePlainText(
  editor: ITiroEditor,
  node: Node,
  inlineSCT: boolean
) {
  if (Text.isText(node)) {
    yield Node.string(node);
    return;
  }
  const { children } = node;
  const inlineDescendants = children.some((n) => !Editor.isBlock(editor, n));
  if (inlineDescendants) {
    yield children
      .flatMap((n) =>
        Array.from(TiroEditor.serializePlainText(editor, n, inlineSCT))
      )
      .join("");
    return;
  }
  for (const childNode of children) {
    yield* TiroEditor.serializePlainText(editor, childNode, inlineSCT);
  }
}

export const withBase = <T extends Editor>(
  editor: T,
  plugins: IEditorPlugin[] = [],
  designMode: boolean = false
): T & ITiroEditor => {
  let tiroEditor = editor as any as ITiroEditor & T;

  tiroEditor = Object.assign(tiroEditor, {
    designMode,
    plugins,
    insertIndent: () => undefined,
    tabFocus: ((options) =>
      tabFocus(tiroEditor, options)) as ITiroEditor["tabFocus"],
    serializePlainText: (node: Node, inlineSCT: boolean) =>
      serializePlainText(tiroEditor, node, inlineSCT),
  });
  const { insertBreak } = editor;
  tiroEditor.insertBreak = () => {
    insertBreak();
    Editor.removeMark(editor, "format");
  };
  return tiroEditor;
};

function tabFocus(editor: Editor, options: { reverse?: boolean } = {}) {
  const { selection } = editor;
  const { reverse = false } = options;
  if (!selection) return false;
  // if first or last point of editor is contained in selection but selection is still expanded
  // collapse first
  if (Range.isExpanded(selection)) {
    Transforms.collapse(editor, { edge: reverse ? "start" : "end" });
    return true;
  }
  let fn = reverse ? Editor.previous : Editor.next;
  const entityElement = fn(editor, {
    match: (n) =>
      //EntityElement.isEntityElement(n) &&
      Editor.isInline(editor, n) && Editor.isVoid(editor, n),
  });
  if (entityElement) {
    const [, path] = entityElement;
    const nextFocus = { path, offset: 0 };

    Transforms.select(editor, nextFocus);
    return true;
  }
  return false;
}

const TiroEditor: TiroEditorInterface = {
  tabFocus: (editor: ITiroEditor, options) => editor.tabFocus(options),
  serializePlainText: (editor: ITiroEditor, node: Node, inlineSCT: boolean) => {
    return editor.serializePlainText(node, inlineSCT);
  },
  insertIndent: (editor) => editor.insertIndent(),
  ...Editor,
};

export default TiroEditor;
