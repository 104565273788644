import { Transition } from "@headlessui/react";
import { HotKeys } from "@/editor/hotkeys";
import React, { Ref } from "react";
import {
  DeepPartial,
  useForm,
  UseFormProps,
  UseFormReturn,
} from "react-hook-form";
import { func, is } from "superstruct";
import { EntityEditorProps } from ".";
import { IEditableEntity } from "./types";
import { useTranslation } from "react-i18next";

type FieldValues<TEntity extends IEditableEntity> = { entity: TEntity };
export interface FormContainerProps<TEntity extends IEditableEntity>
  extends EntityEditorProps<TEntity>,
    Omit<UseFormProps<FieldValues<TEntity>>, "defaultValues"> {
  children:
    | ((props: UseFormReturn<FieldValues<TEntity>>) => React.ReactNode)
    | React.ReactNode;
}

function FormContainer<TEntity extends IEditableEntity = IEditableEntity>(
  {
    entity,
    onEntityChange,
    open,
    close,
    children,
    resolver,
    shouldFocusError,
    shouldUnregister,
    shouldUseNativeValidation,
    context,
    criteriaMode,
    reValidateMode,
    mode,
    delayError,
    ...attributes
  }: FormContainerProps<TEntity>,
  ref?: Ref<HTMLFormElement>
) {
  const { t } = useTranslation();
  const methods = useForm<FieldValues<TEntity>>({
    defaultValues: { entity: entity as DeepPartial<TEntity> },
    resolver,
    shouldFocusError,
    shouldUnregister,
    shouldUseNativeValidation,
    context,
    criteriaMode,
    reValidateMode,
    mode,
    delayError,
  });
  const { handleSubmit, reset } = methods;
  const handleValidSubmission = ({ entity }: { entity: TEntity }) => {
    onEntityChange(entity);
    close && close();
  };
  const handleInvalidSubmission = () => {
    onEntityChange(entity);
    close && close();
  };
  return (
    <Transition
      show={open}
      enter="transition duration-100 ease-out"
      enterFrom="transform scale-95 opacity-0"
      enterTo="transform scale-100 opacity-100"
      leave="transition duration-75 ease-out"
      leaveFrom="transform scale-100 opacity-100"
      leaveTo="transform scale-95 opacity-0"
    >
      <form
        ref={ref}
        {...attributes}
        className="bordery-gray-400 z-30 rounded-md border bg-white py-2 shadow-lg sm:text-sm"
        onSubmit={handleSubmit(handleValidSubmission, handleInvalidSubmission)}
        onKeyDown={(e) => {
          if (HotKeys.isEscape(e)) {
            reset();
            e.preventDefault();
          }
        }}
        onBlur={(e) => {
          if (!e.currentTarget.contains(e.relatedTarget)) {
            e.currentTarget.requestSubmit();
          }
        }}
      >
        {is(children, func()) ? children(methods) : children}
        <button tabIndex={-1} type="submit" className="sr-only">
          {t("sla-wijzigingen-op")}
        </button>
        <button tabIndex={-1} type="reset" className="sr-only">
          {t("verwijderen-wijzigingen")}
        </button>
      </form>
    </Transition>
  );
}
export default FormContainer;
