import { createCodeKey } from "@/data-models/value-models/createCodeKey";
import { IEnabledWhen, IQuestionNode } from "../models";

function createEnabledWhenKey(enabledWhen: IEnabledWhen) {
  const { conditions, mode } = enabledWhen;
  const conditionKeys = conditions
    .map((c) => JSON.stringify(c))
    .sort()
    .join("|");
  return `${mode}|${conditionKeys}`;
}
function createUniqueQuestionKey(question: IQuestionNode): string {
  if ("template" in question) return createUniqueQuestionKey(question.template);
  const { code, enabledWhen } = question;
  const codeKey = createCodeKey(code);
  const enabledWhenKey = enabledWhen ? createEnabledWhenKey(enabledWhen) : "";
  return `${codeKey}|${enabledWhenKey}`;
}

export default createUniqueQuestionKey;
