import { array, Describe, enums, refine, union, validate } from "superstruct";
import { RENDER_TYPES } from "./consts";
import { IRichTextContent, IRichTextContentWithTitle } from "./types";
import {
  ElementModel,
  ITiroElement,
  ITitleElement,
  TitleElementModel,
} from "@/data-models/nodes";

export const RichTextContentModel: Describe<(ITitleElement | ITiroElement)[]> =
  array(union([TitleElementModel, ElementModel]));

function checkIfRichTextContentHasTitle(elements: IRichTextContent) {
  const hasAtLeaseOneElement = elements.length > 0;
  if (!hasAtLeaseOneElement)
    return "Content should have at least a title and an empty text line.";
  const [titleValidationErrors] = validate(elements[0], TitleElementModel);
  if (titleValidationErrors) return titleValidationErrors;
  return hasAtLeaseOneElement && !titleValidationErrors;
}
export const RichTextContentModelWithTitle = refine(
  RichTextContentModel,
  "RichTextContentModelWithTitle",
  checkIfRichTextContentHasTitle,
) as unknown as Describe<IRichTextContentWithTitle>;

export const RenderTypeModel = enums(RENDER_TYPES);

export default RichTextContentModel;
