import React from "react";
import { Result } from "./types";
import { Action } from "./Action";
import { sendSMARTWebMessage as sendSMARTWebMessage } from "@/services/smart-web-messaging";

type UIDoneStatus = "HIDDEN" | "RUNNING" | "READY" | "ERROR";

/**
 * Action to close request to host window
 */
class UIDoneMessage extends Action {
  status: UIDoneStatus;
  secondsToWait: number;
  secondsLeft: number;
  constructor(status: UIDoneStatus = "HIDDEN", secondsToWait: number = 2) {
    super();
    this.status = status;
    this.secondsToWait = secondsToWait;
    this.secondsLeft = this.secondsToWait;
  }

  get current() {
    return {
      status: this.status,
      renderMessage: this.getMessageRenderFunc(),
    };
  }

  reset() {
    this.updateStatus("HIDDEN");
  }
  retry: undefined;

  updateStatus(status: UIDoneStatus) {
    this.status = status;
    this.notify(this.current);
  }

  startTimer() {
    const interval = setInterval(() => {
      this.secondsLeft--;
      this.notify(this.current);
    }, 1000);
    setTimeout(async () => {
      clearInterval(interval);
      // separate try-catch block to avoid breaking the timer
      try {
        await sendSMARTWebMessage("ui.done", {});
      } catch (e) {
        console.error(e);
        this.updateStatus("ERROR");
      }
      this.updateStatus("READY");
    }, this.secondsToWait * 1000);
  }

  async execute() {
    this.updateStatus("RUNNING");
    try {
      this.startTimer();
    } catch (e) {
      console.error(e);
      this.updateStatus("ERROR");
      return [false, e] as Result;
    }
    return [true, null] as Result;
  }

  getMessageRenderFunc(): () => React.ReactNode {
    const messageMap: Record<UIDoneStatus, () => JSX.Element> = {
      HIDDEN: () => <>Sluiten van dit venster.</>,
      RUNNING: () => (
        <>Dit venster wordt automatisch gesloten over {this.secondsLeft} </>
      ),
      READY: () => <>Dit venster is gesloten.</>,
      ERROR: () => (
        <>
          Er is een fout opgetreden bij het sluiten van dit venster. U kunt het
          nog steeds handmatig sluiten.
        </>
      ),
    };
    return messageMap[this.status];
  }
}
export default UIDoneMessage;
