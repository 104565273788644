import classNames from "classnames";
import Button from "@/components/base/Button";
import useRichTextBlockEditor from "@/components/blocks/content/RichTextBlock/useRichTextBlockEditor";
import SuggestEditable from "@/editor/legacy/Suggestions/SuggestEditable";
import { Controller, FieldValues, UseControllerProps } from "react-hook-form";
import { Descendant, Editor } from "slate";
import { ReactEditor, Slate } from "slate-react";
import { PathsMatching } from "@/util/pathsMatching";
import { RenderPlaceholder } from "./RenderPlaceholder";

type SlatePath<TFieldValues extends FieldValues> = PathsMatching<
  TFieldValues,
  Descendant[]
>;
export interface ITextfieldProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends SlatePath<TFieldValues> = SlatePath<TFieldValues>,
> extends UseControllerProps<TFieldValues, TName> {
  name: TName;
  placeholder?: string;
  className?: string;
  disableAddConcept?: boolean;
  disabled?: boolean;
}

const STYLES = {
  focused:
    "focus-within:border focus-within:border-blue-primary focus-within:bg-white",
};
const DEFAULT_VALUE = [{ children: [{ text: "" }] }];
export default function Textfield<
  TFieldValues extends FieldValues = FieldValues,
  TName extends SlatePath<TFieldValues> = SlatePath<TFieldValues>,
>({
  name,
  rules,
  control,
  shouldUnregister = true,
  defaultValue,
  placeholder = "...",
  className,
  disableAddConcept = true,
  disabled = false,
}: ITextfieldProps<TFieldValues, TName>) {
  const [editor, plugins] = useRichTextBlockEditor({
    renderType: "rich-text-with-questionnaire",
  });
  const handleAddConceptClick = () => {
    Editor.insertText(editor, " /");
    ReactEditor.focus(editor);
  };
  return (
    <Controller<TFieldValues, TName>
      name={name}
      defaultValue={defaultValue}
      shouldUnregister={shouldUnregister}
      rules={rules}
      control={control}
      render={({ field: { value = DEFAULT_VALUE, onChange, onBlur } }) => (
        <Slate editor={editor} value={value} onChange={onChange}>
          <SuggestEditable
            readOnly={disabled}
            className={classNames(
              "group w-full self-stretch rounded-md border border-gray-light px-2 py-1",
              STYLES.focused,
              className,
            )}
            renderPlaceholder={RenderPlaceholder}
            placeholder={placeholder}
            plugins={plugins}
            editableClassname="focus:outline-none text-gray-dark text-sm"
            editableFooter={
              disableAddConcept ? undefined : (
                <Button
                  tabIndex={-1}
                  type="button"
                  className="hidden group-focus-within:inline"
                  kind="invisible"
                  label="+ voeg concept toe"
                  onClick={handleAddConceptClick}
                />
              )
            }
            onBlur={onBlur}
          />
        </Slate>
      )}
    />
  );
}
