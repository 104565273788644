import { Instruction } from "./Action";

export class CloseWindowInstruction extends Instruction {
  get current() {
    return {
      status: this.status,
      renderMessage: () => <>U mag dit venster sluiten.</>,
    };
  }
}
