import classNames from "classnames";
import QuestionAnswerForm from "@/components/legacy/questionnaire-v1";
import { useQuestionnaireReducer } from "@/components/legacy/questionnaire-v1/createReducer";
import { QAAction } from "@/components/legacy/questionnaire-v1/types";
import React, { useCallback } from "react";
import { Editor, Node, Path, Transforms } from "slate";
import {
  ReactEditor,
  useFocused,
  useSelected,
  useSlateStatic,
} from "slate-react";
import { RenderTypedElementProps } from "../../TypedElement";
import { IQuestionnaireElement } from "./types";

export interface RenderQuestionnaireElementProps
  extends RenderTypedElementProps {
  element: IQuestionnaireElement;
}
function RenderQuestionnaireElement(props: RenderQuestionnaireElementProps) {
  const { element, attributes, children } = props;
  const editor = useSlateStatic();

  const isFocused = useFocused();
  const isSelected = useSelected();
  const active = isFocused && isSelected;
  const path = ReactEditor.findPath(editor, element);

  const handleFocus: React.FocusEventHandler = useCallback(
    (e) => {
      Transforms.setSelection(editor, Editor.range(editor, path));
    },
    [editor, path]
  );

  const reducer = useQuestionnaireReducer({ enableScoring: true });
  const dispatch = useCallback(
    (action: QAAction) => {
      // Retrieve the value directly from the editor because we are not sure to be fully up to date with rerenders
      const { questions: currentQuestions } = Node.get(
        editor,
        path
      ) as IQuestionnaireElement;
      const newQuestions = reducer(currentQuestions, action);

      Transforms.setNodes(editor, { questions: newQuestions }, { at: path });
    },
    [editor, path, reducer]
  );

  return (
    <div
      {...attributes}
      contentEditable={false}
      className={classNames("rounded", {
        "ring-2 ring-blue-200": active,
      })}
    >
      <QuestionAnswerForm
        className="rounded"
        title={element.title}
        description={element.description}
        questions={element.questions}
        dispatch={dispatch}
        onFocus={handleFocus}
      />
      <span className="absolute">{children}</span>
    </div>
  );
}

export default RenderQuestionnaireElement;
