import { Logo, LogoWithText as LogoWithBrandname } from "../Logo";

import useLaunchedFrom from "@/hooks/useLaunchedFrom";
import { Link, useLocation } from "react-router-dom";

const LogoLink = ({ color = "#007FFF" }: { color?: string }) => {
  const { search } = useLocation();
  const isKWS = useLaunchedFrom("KWS");
  if (!isKWS)
    return (
      <Link to={{ pathname: "/", search }}>
        <LogoWithBrandname
          className="hidden h-8 w-auto"
          aria-label="Tiro.health"
        />
        <Logo
          className="block h-8 w-auto"
          color={color}
          aria-label="Tiro.health"
        />
      </Link>
    );
  return (
    <span>
      <LogoWithBrandname
        className="hidden h-8 w-auto"
        aria-label="Tiro.health"
      />
      <Logo
        className="block h-8 w-auto"
        color={color}
        aria-label="Tiro.health"
      />
    </span>
  );
};

export default LogoLink;
