import { IConceptEntity } from "@/data-models/concept-entity";
import { Editor, Node, Path, Transforms } from "slate";
import { IAbstractEntityElement, IStatefullElement } from "../Entities/types";
import { TypedElement } from "../../TypedElement";
import {
  TOGGLE_ELEMENT as TOGGLE_ELEMENT,
  TOGGLE_GROUP_ELEMENT,
} from "./conts";
export interface IToggleElement
  extends IAbstractEntityElement,
    IStatefullElement {
  readonly type: typeof TOGGLE_ELEMENT;
  entity: IConceptEntity;
}

export interface IToggleGroupElement extends IAbstractEntityElement {
  readonly type: typeof TOGGLE_GROUP_ELEMENT;
  multiple?: boolean;
}

const ToggleElement = {
  ...TypedElement,
  isToggleEntityElement: (o: any): o is IToggleElement =>
    ToggleElement.isTypedElement(o) && o.type === TOGGLE_ELEMENT,
  isToggleGroupElement: (o: any): o is IToggleGroupElement =>
    ToggleElement.isTypedElement(o) && o.type === TOGGLE_GROUP_ELEMENT,

  toggle: (editor: Editor, path: Path) => {
    const element = Node.get(editor, path) as IToggleElement;
    const group = Editor.above<IToggleGroupElement>(editor, {
      at: path,
      match: (n) => ToggleElement.isToggleGroupElement(n),
    });
    Editor.withoutNormalizing(editor, () => {
      if (group && !element.active) {
        const [groupElement, groupPath] = group;
        // if multiple active toggles are not allowed, set every toggle to inactive first.
        if (!groupElement.multiple) {
          Transforms.setNodes<IToggleElement>(
            editor,
            { active: false },
            {
              at: groupPath,
              match: ToggleElement.isToggleEntityElement,
              mode: "lowest",
            }
          );
        }
      }
      Transforms.setNodes<IToggleElement>(
        editor,
        { active: !element.active },
        { at: path }
      );
      //element will become active
      if (!element.active) {
        // set entity on group level
        Transforms.setNodes<IToggleGroupElement>(
          editor,
          { entity: element.entity },
          { at: path.slice(0, -1) }
        );
      }
      // element will become inactive
      if (element.active) {
        // unset entity on group level
        Transforms.unsetNodes<IToggleGroupElement>(editor, "entity", {
          at: path.slice(0, -1),
        });
      }
    });
  },
};

export default ToggleElement;
