/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { models__api__coding_token__Coding } from './models__api__coding_token__Coding';

/**
 * Model for block creation
 */
export type BlockIn = {
    title: string;
    blockType: string;
    renderType: BlockIn.renderType;
    content: any;
    autoPopulateWithPreviousResponse?: (boolean | null);
    fromBlock?: (number | null);
    template?: (string | null);
    version?: (string | null);
    coding?: Array<models__api__coding_token__Coding>;
};

export namespace BlockIn {

    export enum renderType {
        QUESTIONNAIRE_V3 = 'questionnaire-v3',
        QUESTIONNAIRE_V2_1 = 'questionnaire-v2.1',
        QUESTIONNAIRE_V2 = 'questionnaire-v2',
        TABLE_V2 = 'table-v2',
        FREE_TEXT_V1 = 'free-text-v1',
        RICH_TEXT_WITH_QUESTIONNAIRE = 'rich-text-with-questionnaire',
        QA_RICH_TEXT = 'qa-rich-text',
        TIMESTAMPED_RICH_TEXT = 'timestamped-rich-text',
        TABLE_RICH_TEXT = 'table-rich-text',
    }


}

