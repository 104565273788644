import { ValidationScore } from "./ValidationScore";

class Observable {
  listeners: Set<() => void>;

  constructor() {
    this.listeners = new Set();
  }

  register(callback: () => void) {
    this.listeners.add(callback);
  }
  unregister(callback: () => void) {
    this.listeners.delete(callback);
  }
  notify() {
    this.listeners.forEach((cb) => {
      cb();
    });
  }
}

/**
 * Score board that keeps track of completeness of curren report.
 * The score board receives individual validation scores, aggregates them and notifies all listeneres
 * that want to get updates
 */
class ValidationScoreBoard extends Observable {
  scoreBoard: Map<string, ValidationScore>;
  #autoFreeze: boolean;

  constructor(autoFreeze: boolean = true) {
    super();
    this.#autoFreeze = autoFreeze;
    this.scoreBoard = new Map();
  }
  updateScore(key: string, score: ValidationScore) {
    if (this.#autoFreeze) {
      score = Object.freeze(score);
    }
    this.scoreBoard.set(key, score);
    this.notify();
  }

  removeScore(key: string) {
    this.scoreBoard.delete(key);
    this.notify();
  }

  get totalScore() {
    if (this.scoreBoard.size == 0) return 0;
    let score = 0;
    let total = 0;
    this.scoreBoard.forEach((vs) => {
      score += vs.score() * vs.countTotal();
      total += vs.countTotal();
    });
    if (total == 0) return 0;
    return score / total;
  }
  get total() {
    let total = 0;
    this.scoreBoard.forEach((vs) => (total += vs.countTotal()));
    return total;
  }

  get totalInvalid() {
    let total = 0;
    this.scoreBoard.forEach((vs) => (total += vs.countInvalid()));
    return total;
  }

  get missing() {
    return Array.from(this.scoreBoard.values()).flatMap((vs) => vs.missing);
  }
}

export default ValidationScoreBoard;
