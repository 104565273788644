import { useCallback, useState } from "react";
import { FieldValues, Path, useFormContext } from "react-hook-form";
import { IsEmptyFunc } from "./types";

function useEditMode<TFieldValues extends FieldValues = FieldValues>(
  name: Path<TFieldValues>,
  defaultMode: "edit" | "view" = "view",
  isEmptyFn: IsEmptyFunc<TFieldValues>,
) {
  const { getFieldState, getValues } = useFormContext<TFieldValues>();
  const initEditMode = useCallback(() => {
    const value = getValues(name);
    const isEmpty = isEmptyFn({ value, getValues });
    const error = getFieldState(name)?.error;
    //const { error } = getFieldState(name);
    return !!error || isEmpty || defaultMode == "edit";
  }, [name, defaultMode, getValues, isEmptyFn, getFieldState]);

  return useState(initEditMode);
}

export default useEditMode;
