import { useMutation, useQueryClient } from "react-query";
import client, { ReportOut } from "./client";
import keyFactory from "./keyFactory";

export default function useRemoveReport() {
  const queryClient = useQueryClient();
  return useMutation(
    (reportId: number) => client.v1.deleteReportV1({ reportId }),
    {
      onMutate: async (reportId: number) => {
        await queryClient.cancelQueries(keyFactory.allReports());
        let previousReports = queryClient.getQueryData(keyFactory.allReports());
        try {
          const updater = (old?: ReportOut[]) => {
            return old?.filter((report: any) => report.id !== reportId) ?? [];
          };
          queryClient.setQueriesData(keyFactory.allReports(), updater);
        } catch (e) {
          return { previousReports };
        }
        return { previousReports };
      },
      onSuccess: () => {
        queryClient.invalidateQueries(keyFactory.allReports());
      },
      onError: (err, variables, context) => {
        queryClient.setQueryData(
          keyFactory.allReports(),
          context?.previousReports
        );
      },
    }
  );
}
