import { literal } from "superstruct";
import { RENDER_TYPE } from "./consts";
export type { IContent } from "./types";
export { default as ContentModel } from "./models";

export type RenderType = typeof RENDER_TYPE;
export const RenderTypeModel = literal(RENDER_TYPE);

export { RENDER_TYPE };

export { default as RenderFreeTextBlockContent } from "./RenderBlockContent";
export { default as RenderFreeTexttemplateblockContent } from "./RenderTemplateBlockContent";
