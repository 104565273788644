import { useReportBlockList } from "@/services/reports/useReportBlockList";
import BlockList from "@/components/blocks/BlockList";
import LoadingDataBlock from "@/components/loading-pages/LoadingDataBlock";
import RenderBlock from "@/components/blocks/RenderBlock";

export interface BlockListManangerProps {
  reportId: number;
}

function BlockListMananger({ reportId }: BlockListManangerProps) {
  const { blocks, append, remove, insert, reorder, status } =
    useReportBlockList(reportId);

  if (status === "loading") {
    return <LoadingDataBlock message="We laden de bouwblokken in je verslag" />;
  }
  if (!blocks) {
    return (
      <div className="flex items-center justify-center">
        <h2 className="leading-5 text-primary">
          Er ging iets mis tijdens het laden van je verslag.
        </h2>
      </div>
    );
  }
  return (
    <BlockList blocks={blocks} reorder={reorder}>
      {({ index, block, provided }) => (
        <RenderBlock
          id={block.id}
          reportId={reportId}
          dragHandleProps={provided.dragHandleProps ?? undefined}
          insert={(block) => insert({ index: index + 1, block })}
          remove={() => remove({ blockId: block.id })}
        />
      )}
    </BlockList>
  );
}
export default BlockListMananger;
