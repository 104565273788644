import { format, parseISO, isValid } from "date-fns";
import { nlBE } from "date-fns/locale";
import { I18nOptions } from "@/text-parsers/parse-date";

export function printDate(
  value: Date | string,
  { locale = nlBE }: I18nOptions = {}
): string {
  let date: Date | null;
  if (typeof value === "string") {
    date = parseISO(value);
  } else {
    date = value;
  }
  return date && isValid(date) ? format(date, "dd-MM-yyyy", { locale }) : "";
}
