import React, { Dispatch } from "react";
import { QAAction, IQuestionItem } from "../models";
import QALayout, { QuestionAnswerStyleProvider } from "./Layout";
import useQuestion from "./useQuestion";
import { useIsDragging } from "@/components/forms/SortableFieldArray";
import { IterateQuestions } from "./IterateQuestions";
import Answer from "../Answer/Answer";
import QuestionnaireLayout from "../Layout";

export interface QuestionAnswerProps {
  question: IQuestionItem;
  parent?: IQuestionItem;
  dispatch: Dispatch<QAAction>;
  indexPath: number[];
  path: string[];
  onSplit?: () => void;
}

function QuestionAnswer({
  question,
  parent,
  indexPath,
  path,
  dispatch,
}: QuestionAnswerProps) {
  const isDragging = useIsDragging();
  const [questionState, { remove, updateContent, updateValues, toggle }] =
    useQuestion({
      question,
      parent,
      indexPath,
      path,
      dispatch,
    });
  return (
    <OverrideOrientation orientation={question.orientation}>
      <QALayout.Container show={questionState.enabled}>
        <QALayout.QuestionContainer
          indent={questionState.indent}
          isSubtitle={!questionState.answer}
        >
          <QALayout.RemoveButton
            onClick={remove}
            disabled={questionState.required}
          />
          <QALayout.Toolbar>
            <QALayout.MinimizeButton
              tabIndex={-1}
              open={!questionState.isCollapsed && !isDragging}
              hidden={!questionState.isMinimizable}
              onClick={toggle}
              className="block"
            />
          </QALayout.Toolbar>
          <QALayout.Question
            isSubtitle={!questionState.answer}
            code={question.code}
          />
          <QALayout.Answer show={questionState.showAnswer}>
            <Answer
              linkId={path.join(".")}
              mode={questionState.mode}
              content={questionState.answer}
              values={questionState.values}
              onContentChange={updateContent}
              onValuesChange={updateValues}
            />
          </QALayout.Answer>
        </QALayout.QuestionContainer>
        <QALayout.SubQuestionContainer show={!questionState.isCollapsed}>
          <IterateQuestions
            questions={questionState.subQuestions}
            path={path}
            indexPath={indexPath}
          >
            {({ question: childQuestion, path, indexPath }) => (
              <QuestionAnswer
                question={childQuestion}
                parent={childQuestion.enabledWhen && question}
                dispatch={dispatch}
                indexPath={indexPath}
                path={path}
              />
            )}
          </IterateQuestions>
        </QALayout.SubQuestionContainer>
      </QALayout.Container>
    </OverrideOrientation>
  );
}
export default QuestionAnswer;

const OverrideOrientation = ({
  orientation,
  children,
}: {
  orientation?: "horizontal" | "vertical";
  children: React.ReactNode;
}) => {
  if (orientation) {
    return (
      <QuestionAnswerStyleProvider value={{ orientation }}>
        {children}
      </QuestionAnswerStyleProvider>
    );
  }
  return <>{children}</>;
};
