import {
  RENDER_TYPES as RICH_TEXT_RENDER_TYPES,
  LEGACY_RENDER_TYPES as RICH_TEXT_LEGACY_RENDER_TYPES,
} from "./RichTextBlock/consts";
import { RENDER_TYPE as TABLE_RENDER_TYPE } from "./Table/consts";
import { RENDER_TYPE as QUESTIONNAIRE_RENDER_TYPE } from "./Questionnaire/consts";
import { RENDER_TYPE as FREE_TEXT_RENDER_TYPE } from "./FreeText/consts";

export const RENDER_TYPES = [
  ...RICH_TEXT_RENDER_TYPES,
  TABLE_RENDER_TYPE,
  QUESTIONNAIRE_RENDER_TYPE,
  FREE_TEXT_RENDER_TYPE,
] as const;

// These blocktypes are allowed for legacy reports, but should not be used in new reports
export const LEGACY_RENDER_TYPES = [...RICH_TEXT_LEGACY_RENDER_TYPES];
