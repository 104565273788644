// based on FHIR ValueSet AdministrativeGender https://www.hl7.org/fhir/valueset-administrative-gender.html
const GENDER = ["male", "female", "other", "unknown"] as const;
type Gender = typeof GENDER[number];

const GENDER_MAP = new Map<string, Gender>([
  // EN
  ["male", "male"],
  ["female", "female"],
  ["other", "other"],
  ["unknown", "unknown"],
  ["m", "male"],
  ["f", "female"],
  ["o", "other"],
  //NL
  ["man", "male"],
  ["vrouw", "female"],
  ["onbekend", "unknown"],
  ["m", "male"],
  ["v", "female"],
]);

export function parseGenderString(value: string): Gender | null {
  const gender = GENDER_MAP.get(value.toLowerCase()) || null;
  return gender;
}
