import { EnvelopeIcon } from "@heroicons/react/24/outline";

function RenderBlockError({ blockId }: { blockId: string }) {
  const subject = encodeURIComponent(
    "Bouwblok kan niet worden geladen. (id=" + blockId + ")"
  );
  const cc = encodeURIComponent("axel.vanraes@tiro.health");
  const mailto = `mailto:admin@tiro.health?cc=${cc}&subject=${subject}`;
  return (
    <div className="prose-sm rounded-md border border-gray-ultralight bg-gray-ultralight p-2">
      <h3 className="mx-auto max-w-lg">
        🚧 Sorry, we kunnen deze bouwblok niet weergeven. 🚧
      </h3>
      <p className="mx-auto max-w-lg text-left">
        Vermoedelijk is deze bouwblok gemaakt met een oudere versie van de
        applicatie waardoor we deze niet kunnen weergeven. De inhoud staat
        veilig opgeslaan in de database.
        <br />
        <a className="inline-flex items-baseline text-blue-dark" href={mailto}>
          <span className="self-baseline underline hover:decoration-2">
            Contacteer ons
          </span>
          <EnvelopeIcon className="mx-0.5 inline h-4 w-4 self-center" />
        </a>{" "}
        indien je de gestructureerde data nodig hebt of wilt aanpassen.
      </p>
    </div>
  );
}

export default RenderBlockError;
