import { ITiroElement, TiroDescendant } from "@/editor/Base/types";
import { IConcept } from "@/data-models/concept";
export interface DraftQuestionAnswer {
  code: null | IConcept;
  enableWhen?: "CHECKED" | null;
  answer?: TiroDescendant[] | null;
  required?: boolean;
}

export interface IQuestionAnswer extends DraftQuestionAnswer {
  checked?: boolean;
  code: IConcept;
  questions?: IQuestionAnswer[];
  answer?: ITiroElement[] | null;
  required?: boolean;
}

export type DragItem = IQuestionAnswer & { path: number[] };

export enum ActionTypes {
  APPEND_QUESTION = "APPEND_QUESTION",
  EDIT_QUESTION = "EDIT_QUESTION",
  UPDATE_ANSWER = "UPDATE_ANSWER",
  MOVE_QUESTION = "MOVE_QUESTION",
  REMOVE_QUESTION = "REMOVE_QUESTION",
  TOGGLE_STATE = "TOGGLE_STATE",
}

type AppendQuestion = {
  type: ActionTypes.APPEND_QUESTION;
  question: IQuestionAnswer;
  path: number[];
};

type EditQuestion = {
  type: ActionTypes.EDIT_QUESTION;
  question: IQuestionAnswer;
  path: number[];
};

type MoveQuestion = {
  type: ActionTypes.MOVE_QUESTION;
  src: number[];
  dest: number[];
};

type RemoveQuestion = {
  type: ActionTypes.REMOVE_QUESTION;
  path: number[];
};

type ToggleState = {
  type: ActionTypes.TOGGLE_STATE;
  path: number[];
};

type UpdateAnswer = {
  type: ActionTypes.UPDATE_ANSWER;
  path: number[];
  answer: ITiroElement[];
};

export type QAAction =
  | AppendQuestion
  | EditQuestion
  | MoveQuestion
  | RemoveQuestion
  | ToggleState
  | UpdateAnswer;
