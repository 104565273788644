import { FloatingPortal } from "@floating-ui/react";
import { Combobox } from "@headlessui/react";
import Button from "@/components/base/CodeCombobox/Button";
import CodeComboboxResults from "@/components/base/CodeCombobox/CodeComboboxResults";
import ExpansionPropertiesForm from "@/components/base/CodeCombobox/ExpansionPropertiesForm";
import useCodeCombobox from "@/components/base/CodeCombobox/useCodeCombobox";
import CodingCard from "@/components/base/CodingCard/CodingCard";
import { CodingModel } from "@/data-models/value-models/structs";
import { IHumanReadableCoding } from "@/data-models/value-models/types";
import {
  Control,
  FieldPathByValue,
  FieldValues,
  useController,
} from "react-hook-form";
import { is } from "superstruct";

export function CodingCardField<TFieldValues extends FieldValues>({
  name,
  label,
  defaultCoding,
  valueSet,
  control,
}: {
  name: FieldPathByValue<TFieldValues, IHumanReadableCoding | null>;
  label?: string;
  defaultCoding?: IHumanReadableCoding | null;
  valueSet: string;
  className?: string;
  control?: Control<TFieldValues>;
}) {
  const {
    query,
    filteredCodes,
    handleQueryChange,
    by,
    displayValue,
    handleCodeChange,
    referenceProps,
    floatingProps,
    fetchStatus,
    helpers,
    expansionProps,
    setExpansionProps,
  } = useCodeCombobox(valueSet, {
    allowedPlacements: ["bottom-start", "bottom-end"],
  });
  const { field } = useController({
    name,
    defaultValue: defaultCoding as any,
    rules: { validate: (value) => is(value, CodingModel) },
    control,
  });
  return field.value ? (
    <CodingCard {...(field.value as IHumanReadableCoding)} />
  ) : (
    <Combobox
      as="div"
      className="max-w-md"
      value={field.value as IHumanReadableCoding | null}
      onChange={handleCodeChange(field.onChange)}
      by={by}
      onBlur={field.onBlur}
    >
      {({ open }) => (
        <div className="">
          <Combobox.Label className="block text-sm font-normal leading-6 text-gray-900">
            {label}
          </Combobox.Label>
          <div className="flex gap-x-1">
            <div {...referenceProps} className="relative grow rounded-md">
              <Combobox.Input
                autoComplete="off"
                className="w-full rounded-md border-0 bg-white py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                value={query}
                onChange={handleQueryChange}
                displayValue={displayValue}
              />
              <Combobox.Button
                as={Button}
                loading={fetchStatus.isFetching}
                error={fetchStatus.isError}
                success={fetchStatus.isSuccess}
              />
              <FloatingPortal>
                <CodeComboboxResults
                  {...floatingProps}
                  open={open && query.trim().length > 0}
                  minimal
                  codes={filteredCodes}
                  helpers={helpers}
                />
              </FloatingPortal>
            </div>
            <ExpansionPropertiesForm
              value={expansionProps}
              onChange={(partialProps) =>
                setExpansionProps((expansionProps) => ({
                  ...partialProps,
                  ...expansionProps,
                }))
              }
            />
          </div>
        </div>
      )}
    </Combobox>
  );
}

export default CodingCardField;
