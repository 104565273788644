import { ChevronDownIcon } from "@heroicons/react/24/outline";
import classNames from "classnames";
import { HTMLProps } from "react";

export interface MinimizeButtonProps
  extends Omit<HTMLProps<HTMLButtonElement>, "type" | "children"> {
  hidden?: boolean;
  open?: boolean;
}
function MinimizeButton({
  open,
  hidden,
  disabled,
  tabIndex = -1,
  className,
  ...attributes
}: MinimizeButtonProps) {
  return (
    <button
      type="button"
      tabIndex={tabIndex}
      disabled={disabled || hidden}
      className={classNames(
        {
          invisible: hidden,
          "text-gray-primary": !hidden,
        },
        className,
      )}
      {...attributes}
    >
      <ChevronDownIcon
        className={classNames(
          "h-4 w-4 stroke-current transition-transform ease-in-out",
          { "-rotate-90": !open },
        )}
      />
    </button>
  );
}
export default MinimizeButton;
