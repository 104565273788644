import { ICodeableConcept, IHumanReadableCoding } from "./types";
/**
 * @deprecated Move this code to the Code, Coding, and CodeableConcept classes
 * @param code
 * @returns
 */
export function createCodeKey(code: ICodeableConcept) {
  const userSelectedCode = code.coding.find((code) => code.userSelected);
  const firstCode = code.coding[0];
  const selectedCode = userSelectedCode ?? firstCode;
  return `${selectedCode.system}|${selectedCode.code}|${code.text}`;
}

export const createCodingKey = (coding: IHumanReadableCoding) =>
  `${coding.system}|${coding.code}`;
