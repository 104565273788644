import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import {
  LexicalTypeaheadMenuPlugin,
  MenuTextMatch,
} from "@lexical/react/LexicalTypeaheadMenuPlugin";
import { TextNode } from "lexical";
import { IHumanReadableCoding } from "@/data-models/value-models/types";
import { useCallback, useEffect, useRef, useState } from "react";
import { createValueSetExpansionQuery } from "@/services/content/useValueSetExpansion";
import { $createMentionNode, MentionNode } from "./MentionNode";
import { DefinedValueSet } from "@/services/content/content-client";
import { v5 } from "uuid";
import { createPortal } from "react-dom";
import { useMutation, useQuery } from "react-query";
import { useMentionCallbacks } from "./useMentionCallbacks";
import {
  MentionTypeaheadOption,
  MentionsTypeaheadMenuItem,
} from "./MentionTypeaheadOption";
import useMentionKeyToIdMap from "./useMentionKeyToIdMap";
import { ICoding } from "@/data-models/value-models";
import client from "@/services/content/client";
import { is, string } from "superstruct";
import useEditorFocus from "../utils/useEditorFocus";

const CODESYSTEM_NAMESPACE = v5.URL;

const PUNCTUATION =
  "\\.,\\+\\*\\?\\$\\@\\|#{}\\(\\)\\^\\-\\[\\]\\\\/!%'\"~=<>_:;";
const NAME = "\\b[A-Z][^\\s" + PUNCTUATION + "]";

const DocumentMentionsRegex = {
  NAME,
  PUNCTUATION,
};

const PUNC = DocumentMentionsRegex.PUNCTUATION;

const TRIGGERS = ["/"].join("");

// Chars we expect to see in a mention (non-space, non-punctuation).
const VALID_CHARS = "[^" + TRIGGERS + PUNC + "\\s]";

// Non-standard series of chars. Each series must be preceded and followed by
// a valid char.
const VALID_JOINS =
  "(?:" +
  "\\.[ |$]|" + // E.g. "r. " in "Mr. Smith"
  " |" + // E.g. " " in "Josh Duck"
  "[" +
  PUNC +
  "]|" + // E.g. "-' in "Salier-Hellendag"
  ")";

const LENGTH_LIMIT = 75;

/**
 * Regex used to match mentions.
 * Example:
 *
 */
const MentionsRegex = new RegExp(
  "(^|\\s|\\()(" +
    "[" +
    TRIGGERS +
    "]" +
    "((?:" +
    VALID_CHARS +
    VALID_JOINS +
    "){0," +
    LENGTH_LIMIT +
    "})" +
    ")$",
);

// 50 is the longest alias length limit.
const ALIAS_LENGTH_LIMIT = 50;

// Regex used to match alias.
const MentionsRegexAliasRegex = new RegExp(
  "(^|\\s|\\()(" +
    "[" +
    TRIGGERS +
    "]" +
    "((?:" +
    VALID_CHARS +
    "){0," +
    ALIAS_LENGTH_LIMIT +
    "})" +
    ")$",
);

/**
 * Checks for mentions that start with the provided trigger character.
 * @param text  The text to check for mentions.
 * @param minMatchLength  The minimum length of the mention to match.
 * @returns
 */
function checkForMentions(
  text: string,
  minMatchLength: number,
): MenuTextMatch | null {
  let match = MentionsRegex.exec(text);
  if (match === null) {
    // Check for alias mentions. Alias mentions are mentions that start with
    // the trigger character but don't have any non-space or non-punctuation
    match = MentionsRegexAliasRegex.exec(text);
  }

  if (match !== null) {
    // The strategy ignores leading whitespace but we need to know it's
    // length to add it to the leadOffset
    const maybeLeadingWhitespace = match[1];

    const matchingString = match[3];
    if (matchingString.length >= minMatchLength) {
      return {
        leadOffset: match.index + maybeLeadingWhitespace.length,
        matchingString,
        replaceableString: match[2],
      };
    }
  }
  return null;
}

/**
 *
 * @param text
 * @returns
 */
function getPossibleQueryMatch(text: string): MenuTextMatch | null {
  const match = checkForMentions(text, 1);
  return match;
  //return match === null ? checkForCapitalizedNameMentions(text, 3) : match;
}

type MentionPluginProps = {
  valueSet: string | DefinedValueSet;
  onAddEntity?: (id: string, code: IHumanReadableCoding) => void;
  onRemoveEntity?: (id: string) => void;
  initialEntityMap?:
    | Map<string, IHumanReadableCoding>
    | Record<string, IHumanReadableCoding>;
};

export default function MentionPlugin({
  valueSet,
  onAddEntity,
  onRemoveEntity,
}: MentionPluginProps): JSX.Element | null {
  const [editorHasFocus, ref] = useEditorFocus();

  const [editor] = useLexicalComposerContext();
  const [query, setQuery] = useState<string | null>(null);
  const queryRef = useRef<string | null>(query);
  queryRef.current = query;

  const nodeKeyToId = useMentionKeyToIdMap(editor);

  const { onAddMention, onRemoveMention } =
    useMentionCallbacks<IHumanReadableCoding>({
      onAddEntity,
      onRemoveEntity,
      nodeKeyToId,
    });

  useEffect(() => {
    const removeMutationListener = editor.registerMutationListener(
      MentionNode,
      (mutations) => {
        for (const [nodeKey, mutation] of mutations) {
          switch (mutation) {
            case "destroyed": {
              editor.getEditorState().read(() => {
                console.debug("destroyed", nodeKey);
                onRemoveMention(nodeKey);
              });
              break;
            }
            case "updated":
            case "created":
              break;
          }
        }
      },
    );
    return removeMutationListener;
  }, [editor, onRemoveMention]);

  const { mutate: registerCodeUsedEvent } = useMutation({
    mutationFn: (code: ICoding) =>
      client.events.createEventV2({
        requestBody: {
          query: query ?? "",
          coding: code,
          type: "USER_PICKED_CODE",
          valueSet: is(valueSet, string()) ? valueSet : valueSet.url,
        },
      }),
  });

  const { mutate: validateCode } = useMutation({
    mutationFn: (code: ICoding) =>
      client.r5.validateValuesetCodeR5({
        url: is(valueSet, string()) ? valueSet : valueSet.url,
        ...code,
      }),
  });

  const { data: options = [] } = useQuery({
    ...createValueSetExpansionQuery(valueSet, {
      filter: query ?? undefined,
      count: 10,
      prefix: true,
      fuzzy: true,
      acronym: true,
    }),
    select: (valueSet) =>
      valueSet.expansion.contains?.map(MentionTypeaheadOption.fromExpansion),
    keepPreviousData: true,
  });

  const onSelectOption = useCallback(
    (
      selectedOption: MentionTypeaheadOption,
      nodeToReplace: TextNode | null,
      closeMenu: () => void,
    ) => {
      editor.update(() => {
        const linkId = v5(
          selectedOption.code,
          v5(selectedOption.system, CODESYSTEM_NAMESPACE),
        );
        const { code, system, display } = selectedOption;
        const href = `#${linkId}`;
        const mentionNode = $createMentionNode(selectedOption.display, href);
        onAddMention(mentionNode.getKey(), linkId, { code, system, display });
        validateCode({ code, system, display });
        registerCodeUsedEvent({ code, system, display });

        if (nodeToReplace) nodeToReplace.replace(mentionNode);
        mentionNode.select();
        closeMenu();
      });
    },
    [editor, onAddMention, registerCodeUsedEvent, validateCode],
  );

  return (
    <LexicalTypeaheadMenuPlugin<MentionTypeaheadOption>
      onQueryChange={setQuery}
      onSelectOption={onSelectOption}
      triggerFn={getPossibleQueryMatch}
      options={options}
      menuRenderFn={(
        anchorElementRef,
        { selectedIndex, selectOptionAndCleanUp, setHighlightedIndex },
      ) =>
        anchorElementRef.current && options.length && editorHasFocus
          ? createPortal(
              <div
                ref={ref}
                className="typeahead-popover mentions-menu relative top-5 w-fit overflow-hidden rounded border border-gray-200 bg-white shadow-md"
              >
                <ul className="w-fit">
                  {options.map((option, i: number) => (
                    <MentionsTypeaheadMenuItem
                      index={i}
                      isSelected={selectedIndex === i}
                      onClick={() => {
                        setHighlightedIndex(i);
                        selectOptionAndCleanUp(option);
                      }}
                      onMouseEnter={() => {
                        setHighlightedIndex(i);
                      }}
                      key={option.key}
                      option={option}
                    />
                  ))}
                </ul>
              </div>,
              anchorElementRef.current,
            )
          : null
      }
    />
  );
}
