import { useLocation } from "react-router";
import { useSearchParams } from "react-router-dom";
import { Describe, enums, Infer, validate } from "superstruct";

type LaunchHost = "KWS" | "Synops" | "Hippo-PROMS";
export const URI_TO_HOST: Record<string, LaunchHost> = {
  "frame.tiro": "KWS",
  "kws.tiro": "KWS",
  "prosquare.tiro": "KWS",
  "azmm.tiro": "Synops",
  "azmm-staging.tiro": "Synops",
} as const;
const LaunchHostModel: Describe<LaunchHost> = enums([
  "KWS",
  "Synops",
  "Hippo-PROMS",
]);
const useLaunchedFrom = (providedHost: LaunchHost | LaunchHost[]) => {
  return isLaunchedFrom(new URL(window.location.toString()), providedHost);
};

export function isLaunchedFrom(
  url: URL,
  providedHost: LaunchHost | LaunchHost[],
) {
  const params = url.searchParams;
  const [, value] = validate(params.get("host"), LaunchHostModel);
  if (value) {
    return providedHost.includes(value);
  }
  for (const [prefix, host] of Object.entries(URI_TO_HOST)) {
    if (url.hostname.startsWith(prefix)) return providedHost.includes(host);
  }
}

export const LaunchedFromStory = ({
  host: providedHost,
}: {
  host: Infer<typeof LaunchHostModel>;
}) => {
  const isProvidedHost = useLaunchedFrom(providedHost);
  return (
    <span>
      Is current host
      <code className="rounded-sm border-blue-700 px-1 py-0.5">
        {providedHost}
      </code>
      ? <strong>{JSON.stringify(isProvidedHost)}</strong>
    </span>
  );
};

export default useLaunchedFrom;
