import classNames from "classnames";
import React from "react";

interface SectionProps extends React.HTMLAttributes<HTMLDivElement> {
  title: string;
  score?: number | string;
}

function Section({
  title,
  score,
  children,
  className,
  ...attrs
}: SectionProps) {
  return (
    <div
      className={classNames(className, "border-b border-gray-light pt-4")}
      {...attrs}
    >
      {score && (
        <div>
          <p className="px-2 text-sm text-gray-primary">Score</p>
          <h2 className="px-2 text-3xl font-semibold text-gray-dark">73</h2>
        </div>
      )}
      <div className="pt- px-2">
        <p className="text-sm text-gray-primary">Aanbevelingen voor</p>
        <h3 className="break-words text-lg font-semibold text-gray-dark hover:text-blue-500">
          {title}
        </h3>
      </div>
      {children}
    </div>
  );
}

function InfoSideBar({
  children,
  className,
  ...attrs
}: React.HTMLAttributes<HTMLDivElement>) {
  return (
    <div className={classNames("overflow-scroll", className)} {...attrs}>
      {children}
    </div>
  );
}
InfoSideBar.Section = Section;

export default InfoSideBar;
