import { PencilSquareIcon } from "@heroicons/react/20/solid";
import Tippy from "@tippyjs/react";
import classNames from "classnames";
import { Concept } from "@/data-models/concept";
import React from "react";

export interface DropdownEntityTooltipProps
  extends React.HTMLAttributes<HTMLDivElement> {
  valueset: string;
  entity?: Concept;
  children: React.ReactElement;
  onEdit?: () => void;
}
const DropdownEntityTooltip = ({
  valueset,
  entity,
  children,
  className,
  onEdit,
  ...attributes
}: DropdownEntityTooltipProps) => {
  const code = entity?.coding[0];
  return (
    <Tippy
      delay={[500, 50]}
      interactive
      content={
        <div
          className={classNames(
            "space-x flex select-none items-center divide-x",
            className
          )}
          {...attributes}
        >
          {entity ? (
            <div className="px-1">
              <span>{code?.display ?? ""}</span>
              {entity.semanticContext && (
                <>
                  <span> - </span>
                  <span>{entity.semanticContext.display}</span>
                </>
              )}
            </div>
          ) : (
            <div className="px-1">
              <span>{valueset}</span>
            </div>
          )}
          {onEdit && (
            <button
              title="Bewerk keuzelijst"
              type="button"
              onClick={onEdit}
              className="inline"
            >
              <PencilSquareIcon className="mx-1 h-3 w-3" />
            </button>
          )}
        </div>
      }
    >
      {children}
    </Tippy>
  );
};
export default DropdownEntityTooltip;
