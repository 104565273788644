import classNames from "classnames";
import React from "react";

export interface RadioBoxProps
  extends Omit<React.InputHTMLAttributes<HTMLInputElement>, "type"> {
  error?: boolean;
  small?: boolean;
  extraSmall?: boolean;
}
function RadioBox(
  { className, error, small, extraSmall, ...props }: RadioBoxProps,
  ref?: React.Ref<HTMLInputElement>
) {
  return (
    <input
      ref={ref}
      type="radio"
      className={classNames(
        "rounded-full border-gray-light",
        {
          "text-blue-primary focus:ring-blue-primary": !error,
          "text-red-500 ring-red-500": error,
          "h-5 w-5": !small && !extraSmall,
          "h-4 w-4": small,
          "h-3 w-3": extraSmall,
        },
        className
      )}
      {...props}
    />
  );
}

export default RadioBox;
