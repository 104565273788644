import { CONFIG } from "@/config";
import { LoaderFunction } from "react-router";
import { redirect } from "react-router-dom";
import {
  generateCodeChallengeFromVerifier,
  generateCodeVerifier,
} from "@/util/pkce";
import { v4 } from "uuid";
import { z } from "zod";
import {
  SMART_ISS_TO_CLIENT_ID,
  SMART_ISS_TO_CLIENT_SECRET,
  SMART_ISS_TO_DEFAULT_SCOPE,
} from "../consts";

const DEFAULT_SCOPE = "launch/patient launch/encounter openid fhirUser";

const loader: LoaderFunction = async ({ request }) => {
  const url = new URL(request.url);
  const params = new URLSearchParams(url.search);
  const iss = params.get("iss");
  const launch = params.get("launch");
  if (!iss) {
    throw new Error(
      "Invalid SMART on FHIR launch request. Please provide both 'iss' and 'launch' parameters."
    );
  }

  let wellKnownUrl = `${iss}/.well-known/smart-configuration`;
  let wellKnownConfig = await fetch(wellKnownUrl, {
    headers: {
      Accept: "application/json",
      "ngrok-skip-browser-warning": "true",
    },
  })
    .then((res) => {
      if (res.status != 200)
        throw new Error(
          `Invalid SMART on FHIR well-known configuration: ${res.status}`
        );
      return res.json();
    })
    .then(SMARTOnFHIRWellKnownConfigSchema.parse)
    .catch((error) => {
      if (error instanceof z.ZodError) {
        throw new Error(
          `Invalid SMART on FHIR well-known configuration: ${error.message}`
        );
      }
      throw error;
    });

  const client_id = SMART_ISS_TO_CLIENT_ID.get(iss);
  const client_secret = SMART_ISS_TO_CLIENT_SECRET.get(iss);
  const scope = SMART_ISS_TO_DEFAULT_SCOPE.get(iss) || DEFAULT_SCOPE;
  const state = v4();
  sessionStorage.setItem("iss", iss);
  sessionStorage.setItem("state", state);
  sessionStorage.setItem(
    "authorization_endpoint",
    wellKnownConfig.authorization_endpoint
  );
  sessionStorage.setItem("token_endpoint", wellKnownConfig.token_endpoint);
  const code_verifier = generateCodeVerifier();
  sessionStorage.setItem("code_verifier", code_verifier);
  const code_challenge = await generateCodeChallengeFromVerifier(code_verifier);

  const launchParamsDraft = {
    response_type: "code",
    client_id,
    client_secret,
    redirect_uri: `${url.origin}/smart/redirect`,
    launch,
    scope,
    state,
    aud: iss,
    code_challenge_method: "S256",
    code_challenge,
  };
  const launchParamsParseResult =
    LaunchParamsSchema.safeParse(launchParamsDraft);

  // When in production and if params are valid, redirect to the authorization endpoint
  if (!CONFIG.isDev && launchParamsParseResult.success) {
    const url = new URL(wellKnownConfig.authorization_endpoint);
    Object.entries(launchParamsParseResult.data).forEach(([key, value]) => {
      url.searchParams.set(key, value);
    });
    return redirect(url.toString());
  }

  return {
    defaultValues: launchParamsDraft,
    action: wellKnownConfig.authorization_endpoint,
    urlParams: { iss, launch },
  };
};

const SMARTOnFHIRWellKnownConfigSchema = z.object({
  authorization_endpoint: z.string(),
  token_endpoint: z.string(),
});

const LaunchParamsSchema = z.object({
  response_type: z.literal("code"),
  client_id: z.string(),
  client_secret: z.string(),
  redirect_uri: z.string(),
  launch: z.string(),
  scope: z.string(),
  state: z.string(),
  aud: z.string(),
});

export type LoaderData = {
  action: string;
  defaultValues: {
    response_type: string;
    redirect_uri: string;
    scope: string;
    aud: string;
    state: string;
    launch: string;
    client_id: string;
    client_secret: string;
    code_challenge_method: string;
    code_challenge: string;
  };
  urlParams: {
    iss: string;
    launch: string;
  };
};
export default loader;
