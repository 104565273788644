import React from "react";
import {
  FieldArrayPath,
  FieldValues,
  useFieldArray,
  UseFieldArrayProps,
  UseFieldArrayReturn,
} from "react-hook-form";
import {
  UniqueIdentifier,
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
  DragEndEvent,
} from "@dnd-kit/core";
import { CSS } from "@dnd-kit/utilities";
import {
  useSortable,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";

const IsDraggingContext = React.createContext(false);

export function useIsDragging() {
  return React.useContext(IsDraggingContext);
}

export function SortableItem({
  id,
  children,
  inline,
}: React.PropsWithChildren<{ id: string; inline?: boolean }>) {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };
  if (inline) {
    return (
      <div
        ref={setNodeRef}
        style={{ ...style, display: "inline-block" }}
        {...attributes}
        {...listeners}
      >
        <IsDraggingContext.Provider value={isDragging}>
          {children}
        </IsDraggingContext.Provider>
      </div>
    );
  }

  return (
    <div ref={setNodeRef} style={style} {...attributes} {...listeners}>
      <IsDraggingContext.Provider value={isDragging}>
        {children}
      </IsDraggingContext.Provider>
    </div>
  );
}
function SortableFieldArray<
  TFieldValues extends FieldValues = FieldValues,
  TFieldArrayName extends FieldArrayPath<TFieldValues> = FieldArrayPath<TFieldValues>,
  TKeyName extends string = "id"
>({
  children: childrenFunc,
  ...props
}: UseFieldArrayProps<TFieldValues, TFieldArrayName, TKeyName> & {
  children: (
    props: UseFieldArrayReturn<TFieldValues, TFieldArrayName, TKeyName>
  ) => React.ReactNode;
}) {
  const results = useFieldArray<TFieldValues, TFieldArrayName, TKeyName>(props);
  const { move, fields } = results;
  const items: UniqueIdentifier[] = fields.map(
    //@ts-ignore
    (field) => field.id as unknown as string
  );
  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        delay: 50,
        tolerance: 5,
      },
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragEnd={handleDragEnd}
    >
      <SortableContext items={items} strategy={verticalListSortingStrategy}>
        {childrenFunc(results)}
      </SortableContext>
    </DndContext>
  );
  function handleDragEnd(event: DragEndEvent) {
    const { active, over } = event;

    if (over && active.id !== over.id) {
      const oldIndex = items.indexOf(active.id);
      const newIndex = items.indexOf(over.id);
      move(oldIndex, newIndex);
    }
  }
}
export default SortableFieldArray;
