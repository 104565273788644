export const ENTRY_COLLECTION = "collection";
export const SUBTITLE_ENTRY = "entry-subtitle";
export const SUBSECTION_ENTRY = "entry-subsection";
export const FREE_TEXT_ENTRY = "entry-free-text";
export const QA_ENTRY = "entry-qa";
export const QA_QUESTION = "entry-qa-question";
export const QA_ANSWER = "entry-qa-answer";
export const TIME_ENTRY = "entry-time";
export const TIME_TIMESTAMP = "entry-time-timestamp";
export const TIME_DESCRIPTION = "entry-time-description";
export const FIELD_ENTRY_ELEMENT = "entry-field";
