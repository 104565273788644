import { EnvelopeIcon } from "@heroicons/react/24/outline";
import classNames from "classnames";

export interface MailToButtonProps {
  label: string;
  email: string;
  subject?: string;
  body?: string;
  className?: string;
  isOutline?: boolean;
}

export default function MailToButton({
  label,
  email,
  subject,
  body,
  className,
  isOutline = false,
}: MailToButtonProps) {
  return (
    <a
      href={encodeURI(`mailto:${email}?subject=${subject}&body=${body}`)}
      className={classNames(
        "inline-flex items-center rounded px-2 py-1",
        {
          "bg-gray-dark text-white hover:bg-gray-primary": !isOutline,
          "border border-gray-dark text-primary": isOutline,
        },
        className
      )}
    >
      <EnvelopeIcon className="mr-2 h-4 w-4 stroke-current" /> {label}
    </a>
  );
}
