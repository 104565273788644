import { RenderElementProps } from "slate-react";
import { IEditorPlugin } from "../Base/types";
import {
  RenderCalculatorElement,
  RenderCalculatorElementProps,
} from "./RenderCalculatorElement";
import CalculatorElement from "./CalculatorElement";
import { CALCULATOR_ELEMENT } from "./consts";

const CalculatorPlugin: IEditorPlugin = {
  renderElement: (props: RenderElementProps) => {
    const { element } = props;
    if (CalculatorElement.isCalculatorElement(element))
      return (
        <RenderCalculatorElement {...(props as RenderCalculatorElementProps)} />
      );
  },
  inlineTypes: [CALCULATOR_ELEMENT],
  voidTypes: [CALCULATOR_ELEMENT],
};

export default CalculatorPlugin;
