import { CellProps } from "./Cells";
import DateInput from "@/components/inputs/DateInput";

export interface DateCellProps extends Omit<CellProps<"DATE">, "type"> {}

const DateCell = (props: DateCellProps) => {
  return <DateInput name={props.name} style="table" />;
};

export default DateCell;
