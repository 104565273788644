import classNames from "classnames";
import React, { HTMLAttributes, Ref } from "react";

interface ContainerProps extends HTMLAttributes<HTMLDivElement> {}
const QuestionnaireContainerWithoutRef = (
  { className, ...props }: ContainerProps,
  ref: Ref<HTMLDivElement>,
) => (
  <div
    ref={ref}
    className={classNames("divide-y divide-gray-200", className)}
    {...props}
  />
);
const QuestionnaireContainer = React.forwardRef(
  QuestionnaireContainerWithoutRef,
);
interface HeaderProps extends HTMLAttributes<HTMLDivElement> {
  title: string;
  description?: string;
}
const Header = ({ children, title, description, ...props }: HeaderProps) => {
  return (
    <div {...props}>
      <h3 className="text-lg font-medium leading-6 text-gray-900">{title}</h3>
      {description && (
        <p className="mt-1 max-w-2xl text-sm text-gray-primary">
          {description}
        </p>
      )}
      {children}
    </div>
  );
};
interface QuestionListProps extends HTMLAttributes<HTMLDivElement> {}
const QuestionList = ({ className, children, ...props }: QuestionListProps) => (
  <div className={classNames("relative mt-5 w-full", className)} {...props}>
    <dl className="flex flex-col items-stretch">{children}</dl>
  </div>
);
const QuestionnaireLayout = {
  Container: QuestionnaireContainer,
  Header: Header,
  Body: QuestionList,
};
export default QuestionnaireLayout;
