import classNames from "classnames";
import { BinIcon } from "@/Icons";
import React, { useContext } from "react";
import { Row } from "react-table";
import { Editor, Range, Transforms } from "slate";
import {
  ReactEditor,
  RenderElementProps,
  useFocused,
  useSlateStatic,
} from "slate-react";
import { ITableRowElement } from ".";
import { CellRenderContext } from "./TableCell";

export const RowRenderContext =
  React.createContext<Row<ITableRowElement> | null>(null);

export interface RenderTableRowElementProps extends RenderElementProps {
  element: ITableRowElement;
}

export const RenderTableRowElement = ({
  attributes,
  element,
  children,
}: RenderTableRowElementProps) => {
  const editor = useSlateStatic() as ReactEditor;
  const focused = useFocused();
  const row = useContext(RowRenderContext);
  if (!row) throw Error("Can't render row outside of a table.");
  const { selection } = editor;
  const path = ReactEditor.findPath(editor, element);
  const rowRange = Editor.range(editor, path);
  const totalOverlap = selection && Range.equals(rowRange, selection);
  const cellChildren = React.Children.toArray(children);
  return (
    <div {...row.getRowProps()} {...attributes} className="relative">
      {row.cells.map((cell, i) => {
        return (
          <CellRenderContext.Provider key={cell.column.id} value={cell}>
            {cellChildren[i]}
          </CellRenderContext.Provider>
        );
      })}
      <button
        type="button"
        contentEditable={false}
        onClick={() => Transforms.select(editor, path)}
        className="absolute inset-y-1 -left-3 z-10 mx-2 w-2 select-none rounded-md bg-opacity-0 hover:bg-gray-400"
      />
      <button
        type="button"
        contentEditable={false}
        onClick={() => Transforms.removeNodes(editor, { at: path })}
        className={classNames(
          "absolute inset-y-0 -left-8 z-10 mx-2 flex select-none items-center rounded-md text-gray-400 text-opacity-0",
          { "text-opacity-100": totalOverlap && focused },
        )}
      >
        <BinIcon className="absolute h-4 w-4 fill-current" />
      </button>
    </div>
  );
};
