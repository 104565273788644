import { PageContainer } from "@/components/layout/PageContainer";
import LogoLink from "@/components/navbar/LogoLink";
import { Nav } from "@/components/navbar/NavBar";
import { ProfileMenu } from "@/components/navbar/ProfileMenu";
import createProtector, {
  PROTECTOR_CONFIG_DEFAULTS,
} from "@/pages/auth/createProtector";
import { QueryClient } from "react-query";
import { RouteObject } from "react-router";
import { TABLE_EXPORT_PATH } from "./consts";
import ExportPanels from "./ExportPanels";

export function createRoute(queryClient: QueryClient): RouteObject {
  return {
    loader: createProtector({ ...PROTECTOR_CONFIG_DEFAULTS, queryClient }),
    path: TABLE_EXPORT_PATH,
    element: <TableExportPage />,
  };
}

function TableExportPage() {
  return (
    <>
      <Nav className="sticky top-0">
        <Nav.Left>
          <Nav.Logo>
            <LogoLink color="white" />
          </Nav.Logo>

          <div className="mr-auto" />
        </Nav.Left>
        <Nav.ProfileSlot>
          <ProfileMenu />
        </Nav.ProfileSlot>
      </Nav>
      <PageContainer
        className="absolute bottom-0 top-14 w-full bg-fill-secondary"
        wide
      >
        <ExportPanels />
      </PageContainer>
    </>
  );
}
