import UnknownError from "@/components/error-displays/UnknownError/UnknownError";
import { CONFIG } from "@/config";
import { UnauthenticatedWhileEmbeddedError } from "@/errors";
import { useTranslation } from "react-i18next";
import { QueryClient } from "react-query";
import { RouteObject, useRouteError } from "react-router";
import PageContainer from "../PageContainer";
import { LOGIN_PATH } from "./const";
import createAction from "./createAction";
import createLoader from "./createLoader";
import EmailPassLoginPanel from "./EmailPassLoginPanel";
import Separator from "./Separator";
import SSOPanel from "./SSOPanel";

export function createRoute(queryClient: QueryClient): RouteObject {
  return {
    path: LOGIN_PATH,
    action: createAction({ queryClient }),
    loader: createLoader(queryClient),
    element: <LoginPage />,
    errorElement: <Error />,
  };
}

const LoginPage = () => {
  const { t } = useTranslation();
  return (
    <PageContainer
      logo
      title={t("sign-in.title")!}
      className="absolute inset-y-0 mx-auto w-full"
    >
      {!CONFIG.isDemo && (
        <>
          <SSOPanel />
          <Separator />
        </>
      )}
      <EmailPassLoginPanel />
    </PageContainer>
  );
};

const Error = () => {
  const error = useRouteError();
  if (error instanceof UnauthenticatedWhileEmbeddedError)
    return (
      <UnknownError
        title="We kunnen niet integreren met je EPD"
        description="Om Atticus te integreren met je EPD hebben we een ID token nodig die de huidige gebruiker identificeert."
      />
    );
  throw error;
};
