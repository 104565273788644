import classNames from "classnames";
import React from "react";

export interface InputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  error?: boolean;
  small?: boolean;
  extraSmall?: boolean;
  children?: React.ReactNode;
}
function Input_(
  { className, error, small, extraSmall, ...props }: InputProps,
  ref?: React.Ref<HTMLInputElement>
) {
  return (
    <input
      ref={ref}
      className={classNames(
        "block w-full appearance-none border placeholder-gray-primary focus:outline-none",
        {
          "border-gray-light focus:border-blue-primary focus:ring-1 focus:ring-blue-primary":
            !error,
          "border-red-500 ring-1 ring-red-500": error,
          "rounded-md px-3 py-2 sm:text-sm": !small && !extraSmall,
          "rounded px-2 py-0.5 sm:text-sm": small,
          "rounded px-2 py-0.5 text-sm sm:text-xs": extraSmall,
        },
        className
      )}
      {...props}
    />
  );
}

const Input = React.forwardRef(Input_);

export default Input;
