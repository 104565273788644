import { PlusIcon } from "@heroicons/react/24/outline";
import { CogIcon } from "@heroicons/react/20/solid";
import HeadlessTippy from "@tippyjs/react/headless";
import Tippy from "@tippyjs/react";
import React, { useCallback, useMemo } from "react";
import {
  Accessor,
  useFlexLayout,
  useResizeColumns,
  useTable,
} from "react-table";
import { Node, Transforms } from "slate";
import { ReactEditor, useSlateStatic } from "slate-react";
import { ITableCellElement, ITableElement, ITableRowElement } from ".";
import { RenderTypedElementProps } from "../../TypedElement";
import { TABLE_CELL_TYPE, TABLE_ROW_TYPE } from "./consts";
import { RowRenderContext } from "./TableRow";
import JSONEditPanel from "./JSONEditPanel";
import { AllColumnTypes } from "./TablePlugin";
import classNames from "classnames";
import { DROPDOWN_ENTITY_ELEMENT } from "../DropdownPlugin/consts";

export interface RenderTableElementProps extends RenderTypedElementProps {
  element: ITableElement;
}

export const RenderTableElement = ({
  attributes,
  element,
  children,
}: RenderTableElementProps) => {
  const { columns } = element;
  const editor = useSlateStatic() as ReactEditor;
  const handleAddRow = useCallback(() => {
    const cells: ITableCellElement[] = columns.map((c) => ({
      ...c,
      type: TABLE_CELL_TYPE,
      children: [
        "valueSet" in c
          ? {
              type: DROPDOWN_ENTITY_ELEMENT,
              valueSet: c.valueSet,
              inline: false,
              children: [{ text: "" }],
            }
          : { text: "" },
      ],
    }));
    const newRow: ITableRowElement = {
      type: TABLE_ROW_TYPE,
      children: cells,
    };

    const tablePath = ReactEditor.findPath(editor, element);
    const newRowPath = tablePath.concat(element.children.length);
    Transforms.insertNodes(editor, newRow, { at: newRowPath });
  }, [element, columns, editor]);

  const handleAddColumn = useCallback(() => {}, []);

  const handleUpdateColumn = useCallback(
    (newColumn: AllColumnTypes, i: number) => {
      const tablePath = ReactEditor.findPath(editor, element);
      Transforms.setNodes(
        editor,
        {
          columns: [...columns.slice(0, i), newColumn, columns.slice(i)],
        } as Partial<ITableElement>,
        { at: tablePath }
      );
    },
    [columns, editor, element]
  );

  const renderColumns = useMemo(() => {
    return columns.map((c, i) => {
      const { header, dataType, ...other } = c;
      return {
        id: header,
        dataType,
        width:
          dataType === "quantity"
            ? 30
            : dataType === "date"
            ? 45
            : dataType === "concept"
            ? 40
            : 100,
        Header: header,
        accessor: ((row) =>
          Node.string(row.children[i])) as Accessor<ITableRowElement>,
        ...other,
      };
    });
  }, [columns]);

  const defaultColumn = useMemo(
    () => ({
      minWidth: 30,
      width: 150,
      maxWidth: 400,
    }),
    []
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        columns: renderColumns,
        data: element.children,
        defaultColumn,
      },
      useFlexLayout,
      useResizeColumns
    );

  const rowChildren = React.Children.toArray(children);
  return (
    <div {...attributes} className="relative mb-6">
      <div
        {...getTableProps()}
        className="not-prose group -mx-3 !mb-3 !mt-1 !table-fixed border-collapse select-none px-5"
      >
        <div contentEditable={false} className="select-none">
          {headerGroups.map((headerGroup) => (
            // eslint-disable-next-line react/jsx-key
            <div
              {...headerGroup.getHeaderGroupProps()}
              className="relative max-h-min bg-gray-50 current-block-focus-within:bg-gray-light"
            >
              {headerGroup.headers.map((column, index) => (
                // eslint-disable-next-line react/jsx-key
                <div
                  {...column.getHeaderProps()}
                  className="relative border border-gray-200 px-2 py-1.5 text-sm font-semibold current-block-focus-within:border-gray-light"
                >
                  {column.render("Header")}
                  <HeadlessTippy
                    render={() => (
                      <JSONEditPanel
                        title="Edit column props"
                        initialValues={columns[index]}
                        onSubmit={(newColumn) =>
                          handleUpdateColumn(newColumn, index)
                        }
                      />
                    )}
                    interactive
                    trigger="click"
                    placement="bottom"
                  >
                    <button type="button" className="px-1">
                      <CogIcon className="inline h-4 w-4 fill-current text-gray-400" />
                    </button>
                  </HeadlessTippy>
                  <div
                    {...(column as any).getResizerProps()}
                    className={classNames(
                      "absolute inset-y-0 -right-1 z-10 w-1.5 cursor-col-resize bg-gray-primary bg-opacity-0 hover:bg-opacity-100",
                      { invisible: index === columns.length - 1 }
                    )}
                  />
                </div>
              ))}
            </div>
          ))}
        </div>
        <div {...getTableBodyProps()} className="select-none">
          {rows.map((row, i) => {
            prepareRow(row);
            return (
              <RowRenderContext.Provider key={row.id} value={row}>
                {rowChildren[i]}
              </RowRenderContext.Provider>
            );
          })}
        </div>
      </div>
      <Tippy content="Voeg rij toe" delay={[500, 50]}>
        <button
          type="button"
          onClick={handleAddRow}
          className="absolute -bottom-6 flex w-full select-none items-center justify-center rounded-sm bg-gray-light bg-opacity-0 p-0.5 text-primary text-opacity-0 transition delay-75 ease-in hover:bg-opacity-100 hover:text-opacity-100"
        >
          <PlusIcon className="h-4 w-4 stroke-current" />
        </button>
      </Tippy>
      <Tippy content="Voeg kolom toe" delay={[500, 50]}>
        <button
          type="button"
          onClick={handleAddColumn}
          className="absolute -right-6 top-0 flex h-full select-none flex-col items-center justify-center bg-gray-light bg-opacity-0 p-0.5 text-primary text-opacity-0 transition delay-75 ease-in hover:bg-opacity-100 hover:text-opacity-100"
        >
          <PlusIcon className="storke-current h-4 w-4" />
        </button>
      </Tippy>
    </div>
  );
};
