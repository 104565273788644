import classNames from "classnames";
import { ICoding } from "@/data-models/value-models";

const SEMANTIC_TO_CLASSES = {
  finding: "text-blue-800 bg-blue-50 dark:text-blue-100 dark:bg-blue-800",
  disorder:
    "text-emerald-800 bg-emerald-50 dark:text-emerald-100 dark:bg-emerald-800",
  procedure:
    "text-purple-800 bg-purple-50  dark:text-purple-200 dark:bg-purple-800",
  "surgical-procedure":
    "text-purple-800 bg-purple-50 dark:text-purple-200 dark:bg-purple-800",
  "evaluation-procedure":
    "text-purple-800 bg-purple-50 dark:text-purple-200 dark:bg-purple-800",
  substance:
    "text-yellow-800 bg-yellow-50 dark:text-yellow-50 dark:bg-yellow-500/40",
  medication:
    "text-orange-800 bg-orange-50 dark:text-orange-200 dark:bg-orange-800/70",
  "qualifier value":
    "text-purple-800 bg-purple-50 dark:text-purple-200 dark:bg-purple-500/50",
  unknown: "text-gray-800 bg-gray-50 dark:text-gray-200 dark:bg-gray-800",
} as const;

export function getSemanticClasses(code: string) {
  return (
    SEMANTIC_TO_CLASSES[code as keyof typeof SEMANTIC_TO_CLASSES] ??
    SEMANTIC_TO_CLASSES.unknown
  );
}
type SemanticProps = ICoding & {
  minimal?: boolean;
  small?: boolean;
  active?: boolean;
};

/**
 * Styled `span` to display the semantic axis of a coding.
 */
function Semantic({ code, display, minimal, small, active }: SemanticProps) {
  return (
    <span
      className={classNames(
        "whitespace-nowrap rounded-sm px-1.5 py-0.5 text-sm",
        {
          [getSemanticClasses(code)]: !minimal,
          "text-gray-500": minimal && !active,
          "text-blue-100": minimal && active,
        },
        { "text-sm": !small, "text-xs": small }
      )}
    >
      {display}
    </span>
  );
}
export default Semantic;
