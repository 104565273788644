import React, { RefObject } from "react";

export interface HandleBlurProps<TElem extends HTMLElement> {
  onFocusOutside?: (event?: React.FocusEvent<TElem>) => any;
  onFocusInside?: (event?: React.FocusEvent<TElem>) => any;
  onFocusSelf?: (event?: React.FocusEvent<TElem>) => any;
  onFocusNull?: (event?: React.FocusEvent<TElem>) => any;
}
interface HandleBlurOptions {
  /** A list of portal nodes that should be considered as "inside" */
  portals?: (RefObject<HTMLElement> | HTMLElement | null)[];
}

/**
 *
 * Handle blur events differently based on next focus target.
 * @param onFocusOutside Handle blur events where next focus target is outside the countainer
 * @param onFocusInside Handle blur events where next focus target is inside the countainer
 * @param onFocusSelf Handle blur events where next focus target is the container itself
 * @returns Event handler that must be past to the element that you want to observe
 *
 * Example usage:
 * ```jsx
 * function MyComponent(){
 *
 *     const onFocusOutside = ()=>console.log("Focus has left my div.")
 *     const onFocusSelf = ()=>console.log("Hay, your focusing my div.")
 *
 *     return <div onBlur={handleBlur({onFocusOutside, onFocusSelf})})} />
 * }
 * ```
 */
function handleBlur<TElem extends HTMLElement>(
  {
    onFocusInside,
    onFocusOutside,
    onFocusSelf,
    onFocusNull,
  }: HandleBlurProps<TElem>,
  options?: HandleBlurOptions
): React.FocusEventHandler<TElem> {
  const { portals = [] } = options || {};
  return (event) => {
    if (event.currentTarget === event.relatedTarget) {
      onFocusSelf && onFocusSelf(event);
      return;
    }

    const isInsidePortal = portals
      .map((obj) => (obj instanceof HTMLElement ? obj : obj?.current))
      .filter((p): p is HTMLElement => !!p)
      .some((portal) => portal.contains(event.relatedTarget));

    const isInsideSelf = event.currentTarget.contains(event.relatedTarget);
    if (isInsidePortal || isInsideSelf) {
      onFocusInside && onFocusInside(event);
      return;
    }
    if (!event.relatedTarget) {
      onFocusNull && onFocusNull(event);
      return;
    }
    onFocusOutside && onFocusOutside(event);
  };
}

export default handleBlur;
