import client from "./client";
import keyFactory from "./keyFactory";

export function createFHIRR5CompositionQuery({
  reportId,
}: {
  reportId: number;
}) {
  return {
    queryKey: keyFactory.getFHIRR5Composition(reportId),
    queryFn: () => client.fhirR5.readCompositionFhirR5({ reportId }),
  };
}
