import classNames from "classnames";
import { enums } from "superstruct";

export const StatusStruct = enums(["draft", "active", "retired", "unknown"]);
export default function StatusTag({
  status,
}: {
  status: "draft" | "active" | "retired" | "unknown";
}) {
  if (status === "unknown") return null;
  return (
    <span
      className={classNames(
        "w-18 inline-flex items-center rounded-md px-1.5 py-0.5 text-center text-xs font-medium ring-1 ring-inset",
        {
          "bg-green-50 text-green-700 ring-green-600/20": status === "active",
          "bg-red-50 text-red-700 ring-red-600/20": status === "retired",
          "bg-blue-50 text-blue-700 ring-blue-600/20": status === "draft",
        }
      )}
    >
      {status}
    </span>
  );
}
