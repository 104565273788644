import { Menu } from "@headlessui/react";
import { UserTimestamp } from "@/data-models/block";
import BlockMenuButton from "./Button";
import BlockMenuLinkItem from "./LinkItem";
import BlockMenuButtonItem from "./ButtonItem";
import BlockMenuItems from "./Items";

export interface BlockMenuProps {
  lastModified?: UserTimestamp;
}

const BlockMenu = {
  Wrapper: Menu,
  Button: BlockMenuButton,
  LinkItem: BlockMenuLinkItem,
  ButtonItem: BlockMenuButtonItem,
  Items: BlockMenuItems,
};

export default BlockMenu;
