/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { models__api__identifier_token__Identifier_Input } from './models__api__identifier_token__Identifier_Input';

export type SubjectIdentifier = {
    reference?: (string | null);
    type?: SubjectIdentifier.type;
    identifier: models__api__identifier_token__Identifier_Input;
    display?: (string | null);
};

export namespace SubjectIdentifier {

    export enum type {
        PATIENT = 'Patient',
    }


}

