import Tippy from "@tippyjs/react";
import classNames from "classnames";
import { FieldValues, Path, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import Badge from "../Badge/Badge";
import StyledInput from "../Badge/StyledInput";
import { BadgeInputProps } from "../types";
import { DATE_PRINT_MAP } from "./formatter";
import { DATE_VALIDATOR_MAP } from "./validator";

export interface DateInputProps<TFieldValues extends FieldValues = FieldValues>
  extends BadgeInputProps<TFieldValues> {
  name: Path<TFieldValues>;
  autoFocus?: boolean;
  defaultMode?: "edit" | "view";
  dateType?: "date" | "month" | "time" | "datetime-local";
  style?: "default" | "table" | "tile";
  switchToPeriod?: () => any;
}

function DateInput<TFieldValues extends FieldValues = FieldValues>({
  name,
  suffix,
  prefix,
  dateType = "date",
  style = "default",
  switchToPeriod,
  defaultMode,
  autoFocus: defaultAutoFocus = false,
  ...props
}: DateInputProps<TFieldValues>) {
  const { t } = useTranslation();
  const { register, getValues, getFieldState, formState } =
    useFormContext<TFieldValues>();

  return (
    <Badge
      name={name}
      style={style}
      defaultMode={defaultMode}
      defaultAutoFocus={defaultAutoFocus}
    >
      <Badge.Editor prefix={prefix} suffix={suffix}>
        {({ autoFocus }) => (
          <>
            <StyledInput
              type={dateType}
              autoFocus={autoFocus}
              invalid={!!getFieldState(name, formState).error}
              {...register(name, {
                ...props,
                pattern: DATE_VALIDATOR_MAP[dateType](t),
              })}
              size={10}
            />
            {switchToPeriod && (
              <SwitchToPeriod switchToPeriod={switchToPeriod} style={style} />
            )}
          </>
        )}
      </Badge.Editor>
      <Badge.Viewer prefix={prefix} suffix={suffix}>
        {() => DATE_PRINT_MAP[dateType](getValues(name) ?? "")}
      </Badge.Viewer>
    </Badge>
  );
}

DateInput.layout = "INLINE";
export default DateInput;

interface SwitchToPeriodProps {
  switchToPeriod: () => any;
  style: "table" | "tile" | "default";
}

function SwitchToPeriod({ switchToPeriod, style }: SwitchToPeriodProps) {
  const { t } = useTranslation();
  return (
    <span
      className={classNames(
        "ml-1 inline-flex items-center gap-1 hover:text-primary",
        {
          "text-gray-light": style === "table",
          "text-gray-primary": style !== "table",
        },
      )}
    >
      &rarr;
      <Tippy delay={[500, 50]} content={t("add-end-date")} placement="right">
        <button
          type="button"
          onClick={switchToPeriod}
          className={classNames(
            "mx-1 inline-flex h-4 w-4 items-center rounded-lg border bg-white text-xs font-medium shadow-sm focus-within:border-blue-primary hover:border-blue-primary hover:bg-blue-primary hover:text-white focus:border focus:border-blue-primary focus:text-blue-dark focus:outline-none focus:ring-0",
            {
              "border-gray-light text-gray-light": style === "table",
              "border-gray-primary text-gray-dark": style !== "table",
            },
          )}
        >
          <span className="material-symbols-outlined hover-text-white w-4 text-center text-xs hover:text-white ">
            add
          </span>
        </button>
      </Tippy>
    </span>
  );
}
