import { RenderElementProps } from "slate-react";
import { TypedElement } from "../../TypedElement";
import { IEditorPlugin } from "../../Base/types";
import { BADGE_ELEMENT } from "./consts";
import RenderBadgeElement, {
  RenderBadgeElementProps,
} from "./RenderBadgeElement";
import BadgeElement from "./BadgeElement";

const BadgePlugin: IEditorPlugin = {
  renderElement: (props: RenderElementProps) => {
    const { element } = props;
    if (!TypedElement.isTypedElement(element)) return;
    if (BadgeElement.isBadgeElement(element)) {
      return <RenderBadgeElement {...(props as RenderBadgeElementProps)} />;
    }
  },
  inlineTypes: [BADGE_ELEMENT],
  voidTypes: [BADGE_ELEMENT],
};

export default BadgePlugin;
