import { ITiroQuantity } from "@/data-models/value-models";

const DECIMAL_REGEX = /([1-9]\d*|\d)([.,]?\d+)?/;
export const reformatQuantity = (s: string, q: Pick<ITiroQuantity, "unit">) => {
  const match = DECIMAL_REGEX.exec(s);
  return match && `${match[0]}  ${q.unit}`;
};

export const formatQuantityStringToFhir = (
  s: string,
  q: Omit<ITiroQuantity, "value">
): ITiroQuantity | null => {
  const match = DECIMAL_REGEX.exec(s);
  const value = match && parseFloat(match[0] ?? "");
  if (value && !isNaN(value)) {
    return { value, ...q };
  }
  return null;
};
