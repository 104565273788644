import {
  array,
  optional,
  string,
  nonempty,
  Describe,
  nullable,
  boolean,
  type,
} from "superstruct";
import { Concept, IConcept } from "./concept";
import { IAbstractEntity } from "./entity";
import { CodingModel } from "./value-models/structs";

export interface IConceptEntity extends IConcept {
  type?: "concept";
  printText?: string;
}

export const ConceptEntityModel: Describe<IConceptEntity> = type({
  text: string(),
  printText: optional(string()),
  generated: optional(nullable(boolean())),
  preferred: optional(nullable(boolean())),
  coding: nonempty(array(CodingModel)),
});

export class ConceptEntity
  extends Concept
  implements IConceptEntity, IAbstractEntity
{
  type: "concept";
  printText?: string;
  constructor(o: IConceptEntity) {
    super(o);
    this.type = "concept";
    this.printText = o.printText;
  }
  get isValid(): boolean {
    return this.coding.length == 0;
  }
  get value() {
    return this.text;
  }

  generatePrintText(inlineSCT: boolean = false): string {
    const sctCode = this.coding.find((c) =>
      c.system.startsWith("http://snomed.info/sct")
    );
    if (inlineSCT && sctCode) {
      return `|${sctCode.code}| ${sctCode.display}`;
    } else {
      return this.printText || this.text;
    }
  }
}

export class SCTConceptEntity extends ConceptEntity {}
