import React from "react";
import Scrollbars from "react-custom-scrollbars-2";
import MenuList, { MenuListProps } from "./MenuList";

interface ScrollableMenuListProps extends Omit<MenuListProps, "ref"> {}
function ScrollableMenuListWithoutRef(
  { children, ...props }: ScrollableMenuListProps,
  ref: React.Ref<HTMLUListElement>,
) {
  return (
    <MenuList ref={ref} {...props}>
      <Scrollbars
        tagName="div"
        className="scrollbar content max-h-60 w-full max-w-md overflow-y-hidden"
        autoHeight
        autoHide
      >
        {children}
      </Scrollbars>
    </MenuList>
  );
}

const SuggestionList = React.forwardRef(ScrollableMenuListWithoutRef);

export default SuggestionList;
