import React, {
  useState,
  useCallback,
  useRef,
  useMemo,
  useEffect,
} from "react";
import { IHumanReadableCoding } from "@/data-models/value-models/types";
import client, {
  DefinedValueSet,
  Designation,
  ExpansionContains,
  SemanticAxis,
} from "@/services/content/client";
import { is, string } from "superstruct";
import { CodingModel } from "@/data-models/value-models/structs";
import { autoPlacement, Placement, useFloating } from "@floating-ui/react";
import { useMeasure } from "react-use";
import { ExpansionProperties } from "./types";
import { DesignationModel, IDesignation } from "../CodingCard/models";
import { useQuery } from "react-query";
import { createValueSetExpansionQuery } from "@/services/content/useValueSetExpansion";

export interface CodeComboboxProps {
  /**
   * If true, the combobox will be autofocused on mount
   */
  autoFocus?: boolean;
  /**
   * Canonical URI of the value set to expand
   */
  valueSet: string;
  /**
   * Callback for when the user selects a code
   * @param c IHumanReadableCoding
   * @returns void
   */
  onCodeChange?: (c: IHumanReadableCoding | null) => void;
  /**
   * Initial value of the combobox
   */
  code?: IHumanReadableCoding | null;
}

function by(a: IHumanReadableCoding | null, b: IHumanReadableCoding | null) {
  return a?.code == b?.code && a?.system == b?.system;
}

function displayValue(code: IHumanReadableCoding | null) {
  return code?.display ?? "";
}

function getSemantic(property: ExpansionContains["property"]) {
  const { valueCoding = {} } =
    property?.find((p): p is SemanticAxis => p.code === "semantic_axis") ?? {};
  return is(valueCoding, CodingModel) ? valueCoding : undefined;
}

function getDesignation(
  text: string,
  designation: Designation[] | undefined,
): IDesignation | undefined {
  return designation?.find(
    (d) => d.value === text && is(d, DesignationModel),
  ) as IDesignation | undefined;
}

function getCoding(
  coding: IHumanReadableCoding,
  designation: Designation[] | undefined,
) {
  const { system, code, display } = coding;
  const d = designation?.find(
    (d) =>
      d.use?.code === "900000000000003001" &&
      d.use?.system == "http://snomed.info/sct",
  );
  return { system, code, display: d?.value ?? display };
}
export const VS_CODING_HELPERS = {
  getSemantic,
  getDesignation,
  getCoding,
};
type CodeChangeEventHandler = (code: IHumanReadableCoding | null) => void;

type UseCodeComboboxOptions = {
  allowedPlacements?: Placement[];
  limitWidthToContainer?: boolean;
  disableWhenQueryIsEmpty?: boolean;
  count?: number;
  prefix?: boolean;
  fuzzy?: boolean;
  acronym?: boolean;
  disabled?: boolean;
};
function useCodeCombobox(
  defaultValueSet: string | DefinedValueSet,
  {
    allowedPlacements,
    disableWhenQueryIsEmpty = false,
    limitWidthToContainer = false,
    prefix: defaultPrefix = true,
    fuzzy: defaultFuzzy = true,
    acronym: defaultAcronym = true,
    count: defaultCount = 10,
    disabled = false,
  }: UseCodeComboboxOptions = {},
) {
  const [expansionProps, setExpansionProps] = useState<ExpansionProperties>({
    valueSet: defaultValueSet,
    prefix: defaultPrefix,
    fuzzy: defaultFuzzy,
    acronym: defaultAcronym,
    count: defaultCount,
  });
  const [query, setQuery] = useState<string>("");
  const queryRef = useRef<string>(query); // for use in handleCodeChange
  queryRef.current = query;

  const { prefix, fuzzy, acronym, valueSet, count } = expansionProps;
  const isOpen =
    !(query.trim().length == 0 && disableWhenQueryIsEmpty) && !disabled;
  const {
    data: filteredCodes = [],
    isSuccess,
    isLoading,
    isFetching,
    isError,
    isIdle,
  } = useQuery({
    ...createValueSetExpansionQuery(valueSet!, {
      filter: query,
      prefix,
      fuzzy,
      acronym,
      count,
    }),
    enabled: isOpen,
  });

  // fix position of floating portal
  const { floatingStyles, refs, elements, update } = useFloating({
    middleware: [autoPlacement({ allowedPlacements })],
  });
  // set width of floating portal
  const [measureRef, { width }] = useMeasure();
  useEffect(() => {
    // sync floating portal width with input width
    elements.domReference && measureRef(elements.domReference);
  }, [elements.domReference, measureRef]);

  const handleCodeChange = useCallback(
    (onCodeChange: CodeChangeEventHandler | undefined) =>
      async (code: IHumanReadableCoding | null) => {
        onCodeChange && onCodeChange(code);
        if (!code) return;
        setQuery("");
        await client.events.createEventV2({
          requestBody: {
            query: queryRef.current,
            coding: code,
            type: "USER_PICKED_CODE",
            valueSet: is(valueSet, string()) ? valueSet : valueSet.url,
          },
        });
      },
    [valueSet],
  );

  const handleQueryChange = useCallback(
    (query: string | React.ChangeEvent<HTMLInputElement>) => {
      if (is(query, string())) {
        setQuery(query);
      } else {
        setQuery(query.target.value);
      }
    },
    [],
  );

  // When new codes are fetched, update the floating portal since the width options may have changed the position
  useEffect(() => {
    if (isSuccess) {
      update();
    }
  }, [filteredCodes, isSuccess]);

  //
  const floatingProps = useMemo(
    () => ({
      ref: refs.setFloating,
      style: {
        ...floatingStyles,
        width: limitWidthToContainer ? width : floatingStyles.width,
      },
    }),
    [refs.setFloating, floatingStyles, limitWidthToContainer, width],
  );

  const referenceProps = useMemo(
    () => ({
      ref: refs.setReference,
    }),
    [refs.setReference],
  );

  return {
    query,
    isOpen,
    handleQueryChange,
    filteredCodes,
    handleCodeChange,
    displayValue,
    by,
    helpers: VS_CODING_HELPERS,
    floatingProps,
    referenceProps,
    elements,
    expansionProps,
    setExpansionProps,
    fetchStatus: {
      isSuccess,
      isFetching,
      isLoading,
      isError,
      isIdle,
    },
  };
}
export default useCodeCombobox;
