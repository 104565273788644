import { ConceptSuggestion } from "@/components/legacy/suggestions/ConceptSuggestion";
import { Editor, Range, Transforms } from "slate";
import { instance, is } from "superstruct";
import { ITiroEditor } from "../../Base/editor";
import { ISuggestEditor } from "../Suggestions/SuggestEditor";
import CheckElement from "./CheckElement";

function withCheck<E extends ISuggestEditor & ITiroEditor>(editor: E): E {
  const { isVoid, normalizeNode, insertSuggestion } = editor;
  editor.isVoid = (element) => {
    if (CheckElement.isCheckElement(element)) {
      return !!element.entity;
    }
    return isVoid(element);
  };
  editor.insertSuggestion = (query, position, entity) => {
    const checkEntry = Editor.above(editor, {
      match: CheckElement.isCheckElement,
    });
    if (checkEntry) {
      const [checkElement, checkPath] = checkEntry;
      if (checkElement.entity) return insertSuggestion(query, position, entity);
      if (!is(entity, instance(ConceptSuggestion))) {
        throw Error(
          "Only instances of ConceptSuggestion are allowed inside a check list.",
        );
      }
      Transforms.insertText(editor, "", { at: checkPath });
      Transforms.setNodes(
        editor,
        { entity, checked: false },
        { at: checkPath },
      );
      Transforms.move(editor);
      return;
    }
    return insertSuggestion(query, position, entity);
  };
  editor.normalizeNode = (entry) => {
    const [node, path] = entry;
    if (CheckElement.isCheckElement(node) && !("checked" in node)) {
      Transforms.setNodes(editor, { checked: false });
      return;
    }
    const { selection } = editor;
    const hasCollapsedSelection = selection && Range.isCollapsed(selection);

    const isCheckWithoutEntity =
      CheckElement.isCheckElement(node) && !node.entity;
    const isSelectionInsideCheck =
      hasCollapsedSelection &&
      Range.includes(Editor.range(editor, path), selection);

    //console.log({ isCheckWithoutEntity, isSelectionInsideCheck })
    if (isCheckWithoutEntity && !isSelectionInsideCheck)
      return Transforms.removeNodes(editor, { at: path });
    return normalizeNode(entry);
  };
  /** editor.serializePlainText = (node, inlineSCT) => {
    if (CheckElement.isCheckElement(node) && node.checked) {
      return serializeCheckElement(editor, node, inlineSCT);
    }
    return serializePlainText(node, inlineSCT);
  };*/

  return editor;
}
export default withCheck;
