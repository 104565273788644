import TD, { TopDownFormProps } from "@/components/forms/TopDownForm";
import { CodingModel } from "@/data-models/value-models/structs";
import { DeepPartial, SubmitHandler, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import useValueSetValidator from "@/services/content/useValueSetValidator";
import { assert } from "superstruct";
import { IQuestionItem } from "../..";
import { IEnabledWhenCondition } from "../../models";
import { ConceptFieldSet } from "./ConceptFieldSet";
import { ConditionalityFieldSet } from "./ConditionalityFieldSet";
import { FieldValues } from "./types";
import CodingOptionsFieldSet from "./CodingOptionsFieldSet";
import { createChipsInputElement } from "../../add-question/buildQuestion";

interface QuestionPropertiesFormProps
  extends Omit<TopDownFormProps<FieldValues>, "onSubmit" | "children"> {
  actionLabel: string;
  questionValueSet?: string;
  onSubmit: (q: IQuestionItem) => void;
}

const DEFAULT_VALUES: DeepPartial<FieldValues> = { mode: "data-entry" };

export const EMPTY_CONDITION: IEnabledWhenCondition = {
  operator: "=",
  operand: {
    display: "",
    system: "",
    code: "",
  },
};
export const EMPTY_EXTRACTION = { path: "", context: "", reverse: false };

function QuestionPropertiesForm({
  defaultValues,
  actionLabel,
  onSubmit,
  questionValueSet = "http://tiro.health/fhir/ValueSet/questions",
  ...props
}: QuestionPropertiesFormProps) {
  const { t } = useTranslation();

  const handleUpdateQuestion: SubmitHandler<FieldValues> = (values) => {
    console.log("Submitting", values);
    const { text, coding, enabledWhen, extraction, hidden, required, type } =
      values;
    assert(coding, CodingModel);

    let answer = undefined;
    switch (type) {
      case "coding":
        answer = createChipsInputElement(values.name!, values.options!);
        break;
    }
    const question: IQuestionItem = {
      code: {
        text: text ?? coding?.display ?? "",
        coding: [coding],
      },
      answer,
      enabledWhen: enabledWhen?.conditions.length ? enabledWhen : undefined,
      extraction: extraction?.fhir.path ? extraction : undefined,
      hidden: hidden ?? false,
      required: required ?? false,
      mode: values.mode,
    };
    console.log("Submitting", question);
    onSubmit(question);
  };

  return (
    <TD<FieldValues>
      {...props}
      onSubmit={handleUpdateQuestion}
      defaultValues={{ ...DEFAULT_VALUES, ...defaultValues }}
    >
      {({ register, watch, getValues }) => (
        <div>
          <h3 className="mb-1 text-lg font-semibold leading-5 text-gray-darker">
            {t("question-properties")}
          </h3>
          {getValues("name") && (
            <div>
              <code className="rounded border border-gray-lighter bg-gray-ultralight p-px text-xs">
                {getValues("name")}
              </code>
            </div>
          )}
          <fieldset className="mt-2 block pb-2">
            <TD.Field
              {...register("type")}
              InputComponent={TD.Select as any}
              defaultValue="question"
              disabled
              small
              labelProps={{ className: "!text-gray-primary !font-normal" }}
              label={t("questionnaire-v2-question-type")!}
            >
              <option value="subtitle" className="lowercase">
                {t("subtitle")}
              </option>
              <option value="question" className="lowercase">
                {t("question")}
              </option>
              <option value="group" className="lowercase">
                {t("group")}
              </option>
              <option value="date" className="lowercase">
                {t("date")}
              </option>
              <option value="coding" className="lowercase">
                {t("coding")}
              </option>
              <option value="text" className="lowercase">
                {t("text")}
              </option>
              <option value="quantity" className="lowercase">
                {t("quantity")}
              </option>
            </TD.Field>
          </fieldset>
          <legend className="text-sm font-normal text-gray-primary">
            Code
          </legend>
          <ConceptFieldSet
            name="coding"
            valueSet={questionValueSet}
            className="border-b border-gray-lighter pb-2"
          />
          <TD.Field
            {...register("text")}
            label="Weergavetekst"
            labelProps={{ className: "!text-gray-primary !font-normal" }}
            small
            placeholder="Leave empty to use the coding display"
          />

          <fieldset className="mt-2 block pb-2">
            {/*<TD.Option {...register("required")} label={t("verplicht")} small />
            <TD.Option {...register("hidden")} label={t("hidden")} small />*/}
          </fieldset>
          {watch("type") == "coding" && <CodingOptionsFieldSet />}
          <ConditionalityFieldSet className="mt-2 border-b border-gray-lighter pb-2" />
          <TD.Submit small>{actionLabel}</TD.Submit>
        </div>
      )}
    </TD>
  );
}
export default QuestionPropertiesForm;
