import classNames from "classnames";
import MedicalIcon from "@/icons/MedicalIcon";
import { Spinner } from "@/Icons";
import { useTranslation } from "react-i18next";

type SemanticVersion = `${number}.${number}.${number}`;
type Status = "draft" | "active" | "retired";

export interface TemplateTileProps {
  id: string;
  title: string;
  description?: string;
  version?: SemanticVersion;
  status?: Status;
  icon?: MedicalIcon;
  isSubmitting?: boolean;
}

function TemplateTile({
  icon,
  id,
  title,
  description,
  version,
  status,
  isSubmitting,
}: TemplateTileProps) {
  return (
    <li className="flow-root rounded-md bg-gray-50 p-4 hover:bg-gray-ultralight">
      <div className="relative -m-2 flex items-center space-x-4 rounded-xl p-2 focus-within:ring-2 focus-within:ring-gray-light">
        <div
          className={classNames(
            "bg-gray-light",
            "flex h-16 w-16 flex-shrink-0 items-center justify-center rounded-lg",
          )}
        >
          {isSubmitting ? (
            <Spinner className="h-10 w-10 animate-spin stroke-current text-white" />
          ) : icon ? (
            <MedicalIcon
              icon={icon}
              className="h-10 w-10 fill-current text-white"
              aria-hidden="true"
            />
          ) : null}
        </div>
        <div>
          <div className="flex items-center space-x-2">
            <h3 className="text-base font-semibold tracking-wide text-primary">
              <button
                type="submit"
                name="template"
                value={id}
                className="text-left focus:outline-none"
              >
                <span className="absolute inset-0 " aria-hidden="true" />
                {title}
              </button>
            </h3>
            {version && <VersionBadge version={version} />}
            {status && <StatusBadge status={status} />}
          </div>
          <p className="mt-1 text-sm text-primary">{description}</p>
        </div>
      </div>
    </li>
  );
}

export default TemplateTile;

function VersionBadge({ version }: { version: SemanticVersion }) {
  const [major, minor, patch] = version.split(".");
  return (
    <span className="inline-flex items-center rounded-md bg-gray-50 px-1.5 py-0.5 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10">
      {`${major}.${minor}.${patch}`}
    </span>
  );
}

function StatusBadge({ status }: { status: "draft" | "active" | "retired" }) {
  const { t } = useTranslation();
  return (
    <span
      className={classNames(
        { "bg-green-100 text-green-800": status === "active" },
        { "bg-yellow-100 text-yellow-800": status === "draft" },
        { "bg-red-100 text-red-800": status === "retired" },
        "inline-flex items-center rounded-md bg-gray-50 px-1.5 py-0.5 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10",
      )}
    >
      {t(status)}
    </span>
  );
}
