/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BaseTemplateReportWithoutBlocks } from "../models/BaseTemplateReportWithoutBlocks";
import type { CreateTemplateReportDoc } from "../models/CreateTemplateReportDoc";
import type { LegacyReadTemplateReportDoc } from "../models/LegacyReadTemplateReportDoc";

import type { CancelablePromise } from "../core/CancelablePromise";
import type { BaseHttpRequest } from "../core/BaseHttpRequest";

export class TemplatesService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Get All Templates
   * Overview of all template templates available in the Content Engine.
   * @returns BaseTemplateReportWithoutBlocks Successful Response
   * @throws ApiError
   */
  public getAllTemplatesV1({
    offset,
    limit = 100,
    session,
  }: {
    offset?: number;
    limit?: number;
    session?: string;
  }): CancelablePromise<Array<BaseTemplateReportWithoutBlocks>> {
    return this.httpRequest.request({
      method: "GET",
      url: "/v1/templates/",
      cookies: {
        session: session,
      },
      query: {
        offset: offset,
        limit: limit,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Get Template By Id
   * Get template given it's id.
   * @returns LegacyReadTemplateReportDoc Successful Response
   * @throws ApiError
   */
  public getTemplateByIdV1({
    templateId,
    session,
  }: {
    templateId: string;
    session?: string;
  }): CancelablePromise<LegacyReadTemplateReportDoc> {
    return this.httpRequest.request({
      method: "GET",
      url: "/v1/templates/{template_id}",
      path: {
        template_id: templateId,
      },
      cookies: {
        session: session,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Put Template With Id
   * Update template given it's id.
   * @returns any Successful Response
   * @throws ApiError
   */
  public putTemplateWithIdV1({
    templateId,
    requestBody,
    session,
  }: {
    templateId: string;
    requestBody: CreateTemplateReportDoc;
    session?: string;
  }): CancelablePromise<any> {
    return this.httpRequest.request({
      method: "PUT",
      url: "/v1/templates/{template_id}",
      path: {
        template_id: templateId,
      },
      cookies: {
        session: session,
      },
      body: requestBody,
      mediaType: "application/json",
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Delete Template With Id
   * Delete a template given it's id.
   * @returns any Successful Response
   * @throws ApiError
   */
  public deleteTemplateWithIdV1({
    templateId,
    session,
  }: {
    templateId: string;
    session?: string;
  }): CancelablePromise<any> {
    return this.httpRequest.request({
      method: "DELETE",
      url: "/v1/templates/{template_id}",
      path: {
        template_id: templateId,
      },
      cookies: {
        session: session,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }
}
