import { AuthError, User } from "firebase/auth";
import { useQuery, UseQueryOptions } from "react-query";
import { getTenantAuth } from "@/services/tenant";
import keyFactory from "./keyFactory";

function getCurrentUser(): Promise<User | null> {
  const auth = getTenantAuth();
  return new Promise((resolve, reject) => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      unsubscribe();
      resolve(user);
    }, reject);
  });
}
export interface UseIdTokenOptions<Data>
  extends UseQueryOptions<string | null, AuthError, Data> {
  forceRefresh?: boolean;
}

function useIdToken<Data = string>(options: UseIdTokenOptions<Data> = {}) {
  const { forceRefresh } = options;
  return useQuery<string | null, AuthError, Data>(
    keyFactory.currentUser(),
    // we only return a token if we have a realmApp user
    // this prevents issues with unauthenticated database calls
    async () => {
      const user = await getCurrentUser();
      const token = await user?.getIdToken(forceRefresh);
      return token || null;
    },
    {
      staleTime: 3540 * 1000, // 59 minutes, refresh token expires in 1 hour
      ...options,
    }
  );
}
export default useIdToken;
