import {
  Ancestor,
  BaseElement,
  Descendant,
  BaseEditor,
  Node,
  NodeEntry,
  Path,
  Point,
  Range,
  Transforms,
  Editor,
  Element,
} from "slate";
import { TypedElement, ITypedElement } from "../../TypedElement";
import { ITiroElement } from "../../Base/types";
import {
  ColumnLayoutEntry,
  FieldEntryElement,
  IEntryCollectionElement,
  SubtitleElement,
} from "./types";
import {
  FREE_TEXT_ENTRY,
  QA_ANSWER,
  QA_ENTRY,
  TIME_DESCRIPTION,
  TIME_ENTRY,
} from "./consts";
import {
  normalizeColumnLayoutEntry,
  normalizeFreeTextEntry,
  normalizeLeftColumnEntry,
  normalizeSubsectionEntry,
  normalizeSubtitleEntry,
  normalizeTimestamp,
} from "./normalisation";
import { SHORTCUTS } from "./ShortCuts";
import { ICoding } from "@/data-models/value-models";
import TiroEditor, { ITiroEditor } from "../../Base/editor";
import { ReactEditor } from "slate-react";
import { EntryElement } from "./EntryElement";

function* serializeColumnLayoutToPlainText(
  editor: ITiroEditor,
  node: ColumnLayoutEntry,
  inlineSCT: boolean
) {
  const left = Array.from(
    TiroEditor.serializePlainText(editor, node.children[0], inlineSCT)
  );
  const right = Array.from(
    TiroEditor.serializePlainText(editor, node.children[1], inlineSCT)
  );
  const { state } = node.children[0];
  switch (state) {
    case "present":
      left.push(" (aanwezig)");
      break;
    case "absent":
      left.push(" (afwezig)");
      break;
    case "unknown":
      return;
    default:
      if (!state && right.length === 0) return;
  }

  if (right.filter((x) => x.length > 0).length === 0) yield left.join(" ");
  if (right.filter((x) => x.length > 0).length == 1)
    yield left.join(" ") + ": " + right.join(" ");
  if (right.filter((x) => x.length > 0).length > 1)
    yield left.join(" ") + ": \n  " + right.join("\n  ");
}

function* serializeSubtitleLayouttoPlainText(
  editor: BaseEditor,
  node: SubtitleElement
) {
  if (node.state === "absent") return;
  const subtitle = Node.string(node);
  yield "";
  yield subtitle;
}

function* serializeCollectionToPlainText(
  editor: ITiroEditor,
  node: IEntryCollectionElement,
  inlineSCT: boolean
) {
  const left = Array.from(
    TiroEditor.serializePlainText(editor, node.children[0], inlineSCT)
  );
  const right = Array.from(
    node.children
      .slice(1)
      .map((n) =>
        Array.from(TiroEditor.serializePlainText(editor, n, inlineSCT))
      )
      .filter((n) => n.length > 0)
      .map((n) => n.join(" "))
  );
  const { state } = node.children[0];
  switch (state) {
    case "present":
      break;
    case "absent":
      yield left.join(" ");
      return;
    case "unknown":
      return;
    default:
      if (!state && right.filter((x) => x.length > 0).length === 0) return;
  }

  if (right.filter((x) => x.length > 0).length === 0) yield left.join(" ");
  if (right.filter((x) => x.length > 0).length == 1)
    yield left.join(" ") + ": " + right.join(" ");
  if (right.filter((x) => x.length > 0).length > 1)
    yield left.join(" ") + ": \n  " + right.join("\n  ");
}

function* serializeFieldToPlainText(
  editor: BaseEditor,
  node: FieldEntryElement,
  inlineSCT: boolean
) {
  const sctCode = (node.entity?.coding as ICoding[] | undefined)?.find((c) =>
    c.system.startsWith("http://snomed.info/sct")
  );
  if (inlineSCT && sctCode) {
    yield `|${sctCode.code}| ${sctCode.display}`;
    return;
  }
  const text = [];
  if (node.entity) {
    text.push(node.entity.text);
  }
  switch (node.state) {
    case "unknown":
      return;
    case "present":
      text.push("(aanwezig)");
      break;
    case "absent":
      text.push("(afwezig)");
      break;
  }
  yield text.join(" ");
  return;
}

export interface IEntryEditor extends ITiroEditor {
  defaultLayout: "tree" | "two-column";
}

function isCollectionRoot(entryEditor: Editor, entry: NodeEntry<ITiroElement>) {
  const [, path] = entry;
  if (path.length < 1) return false;
  const [parentNode] = Editor.parent(entryEditor, path);
  const parentIsCollection = EntryElement.isCollection(parentNode);
  const isFirstChild = path.slice(-1)[0] === 0;
  return parentIsCollection && isFirstChild;
}

function isLastChild(
  parentEntry: NodeEntry<Ancestor>,
  childEntry: NodeEntry<Descendant>
) {
  const [parentNode, parentPath] = parentEntry;
  const [, childPath] = childEntry;

  if (
    Path.isCommon(parentPath, childPath) &&
    Path.equals(parentPath.concat(parentNode.children.length - 1), childPath)
  ) {
    return true;
  }
  return false;
}

export const withEntryLayout = <T extends ITiroEditor = ITiroEditor>(
  editor: T
): IEntryEditor & T => {
  const {
    deleteBackward,
    deleteForward,
    normalizeNode,
    insertText,
    insertIndent,
    serializePlainText,
  } = editor;
  const entryEditor = editor as any as IEntryEditor & T;

  entryEditor.defaultLayout = "tree";

  entryEditor.insertBreak = () => {
    Transforms.splitNodes(entryEditor, { always: true });
    // remove styling when inserting new line
    const blockAbove = Editor.above(entryEditor, {
      match: (n) => Editor.isBlock(entryEditor, n),
    });
    if (blockAbove && Editor.isEmpty(entryEditor, blockAbove[0])) {
      Transforms.unsetNodes(entryEditor, ["type", "format", "state"]);
    }
  };

  entryEditor.insertIndent = () => {
    const { selection } = entryEditor;
    if (!selection || Range.isExpanded(selection)) return;

    let parentBlock: Element | null = null;
    let parentPath: Path | null = null;
    for ([parentBlock, parentPath] of Editor.levels<Element>(entryEditor, {
      reverse: true,
    })) {
      if (
        Editor.isBlock(editor, parentBlock) &&
        parentPath.slice(-1)[0] !== 0
      ) {
        break;
      }
    }
    if (parentBlock && parentPath) {
      // determine previous entry
      const previousBlockEntry = Editor.previous<ITiroElement & BaseElement>(
        entryEditor,
        {
          match: (n) =>
            Editor.isBlock(entryEditor, n) &&
            ReactEditor.findPath(entryEditor, n).length == parentPath?.length,
          at: parentPath,
        }
      );
      if (previousBlockEntry) {
        const [previousBlock, previousPath] = previousBlockEntry;
        const parentStart = Editor.start(entryEditor, parentPath);

        // in case previous entry is a field or a collection we can wrap current entry in a new or existing cllection
        if (
          (EntryElement.isFieldEntry(previousBlock) ||
            EntryElement.isCollection(previousBlock)) &&
          !isCollectionRoot(entryEditor, previousBlockEntry)
        ) {
          Editor.withoutNormalizing(entryEditor, () => {
            if (Point.equals(parentStart, selection.anchor) && parentPath) {
              if (
                !previousBlock.children.some((n) =>
                  Editor.isBlock(entryEditor, n)
                )
              ) {
                Transforms.wrapNodes(
                  entryEditor,
                  {
                    layout: entryEditor.defaultLayout,
                    type: "collection",
                  } as IEntryCollectionElement,
                  { at: previousPath, match: (n) => n === previousBlock }
                );
              }
              Transforms.wrapNodes(
                entryEditor,
                {
                  layout: entryEditor.defaultLayout,
                  type: "collection",
                } as IEntryCollectionElement,
                { at: parentPath, match: (n) => n === parentBlock }
              );
              Transforms.mergeNodes(entryEditor, { at: parentPath });
            }
          });
        }
      }
    }
    insertIndent();
  };

  entryEditor.insertText = (text) => {
    const { selection } = entryEditor;

    if (!selection) return;

    // a three-double click creates a selectiong hanging over two entries,
    // this is unexpected by the user, so we unhang it first
    Transforms.setSelection(
      entryEditor,
      Editor.unhangRange(entryEditor, selection)
    );

    if (text === " " && selection && Range.isCollapsed(selection)) {
      const { anchor } = selection;
      const block = Editor.above(entryEditor, {
        match: (n) => Editor.isBlock(entryEditor, n),
      });
      const path = block ? block[1] : [];
      const start = Editor.start(entryEditor, path);
      const range = { anchor, focus: start };
      const beforeText = Editor.string(entryEditor, range);
      const properties = SHORTCUTS[beforeText];
      if (properties) {
        // remove the text in current leaf node
        Transforms.select(entryEditor, range);
        Transforms.delete(entryEditor);
        // IMPORTANT release focus so that the combobox input field can take it without Slate crashing
        ReactEditor.blur(entryEditor);
        Transforms.setNodes(entryEditor, properties, {
          match: (n) => Editor.isBlock(entryEditor, n),
        });
        return;
      }
    }
    insertText(text);
  };

  entryEditor.serializePlainText = (node, inlineSCT) => {
    if (EntryElement.isColumnLayoutEntry(node))
      return serializeColumnLayoutToPlainText(entryEditor, node, inlineSCT);

    if (EntryElement.isSubtitle(node)) {
      return serializeSubtitleLayouttoPlainText(entryEditor, node);
    }
    if (EntryElement.isCollection(node)) {
      return serializeCollectionToPlainText(entryEditor, node, inlineSCT);
    }
    if (EntryElement.isFieldEntry(node)) {
      return serializeFieldToPlainText(entryEditor, node, inlineSCT);
    }
    return serializePlainText(node, inlineSCT);
  };

  entryEditor.normalizeNode = (entry) => {
    const [node, path] = entry;

    // subtitle normalisation
    if (EntryElement.isSubtitle(node)) {
      if (normalizeSubtitleEntry([node, path], entryEditor)) return;
    }
    // subsection normalisation
    if (EntryElement.isSubsection(node)) {
      if (normalizeSubsectionEntry([node, path], entryEditor)) return;
    }

    if (EntryElement.isFreeTextEntry(node)) {
      if (normalizeFreeTextEntry([node, path], entryEditor)) return;
    }

    // qa / timestamped entry normalisation
    if (EntryElement.isColumnLayoutEntry(node)) {
      if (normalizeColumnLayoutEntry([node, path], entryEditor, node.type))
        return;
    }

    // qa-question / timestamp normalisation
    if (EntryElement.isLeftColumnEntry(node)) {
      if (normalizeLeftColumnEntry([node, path], entryEditor)) return;
      if (EntryElement.isTimestampedEntryTimestamp(node)) {
        if (normalizeTimestamp([node, path], entryEditor)) return;
      }
    }

    if (EntryElement.isFieldEntry(node)) {
      const text = Node.string(node);
      if (node.entity && node.initialQuery !== undefined) {
        Transforms.unsetNodes(entryEditor, "initialQuery", { at: path });
        if (text)
          Transforms.delete(entryEditor, { at: path.concat(0), voids: true });
        return;
      }
      if (!node.entity && !node.initialQuery) {
        const partialElement: Partial<FieldEntryElement> = {
          initialQuery: text,
        };
        Transforms.setNodes(entryEditor, partialElement, { at: path });
        if (text.length > 0)
          Transforms.delete(entryEditor, { at: path.concat(0), voids: true });
        return;
      }
    }

    if (EntryElement.isCollection(node)) {
      if (node.children.length === 1) {
        Transforms.unwrapNodes(entryEditor, { at: path });
        return;
      }
      /** Special normalisation for two-column since it only supports one level of nesting! */
      if (node.layout === "two-column") {
        for (const [childNode, childPath] of Node.children(entryEditor, path)) {
          if (
            isCollectionRoot(entryEditor, [
              childNode as ITiroElement,
              childPath,
            ])
          )
            continue;
          if (EntryElement.isCollection(childNode)) {
            /**
             * We want to prevent that accidentally pressing TAB
             * on a collection root after a two-column collection
             * will remove the whole hierarcy structure.
             *
             * On the other hand we want to prevent when accidentally tabbing inside a child of a two-column collect that
             * it breaks the existing checklists
             *
             */
            if (isLastChild([node, path], [childNode, childPath])) {
              Transforms.unwrapNodes<IEntryCollectionElement>(entryEditor, {
                at: childPath,
                match: (n) => n === node,
                split: true,
              });
            } else {
              Transforms.unwrapNodes<IEntryCollectionElement>(entryEditor, {
                at: childPath,
                match: (n) => n === childNode,
                split: true,
              });
            }
          }
        }
      }
      const { maxChildren } = node;
      if (maxChildren && node.children.length > maxChildren) {
        Transforms.unwrapNodes(entryEditor, {
          at: path.concat(maxChildren),
          split: true,
          match: (n) => n === node,
        });
        return;
      }
      if (Editor.isEmpty(entryEditor, node)) {
        Transforms.removeNodes(entryEditor, { at: path });
        return;
      }
    }
    return normalizeNode(entry);
  };

  entryEditor.deleteBackward = (unit) => {
    const { selection } = entryEditor;
    if (selection && Range.isCollapsed(selection)) {
      /**
       * START LEGACY ENTRIES (AVR 20220310)
       * Should be cleaned up when fields, free-text and collections are correctly behaving
       */
      // pressing backspace when cursor is at start of a qa-answer element, should move the cursor to the end of qa-question
      for (const [node, path] of Editor.nodes<ITypedElement>(entryEditor, {
        match: (n) =>
          Editor.isBlock(entryEditor, n) &&
          TypedElement.isTypedElement(n) &&
          [QA_ENTRY, FREE_TEXT_ENTRY].includes(n.type),
      })) {
        const start = Editor.start(entryEditor, path);
        if (
          Point.equals(start, selection.anchor) &&
          Node.string(node).length > 0
        ) {
          Transforms.move(entryEditor, { reverse: true, distance: 1 });
          return;
        }
      }
      // END LEGACY

      /** find block parent, from there we can orient our selves inside the document
       *  If the block parent is the first child of a collection, we taken the collection
       *
       */
      let parentBlock: Element | null = null;
      let parentPath: Path | null = null;
      for ([parentBlock, parentPath] of Editor.levels<Element>(entryEditor, {
        reverse: true,
      })) {
        if (
          Editor.isBlock(editor, parentBlock) &&
          parentPath.slice(-1)[0] !== 0
        ) {
          break;
        }
      }

      if (parentBlock && parentPath) {
        /*
         * if we are a the start of current node
         * check if there is collection holding current node
         * to determine if we need to modify the hierarcy
         */
        const parentStart = Editor.start(entryEditor, parentPath);
        if (Point.equals(parentStart, selection.anchor)) {
          // if there is a collection node
          const collectionEntry = Editor.above(entryEditor, {
            at: parentPath,
            match: (n) => EntryElement.isCollection(n),
            mode: "lowest",
          });

          if (collectionEntry) {
            const [collectionNode, collectionPath] = collectionEntry;
            const isLastNodeOfCollection = Path.isCommon(
              collectionPath.concat(collectionNode.children.length - 1),
              parentPath
            );
            /**
             * if we are at the begining of a block in the last node of current collection
             * we need to unwrap it so current node moves one level up in the hierarchy
             */
            if (isLastNodeOfCollection) {
              Transforms.unwrapNodes(entryEditor, {
                at: parentPath,
                split: true,
                match: (n) => n === collectionNode,
              });
              return;
            }
          }
        }
      }
    }

    deleteBackward(unit);
  };

  entryEditor.deleteForward = (unit) => {
    const { selection } = entryEditor;
    if (selection && Range.isCollapsed(selection)) {
      // pressing backspace when cursor is at start of a qa-answer element, should move the cursor to the end of qa-question
      for (const [node, path] of Editor.nodes<ITypedElement>(entryEditor, {
        match: (n) =>
          Editor.isBlock(entryEditor, n) &&
          TypedElement.isTypedElement(n) &&
          [
            QA_ENTRY,
            QA_ANSWER,
            TIME_ENTRY,
            TIME_DESCRIPTION,
            FREE_TEXT_ENTRY,
          ].includes(n.type),
      })) {
        const end = Editor.end(entryEditor, path);
        if (
          Point.equals(end, selection.anchor) &&
          Node.string(node).length > 0
        ) {
          Transforms.move(entryEditor, { reverse: false, distance: 1 });
          return;
        }
      }
      deleteForward(unit);
    }
  };

  return entryEditor;
};

export default withEntryLayout;
