import classNames from "classnames";
import React, { HTMLAttributes, useContext } from "react";
import { useFocused, useSelected } from "slate-react";
import { EntityContextWrapper } from "./ContextWrapper";

type EntityMarks = "underline";
export const EntityStyles = React.createContext<Record<EntityMarks, boolean>>({
  underline: true,
});

export interface RenderMarkedProps extends HTMLAttributes<HTMLSpanElement> {}

export const MarkedWithoutRef = (
  { children, className, ...attributes }: RenderMarkedProps,
  ref: any
) => {
  const selected = useSelected();
  const styles = useContext(EntityStyles);
  const entityContext = useContext(EntityContextWrapper);
  return (
    <span
      ref={ref}
      className={classNames(
        "cursor-pointer select-auto selection:bg-blue-100 selection:text-blue-dark selection:decoration-blue-400",
        "hover:text-blue-500 focus:bg-blue-100 focus:text-blue-dark focus:decoration-blue-400",
        {
          "underline underline-offset-1 hover:decoration-blue-200 ":
            styles.underline,
          "bg-blue-100 text-blue-dark decoration-blue-400 ": !!selected,
          " decoration-gray-light hover:decoration-2":
            entityContext != "absent",
          "line-through": entityContext === "absent",
          "text-gray-400": entityContext === "unknown",
        },
        className
      )}
      {...attributes}
    >
      {children}
    </span>
  );
};
export const RenderMarked = React.forwardRef<
  HTMLSpanElement,
  RenderMarkedProps
>(MarkedWithoutRef);

interface RenderBadgeProps extends React.HTMLAttributes<HTMLButtonElement> {
  active?: boolean;
}
const BadgeWithoutRef = (
  { children, className, active, onClick, ...attributes }: RenderBadgeProps,
  ref: any
) => {
  const focused = useFocused();
  const selected = useSelected();
  return (
    <button
      type="button"
      ref={ref}
      onClick={onClick}
      contentEditable={false}
      tabIndex={-1}
      className={classNames(
        "my-0.5 mr-1 inline-flex select-none items-center rounded  px-2 py-0.5 text-sm font-medium focus:outline-none focus:ring-1",
        {
          "bg-blue-100 text-blue-700": !!active,
          "bg-gray-lighter text-primary": !active,
          "outline-none ring-2 ring-gray-700": focused && selected,
        },
        className
      )}
      {...attributes}
    >
      {children}
    </button>
  );
};
export const RenderBadge = React.forwardRef<
  HTMLButtonElement,
  RenderBadgeProps
>(BadgeWithoutRef);
