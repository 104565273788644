import React from "react";
import { Infer } from "superstruct";
import Tippy from "@tippyjs/react";
import {
  ConceptEntity,
  ConceptEntityModel,
} from "@/data-models/concept-entity";
import classNames from "classnames";
import { PencilSquareIcon } from "@heroicons/react/20/solid";

export interface IConceptEntityTooltipProps
  extends React.HTMLAttributes<HTMLDivElement> {
  entity: Infer<typeof ConceptEntityModel>;
  children: React.ReactElement;
  onEdit?: () => void;
}

const ConceptEntityTooltip = ({
  entity,
  children,
  className,
  onEdit,
  ...attributes
}: IConceptEntityTooltipProps) => {
  const conceptEntity = new ConceptEntity(entity);
  const code = conceptEntity.coding[0];
  const { semanticContext } = conceptEntity;
  return (
    <Tippy
      delay={[500, 50]}
      interactive
      content={
        <div
          className={classNames(
            "space-x flex select-none items-center divide-x",
            className
          )}
          {...attributes}
        >
          <div className="px-1">
            <span>{code.display ?? ""}</span>
            {semanticContext && (
              <>
                <span> - </span>
                <span>{semanticContext.display}</span>
              </>
            )}
          </div>
          {onEdit && (
            <button
              title="Bewerk concept"
              type="button"
              onClick={onEdit}
              className="inline"
            >
              <PencilSquareIcon className="mx-1 h-3 w-3" />
            </button>
          )}
        </div>
      }
    >
      {children}
    </Tippy>
  );
};
export default ConceptEntityTooltip;
