import HeadlessTippy from "@tippyjs/react/headless";
import React, { Ref, useState } from "react";
import { ReactEditor, useSlateStatic } from "slate-react";
import { Editor, Transforms } from "slate";
import { IEntityElement } from "../../../editor/legacy/Entities/types";
import EntityEditor, {
  EntityEditorProps,
} from "@/components/legacy/EntityEditor";
import { IEditableEntity } from "./types";
import { validate } from "superstruct";
import { QuantityModel } from "@/data-models/quantity-entity";
import TooltipErrorBoundary from "@/components/tooltip/TooltipErrorBoundary";
interface HoveringEntityEditorProps<
  TEntity extends IEditableEntity,
  TElement extends IEntityElement<TEntity>
> {
  element: TElement;
  open?: boolean;
  close?: () => void;
  children: JSX.Element;
}
const HoveringEntityEditorWithoutRef = <
  TEntity extends IEditableEntity,
  TElement extends IEntityElement<TEntity>
>(
  {
    element,
    children,
    open: providedOpen,
    close: providedClose,
  }: HoveringEntityEditorProps<TEntity, TElement>,
  ref: Ref<Element>
) => {
  const { entity } = element;
  const editor = useSlateStatic() as ReactEditor;
  const [isOpen, setIsOpen] = useState(false);

  const handleClose = () => {
    if (providedOpen != undefined) providedClose && providedClose();
    setIsOpen(false);
  };

  const open = providedOpen ?? isOpen;

  const handleChange = (entity: TElement["entity"]) => {
    if (!entity) return;
    const path = ReactEditor.findPath(editor, element);
    console.log(
      "before handleEntityChange",
      validate(entity, QuantityModel, { coerce: true, mask: true })
    );
    Transforms.setNodes<TElement>(editor, { entity } as Partial<TElement>, {
      at: path,
    });
    const nextPoint = Editor.after(editor, path);
    if (nextPoint) Transforms.select(editor, nextPoint);
  };
  const TypedEntityEditor = React.forwardRef<
    HTMLFormElement,
    EntityEditorProps<TEntity>
  >(EntityEditor);
  return (
    <HeadlessTippy
      // this is a hack to make sure that the form remounts (and it's initialValues are reset)
      // when the entity changes
      key={JSON.stringify(entity)}
      ref={ref}
      interactive
      visible={open}
      render={() => (
        <TooltipErrorBoundary>
          <TypedEntityEditor
            entity={entity}
            onEntityChange={handleChange}
            close={handleClose}
            open={open}
          />
        </TooltipErrorBoundary>
      )}
    >
      {children}
    </HeadlessTippy>
  );
};

const HoveringEntityEditor = React.forwardRef(HoveringEntityEditorWithoutRef);
export default HoveringEntityEditor;
