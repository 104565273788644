import { CONFIG } from "@/config";
import React from "react";
import toast from "react-hot-toast";
import { useQuery } from "react-query";
const checkAPIHealth = async () => {
  const response = await fetch(`${CONFIG["CONTENT_ENGINE"]}/`);
  if (response.status !== 200) {
    throw Error("Something is wrong with the Tiro Concept Engine.");
  }
  return true;
};

const TOAST_MESSAGE = {
  loading: "Checking connection with Tiro Content Engine.",
  success: "Connection with Tiro Content Engine is healthy!",
  error: "There is a problem while connecting to the Tiro Concept Engine.",
} as const;
const TCE_STALE_TIME = 5 * 60 * 1000;

export interface UseTCEConnectionCheckOptions {
  hidden?: boolean;
}

export function useTCEConnectionCheck({
  hidden,
}: UseTCEConnectionCheckOptions = {}) {
  return useQuery(
    ["tce-connectivity"],
    () => {
      !hidden &&
        toast.loading(TOAST_MESSAGE.loading, { id: "tce-connectivity" });
      return checkAPIHealth();
    },
    {
      staleTime: TCE_STALE_TIME,
      onSuccess: () =>
        !hidden &&
        toast.success(TOAST_MESSAGE.success, {
          id: "tce-connectivity",
          duration: 500,
        }),
      onError: () =>
        !hidden && toast.error(TOAST_MESSAGE.error, { id: "tce-connectivity" }),
    },
  );
}

type TCEConnectionCheckProps = UseTCEConnectionCheckOptions;

export const TCEConnectionCheck = ({
  children,
  ...options
}: React.PropsWithChildren<TCEConnectionCheckProps>) => {
  useTCEConnectionCheck(options);

  return <>{children}</>;
};
