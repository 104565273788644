const SUPPORTED_LOCALES = ["nl-BE", "en-GB"];
type LocaleCode = typeof SUPPORTED_LOCALES[number];
type SuffixMap = Map<Intl.LDMLPluralRule, string>;
const SUFFIX_MAPS: Record<LocaleCode, SuffixMap> = {
  "nl-BE": new Map([
    ["one", "ste"],
    ["two", "de"],
    ["few", "de"],
    ["other", "de"],
  ]),
  "en-GB": new Map([
    ["one", "st"],
    ["two", "nd"],
    ["few", "rd"],
    ["other", "th"],
  ]),
};

export default function formatOrdinals(
  n: number,
  language: LocaleCode = "nl-BE"
) {
  console.log(
    Intl.PluralRules.supportedLocalesOf(language, { localeMatcher: "lookup" })
  );

  const pr = new Intl.PluralRules(language, { type: "ordinal" });
  const suffixes = SUFFIX_MAPS[language];
  const rule = pr.select(n);
  const suffix = suffixes.get(rule);
  return `${n}${suffix}`;
}
