import classNames from "classnames";
import React from "react";

export interface CheckBoxProps
  extends Omit<React.InputHTMLAttributes<HTMLInputElement>, "type"> {
  error?: boolean;
  small?: boolean;
  extraSmall?: boolean;
}
function CheckBox(
  { className, error, small, extraSmall, ...props }: CheckBoxProps,
  ref?: React.Ref<HTMLInputElement>
) {
  return (
    <input
      ref={ref}
      type="checkbox"
      className={classNames(
        "border-gray-light",
        {
          "text-blue-primary focus:ring-blue-primary": !error,
          "text-red-500 ring-red-500": error,
          "h-5 w-5 rounded-md": !small && !extraSmall,
          "h-4 w-4 rounded": small,
          "h-3 w-3 rounded-sm": extraSmall,
        },
        className
      )}
      {...props}
    />
  );
}

export default CheckBox;
