import useMeasureComponentLifeTime, {
  TransactionTypes,
} from "@/hooks/useMeasureComponentLifetime";
import ReportEditor from "./ReportEditor";

function ReportEditPage() {
  useMeasureComponentLifeTime("Edit Note page", TransactionTypes.PAGE_LIFETIME);
  return <ReportEditor />;
}
export default ReportEditPage;
