import { SVGProps } from "@/icons/types";

const Erlenmeyer = (props: SVGProps) => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 512 512"
    stroke="none"
    strokeWidth="1"
    fill="none"
    fillRule="evenodd"
    {...props}
  >
    <path
      stroke="currentColor"
      d="M410.216,433.521l-0.131-0.23L305.105,262.027V31.347h28.582V0h-28.582h-98.22h-28.582v31.347h28.582v230.682
			L101.708,433.665l-0.409,0.706c-9.112,16.768-8.717,37.367,1.029,53.754C111.22,503.074,126.452,512,143.077,512h225.835
			c16.812,0,32.137-9.08,40.992-24.29C419.609,471.044,419.728,450.28,410.216,433.521z M238.231,270.869V31.347h35.527V270.87
			l21.122,34.459h-77.764L238.231,270.869z M382.815,471.938c-1.526,2.621-5.935,8.716-13.903,8.716H143.077
			c-7.587,0-11.907-5.357-13.806-8.551c-3.193-5.369-4.998-14.053-0.595-22.451l69.231-112.976h116.188l68.973,112.52
			C387.737,457.612,386.011,466.448,382.815,471.938z"
    />
    <rect
      x="190.694"
      y="382.171"
      width="23.51"
      height="23.51"
      stroke="currentColor"
    />
    <rect
      x="222.041"
      y="412.735"
      width="23.51"
      height="23.51"
      stroke="currentColor"
    />
    <rect
      x="181.551"
      y="431.282"
      width="23.51"
      height="23.51"
      stroke="currentColor"
    />
  </svg>
);

export default Erlenmeyer;
