import { Row, Column, TableInstance } from "./types";
import { defaultCellValue, generateUniqueId } from "./utils";
import produce, { Draft, setAutoFreeze } from "immer";

// by default immer will Object.freeze, this breaks MOVE_ROW action
// Note that we are mutating the table instead of creating a new table object during
// drag and drop of rows
setAutoFreeze(false);

// A reducer has to types of arguments the State and the Action.
// Here we explicitly define them
type State<TColumns extends Column[] = Column[]> = TableInstance<TColumns>;

// all actions that a user can do on this table
type AddRow = { type: "ADD_ROW" };
type AddColumn = { type: "ADD_COLUMN"; column: Omit<Column, "id"> };
type RemoveRow = { type: "REMOVE_ROW"; index: number };
type MoveRow = { type: "MOVE_ROW"; dragIndex: number; hoverIndex: number };

export type TableAction = AddRow | AddColumn | RemoveRow | MoveRow;

function tableReducer<TColumns extends Column[] = Column[]>(
  table: State<TColumns>,
  action: TableAction
) {
  let nextTable: State<TColumns>;
  switch (action.type) {
    case "ADD_ROW":
      {
        const newRow: Partial<Row> = { id: generateUniqueId() };
        table.columns.forEach((col) => {
          newRow[col.id] = defaultCellValue(col);
        });
        nextTable = {
          ...table,
          data: [...table.data, newRow as Row],
        };
      }
      break;
    case "ADD_COLUMN":
      {
        const columnType = action.column.type;
        // cast because typescript in tables in really hard
        const newColumn = {
          id: generateUniqueId(),
          ...action.column,
        } as Column<typeof columnType>;
        nextTable = {
          ...table,
          data: table.data.map((row) => ({
            ...row,
            [newColumn.id]: defaultCellValue(newColumn),
          })),
        };
      }
      break;
    case "REMOVE_ROW":
      nextTable = {
        ...table,
        data: [
          ...table.data.slice(0, action.index),
          ...table.data.slice(action.index + 1),
        ],
      };
      break;
    case "MOVE_ROW":
      {
        nextTable = produce(table, (draftState: Draft<TableInstance>) => {
          const draggedRow = draftState.data.splice(action.dragIndex, 1);
          draftState.data.splice(action.hoverIndex, 0, draggedRow[0]);
        });
      }
      break;
    default:
      nextTable = table;
  }
  return nextTable;
}

export default tableReducer;
