import { RenderElementProps } from "slate-react";
import { IEditorPlugin } from "../Base/types";
import { TypedElement } from "../TypedElement";
import RenderDropdownInputElement from "./RenderDropdownInputElement";
import { REFERENCE_INPUT_ELEMENT } from "./consts";
import { FieldValues } from "react-hook-form";
import {
  ReferenceDropdownInputProps,
  ReferencePath,
} from "@/components/inputs/Dropdown/ReferenceDropdown";
import { IBaseInputElement } from "@/editor/utils/useSlateInputElement";

interface IReferenceInputElement<
  TFieldValues extends FieldValues = FieldValues,
  TName extends ReferencePath<TFieldValues> = ReferencePath<TFieldValues>
> extends IBaseInputElement,
    Omit<ReferenceDropdownInputProps<TFieldValues, TName>, "children"> {
  name: TName;
  inputType: "dropdown";
}

export interface RenderReferenceInputElementProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends ReferencePath<TFieldValues> = ReferencePath<TFieldValues>
> extends RenderElementProps {
  element: IReferenceInputElement<TFieldValues, TName>;
}

export const ReferenceInputElement = {
  ...TypedElement,
  type: REFERENCE_INPUT_ELEMENT,
  isReferenceInputElement: (o: any): o is IReferenceInputElement => {
    return TypedElement.isTypedElement(o) && o.type === REFERENCE_INPUT_ELEMENT;
  },
};
const ReferenceInputPlugin: IEditorPlugin = {
  renderElement: (props) => {
    const { element } = props;
    if (ReferenceInputElement.isReferenceInputElement(element)) {
      switch (element.inputType) {
        case "dropdown":
        default:
          return <RenderDropdownInputElement {...props} element={element} />;
      }
    }
    return;
  },
  inlineTypes: [REFERENCE_INPUT_ELEMENT],
  voidTypes: [REFERENCE_INPUT_ELEMENT],
};

export default ReferenceInputPlugin;
