import { IAttachment } from "@/components/AttachmentButton/AttachmentButton";
import { TypedElement, ITypedElement } from "../TypedElement";
import { ATTACHMENT_ELEMENT } from "./consts";

export interface IAttachmentElement extends ITypedElement {
  type: typeof ATTACHMENT_ELEMENT;
  readOnly?: boolean;
  entity: IAttachment;
}
const AttachementElement = {
  ...TypedElement,
  isAttachment: (o: any): o is IAttachmentElement => {
    return TypedElement.isTypedElement(o) && o.type === ATTACHMENT_ELEMENT;
  },
};
export default AttachementElement;
