import React from "react";
import {
  QuantityModel,
  createQuantityEntity,
} from "@/data-models/quantity-entity";
import { Infer } from "superstruct";
import Tippy from "@tippyjs/react";
import Erlenmeyer from "@/icons/outline/Erlenmeyer";
import { PencilSquareIcon } from "@heroicons/react/20/solid";

export interface IQuantityEntityTooltipProps {
  entity: Infer<typeof QuantityModel>;
  children: React.ReactElement;
  onEdit?: () => void;
}

const QuantityEntityTooltip = ({
  entity,
  children,
  onEdit,
}: IQuantityEntityTooltipProps) => {
  const quantityEntity = createQuantityEntity(entity);
  return (
    <Tippy
      delay={[500, 50]}
      interactive
      content={
        <div className="flex h-full w-full items-stretch justify-center space-x-2 divide-x py-1 font-light">
          <div className="flex items-center ">
            <Erlenmeyer className="inline h-4 w-4 fill-current stroke-current text-gray-50" />
            {quantityEntity.interprets && (
              <>
                <span>{quantityEntity.interprets.text}</span>
                <span className="mx-2">-</span>
              </>
            )}
            <span>{quantityEntity.toString()}</span>
          </div>
          {onEdit && (
            <button
              title="Bewerk deze meting"
              type="button"
              onClick={onEdit}
              className="block"
            >
              <PencilSquareIcon className="mx-2 h-4 w-4" />
            </button>
          )}
        </div>
      }
    >
      {children}
    </Tippy>
  );
};
export default QuantityEntityTooltip;
