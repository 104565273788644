import { useCallback, useRef } from "react";

/**
 * Make sure a function is stable and doesn't trigger rerenders.
 * Note that if the incomming function changes, the last one will be executed.
 * @param fn callback
 * @returns  stable callback
 */
function useStableCallback<
  TArgs extends unknown[],
  TReturn,
  TFunc = (...args: TArgs) => TReturn,
>(fn: TFunc): TFunc;
function useStableCallback(fn?: undefined): undefined;
function useStableCallback<TArgs extends unknown[], TReturn>(
  fn?: (...args: TArgs) => TReturn,
): undefined | ((...args: TArgs) => TReturn) {
  const fnRef = useRef(fn);
  fnRef.current = fn;
  const stableFn = useCallback(
    (...args: TArgs) => fnRef?.current && fnRef.current(...args),
    [],
  );
  if (!fn) return undefined;
  return stableFn as (...args: TArgs) => TReturn;
}

export default useStableCallback;
